import clsx from 'clsx';
import { isString } from 'lodash-es';
import React, { ChangeEvent, useState } from 'react';
import type { AriaTextFieldProps } from 'react-aria';
import { useTextField } from 'react-aria';
import { Button } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { FieldDescription, FieldError, FieldLabel, FieldProps } from '../../atoms/field/field';
import Icon, { IconName } from '../../atoms/icon/icon';
import { Text } from '../../atoms/text/text';
import { input, inputContainer } from '../../electrons/input';
import { textAreaContainer } from '../../electrons/textArea';

interface TextAreaFieldProps
  extends Omit<AriaTextFieldProps, 'description' | 'errorMessage'>,
    FieldProps {
  className?: string;
  labelClassName?: string;
  rows?: number;
  placeholder?: string;
  maxLength?: number;
  descriptionIconName?: string;
  showCloseButton?: boolean;
  onChange?: (value: string) => void;
}

export function TextAreaField({
  label,
  description,
  errorMessage,
  className,
  labelClassName,
  placeholder,
  rows = 2,
  maxLength,
  descriptionIconName,
  isDisabled,
  showCloseButton,
  onChange,
  ...props
}: TextAreaFieldProps) {
  const ref = React.useRef<HTMLTextAreaElement>(null);

  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(
    {
      label,
      ...props,
      inputElementType: 'textarea',
    },
    ref,
  );

  const [text, setText] = useState<string>((inputProps.value as string) ?? '');
  const [maxCharsLengthError, setMaxCharsLengthError] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value.trim();
    if (text === '' && value === '') return; // Prevents user to type only spaces
    if (!maxLength) {
      setText(event.target.value);
      onChange?.(event.target.value);
    } else {
      const newText = event.target.value;
      if (newText.length <= maxLength) {
        setText(newText);
        setMaxCharsLengthError('');
        onChange?.(event.target.value);
      } else {
        setMaxCharsLengthError('Max characters reached');
      }
    }
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div>
      {label && (
        <FieldLabel
          {...labelProps}
          className={twMerge('m-0 mb-2 flex text-base font-bold', labelClassName)}>
          {label}
        </FieldLabel>
      )}

      <div
        className={twMerge(clsx(inputContainer(), textAreaContainer(), className))}
        {...(isDisabled ? { 'data-disabled': true } : {})}
        data-empty={text === ''}
        data-invalid={errorMessage || maxCharsLengthError || undefined}>
        {descriptionIconName && (
          <Icon className="description-icon mr-3 h-6 w-6" name={descriptionIconName as IconName} />
        )}
        <textarea
          className={twMerge(
            clsx(
              input(),
              'resize-none overflow-hidden bg-transparent p-0 text-base font-normal leading-6 ring-0 focus-within:ring-0 focus:ring-0',
            ),
          )}
          dir="auto"
          rows={rows}
          {...inputProps}
          {...(isDisabled ? { disabled: true } : {})}
          onChange={handleChange}
          placeholder={placeholder}
          ref={ref}
          value={text}
        />

        {showCloseButton && (
          <Button
            className="ml-3 opacity-100 outline-none transition-opacity focus:outline-none group-data-[empty=true]:opacity-0"
            onPress={() => {
              setText('');
              setMaxCharsLengthError('');
            }}>
            <Icon className="h-6 w-6" name="close" />
          </Button>
        )}
      </div>
      <div className="flex">
        {errorMessage || maxCharsLengthError ? (
          <FieldError {...errorMessageProps} label={isString(label) ? label : 'textarea'}>
            {errorMessage ?? maxCharsLengthError}
          </FieldError>
        ) : (
          description && <FieldDescription {...descriptionProps}>{description}</FieldDescription>
        )}
        {maxLength && (
          <div className="flex-1 text-end">
            <Text
              className={maxCharsLengthError ? `text-negative-400 mt-1` : `text-grey-500 mt-2`}
              variant="p5">
              {text.length || '0'}
              {maxLength ? `/${maxLength}` : ''}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
