import { useSearchParams } from '@remix-run/react';
import { Drawer } from '@venncity/block';
import { EventFormTabs } from '~/dto/event-form-dto';
import { useSearchNavigate } from '~/utils/search-params';
import { CreateEventForm } from './create-event-form';
import { EditEventForm } from './edit-event-form';
import { EventPreview } from './event-preview';

export function EventFormDialog() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();

  const eventId = searchParams.get('event-id');
  const view = searchParams.get('view') || EventFormTabs.Form;

  return (
    <Drawer
      className="flex flex-col overflow-hidden"
      defaultOpen
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchNavigate(
            {
              dialogType: undefined,
              view: undefined,
              eventId: undefined,
              residentHosted: undefined,
            },
            { preventScrollReset: true },
          );
        }
      }}>
      <div className="flex min-h-0 flex-1 flex-col">
        {view === EventFormTabs.Preview ? (
          <EventPreview />
        ) : eventId ? (
          <EditEventForm />
        ) : (
          <CreateEventForm />
        )}
      </div>
    </Drawer>
  );
}
