import { useSearchParams } from '@remix-run/react';
import type { loader as profileLoader } from '~/routes/resources+/residents+/$id.profile';
import { useResourceData } from '~/utils/http';
import { GlobalDialogType } from '~/utils/search-params';
import NotificationFormDialog from './notification-form-dialog';

export function CreateResidentNotificationDialog() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get('resident-id') || '';
  const { data: { resident } = {} } = useResourceData<typeof profileLoader>(
    `resources/residents/${residentId}/profile`,
  );
  const residents = resident
    ? [
        {
          id: residentId,
          name: resident.name,
          email: resident.email,
          initials: resident.initials,
          buildingName: resident?.leaseContracts?.[0]?.building || null,
        },
      ]
    : [];

  return (
    <NotificationFormDialog
      initialValues={{
        residents,
        audience: 'SPECIFIC_MEMBERS',
        audienceParams: {
          userIds: [residentId],
          __typename: 'SpecificUsersAudienceParams',
        },
      }}
      nextPageParams={{
        'resident-id': residentId,
        'dialog-type': GlobalDialogType.ResidentProfile,
      }}
      title="New update"
    />
  );
}
