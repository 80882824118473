import { Resize } from '@cloudinary/url-gen/actions';
import { useSearchParams } from '@remix-run/react';
import { Heading, Icon, Dialog, Text } from '@venncity/block';
import { Spin, Button, Divider } from '@venncity/venn-ds';
import { GeneralInfoPageFormTabs } from '~/dto/general-info-page-form-dto';
import { cloudinary } from '~/lib/cloudinary';
import type { loader } from '~/routes/resources+/general-info-pages+/$id.preview';
import { useResourceData } from '~/utils/http';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';

export function GeneralInfoPagePreview() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  const generalInfoPageId = searchParams.get(GlobalSearchParam.GeneralInfoPageId);
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);

  const { data } = useResourceData<typeof loader>(
    `resources/general-info-pages/${generalInfoPageId}/preview?community-id=${communityId}`,
  );

  return (
    <Dialog>
      <Dialog.Header showCloseButton={false} title="General info page preview" />
      <Dialog.Body className="pb-0">
        {data ? (
          <div className="bg-grey-900 rounded-2xl p-12">
            <div className="relative mx-auto mt-4 w-full self-center overflow-hidden rounded-3xl border-[4px] border-[#63605D] bg-[#F8F7F4] p-6">
              <div className="absolute inset-0 z-0 h-[350px] w-full bg-[#c6c6c3]">
                {data?.image?.id ? (
                  <img
                    alt="General info page image"
                    className="absolute inset-0 z-0 h-full w-full object-cover object-center"
                    src={cloudinary.image(data.image.id).resize(Resize.scale().width(700)).toURL()}
                  />
                ) : (
                  <div className="h-full w-full bg-[#c6c6c3] text-black"></div>
                )}
              </div>
              <div className="relative z-10 pt-40">
                <Heading className="font-bold text-white">
                  {data?.title || "Your general info page's title"}
                </Heading>
                <div className="shadow-2 relative mt-4 flex flex-col overflow-hidden rounded-2xl bg-white p-6">
                  <Text
                    aria-label="gip-preview-subtitle"
                    className="text-lg text-[#625851]"
                    weight="bold">
                    {data?.subtitle || "Your general info page's subtitle"}
                  </Text>
                  <Text
                    aria-label="gip-preview-description"
                    className="overwrite-strong-font mt-4 text-[15px] font-medium text-[#3E3A37]"
                    dangerouslySetInnerHTML={{
                      __html: data?.description || 'general info page description',
                    }}
                  />
                </div>
                <div className="mt-6 flex overflow-hidden">
                  {data?.places?.map((place) => (
                    <ServiceCard
                      imageId={place?.images?.media[0]?.resourceId}
                      key={place.id}
                      name={place.name}>
                      <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {place.address?.streetNumber || 'Null'} {place.address?.streetName}
                      </span>
                      <span className="font-bold">{place.currentlyOpen ? 'Open' : 'Closed'}</span>
                    </ServiceCard>
                  ))}
                  {data?.events?.map((event) => (
                    <ServiceCard imageId={event?.image} key={event.id} name={event?.name}>
                      <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {event.startDateHoodTimezone} {event.startTimeHoodTimzeone}
                      </span>
                      <span className="font-bold" dir="ltr">
                        {event.location}
                      </span>
                    </ServiceCard>
                  ))}
                  {data?.services?.map((service) => (
                    <ServiceCard
                      imageId={service?.images?.media[0]?.resourceId}
                      key={service.id}
                      name={service.name}>
                      <span>By {service.providerName}</span>
                    </ServiceCard>
                  ))}
                  {data?.products?.map((product) => (
                    <ServiceCard key={product.id} name={product.name} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Spin />
          </div>
        )}
      </Dialog.Body>
      <div className="px-8">
        <Divider />
      </div>
      <Dialog.Footer className="justify-start">
        <Button
          icon={<Icon name="chevron-left" />}
          onClick={() => {
            searchNavigate({ view: GeneralInfoPageFormTabs.Form });
          }}
          type="primary">
          Edit
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

const ServiceCard = ({
  name,
  imageId,
  children,
}: {
  name: string;
  imageId?: string | null;
  children?: React.ReactNode;
}) => {
  return (
    <div className="mr-4 min-w-[118px] max-w-[118px]">
      <div className=" h-[80px] w-full overflow-hidden rounded-[10px]">
        {imageId ? (
          <img
            alt={`${name} image`}
            className="inset-0 h-full w-full object-cover object-center"
            src={cloudinary.image(imageId).resize(Resize.scale().width(700)).toURL()}
          />
        ) : (
          <div className="text h-full w-full bg-[#D0B6A4] text-black"></div>
        )}
      </div>
      <div className="flex flex-col pb-[10px] pt-[10px]">
        <Text className="mb-1 text-[15px] font-bold text-[#3E3A37]" dir="ltr">
          {name?.toUpperCase()}
        </Text>
        <Text
          className="mb-1 flex w-[118px] flex-col text-[11px] font-normal text-[#3E3A37]"
          dir="ltr">
          {children}
        </Text>
      </div>
    </div>
  );
};
