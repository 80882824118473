import { useSearchParams } from '@remix-run/react';
import { useQuery } from '@tanstack/react-query';
import {
  Icon,
  Popover,
  Pressable,
  Text,
  Item,
  ListBox,
  DialogTrigger,
  BottomSheet,
  SearchField,
} from '@venncity/block';
import { listBoxTriggerIcon } from '@venncity/block/electrons/list-box';
import axios from 'axios';
import { debounce } from 'lodash-es';
import React from 'react';
import type { GridListProps } from 'react-aria-components';
import { useAsyncList } from 'react-stately';
import type { EventResourceDTO } from '~/dto/event-resource-dto';
import { NoSearchResults } from '../helpers/empty-search-results';
import { EmptyState } from '../helpers/empty-state';
import { useIsMobile } from '../hooks/useIsMobile';

export function EventsSelector({ defaultSelectedKey }: { defaultSelectedKey: string | undefined }) {
  const [selectedEvent, setSelectedEventKey] = React.useState<string[]>(
    defaultSelectedKey ? [defaultSelectedKey] : [],
  );
  const [searchParams] = useSearchParams();
  const communityId = searchParams.get('community-id') || '';
  const [isOpen, setIsOpen] = React.useState(false);

  const [BottomSheetIsOpen, setBottomSheetIsOpen] = React.useState(false);

  const isMobile = useIsMobile();
  const itemsCount = isMobile ? 50 : 10;

  const list = useAsyncList<EventResourceDTO>({
    async load({ filterText }) {
      const { data } = await axios.get(`/resources/events?community-id=${communityId}`, {
        params: { q: filterText, includePastEvents: true, limit: itemsCount },
      });
      return { items: data || [] };
    },
  });
  const shouldShowEmptyState = Boolean(
    list.items.length === 0 && !list.filterText && !list.isLoading,
  );

  const getAttendees = React.useCallback(async () => {
    if (selectedEvent.length === 0) return;

    const { data } = await axios.get<{ attendees: number; eventName: string }>(
      `/resources/events/${selectedEvent[0]}/attendees`,
    );

    return { attendees: data.attendees, eventName: data.eventName };
  }, [selectedEvent]);

  const { data: eventAttendees, isLoading } = useQuery({
    queryKey: ['event-attendees', selectedEvent[0]],
    queryFn: getAttendees,
    enabled: !!selectedEvent,
  });

  const { searchField, content } = useEventsListBox({
    ...(!isMobile && { defaultSelectedKeys: selectedEvent }),
    ...(isMobile && { selectedKeys: selectedEvent }),
    items: list.items,
    onInputChange: debounce(list.setFilterText, 400, {
      maxWait: 1000,
      leading: false,
      trailing: true,
    }),
    onSelectionChange: (selection) => {
      setSelectedEventKey(Array.from(selection).map(String));
      isMobile ? setBottomSheetIsOpen(false) : setIsOpen(false);
    },
    isMobile,
    shouldShowEmptyState,
  });

  return (
    <div>
      {selectedEvent && <input name="event" type="hidden" value={selectedEvent[0]} />}
      <div className="hidden lg:block">
        <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
          <ButtonTrigger
            eventAttendees={eventAttendees}
            isLoading={isLoading}
            selectedEvent={selectedEvent}
            setIsOpen={setIsOpen}
          />
          <Popover className="w-[460px]" offset={2}>
            <div className="space-y-2 overflow-y-auto">
              <div className="sticky top-0 w-full bg-white p-3">{searchField}</div>
              <div className="p-3 pt-0">{content}</div>
            </div>
          </Popover>
        </DialogTrigger>
      </div>
      <div className="lg:hidden">
        <DialogTrigger isOpen={BottomSheetIsOpen} onOpenChange={setBottomSheetIsOpen}>
          <ButtonTrigger
            eventAttendees={eventAttendees}
            isLoading={isLoading}
            selectedEvent={selectedEvent}
            setIsOpen={setIsOpen}
          />
          <BottomSheet className="h-[95vh]">
            <BottomSheet.Header className="border-transparent">{searchField}</BottomSheet.Header>
            <BottomSheet.Body>{content}</BottomSheet.Body>
          </BottomSheet>
        </DialogTrigger>
      </div>
    </div>
  );
}

const useEventsListBox = ({
  onInputChange,
  items,
  shouldShowEmptyState,
  isMobile,
  ...props
}: GridListProps<EventResourceDTO> & {
  onInputChange: (value: string) => void;
  shouldShowEmptyState: boolean;
  isMobile: boolean;
}) => {
  const [value, setValue] = React.useState('');

  const searchField = (
    <SearchField
      aria-label="events search field"
      autoFocus
      className={isMobile ? '' : 'bg-white'}
      label="Search an event..."
      onChange={(e) => {
        onInputChange(e);
        setValue(e);
      }}
      value={value}
    />
  );

  const content = shouldShowEmptyState ? (
    <EmptyState stateType="events" />
  ) : items && Array.from(items).length < 1 ? (
    NoSearchResults(value)
  ) : (
    <ListBox
      {...props}
      aria-label="events selector"
      className="p-2 pt-0"
      items={items && Array.from(items)}
      selectionMode="single">
      {(item) => (
        <Item id={item.id} textValue={item.name || ''}>
          {item.name}
        </Item>
      )}
    </ListBox>
  );

  return { searchField, content };
};

function ButtonTrigger({
  setIsOpen,
  selectedEvent,
  isLoading,
  eventAttendees,
}: {
  setIsOpen: (isOpen: boolean) => void;
  selectedEvent: string[];
  isLoading: boolean;
  eventAttendees: { attendees: number; eventName: string } | undefined;
}) {
  return (
    <Pressable
      aria-label="events selector"
      className="rac-pressed:bg-grey-50 w-full rounded-lg bg-white p-4 focus:outline-none"
      onPress={() => setIsOpen}>
      <div className="flex w-full items-center justify-between">
        {selectedEvent.length > 0 ? (
          <div className="flex flex-col gap-y-2 text-left">
            <Text>{eventAttendees?.eventName}</Text>
            <Text className={isLoading ? 'text-grey-400' : 'text-grey-600'}>
              {isLoading ? 'Loading...' : `${eventAttendees?.attendees} attendees`}
            </Text>
          </div>
        ) : (
          <Text className="text-grey-600">Select an event...</Text>
        )}
        <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
          <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
        </span>
      </div>
    </Pressable>
  );
}
