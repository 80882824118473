import { useSearchParams } from '@remix-run/react';
import type { loader } from '~/routes/resources+/general-info-pages+/$id.edit';
import { useResourceData } from '~/utils/http';
import { GlobalSearchParam } from '~/utils/search-params';
import GeneralInfoPageForm from './general-info-page-form';

export function EditGeneralInfoPageForm() {
  const [searchParams] = useSearchParams();

  const generalInfoPageId = searchParams.get(GlobalSearchParam.GeneralInfoPageId);
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);

  const { data } = useResourceData<typeof loader>(
    `resources/general-info-pages/${generalInfoPageId}/edit?community-id=${communityId}`,
  );

  return (
    <GeneralInfoPageForm
      initialValues={data?.generalInfoPage}
      key={data?.generalInfoPage.id}
      title="Edit page"
    />
  );
}
