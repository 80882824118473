import { useSearchParams } from '@remix-run/react';
import { Icon, Popover, Pressable, Item, ListBox, Text } from '@venncity/block';
import { listBoxTriggerIcon } from '@venncity/block/electrons/list-box';
import clsx from 'clsx';
import React from 'react';
import { Select, SelectValue } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { useAppLoaderData } from '~/utils/common';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';

export function CommunityComboboxSelector({ className }: { className?: string }) {
  const appLoaderData = useAppLoaderData();

  const [urlSearchParams] = useSearchParams();

  const [selectOpen, setSelectOpen] = React.useState(false);

  const searchNavigate = useSearchNavigate();
  const communityId = urlSearchParams.get(GlobalSearchParam.CommunityId);

  return (
    <div className={twMerge(className, 'space-y-1')}>
      <Text weight="bold">*Community</Text>
      <div
        className={clsx(
          'border-grey-500 w-full overflow-hidden rounded-lg border focus:outline-none',
          selectOpen && 'border-grey-600',
        )}>
        <Select
          className="rac-open:bg-grey-100 w-full px-4"
          name="community-id"
          onOpenChange={(isOpen) => {
            setSelectOpen(isOpen);
          }}
          onSelectionChange={(value) => {
            searchNavigate({ communityId: value?.toString() });
          }}
          placeholder={'Community'}
          selectedKey={communityId || appLoaderData.selectedCommunity.id}>
          <Pressable className="w-full rounded-lg py-4 focus:outline-none">
            <div className="flex w-full items-center justify-between">
              <SelectValue className="rac-placeholder-shown:text-grey-600" />
              <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
                <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
              </span>
            </div>
          </Pressable>
          <Popover offset={2} placement="bottom end">
            <ListBox items={appLoaderData.user.managedCommunities}>
              {(community) => <Item key={community.id}>{community.displayName}</Item>}
            </ListBox>
          </Popover>
        </Select>
      </div>
    </div>
  );
}
