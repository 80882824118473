import { useSearchParams } from '@remix-run/react';
import type { loader } from '~/routes/resources+/notifications+/$id.edit';
import { useResourceData } from '~/utils/http';
import NotificationFormDialog from './notification-form-dialog';

export function EditNotificationDialog() {
  const [searchParams] = useSearchParams();
  const notificationId = searchParams.get('notification-id');

  const { data } = useResourceData<typeof loader>(
    `/resources/notifications/${notificationId}/edit`,
  );

  return (
    <NotificationFormDialog
      emailSettings={data?.emailSettings}
      initialValues={data?.notification}
      notificationId={notificationId || undefined}
      title="Edit update"
      withActionMenu
    />
  );
}
