import { Resize } from '@cloudinary/url-gen/actions';
import { useSearchParams } from '@remix-run/react';
import { Text, Icon, Dialog } from '@venncity/block';
import { Button, Divider, Spin, Avatar } from '@venncity/venn-ds';
import { DiscussionFormTabs } from '~/dto/discussions-form-dto';
import { cloudinary } from '~/lib/cloudinary';
import type { loader } from '~/routes/resources+/discussions+/$id.preview';
import { useResourceData } from '~/utils/http';
import { useSearchNavigate } from '~/utils/search-params';

export function DiscussionPreview() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  const discussionId = searchParams.get('discussion-id');
  const { data } = useResourceData<typeof loader>(`resources/discussions/${discussionId}/preview`);

  return (
    <Dialog>
      <Dialog.Header showCloseButton={false} title="Discussion preview" />
      <Dialog.Body className="pb-0">
        {data ? (
          <div className="bg-grey-900 rounded-2xl p-12">
            <div className="bg-grey-900 p-120 rounded-2xl">
              <div className="relative mx-auto mt-4 max-h-[42rem] min-h-[12rem] w-full self-center overflow-hidden rounded-2xl border-[4px] border-[#63605D] bg-[#F8F7F4] p-4">
                <div className="inset-0 z-0 w-full bg-stone-100">
                  {data?.imagePublicId ? (
                    <img
                      alt="discussion image"
                      className="inset-0 z-0 h-[350px] h-full w-full rounded-xl object-cover  object-center"
                      src={cloudinary
                        .image(data?.imagePublicId)
                        .resize(Resize.scale().width(700))
                        .toURL()}
                    />
                  ) : null}
                </div>
                <div
                  className={`flex w-full flex-col justify-between ${
                    data?.imagePublicId ? 'mt-[-30px]' : ''
                  }`}>
                  <div className="flex flex-col items-center justify-between md:py-2">
                    <Avatar
                      size="large"
                      src={data.ownerImage} // The profile owner's photo is not stored in the cloudinery but in Amazon
                      username={data.ownerDisplayName}
                    />
                    <Text variant="p5" weight="medium">
                      {data.ownerDisplayName}
                    </Text>
                    <Text className="text-grey-600" variant="p6">
                      {data.timeSince}
                    </Text>
                  </div>
                  <Text className="line-clamp-5 break-all text-center" variant="p5" weight="medium">
                    {data.text}
                  </Text>
                  <footer className="flex place-items-center justify-between pb-4 pt-8">
                    <Icon className="mr-2" name={'heart'} />
                    <Button className={'rounded-xl'}>Reply</Button>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Spin />
          </div>
        )}
      </Dialog.Body>
      <div className="px-8">
        <Divider />
      </div>
      <Dialog.Footer className="justify-start">
        <Button
          icon={<Icon name="chevron-left" />}
          onClick={() => {
            searchNavigate({ view: DiscussionFormTabs.Form });
          }}>
          Edit
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
