import { useSearchParams } from '@remix-run/react';
import { useQuery } from '@tanstack/react-query';
import {
  BottomSheet,
  DialogTrigger,
  Icon,
  Popover,
  Pressable,
  Text,
  GridList,
  SearchField,
} from '@venncity/block';
import { listBoxTriggerIcon } from '@venncity/block/electrons/list-box';
import { Button } from '@venncity/venn-ds';
import axios from 'axios';
import clsx from 'clsx';
import { debounce } from 'lodash-es';
import React from 'react';
import type { GridListProps } from 'react-aria-components';
import { useAsyncList } from 'react-stately';
import { ContractStatus } from '~/codegen/graphql';
import type { BuildingResourceDTO } from '~/dto/building-resource-dto';
import { NoSearchResults } from '../helpers/empty-search-results';
import { EmptyState } from '../helpers/empty-state';
import { useIsMobile } from '../hooks/useIsMobile';

export function BuildingsSelector({
  defaultSelectedKey,
}: {
  defaultSelectedKey: string[] | undefined;
}) {
  const [buildingsSelected, setBuildingsSelected] = React.useState<string[]>(
    defaultSelectedKey || [],
  );

  const [newSelected, setNewSelected] = React.useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const communityId = searchParams.get('community-id') || '';

  const isMobile = useIsMobile();
  const itemsCount = isMobile ? 50 : 10;

  const list = useAsyncList<BuildingResourceDTO>({
    async load({ filterText }) {
      const { data } = await axios.get(
        `/resources/permitted-buildings?community-id=${communityId}`,
        {
          params: { q: filterText, itemsCount },
        },
      );
      return { items: data || [] };
    },
  });

  const shouldShowEmptyState = Boolean(
    list.items.length === 0 && !list.filterText && !list.isLoading,
  );

  const getResidentsCount = React.useCallback(async () => {
    const { data } = await axios.get<{ count: number }>(
      '/resources/notifications/residents/residents/count',
      {
        params: {
          buildingIds: buildingsSelected,
          leaseStatusIn: [ContractStatus.ACTIVE, ContractStatus.FUTURE],
        },
      },
    );

    return data.count;
  }, [buildingsSelected]);

  const { data: residentsCount, isLoading } = useQuery({
    queryKey: ['residents-count', buildingsSelected],
    queryFn: getResidentsCount,
    enabled: buildingsSelected.length > 0,
  });

  const { searchField, content } = useBuildingsList({
    defaultSelectedKeys: buildingsSelected,
    items: list.items,
    onInputChange: debounce(list.setFilterText, 400, {
      maxWait: 1000,
      leading: false,
      trailing: true,
    }),
    onSelectionChange: (selection) => setNewSelected(Array.from(selection).map(String)),
    isMobile,
    shouldShowEmptyState,
  });

  return (
    <div>
      {buildingsSelected?.map((key) => (
        <input key={key} name="buildings" type="hidden" value={key.toString()} />
      ))}
      <div className="hidden lg:block">
        <DialogTrigger
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setBuildingsSelected(newSelected);
            }
          }}>
          <ButtonTrigger
            buildingsSelected={buildingsSelected}
            isLoading={isLoading}
            residentsCount={residentsCount}
          />
          <Popover className="w-[460px]" offset={2}>
            <div className="space-y-2 overflow-y-auto">
              <div className="sticky top-0 w-full bg-white p-3">{searchField}</div>
              <div className="p-3 pt-0">{content}</div>
            </div>
          </Popover>
        </DialogTrigger>
      </div>
      <div className="lg:hidden">
        <DialogTrigger>
          <ButtonTrigger
            buildingsSelected={buildingsSelected}
            isLoading={isLoading}
            residentsCount={residentsCount}
          />
          <BottomSheet className="h-[95vh]" isDismissable={false}>
            <BottomSheet.Header className="border-transparent pt-4">
              {searchField}
            </BottomSheet.Header>
            <BottomSheet.Body>{content}</BottomSheet.Body>

            <BottomSheet.Footer>
              {({ close }) => (
                <div className="flex h-full w-full gap-4 bg-white">
                  <Button
                    className="flex-1 [&_span]:flex [&_span]:w-full [&_span]:justify-center"
                    onClick={close}
                    variant="text">
                    Cancel
                  </Button>
                  <Button
                    className="flex-1 [&_span]:flex [&_span]:w-full [&_span]:justify-center"
                    disabled={newSelected.length === 0 && buildingsSelected.length === 0}
                    onClick={() => {
                      setBuildingsSelected(newSelected);
                      close();
                    }}>
                    Save
                  </Button>
                </div>
              )}
            </BottomSheet.Footer>
          </BottomSheet>
        </DialogTrigger>
      </div>
    </div>
  );
}

const useBuildingsList = ({
  onInputChange,
  shouldShowEmptyState,
  items,
  isMobile,
  ...props
}: GridListProps<BuildingResourceDTO> & {
  onInputChange: (value: string) => void;
  shouldShowEmptyState?: boolean;
  isMobile: boolean;
}) => {
  const [value, setValue] = React.useState('');

  const searchField = (
    <SearchField
      aria-label="buildings search field"
      autoFocus
      className={isMobile ? '' : 'bg-white'}
      label="Search a building..."
      onChange={(e) => {
        setValue(e);
        onInputChange(e);
      }}
      value={value}
    />
  );

  const content = shouldShowEmptyState ? (
    <EmptyState stateType="buildings" />
  ) : items && Array.from(items).length < 1 ? (
    NoSearchResults(value)
  ) : (
    <GridList
      {...props}
      aria-label="buildings selector"
      className={clsx('max-h-fit', isMobile ? 'pt-0' : '')}
      items={(items && Array.from(items)) || []}
      selectionMode="multiple">
      {(item) => (
        <GridList.Item id={item.id} key={item.id} textValue={item.name || ''}>
          {item.name}
        </GridList.Item>
      )}
    </GridList>
  );

  return { searchField, content };
};

function ButtonTrigger({
  buildingsSelected,
  residentsCount,
  isLoading,
}: {
  buildingsSelected: string[];
  residentsCount: number | undefined;
  isLoading: boolean;
}) {
  return (
    <Pressable
      aria-label="buildings selector"
      className="rac-pressed:bg-grey-50 w-full rounded-lg bg-white p-4 focus:outline-none">
      <div className="flex w-full items-center justify-between">
        {buildingsSelected.length > 0 ? (
          <div className="flex flex-col gap-y-2 text-left">
            <Text>{buildingsSelected.length} Buildings</Text>
            <Text className={clsx(isLoading ? 'text-grey-400' : 'text-grey-600')}>
              {isLoading ? 'Loading...' : `${residentsCount} residents`}
            </Text>
          </div>
        ) : (
          <Text className="text-grey-600">Select buildings...</Text>
        )}
        <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
          <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
        </span>
      </div>
    </Pressable>
  );
}
