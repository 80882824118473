import { cva } from 'class-variance-authority';

export const fieldLabel = cva('mb-2 block text-sm', {
  variants: {
    weightLabel: {
      normal: 'font-light',
      bold: 'font-bold',
    },
  },
  defaultVariants: {
    weightLabel: 'normal',
  },
});
