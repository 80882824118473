import { useSearchParams } from '@remix-run/react';
import {
  Icon,
  Pressable,
  Text,
  Tooltip,
  Item,
  Popover,
  BottomSheet,
  SearchField,
  IconButton,
  FieldError,
} from '@venncity/block';
import { ClickableTooltipTrigger } from '@venncity/block/atoms/tooltip/tooltip';
import { listBoxTriggerIcon } from '@venncity/block/electrons/list-box';
import { Switch, Divider } from '@venncity/venn-ds';
import axios from 'axios';
import clsx from 'clsx';
import { debounce, lowerCase, upperFirst, isFunction, isEmpty } from 'lodash-es';
import React from 'react';
import type { PressEvent } from 'react-aria';
import type { GridListProps } from 'react-aria-components';
import { Group, Select, SelectValue, ListBox, DialogTrigger } from 'react-aria-components';
import { useAsyncList } from 'react-stately';
import type { EventResourceDTO } from '~/dto/event-resource-dto';
import { FormSearchParams, ctaTextByApplicationPage } from '~/dto/notifications-form-dto';
import type { PlaceResourceDTO } from '~/dto/place-resource-dto';
import type { ServiceResourceDTO } from '~/dto/service-resource-dto';
import type { UserResourceDTO } from '~/dto/user-resource-dto';
import { enumApplicationPage } from '~/genql';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';
import { EmptyState } from './helpers/empty-state';
import { useIsMobile } from './hooks/useIsMobile';

const deepLinkTypeSelection = [
  { name: enumApplicationPage.EVENT, id: enumApplicationPage.EVENT },
  { name: 'A specific profile', id: enumApplicationPage.USER },
  { name: `Recipient profile`, id: enumApplicationPage.PROFILE },
  { name: 'Invoices', id: enumApplicationPage.INVOICES },
  { name: 'Service requests', id: enumApplicationPage.MY_TICKETS },
  { name: 'Market service', id: enumApplicationPage.SERVICE },
  { name: 'A place', id: enumApplicationPage.PLACE },
] as const;

const selectButtonType = [
  {
    buttonValue: ctaTextByApplicationPage[enumApplicationPage.INVOICES],
    id: enumApplicationPage.INVOICES,
  },
  {
    buttonValue: ctaTextByApplicationPage[enumApplicationPage.MY_TICKETS],
    id: enumApplicationPage.MY_TICKETS,
  },
  {
    buttonValue: ctaTextByApplicationPage[enumApplicationPage.PROFILE],
    id: enumApplicationPage.PROFILE,
  },
] as const;

const subSelectButtonType = [
  {
    page: enumApplicationPage.PLACE,
    buttonValue: ctaTextByApplicationPage[enumApplicationPage.PLACE],
    resource: 'places',
    placeHolder: 'Select a place',
  },
  {
    page: enumApplicationPage.EVENT,
    buttonValue: ctaTextByApplicationPage[enumApplicationPage.EVENT],
    resource: 'events',
    placeHolder: 'Select an event',
  },
  {
    page: enumApplicationPage.SERVICE,
    buttonValue: ctaTextByApplicationPage[enumApplicationPage.SERVICE],
    resource: 'services',
    placeHolder: 'Select a service',
  },
  {
    page: enumApplicationPage.USER,
    buttonValue: ctaTextByApplicationPage[enumApplicationPage.USER],
    resource: 'users',
    placeHolder: 'Select a user',
  },
] as const;

type ResourceType = PlaceResourceDTO | EventResourceDTO | ServiceResourceDTO | UserResourceDTO;
export type ApplicationPageType = (typeof enumApplicationPage)[keyof typeof enumApplicationPage];

export function DeepLink({
  hasDeepLinkEnabled,
  applicationPage,
  entityId,
  errorMessage,
}: {
  hasDeepLinkEnabled: boolean;
  applicationPage: ApplicationPageType | undefined;
  entityId: string;
  errorMessage: string | undefined | string[];
}) {
  const searchNavigate = useSearchNavigate();
  const [deepLinkType, setDeepLinkType] = React.useState<ApplicationPageType | undefined | null>(
    applicationPage || null,
  );

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const [isSelectAppPageVisible, setIsSelectAppPageVisible] = React.useState(
    hasDeepLinkEnabled && !applicationPage,
  );

  const [isToggled, setIsToggled] = React.useState(hasDeepLinkEnabled);

  const simpleDeeplinkTypes: ApplicationPageType[] = [
    enumApplicationPage.INVOICES,
    enumApplicationPage.PROFILE,
    enumApplicationPage.MY_TICKETS,
    enumApplicationPage.EVENTS_PAGE,
  ];
  const isDeepLinkTypeIsSingleSelect = deepLinkType && simpleDeeplinkTypes.includes(deepLinkType);

  const deepLinkName = deepLinkTypeSelection.find((item) => item.id === deepLinkType)?.name || '';

  return (
    <>
      <Group
        className={clsx(
          'border-grey-500 flex flex-col rounded-lg border',
          errorMessage && 'border-negative-400',
        )}>
        <div className="relative flex p-4">
          <div className="flex  w-full items-center">
            <Text className="mr-1">Add a button</Text>
            <ClickableTooltipTrigger
              buttonComponent={<Icon className="h-4 w-4" name="help-circle" />}
              isOpen={isTooltipOpen}
              setIsOpen={setIsTooltipOpen}>
              <Tooltip className="ml-1 mt-1 text-start" placement="top left" variant="dark">
                <Text slot="description" variant="p5" weight="medium">
                  Add a button to direct residents to a specific feature or screen in the app
                </Text>
              </Tooltip>
            </ClickableTooltipTrigger>
          </div>

          <Switch
            checked={isToggled}
            id="hasDeeplink"
            key={`has-deep-link-${Number(hasDeepLinkEnabled)}`}
            onChange={(value) => {
              setIsToggled(value);
              setIsSelectAppPageVisible(value);
              setDeepLinkType(null);
              searchNavigate({
                [FormSearchParams.HasDeepLink]: value || undefined,
                [FormSearchParams.ApplicationPage]: undefined,
                [FormSearchParams.DeepLinkId]: undefined,
              });
            }}
          />
        </div>
        {isToggled && (
          <div className="px-4">
            <Divider marginless />
          </div>
        )}
        {isToggled && (
          <div className={'flex  w-full flex-col outline-none'}>
            <Select
              aria-label="Deep link type"
              className={clsx(
                '[data-placeholder]:text-grey-600 rac-open:bg-100 w-full',
                !isSelectAppPageVisible && 'hidden',
              )}
              defaultSelectedKey={applicationPage}
              name="deep-link-type"
              onSelectionChange={(value) => {
                setDeepLinkType(value as ApplicationPageType);
                searchNavigate({ applicationPage: value.toString(), deepLinkId: undefined });
              }}
              placeholder="Select app page..."
              selectedKey={deepLinkType}>
              <Pressable className="rac-pressed:bg-grey-100 w-full rounded-lg bg-white outline-none">
                <div className="flex w-full items-center justify-between p-4">
                  <div>
                    <SelectValue className="gap-y-2 pr-0 text-left" />
                  </div>
                  <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
                    <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
                  </span>
                </div>
              </Pressable>
              <Popover
                className="w-56 rounded-lg px-1 py-2 outline-none"
                offset={1}
                placement="top end">
                <ListBox
                  className="outline-none"
                  defaultSelectedKeys={applicationPage}
                  items={deepLinkTypeSelection}>
                  {({ id, name }) => (
                    <Item id={id} key={id} textValue={upperFirst(lowerCase(name))}>
                      {upperFirst(lowerCase(name))}
                    </Item>
                  )}
                </ListBox>
              </Popover>
              {subSelectButtonType.map(
                ({ page }) =>
                  deepLinkType === enumApplicationPage[page] && (
                    <>
                      <div className="px-4">
                        <Divider marginless />
                      </div>
                    </>
                  ),
              )}
            </Select>
            {!isSelectAppPageVisible && isDeepLinkTypeIsSingleSelect && (
              <Pressable className="rac-pressed:bg-grey-100 w-full rounded-lg bg-white outline-none">
                <div className="flex w-full items-center justify-between p-4">
                  <div className="gap-y-2 pr-0 text-left">{deepLinkName}</div>
                </div>
              </Pressable>
            )}
            {isToggled &&
              selectButtonType.map(
                ({ id, buttonValue }) =>
                  deepLinkType === enumApplicationPage[id] && (
                    <>
                      <div
                        aria-label="example-button"
                        className="border-grey-500 bg-grey-100 mb-4 ml-4 mr-12 flex h-10 items-center gap-2 rounded-lg border-[1px] px-3 py-2">
                        <Icon className="h-[14px] w-[14px]" name="link-icon" />
                        <Text className="text-grey-800 flex flex-1 justify-start" variant={'p5'}>
                          {buttonValue}
                        </Text>
                        <IconButton
                          className="ring-grey-300 flex h-6 w-6 items-center justify-center rounded-full p-[9px] ring-1"
                          icon="close"
                          iconClassName="w-[16px] h-[16px]"
                          onPress={() => {
                            setDeepLinkType(null);
                            searchNavigate({ applicationPage: undefined });
                            if (isDeepLinkTypeIsSingleSelect) {
                              setIsSelectAppPageVisible(true);
                            }
                          }}
                          variant="light"
                        />
                      </div>
                    </>
                  ),
              )}
          </div>
        )}
        {isToggled &&
          subSelectButtonType.map(
            ({ resource, page }) =>
              deepLinkType === enumApplicationPage[page] && (
                <DeepLinkComboBox
                  defaultSelectedKey={entityId}
                  key={deepLinkType}
                  name="deep-link-id"
                  onSelectionChange={(val) => {
                    if (val === null) {
                      setDeepLinkType(val);
                      searchNavigate({ deepLinkId: undefined });
                      setIsSelectAppPageVisible(true);
                    } else {
                      setIsSelectAppPageVisible(false);
                    }
                  }}
                  resourceType={resource}
                />
              ),
          )}
      </Group>
      {errorMessage && <FieldError aria-label="deep-link">{errorMessage}</FieldError>}
    </>
  );
}

function DeepLinkComboBox({
  resourceType,
  onSelectionChange,
  defaultSelectedKey = '',
  name,
}: {
  resourceType: string;
  onSelectionChange?: (value: ResourceType[] | null) => void;
  defaultSelectedKey?: string;
  name?: string;
}) {
  const isMobile = useIsMobile();
  const itemsCount = isMobile ? 50 : 10;
  const [searchParams] = useSearchParams();
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);

  const list = useAsyncList<ResourceType>({
    async load({ filterText }) {
      const { data } = await axios.get(`/resources/${resourceType}?community-id=${communityId}`, {
        params: { q: filterText, include: defaultSelectedKey || undefined, itemsCount },
      });
      return { items: data || [] };
    },
  });

  const [selectedKey, setSelectedKey] = React.useState<string[]>(
    defaultSelectedKey ? list.items.map((item) => JSON.stringify(item)) : [],
  );

  const selectedItem = selectedKey?.map((item) => JSON.parse(item.toString()) as ResourceType);

  const [isOpen, setIsOpen] = React.useState(false);
  const [BottomSheetIsOpen, setBottomSheetIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (defaultSelectedKey) {
      setSelectedKey(list.items.map((item) => JSON.stringify(item)));
      if (isFunction(onSelectionChange) && list.items.length > 0) {
        onSelectionChange(list.items);
      }
    }
  }, [list.items]);

  const shouldShowEmptyState = Boolean(
    list.items.length === 0 && !list.filterText && !list.isLoading,
  );

  const { searchField, content } = useCustomListBox({
    defaultSelectedKeys: selectedKey,
    items: list.items,
    onInputChange: debounce(list.setFilterText, 400, {
      maxWait: 1000,
      leading: false,
      trailing: true,
    }),
    onSelectionChange: (selection) => {
      setSelectedKey(Array.from(selection).map(String));
      isMobile ? setBottomSheetIsOpen(false) : setIsOpen(false);

      if (isFunction(onSelectionChange)) {
        onSelectionChange(JSON.parse(Array.from(selection)[0] as string));
      }
    },
    resourceType,
    shouldShowEmptyState,
    isMobile,
  });

  return (
    <div aria-label="deep-link">
      {selectedItem && <input name={name} type="hidden" value={selectedItem[0]?.id} />}
      <div className="hidden lg:block">
        <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
          {subSelectButtonType.map(
            ({ resource, buttonValue, placeHolder }) =>
              resource === resourceType && (
                <ButtonTrigger
                  buttonValue={buttonValue}
                  isLoading={(defaultSelectedKey && isEmpty(list.items)) || false}
                  key={list.items.toString()}
                  onCancellation={() => {
                    if (isFunction(onSelectionChange)) {
                      onSelectionChange(null);
                    }
                  }}
                  onPress={() => setIsOpen(!isOpen)}
                  placeholder={placeHolder}
                  selectedValue={selectedItem[0]?.name}
                />
              ),
          )}
          <Popover className="shadow-10 w-[465px] outline-none" offset={2} placement="top end">
            <div className="space-y-2 overflow-y-auto">
              <div className="sticky top-0 w-full bg-white p-3">{searchField}</div>
              <div className="p-3 pt-0">{content}</div>
            </div>
          </Popover>
        </DialogTrigger>
      </div>
      <div className="lg:hidden">
        <DialogTrigger isOpen={BottomSheetIsOpen} onOpenChange={setBottomSheetIsOpen}>
          {subSelectButtonType.map(
            ({ resource, buttonValue, placeHolder }) =>
              resource === resourceType && (
                <ButtonTrigger
                  buttonValue={buttonValue}
                  isLoading={(defaultSelectedKey && isEmpty(list.items)) || false}
                  key={list.items.toString()}
                  onCancellation={() => {
                    if (isFunction(onSelectionChange)) {
                      onSelectionChange(null);
                    }
                  }}
                  onPress={() => setIsOpen(!isOpen)}
                  placeholder={placeHolder}
                  selectedValue={selectedItem[0]?.name}
                />
              ),
          )}
          <BottomSheet className="h-[95vh]">
            <BottomSheet.Header className="border-transparent">{searchField}</BottomSheet.Header>
            <BottomSheet.Body>{content}</BottomSheet.Body>
          </BottomSheet>
        </DialogTrigger>
      </div>
    </div>
  );
}

const searchTextLabel = (resourceType: string) => {
  switch (resourceType) {
    case 'events':
      return 'Search an event...';
    case 'users':
      return 'Search a specific profile...';
    case 'services':
      return 'Search for a market service...';
    case 'places':
      return 'Search for a place...';
    default:
      return 'Search...';
  }
};

const useCustomListBox = ({
  onInputChange,
  items,
  shouldShowEmptyState,
  resourceType,
  isMobile,
  ...props
}: GridListProps<ResourceType> & {
  onInputChange: (value: string) => void;
  resourceType: string;
  shouldShowEmptyState?: boolean;
  isMobile: boolean;
}) => {
  const [value, setValue] = React.useState('');

  const searchField = (
    <SearchField
      aria-label="search field"
      autoFocus
      className={isMobile ? '' : 'bg-white'}
      label={searchTextLabel(resourceType)}
      onChange={(e) => {
        onInputChange(e);
        setValue(e);
      }}
      value={value}
    />
  );

  const content = shouldShowEmptyState ? (
    <EmptyState stateType={resourceType} />
  ) : items && Array.from(items).length < 1 ? (
    NoSearchResults(value)
  ) : (
    <>
      <ListBox
        aria-label={'deep-link-list'}
        className={isMobile ? 'p-2 pt-0' : 'h-full overflow-y-auto'}
        {...props}
        items={Array.from(items ?? [])}
        selectionMode="single">
        {(item) => (
          <Item id={JSON.stringify(item)} textValue={item.name || ''}>
            {item.name}
          </Item>
        )}
      </ListBox>
    </>
  );

  return { searchField, content };
};

const ButtonTrigger = ({
  selectedValue = '',
  buttonValue,
  placeholder,
  onPress,
  onCancellation,
  isLoading,
}: {
  selectedValue: string;
  buttonValue: string;
  placeholder?: string;
  isLoading?: boolean;
  onPress?: (e: PressEvent) => void;
  onCancellation?: () => void;
}) => {
  return (
    <div className="flex flex-col">
      {isLoading ? (
        <DeepLinkSkeleton />
      ) : (
        <div>
          <Pressable
            className="rac-pressed:bg-grey-100 h-full w-full rounded-lg bg-white outline-none"
            onPress={onPress}>
            <div className="flex flex-col gap-y-2 p-4">
              <div className="flex h-6 w-full items-center justify-between">
                {selectedValue.length > 0 ? (
                  <div className="flex flex-col gap-y-2 text-left">
                    <Text>{selectedValue}</Text>
                  </div>
                ) : (
                  <Text className="text-grey-600">{placeholder}</Text>
                )}
                <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
                  <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
                </span>
              </div>
            </div>
          </Pressable>
          {selectedValue.length > 0 && (
            <div
              aria-label="example-button"
              className="border-grey-500 bg-grey-100 mb-4 ml-4  mr-12 flex h-10  items-center gap-2 rounded-lg border-[1px] px-3 py-2">
              <Icon className="h-[14px] w-[14px]" name="link-icon" />{' '}
              <Text className="text-grey-800 flex flex-1 justify-start" variant={'p5'}>
                {buttonValue}
              </Text>
              <IconButton
                className="ring-grey-300 flex h-6 w-6 items-center justify-center rounded-full p-[9px] ring-1"
                icon="close"
                iconClassName="w-[16px] h-[16px]"
                onPress={onCancellation}
                size={'xs'}
                variant={'light'}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function NoSearchResults(value: string) {
  return (
    <div className="text-grey-900 flex flex-col gap-6 p-6 text-center">
      <Text>
        No results found for <b>"{value}"</b>
      </Text>
      <Text>Try adjusting your search to find what you're looking for</Text>
    </div>
  );
}

function DeepLinkSkeleton() {
  return (
    <div className="flex w-full flex-col ">
      <div className="gap-y-2 p-4">
        <div className="bg-grey-200 h-6 w-full animate-pulse gap-y-2 rounded-lg" />
        <div className="bg-grey-200 mr-8 mt-4 h-10 animate-pulse rounded-lg" />
      </div>
    </div>
  );
}
