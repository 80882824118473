import { Resize } from '@cloudinary/url-gen/actions';
import { useSearchParams } from '@remix-run/react';
import { Heading, Icon, Dialog } from '@venncity/block';
import { Spin, Button, Divider } from '@venncity/venn-ds';
import invariant from 'tiny-invariant';
import { FeaturedSlideFormTabs } from '~/dto/featured-slide-dto';
import { cloudinary } from '~/lib/cloudinary';
import type { loader } from '~/routes/resources+/featured-slides+/$id.preview';
import { useResourceData } from '~/utils/http';
import { useSearchNavigate } from '~/utils/search-params';

export function FeaturedSlidePreview({ communityId }: { communityId?: string | null }) {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  invariant(communityId, 'Community id must be defined');
  const featuredSlideId = searchParams.get('featured-slide-id');

  const { data } = useResourceData<typeof loader>(
    `resources/featured-slides/${featuredSlideId}/preview?community-id=${communityId}`,
  );

  return (
    <Dialog>
      <Dialog.Header showCloseButton={false} title="Post preview" />
      <Dialog.Body className="pb-0">
        {data ? (
          <div className="bg-grey-900 rounded-2xl p-12">
            <div className="bg-grey-900 p-120 rounded-2xl">
              <div className="relative m-0 mx-auto my-auto min-h-[464px] w-[328px] self-center overflow-hidden rounded-2xl border-[4px] border-[#63605D] bg-[#F8F7F4] p-6 pt-[17rem]">
                <div className="absolute inset-0 z-0 mx-auto my-auto min-h-[460px] w-[324px] bg-[#c6c6c3]">
                  {data?.image ? (
                    <img
                      alt="post"
                      className="absolute inset-0 z-0 h-full w-full object-cover object-center"
                      src={cloudinary.image(data?.image).resize(Resize.scale().width(700)).toURL()}
                    />
                  ) : (
                    <div className="h-full w-full text-black"></div>
                  )}
                </div>
                <div className="shadow-2 relative mt-4 flex flex-col justify-center overflow-hidden rounded-2xl bg-[#f8f7f4d1] p-6 pt-10">
                  <Heading className="break-words pb-4 text-2xl">
                    {data?.description || 'Your post name'}
                  </Heading>
                  <div className="flex">
                    <Icon className="mr-2" name={'star'} /> Read More
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Spin />
          </div>
        )}
      </Dialog.Body>
      <div className="px-8">
        <Divider />
      </div>
      <Dialog.Footer className="justify-start">
        <Button
          onClick={() => {
            searchNavigate({ view: FeaturedSlideFormTabs.Form });
          }}>
          <Icon name="chevron-left" /> Edit
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
