export default {
    "scalars": [
        4,
        6,
        7,
        9,
        16,
        19,
        26,
        37,
        39,
        44,
        45,
        46,
        50,
        52,
        55,
        64,
        75,
        79,
        81,
        86,
        89,
        92,
        100,
        105,
        112,
        118,
        123,
        129,
        138,
        143,
        144,
        150,
        151,
        152,
        153,
        158,
        162,
        165,
        169,
        173,
        186,
        187,
        190,
        198,
        203,
        204,
        209,
        210,
        214,
        217,
        218,
        221,
        232,
        233,
        235,
        237,
        248,
        250,
        253,
        257,
        260,
        273,
        274,
        275,
        283,
        293,
        301,
        313,
        318,
        319,
        320,
        321,
        324,
        327,
        335,
        338,
        342,
        343,
        349,
        355,
        367,
        368,
        374,
        375,
        379,
        389,
        397,
        410,
        416,
        417,
        425,
        436,
        450,
        454,
        459,
        460,
        466,
        471,
        473,
        474,
        478,
        479,
        480,
        488,
        499,
        501,
        515,
        516,
        532,
        537,
        539,
        543,
        544,
        559,
        568,
        572,
        579,
        580,
        583,
        586,
        587,
        600,
        605,
        613,
        616,
        620,
        621,
        635,
        638,
        653,
        654,
        655,
        658,
        659,
        661,
        667,
        672,
        673,
        676,
        681,
        685,
        686,
        687,
        688,
        697
    ],
    "types": {
        "Adapter": {
            "description": [
                559
            ],
            "endpoint": [
                559
            ],
            "id": [
                293
            ],
            "settings": [
                2
            ],
            "type": [
                4
            ],
            "__typename": [
                559
            ]
        },
        "AdapterDataInput": {
            "description": [
                559
            ],
            "endpoint": [
                559
            ],
            "settings": [
                3
            ],
            "type": [
                4
            ],
            "__typename": [
                559
            ]
        },
        "AdapterSetting": {
            "IsSecure": [
                129
            ],
            "id": [
                293
            ],
            "key": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AdapterSettingInput": {
            "IsSecure": [
                129
            ],
            "key": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AdapterType": {},
        "Address": {
            "addressType": [
                6
            ],
            "city": [
                559
            ],
            "coordinate": [
                188
            ],
            "country": [
                559
            ],
            "entrance": [
                559
            ],
            "googlePlaceID": [
                559
            ],
            "id": [
                293
            ],
            "isDefault": [
                129
            ],
            "state": [
                559
            ],
            "streetName": [
                559
            ],
            "streetNumber": [
                559
            ],
            "zipcode": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AddressType": {},
        "AdmissionFeeType": {},
        "AmenitiesList": {
            "items": [
                11
            ],
            "pager": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "AmenitiesOrderBy": {},
        "AmenitiesWhereInput": {
            "accessibleToBuildingIds": [
                293
            ],
            "id": [
                293
            ],
            "isActive": [
                129
            ],
            "isReservable": [
                129
            ],
            "location": [
                559
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Amenity": {
            "accessibleToBuildingIds": [
                559
            ],
            "accessibleToBuildings": [
                13
            ],
            "amenityCalendarSettings": [
                15
            ],
            "availableDates": [
                654,
                {
                    "where": [
                        12,
                        "AmenityAvailableDatesWhereInput!"
                    ]
                }
            ],
            "bookingOptions": [
                127,
                {
                    "where": [
                        128,
                        "BookingOptionsWhereInput!"
                    ]
                }
            ],
            "cancellationPolicy": [
                559
            ],
            "description": [
                559
            ],
            "firstAvailableDate": [
                654
            ],
            "id": [
                293
            ],
            "isActive": [
                129
            ],
            "isReservable": [
                129
            ],
            "lastAvailableDate": [
                654
            ],
            "location": [
                559
            ],
            "locationBuilding": [
                159
            ],
            "locationBuildingId": [
                293
            ],
            "managersToNotify": [
                293
            ],
            "media": [
                24
            ],
            "name": [
                559
            ],
            "pmsChargeCode": [
                559
            ],
            "pmsGlAccountNumber": [
                559
            ],
            "requireAgreement": [
                129
            ],
            "requireApproval": [
                129
            ],
            "stripeAccountId": [
                559
            ],
            "stripeProductId": [
                559
            ],
            "timezone": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityAvailableDatesWhereInput": {
            "durationInMinutes": [
                301
            ],
            "endDate": [
                654
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "AmenityBuilding": {
            "Building": [
                159
            ],
            "BuildingId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "AmenityCalendarCreateInput": {
            "amenityId": [
                293
            ],
            "checkIn": [
                559
            ],
            "checkOut": [
                559
            ],
            "openingHours": [
                28
            ],
            "reservationSettings": [
                42
            ],
            "type": [
                16
            ],
            "__typename": [
                559
            ]
        },
        "AmenityCalendarSettings": {
            "amenity": [
                11
            ],
            "amenityId": [
                293
            ],
            "checkIn": [
                559
            ],
            "checkOut": [
                559
            ],
            "id": [
                293
            ],
            "isActive": [
                129
            ],
            "openingHours": [
                27
            ],
            "reservationSettings": [
                41
            ],
            "type": [
                16
            ],
            "__typename": [
                559
            ]
        },
        "AmenityCalendarType": {},
        "AmenityCalendarUnavailabilitiesWhereInput": {
            "accessibleToBuildingIds": [
                293
            ],
            "amenityIds": [
                293
            ],
            "endDate": [
                654
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "AmenityCalendarUnavailability": {
            "amenityCalendar": [
                15
            ],
            "amenityCalendarId": [
                293
            ],
            "createdAt": [
                654
            ],
            "endDate": [
                654
            ],
            "id": [
                293
            ],
            "reason": [
                559
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "AmenityCalendarUnavailabilityOrderBy": {},
        "AmenityCalendarUpdateInput": {
            "reservationSettings": [
                43
            ],
            "__typename": [
                559
            ]
        },
        "AmenityCancelManyWhereInput": {
            "idIn": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "AmenityCancellationDenialInput": {
            "note": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityCreateInput": {
            "accessibleToBuildingIds": [
                293
            ],
            "cancellationPolicy": [
                559
            ],
            "description": [
                559
            ],
            "isActive": [
                129
            ],
            "isReservable": [
                129
            ],
            "location": [
                559
            ],
            "locationBuildingId": [
                293
            ],
            "managersToNotify": [
                293
            ],
            "media": [
                25
            ],
            "name": [
                559
            ],
            "pmsChargeCode": [
                559
            ],
            "pmsGlAccountNumber": [
                559
            ],
            "requireAgreement": [
                129
            ],
            "requireApproval": [
                129
            ],
            "stripeAccountId": [
                559
            ],
            "stripeProductId": [
                559
            ],
            "timezone": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityMedia": {
            "id": [
                293
            ],
            "type": [
                26
            ],
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityMediaCreateInput": {
            "type": [
                26
            ],
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityMediaType": {},
        "AmenityOpeningHours": {
            "closingTime": [
                559
            ],
            "dayOfWeek": [
                198
            ],
            "openingTime": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityOpeningHoursCreateInput": {
            "closingTime": [
                559
            ],
            "dayOfWeek": [
                198
            ],
            "openingTime": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservation": {
            "FeeAmount": [
                274
            ],
            "amenity": [
                11
            ],
            "amenityId": [
                293
            ],
            "amountPaid": [
                274
            ],
            "cancellationNote": [
                559
            ],
            "cancellationRequestNote": [
                559
            ],
            "cancellationRequestStatus": [
                173
            ],
            "createdAt": [
                654
            ],
            "denialReason": [
                559
            ],
            "endDate": [
                654
            ],
            "id": [
                293
            ],
            "paidAt": [
                654
            ],
            "paymentMethod": [
                397
            ],
            "paymentStatus": [
                39
            ],
            "pmsPaymentId": [
                559
            ],
            "pmsRefundId": [
                559
            ],
            "pmsReversalId": [
                559
            ],
            "refundAmount": [
                274
            ],
            "refundedAt": [
                654
            ],
            "reservationId": [
                559
            ],
            "reservationType": [
                46
            ],
            "startDate": [
                654
            ],
            "status": [
                44
            ],
            "statusUpdateNote": [
                559
            ],
            "timeLine": [
                45
            ],
            "unit": [
                656
            ],
            "unitId": [
                293
            ],
            "user": [
                662
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationCancelAndRefundInput": {
            "cancellationNote": [
                559
            ],
            "refundAmount": [
                274
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationCancelInput": {
            "cancellationNote": [
                559
            ],
            "refundId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationCreateInput": {
            "amenityId": [
                293
            ],
            "endDate": [
                654
            ],
            "requireApproval": [
                129
            ],
            "startDate": [
                654
            ],
            "unitId": [
                293
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationDenialInput": {
            "reason": [
                559
            ],
            "refundId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationOption": {
            "amenityCalendarId": [
                293
            ],
            "durationInMinutes": [
                301
            ],
            "id": [
                293
            ],
            "label": [
                559
            ],
            "price": [
                274
            ],
            "priceByPaymentMethod": [
                444
            ],
            "productName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationOptionCreateInput": {
            "durationInMinutes": [
                301
            ],
            "label": [
                559
            ],
            "price": [
                274
            ],
            "productName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationOptionUpdateInput": {
            "amenityCalendarId": [
                293
            ],
            "durationInMinutes": [
                301
            ],
            "label": [
                559
            ],
            "price": [
                274
            ],
            "productName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationOrderBy": {},
        "AmenityReservationPayInput": {
            "reservationId": [
                559
            ],
            "stripeCheckoutSessionId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationPaymentStatus": {},
        "AmenityReservationRequestCancelInput": {
            "cancellationRequestNote": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationSettings": {
            "amenityCalendarId": [
                293
            ],
            "maxReservationDaysInAdvance": [
                301
            ],
            "minReservationDaysInAdvance": [
                301
            ],
            "postReservationBufferMinutes": [
                301
            ],
            "reservationDurationOptions": [
                301
            ],
            "reservationOptions": [
                34
            ],
            "reservationQuotaFrequency": [
                459
            ],
            "reservationQuotaLimit": [
                301
            ],
            "reservationQuotaType": [
                460
            ],
            "slotCapacity": [
                301
            ],
            "slotDurationInMinutes": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationSettingsCreateInput": {
            "maxReservationDaysInAdvance": [
                301
            ],
            "minReservationDaysInAdvance": [
                301
            ],
            "postReservationBufferMinutes": [
                301
            ],
            "reservationDurationOptions": [
                301
            ],
            "reservationOptions": [
                35
            ],
            "reservationQuotaFrequency": [
                459
            ],
            "reservationQuotaLimit": [
                301
            ],
            "reservationQuotaType": [
                460
            ],
            "slotCapacity": [
                301
            ],
            "slotDurationInMinutes": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationSettingsUpdateInput": {
            "reservationOptions": [
                36
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationStatus": {},
        "AmenityReservationTimeLine": {},
        "AmenityReservationType": {},
        "AmenityReservationUserCreateInput": {
            "amenityId": [
                293
            ],
            "endDate": [
                654
            ],
            "notes": [
                559
            ],
            "startDate": [
                654
            ],
            "unitId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "AmenityReservationsWhereInput": {
            "accessibleToBuildingIds": [
                293
            ],
            "amenityIds": [
                293
            ],
            "cancellationRequestStatuses": [
                173
            ],
            "endDate": [
                654
            ],
            "startDate": [
                654
            ],
            "status": [
                44
            ],
            "statuses": [
                44
            ],
            "timeLines": [
                45
            ],
            "unitId": [
                293
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "AmenityUpdateInput": {
            "accessibleToBuildingIds": [
                293
            ],
            "cancellationPolicy": [
                559
            ],
            "description": [
                559
            ],
            "isActive": [
                129
            ],
            "isReservable": [
                129
            ],
            "location": [
                559
            ],
            "locationBuildingId": [
                293
            ],
            "managersToNotify": [
                293
            ],
            "media": [
                25
            ],
            "name": [
                559
            ],
            "pmsChargeCode": [
                559
            ],
            "pmsGlAccountNumber": [
                559
            ],
            "price": [
                274
            ],
            "requireAgreement": [
                129
            ],
            "requireApproval": [
                129
            ],
            "stripeAccountId": [
                559
            ],
            "stripeProductId": [
                559
            ],
            "timezone": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Any": {},
        "AppDashboardLink": {
            "appPageLinkDate": [
                62
            ],
            "audienceType": [
                52
            ],
            "building": [
                159
            ],
            "buildingId": [
                293
            ],
            "community": [
                175
            ],
            "communityId": [
                293
            ],
            "disabled": [
                129
            ],
            "displayText": [
                559
            ],
            "emailLinkData": [
                223
            ],
            "externalAppLinkData": [
                258
            ],
            "iconResourceKey": [
                559
            ],
            "id": [
                293
            ],
            "linkType": [
                55
            ],
            "ordering": [
                301
            ],
            "organization": [
                385
            ],
            "organizationId": [
                293
            ],
            "phoneLinkData": [
                419
            ],
            "section": [
                480
            ],
            "smsLinkData": [
                528
            ],
            "webLinkData": [
                690
            ],
            "__typename": [
                559
            ]
        },
        "AppDashboardLinkAudienceType": {},
        "AppDashboardLinkCreateInput": {
            "appPageLinkData": [
                63
            ],
            "buildingId": [
                293
            ],
            "communityId": [
                293
            ],
            "disabled": [
                129
            ],
            "displayText": [
                559
            ],
            "emailLinkData": [
                224
            ],
            "externalAppLinkData": [
                259
            ],
            "iconResourceKey": [
                559
            ],
            "linkType": [
                55
            ],
            "organizationId": [
                293
            ],
            "phoneLinkData": [
                420
            ],
            "section": [
                480
            ],
            "smsLinkData": [
                529
            ],
            "webLinkData": [
                691
            ],
            "__typename": [
                559
            ]
        },
        "AppDashboardLinkOrderInput": {
            "id": [
                559
            ],
            "ordering": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "AppDashboardLinkType": {},
        "AppDashboardLinkUpdateInput": {
            "appPageLinkData": [
                63
            ],
            "buildingId": [
                293
            ],
            "communityId": [
                293
            ],
            "disabled": [
                129
            ],
            "displayText": [
                559
            ],
            "emailLinkData": [
                224
            ],
            "externalAppLinkData": [
                259
            ],
            "iconResourceKey": [
                559
            ],
            "ordering": [
                301
            ],
            "phoneLinkData": [
                420
            ],
            "section": [
                480
            ],
            "smsLinkData": [
                529
            ],
            "webLinkData": [
                691
            ],
            "__typename": [
                559
            ]
        },
        "AppLinkType": {
            "android": [
                559
            ],
            "ios": [
                559
            ],
            "uri_prefix": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AppLinkTypeInput": {
            "android": [
                559
            ],
            "ios": [
                559
            ],
            "uri_prefix": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AppMoveInSettings": {
            "isVisibleToUser": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "AppOnboardingScreen": {
            "bullets": [
                559
            ],
            "screenType": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AppOnboardingScreenInput": {
            "bullets": [
                559
            ],
            "screenType": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AppPageLinkData": {
            "pageName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AppPageLinkDataInput": {
            "pageName": [
                64
            ],
            "__typename": [
                559
            ]
        },
        "AppPageName": {},
        "AppReputationSettings": {
            "reputationLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AppSettings": {
            "amenitiesEnabled": [
                129
            ],
            "awayNoticeSettings": [
                121,
                {
                    "where": [
                        692
                    ]
                }
            ],
            "communityId": [
                559
            ],
            "dashboardLinks": [
                51
            ],
            "embeddedApplications": [
                464
            ],
            "legacy": [
                337
            ],
            "moveInSettings": [
                59
            ],
            "rentPaymentEnabled": [
                129
            ],
            "reputation": [
                65
            ],
            "serviceRequestsSettings": [
                506
            ],
            "thermostatsEnabled": [
                129
            ],
            "visitorsEnabled": [
                129,
                {
                    "where": [
                        692
                    ]
                }
            ],
            "__typename": [
                559
            ]
        },
        "Applicant": {
            "applicantSubmissionStep": [
                79
            ],
            "applicationStatus": [
                89
            ],
            "assetsReport": [
                90
            ],
            "birthDate": [
                654
            ],
            "buildingId": [
                293
            ],
            "city": [
                559
            ],
            "coApplicants": [
                67
            ],
            "createdAt": [
                654
            ],
            "creditReport": [
                191
            ],
            "criminalBackgroundCheck": [
                193
            ],
            "decidingPMUser": [
                662
            ],
            "decidingPMUserId": [
                293
            ],
            "documents": [
                70
            ],
            "email": [
                559
            ],
            "firstName": [
                559
            ],
            "id": [
                293
            ],
            "identityVerification": [
                294
            ],
            "incomeReport": [
                298
            ],
            "lastName": [
                559
            ],
            "leaseRecommendationStatus": [
                335
            ],
            "leaseTerms": [
                74
            ],
            "mainApplicantId": [
                293
            ],
            "middleName": [
                559
            ],
            "organizationId": [
                293
            ],
            "phone": [
                559
            ],
            "screeningStatus": [
                479
            ],
            "state": [
                559
            ],
            "street": [
                559
            ],
            "summary": [
                80
            ],
            "type": [
                81
            ],
            "unit": [
                656
            ],
            "unitId": [
                293
            ],
            "zip": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantCreateResponse": {
            "id": [
                293
            ],
            "idvToken": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantDocument": {
            "availableUntil": [
                654
            ],
            "lastModified": [
                654
            ],
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantDocuments": {
            "applicantConsentDocument": [
                69
            ],
            "assetsReportDocument": [
                69
            ],
            "creditScoreReportDocument": [
                69
            ],
            "identityVerificationImagesDocument": [
                69
            ],
            "incomeReportDocument": [
                69
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantFilter": {
            "applicationStatusIn": [
                89
            ],
            "buildingIdIn": [
                293
            ],
            "createdAtGte": [
                654
            ],
            "createdAtLte": [
                654
            ],
            "organizationId": [
                293
            ],
            "screeningStatusIn": [
                479
            ],
            "unitIdIn": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantGenerateLeaseInput": {
            "concessionOf": [
                274
            ],
            "leaseEndDate": [
                654
            ],
            "leaseStartDate": [
                654
            ],
            "mainApplicantId": [
                293
            ],
            "monthlyRent": [
                274
            ],
            "monthsOfConcession": [
                301
            ],
            "securityDeposit": [
                274
            ],
            "sendToApplicant": [
                129
            ],
            "thirdPartyGuarantor": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantInvitee": {
            "email": [
                559
            ],
            "firstName": [
                559
            ],
            "lastName": [
                559
            ],
            "middleName": [
                559
            ],
            "relation": [
                559
            ],
            "type": [
                81
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantLeaseTerms": {
            "concessionOf": [
                274
            ],
            "leaseEndDate": [
                654
            ],
            "leaseStartDate": [
                654
            ],
            "monthlyRent": [
                274
            ],
            "monthsOfConcession": [
                301
            ],
            "securityDeposit": [
                274
            ],
            "thirdPartyGuarantor": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantOrderBy": {},
        "ApplicantPet": {
            "age": [
                301
            ],
            "breed": [
                559
            ],
            "color": [
                559
            ],
            "name": [
                559
            ],
            "sex": [
                416
            ],
            "type": [
                417
            ],
            "weight": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantPublicData": {
            "applicantSubmissionStep": [
                79
            ],
            "coApplicants": [
                174
            ],
            "email": [
                559
            ],
            "firstName": [
                559
            ],
            "id": [
                293
            ],
            "lastName": [
                559
            ],
            "mainApplicant": [
                345
            ],
            "middleName": [
                559
            ],
            "plaidIdvToken": [
                559
            ],
            "unit": [
                657
            ],
            "unitId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantRejectInput": {
            "mainApplicantId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantSubmissionStep": {},
        "ApplicantSummary": {
            "assets": [
                91
            ],
            "credit": [
                192
            ],
            "criminal": [
                194
            ],
            "identity": [
                295
            ],
            "income": [
                299
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantType": {},
        "ApplicantUpdateInput": {
            "SSN": [
                559
            ],
            "applicantId": [
                293
            ],
            "birthDate": [
                654
            ],
            "city": [
                559
            ],
            "firstName": [
                559
            ],
            "lastName": [
                559
            ],
            "middleName": [
                559
            ],
            "phone": [
                559
            ],
            "state": [
                559
            ],
            "street": [
                559
            ],
            "zip": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantUpdateResponse": {
            "id": [
                293
            ],
            "idvToken": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantsInviteInput": {
            "invitees": [
                73
            ],
            "mainApplicantId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ApplicantsInviteResponse": {
            "failed": [
                559
            ],
            "succeeded": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ApplicationPage": {},
        "ApplicationSettings": {
            "assetsThreshold": [
                274
            ],
            "buildingId": [
                293
            ],
            "consentHTMLTemplate": [
                559
            ],
            "creditScoreThreshold": [
                301
            ],
            "criminalRecordsThreshold": [
                301
            ],
            "id": [
                293
            ],
            "incomeThresholdNumberOfRentMonths": [
                301
            ],
            "isIdentityVerificationRequired": [
                129
            ],
            "organizationId": [
                293
            ],
            "plaidIDVTemplateId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ApplicationSettingsInput": {
            "assetsThreshold": [
                274
            ],
            "consentHTMLTemplate": [
                559
            ],
            "creditScoreThreshold": [
                301
            ],
            "criminalRecordsThreshold": [
                301
            ],
            "incomeThresholdNumberOfRentMonths": [
                301
            ],
            "isIdentityVerificationRequired": [
                129
            ],
            "plaidIDVTemplateId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ApplicationStatus": {},
        "AssetReportResult": {
            "status": [
                478
            ],
            "totalAssets": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "AssetReportSummary": {
            "assetsSum": [
                301
            ],
            "minAssetsThreshold": [
                301
            ],
            "status": [
                568
            ],
            "__typename": [
                559
            ]
        },
        "AttendanceStatus": {},
        "AuthChallenge": {
            "authSession": [
                559
            ],
            "type": [
                697
            ],
            "__typename": [
                559
            ]
        },
        "AuthChallengeCreateInput": {
            "username": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AuthChallengeVerifyInput": {
            "answer": [
                559
            ],
            "authSession": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AuthChallengeVerifyResponse": {
            "token": [
                97
            ],
            "__typename": [
                559
            ]
        },
        "AuthToken": {
            "accessToken": [
                559
            ],
            "refreshToken": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AuthTokenRefreshInput": {
            "originalToken": [
                559
            ],
            "refreshToken": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AuthTokenRefreshResponse": {
            "token": [
                97
            ],
            "__typename": [
                559
            ]
        },
        "AuthenticationMethod": {},
        "AutomationActionDescriptor": {
            "actionParams": [
                103
            ],
            "displayName": [
                559
            ],
            "id": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "AutomationDescriptor": {
            "availableActions": [
                101
            ],
            "displayName": [
                559
            ],
            "id": [
                293
            ],
            "predicateParams": [
                103
            ],
            "ruleType": [
                473
            ],
            "supportedScopes": [
                112
            ],
            "__typename": [
                559
            ]
        },
        "AutomationDescriptorParam": {
            "availableValues": [
                104
            ],
            "displayName": [
                559
            ],
            "id": [
                293
            ],
            "isMandatory": [
                129
            ],
            "maxValue": [
                301
            ],
            "minValue": [
                301
            ],
            "type": [
                105
            ],
            "uiOrder": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "AutomationDescriptorParamOption": {
            "key": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AutomationDescriptorParamType": {},
        "AutomationRule": {
            "action": [
                107
            ],
            "automationDescriptorId": [
                293
            ],
            "buildingIds": [
                559
            ],
            "communityIds": [
                559
            ],
            "createdAt": [
                654
            ],
            "description": [
                559
            ],
            "id": [
                293
            ],
            "isActive": [
                129
            ],
            "name": [
                559
            ],
            "organizationId": [
                559
            ],
            "predicateParamValues": [
                110
            ],
            "scope": [
                112
            ],
            "stats": [
                113
            ],
            "__typename": [
                559
            ]
        },
        "AutomationRuleAction": {
            "actionDescriptorId": [
                293
            ],
            "actionParams": [
                110
            ],
            "__typename": [
                559
            ]
        },
        "AutomationRuleActionInput": {
            "actionDescriptorId": [
                293
            ],
            "actionParams": [
                111
            ],
            "__typename": [
                559
            ]
        },
        "AutomationRuleInput": {
            "action": [
                108
            ],
            "automationDescriptorId": [
                293
            ],
            "description": [
                559
            ],
            "isActive": [
                129
            ],
            "name": [
                559
            ],
            "predicateParamValues": [
                111
            ],
            "__typename": [
                559
            ]
        },
        "AutomationRuleParamValue": {
            "paramDescriptorId": [
                293
            ],
            "value": [
                50
            ],
            "__typename": [
                559
            ]
        },
        "AutomationRuleParamValueInput": {
            "paramDescriptorId": [
                293
            ],
            "value": [
                50
            ],
            "__typename": [
                559
            ]
        },
        "AutomationRuleScope": {},
        "AutomationRuleStats": {
            "lastWeek": [
                301
            ],
            "total": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "AutomationTriggerHistory": {
            "actionData": [
                559
            ],
            "createdAt": [
                654
            ],
            "eventData": [
                559
            ],
            "id": [
                293
            ],
            "ruleId": [
                293
            ],
            "status": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AutomationTriggerHistoryWhereInput": {
            "createdAtGte": [
                654
            ],
            "ruleId": [
                293
            ],
            "status": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AwayNotice": {
            "awayNoticeServiceReservations": [
                119
            ],
            "createdAt": [
                654
            ],
            "endDate": [
                654
            ],
            "id": [
                293
            ],
            "leaseContract": [
                325
            ],
            "leaseContractId": [
                293
            ],
            "notes": [
                559
            ],
            "organizationId": [
                293
            ],
            "startDate": [
                654
            ],
            "status": [
                123
            ],
            "timeline": [
                655
            ],
            "__typename": [
                559
            ]
        },
        "AwayNoticeCreateInput": {
            "awayNoticeServiceReservations": [
                120
            ],
            "endDate": [
                654
            ],
            "leaseContractId": [
                293
            ],
            "notes": [
                559
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "AwayNoticeOrderBy": {},
        "AwayNoticeServiceReservation": {
            "awayNoticeServiceType": [
                559
            ],
            "notes": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AwayNoticeServiceReservationInput": {
            "awayNoticeServiceType": [
                559
            ],
            "notes": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "AwayNoticeSettings": {
            "awayNoticeServiceTypes": [
                559
            ],
            "buildingId": [
                293
            ],
            "id": [
                293
            ],
            "isEnabled": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "AwayNoticeSettingsUpsertInput": {
            "awayNoticeServiceTypes": [
                559
            ],
            "buildingId": [
                293
            ],
            "isEnabled": [
                129
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "AwayNoticeStatus": {},
        "AwayNoticeUpdateInput": {
            "awayNoticeServiceReservations": [
                120
            ],
            "endDate": [
                654
            ],
            "leaseContractId": [
                293
            ],
            "notes": [
                559
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "AwayNoticeWhereInput": {
            "endDateLt": [
                654
            ],
            "startDateGt": [
                654
            ],
            "status": [
                123
            ],
            "__typename": [
                559
            ]
        },
        "Bedroom": {
            "askedRent": [
                274
            ],
            "id": [
                293
            ],
            "leaseContracts": [
                325
            ],
            "name": [
                559
            ],
            "realEstateListing": [
                451
            ],
            "squareMeters": [
                301
            ],
            "unit": [
                656
            ],
            "__typename": [
                559
            ]
        },
        "BookingOption": {
            "endTime": [
                654
            ],
            "startTime": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "BookingOptionsWhereInput": {
            "date": [
                654
            ],
            "durationInMinutes": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "Boolean": {},
        "Brand": {
            "buildingsIds": [
                293
            ],
            "colors": [
                131
            ],
            "id": [
                293
            ],
            "landLordLogo": [
                559
            ],
            "organizationId": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BrandColors": {
            "primary": [
                559
            ],
            "primaryDark": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BrandColorsInput": {
            "primary": [
                559
            ],
            "primaryDark": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BrandCreateInput": {
            "buildingsIds": [
                293
            ],
            "colors": [
                132
            ],
            "landLordLogo": [
                559
            ],
            "organizationId": [
                293
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BrandUpdateInput": {
            "buildingsIds": [
                293
            ],
            "colors": [
                132
            ],
            "landLordLogo": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Broadcast": {
            "attachments": [
                136
            ],
            "audiences": [
                139
            ],
            "category": [
                144
            ],
            "content": [
                145
            ],
            "createdAt": [
                654
            ],
            "createdBy": [
                662
            ],
            "createdByUserId": [
                293
            ],
            "dateSent": [
                654
            ],
            "emailPayload": [
                148
            ],
            "id": [
                293
            ],
            "medium": [
                367
            ],
            "mediums": [
                150
            ],
            "notifications": [
                378,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        377
                    ]
                }
            ],
            "scheduledBroadcast": [
                475
            ],
            "sendStatus": [
                151
            ],
            "stats": [
                154
            ],
            "updatedBy": [
                662
            ],
            "updatedByUserId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastAttachment": {
            "id": [
                293
            ],
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastAttachmentCreateInput": {
            "type": [
                138
            ],
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastAttachmentType": {},
        "BroadcastAudience": {
            "audienceParams": [
                141
            ],
            "type": [
                143
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastAudienceInput": {
            "audienceParams": [
                142
            ],
            "type": [
                143
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastAudienceParams": {
            "on_BuildingAudienceParams": [
                160
            ],
            "on_EventAudienceParams": [
                243
            ],
            "on_InterestGroupsAudienceParams": [
                316
            ],
            "on_SpecificUsersAudienceParams": [
                550
            ],
            "on_UserTypeAudienceParams": [
                677
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastAudienceParamsInput": {
            "buildingAudienceParams": [
                161
            ],
            "eventAudienceParams": [
                244
            ],
            "interestGroupsAudienceParams": [
                702
            ],
            "specificUsersAudienceParams": [
                551
            ],
            "userTypeAudienceParams": [
                678
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastAudienceType": {},
        "BroadcastCategory": {},
        "BroadcastContent": {
            "cta": [
                195
            ],
            "deepLink": [
                199
            ],
            "message": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastContentInput": {
            "cta": [
                196
            ],
            "deepLink": [
                200
            ],
            "message": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastCreateInput": {
            "attachments": [
                137
            ],
            "audiences": [
                140
            ],
            "category": [
                144
            ],
            "content": [
                146
            ],
            "createdByUserId": [
                293
            ],
            "emailPayload": [
                149
            ],
            "hoodId": [
                293
            ],
            "medium": [
                367
            ],
            "mediums": [
                150
            ],
            "sendStatus": [
                151
            ],
            "sourceType": [
                152
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastEmailPayload": {
            "fromEmail": [
                559
            ],
            "fromName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastEmailPayloadInput": {
            "fromEmail": [
                559
            ],
            "fromName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastMedium": {},
        "BroadcastSendStatus": {},
        "BroadcastSourceType": {},
        "BroadcastStatName": {},
        "BroadcastStatParam": {
            "count": [
                301
            ],
            "rate": [
                559
            ],
            "status": [
                153
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastUpdateInput": {
            "attachments": [
                137
            ],
            "audiences": [
                140
            ],
            "category": [
                144
            ],
            "content": [
                146
            ],
            "dateSent": [
                654
            ],
            "emailPayload": [
                149
            ],
            "medium": [
                367
            ],
            "mediums": [
                150
            ],
            "scheduledBroadcastId": [
                293
            ],
            "sendStatus": [
                151
            ],
            "updatedByUserId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastWhereInput": {
            "medium": [
                367
            ],
            "mediums": [
                150
            ],
            "organizationId": [
                293
            ],
            "statusIn": [
                151
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastsList": {
            "items": [
                135
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "BroadcastsOrderBy": {},
        "Building": {
            "address": [
                5
            ],
            "buildingNumber": [
                301
            ],
            "communities": [
                175
            ],
            "externalId": [
                293
            ],
            "id": [
                293
            ],
            "legalName": [
                559
            ],
            "name": [
                559
            ],
            "paymentReminders": [
                398
            ],
            "settings": [
                163
            ],
            "status": [
                165
            ],
            "streetName": [
                559
            ],
            "units": [
                656
            ],
            "__typename": [
                559
            ]
        },
        "BuildingAudienceParams": {
            "buildingIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "BuildingAudienceParamsInput": {
            "buildingIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "BuildingMode": {},
        "BuildingSettings": {
            "amenityReservationTemplateName": [
                559
            ],
            "buildingId": [
                559
            ],
            "calcContractStatusByDate": [
                129
            ],
            "isLocksEnabled": [
                129
            ],
            "isPackagesEnabled": [
                129
            ],
            "isRentPaymentMobileEnabled": [
                129
            ],
            "isRentPaymentSupported": [
                129
            ],
            "isShiftNotesEnabled": [
                129
            ],
            "isVisitorsEnabled": [
                129
            ],
            "paymentPolicyFAQLink": [
                559
            ],
            "paymentPortalLink": [
                559
            ],
            "thermostatAdapterId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BuildingSettingsSetInput": {
            "amenityReservationTemplateName": [
                559
            ],
            "buildingId": [
                559
            ],
            "calcContractStatusByDate": [
                129
            ],
            "isLocksEnabled": [
                129
            ],
            "isPackagesEnabled": [
                129
            ],
            "isRentPaymentMobileEnabled": [
                129
            ],
            "isRentPaymentSupported": [
                129
            ],
            "isShiftNotesEnabled": [
                129
            ],
            "isVisitorsEnabled": [
                129
            ],
            "paymentPolicyFAQLink": [
                559
            ],
            "paymentPortalLink": [
                559
            ],
            "thermostatAdapterId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BuildingStatus": {},
        "BuildingsTaskListSetInput": {
            "buildingIds": [
                293
            ],
            "taskListId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "BuildingsWhereInput": {
            "communityIdsIn": [
                293
            ],
            "externalIds": [
                293
            ],
            "ids": [
                293
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Byline": {
            "count": [
                301
            ],
            "type": [
                169
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "BylineType": {},
        "Calendar": {
            "id": [
                293
            ],
            "intervalDuration": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "CalendarBookingDetailsInput": {
            "slots": [
                172
            ],
            "__typename": [
                559
            ]
        },
        "CalendarTimeSlotInput": {
            "endTime": [
                654
            ],
            "startTime": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "CancellationRequestStatus": {},
        "CoApplicantPublicData": {
            "email": [
                559
            ],
            "firstName": [
                559
            ],
            "id": [
                293
            ],
            "lastName": [
                559
            ],
            "middleName": [
                559
            ],
            "type": [
                81
            ],
            "__typename": [
                559
            ]
        },
        "Community": {
            "buildings": [
                159,
                {
                    "where": [
                        178
                    ]
                }
            ],
            "coordinates": [
                188
            ],
            "country": [
                190
            ],
            "displayName": [
                559
            ],
            "id": [
                293
            ],
            "language": [
                324
            ],
            "name": [
                559
            ],
            "organization": [
                385
            ],
            "organizationId": [
                293
            ],
            "settings": [
                180
            ],
            "state": [
                559
            ],
            "timezone": [
                559
            ],
            "uiMetadata": [
                182
            ],
            "__typename": [
                559
            ]
        },
        "CommunityBuildings": {
            "buildingsIds": [
                293
            ],
            "communityId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "CommunityBuildingsSetInput": {
            "buildingsIds": [
                293
            ],
            "communityId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "CommunityBuildingsWhereInput": {
            "externalIds": [
                293
            ],
            "ids": [
                293
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "CommunityCreateInput": {
            "coordinates": [
                189
            ],
            "country": [
                190
            ],
            "displayName": [
                559
            ],
            "language": [
                324
            ],
            "organizationId": [
                293
            ],
            "state": [
                559
            ],
            "timezone": [
                559
            ],
            "uiMetadata": [
                183
            ],
            "__typename": [
                559
            ]
        },
        "CommunitySettings": {
            "communityId": [
                293
            ],
            "moderationSlackChannel": [
                559
            ],
            "socialProfileSuspendDurationInMinutes": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "CommunitySettingsSetInput": {
            "communityId": [
                293
            ],
            "emailSender": [
                559
            ],
            "emailTemplate": [
                559
            ],
            "moderationSlackChannel": [
                559
            ],
            "smsPhoneNumberSender": [
                559
            ],
            "socialProfileSuspendDurationInMinutes": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "CommunityUiMetadata": {
            "appOnboardingScreens": [
                60
            ],
            "collateralUrl": [
                559
            ],
            "communityLogoUrl": [
                559
            ],
            "dashboardLogoUrl": [
                559
            ],
            "hideVennLogo": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "CommunityUiMetadataInput": {
            "appOnboardingScreens": [
                61
            ],
            "collateralUrl": [
                559
            ],
            "communityLogoUrl": [
                559
            ],
            "dashboardLogoUrl": [
                559
            ],
            "hideVennLogo": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "CommunityUpdateInput": {
            "coordinates": [
                189
            ],
            "country": [
                190
            ],
            "displayName": [
                559
            ],
            "language": [
                324
            ],
            "state": [
                559
            ],
            "timezone": [
                559
            ],
            "uiMetadata": [
                183
            ],
            "__typename": [
                559
            ]
        },
        "CommunityWhereInput": {
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ContractStatus": {},
        "ContractTimeline": {},
        "Coordinate": {
            "latitude": [
                274
            ],
            "latitudeDelta": [
                274
            ],
            "longitude": [
                274
            ],
            "longitudeDelta": [
                274
            ],
            "__typename": [
                559
            ]
        },
        "CoordinateInput": {
            "latitude": [
                274
            ],
            "longitude": [
                274
            ],
            "__typename": [
                559
            ]
        },
        "CountryCode": {},
        "CreditReportResult": {
            "creditScore": [
                301
            ],
            "status": [
                478
            ],
            "__typename": [
                559
            ]
        },
        "CreditReportSummary": {
            "averageCreditScore": [
                301
            ],
            "minCreditThreshold": [
                301
            ],
            "status": [
                568
            ],
            "__typename": [
                559
            ]
        },
        "CriminalBackgroundReportResult": {
            "numberOfOffenses": [
                301
            ],
            "status": [
                478
            ],
            "__typename": [
                559
            ]
        },
        "CriminalBackgroundReportSummary": {
            "status": [
                568
            ],
            "sumOfCriminalRecords": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "CtaData": {
            "applicationPage": [
                86
            ],
            "ctaText": [
                559
            ],
            "description": [
                559
            ],
            "entityId": [
                293
            ],
            "params": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "CtaInput": {
            "applicationPage": [
                86
            ],
            "ctaText": [
                559
            ],
            "description": [
                559
            ],
            "entityId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "DateRangeInput": {
            "end": [
                654
            ],
            "start": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "DayOfWeek": {},
        "DeepLink": {
            "applicationPage": [
                86
            ],
            "description": [
                559
            ],
            "entityId": [
                293
            ],
            "params": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "DeepLinkInput": {
            "applicationPage": [
                86
            ],
            "description": [
                559
            ],
            "entityId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "DeliveryPackage": {
            "additionalInfo": [
                559
            ],
            "arrivedOnDate": [
                654
            ],
            "building": [
                159
            ],
            "buildingId": [
                559
            ],
            "courier": [
                559
            ],
            "id": [
                293
            ],
            "packageNumber": [
                559
            ],
            "pickUpDate": [
                654
            ],
            "pickUpLocation": [
                559
            ],
            "status": [
                204
            ],
            "unit": [
                656
            ],
            "unitId": [
                559
            ],
            "user": [
                662
            ],
            "userId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "DeliveryPackageCreateInput": {
            "additionalInfo": [
                559
            ],
            "arrivedOnDate": [
                654
            ],
            "courier": [
                559
            ],
            "packageNumber": [
                559
            ],
            "pickUpLocation": [
                559
            ],
            "status": [
                204
            ],
            "unitId": [
                559
            ],
            "userId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "DeliveryPackageOrderBy": {},
        "DeliveryPackageStatus": {},
        "DeliveryPackageUpdateInput": {
            "additionalInfo": [
                559
            ],
            "arrivedOnDate": [
                654
            ],
            "buildingId": [
                559
            ],
            "courier": [
                559
            ],
            "packageNumber": [
                559
            ],
            "pickUpDate": [
                654
            ],
            "pickUpLocation": [
                559
            ],
            "status": [
                204
            ],
            "unitId": [
                559
            ],
            "userId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "DeliveryPackageWhereInput": {
            "id": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "DeliveryPackagesList": {
            "items": [
                201
            ],
            "__typename": [
                559
            ]
        },
        "DeliveryPackagesWhereInput": {
            "arrivedOnDateBetween": [
                197
            ],
            "packageNumberEq": [
                559
            ],
            "statuses": [
                204
            ],
            "statusesIn": [
                204
            ],
            "unitIdIn": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "DeviceMode": {},
        "DevicePlatform": {},
        "Discussion": {
            "audienceBuildings": [
                159
            ],
            "authorUser": [
                662
            ],
            "commentsCount": [
                301
            ],
            "id": [
                293
            ],
            "image": [
                559
            ],
            "imageAspectRatio": [
                274
            ],
            "interactingSocialProfiles": [
                535
            ],
            "isLikedByCurrentUser": [
                129
            ],
            "latestActivity": [
                654
            ],
            "likesCount": [
                301
            ],
            "locationLabel": [
                559
            ],
            "publishedAt": [
                654
            ],
            "status": [
                217
            ],
            "survey": [
                584
            ],
            "text": [
                559
            ],
            "type": [
                218
            ],
            "updatedAt": [
                654
            ],
            "wasViewedByCurrentUser": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "DiscussionComment": {
            "authorSocialProfile": [
                535
            ],
            "createdAt": [
                654
            ],
            "id": [
                293
            ],
            "message": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "DiscussionCommentsList": {
            "items": [
                212
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "DiscussionCommentsOrderBy": {},
        "DiscussionCommentsWhereInput": {
            "discussionId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "DiscussionCreateInput": {
            "audienceBuildingIds": [
                293
            ],
            "hoodName": [
                559
            ],
            "image": [
                559
            ],
            "ownerUserId": [
                293
            ],
            "status": [
                217
            ],
            "text": [
                559
            ],
            "type": [
                218
            ],
            "__typename": [
                559
            ]
        },
        "DiscussionPublishStatus": {},
        "DiscussionType": {},
        "DiscussionUpdateInput": {
            "audienceBuildingIds": [
                293
            ],
            "image": [
                559
            ],
            "status": [
                217
            ],
            "text": [
                559
            ],
            "type": [
                218
            ],
            "__typename": [
                559
            ]
        },
        "DiscussionsList": {
            "items": [
                211
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "DiscussionsOrderBy": {},
        "EmailDomainInput": {
            "domain": [
                559
            ],
            "isVerified": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "EmailLinkData": {
            "emailAddress": [
                559
            ],
            "subject": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "EmailLinkDataInput": {
            "emailAddress": [
                559
            ],
            "subject": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "EmailSenderDomain": {
            "domain": [
                559
            ],
            "isVerified": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "EmailSettings": {
            "domains": [
                225
            ],
            "endpoint": [
                559
            ],
            "fromEmails": [
                559
            ],
            "id": [
                293
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "EmailSettingsUpdateInput": {
            "domains": [
                222
            ],
            "fromEmails": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "EmbeddedApplicationAdapter": {
            "buildingIds": [
                293
            ],
            "endpoint": [
                559
            ],
            "id": [
                293
            ],
            "isEnabled": [
                129
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "settings": [
                481
            ],
            "type": [
                235
            ],
            "__typename": [
                559
            ]
        },
        "EmbeddedApplicationAdapterCreateInput": {
            "buildingIDs": [
                293
            ],
            "endpoint": [
                559
            ],
            "isEnabled": [
                129
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "settings": [
                482
            ],
            "type": [
                235
            ],
            "__typename": [
                559
            ]
        },
        "EmbeddedApplicationAdapterUpdateInput": {
            "buildingIds": [
                293
            ],
            "isEnabled": [
                129
            ],
            "name": [
                559
            ],
            "settings": [
                482
            ],
            "__typename": [
                559
            ]
        },
        "EmbeddedApplicationAdapterWhereInput": {
            "isEnabled": [
                129
            ],
            "typeIn": [
                235
            ],
            "__typename": [
                559
            ]
        },
        "EmbeddedApplicationAuthenticationMethod": {},
        "EmbeddedApplicationCallbackHandlingMethod": {},
        "EmbeddedApplicationSaml2Settings": {
            "acsURL": [
                559
            ],
            "metadataURL": [
                559
            ],
            "relayState": [
                559
            ],
            "userCustomAttributes": [
                323
            ],
            "__typename": [
                559
            ]
        },
        "EmbeddedApplicationType": {},
        "EmbeddedApplicationWindowEventSettings": {
            "errorMessageJsonPath": [
                559
            ],
            "failureJsonPath": [
                559
            ],
            "origin": [
                559
            ],
            "successJsonPath": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "EntityType": {},
        "Event": {
            "RVSPs": [
                301
            ],
            "admissionFeeType": [
                7
            ],
            "attendanceCancellable": [
                129
            ],
            "attendees": [
                241,
                {
                    "where": [
                        242
                    ]
                }
            ],
            "audienceBuildingIds": [
                293
            ],
            "audienceBuildings": [
                159
            ],
            "availableTickets": [
                301
            ],
            "category": [
                559
            ],
            "checkIns": [
                301
            ],
            "communities": [
                175
            ],
            "communityIds": [
                293
            ],
            "description": [
                559
            ],
            "endDate": [
                654
            ],
            "hoodId": [
                293
            ],
            "id": [
                293
            ],
            "imageResourceId": [
                293
            ],
            "locationBuilding": [
                159
            ],
            "locationBuildingId": [
                293
            ],
            "maxTickets": [
                301
            ],
            "name": [
                559
            ],
            "ownershipType": [
                248
            ],
            "place": [
                421
            ],
            "placeId": [
                293
            ],
            "publish": [
                129
            ],
            "residentHostIds": [
                293
            ],
            "residentHosts": [
                662
            ],
            "shouldGenerateQRCode": [
                129
            ],
            "startDate": [
                654
            ],
            "statistics": [
                249
            ],
            "status": [
                250
            ],
            "surveyId": [
                293
            ],
            "ticketPrice": [
                274
            ],
            "virtualLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "EventAttendInput": {
            "eventId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "EventAttendance": {
            "attendanceStatus": [
                92
            ],
            "eventDetails": [
                238
            ],
            "eventId": [
                293
            ],
            "rating": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "EventAttendee": {
            "attendanceStatus": [
                92
            ],
            "eventId": [
                293
            ],
            "id": [
                293
            ],
            "rating": [
                447
            ],
            "user": [
                662
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "EventAttendeeWhereInput": {
            "attendanceStatus": [
                92
            ],
            "attendanceStatusNot": [
                92
            ],
            "__typename": [
                559
            ]
        },
        "EventAudienceParams": {
            "eventId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "EventAudienceParamsInput": {
            "eventId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "EventCancelAttendanceInput": {
            "eventAttendeeId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "EventCheckInWithScanInput": {
            "eventId": [
                293
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "EventCreateInput": {
            "admissionFeeType": [
                7
            ],
            "audienceBuildingIds": [
                293
            ],
            "category": [
                559
            ],
            "communityIds": [
                293
            ],
            "description": [
                559
            ],
            "endDate": [
                654
            ],
            "hoodId": [
                559
            ],
            "imageResourceId": [
                559
            ],
            "maxTickets": [
                301
            ],
            "name": [
                559
            ],
            "ownershipType": [
                248
            ],
            "placeId": [
                293
            ],
            "publish": [
                129
            ],
            "residentHostIds": [
                293
            ],
            "shouldGenerateQRCode": [
                129
            ],
            "startDate": [
                654
            ],
            "ticketPrice": [
                274
            ],
            "virtualLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "EventOwnershipType": {},
        "EventStatistics": {
            "ratingAverage": [
                274
            ],
            "ratingCount": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "EventStatus": {},
        "EventSuggestionImageInput": {
            "format": [
                559
            ],
            "publicId": [
                559
            ],
            "resourceType": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "EventSuggestionInput": {
            "communityId": [
                293
            ],
            "date": [
                654
            ],
            "description": [
                559
            ],
            "helpDetails": [
                559
            ],
            "imageResource": [
                251
            ],
            "location": [
                559
            ],
            "locationDetails": [
                559
            ],
            "name": [
                559
            ],
            "organizers": [
                393
            ],
            "timeframe": [
                253
            ],
            "__typename": [
                559
            ]
        },
        "EventSuggestionTimeframe": {},
        "EventUpdateInput": {
            "admissionFeeType": [
                7
            ],
            "audienceBuildingIds": [
                293
            ],
            "category": [
                559
            ],
            "communityIds": [
                293
            ],
            "description": [
                559
            ],
            "endDate": [
                654
            ],
            "hoodId": [
                559
            ],
            "imageResourceId": [
                559
            ],
            "maxTickets": [
                301
            ],
            "name": [
                559
            ],
            "ownershipType": [
                248
            ],
            "placeId": [
                559
            ],
            "publish": [
                129
            ],
            "residentHostIds": [
                293
            ],
            "shouldGenerateQRCode": [
                129
            ],
            "startDate": [
                654
            ],
            "ticketPrice": [
                274
            ],
            "virtualLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "EventWhereInput": {
            "buildingIds": [
                293
            ],
            "communityId": [
                293
            ],
            "communityIds": [
                293
            ],
            "hoodId": [
                293
            ],
            "ids": [
                293
            ],
            "searchName": [
                559
            ],
            "startDateGt": [
                654
            ],
            "startDateLt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "EventsList": {
            "items": [
                238
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "EventsOrderBy": {},
        "ExternalAppLinkData": {
            "androidFallbackLink": [
                559
            ],
            "androidLink": [
                559
            ],
            "iosFallbackLink": [
                559
            ],
            "iosLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ExternalAppLinkDataInput": {
            "androidFallbackLink": [
                559
            ],
            "androidLink": [
                559
            ],
            "iosFallbackLink": [
                559
            ],
            "iosLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "FanMode": {},
        "FeatureFlag": {
            "id": [
                293
            ],
            "key": [
                559
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "FeatureFlagWhereInput": {
            "searchKey": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlide": {
            "audienceBuildings": [
                264
            ],
            "callToAction": [
                265
            ],
            "communityId": [
                293
            ],
            "createdBy": [
                662
            ],
            "createdById": [
                293
            ],
            "description": [
                559
            ],
            "id": [
                293
            ],
            "imageResourceId": [
                559
            ],
            "linkType": [
                338
            ],
            "name": [
                559
            ],
            "priority": [
                301
            ],
            "publishEndDate": [
                654
            ],
            "publishStartDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideBuildings": {
            "building": [
                159
            ],
            "id": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideCallToAction": {
            "label": [
                559
            ],
            "link": [
                268
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideCallToActionInput": {
            "label": [
                559
            ],
            "link": [
                269
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideCreateInput": {
            "audienceBuildingIds": [
                293
            ],
            "callToAction": [
                266
            ],
            "communityId": [
                293
            ],
            "description": [
                559
            ],
            "image": [
                297
            ],
            "linkType": [
                338
            ],
            "name": [
                559
            ],
            "priority": [
                301
            ],
            "publishEndDate": [
                654
            ],
            "publishStartDate": [
                654
            ],
            "template": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideLink": {
            "name": [
                559
            ],
            "type": [
                338
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideLinkInput": {
            "name": [
                559
            ],
            "type": [
                338
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideList": {
            "items": [
                263
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideUpdateInput": {
            "audienceBuildingIds": [
                293
            ],
            "callToAction": [
                266
            ],
            "description": [
                559
            ],
            "image": [
                297
            ],
            "linkType": [
                338
            ],
            "name": [
                559
            ],
            "publishEndDate": [
                654
            ],
            "publishStartDate": [
                654
            ],
            "template": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlideWhereInput": {
            "communityIds": [
                293
            ],
            "isActive": [
                129
            ],
            "publishBetween": [
                197
            ],
            "__typename": [
                559
            ]
        },
        "FeaturedSlidesOrderBy": {},
        "Float": {},
        "Gender": {},
        "GeneralInfoPage": {
            "cards": [
                277
            ],
            "communityId": [
                293
            ],
            "createdAt": [
                654
            ],
            "createdBy": [
                662
            ],
            "createdById": [
                293
            ],
            "description": [
                559
            ],
            "id": [
                293
            ],
            "image": [
                296
            ],
            "publish": [
                129
            ],
            "subtitle": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "GeneralInfoPageCards": {
            "eventIds": [
                293
            ],
            "events": [
                238
            ],
            "placeIds": [
                293
            ],
            "places": [
                421
            ],
            "serviceIds": [
                293
            ],
            "serviceProductIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "GeneralInfoPageCardsInput": {
            "eventIds": [
                293
            ],
            "placeIds": [
                293
            ],
            "serviceIds": [
                293
            ],
            "serviceProductIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "GeneralInfoPageCreateInput": {
            "cards": [
                278
            ],
            "description": [
                559
            ],
            "image": [
                297
            ],
            "publish": [
                129
            ],
            "subtitle": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "GeneralInfoPageList": {
            "items": [
                276
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "GeneralInfoPageUpdateInput": {
            "cards": [
                278
            ],
            "description": [
                559
            ],
            "image": [
                297
            ],
            "publish": [
                129
            ],
            "subtitle": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "GeneralInfoPageWhereInput": {
            "communityId": [
                293
            ],
            "freeText": [
                559
            ],
            "publish": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "GeneralInfoPagesOrderBy": {},
        "GuestKey": {
            "key": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "GuestKeyEntry": {
            "id": [
                559
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Hood": {
            "coordinates": [
                50
            ],
            "country": [
                190
            ],
            "displayName": [
                559
            ],
            "id": [
                293
            ],
            "language": [
                559
            ],
            "name": [
                559
            ],
            "portfolio": [
                440
            ],
            "portfolioId": [
                293
            ],
            "state": [
                559
            ],
            "timezone": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "HoodAggregations": {
            "activeSocialProfilesCount": [
                301
            ],
            "newSocialProfilesCount": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "HoodRoot": {
            "aggregations": [
                287
            ],
            "broadcast": [
                135,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "broadcasts": [
                157,
                {
                    "orderBy": [
                        158,
                        "[BroadcastsOrderBy!]"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        156
                    ]
                }
            ],
            "discussion": [
                211,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "discussionComments": [
                213,
                {
                    "orderBy": [
                        214
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        215,
                        "DiscussionCommentsWhereInput!"
                    ]
                }
            ],
            "discussions": [
                220,
                {
                    "orderBy": [
                        221,
                        "[DiscussionsOrderBy!]"
                    ],
                    "pager": [
                        395
                    ]
                }
            ],
            "discussionsSearchSuggestions": [
                559,
                {
                    "searchTerm": [
                        559,
                        "String!"
                    ]
                }
            ],
            "event": [
                238,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "events": [
                256,
                {
                    "orderBy": [
                        257,
                        "[EventsOrderBy!]"
                    ],
                    "pager": [
                        395,
                        "PageInput!"
                    ],
                    "where": [
                        255
                    ]
                }
            ],
            "featuredSlidesSearchSuggestions": [
                559,
                {
                    "searchTerm": [
                        559,
                        "String!"
                    ]
                }
            ],
            "hoodDetails": [
                286
            ],
            "hoodId": [
                293
            ],
            "hoodSettings": [
                289,
                {
                    "where": [
                        290,
                        "HoodSettingWhereInput!"
                    ]
                }
            ],
            "interestGroup": [
                302,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroups": [
                317,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        315
                    ]
                }
            ],
            "managersSocialProfiles": [
                535
            ],
            "place": [
                421,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "service": [
                483,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceProduct": [
                484,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "socialProfiles": [
                549,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        548
                    ]
                }
            ],
            "user": [
                662,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "users": [
                680,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        679
                    ]
                }
            ],
            "__typename": [
                559
            ]
        },
        "HoodSetting": {
            "hoodId": [
                293
            ],
            "key": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "HoodSettingWhereInput": {
            "key": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "HubMetadata": {
            "background": [
                559
            ],
            "firstTimeWalkMe": [
                129
            ],
            "nickName": [
                559
            ],
            "onboarded": [
                129
            ],
            "picture": [
                559
            ],
            "promoAmenities": [
                129
            ],
            "promoMoveIn": [
                129
            ],
            "renewals": [
                129
            ],
            "roles": [
                559
            ],
            "tShirtSize": [
                559
            ],
            "tasks": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "HubMetadataSetInput": {
            "background": [
                559
            ],
            "firstTimeWalkMe": [
                129
            ],
            "nickName": [
                559
            ],
            "onboarded": [
                129
            ],
            "picture": [
                559
            ],
            "promoAmenities": [
                129
            ],
            "promoMoveIn": [
                129
            ],
            "renewals": [
                129
            ],
            "roles": [
                559
            ],
            "tShirtSize": [
                559
            ],
            "tasks": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ID": {},
        "IdentityVerificationResult": {
            "isIdentityVerified": [
                129
            ],
            "status": [
                478
            ],
            "__typename": [
                559
            ]
        },
        "IdentityVerificationSummary": {
            "areAllIdentitiesVerified": [
                129
            ],
            "status": [
                568
            ],
            "__typename": [
                559
            ]
        },
        "Image": {
            "format": [
                559
            ],
            "resourceId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ImageInput": {
            "resourceId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "IncomeReportResult": {
            "annualIncome": [
                301
            ],
            "status": [
                478
            ],
            "__typename": [
                559
            ]
        },
        "IncomeReportSummary": {
            "incomeSum": [
                301
            ],
            "minIncomeThreshold": [
                301
            ],
            "numOfSkipped": [
                301
            ],
            "status": [
                568
            ],
            "__typename": [
                559
            ]
        },
        "InitTokenParams": {
            "expiresAt": [
                654
            ],
            "refreshToken": [
                559
            ],
            "token": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Int": {},
        "InterestGroup": {
            "createdAt": [
                654
            ],
            "createdByUser": [
                662
            ],
            "createdByUserId": [
                293
            ],
            "description": [
                559
            ],
            "disabled": [
                129
            ],
            "groupSuggestedByNeighbor": [
                129
            ],
            "id": [
                293
            ],
            "imageUrl": [
                559
            ],
            "isPublic": [
                129
            ],
            "memberCount": [
                301
            ],
            "name": [
                559
            ],
            "socialProfilesPreview": [
                535
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupCreateInput": {
            "description": [
                559
            ],
            "imageUrl": [
                559
            ],
            "isPublic": [
                129
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupInsightCategory": {
            "description": [
                559
            ],
            "id": [
                293
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupInsightCategoryDiscussionWhereInput": {
            "discussionStartedBetween": [
                197
            ],
            "interestGroupIdsIn": [
                293
            ],
            "labels": [
                293
            ],
            "sentiment": [
                313
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupInsightDiscussion": {
            "InterestGroup": [
                302
            ],
            "category": [
                304
            ],
            "categoryId": [
                293
            ],
            "endAt": [
                654
            ],
            "id": [
                293
            ],
            "interestGroupId": [
                293
            ],
            "labels": [
                309
            ],
            "members": [
                310
            ],
            "messageIds": [
                293
            ],
            "messages": [
                312
            ],
            "sentiment": [
                313
            ],
            "shortDescription": [
                559
            ],
            "startAt": [
                654
            ],
            "topic": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupInsightDiscussionWhereInput": {
            "categoryIdsIn": [
                293
            ],
            "discussionStartedBetween": [
                197
            ],
            "interestGroupIdsIn": [
                293
            ],
            "labels": [
                293
            ],
            "sentiment": [
                313
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupInsightSummary": {
            "dateFrom": [
                654
            ],
            "dateTo": [
                654
            ],
            "interestGroupId": [
                293
            ],
            "members": [
                310
            ],
            "messageIds": [
                293
            ],
            "summary": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupLabel": {
            "color": [
                559
            ],
            "icon": [
                559
            ],
            "id": [
                293
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupMember": {
            "user": [
                662
            ],
            "user_id": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupMembersList": {
            "members": [
                310
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupMessage": {
            "communityId": [
                293
            ],
            "id": [
                293
            ],
            "interestGroupId": [
                293
            ],
            "organizationId": [
                293
            ],
            "streamCreatedAt": [
                654
            ],
            "streamUpdatedAt": [
                654
            ],
            "text": [
                559
            ],
            "user": [
                662
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupSentiment": {},
        "InterestGroupUpdateInput": {
            "description": [
                559
            ],
            "disabled": [
                129
            ],
            "imageUrl": [
                559
            ],
            "isPublic": [
                129
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupWhereInput": {
            "includePrivate": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupsAudienceParams": {
            "interestGroupIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "InterestGroupsList": {
            "items": [
                302
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "InviteStatus": {},
        "JSONW": {},
        "JobItemEntityType": {},
        "JobItemStatus": {},
        "JobItemStatusCount": {
            "count": [
                301
            ],
            "status": [
                616
            ],
            "__typename": [
                559
            ]
        },
        "KeyValueParam": {
            "key": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Language": {},
        "LeaseContract": {
            "activeTaskLists": [
                671,
                {
                    "where": [
                        328,
                        "LeaseContractTaskListWhereInput!"
                    ]
                }
            ],
            "bedroom": [
                126
            ],
            "bedroomId": [
                559
            ],
            "contractTimeline": [
                187
            ],
            "endDate": [
                654
            ],
            "externalId": [
                293
            ],
            "hood": [
                286
            ],
            "hoodId": [
                559
            ],
            "id": [
                293
            ],
            "isDraft": [
                129
            ],
            "isTerminatedEarly": [
                129
            ],
            "lateFeeApplicable": [
                129
            ],
            "lateFeeGracePeriod": [
                301
            ],
            "ledger": [
                332
            ],
            "metadata": [
                50
            ],
            "moveInDate": [
                654
            ],
            "moveOutDate": [
                654
            ],
            "moveOutReason": [
                355
            ],
            "originalEndDate": [
                654
            ],
            "pmsStatus": [
                559
            ],
            "renewalScore": [
                453
            ],
            "rent": [
                274
            ],
            "securityDepositAmount": [
                274
            ],
            "startDate": [
                654
            ],
            "status": [
                186
            ],
            "unit": [
                656
            ],
            "unitId": [
                559
            ],
            "user": [
                662
            ],
            "userId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "LeaseContractCreateInput": {
            "endDate": [
                654
            ],
            "organizationId": [
                293
            ],
            "startDate": [
                654
            ],
            "unitId": [
                293
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "LeaseContractOrderBy": {},
        "LeaseContractTaskListWhereInput": {
            "type": [
                635
            ],
            "__typename": [
                559
            ]
        },
        "LeaseContractWhereInput": {
            "buildingIds": [
                293
            ],
            "contractTimeline": [
                187
            ],
            "isDraft": [
                129
            ],
            "isPrimary": [
                129
            ],
            "statusIn": [
                186
            ],
            "userIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "LeaseContractsList": {
            "items": [
                325
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "LeaseEndRange": {
            "end": [
                654
            ],
            "start": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "LeaseLedger": {
            "BalanceDue": [
                274
            ],
            "charges": [
                333
            ],
            "payments": [
                334
            ],
            "totalUnpaidCharges": [
                274
            ],
            "__typename": [
                559
            ]
        },
        "LeaseLedgerCharge": {
            "amount": [
                274
            ],
            "amountPaid": [
                274
            ],
            "code": [
                559
            ],
            "date": [
                654
            ],
            "description": [
                559
            ],
            "id": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "LeaseLedgerPayment": {
            "amount": [
                274
            ],
            "id": [
                293
            ],
            "paidAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "LeaseRecommendationStatus": {},
        "LeaseRentRange": {
            "end": [
                274
            ],
            "start": [
                274
            ],
            "__typename": [
                559
            ]
        },
        "LegacySettings": {
            "cancelOrderEnabled": [
                129
            ],
            "cancelOrderUserId": [
                559
            ],
            "customCommunityGuidelinesObjectsArray": [
                559
            ],
            "dashboardAdditionalOptions": [
                559
            ],
            "enableMaintenanceChat": [
                129
            ],
            "hasCustomCommunityGuidelines": [
                129
            ],
            "immediatePaymentEnabled": [
                129
            ],
            "landlordImage": [
                559
            ],
            "locksEnabled": [
                129
            ],
            "locksProvider": [
                559
            ],
            "locksSharingEnabled": [
                129
            ],
            "locksSupportPhone": [
                559
            ],
            "maintenanceUserID": [
                559
            ],
            "mobileSettingsIsDemoHood": [
                129
            ],
            "mobileSettingsNewAppDisplayName": [
                559
            ],
            "mobileSettingsNewAppLink": [
                559
            ],
            "mobileSettingsNewAppModalEnabled": [
                129
            ],
            "moveInSettingsAdditionalInterestedParty": [
                559
            ],
            "moveInSettingsEnabled": [
                129
            ],
            "moveInSettingsEnabledUsers": [
                559
            ],
            "moveInSettingsUseHardCodedTemplate": [
                129
            ],
            "packagesEnabled": [
                129
            ],
            "paymentInvoicesEnabled": [
                129
            ],
            "paymentProviderUrl": [
                559
            ],
            "preventUsersFromPostingInFeed": [
                129
            ],
            "supportDescriptionOptionalEnabled": [
                129
            ],
            "supportEntryNotesMandatoryEnabled": [
                129
            ],
            "supportProvider": [
                559
            ],
            "supportSubjectOptionalEnabled": [
                129
            ],
            "supportTicketCovidQuestionsEnabled": [
                129
            ],
            "supportTicketEnabled": [
                129
            ],
            "supportTicketEntryNoteEnabled": [
                129
            ],
            "supportUserId": [
                559
            ],
            "walletEnabled": [
                129
            ],
            "yardiCommentsEmailAddress": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "LinkType": {},
        "LockCredentials": {
            "credentials": [
                559
            ],
            "provider": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "LockProvider": {
            "adapterId": [
                559
            ],
            "authenticationMethod": [
                100
            ],
            "initTokenParams": [
                300
            ],
            "isBluetoothEnabled": [
                129
            ],
            "isGuestAccessEnabled": [
                129
            ],
            "oAuthParams": [
                380
            ],
            "provider": [
                559
            ],
            "providerSpecificParams": [
                323
            ],
            "__typename": [
                559
            ]
        },
        "LockProviderKey": {
            "id": [
                559
            ],
            "isSharable": [
                129
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "LockProviderType": {},
        "Long": {},
        "MainApplicantCreateInput": {
            "SSN": [
                559
            ],
            "birthDate": [
                654
            ],
            "buildingId": [
                293
            ],
            "city": [
                559
            ],
            "email": [
                559
            ],
            "firstName": [
                559
            ],
            "lastName": [
                559
            ],
            "middleName": [
                559
            ],
            "organizationId": [
                293
            ],
            "pets": [
                76
            ],
            "phone": [
                559
            ],
            "state": [
                559
            ],
            "street": [
                559
            ],
            "unitId": [
                293
            ],
            "zip": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "MainApplicantPublicData": {
            "email": [
                559
            ],
            "firstName": [
                559
            ],
            "id": [
                293
            ],
            "lastName": [
                559
            ],
            "middleName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ManagedCommunitiesWhereInput": {
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "MoveInFunnelRecord": {
            "leaseContract": [
                325
            ],
            "leaseContractId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "MoveInFunnelRecordList": {
            "pageInfo": [
                394
            ],
            "records": [
                347
            ],
            "__typename": [
                559
            ]
        },
        "MoveInFunnelStatus": {},
        "MoveInFunnelWhereInput": {
            "buildingIds": [
                293
            ],
            "communityIds": [
                293
            ],
            "leaseStartDateBetween": [
                197
            ],
            "organizationId": [
                293
            ],
            "status": [
                349
            ],
            "__typename": [
                559
            ]
        },
        "MoveInReconcileInput": {
            "communityId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "MoveInSettings": {
            "additionalInterestedParty": [
                129
            ],
            "buildingId": [
                559
            ],
            "disableDaysAfterMoveInDate": [
                301
            ],
            "enableDaysBeforeMoveInDate": [
                301
            ],
            "enabledLeaseStatuses": [
                186
            ],
            "enabledScreeningStatuses": [
                559
            ],
            "enabledUsers": [
                559
            ],
            "isEnabled": [
                129
            ],
            "openForRoommates": [
                129
            ],
            "organizationId": [
                559
            ],
            "useHardCodedTemplate": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "MoveInSettingsCreateInput": {
            "additionalInterestedParty": [
                129
            ],
            "disableDaysAfterMoveInDate": [
                301
            ],
            "enableDaysBeforeMoveInDate": [
                301
            ],
            "enabledLeaseStatuses": [
                186
            ],
            "enabledScreeningStatuses": [
                559
            ],
            "enabledUsers": [
                559
            ],
            "isEnabled": [
                129
            ],
            "openForRoommates": [
                129
            ],
            "useHardCodedTemplate": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "MoveInSettingsUpdateInput": {
            "additionalInterestedParty": [
                129
            ],
            "disableDaysAfterMoveInDate": [
                301
            ],
            "enableDaysBeforeMoveInDate": [
                301
            ],
            "enabledLeaseStatuses": [
                186
            ],
            "enabledScreeningStatuses": [
                559
            ],
            "enabledUsers": [
                559
            ],
            "isEnabled": [
                129
            ],
            "openForRoommates": [
                129
            ],
            "useHardCodedTemplate": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "MoveOutReason": {},
        "Mutation": {
            "ShiftNoteClose": [
                129,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "adapterCreate": [
                0,
                {
                    "data": [
                        1,
                        "AdapterDataInput!"
                    ]
                }
            ],
            "adapterUpdate": [
                0,
                {
                    "data": [
                        1,
                        "AdapterDataInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityCalendarCreate": [
                11,
                {
                    "data": [
                        14,
                        "AmenityCalendarCreateInput!"
                    ]
                }
            ],
            "amenityCalendarUnavailabilityDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityCalendarUnavailabilitySet": [
                129,
                {
                    "data": [
                        696,
                        "amenityCalendarUnavailabilitySetInput!"
                    ]
                }
            ],
            "amenityCalendarUpdate": [
                15,
                {
                    "data": [
                        20,
                        "AmenityCalendarUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityCancellationRequestDeny": [
                29,
                {
                    "data": [
                        22,
                        "AmenityCancellationDenialInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityCreate": [
                11,
                {
                    "data": [
                        23,
                        "AmenityCreateInput!"
                    ]
                }
            ],
            "amenityReservationApprove": [
                29,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityReservationCancel": [
                29,
                {
                    "data": [
                        31,
                        "AmenityReservationCancelInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityReservationCancelAndRefund": [
                29,
                {
                    "data": [
                        30,
                        "AmenityReservationCancelAndRefundInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityReservationCancelMany": [
                301,
                {
                    "data": [
                        31,
                        "AmenityReservationCancelInput!"
                    ],
                    "where": [
                        21,
                        "AmenityCancelManyWhereInput!"
                    ]
                }
            ],
            "amenityReservationCreate": [
                29,
                {
                    "data": [
                        32,
                        "AmenityReservationCreateInput!"
                    ]
                }
            ],
            "amenityReservationDeny": [
                29,
                {
                    "data": [
                        33,
                        "AmenityReservationDenialInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityUpdate": [
                11,
                {
                    "data": [
                        49,
                        "AmenityUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "appDashboardLinkCreate": [
                51,
                {
                    "data": [
                        53,
                        "AppDashboardLinkCreateInput!"
                    ]
                }
            ],
            "appDashboardLinkDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "appDashboardLinkOrder": [
                129,
                {
                    "data": [
                        54,
                        "[AppDashboardLinkOrderInput!]!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "appDashboardLinkUpdate": [
                51,
                {
                    "data": [
                        56,
                        "AppDashboardLinkUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicantConsentCreate": [
                129,
                {
                    "applicantId": [
                        293,
                        "ID!"
                    ],
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "signature": [
                        559,
                        "String!"
                    ]
                }
            ],
            "applicantCreate": [
                68,
                {
                    "input": [
                        344,
                        "MainApplicantCreateInput!"
                    ]
                }
            ],
            "applicantGenerateLease": [
                129,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "input": [
                        72,
                        "ApplicantGenerateLeaseInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicantReject": [
                129,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "input": [
                        78,
                        "ApplicantRejectInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicantScreeningReRun": [
                129,
                {
                    "applicantId": [
                        293,
                        "ID!"
                    ],
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicantStripeCheckoutCreate": [
                560,
                {
                    "applicantId": [
                        293,
                        "ID!"
                    ],
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicantUpdate": [
                83,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "input": [
                        82,
                        "ApplicantUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicantsInvite": [
                85,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "input": [
                        84,
                        "ApplicantsInviteInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicationSettingsUpdate": [
                87,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "input": [
                        88,
                        "ApplicationSettingsInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "authChallengeCreate": [
                93,
                {
                    "data": [
                        94,
                        "AuthChallengeCreateInput!"
                    ]
                }
            ],
            "authChallengeVerify": [
                96,
                {
                    "data": [
                        95,
                        "AuthChallengeVerifyInput!"
                    ]
                }
            ],
            "authTokenRefresh": [
                99,
                {
                    "data": [
                        98,
                        "AuthTokenRefreshInput!"
                    ]
                }
            ],
            "automationRuleCreate": [
                106,
                {
                    "data": [
                        109,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "automationRuleDelete": [
                129,
                {
                    "organizationID": [
                        293,
                        "ID!"
                    ],
                    "ruleId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "automationRuleSet": [
                106,
                {
                    "data": [
                        109,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "ruleId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeApprove": [
                116,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeCreate": [
                116,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        117,
                        "AwayNoticeCreateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeSettingsUpsert": [
                121,
                {
                    "data": [
                        122,
                        "AwayNoticeSettingsUpsertInput!"
                    ]
                }
            ],
            "brandCreate": [
                130,
                {
                    "data": [
                        133,
                        "BrandCreateInput!"
                    ]
                }
            ],
            "brandDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "brandUpdate": [
                130,
                {
                    "data": [
                        134,
                        "BrandUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "broadcastCreate": [
                135,
                {
                    "data": [
                        147,
                        "BroadcastCreateInput!"
                    ]
                }
            ],
            "broadcastDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "broadcastSend": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "broadcastUpdate": [
                135,
                {
                    "data": [
                        155,
                        "BroadcastUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "buildingSettingsSet": [
                163,
                {
                    "data": [
                        164,
                        "BuildingSettingsSetInput!"
                    ]
                }
            ],
            "buildingUnitStatusSet": [
                159,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "status": [
                        658,
                        "UnitStatus!"
                    ]
                }
            ],
            "buildingsAutomationRuleCreate": [
                106,
                {
                    "buildingIds": [
                        293,
                        "[ID!]!"
                    ],
                    "data": [
                        109,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "buildingsTaskListSet": [
                159,
                {
                    "data": [
                        166,
                        "BuildingsTaskListSetInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "chatMessageDelete": [
                129,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "messageId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "communitiesAutomationRuleCreate": [
                106,
                {
                    "communityIds": [
                        293,
                        "[ID!]!"
                    ],
                    "data": [
                        109,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "communityBuildingsSet": [
                176,
                {
                    "data": [
                        177,
                        "CommunityBuildingsSetInput!"
                    ]
                }
            ],
            "communityCreate": [
                175,
                {
                    "data": [
                        179,
                        "CommunityCreateInput!"
                    ]
                }
            ],
            "communitySettingsSet": [
                180,
                {
                    "data": [
                        181,
                        "CommunitySettingsSetInput!"
                    ]
                }
            ],
            "communityUpdate": [
                175,
                {
                    "data": [
                        184,
                        "CommunityUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "deliveryPackageCreate": [
                201,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        202,
                        "DeliveryPackageCreateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "deliveryPackageDelete": [
                129,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        206,
                        "DeliveryPackageWhereInput!"
                    ]
                }
            ],
            "deliveryPackageUpdate": [
                201,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        205,
                        "DeliveryPackageUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        206,
                        "DeliveryPackageWhereInput!"
                    ]
                }
            ],
            "emailSettingsDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "emailSettingsUpdate": [
                226,
                {
                    "data": [
                        227,
                        "EmailSettingsUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "embeddedApplicationAdapterCreate": [
                228,
                {
                    "data": [
                        229,
                        "EmbeddedApplicationAdapterCreateInput!"
                    ]
                }
            ],
            "embeddedApplicationAdapterUpdate": [
                228,
                {
                    "adapterId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        230,
                        "EmbeddedApplicationAdapterUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "eventCheckin": [
                129,
                {
                    "communityId": [
                        293
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "userIds": [
                        293,
                        "[ID!]!"
                    ]
                }
            ],
            "eventCreate": [
                238,
                {
                    "data": [
                        247,
                        "EventCreateInput!"
                    ]
                }
            ],
            "eventDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "eventUpdate": [
                238,
                {
                    "data": [
                        254,
                        "EventUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "externalLockProviderGroupCreate": [
                698,
                {
                    "data": [
                        699,
                        "externalLockProviderGroupCreateInput!"
                    ]
                }
            ],
            "externalLockProviderGroupDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "externalLockProviderGroupUpdate": [
                698,
                {
                    "data": [
                        701,
                        "externalLockProviderGroupUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "featuredSlideCreate": [
                263,
                {
                    "data": [
                        267,
                        "FeaturedSlideCreateInput!"
                    ]
                }
            ],
            "featuredSlideDelete": [
                129,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "featuredSlideUpdate": [
                263,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        271,
                        "FeaturedSlideUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "generalInfoPageCreate": [
                276,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        279,
                        "GeneralInfoPageCreateInput!"
                    ]
                }
            ],
            "generalInfoPageDelete": [
                129,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "generalInfoPageUpdate": [
                276,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        281,
                        "GeneralInfoPageUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroupCreate": [
                302,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        303,
                        "InterestGroupCreateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroupDelete": [
                129,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroupUpdate": [
                302,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        314,
                        "InterestGroupUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "leaseContractCreate": [
                325,
                {
                    "data": [
                        326,
                        "LeaseContractCreateInput!"
                    ]
                }
            ],
            "leaseContractTerminate": [
                325,
                {
                    "endDate": [
                        654,
                        "Time!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "me": [
                357
            ],
            "moveInReconcile": [
                129,
                {
                    "data": [
                        351,
                        "MoveInReconcileInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "moveInSettingsCreate": [
                352,
                {
                    "buildingId": [
                        293
                    ],
                    "data": [
                        353,
                        "MoveInSettingsCreateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "moveInSettingsUpdate": [
                352,
                {
                    "buildingId": [
                        293
                    ],
                    "data": [
                        354,
                        "MoveInSettingsUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "notificationSnoozeCreate": [
                369,
                {
                    "data": [
                        370,
                        "NotificationSnoozeCreateInput!"
                    ]
                }
            ],
            "notificationSnoozeUpdate": [
                369,
                {
                    "data": [
                        371,
                        "NotificationSnoozeUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "notificationsUpdateMany": [
                301,
                {
                    "data": [
                        376,
                        "NotificationUpdateInput!"
                    ],
                    "where": [
                        377,
                        "NotificationWhereInput!"
                    ]
                }
            ],
            "organizationAutomationRuleCreate": [
                106,
                {
                    "data": [
                        109,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "organizationCreate": [
                385,
                {
                    "data": [
                        386,
                        "OrganizationCreateInput!"
                    ]
                }
            ],
            "organizationMemberInvite": [
                387,
                {
                    "data": [
                        388,
                        "OrganizationMemberInviteInput!"
                    ]
                }
            ],
            "organizationMemberUpdate": [
                387,
                {
                    "data": [
                        390,
                        "OrganizationMemberUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "organizationUpdate": [
                385,
                {
                    "data": [
                        392,
                        "OrganizationUpdateInput!"
                    ]
                }
            ],
            "paymentReminderDelete": [
                129,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "paymentReminderSet": [
                398,
                {
                    "data": [
                        401,
                        "PaymentRemindersSetInput!"
                    ]
                }
            ],
            "periodicSurveyAssignmentsBatchCreate": [
                585,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "periodicSurveySettingsCreate": [
                406,
                {
                    "data": [
                        407,
                        "PeriodicSurveySettingsCreateInput!"
                    ]
                }
            ],
            "periodicSurveySettingsSet": [
                406,
                {
                    "data": [
                        408,
                        "PeriodicSurveySettingsSetInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "plaidAccessTokenCreate": [
                129,
                {
                    "input": [
                        422,
                        "PlaidAccessTokenCreateInput!"
                    ]
                }
            ],
            "plaidIncomeSkip": [
                129,
                {
                    "applicantId": [
                        293,
                        "ID!"
                    ],
                    "applicantSubmissionStep": [
                        79,
                        "ApplicantSubmissionStep!"
                    ],
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "plaidLinkTokenCreate": [
                424,
                {
                    "input": [
                        423,
                        "PlaidLinkTokenCreateInput!"
                    ]
                }
            ],
            "pmsAdapterCreate": [
                426,
                {
                    "data": [
                        427,
                        "PmsAdapterCreateInput!"
                    ]
                }
            ],
            "pmsAdapterTest": [
                428,
                {
                    "data": [
                        427,
                        "PmsAdapterCreateInput!"
                    ]
                }
            ],
            "pmsAdapterUpdate": [
                426,
                {
                    "adapterId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        429,
                        "PmsAdapterUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "pmsSyncJobCreate": [
                430,
                {
                    "data": [
                        431,
                        "PmsSyncJobCreateInput!"
                    ]
                }
            ],
            "reputationCreate": [
                456,
                {
                    "data": [
                        457,
                        "ReputationCreateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "reputationDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "reputationUpdate": [
                456,
                {
                    "data": [
                        458,
                        "ReputationUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "residentCreate": [
                461,
                {
                    "data": [
                        462,
                        "ResidentCreateInput!"
                    ]
                }
            ],
            "scheduledBroadcastCreate": [
                475,
                {
                    "data": [
                        476,
                        "ScheduledBroadcastCreateInput!"
                    ]
                }
            ],
            "scheduledBroadcastUpdate": [
                475,
                {
                    "data": [
                        477,
                        "ScheduledBroadcastUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestCancel": [
                575,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestCategorySet": [
                570,
                {
                    "categoryId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        491,
                        "ServiceRequestCategorySetInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestCreate": [
                497,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        493,
                        "ServiceRequestCreateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestSettingsCreate": [
                506,
                {
                    "buildingId": [
                        293
                    ],
                    "data": [
                        507,
                        "ServiceRequestsSettingsInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestSettingsSet": [
                506,
                {
                    "buildingId": [
                        293
                    ],
                    "data": [
                        508,
                        "ServiceRequestsSettingsSetInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestSettingsSurveyIdSet": [
                506,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "surveyId": [
                        559
                    ]
                }
            ],
            "serviceRequestSettingsUpdate": [
                506,
                {
                    "buildingId": [
                        293
                    ],
                    "data": [
                        507,
                        "ServiceRequestsSettingsInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestUpdate": [
                575,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        500,
                        "ServiceRequestUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsAdapterCreate": [
                502,
                {
                    "data": [
                        503,
                        "ServiceRequestsAdapterCreateInput!"
                    ]
                }
            ],
            "serviceRequestsAdapterDelete": [
                129,
                {
                    "adapterId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsAdapterSetStatusMapping": [
                502,
                {
                    "adapterId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "statusMapping": [
                        558,
                        "StatusMappings!"
                    ]
                }
            ],
            "serviceRequestsAdapterTest": [
                504,
                {
                    "data": [
                        503,
                        "ServiceRequestsAdapterCreateInput!"
                    ]
                }
            ],
            "serviceRequestsAdapterUpdate": [
                502,
                {
                    "adapterId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        505,
                        "ServiceRequestsAdapterUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsSetIsEnabled": [
                506,
                {
                    "buildingId": [
                        293
                    ],
                    "isEnabled": [
                        129,
                        "Boolean!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "setApplicantSubmissionStep": [
                129,
                {
                    "applicantId": [
                        293,
                        "ID!"
                    ],
                    "applicantSubmissionStep": [
                        79,
                        "ApplicantSubmissionStep!"
                    ],
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "setAwayNoticeAsArchived": [
                129,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "setShiftNotesAsArchived": [
                129,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "setVisitorAsArchived": [
                683,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "shiftNoteCreate": [
                513,
                {
                    "data": [
                        514,
                        "ShiftNoteCreateInput!"
                    ]
                }
            ],
            "shiftNoteUpdate": [
                513,
                {
                    "data": [
                        517,
                        "ShiftNoteUpdateInput!"
                    ]
                }
            ],
            "smartLockAdapterCreate": [
                520,
                {
                    "data": [
                        521,
                        "SmartLockAdapterCreateInput!"
                    ]
                }
            ],
            "smartLockAdapterDelete": [
                129,
                {
                    "adapterId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "smartLockAdapterUpdate": [
                520,
                {
                    "adapterId": [
                        293,
                        "ID!"
                    ],
                    "data": [
                        522,
                        "SmartLockAdapterUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "socialProfileSuspend": [
                129,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "suspendDurationInMinutes": [
                        301
                    ],
                    "userId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "staffTaskCreate": [
                552,
                {
                    "data": [
                        554,
                        "StaffTaskCreateInput!"
                    ]
                }
            ],
            "staffTaskDelete": [
                129,
                {
                    "where": [
                        557,
                        "StaffTaskWhereUniqueInput!"
                    ]
                }
            ],
            "staffTaskUpdate": [
                552,
                {
                    "data": [
                        555,
                        "StaffTaskUpdateInput!"
                    ],
                    "where": [
                        557,
                        "StaffTaskWhereUniqueInput!"
                    ]
                }
            ],
            "stripeConnectedAccountAddExisting": [
                561,
                {
                    "data": [
                        562,
                        "StripeConnectedAccountAddExistingInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "stripeConnectedAccountCreate": [
                561,
                {
                    "data": [
                        563,
                        "StripeConnectedAccountCreateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "stripeConnectedAccountSessionCreate": [
                564,
                {
                    "accountId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "stripeConnectedAccountUpdate": [
                561,
                {
                    "data": [
                        565,
                        "StripeConnectedAccountUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "supportTicketCommentCreate": [
                576,
                {
                    "data": [
                        577,
                        "SupportTicketCommentCreateInput!"
                    ]
                }
            ],
            "supportTicketCreate": [
                575,
                {
                    "data": [
                        578,
                        "SupportTicketCreateInput!"
                    ]
                }
            ],
            "surveyCreate": [
                584,
                {
                    "data": [
                        593,
                        "SurveyCreateInput!"
                    ]
                }
            ],
            "surveyQuestionUpdate": [
                594,
                {
                    "data": [
                        601,
                        "SurveyQuestionUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "surveyResponseSubmit": [
                585,
                {
                    "data": [
                        602,
                        "SurveyResponseSubmitInput!"
                    ]
                }
            ],
            "surveyUpdate": [
                584,
                {
                    "data": [
                        606,
                        "SurveyUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "syncJobCreate": [
                609,
                {
                    "data": [
                        610,
                        "SyncJobCreateInput!"
                    ]
                }
            ],
            "syncJobDownloadExport": [
                612,
                {
                    "data": [
                        611,
                        "SyncJobDownloadExportInput!"
                    ]
                }
            ],
            "syncJobPrepareExport": [
                129,
                {
                    "data": [
                        618,
                        "SyncJobPrepareExportInput!"
                    ]
                }
            ],
            "taskCreate": [
                625,
                {
                    "data": [
                        626,
                        "TaskCreateInput!"
                    ]
                }
            ],
            "taskListCreate": [
                627,
                {
                    "data": [
                        629,
                        "TaskListCreateInput!"
                    ]
                }
            ],
            "taskListCreateFrom": [
                627,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "input": [
                        628,
                        "TaskListCreateFromInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskListDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskListTaskCreate": [
                630,
                {
                    "data": [
                        631,
                        "TaskListTaskCreateInput!"
                    ]
                }
            ],
            "taskListTaskDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskListTaskReorder": [
                630,
                {
                    "data": [
                        632,
                        "TaskListTaskReorderInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskListTaskUpdate": [
                630,
                {
                    "data": [
                        633,
                        "TaskListTaskUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskListUpdate": [
                627,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "input": [
                        636,
                        "TaskListUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskUpdate": [
                625,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "input": [
                        639,
                        "TaskUpdateInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "teamBuildingPermissionsSet": [
                129,
                {
                    "data": [
                        644,
                        "TeamBuildingPermissionsSetInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "teamBuildingsPermissionsAdd": [
                129,
                {
                    "data": [
                        645,
                        "TeamBuildingsPermissionsAddInput!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "teamCreate": [
                642,
                {
                    "data": [
                        646,
                        "TeamCreateInput!"
                    ]
                }
            ],
            "teamDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "teamUpdate": [
                642,
                {
                    "data": [
                        648,
                        "TeamUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "unitIsAcceptingApplicationsSet": [
                656,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "isAcceptingApplications": [
                        129,
                        "Boolean!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "unitIds": [
                        293,
                        "[ID!]!"
                    ]
                }
            ],
            "unitStatusSet": [
                656,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "status": [
                        658,
                        "UnitStatus!"
                    ],
                    "unitIds": [
                        293,
                        "[ID!]!"
                    ]
                }
            ],
            "userLoginApiKey": [
                666,
                {
                    "data": [
                        665,
                        "UserLoginApiKeyInput!"
                    ]
                }
            ],
            "visitorCreate": [
                683,
                {
                    "data": [
                        684,
                        "VisitorCreateInput!"
                    ]
                }
            ],
            "whiteLabelCreate": [
                693,
                {
                    "data": [
                        694,
                        "WhiteLabelCreateInput!"
                    ]
                }
            ],
            "whiteLabelDelete": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "whiteLabelUpdate": [
                693,
                {
                    "data": [
                        695,
                        "WhiteLabelUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "__typename": [
                559
            ]
        },
        "MutationUserRoot": {
            "ActiveLeaseContracts": [
                325
            ],
            "MyAwayNoticeCreate": [
                116,
                {
                    "data": [
                        117,
                        "AwayNoticeCreateInput!"
                    ]
                }
            ],
            "MyAwayNoticeSetArchived": [
                129,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyAwayNoticeUpdate": [
                116,
                {
                    "data": [
                        124,
                        "AwayNoticeUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyDeliveryPackageStatusSet": [
                129,
                {
                    "packageId": [
                        559,
                        "String!"
                    ],
                    "pickUpDate": [
                        654
                    ],
                    "status": [
                        204,
                        "DeliveryPackageStatus!"
                    ]
                }
            ],
            "MyEmbeddedAppAuthenticatedURLGenerate": [
                463,
                {
                    "EmbeddedAppID": [
                        293,
                        "ID!"
                    ],
                    "embeddedAppRoute": [
                        559
                    ],
                    "leaseContractID": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyEventAttend": [
                241,
                {
                    "eventId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyEventCheckIn": [
                241,
                {
                    "eventId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyEventCheckInWithScan": [
                241,
                {
                    "data": [
                        246,
                        "EventCheckInWithScanInput!"
                    ]
                }
            ],
            "MyEventCheckinCancel": [
                241,
                {
                    "eventId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyEventRatingSet": [
                238,
                {
                    "eventId": [
                        293,
                        "ID!"
                    ],
                    "rating": [
                        301,
                        "Int!"
                    ],
                    "review": [
                        559
                    ]
                }
            ],
            "MyEventSuggest": [
                238,
                {
                    "data": [
                        252,
                        "EventSuggestionInput!"
                    ]
                }
            ],
            "MyEventUnattend": [
                241,
                {
                    "eventId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyFirstLoginSet": [
                129
            ],
            "MyHubMetadataSet": [
                662,
                {
                    "data": [
                        292,
                        "HubMetadataSetInput!"
                    ]
                }
            ],
            "MyLockProvidersInitializeAccess": [
                340
            ],
            "MyMobileLastVisit": [
                129
            ],
            "MyNotificationSendYourselfTest": [
                364,
                {
                    "data": [
                        147,
                        "BroadcastCreateInput!"
                    ]
                }
            ],
            "MyNotificationUpdate": [
                364,
                {
                    "NotificationData": [
                        376,
                        "NotificationUpdateInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyPersonalInformationUpdate": [
                662,
                {
                    "data": [
                        413,
                        "PersonalInformationUpdateInput!"
                    ]
                }
            ],
            "MyPushNotificationTokenRegister": [
                129,
                {
                    "data": [
                        452,
                        "RegisterUserDeviceInput!"
                    ]
                }
            ],
            "MyPushNotificationTokenUnRegister": [
                129,
                {
                    "data": [
                        660,
                        "UnregisterUserDeviceInput!"
                    ]
                }
            ],
            "MyReservationCancel": [
                29,
                {
                    "data": [
                        31,
                        "AmenityReservationCancelInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyReservationCancelMany": [
                301,
                {
                    "data": [
                        31,
                        "AmenityReservationCancelInput!"
                    ],
                    "where": [
                        21,
                        "AmenityCancelManyWhereInput!"
                    ]
                }
            ],
            "MyReservationCheckout": [
                360,
                {
                    "data": [
                        359,
                        "MyReservationCheckoutInput!"
                    ]
                }
            ],
            "MyReservationCreate": [
                29,
                {
                    "data": [
                        47,
                        "AmenityReservationUserCreateInput!"
                    ]
                }
            ],
            "MyReservationPay": [
                29,
                {
                    "data": [
                        38,
                        "AmenityReservationPayInput!"
                    ]
                }
            ],
            "MyReservationRequestCancel": [
                29,
                {
                    "data": [
                        40,
                        "AmenityReservationRequestCancelInput!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyServiceRequestCommentCreate": [
                492,
                {
                    "data": [
                        361,
                        "MyServiceRequestCommentCreateInput!"
                    ]
                }
            ],
            "MySmartLockGuestKeyGenerate": [
                284,
                {
                    "data": [
                        523,
                        "SmartLockGuestKeyGenerateInput!"
                    ]
                }
            ],
            "MySmartLockOAuthTokenGenerate": [
                300,
                {
                    "data": [
                        524,
                        "SmartLockOAuthTokenGenerateInput!"
                    ]
                }
            ],
            "MySmartLockOAuthTokenRefresh": [
                300,
                {
                    "data": [
                        525,
                        "SmartLockOAuthTokenRefreshInput!"
                    ]
                }
            ],
            "MySmartLockRegisterWebhook": [
                129,
                {
                    "data": [
                        526,
                        "SmartLockWebhookRegisterInput!"
                    ]
                }
            ],
            "MySmartLockUnRegisterWebhook": [
                129,
                {
                    "data": [
                        527,
                        "SmartLockWebhookUnregisterInput!"
                    ]
                }
            ],
            "MySurveyResponseSubmit": [
                585,
                {
                    "data": [
                        603,
                        "SurveyResponseUserSubmitInput!"
                    ]
                }
            ],
            "MySurveySnooze": [
                585,
                {
                    "assignmentId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "MyThermostatSet": [
                129,
                {
                    "data": [
                        651
                    ]
                }
            ],
            "MyThermostatSetState": [
                129,
                {
                    "data": [
                        652
                    ]
                }
            ],
            "MyUserTaskStatusUpdate": [
                674,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "status": [
                        673,
                        "UserTaskStatus!"
                    ]
                }
            ],
            "MyVisitorCreate": [
                683,
                {
                    "data": [
                        684,
                        "VisitorCreateInput!"
                    ]
                }
            ],
            "MyVisitorSetArchived": [
                683,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "UserId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "MyLeaseContractWhereInput": {
            "statusIn": [
                186
            ],
            "__typename": [
                559
            ]
        },
        "MyReservationCheckoutInput": {
            "amenityId": [
                559
            ],
            "cancelUrl": [
                559
            ],
            "paymentMethod": [
                397
            ],
            "reservationId": [
                559
            ],
            "successUrl": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "MyReservationCheckoutResponse": {
            "checkoutPageUrl": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "MyServiceRequestCommentCreateInput": {
            "body": [
                559
            ],
            "fileKeys": [
                559
            ],
            "ticketId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "NeighborsList": {
            "items": [
                535
            ],
            "pageInfo": [
                394
            ],
            "seed": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "NewDiscussionAddedSubscription": {
            "hoodId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "Notification": {
            "broadcast": [
                135
            ],
            "category": [
                365
            ],
            "clickedOnCta": [
                129
            ],
            "createdAt": [
                654
            ],
            "createdBy": [
                662
            ],
            "createdByUserId": [
                293
            ],
            "cta": [
                195
            ],
            "deepLink": [
                199
            ],
            "id": [
                293
            ],
            "message": [
                559
            ],
            "recipientStatus": [
                368
            ],
            "sourceType": [
                559
            ],
            "status": [
                374
            ],
            "title": [
                559
            ],
            "toUser": [
                662
            ],
            "toUserId": [
                293
            ],
            "visibleToUser": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "NotificationCategory": {
            "createdAt": [
                654
            ],
            "description": [
                559
            ],
            "id": [
                293
            ],
            "isMandatory": [
                129
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "NotificationCategoryWhereInput": {
            "isMandatory": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "NotificationMedium": {},
        "NotificationRecipientStatus": {},
        "NotificationSnooze": {
            "expiration": [
                654
            ],
            "id": [
                293
            ],
            "notificationCategory": [
                365
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "NotificationSnoozeCreateInput": {
            "enabled": [
                129
            ],
            "expiration": [
                654
            ],
            "medium": [
                367
            ],
            "notificationCategoryId": [
                293
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "NotificationSnoozeUpdateInput": {
            "enabled": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "NotificationSnoozeWhereInput": {
            "enabled": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "NotificationSnoozesList": {
            "items": [
                369
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "NotificationStatus": {},
        "NotificationType": {},
        "NotificationUpdateInput": {
            "clickedOnCta": [
                129
            ],
            "recipientStatus": [
                368
            ],
            "__typename": [
                559
            ]
        },
        "NotificationWhereInput": {
            "createdAtGt": [
                654
            ],
            "medium": [
                367
            ],
            "recipientStatusIn": [
                368
            ],
            "recipientStatusNotIn": [
                368
            ],
            "sourceTypeIn": [
                559
            ],
            "status": [
                374
            ],
            "toUserId": [
                293
            ],
            "toUserSearchKeys": [
                559
            ],
            "type": [
                375
            ],
            "visibleToUser": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "NotificationsList": {
            "items": [
                364
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "NotificationsOrderBy": {},
        "OAuthParams": {
            "apiBaseURL": [
                559
            ],
            "authURL": [
                559
            ],
            "clientId": [
                559
            ],
            "tokenURL": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "OauthSettings": {
            "apiBaseURL": [
                559
            ],
            "authURL": [
                559
            ],
            "clientId": [
                559
            ],
            "clientSecret": [
                559
            ],
            "tokenURL": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "OauthSettingsInput": {
            "apiBaseURL": [
                559
            ],
            "authURL": [
                559
            ],
            "clientId": [
                559
            ],
            "clientSecret": [
                559
            ],
            "tokenURL": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "OrderedActiveUserTaskLists": {
            "userTaskList": [
                671
            ],
            "userTasks": [
                670
            ],
            "__typename": [
                559
            ]
        },
        "OrderedActiveUserTaskListsWhereInput": {
            "communityId": [
                293
            ],
            "type": [
                635
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "Organization": {
            "buildings": [
                159,
                {
                    "where": [
                        167
                    ]
                }
            ],
            "communities": [
                175
            ],
            "id": [
                293
            ],
            "logoUrl": [
                559
            ],
            "name": [
                559
            ],
            "subdomain": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "OrganizationCreateInput": {
            "organizationName": [
                559
            ],
            "user": [
                664
            ],
            "__typename": [
                559
            ]
        },
        "OrganizationMember": {
            "id": [
                293
            ],
            "inviteDate": [
                654
            ],
            "inviteStatus": [
                318
            ],
            "isActive": [
                129
            ],
            "organizationId": [
                293
            ],
            "teams": [
                642
            ],
            "type": [
                389
            ],
            "user": [
                662
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "OrganizationMemberInviteInput": {
            "email": [
                559
            ],
            "firstName": [
                559
            ],
            "isActive": [
                129
            ],
            "lastName": [
                559
            ],
            "mobilePhone": [
                559
            ],
            "organizationId": [
                293
            ],
            "teamIds": [
                293
            ],
            "type": [
                389
            ],
            "__typename": [
                559
            ]
        },
        "OrganizationMemberType": {},
        "OrganizationMemberUpdateInput": {
            "isActive": [
                129
            ],
            "teamIdsSet": [
                293
            ],
            "type": [
                389
            ],
            "__typename": [
                559
            ]
        },
        "OrganizationMembersWhereInput": {
            "searchKey": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "OrganizationUpdateInput": {
            "logoUrl": [
                559
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "OrganizerDetails": {
            "mobilePhone": [
                559
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PageInfo": {
            "totalItems": [
                343
            ],
            "__typename": [
                559
            ]
        },
        "PageInput": {
            "offset": [
                301
            ],
            "size": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "PaymentDetails": {
            "serviceFee": [
                274
            ],
            "totalPrice": [
                274
            ],
            "__typename": [
                559
            ]
        },
        "PaymentMethod": {},
        "PaymentReminders": {
            "lateReminderSchedule": [
                399
            ],
            "reminderSchedule": [
                399
            ],
            "__typename": [
                559
            ]
        },
        "PaymentRemindersSchedule": {
            "dayOfMonth": [
                301
            ],
            "hourOfDay": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "PaymentRemindersScheduleInput": {
            "dayOfMonth": [
                301
            ],
            "hourOfDay": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "PaymentRemindersSetInput": {
            "buildingId": [
                293
            ],
            "lateReminderSchedule": [
                400
            ],
            "reminderSchedule": [
                400
            ],
            "__typename": [
                559
            ]
        },
        "PeriodicSurveyQuestionResponseStats": {
            "count": [
                301
            ],
            "rating": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "PeriodicSurveyQuestionStats": {
            "averageScore": [
                274
            ],
            "questionId": [
                293
            ],
            "responsesStats": [
                402
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PeriodicSurveyReport": {
            "batchId": [
                293
            ],
            "questionsStats": [
                403
            ],
            "surveyStats": [
                409
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PeriodicSurveyReportWhereInput": {
            "surveyBatchId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PeriodicSurveySettings": {
            "id": [
                293
            ],
            "minResidentialDays": [
                301
            ],
            "nextRunDate": [
                654
            ],
            "organizationId": [
                293
            ],
            "runIntervalDays": [
                301
            ],
            "surveyId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PeriodicSurveySettingsCreateInput": {
            "firstRunDate": [
                654
            ],
            "minResidentialDays": [
                301
            ],
            "organizationId": [
                293
            ],
            "runIntervalDays": [
                301
            ],
            "surveyId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PeriodicSurveySettingsSetInput": {
            "id": [
                293
            ],
            "minResidentialDays": [
                301
            ],
            "runIntervalDays": [
                301
            ],
            "surveyId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PeriodicSurveyStats": {
            "completed": [
                301
            ],
            "opened": [
                301
            ],
            "sent": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "Permission": {},
        "PermittedBuilding": {
            "building": [
                159
            ],
            "buildingId": [
                293
            ],
            "permissions": [
                410
            ],
            "__typename": [
                559
            ]
        },
        "PermittedBuildingsWhereInput": {
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PersonalInformationUpdateInput": {
            "firstName": [
                559
            ],
            "lastName": [
                559
            ],
            "profilePictureUrl": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Pet": {
            "bio": [
                559
            ],
            "breed": [
                559
            ],
            "id": [
                293
            ],
            "imgUrl": [
                559
            ],
            "name": [
                559
            ],
            "tags": [
                559
            ],
            "type": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PetCreateInput": {
            "bio": [
                559
            ],
            "breed": [
                559
            ],
            "imgUrl": [
                559
            ],
            "name": [
                559
            ],
            "socialProfileId": [
                293
            ],
            "tags": [
                559
            ],
            "type": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PetSex": {},
        "PetType": {},
        "PetUpdateInput": {
            "bio": [
                559
            ],
            "breed": [
                559
            ],
            "imgUrl": [
                559
            ],
            "name": [
                559
            ],
            "tags": [
                559
            ],
            "type": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PhoneLinkData": {
            "phoneNumber": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PhoneLinkDataInput": {
            "phoneNumber": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Place": {
            "address": [
                5
            ],
            "addressId": [
                293
            ],
            "bookableAsset": [
                50
            ],
            "building": [
                159
            ],
            "description": [
                559
            ],
            "events": [
                238,
                {
                    "where": [
                        255
                    ]
                }
            ],
            "hoodId": [
                293
            ],
            "id": [
                293
            ],
            "imageResourceId": [
                559
            ],
            "name": [
                559
            ],
            "openingHours": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PlaidAccessTokenCreateInput": {
            "applicantId": [
                293
            ],
            "buildingId": [
                293
            ],
            "organizationId": [
                293
            ],
            "publicToken": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PlaidLinkTokenCreateInput": {
            "applicantId": [
                293
            ],
            "buildingId": [
                293
            ],
            "linkType": [
                425
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PlaidLinkTokenResponse": {
            "linkToken": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PlaidLinkType": {},
        "PmsAdapter": {
            "endpoint": [
                559
            ],
            "id": [
                293
            ],
            "lastFailureSync": [
                430
            ],
            "lastFailureSyncJobId": [
                293
            ],
            "lastSuccessSync": [
                430
            ],
            "lastSuccessSyncJobId": [
                293
            ],
            "lastSyncJob": [
                430
            ],
            "lastSyncJobId": [
                293
            ],
            "name": [
                559
            ],
            "organization": [
                385
            ],
            "organizationId": [
                293
            ],
            "periodicSyncEnabled": [
                129
            ],
            "settings": [
                481
            ],
            "__typename": [
                559
            ]
        },
        "PmsAdapterCreateInput": {
            "endpoint": [
                559
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "periodicSyncEnabled": [
                129
            ],
            "settings": [
                482
            ],
            "__typename": [
                559
            ]
        },
        "PmsAdapterTestResult": {
            "message": [
                559
            ],
            "success": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "PmsAdapterUpdateInput": {
            "endpoint": [
                559
            ],
            "name": [
                559
            ],
            "periodicSyncEnabled": [
                129
            ],
            "settings": [
                482
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncJob": {
            "adapterId": [
                293
            ],
            "forceUpdate": [
                129
            ],
            "hoodId": [
                293
            ],
            "id": [
                293
            ],
            "items": [
                433,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "organizationId": [
                293
            ],
            "results": [
                435
            ],
            "startedAt": [
                654
            ],
            "status": [
                436
            ],
            "updatedAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncJobCreateInput": {
            "adapterId": [
                293
            ],
            "forceUpdate": [
                129
            ],
            "hoodId": [
                293
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncJobItem": {
            "entityData": [
                50
            ],
            "entityId": [
                293
            ],
            "entityType": [
                320
            ],
            "errors": [
                439
            ],
            "id": [
                293
            ],
            "jobId": [
                293
            ],
            "status": [
                321
            ],
            "vennId": [
                293
            ],
            "warnings": [
                439
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncJobItemList": {
            "items": [
                432
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncJobItemsWhereInput": {
            "statusIn": [
                321
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncJobResult": {
            "countPerStatus": [
                322
            ],
            "entityType": [
                320
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncJobStatus": {},
        "PmsSyncJobWhereInput": {
            "adapterId": [
                293
            ],
            "idIn": [
                293
            ],
            "startedBetween": [
                197
            ],
            "statusIn": [
                436
            ],
            "updatedBetween": [
                197
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncJobsList": {
            "PageInfo": [
                394
            ],
            "items": [
                430
            ],
            "__typename": [
                559
            ]
        },
        "PmsSyncMessage": {
            "code": [
                559
            ],
            "message": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Portfolio": {
            "id": [
                293
            ],
            "name": [
                559
            ],
            "subdomain": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "PortfolioContextFilter": {
            "buildingIds": [
                293
            ],
            "hoodIds": [
                293
            ],
            "portfolioId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PortfolioContextFilterInput": {
            "buildingIds": [
                293
            ],
            "hoodIds": [
                293
            ],
            "portfolioId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "PortfolioRoot": {
            "contextFilter": [
                441
            ],
            "hoods": [
                288,
                {
                    "ids": [
                        293,
                        "[ID!]"
                    ]
                }
            ],
            "id": [
                293
            ],
            "leaseContracts": [
                330,
                {
                    "orderBy": [
                        327,
                        "[LeaseContractOrderBy!]"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        329
                    ]
                }
            ],
            "pmsSyncJobs": [
                438,
                {
                    "where": [
                        437
                    ]
                }
            ],
            "portfolioDetails": [
                440
            ],
            "reports": [
                455
            ],
            "residents": [
                469,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        468
                    ]
                }
            ],
            "survey": [
                584,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "surveys": [
                608
            ],
            "__typename": [
                559
            ]
        },
        "PriceByPaymentMethod": {
            "ach": [
                396
            ],
            "creditCard": [
                396
            ],
            "__typename": [
                559
            ]
        },
        "Query": {
            "amenities": [
                8,
                {
                    "orderBy": [
                        9,
                        "[AmenitiesOrderBy]"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        10
                    ]
                }
            ],
            "amenity": [
                11,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityCalendarUnavailabilities": [
                18,
                {
                    "orderBy": [
                        19,
                        "[AmenityCalendarUnavailabilityOrderBy]"
                    ],
                    "where": [
                        17
                    ]
                }
            ],
            "amenityCalendarUnavailability": [
                18,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityReservation": [
                29,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "amenityReservations": [
                29,
                {
                    "orderBy": [
                        37,
                        "[AmenityReservationOrderBy]"
                    ],
                    "where": [
                        48
                    ]
                }
            ],
            "appDashboardLink": [
                51,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "appDashboardLinks": [
                51,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicant": [
                67,
                {
                    "applicantId": [
                        293,
                        "ID!"
                    ],
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicantConsentPreview": [
                559,
                {
                    "applicantId": [
                        293,
                        "ID!"
                    ],
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicantPublicData": [
                77,
                {
                    "applicantId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "applicants": [
                67,
                {
                    "orderBy": [
                        75,
                        "[ApplicantOrderBy!]"
                    ],
                    "where": [
                        71,
                        "ApplicantFilter!"
                    ]
                }
            ],
            "applicationSettings": [
                87,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "automationDescriptor": [
                102,
                {
                    "automationDescriptorId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "automationDescriptors": [
                102,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "automationRules": [
                106,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "automationTriggerHistory": [
                114,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        115
                    ]
                }
            ],
            "awayNotice": [
                116,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeSettings": [
                121,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeSettingsList": [
                121,
                {
                    "buildingsIds": [
                        293,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "awayNotices": [
                116,
                {
                    "buildingIds": [
                        293,
                        "[ID!]!"
                    ],
                    "orderBy": [
                        118
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        125
                    ]
                }
            ],
            "brand": [
                130,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "brands": [
                130,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "broadcast": [
                135,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "broadcasts": [
                157,
                {
                    "orderBy": [
                        158,
                        "[BroadcastsOrderBy!]"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        156,
                        "BroadcastWhereInput!"
                    ]
                }
            ],
            "building": [
                159,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "communities": [
                175,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        185
                    ]
                }
            ],
            "community": [
                175,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "deliveryPackage": [
                201,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        206,
                        "DeliveryPackageWhereInput!"
                    ]
                }
            ],
            "deliveryPackages": [
                207,
                {
                    "buildingIds": [
                        293,
                        "[ID!]!"
                    ],
                    "orderBy": [
                        203
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        208
                    ]
                }
            ],
            "emailSettings": [
                226,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "embeddedApplicationAdapter": [
                228,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "embeddedApplicationAdapters": [
                228,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        231
                    ]
                }
            ],
            "event": [
                238,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "events": [
                256,
                {
                    "orderBy": [
                        257,
                        "[EventsOrderBy!]"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        255,
                        "EventWhereInput!"
                    ]
                }
            ],
            "externalLockProviderGroup": [
                698,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "externalLockProviderGroups": [
                700,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "pager": [
                        395
                    ]
                }
            ],
            "featuredSlide": [
                263,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "featuredSlides": [
                270,
                {
                    "orderBy": [
                        273,
                        "FeaturedSlidesOrderBy!"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        272
                    ]
                }
            ],
            "generalInfoPage": [
                276,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "generalInfoPages": [
                280,
                {
                    "orderBy": [
                        283,
                        "GeneralInfoPagesOrderBy!"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        282,
                        "GeneralInfoPageWhereInput!"
                    ]
                }
            ],
            "hood": [
                288,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroup": [
                302,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroupInsightCategories": [
                304,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroupInsightDiscussion": [
                306,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroupInsightDiscussions": [
                306,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        307,
                        "InterestGroupInsightDiscussionWhereInput!"
                    ]
                }
            ],
            "interestGroupInsightSummary": [
                308,
                {
                    "communityId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "startedBetween": [
                        197
                    ]
                }
            ],
            "interestGroupLabels": [
                309,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroupMembers": [
                311,
                {
                    "interestGroupId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "interestGroups": [
                302,
                {
                    "communityIds": [
                        293,
                        "[ID!]"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "leaseContract": [
                325,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "leaseContracts": [
                330,
                {
                    "orderBy": [
                        327,
                        "[LeaseContractOrderBy!]"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        329,
                        "LeaseContractWhereInput!"
                    ]
                }
            ],
            "me": [
                668
            ],
            "moveInFunnel": [
                348,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        350,
                        "MoveInFunnelWhereInput!"
                    ]
                }
            ],
            "moveInSettings": [
                352,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "notificationCategories": [
                365,
                {
                    "where": [
                        366
                    ]
                }
            ],
            "orderedActiveUserTaskLists": [
                383,
                {
                    "where": [
                        384,
                        "OrderedActiveUserTaskListsWhereInput!"
                    ]
                }
            ],
            "organization": [
                385,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "organizationMember": [
                387,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "organizationMembers": [
                387,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "organizations": [
                385
            ],
            "periodicSurveyReport": [
                404,
                {
                    "buildingIds": [
                        293,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        405,
                        "PeriodicSurveyReportWhereInput!"
                    ]
                }
            ],
            "periodicSurveySettings": [
                406,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "pmsAdapter": [
                426,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "pmsAdapters": [
                426,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "pmsSyncJob": [
                430,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "pmsSyncJobs": [
                438,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        437,
                        "PmsSyncJobWhereInput!"
                    ]
                }
            ],
            "portfolio": [
                443,
                {
                    "contextFilter": [
                        442,
                        "PortfolioContextFilterInput!"
                    ]
                }
            ],
            "portfolios": [
                440
            ],
            "reputation": [
                456,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "reputations": [
                456,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "residentRenewalRiskScore": [
                453,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "residents": [
                469,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        468,
                        "ResidentWhereInput!"
                    ]
                }
            ],
            "residentsUpForRenewal": [
                453,
                {
                    "buildingIds": [
                        293,
                        "[ID!]!"
                    ],
                    "filters": [
                        470
                    ],
                    "orderBy": [
                        466
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestAdapters": [
                502,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestAttachments": [
                490,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "serviceRequestTicketId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestSettings": [
                506,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequests": [
                494,
                {
                    "buildingIds": [
                        293,
                        "[ID!]"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        512,
                        "ServiceRequestsWhereInput!"
                    ]
                }
            ],
            "serviceRequestsAdapter": [
                502,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsAdapterDefaultStatusMapping": [
                495,
                {
                    "adapterId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsCategories": [
                570,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "shiftNote": [
                513,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "shiftNotes": [
                513,
                {
                    "orderBy": [
                        515
                    ],
                    "where": [
                        518,
                        "ShiftNoteWhereInput!"
                    ]
                }
            ],
            "smartLockAdapter": [
                520,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "smartLockAdapters": [
                520,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "staffTask": [
                552,
                {
                    "where": [
                        557,
                        "StaffTaskWhereUniqueInput!"
                    ]
                }
            ],
            "staffTasks": [
                552,
                {
                    "where": [
                        556
                    ]
                }
            ],
            "stripeConnectedAccounts": [
                561,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "survey": [
                584,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "surveyQuestionResponseData": [
                330,
                {
                    "buildingsIds": [
                        293,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        598
                    ]
                }
            ],
            "surveys": [
                608,
                {
                    "where": [
                        607,
                        "SurveyWhereInput!"
                    ]
                }
            ],
            "surveysBatches": [
                591,
                {
                    "buildingIds": [
                        293,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        590
                    ]
                }
            ],
            "syncJob": [
                609,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "syncJobs": [
                623,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        622,
                        "SyncJobWhereInput!"
                    ]
                }
            ],
            "task": [
                625,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskList": [
                627,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskListTask": [
                630,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskListTasks": [
                630,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        634,
                        "TaskListTaskWhereInput!"
                    ]
                }
            ],
            "taskLists": [
                627,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        637,
                        "TaskListWhereInput!"
                    ]
                }
            ],
            "tasks": [
                625,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        640,
                        "TaskWhereInput!"
                    ]
                }
            ],
            "team": [
                642,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "teamBuildingPermissions": [
                643,
                {
                    "buildingId": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "teamId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "teamBuildingPermissionsList": [
                643,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "teamId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "teams": [
                642,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        649
                    ]
                }
            ],
            "unit": [
                656,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "unitsAcceptingApplications": [
                657,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "user": [
                662,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "userTask": [
                670,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "userTasks": [
                670,
                {
                    "where": [
                        675,
                        "UserTasksWhereInput!"
                    ]
                }
            ],
            "visitor": [
                683,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "visitors": [
                683,
                {
                    "buildingIds": [
                        293,
                        "[ID!]"
                    ],
                    "orderBy": [
                        685
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ],
                    "where": [
                        689
                    ]
                }
            ],
            "whiteLabel": [
                693,
                {
                    "id": [
                        293,
                        "ID!"
                    ],
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "whiteLabels": [
                693,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "__typename": [
                559
            ]
        },
        "QueryParam": {
            "key": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Rating": {
            "comment": [
                559
            ],
            "score": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "RealEstateAmenity": {
            "building": [
                159
            ],
            "buildingId": [
                559
            ],
            "category": [
                449
            ],
            "createdAt": [
                654
            ],
            "currentStatus": [
                450
            ],
            "currentStatusDescription": [
                559
            ],
            "deleted": [
                301
            ],
            "deletedAt": [
                654
            ],
            "id": [
                293
            ],
            "unit": [
                656
            ],
            "unitId": [
                559
            ],
            "updatedAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "RealEstateAmenityCategory": {
            "displayName": [
                559
            ],
            "hood": [
                286
            ],
            "hoodId": [
                559
            ],
            "icon": [
                559
            ],
            "id": [
                293
            ],
            "isPublic": [
                129
            ],
            "manageStatus": [
                129
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "RealEstateAmenityStatus": {},
        "RealEstateListing": {
            "address": [
                5
            ],
            "amenities": [
                448
            ],
            "bedroom": [
                126
            ],
            "bedroomId": [
                559
            ],
            "createdAt": [
                654
            ],
            "deleted": [
                301
            ],
            "deletedAt": [
                654
            ],
            "description": [
                559
            ],
            "featuredListing": [
                129
            ],
            "floorPlan": [
                50
            ],
            "hood": [
                286
            ],
            "hoodName": [
                559
            ],
            "id": [
                293
            ],
            "images": [
                50
            ],
            "listingPrice": [
                274
            ],
            "name": [
                559
            ],
            "nearBySubways": [
                567
            ],
            "promoBadgeTooltip": [
                559
            ],
            "publishInListing": [
                129
            ],
            "relatedListings": [
                50
            ],
            "shortDescription": [
                559
            ],
            "slug": [
                559
            ],
            "unit": [
                656
            ],
            "unitId": [
                559
            ],
            "updatedAt": [
                654
            ],
            "virtualTour": [
                50
            ],
            "__typename": [
                559
            ]
        },
        "RegisterUserDeviceInput": {
            "deviceId": [
                559
            ],
            "deviceName": [
                559
            ],
            "devicePlatform": [
                210
            ],
            "deviceToken": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "RenewalRiskScore": {
            "coOccupantsLeaseIds": [
                293
            ],
            "coOccupantsLeases": [
                325
            ],
            "createdAt": [
                654
            ],
            "id": [
                293
            ],
            "mainLeaseContract": [
                325
            ],
            "mainLeaseContractId": [
                293
            ],
            "medianResolutionTime": [
                274
            ],
            "repeatingRequestsCount": [
                301
            ],
            "riskCategory": [
                471
            ],
            "riskScore": [
                274
            ],
            "secondaryLeaseContracts": [
                325
            ],
            "serviceRequestsCount": [
                301
            ],
            "serviceRequestsTopics": [
                559
            ],
            "updatedAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "RentingType": {},
        "ReportsRoot": {
            "contextFilter": [
                441
            ],
            "moveInFunnel": [
                348,
                {
                    "pager": [
                        395,
                        "PageInput!"
                    ],
                    "where": [
                        350,
                        "MoveInFunnelWhereInput!"
                    ]
                }
            ],
            "__typename": [
                559
            ]
        },
        "Reputation": {
            "buildingsIds": [
                293
            ],
            "id": [
                293
            ],
            "organizationId": [
                559
            ],
            "reputationLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ReputationCreateInput": {
            "buildingsIds": [
                293
            ],
            "reputationLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ReputationUpdateInput": {
            "buildingsIds": [
                293
            ],
            "reputationLink": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ReservationQuotaFrequency": {},
        "ReservationQuotaType": {},
        "Resident": {
            "email": [
                559
            ],
            "events": [
                238,
                {
                    "where": [
                        465
                    ]
                }
            ],
            "firstName": [
                559
            ],
            "lastName": [
                559
            ],
            "leaseContracts": [
                325
            ],
            "metadata": [
                50
            ],
            "middleName": [
                559
            ],
            "mobilePhone": [
                559
            ],
            "myEvents": [
                256,
                {
                    "orderBy": [
                        257,
                        "[EventsOrderBy!]"
                    ],
                    "where": [
                        255
                    ]
                }
            ],
            "residentSince": [
                654,
                {
                    "organizationId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "socialProfile": [
                535
            ],
            "taskLists": [
                671,
                {
                    "where": [
                        467,
                        "ResidentTaskListsWhereInput!"
                    ]
                }
            ],
            "user": [
                662
            ],
            "userId": [
                293
            ],
            "userType": [
                676
            ],
            "__typename": [
                559
            ]
        },
        "ResidentCreateInput": {
            "leaseContractEndDate": [
                654
            ],
            "leaseContractStartDate": [
                654
            ],
            "leaseContractUnitId": [
                293
            ],
            "organizationId": [
                293
            ],
            "userEmail": [
                559
            ],
            "userFirstName": [
                559
            ],
            "userLastName": [
                559
            ],
            "userMobilePhone": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ResidentEmbeddedApplicationDescriptor": {
            "authenticationMethod": [
                232
            ],
            "authenticationSettingsSAML2": [
                234
            ],
            "callbackHandlingMethod": [
                233
            ],
            "callbackHandlingSettingsWindowEvent": [
                236
            ],
            "iframeTitle": [
                559
            ],
            "iframeUrl": [
                559
            ],
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ResidentEmbeddedApplications": {
            "embeddedAppID": [
                293
            ],
            "endpoint": [
                559
            ],
            "leaseContracts": [
                293
            ],
            "name": [
                559
            ],
            "type": [
                235
            ],
            "__typename": [
                559
            ]
        },
        "ResidentEventsWhereInput": {
            "communityIdIn": [
                293
            ],
            "startDateBetween": [
                197
            ],
            "__typename": [
                559
            ]
        },
        "ResidentRiskSortField": {},
        "ResidentTaskListsWhereInput": {
            "idIn": [
                293
            ],
            "isActive": [
                129
            ],
            "type": [
                635
            ],
            "__typename": [
                559
            ]
        },
        "ResidentWhereInput": {
            "buildingIdIn": [
                293
            ],
            "communityIdIn": [
                293
            ],
            "completedAppOnboarding": [
                129
            ],
            "contractEndDateBetween": [
                197
            ],
            "contractStartDateBetween": [
                197
            ],
            "contractStatusIn": [
                186
            ],
            "contractTimelineIn": [
                187
            ],
            "isContractPrimary": [
                129
            ],
            "leaseRentRange": [
                336
            ],
            "leaseStatusIn": [
                186
            ],
            "riskCategoryIn": [
                471
            ],
            "searchKey": [
                559
            ],
            "upForRenewal": [
                129
            ],
            "userIdIn": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ResidentsList": {
            "items": [
                461
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "ResidentsUpForRenewalFilter": {
            "leaseEndRange": [
                331
            ],
            "leaseRentRange": [
                336
            ],
            "riskCategoryIn": [
                471
            ],
            "__typename": [
                559
            ]
        },
        "RiskCategory": {},
        "Role": {
            "hood": [
                286
            ],
            "id": [
                293
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "RuleType": {},
        "SatisfactionScore": {},
        "ScheduledBroadcast": {
            "endDate": [
                654
            ],
            "id": [
                293
            ],
            "nextNotificationDate": [
                654
            ],
            "scheduledRuleCron": [
                559
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "ScheduledBroadcastCreateInput": {
            "broadcastTemplateId": [
                293
            ],
            "scheduledRuleCron": [
                559
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "ScheduledBroadcastUpdateInput": {
            "endDate": [
                654
            ],
            "scheduledRuleCron": [
                559
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "ScreeningCheckStatus": {},
        "ScreeningStatus": {},
        "SectionType": {},
        "SecureKeyValue": {
            "isSecure": [
                129
            ],
            "key": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SecureKeyValueInput": {
            "isSecure": [
                129
            ],
            "key": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Service": {
            "description": [
                559
            ],
            "id": [
                293
            ],
            "isTermsAndConditionsEnabled": [
                129
            ],
            "name": [
                559
            ],
            "providerName": [
                559
            ],
            "serviceProducts": [
                484,
                {
                    "where": [
                        489
                    ]
                }
            ],
            "termsAndConditions": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ServiceProduct": {
            "actualPrice": [
                274
            ],
            "calendar": [
                170
            ],
            "currentUserSubscriptionContractId": [
                293
            ],
            "description": [
                559
            ],
            "discountedPrice": [
                274
            ],
            "id": [
                293
            ],
            "imageResourceId": [
                559
            ],
            "name": [
                559
            ],
            "pickupLocation": [
                421
            ],
            "price": [
                274
            ],
            "priceUnit": [
                559
            ],
            "service": [
                483
            ],
            "serviceProductType": [
                488
            ],
            "subProductId": [
                293
            ],
            "taxAmount": [
                274
            ],
            "taxRate": [
                641
            ],
            "__typename": [
                559
            ]
        },
        "ServiceProductPurchaseInput": {
            "calendarBookingDetails": [
                171
            ],
            "paymentMethodId": [
                293
            ],
            "serviceProductId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ServiceProductPurchaseResult": {
            "clientSecret": [
                559
            ],
            "isSucceeded": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "ServiceProductSubscribeInput": {
            "externalSubscriptionId": [
                293
            ],
            "serviceProductId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ServiceProductType": {},
        "ServiceProductWhereInput": {
            "published": [
                129
            ],
            "serviceId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestAttachment": {
            "URL": [
                559
            ],
            "contentType": [
                559
            ],
            "fileName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestCategorySetInput": {
            "displayHasPet": [
                129
            ],
            "displayPermissionToEnterRoom": [
                129
            ],
            "published": [
                129
            ],
            "shouldIncludeRoommates": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestComment": {
            "attachments": [
                490
            ],
            "author": [
                662
            ],
            "authorId": [
                293
            ],
            "content": [
                559
            ],
            "id": [
                293
            ],
            "postedAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestCreateInput": {
            "categoryId": [
                293
            ],
            "ccRoommatesToTicket": [
                129
            ],
            "description": [
                559
            ],
            "entryNote": [
                559
            ],
            "forLeaseContractId": [
                293
            ],
            "forUnitId": [
                293
            ],
            "hasPet": [
                129
            ],
            "metadata": [
                50
            ],
            "permissionToEnterRoom": [
                129
            ],
            "subject": [
                559
            ],
            "target": [
                501
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestList": {
            "items": [
                497
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestStatusMapping": {
            "externalStatus": [
                559
            ],
            "vennStatus": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestStatusMappingInput": {
            "externalStatus": [
                559
            ],
            "vennStatus": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestTicket": {
            "building": [
                159
            ],
            "buildingId": [
                293
            ],
            "category": [
                570
            ],
            "categoryId": [
                293
            ],
            "closedAt": [
                654
            ],
            "comments": [
                492
            ],
            "description": [
                559
            ],
            "entryNote": [
                559
            ],
            "externalId": [
                293
            ],
            "forLeaseContract": [
                325
            ],
            "forLeaseContractId": [
                293
            ],
            "forUnit": [
                656
            ],
            "forUnitId": [
                293
            ],
            "hasPet": [
                129
            ],
            "havesDownloadableAttachments": [
                129
            ],
            "id": [
                293
            ],
            "openedAt": [
                654
            ],
            "openingManager": [
                498
            ],
            "openingManagerId": [
                293
            ],
            "permissionToEnterRoom": [
                129
            ],
            "source": [
                579
            ],
            "status": [
                580
            ],
            "subject": [
                559
            ],
            "target": [
                501
            ],
            "timeToResolutionInMinutes": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestTicketOpeningManagerDetails": {
            "email": [
                559
            ],
            "fullName": [
                559
            ],
            "id": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestTicketStatus": {},
        "ServiceRequestUpdateInput": {
            "description": [
                559
            ],
            "entryNote": [
                559
            ],
            "permissionToEnterRoom": [
                129
            ],
            "problemValue": [
                559
            ],
            "subject": [
                559
            ],
            "supportCategoryId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestedTarget": {},
        "ServiceRequestsAdapter": {
            "endpoint": [
                559
            ],
            "id": [
                293
            ],
            "isPeriodicSyncEnabled": [
                129
            ],
            "lastFailureSync": [
                609
            ],
            "lastFailureSyncJobId": [
                293
            ],
            "lastSuccessSync": [
                609
            ],
            "lastSuccessSyncJobId": [
                293
            ],
            "lastSyncJob": [
                609
            ],
            "lastSyncJobId": [
                293
            ],
            "name": [
                559
            ],
            "organization": [
                385
            ],
            "organizationId": [
                293
            ],
            "settings": [
                481
            ],
            "statusMapping": [
                495
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsAdapterCreateInput": {
            "endpoint": [
                559
            ],
            "isPeriodicSyncEnabled": [
                129
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "settings": [
                482
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsAdapterTestResult": {
            "message": [
                559
            ],
            "success": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsAdapterUpdateInput": {
            "endpoint": [
                559
            ],
            "isPeriodicSyncEnabled": [
                129
            ],
            "name": [
                559
            ],
            "settings": [
                482
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsSettings": {
            "allowedCommentingStatuses": [
                559
            ],
            "areDuplicateRequestsAllowed": [
                129
            ],
            "areEntryNotesRequired": [
                129
            ],
            "buildingId": [
                559
            ],
            "isCommentingEnabled": [
                129
            ],
            "isDescriptionRequired": [
                129
            ],
            "isEnabled": [
                129
            ],
            "isHubDashboardEnabled": [
                129
            ],
            "isSubjectRequired": [
                129
            ],
            "organizationId": [
                559
            ],
            "serviceRequestsCategoriesLevelNames": [
                559
            ],
            "shouldDisplayCommentingChatInput": [
                129
            ],
            "shouldDisplayCovidQuestions": [
                129
            ],
            "shouldDisplayEntryNotes": [
                129
            ],
            "shouldDisplayHubDashboardActions": [
                129
            ],
            "shouldDisplaySubject": [
                129
            ],
            "shouldSendEmailOnCreation": [
                129
            ],
            "supportEmail": [
                559
            ],
            "supportPhoneNumber": [
                559
            ],
            "supportedTicketAttachmentsTypes": [
                653
            ],
            "surveyId": [
                559
            ],
            "ticketStatusesNotificationEnabled": [
                559
            ],
            "ticketStatusesNotificationTemplateMapping": [
                509
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsSettingsInput": {
            "allowedCommentingStatuses": [
                559
            ],
            "areDuplicateRequestsAllowed": [
                129
            ],
            "areEntryNotesRequired": [
                129
            ],
            "isCommentingEnabled": [
                129
            ],
            "isDescriptionRequired": [
                129
            ],
            "isSubjectRequired": [
                129
            ],
            "shouldDisplayCovidQuestions": [
                129
            ],
            "shouldDisplayEntryNotes": [
                129
            ],
            "shouldSendEmailOnCreation": [
                129
            ],
            "supportEmail": [
                559
            ],
            "supportPhoneNumber": [
                559
            ],
            "surveyId": [
                559
            ],
            "ticketStatusesNotificationEnabled": [
                559
            ],
            "ticketStatusesNotificationTemplateMapping": [
                510
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsSettingsSetInput": {
            "ShouldDisplayHubDashboardActions": [
                129
            ],
            "allowedCommentingStatuses": [
                559
            ],
            "areDuplicateRequestsAllowed": [
                129
            ],
            "areEntryNotesRequired": [
                129
            ],
            "isCommentingEnabled": [
                129
            ],
            "isDescriptionRequired": [
                129
            ],
            "isEnabled": [
                129
            ],
            "isHubDashboardEnabled": [
                129
            ],
            "isSubjectRequired": [
                129
            ],
            "shouldDisplayCovidQuestions": [
                129
            ],
            "supportPhoneNumber": [
                559
            ],
            "surveyId": [
                559
            ],
            "ticketStatusesNotificationEnabled": [
                559
            ],
            "ticketStatusesNotificationTemplateMapping": [
                510
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping": {
            "notificationContent": [
                703
            ],
            "status": [
                580
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsSettingsTicketStatusesNotificationTemplateMappingInput": {
            "notificationContent": [
                704
            ],
            "status": [
                580
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsUploadUrlResponse": {
            "URL": [
                559
            ],
            "key": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ServiceRequestsWhereInput": {
            "categoryIds": [
                293
            ],
            "ids": [
                293
            ],
            "statusIn": [
                580
            ],
            "__typename": [
                559
            ]
        },
        "ShiftNote": {
            "building": [
                159
            ],
            "buildingId": [
                293
            ],
            "createdAt": [
                654
            ],
            "createdBy": [
                662
            ],
            "createdById": [
                293
            ],
            "createdByName": [
                559
            ],
            "id": [
                293
            ],
            "note": [
                559
            ],
            "status": [
                516
            ],
            "__typename": [
                559
            ]
        },
        "ShiftNoteCreateInput": {
            "buildingId": [
                293
            ],
            "note": [
                559
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "ShiftNoteOrderBy": {},
        "ShiftNoteStatus": {},
        "ShiftNoteUpdateInput": {
            "buildingId": [
                293
            ],
            "id": [
                293
            ],
            "note": [
                559
            ],
            "organizationId": [
                293
            ],
            "status": [
                516
            ],
            "__typename": [
                559
            ]
        },
        "ShiftNoteWhereInput": {
            "buildingIds": [
                293
            ],
            "createdAtBT": [
                654
            ],
            "createdAtLT": [
                654
            ],
            "organizationId": [
                293
            ],
            "search": [
                559
            ],
            "statuses": [
                516
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockAccessKeyGenerateInput": {
            "lockProvider": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockAdapter": {
            "AuthenticationMethod": [
                100
            ],
            "IsGuestAccessEnabled": [
                129
            ],
            "IsMasterKeyEnabled": [
                129
            ],
            "OauthSettings": [
                381
            ],
            "buildingIds": [
                293
            ],
            "buildings": [
                159
            ],
            "endpoint": [
                559
            ],
            "grantAccessByMoveDates": [
                129
            ],
            "grantAccessContractStatuses": [
                186
            ],
            "id": [
                293
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "settings": [
                481
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockAdapterCreateInput": {
            "AuthenticationMethod": [
                100
            ],
            "IsGuestAccessEnabled": [
                129
            ],
            "IsMasterKeyEnabled": [
                129
            ],
            "OauthSettings": [
                382
            ],
            "buildingIds": [
                293
            ],
            "endpoint": [
                559
            ],
            "grantAccessByMoveDates": [
                129
            ],
            "grantAccessContractStatuses": [
                186
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "settings": [
                482
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockAdapterUpdateInput": {
            "AuthenticationMethod": [
                100
            ],
            "IsGuestAccessEnabled": [
                129
            ],
            "IsMasterKeyEnabled": [
                129
            ],
            "OauthSettings": [
                382
            ],
            "buildingIds": [
                293
            ],
            "endpoint": [
                559
            ],
            "grantAccessByMoveDates": [
                129
            ],
            "grantAccessContractStatuses": [
                186
            ],
            "name": [
                559
            ],
            "settings": [
                482
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockGuestKeyGenerateInput": {
            "locks": [
                285
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockOAuthTokenGenerateInput": {
            "adapterId": [
                559
            ],
            "additionalQueryParams": [
                446
            ],
            "authorizationCode": [
                559
            ],
            "codeVerifier": [
                559
            ],
            "redirectURL": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockOAuthTokenRefreshInput": {
            "adapterId": [
                559
            ],
            "additionalQueryParams": [
                446
            ],
            "refreshToken": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockWebhookRegisterInput": {
            "adapterId": [
                559
            ],
            "deviceToken": [
                559
            ],
            "metadata": [
                319
            ],
            "__typename": [
                559
            ]
        },
        "SmartLockWebhookUnregisterInput": {
            "adapterId": [
                559
            ],
            "deviceToken": [
                559
            ],
            "metadata": [
                319
            ],
            "__typename": [
                559
            ]
        },
        "SmsLinkData": {
            "body": [
                559
            ],
            "phoneNumber": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SmsLinkDataInput": {
            "body": [
                559
            ],
            "phoneNumber": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SocialInteraction": {
            "fromSocialProfile": [
                535
            ],
            "id": [
                293
            ],
            "toSocialProfile": [
                535
            ],
            "type": [
                532
            ],
            "viewed": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "SocialInteractionCreateInput": {
            "fromUserId": [
                293
            ],
            "toUserId": [
                293
            ],
            "type": [
                532
            ],
            "viewed": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "SocialInteractionType": {},
        "SocialInteractionUpdateInput": {
            "viewed": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "SocialInteractionWhereInput": {
            "fromUserId": [
                293
            ],
            "toUserId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfile": {
            "address": [
                540
            ],
            "biography": [
                540
            ],
            "blockStatus": [
                537
            ],
            "byline": [
                168
            ],
            "callToAction": [
                538
            ],
            "completionPercentage": [
                301
            ],
            "createdAt": [
                654
            ],
            "dateOfBirth": [
                540
            ],
            "firstName": [
                559
            ],
            "id": [
                293
            ],
            "imageId": [
                559
            ],
            "imageUrl": [
                559
            ],
            "interestGroups": [
                317,
                {
                    "pager": [
                        395
                    ]
                }
            ],
            "lastName": [
                559
            ],
            "occupation": [
                540
            ],
            "pets": [
                542
            ],
            "pronouns": [
                545
            ],
            "quote": [
                540
            ],
            "socialLinks": [
                546
            ],
            "socialTags": [
                536
            ],
            "superpowers": [
                540
            ],
            "userId": [
                293
            ],
            "website": [
                540
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfileArrayField": {
            "privacy": [
                543
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfileBlockStatus": {},
        "SocialProfileCallToActionField": {
            "privacy": [
                543
            ],
            "target": [
                559
            ],
            "type": [
                539
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfileCallToActionType": {},
        "SocialProfileField": {
            "privacy": [
                543
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfileImageCreateInput": {
            "fileName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfilePets": {
            "pets": [
                414
            ],
            "privacy": [
                543
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfilePrivacy": {},
        "SocialProfilePronouns": {},
        "SocialProfilePronounsField": {
            "customPronouns": [
                559
            ],
            "privacy": [
                543
            ],
            "value": [
                544
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfileSocialLinksField": {
            "facebook": [
                540
            ],
            "instagram": [
                540
            ],
            "linkedin": [
                540
            ],
            "youtube": [
                540
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfileUpdateInput": {
            "image": [
                541
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfileWhereInput": {
            "userId_not": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SocialProfilesList": {
            "items": [
                535
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "SpecificUsersAudienceParams": {
            "userIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SpecificUsersAudienceParamsInput": {
            "userIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "StaffTask": {
            "assignees": [
                553
            ],
            "buildingIds": [
                293
            ],
            "createdAt": [
                654
            ],
            "deadline": [
                654
            ],
            "description": [
                559
            ],
            "id": [
                293
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "priority": [
                559
            ],
            "status": [
                559
            ],
            "updatedAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "StaffTaskAssignee": {
            "user": [
                662
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "StaffTaskCreateInput": {
            "assigneeIds": [
                293
            ],
            "buildingIds": [
                293
            ],
            "deadline": [
                654
            ],
            "description": [
                559
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "priority": [
                559
            ],
            "status": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "StaffTaskUpdateInput": {
            "assigneeIds": [
                293
            ],
            "buildingIds": [
                293
            ],
            "deadline": [
                654
            ],
            "description": [
                559
            ],
            "name": [
                559
            ],
            "priority": [
                559
            ],
            "status": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "StaffTaskWhereInput": {
            "assigneeIds": [
                293
            ],
            "buildingIds": [
                293
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "priority": [
                559
            ],
            "searchKey": [
                559
            ],
            "status": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "StaffTaskWhereUniqueInput": {
            "buildingIds": [
                293
            ],
            "id": [
                293
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "StatusMappings": {
            "mapping": [
                496
            ],
            "__typename": [
                559
            ]
        },
        "String": {},
        "StripeCheckoutSession": {
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "StripeConnectedAccount": {
            "achFeePercent": [
                274
            ],
            "chargesEnabled": [
                129
            ],
            "creditCardFeePercent": [
                274
            ],
            "email": [
                559
            ],
            "externalId": [
                559
            ],
            "id": [
                293
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "payoutsEnabled": [
                129
            ],
            "requirementsMissing": [
                129
            ],
            "__typename": [
                559
            ]
        },
        "StripeConnectedAccountAddExistingInput": {
            "email": [
                559
            ],
            "externalId": [
                293
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "StripeConnectedAccountCreateInput": {
            "achFeePercent": [
                274
            ],
            "creditCardFeePercent": [
                274
            ],
            "email": [
                559
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "StripeConnectedAccountSession": {
            "clientSecret": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "StripeConnectedAccountUpdateInput": {
            "achFeePercent": [
                274
            ],
            "creditCardFeePercent": [
                274
            ],
            "__typename": [
                559
            ]
        },
        "SubscriptionContract": {
            "id": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SubwayStation": {
            "line": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SummaryStatus": {},
        "SupportAvailabilityInput": {
            "day": [
                572
            ],
            "time": [
                583
            ],
            "__typename": [
                559
            ]
        },
        "SupportCategory": {
            "clientVisibleName": [
                559
            ],
            "displayHasPet": [
                129
            ],
            "displayPermissionToEnterRoom": [
                129
            ],
            "id": [
                293
            ],
            "isArchived": [
                129
            ],
            "name": [
                559
            ],
            "parent": [
                570
            ],
            "parentId": [
                293
            ],
            "problemValues": [
                559
            ],
            "published": [
                129
            ],
            "requiresScheduling": [
                129
            ],
            "shouldIncludeRoommates": [
                129
            ],
            "type": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SupportCategoryWhereInput": {
            "parentId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SupportDay": {},
        "SupportSatisfactionRating": {
            "comment": [
                559
            ],
            "id": [
                293
            ],
            "score": [
                474
            ],
            "__typename": [
                559
            ]
        },
        "SupportSurveyResponseInput": {
            "comment": [
                559
            ],
            "score": [
                474
            ],
            "supportTicketId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SupportTicket": {
            "category": [
                570
            ],
            "categoryId": [
                293
            ],
            "closedAt": [
                654
            ],
            "comments": [
                492
            ],
            "description": [
                559
            ],
            "entryNote": [
                559
            ],
            "externalId": [
                293
            ],
            "externalStatus": [
                559
            ],
            "hasAttachments": [
                129
            ],
            "hasPet": [
                129
            ],
            "havesDownloadableAttachments": [
                129
            ],
            "id": [
                293
            ],
            "lastUpdated": [
                654
            ],
            "leaseContract": [
                325
            ],
            "leaseContractId": [
                293
            ],
            "openedAt": [
                654
            ],
            "openingManager": [
                498
            ],
            "openingManagerId": [
                293
            ],
            "permissionToEnterRoom": [
                129
            ],
            "source": [
                579
            ],
            "status": [
                580
            ],
            "subject": [
                559
            ],
            "surveyAssignment": [
                585
            ],
            "timeToResolutionInMinutes": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "SupportTicketComment": {
            "attachmentUrls": [
                559
            ],
            "body": [
                559
            ],
            "id": [
                293
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SupportTicketCommentCreateInput": {
            "attachmentUrls": [
                559
            ],
            "body": [
                559
            ],
            "ticketId": [
                293
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SupportTicketCreateInput": {
            "attachmentUrls": [
                559
            ],
            "attachments": [
                661
            ],
            "availability": [
                569
            ],
            "body": [
                559
            ],
            "ccRoommatesToTicket": [
                129
            ],
            "entryNote": [
                559
            ],
            "fileKeys": [
                559
            ],
            "hasPet": [
                129
            ],
            "hoodId": [
                293
            ],
            "metadata": [
                50
            ],
            "permissionToEnterRoom": [
                129
            ],
            "problemValue": [
                559
            ],
            "reporterUserId": [
                293
            ],
            "subject": [
                559
            ],
            "supportCategoryId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SupportTicketSource": {},
        "SupportTicketStatus": {},
        "SupportTicketUpdatesSubscription": {
            "status": [
                580
            ],
            "supportTicketId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SupportTicketWhereInput": {
            "categoryId": [
                293
            ],
            "problemValue": [
                559
            ],
            "statusNotIn": [
                580
            ],
            "__typename": [
                559
            ]
        },
        "SupportTime": {},
        "Survey": {
            "assignmentExpirationDays": [
                301
            ],
            "description": [
                559
            ],
            "id": [
                293
            ],
            "isEnabled": [
                129
            ],
            "isPublic": [
                129
            ],
            "name": [
                559
            ],
            "priority": [
                301
            ],
            "questions": [
                594
            ],
            "snoozeMaxAmount": [
                301
            ],
            "snoozeTTLDays": [
                301
            ],
            "title": [
                559
            ],
            "type": [
                605
            ],
            "__typename": [
                559
            ]
        },
        "SurveyAssignment": {
            "batchId": [
                559
            ],
            "expirationDate": [
                654
            ],
            "id": [
                559
            ],
            "relatedEntityId": [
                559
            ],
            "relatedEntityType": [
                586
            ],
            "relatedLeaseContract": [
                325
            ],
            "relatedSupportTicket": [
                575
            ],
            "snoozeCount": [
                301
            ],
            "snoozeLastDate": [
                654
            ],
            "status": [
                587
            ],
            "survey": [
                584
            ],
            "surveyId": [
                559
            ],
            "user": [
                662
            ],
            "userId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SurveyAssignmentRelatedEntityType": {},
        "SurveyAssignmentStatus": {},
        "SurveyAssignmentUserWhereInput": {
            "batchIdIn": [
                559
            ],
            "relatedEntityIdIn": [
                559
            ],
            "relatedEntityTypeIn": [
                586
            ],
            "statusIn": [
                587
            ],
            "surveyIdIn": [
                559
            ],
            "surveyTypeIn": [
                605
            ],
            "__typename": [
                559
            ]
        },
        "SurveyBatch": {
            "id": [
                293
            ],
            "name": [
                559
            ],
            "survey": [
                584
            ],
            "__typename": [
                559
            ]
        },
        "SurveyBatchWhereInput": {
            "surveyType": [
                605
            ],
            "__typename": [
                559
            ]
        },
        "SurveyBatchesList": {
            "items": [
                589
            ],
            "__typename": [
                559
            ]
        },
        "SurveyChoiceOption": {
            "Order": [
                301
            ],
            "Value": [
                559
            ],
            "text": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SurveyCreateInput": {
            "assignmentExpirationDays": [
                301
            ],
            "description": [
                559
            ],
            "hoodId": [
                293
            ],
            "isEnabled": [
                129
            ],
            "isPublic": [
                129
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "priority": [
                301
            ],
            "questions": [
                596
            ],
            "snoozeMaxAmount": [
                301
            ],
            "snoozeTTLDays": [
                301
            ],
            "title": [
                559
            ],
            "type": [
                605
            ],
            "__typename": [
                559
            ]
        },
        "SurveyQuestion": {
            "allowComments": [
                129
            ],
            "choiceOptions": [
                592
            ],
            "id": [
                293
            ],
            "order": [
                301
            ],
            "scaleOptions": [
                604
            ],
            "survey": [
                584
            ],
            "text": [
                559
            ],
            "type": [
                600
            ],
            "__typename": [
                559
            ]
        },
        "SurveyQuestionChoiceOptionCreateInput": {
            "order": [
                301
            ],
            "text": [
                559
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SurveyQuestionCreateInput": {
            "allowComments": [
                129
            ],
            "choiceOptions": [
                595
            ],
            "order": [
                301
            ],
            "scaleOptions": [
                599
            ],
            "text": [
                559
            ],
            "type": [
                600
            ],
            "__typename": [
                559
            ]
        },
        "SurveyQuestionResponseInput": {
            "comment": [
                559
            ],
            "questionId": [
                293
            ],
            "value": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SurveyQuestionResponseWhereInput": {
            "batchId": [
                293
            ],
            "questionId": [
                293
            ],
            "rating": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SurveyQuestionScaleOptionCreateInput": {
            "max": [
                301
            ],
            "maxText": [
                559
            ],
            "min": [
                301
            ],
            "minText": [
                559
            ],
            "steps": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "SurveyQuestionType": {},
        "SurveyQuestionUpdateInput": {
            "allowComments": [
                129
            ],
            "choiceOptions": [
                595
            ],
            "id": [
                293
            ],
            "order": [
                301
            ],
            "scaleOptions": [
                599
            ],
            "text": [
                559
            ],
            "type": [
                600
            ],
            "__typename": [
                559
            ]
        },
        "SurveyResponseSubmitInput": {
            "responses": [
                597
            ],
            "surveyAssignmentId": [
                293
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SurveyResponseUserSubmitInput": {
            "isCompleted": [
                129
            ],
            "responses": [
                597
            ],
            "surveyAssignmentId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SurveyScaleOption": {
            "max": [
                301
            ],
            "maxText": [
                559
            ],
            "min": [
                301
            ],
            "minText": [
                559
            ],
            "steps": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "SurveyType": {},
        "SurveyUpdateInput": {
            "description": [
                559
            ],
            "isEnabled": [
                129
            ],
            "isPublic": [
                129
            ],
            "name": [
                559
            ],
            "priority": [
                301
            ],
            "questions": [
                601
            ],
            "snoozeMaxAmount": [
                301
            ],
            "snoozeTTLDays": [
                301
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SurveyWhereInput": {
            "communityIds": [
                293
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SurveysList": {
            "items": [
                584
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "SyncJob": {
            "adapterId": [
                293
            ],
            "exportStatus": [
                613
            ],
            "forceUpdate": [
                129
            ],
            "id": [
                293
            ],
            "items": [
                615,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        617
                    ]
                }
            ],
            "organizationId": [
                293
            ],
            "results": [
                619
            ],
            "startedAt": [
                654
            ],
            "status": [
                620
            ],
            "type": [
                559
            ],
            "updatedAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobCreateInput": {
            "adapterId": [
                293
            ],
            "forceUpdate": [
                129
            ],
            "organizationId": [
                293
            ],
            "type": [
                621
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobDownloadExportInput": {
            "organizationId": [
                293
            ],
            "syncJobId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobDownloadExportResult": {
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobExportStatus": {},
        "SyncJobItem": {
            "entityData": [
                50
            ],
            "entityId": [
                293
            ],
            "entityType": [
                559
            ],
            "errors": [
                624
            ],
            "id": [
                293
            ],
            "jobId": [
                293
            ],
            "status": [
                616
            ],
            "vennId": [
                293
            ],
            "warnings": [
                624
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobItemList": {
            "items": [
                614
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobItemStatus": {},
        "SyncJobItemsWhereInput": {
            "entityIdSearchKey": [
                559
            ],
            "entityTypeIn": [
                320
            ],
            "statusIn": [
                321
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobPrepareExportInput": {
            "organizationId": [
                293
            ],
            "syncJobId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobResult": {
            "countPerStatus": [
                322
            ],
            "entityType": [
                320
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobStatus": {},
        "SyncJobType": {},
        "SyncJobWhereInput": {
            "adapterId": [
                293
            ],
            "idIn": [
                293
            ],
            "startedBetween": [
                197
            ],
            "statusIn": [
                620
            ],
            "updatedBetween": [
                197
            ],
            "__typename": [
                559
            ]
        },
        "SyncJobsList": {
            "PageInfo": [
                394
            ],
            "items": [
                609
            ],
            "__typename": [
                559
            ]
        },
        "SyncMessage": {
            "code": [
                559
            ],
            "message": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Task": {
            "completedCtaText": [
                559
            ],
            "createdAt": [
                654
            ],
            "displayText": [
                559
            ],
            "iconName": [
                559
            ],
            "id": [
                559
            ],
            "name": [
                559
            ],
            "organizationId": [
                559
            ],
            "template": [
                638
            ],
            "templateCss": [
                559
            ],
            "templateHtml": [
                559
            ],
            "uncompletedCtaText": [
                559
            ],
            "updatedAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "TaskCreateInput": {
            "completedCtaText": [
                559
            ],
            "displayText": [
                559
            ],
            "iconName": [
                559
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "templateCss": [
                559
            ],
            "templateHtml": [
                559
            ],
            "uncompletedCtaText": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "TaskList": {
            "buildings": [
                159
            ],
            "communityId": [
                293
            ],
            "id": [
                293
            ],
            "isConnectedToUsers": [
                129
            ],
            "isDefault": [
                129
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "task": [
                630,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "tasks": [
                630
            ],
            "type": [
                635
            ],
            "__typename": [
                559
            ]
        },
        "TaskListCreateFromInput": {
            "name": [
                559
            ],
            "type": [
                635
            ],
            "__typename": [
                559
            ]
        },
        "TaskListCreateInput": {
            "communityId": [
                293
            ],
            "isDefault": [
                129
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "type": [
                635
            ],
            "__typename": [
                559
            ]
        },
        "TaskListTask": {
            "id": [
                293
            ],
            "isOptional": [
                129
            ],
            "order": [
                301
            ],
            "organizationId": [
                293
            ],
            "tMinusDays": [
                301
            ],
            "task": [
                625
            ],
            "taskId": [
                293
            ],
            "taskList": [
                627
            ],
            "taskListId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TaskListTaskCreateInput": {
            "isOptional": [
                129
            ],
            "order": [
                301
            ],
            "organizationId": [
                293
            ],
            "tMinusInDays": [
                301
            ],
            "taskId": [
                293
            ],
            "taskListId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TaskListTaskReorderInput": {
            "order": [
                301
            ],
            "taskListTaskId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TaskListTaskUpdateInput": {
            "isOptional": [
                129
            ],
            "tMinusInDays": [
                301
            ],
            "taskId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TaskListTaskWhereInput": {
            "taskIdsIn": [
                293
            ],
            "taskListIdsIn": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TaskListType": {},
        "TaskListUpdateInput": {
            "name": [
                559
            ],
            "type": [
                635
            ],
            "__typename": [
                559
            ]
        },
        "TaskListWhereInput": {
            "Ids": [
                293
            ],
            "buildingIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TaskTemplate": {},
        "TaskUpdateInput": {
            "completedCtaText": [
                559
            ],
            "displayText": [
                559
            ],
            "iconName": [
                559
            ],
            "name": [
                559
            ],
            "templateCss": [
                559
            ],
            "templateHtml": [
                559
            ],
            "uncompletedCtaText": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "TaskWhereInput": {
            "Ids": [
                293
            ],
            "searchKey": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "TaxRate": {
            "externalTaxRateId": [
                559
            ],
            "id": [
                293
            ],
            "value": [
                274
            ],
            "__typename": [
                559
            ]
        },
        "Team": {
            "buildingsCount": [
                301
            ],
            "communities": [
                175
            ],
            "communitiesCount": [
                301
            ],
            "id": [
                293
            ],
            "members": [
                387
            ],
            "membersCount": [
                301
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TeamBuildingPermissions": {
            "building": [
                159
            ],
            "buildingId": [
                293
            ],
            "permissions": [
                410
            ],
            "teamId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TeamBuildingPermissionsSetInput": {
            "buildingId": [
                293
            ],
            "permissionsSet": [
                410
            ],
            "teamId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TeamBuildingsPermissionsAddInput": {
            "buildingIds": [
                293
            ],
            "permissions": [
                410
            ],
            "teamId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TeamCreateInput": {
            "communityIds": [
                293
            ],
            "memberIds": [
                293
            ],
            "name": [
                559
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TeamDeleteInput": {
            "id": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "TeamUpdateInput": {
            "communityIds": [
                293
            ],
            "memberIds": [
                293
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "TeamsWhereInput": {
            "searchKey": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Thermostat": {
            "availableDeviceModes": [
                209
            ],
            "availableFanModes": [
                260
            ],
            "buildingId": [
                559
            ],
            "buildingMode": [
                162
            ],
            "currentTemperature": [
                274
            ],
            "deviceMode": [
                209
            ],
            "fanMode": [
                260
            ],
            "id": [
                293
            ],
            "targetTemperature": [
                274
            ],
            "unit": [
                656
            ],
            "unitId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ThermostatTemperatureUpdateInput": {
            "newTemperature": [
                274
            ],
            "thermostatId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "ThermostatUpdateInput": {
            "DeviceMode": [
                209
            ],
            "FanMode": [
                260
            ],
            "newTemperature": [
                274
            ],
            "thermostatId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "TicketAttachmentType": {},
        "Time": {},
        "Timeline": {},
        "Unit": {
            "building": [
                159
            ],
            "buildingId": [
                293
            ],
            "externalId": [
                293
            ],
            "id": [
                293
            ],
            "isAcceptingApplications": [
                129
            ],
            "name": [
                559
            ],
            "status": [
                658
            ],
            "unitName": [
                559
            ],
            "updatedAt": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "UnitPublicData": {
            "buildingId": [
                293
            ],
            "buildingName": [
                559
            ],
            "id": [
                293
            ],
            "name": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "UnitStatus": {},
        "UnitType": {},
        "UnregisterUserDeviceInput": {
            "deviceId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Upload": {},
        "User": {
            "building": [
                159
            ],
            "buildingId": [
                293
            ],
            "email": [
                559
            ],
            "events": [
                256,
                {
                    "orderBy": [
                        257,
                        "[EventsOrderBy!]"
                    ],
                    "pager": [
                        395,
                        "PageInput!"
                    ],
                    "where": [
                        255
                    ]
                }
            ],
            "firstName": [
                559
            ],
            "hoodsAssigned": [
                286
            ],
            "hubMetadata": [
                291
            ],
            "id": [
                293
            ],
            "isSystemAdmin": [
                129
            ],
            "lastName": [
                559
            ],
            "leaseContracts": [
                325,
                {
                    "where": [
                        329
                    ]
                }
            ],
            "managedCommunities": [
                175,
                {
                    "where": [
                        346
                    ]
                }
            ],
            "metadata": [
                50
            ],
            "middleName": [
                559
            ],
            "mobileLastVisit": [
                654
            ],
            "mobilePhone": [
                559
            ],
            "organizationMemberships": [
                387
            ],
            "permittedBuildings": [
                411,
                {
                    "where": [
                        412,
                        "PermittedBuildingsWhereInput!"
                    ]
                }
            ],
            "profilePictureUrl": [
                559
            ],
            "resident": [
                461
            ],
            "socialProfile": [
                535
            ],
            "unit": [
                656
            ],
            "unitId": [
                293
            ],
            "userType": [
                676
            ],
            "__typename": [
                559
            ]
        },
        "UserAggregations": {
            "unopenedNotifications": [
                301
            ],
            "__typename": [
                559
            ]
        },
        "UserInviteInput": {
            "email": [
                559
            ],
            "firstName": [
                559
            ],
            "lastName": [
                559
            ],
            "mobilePhone": [
                559
            ],
            "role": [
                667
            ],
            "__typename": [
                559
            ]
        },
        "UserLoginApiKeyInput": {
            "apiKey": [
                559
            ],
            "userId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "UserLoginApiKeyResponse": {
            "token": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "UserRole": {},
        "UserRoot": {
            "amenities": [
                8,
                {
                    "orderBy": [
                        9,
                        "[AmenitiesOrderBy]"
                    ],
                    "pager": [
                        395
                    ]
                }
            ],
            "amenity": [
                11,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "appSettings": [
                66
            ],
            "awayNotice": [
                116,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "awayNotices": [
                116,
                {
                    "leaseContractId": [
                        293,
                        "ID!"
                    ],
                    "orderBy": [
                        118
                    ],
                    "where": [
                        125
                    ]
                }
            ],
            "deliveryPackages": [
                207,
                {
                    "orderBy": [
                        203
                    ],
                    "statuses": [
                        204,
                        "[DeliveryPackageStatus!]"
                    ]
                }
            ],
            "event": [
                238,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "events": [
                238,
                {
                    "where": [
                        255
                    ]
                }
            ],
            "featureFlags": [
                261,
                {
                    "where": [
                        262
                    ]
                }
            ],
            "hood": [
                286
            ],
            "hoodsAssigned": [
                286
            ],
            "leaseContracts": [
                325,
                {
                    "where": [
                        358
                    ]
                }
            ],
            "myAttendedEvents": [
                240,
                {
                    "communityId": [
                        293
                    ]
                }
            ],
            "myEventRating": [
                301,
                {
                    "eventId": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "notification": [
                364,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "notificationSnoozes": [
                369,
                {
                    "where": [
                        372
                    ]
                }
            ],
            "notifications": [
                378,
                {
                    "orderBy": [
                        379,
                        "[NotificationsOrderBy!]"
                    ],
                    "pager": [
                        395
                    ],
                    "where": [
                        377
                    ]
                }
            ],
            "orderedActiveUserTaskLists": [
                383,
                {
                    "where": [
                        384,
                        "OrderedActiveUserTaskListsWhereInput!"
                    ]
                }
            ],
            "reservation": [
                29,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "reservations": [
                29,
                {
                    "orderBy": [
                        37,
                        "[AmenityReservationOrderBy]"
                    ],
                    "where": [
                        48
                    ]
                }
            ],
            "serviceRequest": [
                575,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestAttachmentUploadUrl": [
                511
            ],
            "smartHome": [
                669
            ],
            "supportCategories": [
                570,
                {
                    "where": [
                        571,
                        "SupportCategoryWhereInput!"
                    ]
                }
            ],
            "supportTickets": [
                575,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        582
                    ]
                }
            ],
            "surveyAssignments": [
                585,
                {
                    "pager": [
                        395
                    ],
                    "where": [
                        588
                    ]
                }
            ],
            "surveyAssignmentsToDo": [
                585
            ],
            "thermostats": [
                650
            ],
            "user": [
                662
            ],
            "userId": [
                293
            ],
            "userTask": [
                670,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "visitor": [
                683,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "visitors": [
                683,
                {
                    "orderBy": [
                        685,
                        "VisitorOrderBy!"
                    ],
                    "where": [
                        689
                    ]
                }
            ],
            "__typename": [
                559
            ]
        },
        "UserSmartHomeRoot": {
            "lockCredentials": [
                339
            ],
            "lockProviderKeys": [
                341
            ],
            "__typename": [
                559
            ]
        },
        "UserTask": {
            "dueDate": [
                654
            ],
            "id": [
                293
            ],
            "status": [
                673
            ],
            "taskListTask": [
                630
            ],
            "taskListTaskId": [
                293
            ],
            "user": [
                662
            ],
            "userId": [
                293
            ],
            "userTaskList": [
                671
            ],
            "userTaskListId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "UserTaskList": {
            "dueDate": [
                654
            ],
            "id": [
                293
            ],
            "isActive": [
                129
            ],
            "leaseContract": [
                325
            ],
            "leaseContractId": [
                293
            ],
            "organizationId": [
                293
            ],
            "status": [
                672
            ],
            "task": [
                670,
                {
                    "id": [
                        293,
                        "ID!"
                    ]
                }
            ],
            "taskList": [
                627
            ],
            "taskListId": [
                293
            ],
            "tasks": [
                670
            ],
            "__typename": [
                559
            ]
        },
        "UserTaskListStatus": {},
        "UserTaskStatus": {},
        "UserTaskStatusUpdateResponse": {
            "userTaskListStatus": [
                672
            ],
            "userTaskStatus": [
                673
            ],
            "__typename": [
                559
            ]
        },
        "UserTasksWhereInput": {
            "userTaskListId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "UserType": {},
        "UserTypeAudienceParams": {
            "userType": [
                676
            ],
            "__typename": [
                559
            ]
        },
        "UserTypeAudienceParamsInput": {
            "userType": [
                676
            ],
            "__typename": [
                559
            ]
        },
        "UserWhereInput": {
            "unitIn": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "UsersList": {
            "items": [
                662
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "ValidationConstraint": {},
        "ValidatorConstraint": {
            "name": [
                681
            ],
            "param": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "Visitor": {
            "email": [
                559
            ],
            "endTime": [
                654
            ],
            "firstName": [
                559
            ],
            "id": [
                293
            ],
            "isArchived": [
                129
            ],
            "lastName": [
                559
            ],
            "leaseContract": [
                325
            ],
            "leaseContractId": [
                293
            ],
            "notes": [
                559
            ],
            "organizationId": [
                293
            ],
            "phoneNumber": [
                559
            ],
            "sendByMedium": [
                686
            ],
            "startTime": [
                654
            ],
            "type": [
                688
            ],
            "__typename": [
                559
            ]
        },
        "VisitorCreateInput": {
            "email": [
                559
            ],
            "endTime": [
                654
            ],
            "firstName": [
                559
            ],
            "lastName": [
                559
            ],
            "leaseContractId": [
                293
            ],
            "notes": [
                559
            ],
            "organizationId": [
                293
            ],
            "phoneNumber": [
                559
            ],
            "sendByMedium": [
                686
            ],
            "startTime": [
                654
            ],
            "type": [
                688
            ],
            "__typename": [
                559
            ]
        },
        "VisitorOrderBy": {},
        "VisitorSendByMedium": {},
        "VisitorStatus": {},
        "VisitorType": {},
        "VisitorWhereInput": {
            "endDateLt": [
                654
            ],
            "leaseContractId": [
                293
            ],
            "searchName": [
                559
            ],
            "startDateGt": [
                654
            ],
            "status": [
                687
            ],
            "__typename": [
                559
            ]
        },
        "WebLinkData": {
            "openInBrowser": [
                129
            ],
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "WebLinkDataInput": {
            "openInBrowser": [
                129
            ],
            "url": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "WhereAppSettingsInput": {
            "leaseContractId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "WhiteLabel": {
            "appIcon": [
                559
            ],
            "appLink": [
                57
            ],
            "appName": [
                559
            ],
            "brandId": [
                293
            ],
            "id": [
                293
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "WhiteLabelCreateInput": {
            "appIcon": [
                559
            ],
            "appLink": [
                58
            ],
            "appName": [
                559
            ],
            "brandId": [
                293
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "WhiteLabelUpdateInput": {
            "appIcon": [
                559
            ],
            "appLink": [
                58
            ],
            "appName": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "amenityCalendarUnavailabilitySetInput": {
            "AmenityIds": [
                293
            ],
            "endDate": [
                654
            ],
            "reason": [
                559
            ],
            "startDate": [
                654
            ],
            "__typename": [
                559
            ]
        },
        "authChallengeType": {},
        "externalLockProviderGroup": {
            "entityId": [
                293
            ],
            "entityType": [
                237
            ],
            "id": [
                293
            ],
            "lockProvider": [
                342
            ],
            "lockProviderGroupId": [
                559
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "externalLockProviderGroupCreateInput": {
            "entityId": [
                293
            ],
            "entityType": [
                237
            ],
            "lockProvider": [
                342
            ],
            "lockProviderGroupId": [
                559
            ],
            "organizationId": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "externalLockProviderGroupList": {
            "items": [
                698
            ],
            "pageInfo": [
                394
            ],
            "__typename": [
                559
            ]
        },
        "externalLockProviderGroupUpdateInput": {
            "entityId": [
                293
            ],
            "entityType": [
                237
            ],
            "lockProvider": [
                342
            ],
            "lockProviderGroupId": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "interestGroupsAudienceParamsInput": {
            "interestGroupIds": [
                293
            ],
            "__typename": [
                559
            ]
        },
        "supportTicketNotificationText": {
            "message": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        },
        "supportTicketNotificationTextInput": {
            "message": [
                559
            ],
            "title": [
                559
            ],
            "__typename": [
                559
            ]
        }
    }
}