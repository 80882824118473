import { cloudinary } from '~/lib/cloudinary';

export const useOrgLogo = (logoUrl: string) => {
  const fetchAndModifySVG = async (fillColor: string) => {
    const svgUrl = cloudinary.image(logoUrl).format('svg').toURL();
    console.log('svgUrl:', svgUrl);
    if (!svgUrl) {
      return '';
    }
    try {
      const response = await fetch(svgUrl);
      let svgText = await response.text();

      const modifiedSVG = svgText
        .replace(/fill="#3E3A37"/g, `fill="${fillColor}"`)
        .replace(/height="\d+"/g, `height="40px"`)
        .replace(/width="\d+"/g, `width="auto"`);

      return modifiedSVG;
    } catch (error) {
      console.error('Error fetching or modifying SVG:', error);
      return '';
    }
  };

  return { fetchAndModifySVG };
};
