import { useFetcher, useFetchers } from '@remix-run/react';
import { Icon, Modal, text, TextField } from '@venncity/block';
import { Button } from '@venncity/venn-ds';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { Dialog, Heading } from 'react-aria-components';
import { getImageIdFromUrl } from '~/lib/cloudinary';
import type { loader } from '~/routes/resources+/settings+/account';
import { useResourceData } from '~/utils/http';
import type { ActionResponseBody } from '~/utils/http.server';
import { useSearchNavigate } from '~/utils/search-params';
import { ProfileImageUpload } from './profile-image-upload';

export function SettingsDialog() {
  const searchNavigate = useSearchNavigate();
  const { data } = useResourceData<typeof loader>(`resources/settings/account`);
  const fetchers = useFetchers();
  const fetcher = useFetcher<ActionResponseBody>();
  const errors = fetcher.data?.errors;
  const profileImage = data?.user?.profilePictureUrl;

  const anyFetchersArePending = fetchers.some(
    (fetcher) =>
      (['loading', 'submitting'].includes(fetcher.state) && fetcher.formMethod === 'POST') ||
      (fetcher.state === 'idle' && !isEmpty(fetcher.data?.data)),
  );

  const isPending =
    (fetcher.formMethod === 'POST' && ['loading', 'submitting'].includes(fetcher.state)) ||
    (fetcher.state === 'idle' && !isEmpty(fetcher.data?.data));

  React.useEffect(() => {
    if (fetcher.state === 'idle' && !isEmpty(fetcher.data?.data)) {
      searchNavigate(
        {
          view: undefined,
          dialogType: undefined,
        },
        { preventScrollReset: true },
      );
    }
  }, [fetcher.data, fetcher.state, searchNavigate]);

  return (
    <Modal
      className="overflow-auto md:max-w-xl lg:max-w-xl xl:max-w-xl"
      defaultOpen
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchNavigate(
            {
              dialogType: undefined,
            },
            { preventScrollReset: true },
          );
        }
      }}>
      <Dialog className="focus:outline-none">
        {({ close }) => (
          <>
            <header className="border-grey-300 flex items-center justify-between border-b py-5 pl-10 pr-7">
              <Heading className={text({ variant: 'p3', weight: 'bold' })} level={3} slot="title">
                Profile settings
              </Heading>
              <Button icon={<Icon name="close" />} onClick={close} shape="circle" />
            </header>
            <fetcher.Form
              action="/resources/settings/account"
              className="flex flex-col gap-x-20 p-10 md:flex-row"
              method="POST">
              <ProfileImageUpload
                defaultValue={profileImage ? getImageIdFromUrl(profileImage) : undefined}
                key={profileImage}
              />
              <div className="flex flex-1 flex-col content-evenly gap-y-8">
                <TextField
                  defaultValue={data?.user?.firstName || ''}
                  errorMessage={errors?.firstName}
                  key={data?.user?.firstName}
                  label="First name"
                  name="first-name"
                  placeholder="Change first name"
                />
                <TextField
                  defaultValue={data?.user?.lastName || ''}
                  errorMessage={errors?.lastName}
                  key={data?.user?.lastName}
                  label="Last name"
                  name="last-name"
                  placeholder="Change last name"
                />
                <TextField
                  defaultValue={data?.user?.email}
                  errorMessage={errors?.email}
                  isDisabled
                  key={data?.user?.email}
                  label="Email"
                  name="email"
                  placeholder="Change email"
                />
                <TextField
                  defaultValue={data?.user?.mobilePhone || ''}
                  errorMessage={errors?.phoneNumber}
                  isDisabled
                  key={data?.user?.mobilePhone}
                  label="Phone number"
                  name="phone-number"
                  placeholder="Change phone number"
                />
                <footer className="flex justify-end">
                  <Button
                    disabled={anyFetchersArePending}
                    formNoValidate={true}
                    htmlType="submit"
                    loading={isPending}
                    name="action"
                    type="primary">
                    Submit
                  </Button>
                </footer>
              </div>
            </fetcher.Form>
          </>
        )}
      </Dialog>
    </Modal>
  );
}
