import { useSearchParams } from '@remix-run/react';
import type { loader } from '~/routes/resources+/groups+/$id.edit';
import { useResourceData } from '~/utils/http';
import { GlobalSearchParam } from '~/utils/search-params';
import GroupsForm from './groups-form';

export default function GroupsEdit() {
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('group-id') || '';
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);
  const { data } = useResourceData<typeof loader>(
    `resources/groups/${groupId}/edit?${GlobalSearchParam.CommunityId}=${communityId}`,
  );

  return <GroupsForm groupId={groupId} initialValues={data?.group} title="Edit Group" />;
}
