import { useSearchParams } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import type { loader } from '~/routes/resources+/discussions+/$id.edit';
import { useResourceData } from '~/utils/http';
import DiscussionForm from './discussion-form';

export default function DiscussionEdit() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['discussion-edit']);
  const discussionId = searchParams.get('discussion-id') || '';

  const { data } = useResourceData<typeof loader>(`resources/discussions/${discussionId}/edit`);

  return (
    <DiscussionForm
      discussionId={discussionId}
      initialValues={data?.initialValues}
      title={t('title')}
    />
  );
}

export const handle = {
  i18n: ['discussion-edit'],
};
