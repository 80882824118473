import type { LoaderFunctionArgs } from '@remix-run/node';
import type {
  ClientLoaderFunctionArgs,
  LinkProps,
  ShouldRevalidateFunction,
} from '@remix-run/react';
import { Link, useLoaderData } from '@remix-run/react';
import type { IconName } from '@venncity/block';
import { Icon, Text } from '@venncity/block';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useAnalytics } from '~/lib/analytics';
import { requireLogin } from '~/lib/auth.server';
import { getSession } from '~/lib/session.server';
import { getFeatureFlags } from '~/services/flagsmith.server';
import { FeatureFlags } from '~/utils/feature-flags';
import { searchParamsHash } from '~/utils/search-params';

export async function loader({ request }: LoaderFunctionArgs) {
  await requireLogin(request);

  const session = await getSession(request);

  const userFirstName = session.get('user-first-name');

  const featureFlags = await getFeatureFlags(request, [FeatureFlags.hub_renewal]);

  return { userFirstName, featureFlags };
}

export async function clientLoader({ serverLoader }: ClientLoaderFunctionArgs) {
  const data = await serverLoader();

  await i18next.loadNamespaces(['home-page', 'app-layout']);

  return data;
}

export default function Home() {
  const loaderData = useLoaderData<typeof loader>();
  const { t } = useTranslation('home-page');

  return (
    <main className="grid grid-flow-row auto-rows-min grid-cols-12 py-6 text-white lg:py-20">
      <header className="col-span-12 flex min-w-0 flex-col items-center justify-center text-center md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
        <div className="flex flex-col gap-2 md:gap-3">
          <p className="text-p4 xl:text-p3">
            {t('homePage.hiUserName', { name: loaderData.userFirstName })}
          </p>
          <h2 className="text-p3 md:text-h3 xl:text-h2 font-bold">{t('homePage.title')}</h2>
        </div>
        <div className="mt-6 grid w-full auto-cols-min grid-flow-col grid-rows-3 justify-center gap-3 md:mt-8 md:auto-cols-fr md:grid-rows-1 md:gap-4 xl:mt-10">
          <QuickAction
            icon="send"
            to={{
              search: searchParamsHash({ dialogType: 'create-notification' }).toString(),
            }}>
            {t('homePage.sendNotification')}
          </QuickAction>
          <QuickAction icon="user" to="/residents?q=">
            {t('homePage.residentProfile')}
          </QuickAction>
          <QuickAction
            icon="calendar"
            to={{ search: searchParamsHash({ dialogType: 'event-form' }).toString() }}>
            {t('homePage.createEvent')}
          </QuickAction>
        </div>
      </header>
      <section className="col-span-12 mt-10 grid w-full grid-cols-1 gap-3 md:col-span-10 md:col-start-2 md:mt-8 md:grid-cols-10 md:grid-rows-3 md:gap-5 lg:mt-12 lg:grid-rows-2">
        <DomainCardLink
          className="h-48 md:col-span-6 md:col-start-1 xl:col-span-5"
          description={t('homePage.residents360.description')}
          icon="resident-360"
          title={t('homePage.residents360.title')}
          to="/residents">
          <div className="border-grey-300 flex items-center justify-center -space-x-2 rounded-xl border pl-5 pr-3">
            <img
              alt="Avatar 1"
              className="bg-grey-100 hidden h-10 w-10 rounded-full border border-white object-cover object-bottom xl:block"
              src="https://res.cloudinary.com/venn-city/image/upload/w_160,q_auto,e_sharpen:10/v1711956468/experience-hub/common/homepage/avatar-1_qqnwcc.webp"
            />
            <img
              alt="Avatar 2"
              className="bg-grey-100 h-10 w-10 rounded-full border border-white object-cover object-center"
              src="https://res.cloudinary.com/venn-city/image/upload/w_160,q_auto,e_sharpen:10/v1711956468/experience-hub/common/homepage/avatar-2_lfo4rf.webp"
            />
            <img
              alt="Avatar 3"
              className="bg-grey-100 h-10 w-10 rounded-full border border-white object-cover object-center"
              src="https://res.cloudinary.com/venn-city/image/upload/w_160,q_auto,e_sharpen:10/v1711956473/experience-hub/common/homepage/avatar-3_yncydp.webp"
            />
            <img
              alt="Avatar 4"
              className="bg-grey-100 h-10 w-10 rounded-full border border-white object-cover object-top"
              src="https://res.cloudinary.com/venn-city/image/upload/w_160,q_auto,e_sharpen:10/v1711956469/experience-hub/common/homepage/avatar-4_dylgxb.webp"
            />
            <img
              alt="Avatar 5"
              className="bg-grey-100 hidden h-10 w-10 rounded-full border border-white object-cover object-center lg:block"
              src="https://res.cloudinary.com/venn-city/image/upload/w_160,q_auto,e_sharpen:10/v1711956470/experience-hub/common/homepage/avatar-5_vcpi49.webp"
            />
          </div>
        </DomainCardLink>
        <DomainCardLink
          className="h-48 md:col-span-6 md:col-start-5 md:row-start-2 xl:col-span-4 xl:col-start-7"
          description={t('homePage.community.description')}
          icon="community"
          title={t('homePage.community.title')}
          to="/stay">
          <div className="border-grey-300 flex gap-x-1.5 rounded-xl border p-3">
            <img
              alt="Community 1"
              className="bg-grey-100 w-14 rounded-lg object-cover"
              src="https://res.cloudinary.com/venn-city/image/upload/w_300,h_500,c_fill,q_auto,e_sharpen:10/v1711956474/experience-hub/common/homepage/community-1_gvdydo.webp"
            />
            <img
              alt="Community 2"
              className="bg-grey-100 w-14 rounded-lg object-cover"
              src="https://res.cloudinary.com/venn-city/image/upload/w_300,h_500,c_fill,q_auto,e_sharpen:10/v1711956473/experience-hub/common/homepage/community-2_oylp9b.webp"
            />
            <img
              alt="Community 3"
              className="bg-grey-100 hidden w-14 rounded-lg object-cover xl:block"
              src="https://res.cloudinary.com/venn-city/image/upload/w_300,h_500,c_fill,q_auto,e_sharpen:10/v1711956469/experience-hub/common/homepage/community-3_v5dlja.webp"
            />
          </div>
        </DomainCardLink>
        <DomainCardLink
          className="h-48 md:col-span-3 md:col-start-4 md:row-start-3 xl:col-span-3 xl:col-start-8 xl:row-start-1"
          description={t('homePage.communication.description')}
          icon="communication"
          title={t('homePage.communication.title')}
          to="/communication"
        />
        <DomainCardLink
          className="h-48 md:col-span-4 md:col-start-1 md:row-start-2 xl:col-span-2 xl:col-start-3 "
          description={t('homePage.amenities.description')}
          icon="amenities"
          title={t('homePage.amenities.title')}
          to="/amenities/reservations"
        />
        <DomainCardLink
          className="h-48 md:col-span-3 md:col-start-1 md:row-start-3 xl:col-span-2 xl:col-start-5 xl:row-start-2"
          description={t('homePage.renewals.description')}
          icon="renewals"
          title={t('homePage.renewals.title')}
          to={loaderData.featureFlags.hub_renewal ? '/renewals' : '/renewals/coming-soon'}
        />
        <DomainCardLink
          className="h-48 md:col-span-4 md:col-start-7 md:row-start-1 xl:col-span-2 xl:col-start-6 "
          description={t('homePage.reports.description')}
          icon="reports"
          title={t('homePage.reports.title')}
          to="/mbr"
        />
        <DomainCardLink
          className="h-48 md:col-span-4 md:col-start-7 md:row-start-3 xl:col-span-2 xl:col-start-1 xl:row-start-2"
          description={t('homePage.moveIn.description')}
          icon="move-in"
          title={t('homePage.moveIn.title')}
          to="/move-in"
        />
      </section>
    </main>
  );
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
  formMethod,
}) => {
  if (formMethod === 'POST') {
    return defaultShouldRevalidate;
  }

  return false;
};

function QuickAction({ children, icon, ...props }: LinkProps & { icon: IconName }) {
  const { actionClicked } = useAnalytics();

  return (
    <Link
      className=" text-grey-900 shadow-2 text-p5 xl:text-p4 group flex h-10 w-48 items-center gap-3  rounded-xl bg-white p-1 font-medium md:w-auto xl:h-14 xl:p-2"
      onClick={(e) => actionClicked({ target: e.currentTarget })}
      prefetch="intent"
      {...props}>
      <div className="group-hover:bg-primary-500 bg-primary-400 flex h-8 w-8 items-center justify-center rounded-lg p-2 xl:h-10 xl:w-10">
        <Icon className="h-4 w-4 text-white xl:h-6 xl:w-6 " name={icon} />
      </div>
      <span className="truncate">{children}</span>
    </Link>
  );
}

function DomainCardLink({
  children,
  title,
  icon,
  className,
  description,
  ...props
}: {
  children?: React.ReactNode;
  title: string;
  icon: IconName;
  description: string;
} & LinkProps) {
  return (
    <Link
      {...props}
      className={twMerge(
        'text-grey-900 shadow-10 flex h-40 w-full justify-between rounded-xl bg-white p-4 transition-transform duration-300 ease-in-out hover:scale-[1.03]',
        className,
      )}
      prefetch="intent">
      <div className="flex flex-col items-start justify-between">
        <Icon className="h-10 w-10" name={icon} />
        <div className="flex flex-col gap-2">
          <p className="text-p3 xl:text-h3 font-bold">{title}</p>
          <Text className="hidden md:inline-block" variant="p5">
            {description}
          </Text>
        </div>
      </div>
      {children}
    </Link>
  );
}

export const handle = {
  i18n: ['home-page'],
};
