import { useFetcher } from '@remix-run/react';
import React from 'react';

export function useResourceData<T>(path: string) {
  const fetcher = useFetcher<T>();

  React.useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data && path) {
      fetcher.load(path);
    }
  }, [fetcher, path]);

  return fetcher;
}
