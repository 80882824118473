import { useFetcher, useSearchParams } from '@remix-run/react';
import { TextField, TextAreaField, Dialog } from '@venncity/block';
import { Button, Switch } from '@venncity/venn-ds';
import { isEmpty } from 'lodash-es';
import React from 'react';
import type { ValueOf } from 'type-fest';
import { ImageUpload } from '~/components/image-upload';
import { GroupFormAction, GroupFormTabs } from '~/dto/group-form-dto';
import { CommunityCombobox } from '~/routes/_app+/_layout/helpers/components/community-combobox';
import useScrollToError from '~/utils/hooks/useScrollToError';
import type { ActionResponseBody } from '~/utils/http.server';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';

export default function GroupsForm({
  groupId,
  initialValues,
  title,
}: {
  groupId?: string;
  title: string;
  initialValues?: {
    id?: string;
    isPublic?: boolean;
    description?: string;
    name?: string;
    imageResourceId?: string;
  };
}) {
  const searchNavigate = useSearchNavigate();
  const dialogRef = React.useRef<HTMLElement>(null);
  const fetcher = useFetcher<ActionResponseBody<{ id: string }>>();
  const buttonLabel = groupId ? 'Update Group' : 'Create Group';
  const anyFetchersArePending =
    fetcher.formMethod === 'POST' && ['loading', 'submitting'].includes(fetcher.state);
  const isSaving =
    anyFetchersArePending && fetcher.formData?.get('action') === GroupFormAction.Save;
  const isPreviewing =
    anyFetchersArePending && fetcher.formData?.get('action') === GroupFormAction.Preview;
  React.useEffect(() => {
    if (fetcher.state === 'loading' && !isEmpty(fetcher.data?.data)) {
      const action = fetcher.formData?.get('action') as ValueOf<typeof GroupFormAction>;

      if (action === GroupFormAction.Preview) {
        searchNavigate(
          { view: GroupFormTabs.Preview, groupId: fetcher.data?.data?.id },
          { preventScrollReset: true },
        );
      } else {
        searchNavigate(
          {
            view: undefined,
            groupId: undefined,
            dialogType: undefined,
          },
          { preventScrollReset: true },
        );
      }
    }
  }, [fetcher.data, fetcher.formData, fetcher.state, searchNavigate]);

  useScrollToError(dialogRef, fetcher.data?.errors || {});

  const [searchParams] = useSearchParams();
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);
  return (
    <Dialog ref={dialogRef}>
      <Dialog.Header title={title} />
      <fetcher.Form
        action={
          groupId
            ? `/resources/groups/${groupId}/edit?${GlobalSearchParam.CommunityId}=${communityId}`
            : `/resources/groups/new?${GlobalSearchParam.CommunityId}=${communityId}`
        }
        className="flex flex-1 flex-col"
        key={initialValues?.name}
        method="POST">
        <Dialog.Body>
          <TextField
            defaultValue={initialValues?.name}
            description="Write a name for your new group"
            errorMessage={fetcher.data?.errors?.name}
            label="Name"
            name="name"
          />
          {!initialValues && <CommunityCombobox />}
          <TextAreaField
            defaultValue={initialValues?.description}
            description="Write a description for your group"
            errorMessage={fetcher.data?.errors?.description}
            label="Description"
            name="description"
            rows={6}
          />
          <Switch
            defaultChecked={groupId ? initialValues?.isPublic : true}
            id="is-public"
            label="Published"
          />
          <ImageUpload
            defaultValue={
              initialValues?.imageResourceId ? initialValues.imageResourceId : undefined
            }
            description="Pick an image for your group"
            errorMessage={fetcher.data?.errors?.image}
            label="Image"
            name="image"
          />
        </Dialog.Body>
        <Dialog.Separator />
        <Dialog.Footer className="justify-between">
          <Button
            disabled={anyFetchersArePending}
            formNoValidate={true}
            htmlType="submit"
            loading={isPreviewing}
            name="action"
            value={GroupFormAction.Preview}>
            Preview
          </Button>
          <Button
            disabled={anyFetchersArePending}
            htmlType="submit"
            loading={isSaving}
            name="action"
            type="primary"
            value={GroupFormAction.Save}>
            {buttonLabel}
          </Button>
        </Dialog.Footer>
      </fetcher.Form>
    </Dialog>
  );
}
