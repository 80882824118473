import { Resize } from '@cloudinary/url-gen/actions';
import { Text } from '@venncity/block';
import { Divider } from '@venncity/venn-ds';
import { useTranslation } from 'react-i18next';
import { cloudinary } from '~/lib/cloudinary';

interface ActivityEventDTO {
  id: string;
  name: string;
  date: string;
  image: string;
}

interface ActivityGroupDTO {
  id: string;
  name: string;
  image?: string;
  count: number;
}

export function ActivityTab({
  events,
  groups,
}: {
  events: ActivityEventDTO[];
  groups?: ActivityGroupDTO[];
}) {
  const { t } = useTranslation('resident-profile');

  return (
    <div className="mt-6 flex w-full flex-col gap-4 px-4">
      <Section subtitle="Last 3 months" title="Events participation">
        <div className="p-4">
          {events?.length ? (
            events?.map((event) => (
              <div className="mt-3 flex" key={event.id}>
                <img
                  alt={event.name}
                  className="h-16 w-24 rounded-xl object-cover"
                  src={
                    event?.image
                      ? cloudinary
                          .image(event.image)
                          .format('webp')
                          .resize(Resize.fill().width(100).height(70))
                          .toURL()
                      : ''
                  }
                />
                <div className="ml-3 flex flex-col justify-between">
                  <Text className="line-clamp-2" variant="p5" weight="bold">
                    {event.name}
                  </Text>
                  <Text variant="p6">{event.date}</Text>
                </div>
              </div>
            ))
          ) : (
            <Text className="flex h-24 items-center justify-center">{t('empty-state.events')}</Text>
          )}
        </div>
      </Section>
      <Section title="Groups joined">
        <div className="p-4">
          {groups?.length ? (
            groups?.map((group) => (
              <div className="mt-3 flex" key={group.id}>
                <img
                  alt={group.name || 'group'}
                  className="h-16 w-16 rounded-xl object-cover"
                  src={
                    group?.image
                      ? cloudinary
                          .image(group.image)
                          .format('webp')
                          .resize(Resize.fill().width(70).height(70))
                          .toURL()
                      : ''
                  }
                />
                <div className="ml-3 flex flex-col justify-between">
                  <Text className="line-clamp-2" variant="p5" weight="bold">
                    {group.name}
                  </Text>
                  <Text variant="p6">{t('group.count', { num: group.count })}</Text>
                </div>
              </div>
            ))
          ) : (
            <Text className="flex h-24 items-center justify-center">{t('empty-state.groups')}</Text>
          )}
        </div>
      </Section>
      <Section title="Resident sentiment">
        <div className="p-4">
          <Text className="flex h-24 items-center justify-center italic">{t('coming-soon')}</Text>
        </div>
      </Section>
      <Section title="Service request behavior">
        <div className="p-4">
          <Text className="flex h-24 items-center justify-center italic">{t('coming-soon')}</Text>
        </div>
      </Section>
      <Section title="Payments behavior">
        <div className="p-4">
          <Text className="flex h-24 items-center justify-center italic">{t('coming-soon')}</Text>
        </div>
      </Section>
      <Section title="Amenities usage">
        <div className="p-4">
          <Text className="flex h-24 items-center justify-center italic">{t('coming-soon')}</Text>
        </div>
      </Section>
      <Section title="Resident satisfaction">
        <div className="p-4">
          <Text className="flex h-24 items-center justify-center italic">{t('coming-soon')}</Text>
        </div>
      </Section>
    </div>
  );
}

function Section({
  title,
  children,
  subtitle,
}: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className="border-grey-300 rounded-2xl border bg-white">
      <div className="flex flex-col gap-2 px-6 py-4">
        <Text weight="bold">{title}</Text>
        {subtitle && <Text variant="p6">{subtitle}</Text>}
      </div>
      <Divider marginless />
      {children}
    </div>
  );
}
