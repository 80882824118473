import type { LinkProps } from '@remix-run/react';
import { Link, useSearchParams } from '@remix-run/react';
import { searchParamsHash, type SearchParamsHash } from '~/utils/search-params';

export function SearchLink({
  children,
  to,
  ...props
}: Omit<LinkProps, 'to'> & { to: SearchParamsHash }) {
  const [searchParams] = useSearchParams();

  return (
    <Link to={{ search: searchParamsHash(to, searchParams).toString() }} {...props} replace>
      {children}
    </Link>
  );
}
