export function Fallback() {
  return (
    <svg fill="none" height="48" width="48" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <rect fill="#E1DFDE" height="48" rx="24" width="48" />
        <g fill="#888683">
          <circle cx="24" cy="19.714" r="9.429" />
          <circle cx="24" cy="49.714" r="18.857" transform="rotate(-180 24 49.714)" />
        </g>
      </g>
      <rect
        height="47.143"
        rx="23.571"
        stroke="#2C2927"
        strokeOpacity=".25"
        strokeWidth=".857"
        width="47.143"
        x=".429"
        y=".429"
      />
      <defs>
        <clipPath id="a">
          <rect fill="#fff" height="48" rx="24" width="48" />
        </clipPath>
      </defs>
    </svg>
  );
}
