import { Resize } from '@cloudinary/url-gen/actions';
import { useSearchParams } from '@remix-run/react';
import { Heading, Icon, Text, Dialog } from '@venncity/block';
import { Button, Spin, Divider } from '@venncity/venn-ds';
import dayjs from 'dayjs';
import { EventFormTabs, TicketType } from '~/dto/event-form-dto';
import { enumAdmissionFeeType } from '~/genql';
import { cloudinary } from '~/lib/cloudinary';
import type { loader } from '~/routes/resources+/events+/$id.preview';
import { useResourceData } from '~/utils/http';
import { useSearchNavigate } from '~/utils/search-params';

const admissionFeeTypeLabels: Record<string, string> = {
  [enumAdmissionFeeType.FREE]: 'Free',
  [enumAdmissionFeeType.COMPLIMENTARY]: 'Complimentary from management',
};

export function EventPreview() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  const eventId = searchParams.get('event-id');
  const { data } = useResourceData<typeof loader>(`resources/events/${eventId}/preview`);

  return (
    <Dialog>
      <Dialog.Header showCloseButton={false} title="Event preview" />
      <Dialog.Body className="pb-0">
        {data ? (
          <div className="bg-grey-900 rounded-2xl p-12">
            <div className="relative mx-auto mt-4 w-full self-center overflow-hidden rounded-3xl border-[4px] border-[#63605D] bg-[#F8F7F4] p-4">
              <div className="absolute inset-0 z-0 h-[350px] w-full bg-[#c6c6c3]">
                {data?.image ? (
                  <img
                    alt="Event"
                    className="absolute inset-0 z-0 h-full w-full object-cover object-center"
                    src={cloudinary.image(data?.image).resize(Resize.scale().width(700)).toURL()}
                  />
                ) : (
                  <div className="h-full w-full text-black"></div>
                )}
              </div>
              <div className="relative z-10 pt-40">
                <Heading className="text-center text-white">
                  {data?.name || "Your event's name"}
                </Heading>
                <Button className="mt-4 flex w-full justify-center" type="primary">
                  JOIN EVENT
                </Button>
                <div className="shadow-2 relative mt-4 flex flex-col overflow-hidden rounded-2xl bg-white p-4">
                  <Text className="text-[#625851]">When</Text>
                  <Text weight="bold">{dayjs(data?.startDate).format('dddd	MMM D, YYYY')}</Text>
                  <Text className="mt-1">{dayjs(data?.startDate).format('hh:mm A')}</Text>
                  <Text className="mt-4 text-[#625851]">Where</Text>
                  <Text className="mt-1" weight="bold">
                    {data?.place || "Your event's location"}
                  </Text>
                  <Text className="mt-1 underline">{data?.virtualLink ? 'Link to Zoom' : ''}</Text>
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      {data.admissionFeeType &&
                        data.admissionFeeType !== enumAdmissionFeeType.NONE && (
                          <>
                            <Text className="mt-4 text-[#625851]">Admission Fee</Text>
                            <Text className="mt-1" weight="bold">
                              {admissionFeeTypeLabels[data.admissionFeeType]}
                            </Text>
                          </>
                        )}
                    </div>
                    {data.shouldGenerateQRCode && <Icon className="items-end" name="ticket" />}
                  </div>
                </div>
                <div className="shadow-2 relative mt-4 flex flex-col overflow-hidden rounded-2xl bg-white p-4">
                  <Text>About</Text>
                  <Text
                    className="mt-1"
                    dangerouslySetInnerHTML={{ __html: data?.description || 'Event description' }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Spin />
          </div>
        )}
      </Dialog.Body>
      <div className="px-8">
        <Divider />
      </div>
      <Dialog.Footer className="justify-start">
        <Button
          onClick={() => {
            searchNavigate({ view: EventFormTabs.Form });
          }}>
          <Icon name="chevron-left" /> Edit
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

function admissionFee(ticketType: string = TicketType.Free, ticketPrice: number = 0) {
  switch (ticketType) {
    case TicketType.Paid:
      return '$' + ticketPrice;
    default:
      return 'Free';
  }
}
