import { useLocation } from '@remix-run/react';
import { motion } from 'motion/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useIsomorphicLayoutEffect } from '~/utils/dom';

export function ContentArea({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();

  useIsomorphicLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [location.pathname]);

  return (
    <motion.div
      className="flex min-h-0 min-w-0 flex-1 flex-col overflow-y-auto px-4 lg:px-10"
      layoutScroll
      ref={containerRef}>
      <div
        className={twMerge(
          'mx-auto flex min-h-0 w-full min-w-0 max-w-[1480px] flex-1 flex-col',
          className,
        )}>
        {children}
      </div>
    </motion.div>
  );
}
