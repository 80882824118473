import { redirect, type LoaderFunctionArgs } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import React from 'react';
import skylineImage from '~/images/skyline.png';
import { analytics, useAnalytics } from '~/lib/analytics';
import { getSession } from '~/lib/session.server';

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request);
  const userId = session.get('user-id');
  if (userId) {
    return redirect('/');
  }

  return {};
}

export default function Auth() {
  const { pageName, pageCategory } = useAnalytics();

  React.useEffect(() => {
    if (pageName) {
      void analytics.page(pageCategory, pageName);
    }
  }, [pageCategory, pageName]);

  return (
    <div
      className="flex flex-1 flex-col overflow-y-auto bg-black/40 bg-cover bg-center text-white bg-blend-overlay"
      style={{ backgroundImage: `url(${skylineImage})` }}>
      <header className="flex h-24 items-center justify-center py-7">
        <VennLogo />
      </header>
      <main className="flex flex-1 flex-col items-center justify-center">
        <article className="w-full max-w-xs">
          <Outlet />
        </article>
      </main>
    </div>
  );
}

function VennLogo() {
  return (
    <svg fill="none" height="19" viewBox="0 0 72 19" width="72" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M34.3028 10.4507C34.3322 10.0362 34.3617 9.5329 34.3617 9.05922C34.3322 3.70067 31.0635 0.0592194 26.293 0.0592194C21.2868 0.0592194 17.812 3.70067 17.812 9.05922C17.812 14.477 21.4341 18.0592 26.5285 18.0592C29.12 18.0592 31.4463 17.4375 33.5371 15.6612L31.6819 12.9967C30.2684 14.1217 28.4721 14.8026 26.7641 14.8026C24.3494 14.8026 22.1997 13.5296 21.6991 10.4507H34.3028ZM30.5629 7.60856H21.7285C22.1114 5.09211 23.5838 3.37501 26.2341 3.40461C28.6193 3.40461 30.1506 4.94409 30.5629 7.60856ZM6.83192 17.6151H11.2491L18.081 0.503307H14.135L9.0994 13.7369L4.06381 0.503307H0L6.83192 17.6151ZM52.653 6.86858V17.6153H48.9131V8.112C48.9131 5.24029 47.4408 3.64161 44.9377 3.64161C42.5524 3.64161 40.5205 5.24029 40.5205 8.17121V17.6449H36.8101V0.533052H40.491V2.63503C41.8456 0.62187 43.9659 0.0889731 45.7033 0.0889731C49.7966 0.0593678 52.653 2.87187 52.653 6.86858ZM72.0001 17.6153V6.86858C72.0001 2.87187 69.1437 0.0593678 65.021 0.0889731C63.2835 0.0889731 61.1633 0.62187 59.8087 2.63503V0.533052H56.1277V17.6449H59.8676V8.17121C59.8676 5.24029 61.8995 3.64161 64.2848 3.64161C66.7878 3.64161 68.2602 5.24029 68.2602 8.112V17.6153H72.0001Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}
