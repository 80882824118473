import { TagField } from '@venncity/block';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useAsyncList } from 'react-stately';
import type { BuildingResourceDTO } from '~/dto/building-resource-dto';
import { GlobalSearchParam } from '~/utils/search-params';

interface BuildingsTagFieldProps {
  defaultValueFull?: BuildingResourceDTO[];
  placeholder?: string;
  name?: string;
  errorMessage?: string | string[];
  label?: string;
  communityId?: string;
}

export default function BuildingsTagField({
  placeholder,
  name = 'buildings',
  defaultValueFull,
  communityId,
  errorMessage,
  label,
}: BuildingsTagFieldProps) {
  const buildingsList = useAsyncList<BuildingResourceDTO>({
    async load({ filterText }) {
      const resourceSearch = communityId ? `?${GlobalSearchParam.CommunityId}=${communityId}` : '';
      const { data } = await axios.get(`/resources/buildings${resourceSearch}`, {
        params: { q: filterText },
      });
      return { items: data || [] };
    },
  });

  return (
    <TagField
      defaultSelectedItems={defaultValueFull}
      errorMessage={errorMessage}
      getItemId={(item) => item.id}
      getItemLabel={(item) => item.name || ''}
      items={buildingsList.items}
      label={label}
      name={name}
      onInputChange={debounce(buildingsList.setFilterText, 400, {
        maxWait: 1000,
        leading: false,
        trailing: true,
      })}
      placeholder={placeholder}>
      {(item) => <span>{item.name}</span>}
    </TagField>
  );
}
