import { useRouteLoaderData } from '@remix-run/react';
import React from 'react';
import type { loader } from '~/routes/_app+/_layout';

export async function unwrap<T, U>(
  promise: Promise<T>,
  callback: (resolved: Awaited<T>) => Promise<U> | U,
) {
  return new Promise<U>(async (resolve, reject) => {
    try {
      const resolved = await promise;

      const augmented = await callback(resolved);

      resolve(augmented);
    } catch (error: any) {
      if (error.data) {
        const augmented = await callback(error.data);
        resolve(augmented);
      } else {
        reject(error);
      }
    }
  });
}

export default function useTimeout(callback: () => void, delay: number) {
  const timeoutRef = React.useRef<number>();
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(tick, delay);

      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);

  return timeoutRef;
}

export const sleep = (ms: number = 3000) => new Promise((resolve) => setTimeout(resolve, ms));

export function jsonPrettify(payload: any) {
  return JSON.stringify(payload, undefined, 2);
}

export function getBeginningOfNextQuarter() {
  return new Date(
    new Date(new Date().getFullYear(), 0, 1).setMonth(
      Math.ceil((new Date().getMonth() + 1) / 3) * 3,
    ),
  );
}

export function useAppLoaderData() {
  const appLoaderData = useRouteLoaderData<typeof loader>('routes/_app+/_layout/index');

  if (!appLoaderData) {
    throw new Error('useAppLoaderData can only be called in the context of the app layout');
  }

  return appLoaderData;
}
