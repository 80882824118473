import { useFetcher, useLoaderData } from '@remix-run/react';
import type { AvatarProps } from '@venncity/block';
import { Pressable, Dialog, Heading, Icon, Menu, Popover, Text } from '@venncity/block';
import { Avatar, Divider } from '@venncity/venn-ds';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { startCase } from 'lodash-es';
import { motion } from 'motion/react';
import React from 'react';
import type { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useAnalytics } from '~/lib/analytics';
import { cloudinary } from '~/lib/cloudinary';
import { GlobalDialogType, useSearchNavigate } from '~/utils/search-params';
import { sideMenuOpen, userProfileDialogOpen, walkMeShown } from '~/utils/state';
import { isValidUrl } from '~/utils/string';
import type { loader } from '../..';

export function UserProfileDialog() {
  const loaderData = useLoaderData<typeof loader>();
  const [isUserProfileDialogOpen, setIsProfileMenuOpen] = useAtom(userProfileDialogOpen);
  const [isSideMenuOpen] = useAtom(sideMenuOpen);
  const [isWalkMeShown] = useAtom(walkMeShown);
  const triggerRef = React.useRef<HTMLElement | null>(null);
  const SM_BREAKPOINT = 640;

  React.useEffect(() => {
    let trigger = triggerRef.current;

    if (isSideMenuOpen && window.innerWidth < SM_BREAKPOINT) {
      trigger = document.getElementById('side-menu-profile-dialog-trigger');
    } else {
      trigger = document.getElementById('user-profile-dialog-trigger');
    }

    triggerRef.current = trigger;
  }, [isSideMenuOpen]);

  return (
    <Popover
      className={clsx('max-h-60 w-80 px-5 pb-2 pt-6', isWalkMeShown && 'overflow-visible')}
      isKeyboardDismissDisabled={isWalkMeShown}
      isOpen={isUserProfileDialogOpen || isWalkMeShown}
      onOpenChange={setIsProfileMenuOpen}
      placement="bottom end"
      shouldCloseOnInteractOutside={() => !isWalkMeShown}
      triggerRef={triggerRef}>
      <Dialog className={clsx(isWalkMeShown && 'overflow-visible')}>
        <div className="flex flex-col items-center">
          <Heading className="max-w-[263px] truncate pb-4" variant="h3">
            {loaderData.organization.name}
          </Heading>
          <Avatar
            src={
              isValidUrl(loaderData.user.avatar)
                ? loaderData.user.avatar
                : cloudinary.image(loaderData.user.avatar).toURL()
            }
            superSize
            username={loaderData.user.name}
          />
          <Heading className="mt-4" key={loaderData.user.name} slot="title" variant="h3">
            {loaderData.user.name}
          </Heading>
          <Text className="mt-1" variant="p5">
            {loaderData.user.email}
          </Text>
        </div>
        <Divider />
        <UserProfileMenu />
      </Dialog>
    </Popover>
  );
}

export function UserProfileDialogTrigger({
  className,
  id,
  ...props
}: AvatarProps & { id: string }) {
  const loaderData = useLoaderData<typeof loader>();
  const [, setIsProfileMenuOpen] = useAtom(userProfileDialogOpen);
  const [isWalkMeShown] = useAtom(walkMeShown);

  return (
    <Pressable
      aria-label={loaderData.user.initials}
      className="focus:outline-none"
      id={id}
      isDisabled={!!isWalkMeShown}
      onPress={() => setIsProfileMenuOpen(true)}
      {...props}>
      <Avatar
        className={twMerge(clsx(isWalkMeShown && 'z-[9999]'), className)}
        key={loaderData.user.initials}
        size="large"
        src={
          isValidUrl(loaderData.user.avatar)
            ? loaderData.user.avatar
            : cloudinary.image(loaderData.user.avatar).toURL()
        }
        username={loaderData.user.name}
      />
    </Pressable>
  );
}

function UserProfileMenu() {
  const loaderData = useLoaderData<typeof loader>();
  const searchNavigate = useSearchNavigate();
  const { actionClicked } = useAnalytics();
  const [, setIsUserProfileOpen] = useAtom(userProfileDialogOpen);
  const [, setIsWalkMeShown] = useAtom(walkMeShown);
  const fetcher = useFetcher();
  const [isWalkMeShown] = useAtom(walkMeShown);
  const { t } = useTranslation('app-layout');

  function handleAction(action: Key) {
    switch (action) {
      case 'settings':
        searchNavigate({
          dialogType: GlobalDialogType.Settings,
        });

        break;
      case 'change-background-image':
        searchNavigate({
          dialogType: GlobalDialogType.ChangeBackgroundImage,
        });

        break;
      case 'watch-video':
        searchNavigate({
          dialogType: GlobalDialogType.ShowsIntroductoryVideo,
        });

        break;
    }

    actionClicked({ element: `View ${startCase(action.toString())}` });

    if (isWalkMeShown) {
      fetcher.submit({}, { action: '/resources/first-time-walkme', method: 'POST' });
    }

    setIsWalkMeShown(false);
    setIsUserProfileOpen(false);
  }

  return (
    <Menu
      aria-label="User profile menu"
      disabledKeys={isWalkMeShown ? ['idea', 'settings', 'change-background-image', 'logout'] : []}
      onAction={handleAction}>
      {loaderData.featureFlags.hub_admin_page && (
        <Menu.Item href={'/organizations/' + loaderData.organization.id} id="admin">
          <Icon name="settings" />
          {t('profile-dialog.menu.admin')}
        </Menu.Item>
      )}
      {loaderData.hubCollateralLink && !loaderData.featureFlags.hub_extended_onboarding && (
        <Menu.Item
          id="collateral"
          onAction={() => {
            if (!loaderData.hubCollateralLink) return;

            window.open(loaderData.hubCollateralLink, '_blank')?.focus();
          }}>
          <Icon name="idea" />
          {t('profile-dialog.menu.collateral')}
        </Menu.Item>
      )}
      <Menu.Item id="settings">
        <Icon name="profile" />
        {t('profile-dialog.menu.settings')}
      </Menu.Item>
      <Menu.Item id="change-background-image">
        <Icon name="picture" />
        {t('profile-dialog.menu.background')}
      </Menu.Item>
      {loaderData.featureFlags.hub_admin_tools && (
        <Menu.Item href="/organizations" id="organization-tools">
          <Icon name="neighborhood" />
          {t('profile-dialog.menu.organizations')}
        </Menu.Item>
      )}
      {loaderData.featureFlags.hub_internal_tools && (
        <Menu.Item href="/settings/reset-user-onboarding" id="internal-tools">
          <Icon name="maintenance" />
          {t('profile-dialog.menu.internal-tools')}
        </Menu.Item>
      )}
      {loaderData.featureFlags.hub_extended_onboarding && (
        <>
          <Menu.Item href="/marketing-materials" id="marketing-material">
            <div className="absolute -left-40 top-3">
              <BowRightArrow />
            </div>
            <Icon name="stars" />
            {t('profile-dialog.menu.marketing-materials')}
          </Menu.Item>
          <Menu.Item id="watch-video">
            <Icon name="film" />
            {t('profile-dialog.menu.watch-video')}
          </Menu.Item>
        </>
      )}
      <Menu.Separator className="my-2" />
      <Menu.Item href="//wkf.ms/45tNgYW" id="give-feedback" target="_blank">
        <Icon name="communication" />
        {t('profile-dialog.menu.feedback')}
      </Menu.Item>
      <Menu.Item href="/logout" id="logout">
        <Icon name="leave" />
        {t('profile-dialog.menu.logout')}
      </Menu.Item>
    </Menu>
  );
}

function BowRightArrow() {
  return (
    <motion.svg
      animate={{ x: -20 }}
      fill="none"
      height="85"
      transition={{
        duration: 0.5,
        repeat: 5,
        repeatType: 'reverse',
      }}
      viewBox="0 0 117 85"
      width="117"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 81.6983C3 81.6983 27.6586 12.4074 112.611 13.727"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="5.09508"
      />
      <path
        d="M85.5518 3C85.5518 3 97.0208 5.182 113.513 13.279"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="5.09508"
      />
      <path
        d="M112.82 13.1873C112.82 13.1873 98.4639 19.3917 89.2959 26.3743"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="5.09508"
      />
    </motion.svg>
  );
}
