import { VariantProps } from 'class-variance-authority';
import type { TextFieldProps } from 'react-aria-components';
import { Input, TextField } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { FieldLabel, FieldDescription, FieldError } from '../../atoms/field/field';
import { fieldLabel } from '../../electrons/field';
import { inputContainer, input } from '../../electrons/input';

interface _TextFieldProps extends TextFieldProps {
  label?: string;
  description?: string | React.ReactNode;
  errorMessage?: string | string[];
  placeholder?: string;
}

function _TextField({
  label,
  description,
  placeholder,
  errorMessage,
  isInvalid,
  size,
  weightLabel,
  ...props
}: _TextFieldProps & VariantProps<typeof input> & VariantProps<typeof fieldLabel>) {
  return (
    <TextField isInvalid={errorMessage ? true : isInvalid} {...props} validationBehavior="aria">
      {label && <FieldLabel weightLabel={weightLabel}>{label}</FieldLabel>}
      <Input
        className={twMerge(inputContainer(), input({ size }))}
        dir="auto"
        placeholder={placeholder}
      />
      {errorMessage ? (
        Array.isArray(errorMessage) ? (
          <ul className="mt-1">
            {errorMessage.map((message, i) => (
              <li className="leading-none" key={i}>
                <FieldError className="mt-0" label={label}>
                  {message}
                </FieldError>
              </li>
            ))}
          </ul>
        ) : (
          <FieldError label={label}>{errorMessage}</FieldError>
        )
      ) : (
        description && <FieldDescription>{description}</FieldDescription>
      )}
    </TextField>
  );
}

export { _TextField as TextField };
