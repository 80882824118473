import { Tag as AntTag, TagProps as AntTagProps } from 'antd';

export type { TagProps } from 'antd';

export enum TagColors {
  SUCCESS = 'success',
  PROCESSING = 'processing',
  ERROR = 'error',
  DEFAULT = 'default',
  WARNING = 'warning',
}

export const Tag = (props: AntTagProps) => {
  return <AntTag style={{ fontWeight: '500' }} {...props} />;
};
