/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
  Long: any;
};

export type AddCommentInput = {
  images?: InputMaybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  parentId: Scalars['String'];
  parentName: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  coordinate?: Maybe<Coordinate>;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  entrance?: Maybe<Scalars['String']>;
  googlePlaceID?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetName: Scalars['String'];
  streetNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  aggregate: AggregateAddress;
  edges: Array<Maybe<AddressEdge>>;
  pageInfo: PageInfo;
};

export type AddressCreateInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['String']>;
  googlePlaceID?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetName: Scalars['String'];
  streetNumber?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type AddressCreateOneInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  create?: InputMaybe<AddressCreateInput>;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  cursor: Scalars['String'];
  node: Address;
};

export enum AddressOrderByInput {
  CITY_ASC = 'city_ASC',
  CITY_DESC = 'city_DESC',
  COUNTRY_ASC = 'country_ASC',
  COUNTRY_DESC = 'country_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENTRANCE_ASC = 'entrance_ASC',
  ENTRANCE_DESC = 'entrance_DESC',
  GOOGLEPLACEID_ASC = 'googlePlaceID_ASC',
  GOOGLEPLACEID_DESC = 'googlePlaceID_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  LATITUDE_ASC = 'latitude_ASC',
  LATITUDE_DESC = 'latitude_DESC',
  LONGITUDE_ASC = 'longitude_ASC',
  LONGITUDE_DESC = 'longitude_DESC',
  STATE_ASC = 'state_ASC',
  STATE_DESC = 'state_DESC',
  STREETNAME_ASC = 'streetName_ASC',
  STREETNAME_DESC = 'streetName_DESC',
  STREETNUMBER_ASC = 'streetNumber_ASC',
  STREETNUMBER_DESC = 'streetNumber_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  ZIPCODE_ASC = 'zipcode_ASC',
  ZIPCODE_DESC = 'zipcode_DESC'
}

export enum AddressType {
  BILLING_ADDRESS = 'BILLING_ADDRESS'
}

export type AddressUpdateDataInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['String']>;
  googlePlaceID?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['String']>;
  googlePlaceID?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type AddressUpdateOneInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  create?: InputMaybe<AddressCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AddressUpdateDataInput>;
  upsert?: InputMaybe<AddressUpsertNestedInput>;
};

export type AddressUpsertNestedInput = {
  create: AddressCreateInput;
  update: AddressUpdateDataInput;
};

export type AddressWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<AddressWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<AddressWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<AddressWhereContractsPersistencyInput>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_ends_with?: InputMaybe<Scalars['String']>;
  city_gt?: InputMaybe<Scalars['String']>;
  city_gte?: InputMaybe<Scalars['String']>;
  city_in?: InputMaybe<Array<Scalars['String']>>;
  city_lt?: InputMaybe<Scalars['String']>;
  city_lte?: InputMaybe<Scalars['String']>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_ends_with?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<Scalars['String']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']>;
  city_starts_with?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_ends_with?: InputMaybe<Scalars['String']>;
  country_gt?: InputMaybe<Scalars['String']>;
  country_gte?: InputMaybe<Scalars['String']>;
  country_in?: InputMaybe<Array<Scalars['String']>>;
  country_lt?: InputMaybe<Scalars['String']>;
  country_lte?: InputMaybe<Scalars['String']>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_ends_with?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<Scalars['String']>>;
  country_not_starts_with?: InputMaybe<Scalars['String']>;
  country_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['String']>;
  entrance_contains?: InputMaybe<Scalars['String']>;
  entrance_ends_with?: InputMaybe<Scalars['String']>;
  entrance_gt?: InputMaybe<Scalars['String']>;
  entrance_gte?: InputMaybe<Scalars['String']>;
  entrance_in?: InputMaybe<Array<Scalars['String']>>;
  entrance_lt?: InputMaybe<Scalars['String']>;
  entrance_lte?: InputMaybe<Scalars['String']>;
  entrance_not?: InputMaybe<Scalars['String']>;
  entrance_not_contains?: InputMaybe<Scalars['String']>;
  entrance_not_ends_with?: InputMaybe<Scalars['String']>;
  entrance_not_in?: InputMaybe<Array<Scalars['String']>>;
  entrance_not_starts_with?: InputMaybe<Scalars['String']>;
  entrance_starts_with?: InputMaybe<Scalars['String']>;
  googlePlaceID?: InputMaybe<Scalars['String']>;
  googlePlaceID_contains?: InputMaybe<Scalars['String']>;
  googlePlaceID_ends_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_gt?: InputMaybe<Scalars['String']>;
  googlePlaceID_gte?: InputMaybe<Scalars['String']>;
  googlePlaceID_in?: InputMaybe<Array<Scalars['String']>>;
  googlePlaceID_lt?: InputMaybe<Scalars['String']>;
  googlePlaceID_lte?: InputMaybe<Scalars['String']>;
  googlePlaceID_not?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_contains?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_ends_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_in?: InputMaybe<Array<Scalars['String']>>;
  googlePlaceID_not_starts_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  latitude_contains?: InputMaybe<Scalars['String']>;
  latitude_ends_with?: InputMaybe<Scalars['String']>;
  latitude_gt?: InputMaybe<Scalars['String']>;
  latitude_gte?: InputMaybe<Scalars['String']>;
  latitude_in?: InputMaybe<Array<Scalars['String']>>;
  latitude_lt?: InputMaybe<Scalars['String']>;
  latitude_lte?: InputMaybe<Scalars['String']>;
  latitude_not?: InputMaybe<Scalars['String']>;
  latitude_not_contains?: InputMaybe<Scalars['String']>;
  latitude_not_ends_with?: InputMaybe<Scalars['String']>;
  latitude_not_in?: InputMaybe<Array<Scalars['String']>>;
  latitude_not_starts_with?: InputMaybe<Scalars['String']>;
  latitude_starts_with?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  longitude_contains?: InputMaybe<Scalars['String']>;
  longitude_ends_with?: InputMaybe<Scalars['String']>;
  longitude_gt?: InputMaybe<Scalars['String']>;
  longitude_gte?: InputMaybe<Scalars['String']>;
  longitude_in?: InputMaybe<Array<Scalars['String']>>;
  longitude_lt?: InputMaybe<Scalars['String']>;
  longitude_lte?: InputMaybe<Scalars['String']>;
  longitude_not?: InputMaybe<Scalars['String']>;
  longitude_not_contains?: InputMaybe<Scalars['String']>;
  longitude_not_ends_with?: InputMaybe<Scalars['String']>;
  longitude_not_in?: InputMaybe<Array<Scalars['String']>>;
  longitude_not_starts_with?: InputMaybe<Scalars['String']>;
  longitude_starts_with?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  streetNumber_contains?: InputMaybe<Scalars['String']>;
  streetNumber_ends_with?: InputMaybe<Scalars['String']>;
  streetNumber_gt?: InputMaybe<Scalars['String']>;
  streetNumber_gte?: InputMaybe<Scalars['String']>;
  streetNumber_in?: InputMaybe<Array<Scalars['String']>>;
  streetNumber_lt?: InputMaybe<Scalars['String']>;
  streetNumber_lte?: InputMaybe<Scalars['String']>;
  streetNumber_not?: InputMaybe<Scalars['String']>;
  streetNumber_not_contains?: InputMaybe<Scalars['String']>;
  streetNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  streetNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  streetNumber_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_gt?: InputMaybe<Scalars['String']>;
  zipcode_gte?: InputMaybe<Scalars['String']>;
  zipcode_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_lt?: InputMaybe<Scalars['String']>;
  zipcode_lte?: InputMaybe<Scalars['String']>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_not_starts_with?: InputMaybe<Scalars['String']>;
  zipcode_starts_with?: InputMaybe<Scalars['String']>;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_ends_with?: InputMaybe<Scalars['String']>;
  city_gt?: InputMaybe<Scalars['String']>;
  city_gte?: InputMaybe<Scalars['String']>;
  city_in?: InputMaybe<Array<Scalars['String']>>;
  city_lt?: InputMaybe<Scalars['String']>;
  city_lte?: InputMaybe<Scalars['String']>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_ends_with?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<Scalars['String']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']>;
  city_starts_with?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_ends_with?: InputMaybe<Scalars['String']>;
  country_gt?: InputMaybe<Scalars['String']>;
  country_gte?: InputMaybe<Scalars['String']>;
  country_in?: InputMaybe<Array<Scalars['String']>>;
  country_lt?: InputMaybe<Scalars['String']>;
  country_lte?: InputMaybe<Scalars['String']>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_ends_with?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<Scalars['String']>>;
  country_not_starts_with?: InputMaybe<Scalars['String']>;
  country_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['String']>;
  entrance_contains?: InputMaybe<Scalars['String']>;
  entrance_ends_with?: InputMaybe<Scalars['String']>;
  entrance_gt?: InputMaybe<Scalars['String']>;
  entrance_gte?: InputMaybe<Scalars['String']>;
  entrance_in?: InputMaybe<Array<Scalars['String']>>;
  entrance_lt?: InputMaybe<Scalars['String']>;
  entrance_lte?: InputMaybe<Scalars['String']>;
  entrance_not?: InputMaybe<Scalars['String']>;
  entrance_not_contains?: InputMaybe<Scalars['String']>;
  entrance_not_ends_with?: InputMaybe<Scalars['String']>;
  entrance_not_in?: InputMaybe<Array<Scalars['String']>>;
  entrance_not_starts_with?: InputMaybe<Scalars['String']>;
  entrance_starts_with?: InputMaybe<Scalars['String']>;
  googlePlaceID?: InputMaybe<Scalars['String']>;
  googlePlaceID_contains?: InputMaybe<Scalars['String']>;
  googlePlaceID_ends_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_gt?: InputMaybe<Scalars['String']>;
  googlePlaceID_gte?: InputMaybe<Scalars['String']>;
  googlePlaceID_in?: InputMaybe<Array<Scalars['String']>>;
  googlePlaceID_lt?: InputMaybe<Scalars['String']>;
  googlePlaceID_lte?: InputMaybe<Scalars['String']>;
  googlePlaceID_not?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_contains?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_ends_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_in?: InputMaybe<Array<Scalars['String']>>;
  googlePlaceID_not_starts_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  latitude_contains?: InputMaybe<Scalars['String']>;
  latitude_ends_with?: InputMaybe<Scalars['String']>;
  latitude_gt?: InputMaybe<Scalars['String']>;
  latitude_gte?: InputMaybe<Scalars['String']>;
  latitude_in?: InputMaybe<Array<Scalars['String']>>;
  latitude_lt?: InputMaybe<Scalars['String']>;
  latitude_lte?: InputMaybe<Scalars['String']>;
  latitude_not?: InputMaybe<Scalars['String']>;
  latitude_not_contains?: InputMaybe<Scalars['String']>;
  latitude_not_ends_with?: InputMaybe<Scalars['String']>;
  latitude_not_in?: InputMaybe<Array<Scalars['String']>>;
  latitude_not_starts_with?: InputMaybe<Scalars['String']>;
  latitude_starts_with?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  longitude_contains?: InputMaybe<Scalars['String']>;
  longitude_ends_with?: InputMaybe<Scalars['String']>;
  longitude_gt?: InputMaybe<Scalars['String']>;
  longitude_gte?: InputMaybe<Scalars['String']>;
  longitude_in?: InputMaybe<Array<Scalars['String']>>;
  longitude_lt?: InputMaybe<Scalars['String']>;
  longitude_lte?: InputMaybe<Scalars['String']>;
  longitude_not?: InputMaybe<Scalars['String']>;
  longitude_not_contains?: InputMaybe<Scalars['String']>;
  longitude_not_ends_with?: InputMaybe<Scalars['String']>;
  longitude_not_in?: InputMaybe<Array<Scalars['String']>>;
  longitude_not_starts_with?: InputMaybe<Scalars['String']>;
  longitude_starts_with?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  streetNumber_contains?: InputMaybe<Scalars['String']>;
  streetNumber_ends_with?: InputMaybe<Scalars['String']>;
  streetNumber_gt?: InputMaybe<Scalars['String']>;
  streetNumber_gte?: InputMaybe<Scalars['String']>;
  streetNumber_in?: InputMaybe<Array<Scalars['String']>>;
  streetNumber_lt?: InputMaybe<Scalars['String']>;
  streetNumber_lte?: InputMaybe<Scalars['String']>;
  streetNumber_not?: InputMaybe<Scalars['String']>;
  streetNumber_not_contains?: InputMaybe<Scalars['String']>;
  streetNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  streetNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  streetNumber_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_gt?: InputMaybe<Scalars['String']>;
  zipcode_gte?: InputMaybe<Scalars['String']>;
  zipcode_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_lt?: InputMaybe<Scalars['String']>;
  zipcode_lte?: InputMaybe<Scalars['String']>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_not_starts_with?: InputMaybe<Scalars['String']>;
  zipcode_starts_with?: InputMaybe<Scalars['String']>;
};

export type AddressWhereInputPersistency = {
  AND?: InputMaybe<Array<AddressWhereInputPersistency>>;
  NOT?: InputMaybe<Array<AddressWhereInputPersistency>>;
  OR?: InputMaybe<Array<AddressWhereInputPersistency>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_ends_with?: InputMaybe<Scalars['String']>;
  city_gt?: InputMaybe<Scalars['String']>;
  city_gte?: InputMaybe<Scalars['String']>;
  city_in?: InputMaybe<Array<Scalars['String']>>;
  city_lt?: InputMaybe<Scalars['String']>;
  city_lte?: InputMaybe<Scalars['String']>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_ends_with?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<Scalars['String']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']>;
  city_starts_with?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_ends_with?: InputMaybe<Scalars['String']>;
  country_gt?: InputMaybe<Scalars['String']>;
  country_gte?: InputMaybe<Scalars['String']>;
  country_in?: InputMaybe<Array<Scalars['String']>>;
  country_lt?: InputMaybe<Scalars['String']>;
  country_lte?: InputMaybe<Scalars['String']>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_ends_with?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<Scalars['String']>>;
  country_not_starts_with?: InputMaybe<Scalars['String']>;
  country_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['String']>;
  entrance_contains?: InputMaybe<Scalars['String']>;
  entrance_ends_with?: InputMaybe<Scalars['String']>;
  entrance_gt?: InputMaybe<Scalars['String']>;
  entrance_gte?: InputMaybe<Scalars['String']>;
  entrance_in?: InputMaybe<Array<Scalars['String']>>;
  entrance_lt?: InputMaybe<Scalars['String']>;
  entrance_lte?: InputMaybe<Scalars['String']>;
  entrance_not?: InputMaybe<Scalars['String']>;
  entrance_not_contains?: InputMaybe<Scalars['String']>;
  entrance_not_ends_with?: InputMaybe<Scalars['String']>;
  entrance_not_in?: InputMaybe<Array<Scalars['String']>>;
  entrance_not_starts_with?: InputMaybe<Scalars['String']>;
  entrance_starts_with?: InputMaybe<Scalars['String']>;
  googlePlaceID?: InputMaybe<Scalars['String']>;
  googlePlaceID_contains?: InputMaybe<Scalars['String']>;
  googlePlaceID_ends_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_gt?: InputMaybe<Scalars['String']>;
  googlePlaceID_gte?: InputMaybe<Scalars['String']>;
  googlePlaceID_in?: InputMaybe<Array<Scalars['String']>>;
  googlePlaceID_lt?: InputMaybe<Scalars['String']>;
  googlePlaceID_lte?: InputMaybe<Scalars['String']>;
  googlePlaceID_not?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_contains?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_ends_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_in?: InputMaybe<Array<Scalars['String']>>;
  googlePlaceID_not_starts_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  latitude_contains?: InputMaybe<Scalars['String']>;
  latitude_ends_with?: InputMaybe<Scalars['String']>;
  latitude_gt?: InputMaybe<Scalars['String']>;
  latitude_gte?: InputMaybe<Scalars['String']>;
  latitude_in?: InputMaybe<Array<Scalars['String']>>;
  latitude_lt?: InputMaybe<Scalars['String']>;
  latitude_lte?: InputMaybe<Scalars['String']>;
  latitude_not?: InputMaybe<Scalars['String']>;
  latitude_not_contains?: InputMaybe<Scalars['String']>;
  latitude_not_ends_with?: InputMaybe<Scalars['String']>;
  latitude_not_in?: InputMaybe<Array<Scalars['String']>>;
  latitude_not_starts_with?: InputMaybe<Scalars['String']>;
  latitude_starts_with?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  longitude_contains?: InputMaybe<Scalars['String']>;
  longitude_ends_with?: InputMaybe<Scalars['String']>;
  longitude_gt?: InputMaybe<Scalars['String']>;
  longitude_gte?: InputMaybe<Scalars['String']>;
  longitude_in?: InputMaybe<Array<Scalars['String']>>;
  longitude_lt?: InputMaybe<Scalars['String']>;
  longitude_lte?: InputMaybe<Scalars['String']>;
  longitude_not?: InputMaybe<Scalars['String']>;
  longitude_not_contains?: InputMaybe<Scalars['String']>;
  longitude_not_ends_with?: InputMaybe<Scalars['String']>;
  longitude_not_in?: InputMaybe<Array<Scalars['String']>>;
  longitude_not_starts_with?: InputMaybe<Scalars['String']>;
  longitude_starts_with?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  streetNumber_contains?: InputMaybe<Scalars['String']>;
  streetNumber_ends_with?: InputMaybe<Scalars['String']>;
  streetNumber_gt?: InputMaybe<Scalars['String']>;
  streetNumber_gte?: InputMaybe<Scalars['String']>;
  streetNumber_in?: InputMaybe<Array<Scalars['String']>>;
  streetNumber_lt?: InputMaybe<Scalars['String']>;
  streetNumber_lte?: InputMaybe<Scalars['String']>;
  streetNumber_not?: InputMaybe<Scalars['String']>;
  streetNumber_not_contains?: InputMaybe<Scalars['String']>;
  streetNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  streetNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  streetNumber_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_gt?: InputMaybe<Scalars['String']>;
  zipcode_gte?: InputMaybe<Scalars['String']>;
  zipcode_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_lt?: InputMaybe<Scalars['String']>;
  zipcode_lte?: InputMaybe<Scalars['String']>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_not_starts_with?: InputMaybe<Scalars['String']>;
  zipcode_starts_with?: InputMaybe<Scalars['String']>;
};

export type AddressWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<AddressWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<AddressWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<AddressWherePersistencyContractsInput>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_ends_with?: InputMaybe<Scalars['String']>;
  city_gt?: InputMaybe<Scalars['String']>;
  city_gte?: InputMaybe<Scalars['String']>;
  city_in?: InputMaybe<Array<Scalars['String']>>;
  city_lt?: InputMaybe<Scalars['String']>;
  city_lte?: InputMaybe<Scalars['String']>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_ends_with?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<Scalars['String']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']>;
  city_starts_with?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_ends_with?: InputMaybe<Scalars['String']>;
  country_gt?: InputMaybe<Scalars['String']>;
  country_gte?: InputMaybe<Scalars['String']>;
  country_in?: InputMaybe<Array<Scalars['String']>>;
  country_lt?: InputMaybe<Scalars['String']>;
  country_lte?: InputMaybe<Scalars['String']>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_ends_with?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<Scalars['String']>>;
  country_not_starts_with?: InputMaybe<Scalars['String']>;
  country_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['String']>;
  entrance_contains?: InputMaybe<Scalars['String']>;
  entrance_ends_with?: InputMaybe<Scalars['String']>;
  entrance_gt?: InputMaybe<Scalars['String']>;
  entrance_gte?: InputMaybe<Scalars['String']>;
  entrance_in?: InputMaybe<Array<Scalars['String']>>;
  entrance_lt?: InputMaybe<Scalars['String']>;
  entrance_lte?: InputMaybe<Scalars['String']>;
  entrance_not?: InputMaybe<Scalars['String']>;
  entrance_not_contains?: InputMaybe<Scalars['String']>;
  entrance_not_ends_with?: InputMaybe<Scalars['String']>;
  entrance_not_in?: InputMaybe<Array<Scalars['String']>>;
  entrance_not_starts_with?: InputMaybe<Scalars['String']>;
  entrance_starts_with?: InputMaybe<Scalars['String']>;
  googlePlaceID?: InputMaybe<Scalars['String']>;
  googlePlaceID_contains?: InputMaybe<Scalars['String']>;
  googlePlaceID_ends_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_gt?: InputMaybe<Scalars['String']>;
  googlePlaceID_gte?: InputMaybe<Scalars['String']>;
  googlePlaceID_in?: InputMaybe<Array<Scalars['String']>>;
  googlePlaceID_lt?: InputMaybe<Scalars['String']>;
  googlePlaceID_lte?: InputMaybe<Scalars['String']>;
  googlePlaceID_not?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_contains?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_ends_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_not_in?: InputMaybe<Array<Scalars['String']>>;
  googlePlaceID_not_starts_with?: InputMaybe<Scalars['String']>;
  googlePlaceID_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  latitude_contains?: InputMaybe<Scalars['String']>;
  latitude_ends_with?: InputMaybe<Scalars['String']>;
  latitude_gt?: InputMaybe<Scalars['String']>;
  latitude_gte?: InputMaybe<Scalars['String']>;
  latitude_in?: InputMaybe<Array<Scalars['String']>>;
  latitude_lt?: InputMaybe<Scalars['String']>;
  latitude_lte?: InputMaybe<Scalars['String']>;
  latitude_not?: InputMaybe<Scalars['String']>;
  latitude_not_contains?: InputMaybe<Scalars['String']>;
  latitude_not_ends_with?: InputMaybe<Scalars['String']>;
  latitude_not_in?: InputMaybe<Array<Scalars['String']>>;
  latitude_not_starts_with?: InputMaybe<Scalars['String']>;
  latitude_starts_with?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  longitude_contains?: InputMaybe<Scalars['String']>;
  longitude_ends_with?: InputMaybe<Scalars['String']>;
  longitude_gt?: InputMaybe<Scalars['String']>;
  longitude_gte?: InputMaybe<Scalars['String']>;
  longitude_in?: InputMaybe<Array<Scalars['String']>>;
  longitude_lt?: InputMaybe<Scalars['String']>;
  longitude_lte?: InputMaybe<Scalars['String']>;
  longitude_not?: InputMaybe<Scalars['String']>;
  longitude_not_contains?: InputMaybe<Scalars['String']>;
  longitude_not_ends_with?: InputMaybe<Scalars['String']>;
  longitude_not_in?: InputMaybe<Array<Scalars['String']>>;
  longitude_not_starts_with?: InputMaybe<Scalars['String']>;
  longitude_starts_with?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  streetNumber_contains?: InputMaybe<Scalars['String']>;
  streetNumber_ends_with?: InputMaybe<Scalars['String']>;
  streetNumber_gt?: InputMaybe<Scalars['String']>;
  streetNumber_gte?: InputMaybe<Scalars['String']>;
  streetNumber_in?: InputMaybe<Array<Scalars['String']>>;
  streetNumber_lt?: InputMaybe<Scalars['String']>;
  streetNumber_lte?: InputMaybe<Scalars['String']>;
  streetNumber_not?: InputMaybe<Scalars['String']>;
  streetNumber_not_contains?: InputMaybe<Scalars['String']>;
  streetNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  streetNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  streetNumber_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_gt?: InputMaybe<Scalars['String']>;
  zipcode_gte?: InputMaybe<Scalars['String']>;
  zipcode_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_lt?: InputMaybe<Scalars['String']>;
  zipcode_lte?: InputMaybe<Scalars['String']>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_not_starts_with?: InputMaybe<Scalars['String']>;
  zipcode_starts_with?: InputMaybe<Scalars['String']>;
};

export type AddressWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AggregateAddress = {
  __typename?: 'AggregateAddress';
  count: Scalars['Int'];
};

export type AggregateAlbertDog = {
  __typename?: 'AggregateAlbertDog';
  count: Scalars['Int'];
};

export type AggregateApplication = {
  __typename?: 'AggregateApplication';
  count: Scalars['Int'];
};

export type AggregateAudit = {
  __typename?: 'AggregateAudit';
  count: Scalars['Int'];
};

export type AggregateBedroom = {
  __typename?: 'AggregateBedroom';
  count: Scalars['Int'];
};

export type AggregateBill = {
  __typename?: 'AggregateBill';
  count: Scalars['Int'];
};

export type AggregateBillableItem = {
  __typename?: 'AggregateBillableItem';
  count: Scalars['Int'];
};

export type AggregateBuilding = {
  __typename?: 'AggregateBuilding';
  count: Scalars['Int'];
};

export type AggregateCalendar = {
  __typename?: 'AggregateCalendar';
  count: Scalars['Int'];
};

export type AggregateCalendarBooking = {
  __typename?: 'AggregateCalendarBooking';
  count: Scalars['Int'];
};

export type AggregateComment = {
  __typename?: 'AggregateComment';
  count: Scalars['Int'];
};

export type AggregateCommunityTitle = {
  __typename?: 'AggregateCommunityTitle';
  count: Scalars['Int'];
};

export type AggregateCurrency = {
  __typename?: 'AggregateCurrency';
  count: Scalars['Int'];
};

export type AggregateDeliveryPackage = {
  __typename?: 'AggregateDeliveryPackage';
  count: Scalars['Int'];
};

export type AggregateEvent = {
  __typename?: 'AggregateEvent';
  count: Scalars['Int'];
};

export type AggregateEventAttendee = {
  __typename?: 'AggregateEventAttendee';
  count: Scalars['Int'];
};

export type AggregateExternalBill = {
  __typename?: 'AggregateExternalBill';
  count: Scalars['Int'];
};

export type AggregateExternalReceipt = {
  __typename?: 'AggregateExternalReceipt';
  count: Scalars['Int'];
};

export type AggregateFeature = {
  __typename?: 'AggregateFeature';
  count: Scalars['Int'];
};

export type AggregateFeatureAnnouncement = {
  __typename?: 'AggregateFeatureAnnouncement';
  count: Scalars['Int'];
};

export type AggregateFeaturedSlide = {
  __typename?: 'AggregateFeaturedSlide';
  count: Scalars['Int'];
};

export type AggregateFeedClassification = {
  __typename?: 'AggregateFeedClassification';
  count: Scalars['Int'];
};

export type AggregateHood = {
  __typename?: 'AggregateHood';
  count: Scalars['Int'];
};

export type AggregateHoodSetting = {
  __typename?: 'AggregateHoodSetting';
  count: Scalars['Int'];
};

export type AggregateHoodSettingValue = {
  __typename?: 'AggregateHoodSettingValue';
  count: Scalars['Int'];
};

export type AggregateHoodSettingsGroup = {
  __typename?: 'AggregateHoodSettingsGroup';
  count: Scalars['Int'];
};

export type AggregateInterestGroup = {
  __typename?: 'AggregateInterestGroup';
  count: Scalars['Int'];
};

export type AggregateInvoice = {
  __typename?: 'AggregateInvoice';
  count: Scalars['Int'];
};

export type AggregateLeaseContract = {
  __typename?: 'AggregateLeaseContract';
  count: Scalars['Int'];
};

export type AggregateMember = {
  __typename?: 'AggregateMember';
  count: Scalars['Int'];
};

export type AggregateMemberAddress = {
  __typename?: 'AggregateMemberAddress';
  count: Scalars['Int'];
};

export type AggregateMemberProfile = {
  __typename?: 'AggregateMemberProfile';
  count: Scalars['Int'];
};

export type AggregateMemberProfileAddressField = {
  __typename?: 'AggregateMemberProfileAddressField';
  count: Scalars['Int'];
};

export type AggregateMemberProfileBlock = {
  __typename?: 'AggregateMemberProfileBlock';
  count: Scalars['Int'];
};

export type AggregateMemberProfileCallToActionField = {
  __typename?: 'AggregateMemberProfileCallToActionField';
  count: Scalars['Int'];
};

export type AggregateMemberProfileDateField = {
  __typename?: 'AggregateMemberProfileDateField';
  count: Scalars['Int'];
};

export type AggregateMemberProfilePetsField = {
  __typename?: 'AggregateMemberProfilePetsField';
  count: Scalars['Int'];
};

export type AggregateMemberProfilePronounsField = {
  __typename?: 'AggregateMemberProfilePronounsField';
  count: Scalars['Int'];
};

export type AggregateMemberProfileSocialLinksField = {
  __typename?: 'AggregateMemberProfileSocialLinksField';
  count: Scalars['Int'];
};

export type AggregateMemberProfileSocialTagsField = {
  __typename?: 'AggregateMemberProfileSocialTagsField';
  count: Scalars['Int'];
};

export type AggregateMemberProfileStringField = {
  __typename?: 'AggregateMemberProfileStringField';
  count: Scalars['Int'];
};

export type AggregateMembershipContract = {
  __typename?: 'AggregateMembershipContract';
  count: Scalars['Int'];
};

export type AggregateMobileAppPage = {
  __typename?: 'AggregateMobileAppPage';
  count: Scalars['Int'];
};

export type AggregatePaymentProvider = {
  __typename?: 'AggregatePaymentProvider';
  count: Scalars['Int'];
};

export type AggregatePaymentReceiptEmail = {
  __typename?: 'AggregatePaymentReceiptEmail';
  count: Scalars['Int'];
};

export type AggregatePaymentRequest = {
  __typename?: 'AggregatePaymentRequest';
  count: Scalars['Int'];
};

export type AggregatePaymentRequestEmail = {
  __typename?: 'AggregatePaymentRequestEmail';
  count: Scalars['Int'];
};

export type AggregatePaymentSetting = {
  __typename?: 'AggregatePaymentSetting';
  count: Scalars['Int'];
};

export type AggregatePaymentTransaction = {
  __typename?: 'AggregatePaymentTransaction';
  count: Scalars['Int'];
};

export type AggregatePayoutPlan = {
  __typename?: 'AggregatePayoutPlan';
  count: Scalars['Int'];
};

export type AggregatePet = {
  __typename?: 'AggregatePet';
  count: Scalars['Int'];
};

export type AggregatePlace = {
  __typename?: 'AggregatePlace';
  count: Scalars['Int'];
};

export type AggregatePortfolio = {
  __typename?: 'AggregatePortfolio';
  count: Scalars['Int'];
};

export type AggregateProduct = {
  __typename?: 'AggregateProduct';
  count: Scalars['Int'];
};

export type AggregateProductCategory = {
  __typename?: 'AggregateProductCategory';
  count: Scalars['Int'];
};

export type AggregateProductType = {
  __typename?: 'AggregateProductType';
  count: Scalars['Int'];
};

export type AggregatePropertyManagementOffice = {
  __typename?: 'AggregatePropertyManagementOffice';
  count: Scalars['Int'];
};

export type AggregateRole = {
  __typename?: 'AggregateRole';
  count: Scalars['Int'];
};

export type AggregateService = {
  __typename?: 'AggregateService';
  count: Scalars['Int'];
};

export type AggregateServiceCategory = {
  __typename?: 'AggregateServiceCategory';
  count: Scalars['Int'];
};

export type AggregateServiceProduct = {
  __typename?: 'AggregateServiceProduct';
  count: Scalars['Int'];
};

export type AggregateServiceProductOrder = {
  __typename?: 'AggregateServiceProductOrder';
  count: Scalars['Int'];
};

export type AggregateSharedSpace = {
  __typename?: 'AggregateSharedSpace';
  count: Scalars['Int'];
};

export type AggregateSocialInteraction = {
  __typename?: 'AggregateSocialInteraction';
  count: Scalars['Int'];
};

export type AggregateSocialMoment = {
  __typename?: 'AggregateSocialMoment';
  count: Scalars['Int'];
};

export type AggregateSocialMomentSentiment = {
  __typename?: 'AggregateSocialMomentSentiment';
  count: Scalars['Int'];
};

export type AggregateSocialMomentView = {
  __typename?: 'AggregateSocialMomentView';
  count: Scalars['Int'];
};

export type AggregateSocialReaction = {
  __typename?: 'AggregateSocialReaction';
  count: Scalars['Int'];
};

export type AggregateSocialTag = {
  __typename?: 'AggregateSocialTag';
  count: Scalars['Int'];
};

export type AggregateSubProduct = {
  __typename?: 'AggregateSubProduct';
  count: Scalars['Int'];
};

export type AggregateSubscriptionContract = {
  __typename?: 'AggregateSubscriptionContract';
  count: Scalars['Int'];
};

export type AggregateSurvey = {
  __typename?: 'AggregateSurvey';
  count: Scalars['Int'];
};

export type AggregateSurveyQuestion = {
  __typename?: 'AggregateSurveyQuestion';
  count: Scalars['Int'];
};

export type AggregateSurveyQuestionResponse = {
  __typename?: 'AggregateSurveyQuestionResponse';
  count: Scalars['Int'];
};

export type AggregateSurveyResult = {
  __typename?: 'AggregateSurveyResult';
  count: Scalars['Int'];
};

export type AggregateSurveyUserAction = {
  __typename?: 'AggregateSurveyUserAction';
  count: Scalars['Int'];
};

export type AggregateTaxRate = {
  __typename?: 'AggregateTaxRate';
  count: Scalars['Int'];
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  count: Scalars['Int'];
};

export type AggregateTreasureItem = {
  __typename?: 'AggregateTreasureItem';
  count: Scalars['Int'];
};

export type AggregateUnit = {
  __typename?: 'AggregateUnit';
  count: Scalars['Int'];
};

export type AggregateUnitStatusLog = {
  __typename?: 'AggregateUnitStatusLog';
  count: Scalars['Int'];
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  count: Scalars['Int'];
};

export type AggregateUserApiKey = {
  __typename?: 'AggregateUserApiKey';
  count: Scalars['Int'];
};

export type AggregateUserPaymentMethod = {
  __typename?: 'AggregateUserPaymentMethod';
  count: Scalars['Int'];
};

export enum AiProvider {
  AWS = 'AWS',
  LAB21 = 'LAB21',
  OPEN_AI = 'OPEN_AI'
}

export type AlbertDog = {
  __typename?: 'AlbertDog';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AlbertDogConnection = {
  __typename?: 'AlbertDogConnection';
  aggregate: AggregateAlbertDog;
  edges: Array<Maybe<AlbertDogEdge>>;
  pageInfo: PageInfo;
};

export type AlbertDogCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AlbertDogEdge = {
  __typename?: 'AlbertDogEdge';
  cursor: Scalars['String'];
  node: AlbertDog;
};

export enum AlbertDogOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type AlbertDogUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AlbertDogWhereInput = {
  AND?: InputMaybe<Array<AlbertDogWhereInput>>;
  NOT?: InputMaybe<Array<AlbertDogWhereInput>>;
  OR?: InputMaybe<Array<AlbertDogWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type AlbertDogWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Application = {
  __typename?: 'Application';
  assignedEmployeeUser?: Maybe<User>;
  assignedEmployeeUserId?: Maybe<Scalars['String']>;
  backgroundCheckData?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guarantorData?: Maybe<Scalars['Json']>;
  holdDeposit?: Maybe<Scalars['Float']>;
  holdDepositPaymentRequest?: Maybe<PaymentRequest>;
  holdDepositPaymentRequestId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBackgroundCheckInProgress: Scalars['Boolean'];
  leaseContract?: Maybe<LeaseContract>;
  membershipContract?: Maybe<MembershipContract>;
  metadata?: Maybe<Scalars['Json']>;
  notes?: Maybe<Scalars['String']>;
  numberOfMonthsToCharge?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusFlags?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type ApplicationConnection = {
  __typename?: 'ApplicationConnection';
  aggregate: AggregateApplication;
  edges: Array<Maybe<ApplicationEdge>>;
  pageInfo: PageInfo;
};

export type ApplicationCreateInput = {
  assignedEmployeeUserId?: InputMaybe<Scalars['String']>;
  backgroundCheckData?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  guarantorData?: InputMaybe<Scalars['Json']>;
  holdDeposit?: InputMaybe<Scalars['Float']>;
  holdDepositPaymentRequestId?: InputMaybe<Scalars['String']>;
  isBackgroundCheckInProgress?: InputMaybe<Scalars['Boolean']>;
  leaseContract?: InputMaybe<LeaseContractCreateOneInput>;
  membershipContract?: InputMaybe<MembershipContractCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  notes?: InputMaybe<Scalars['String']>;
  numberOfMonthsToCharge?: InputMaybe<Scalars['Int']>;
  statusFlags?: InputMaybe<Scalars['Json']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ApplicationEdge = {
  __typename?: 'ApplicationEdge';
  cursor: Scalars['String'];
  node: Application;
};

export type ApplicationInput = {
  applicationId: Scalars['String'];
};

export enum ApplicationOrderByInput {
  ASSIGNEDEMPLOYEEUSERID_ASC = 'assignedEmployeeUserId_ASC',
  ASSIGNEDEMPLOYEEUSERID_DESC = 'assignedEmployeeUserId_DESC',
  BACKGROUNDCHECKDATA_ASC = 'backgroundCheckData_ASC',
  BACKGROUNDCHECKDATA_DESC = 'backgroundCheckData_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  GUARANTORDATA_ASC = 'guarantorData_ASC',
  GUARANTORDATA_DESC = 'guarantorData_DESC',
  HOLDDEPOSITPAYMENTREQUESTID_ASC = 'holdDepositPaymentRequestId_ASC',
  HOLDDEPOSITPAYMENTREQUESTID_DESC = 'holdDepositPaymentRequestId_DESC',
  HOLDDEPOSIT_ASC = 'holdDeposit_ASC',
  HOLDDEPOSIT_DESC = 'holdDeposit_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISBACKGROUNDCHECKINPROGRESS_ASC = 'isBackgroundCheckInProgress_ASC',
  ISBACKGROUNDCHECKINPROGRESS_DESC = 'isBackgroundCheckInProgress_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  NOTES_ASC = 'notes_ASC',
  NOTES_DESC = 'notes_DESC',
  NUMBEROFMONTHSTOCHARGE_ASC = 'numberOfMonthsToCharge_ASC',
  NUMBEROFMONTHSTOCHARGE_DESC = 'numberOfMonthsToCharge_DESC',
  STATUSFLAGS_ASC = 'statusFlags_ASC',
  STATUSFLAGS_DESC = 'statusFlags_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type ApplicationResult = {
  __typename?: 'ApplicationResult';
  dummy?: Maybe<Scalars['String']>;
};

export type ApplicationUpdateInput = {
  assignedEmployeeUserId?: InputMaybe<Scalars['String']>;
  backgroundCheckData?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  guarantorData?: InputMaybe<Scalars['Json']>;
  holdDeposit?: InputMaybe<Scalars['Float']>;
  holdDepositPaymentRequestId?: InputMaybe<Scalars['String']>;
  isBackgroundCheckInProgress?: InputMaybe<Scalars['Boolean']>;
  leaseContract?: InputMaybe<LeaseContractUpdateOneInput>;
  membershipContract?: InputMaybe<MembershipContractUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  notes?: InputMaybe<Scalars['String']>;
  numberOfMonthsToCharge?: InputMaybe<Scalars['Int']>;
  statusFlags?: InputMaybe<Scalars['Json']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ApplicationWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<ApplicationWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<ApplicationWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<ApplicationWhereContractsPersistencyInput>>;
  assignedEmployee?: InputMaybe<UserWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  holdDeposit?: InputMaybe<Scalars['Float']>;
  holdDepositPaymentRequest?: InputMaybe<PaymentRequestWhereContractsPersistencyInput>;
  holdDeposit_gt?: InputMaybe<Scalars['Float']>;
  holdDeposit_gte?: InputMaybe<Scalars['Float']>;
  holdDeposit_in?: InputMaybe<Array<Scalars['Float']>>;
  holdDeposit_lt?: InputMaybe<Scalars['Float']>;
  holdDeposit_lte?: InputMaybe<Scalars['Float']>;
  holdDeposit_not?: InputMaybe<Scalars['Float']>;
  holdDeposit_not_in?: InputMaybe<Array<Scalars['Float']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isBackgroundCheckInProgress?: InputMaybe<Scalars['Boolean']>;
  isBackgroundCheckInProgress_not?: InputMaybe<Scalars['Boolean']>;
  leaseContract?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  membershipContract?: InputMaybe<MembershipContractWhereContractsPersistencyInput>;
  notes?: InputMaybe<Scalars['String']>;
  notes_contains?: InputMaybe<Scalars['String']>;
  notes_ends_with?: InputMaybe<Scalars['String']>;
  notes_gt?: InputMaybe<Scalars['String']>;
  notes_gte?: InputMaybe<Scalars['String']>;
  notes_in?: InputMaybe<Array<Scalars['String']>>;
  notes_lt?: InputMaybe<Scalars['String']>;
  notes_lte?: InputMaybe<Scalars['String']>;
  notes_not?: InputMaybe<Scalars['String']>;
  notes_not_contains?: InputMaybe<Scalars['String']>;
  notes_not_ends_with?: InputMaybe<Scalars['String']>;
  notes_not_in?: InputMaybe<Array<Scalars['String']>>;
  notes_not_starts_with?: InputMaybe<Scalars['String']>;
  notes_starts_with?: InputMaybe<Scalars['String']>;
  numberOfMonthsToCharge?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_gt?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_gte?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_in?: InputMaybe<Array<Scalars['Int']>>;
  numberOfMonthsToCharge_lt?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_lte?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_not?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_not_in?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type ApplicationWhereInput = {
  AND?: InputMaybe<Array<ApplicationWhereInput>>;
  NOT?: InputMaybe<Array<ApplicationWhereInput>>;
  OR?: InputMaybe<Array<ApplicationWhereInput>>;
  assignedEmployee?: InputMaybe<UserWhereContractsPersistencyInput>;
  assignedEmployeeUserId?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_contains?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_ends_with?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_gt?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_gte?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_in?: InputMaybe<Array<Scalars['String']>>;
  assignedEmployeeUserId_lt?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_lte?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_not?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_not_contains?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_not_ends_with?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_not_in?: InputMaybe<Array<Scalars['String']>>;
  assignedEmployeeUserId_not_starts_with?: InputMaybe<Scalars['String']>;
  assignedEmployeeUserId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  holdDeposit?: InputMaybe<Scalars['Float']>;
  holdDepositPaymentRequestId?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_contains?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_ends_with?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_gt?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_gte?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_in?: InputMaybe<Array<Scalars['String']>>;
  holdDepositPaymentRequestId_lt?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_lte?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_not?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_not_contains?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_not_ends_with?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_not_in?: InputMaybe<Array<Scalars['String']>>;
  holdDepositPaymentRequestId_not_starts_with?: InputMaybe<Scalars['String']>;
  holdDepositPaymentRequestId_starts_with?: InputMaybe<Scalars['String']>;
  holdDeposit_gt?: InputMaybe<Scalars['Float']>;
  holdDeposit_gte?: InputMaybe<Scalars['Float']>;
  holdDeposit_in?: InputMaybe<Array<Scalars['Float']>>;
  holdDeposit_lt?: InputMaybe<Scalars['Float']>;
  holdDeposit_lte?: InputMaybe<Scalars['Float']>;
  holdDeposit_not?: InputMaybe<Scalars['Float']>;
  holdDeposit_not_in?: InputMaybe<Array<Scalars['Float']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isBackgroundCheckInProgress?: InputMaybe<Scalars['Boolean']>;
  isBackgroundCheckInProgress_not?: InputMaybe<Scalars['Boolean']>;
  leaseContract?: InputMaybe<LeaseContractWhereInput>;
  membershipContract?: InputMaybe<MembershipContractWhereInput>;
  notes?: InputMaybe<Scalars['String']>;
  notes_contains?: InputMaybe<Scalars['String']>;
  notes_ends_with?: InputMaybe<Scalars['String']>;
  notes_gt?: InputMaybe<Scalars['String']>;
  notes_gte?: InputMaybe<Scalars['String']>;
  notes_in?: InputMaybe<Array<Scalars['String']>>;
  notes_lt?: InputMaybe<Scalars['String']>;
  notes_lte?: InputMaybe<Scalars['String']>;
  notes_not?: InputMaybe<Scalars['String']>;
  notes_not_contains?: InputMaybe<Scalars['String']>;
  notes_not_ends_with?: InputMaybe<Scalars['String']>;
  notes_not_in?: InputMaybe<Array<Scalars['String']>>;
  notes_not_starts_with?: InputMaybe<Scalars['String']>;
  notes_starts_with?: InputMaybe<Scalars['String']>;
  numberOfMonthsToCharge?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_gt?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_gte?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_in?: InputMaybe<Array<Scalars['Int']>>;
  numberOfMonthsToCharge_lt?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_lte?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_not?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_not_in?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type ApplicationWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<ApplicationWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<ApplicationWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<ApplicationWherePersistencyContractsInput>>;
  assignedEmployee?: InputMaybe<UserWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  holdDeposit?: InputMaybe<Scalars['Float']>;
  holdDepositPaymentRequest?: InputMaybe<PaymentRequestWherePersistencyContractsInput>;
  holdDeposit_gt?: InputMaybe<Scalars['Float']>;
  holdDeposit_gte?: InputMaybe<Scalars['Float']>;
  holdDeposit_in?: InputMaybe<Array<Scalars['Float']>>;
  holdDeposit_lt?: InputMaybe<Scalars['Float']>;
  holdDeposit_lte?: InputMaybe<Scalars['Float']>;
  holdDeposit_not?: InputMaybe<Scalars['Float']>;
  holdDeposit_not_in?: InputMaybe<Array<Scalars['Float']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isBackgroundCheckInProgress?: InputMaybe<Scalars['Boolean']>;
  isBackgroundCheckInProgress_not?: InputMaybe<Scalars['Boolean']>;
  leaseContract?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  membershipContract?: InputMaybe<MembershipContractWherePersistencyContractsInput>;
  notes?: InputMaybe<Scalars['String']>;
  notes_contains?: InputMaybe<Scalars['String']>;
  notes_ends_with?: InputMaybe<Scalars['String']>;
  notes_gt?: InputMaybe<Scalars['String']>;
  notes_gte?: InputMaybe<Scalars['String']>;
  notes_in?: InputMaybe<Array<Scalars['String']>>;
  notes_lt?: InputMaybe<Scalars['String']>;
  notes_lte?: InputMaybe<Scalars['String']>;
  notes_not?: InputMaybe<Scalars['String']>;
  notes_not_contains?: InputMaybe<Scalars['String']>;
  notes_not_ends_with?: InputMaybe<Scalars['String']>;
  notes_not_in?: InputMaybe<Array<Scalars['String']>>;
  notes_not_starts_with?: InputMaybe<Scalars['String']>;
  notes_starts_with?: InputMaybe<Scalars['String']>;
  numberOfMonthsToCharge?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_gt?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_gte?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_in?: InputMaybe<Array<Scalars['Int']>>;
  numberOfMonthsToCharge_lt?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_lte?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_not?: InputMaybe<Scalars['Int']>;
  numberOfMonthsToCharge_not_in?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type ApplicationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AttendanceEventInput = {
  eventId: Scalars['ID'];
};

export type AttendancePricedEventInput = {
  eventId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};

export enum AttendanceStatus {
  CANCELLED = 'CANCELLED',
  CHECKED_IN = 'CHECKED_IN',
  GOING = 'GOING'
}

export type Audit = {
  __typename?: 'Audit';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  entityAfter?: Maybe<Scalars['Json']>;
  entityBefore?: Maybe<Scalars['Json']>;
  entityId: Scalars['String'];
  entityName: Scalars['String'];
  functionName?: Maybe<Scalars['String']>;
  graphqlQuery?: Maybe<Scalars['String']>;
  graphqlVariables?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  impersonated?: Maybe<Scalars['Boolean']>;
  operation: Scalars['String'];
  originalFunctionName?: Maybe<Scalars['String']>;
  originalServiceName?: Maybe<Scalars['String']>;
  recordDiff?: Maybe<Scalars['Json']>;
  serviceName?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  userInfo?: Maybe<Scalars['Json']>;
  vennRequestId?: Maybe<Scalars['String']>;
  xrayTraceId?: Maybe<Scalars['String']>;
};

export type AuditConnection = {
  __typename?: 'AuditConnection';
  aggregate: AggregateAudit;
  edges: Array<Maybe<AuditEdge>>;
  pageInfo: PageInfo;
};

export type AuditCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entityAfter?: InputMaybe<Scalars['Json']>;
  entityBefore?: InputMaybe<Scalars['Json']>;
  entityId: Scalars['String'];
  entityName: Scalars['String'];
  functionName?: InputMaybe<Scalars['String']>;
  graphqlQuery?: InputMaybe<Scalars['String']>;
  graphqlVariables?: InputMaybe<Scalars['Json']>;
  impersonated?: InputMaybe<Scalars['Boolean']>;
  operation: Scalars['String'];
  originalFunctionName?: InputMaybe<Scalars['String']>;
  originalServiceName?: InputMaybe<Scalars['String']>;
  recordDiff?: InputMaybe<Scalars['Json']>;
  serviceName?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
  userInfo?: InputMaybe<Scalars['Json']>;
  vennRequestId?: InputMaybe<Scalars['String']>;
  xrayTraceId?: InputMaybe<Scalars['String']>;
};

export type AuditEdge = {
  __typename?: 'AuditEdge';
  cursor: Scalars['String'];
  node: Audit;
};

export enum AuditOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENTITYAFTER_ASC = 'entityAfter_ASC',
  ENTITYAFTER_DESC = 'entityAfter_DESC',
  ENTITYBEFORE_ASC = 'entityBefore_ASC',
  ENTITYBEFORE_DESC = 'entityBefore_DESC',
  ENTITYID_ASC = 'entityId_ASC',
  ENTITYID_DESC = 'entityId_DESC',
  ENTITYNAME_ASC = 'entityName_ASC',
  ENTITYNAME_DESC = 'entityName_DESC',
  FUNCTIONNAME_ASC = 'functionName_ASC',
  FUNCTIONNAME_DESC = 'functionName_DESC',
  GRAPHQLQUERY_ASC = 'graphqlQuery_ASC',
  GRAPHQLQUERY_DESC = 'graphqlQuery_DESC',
  GRAPHQLVARIABLES_ASC = 'graphqlVariables_ASC',
  GRAPHQLVARIABLES_DESC = 'graphqlVariables_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMPERSONATED_ASC = 'impersonated_ASC',
  IMPERSONATED_DESC = 'impersonated_DESC',
  OPERATION_ASC = 'operation_ASC',
  OPERATION_DESC = 'operation_DESC',
  ORIGINALFUNCTIONNAME_ASC = 'originalFunctionName_ASC',
  ORIGINALFUNCTIONNAME_DESC = 'originalFunctionName_DESC',
  ORIGINALSERVICENAME_ASC = 'originalServiceName_ASC',
  ORIGINALSERVICENAME_DESC = 'originalServiceName_DESC',
  RECORDDIFF_ASC = 'recordDiff_ASC',
  RECORDDIFF_DESC = 'recordDiff_DESC',
  SERVICENAME_ASC = 'serviceName_ASC',
  SERVICENAME_DESC = 'serviceName_DESC',
  TIMESTAMP_ASC = 'timestamp_ASC',
  TIMESTAMP_DESC = 'timestamp_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC',
  USERINFO_ASC = 'userInfo_ASC',
  USERINFO_DESC = 'userInfo_DESC',
  VENNREQUESTID_ASC = 'vennRequestId_ASC',
  VENNREQUESTID_DESC = 'vennRequestId_DESC',
  XRAYTRACEID_ASC = 'xrayTraceId_ASC',
  XRAYTRACEID_DESC = 'xrayTraceId_DESC'
}

export type AuditWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<AuditWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<AuditWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<AuditWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entityId?: InputMaybe<Scalars['String']>;
  entityId_contains?: InputMaybe<Scalars['String']>;
  entityId_ends_with?: InputMaybe<Scalars['String']>;
  entityId_gt?: InputMaybe<Scalars['String']>;
  entityId_gte?: InputMaybe<Scalars['String']>;
  entityId_in?: InputMaybe<Array<Scalars['String']>>;
  entityId_lt?: InputMaybe<Scalars['String']>;
  entityId_lte?: InputMaybe<Scalars['String']>;
  entityId_not?: InputMaybe<Scalars['String']>;
  entityId_not_contains?: InputMaybe<Scalars['String']>;
  entityId_not_ends_with?: InputMaybe<Scalars['String']>;
  entityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  entityId_not_starts_with?: InputMaybe<Scalars['String']>;
  entityId_starts_with?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  entityName_contains?: InputMaybe<Scalars['String']>;
  entityName_ends_with?: InputMaybe<Scalars['String']>;
  entityName_gt?: InputMaybe<Scalars['String']>;
  entityName_gte?: InputMaybe<Scalars['String']>;
  entityName_in?: InputMaybe<Array<Scalars['String']>>;
  entityName_lt?: InputMaybe<Scalars['String']>;
  entityName_lte?: InputMaybe<Scalars['String']>;
  entityName_not?: InputMaybe<Scalars['String']>;
  entityName_not_contains?: InputMaybe<Scalars['String']>;
  entityName_not_ends_with?: InputMaybe<Scalars['String']>;
  entityName_not_in?: InputMaybe<Array<Scalars['String']>>;
  entityName_not_starts_with?: InputMaybe<Scalars['String']>;
  entityName_starts_with?: InputMaybe<Scalars['String']>;
  functionName?: InputMaybe<Scalars['String']>;
  functionName_contains?: InputMaybe<Scalars['String']>;
  functionName_ends_with?: InputMaybe<Scalars['String']>;
  functionName_gt?: InputMaybe<Scalars['String']>;
  functionName_gte?: InputMaybe<Scalars['String']>;
  functionName_in?: InputMaybe<Array<Scalars['String']>>;
  functionName_lt?: InputMaybe<Scalars['String']>;
  functionName_lte?: InputMaybe<Scalars['String']>;
  functionName_not?: InputMaybe<Scalars['String']>;
  functionName_not_contains?: InputMaybe<Scalars['String']>;
  functionName_not_ends_with?: InputMaybe<Scalars['String']>;
  functionName_not_in?: InputMaybe<Array<Scalars['String']>>;
  functionName_not_starts_with?: InputMaybe<Scalars['String']>;
  functionName_starts_with?: InputMaybe<Scalars['String']>;
  graphqlQuery?: InputMaybe<Scalars['String']>;
  graphqlQuery_contains?: InputMaybe<Scalars['String']>;
  graphqlQuery_ends_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_gt?: InputMaybe<Scalars['String']>;
  graphqlQuery_gte?: InputMaybe<Scalars['String']>;
  graphqlQuery_in?: InputMaybe<Array<Scalars['String']>>;
  graphqlQuery_lt?: InputMaybe<Scalars['String']>;
  graphqlQuery_lte?: InputMaybe<Scalars['String']>;
  graphqlQuery_not?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_contains?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_ends_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_in?: InputMaybe<Array<Scalars['String']>>;
  graphqlQuery_not_starts_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  impersonated?: InputMaybe<Scalars['Boolean']>;
  impersonated_not?: InputMaybe<Scalars['Boolean']>;
  operation?: InputMaybe<Scalars['String']>;
  operation_contains?: InputMaybe<Scalars['String']>;
  operation_ends_with?: InputMaybe<Scalars['String']>;
  operation_gt?: InputMaybe<Scalars['String']>;
  operation_gte?: InputMaybe<Scalars['String']>;
  operation_in?: InputMaybe<Array<Scalars['String']>>;
  operation_lt?: InputMaybe<Scalars['String']>;
  operation_lte?: InputMaybe<Scalars['String']>;
  operation_not?: InputMaybe<Scalars['String']>;
  operation_not_contains?: InputMaybe<Scalars['String']>;
  operation_not_ends_with?: InputMaybe<Scalars['String']>;
  operation_not_in?: InputMaybe<Array<Scalars['String']>>;
  operation_not_starts_with?: InputMaybe<Scalars['String']>;
  operation_starts_with?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
  serviceName_contains?: InputMaybe<Scalars['String']>;
  serviceName_ends_with?: InputMaybe<Scalars['String']>;
  serviceName_gt?: InputMaybe<Scalars['String']>;
  serviceName_gte?: InputMaybe<Scalars['String']>;
  serviceName_in?: InputMaybe<Array<Scalars['String']>>;
  serviceName_lt?: InputMaybe<Scalars['String']>;
  serviceName_lte?: InputMaybe<Scalars['String']>;
  serviceName_not?: InputMaybe<Scalars['String']>;
  serviceName_not_contains?: InputMaybe<Scalars['String']>;
  serviceName_not_ends_with?: InputMaybe<Scalars['String']>;
  serviceName_not_in?: InputMaybe<Array<Scalars['String']>>;
  serviceName_not_starts_with?: InputMaybe<Scalars['String']>;
  serviceName_starts_with?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
  xrayTraceId?: InputMaybe<Scalars['String']>;
  xrayTraceId_contains?: InputMaybe<Scalars['String']>;
  xrayTraceId_ends_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_gt?: InputMaybe<Scalars['String']>;
  xrayTraceId_gte?: InputMaybe<Scalars['String']>;
  xrayTraceId_in?: InputMaybe<Array<Scalars['String']>>;
  xrayTraceId_lt?: InputMaybe<Scalars['String']>;
  xrayTraceId_lte?: InputMaybe<Scalars['String']>;
  xrayTraceId_not?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_contains?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_ends_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_in?: InputMaybe<Array<Scalars['String']>>;
  xrayTraceId_not_starts_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_starts_with?: InputMaybe<Scalars['String']>;
};

export type AuditWhereInput = {
  AND?: InputMaybe<Array<AuditWhereInput>>;
  NOT?: InputMaybe<Array<AuditWhereInput>>;
  OR?: InputMaybe<Array<AuditWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entityId?: InputMaybe<Scalars['String']>;
  entityId_contains?: InputMaybe<Scalars['String']>;
  entityId_ends_with?: InputMaybe<Scalars['String']>;
  entityId_gt?: InputMaybe<Scalars['String']>;
  entityId_gte?: InputMaybe<Scalars['String']>;
  entityId_in?: InputMaybe<Array<Scalars['String']>>;
  entityId_lt?: InputMaybe<Scalars['String']>;
  entityId_lte?: InputMaybe<Scalars['String']>;
  entityId_not?: InputMaybe<Scalars['String']>;
  entityId_not_contains?: InputMaybe<Scalars['String']>;
  entityId_not_ends_with?: InputMaybe<Scalars['String']>;
  entityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  entityId_not_starts_with?: InputMaybe<Scalars['String']>;
  entityId_starts_with?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  entityName_contains?: InputMaybe<Scalars['String']>;
  entityName_ends_with?: InputMaybe<Scalars['String']>;
  entityName_gt?: InputMaybe<Scalars['String']>;
  entityName_gte?: InputMaybe<Scalars['String']>;
  entityName_in?: InputMaybe<Array<Scalars['String']>>;
  entityName_lt?: InputMaybe<Scalars['String']>;
  entityName_lte?: InputMaybe<Scalars['String']>;
  entityName_not?: InputMaybe<Scalars['String']>;
  entityName_not_contains?: InputMaybe<Scalars['String']>;
  entityName_not_ends_with?: InputMaybe<Scalars['String']>;
  entityName_not_in?: InputMaybe<Array<Scalars['String']>>;
  entityName_not_starts_with?: InputMaybe<Scalars['String']>;
  entityName_starts_with?: InputMaybe<Scalars['String']>;
  functionName?: InputMaybe<Scalars['String']>;
  functionName_contains?: InputMaybe<Scalars['String']>;
  functionName_ends_with?: InputMaybe<Scalars['String']>;
  functionName_gt?: InputMaybe<Scalars['String']>;
  functionName_gte?: InputMaybe<Scalars['String']>;
  functionName_in?: InputMaybe<Array<Scalars['String']>>;
  functionName_lt?: InputMaybe<Scalars['String']>;
  functionName_lte?: InputMaybe<Scalars['String']>;
  functionName_not?: InputMaybe<Scalars['String']>;
  functionName_not_contains?: InputMaybe<Scalars['String']>;
  functionName_not_ends_with?: InputMaybe<Scalars['String']>;
  functionName_not_in?: InputMaybe<Array<Scalars['String']>>;
  functionName_not_starts_with?: InputMaybe<Scalars['String']>;
  functionName_starts_with?: InputMaybe<Scalars['String']>;
  graphqlQuery?: InputMaybe<Scalars['String']>;
  graphqlQuery_contains?: InputMaybe<Scalars['String']>;
  graphqlQuery_ends_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_gt?: InputMaybe<Scalars['String']>;
  graphqlQuery_gte?: InputMaybe<Scalars['String']>;
  graphqlQuery_in?: InputMaybe<Array<Scalars['String']>>;
  graphqlQuery_lt?: InputMaybe<Scalars['String']>;
  graphqlQuery_lte?: InputMaybe<Scalars['String']>;
  graphqlQuery_not?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_contains?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_ends_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_in?: InputMaybe<Array<Scalars['String']>>;
  graphqlQuery_not_starts_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  impersonated?: InputMaybe<Scalars['Boolean']>;
  impersonated_not?: InputMaybe<Scalars['Boolean']>;
  operation?: InputMaybe<Scalars['String']>;
  operation_contains?: InputMaybe<Scalars['String']>;
  operation_ends_with?: InputMaybe<Scalars['String']>;
  operation_gt?: InputMaybe<Scalars['String']>;
  operation_gte?: InputMaybe<Scalars['String']>;
  operation_in?: InputMaybe<Array<Scalars['String']>>;
  operation_lt?: InputMaybe<Scalars['String']>;
  operation_lte?: InputMaybe<Scalars['String']>;
  operation_not?: InputMaybe<Scalars['String']>;
  operation_not_contains?: InputMaybe<Scalars['String']>;
  operation_not_ends_with?: InputMaybe<Scalars['String']>;
  operation_not_in?: InputMaybe<Array<Scalars['String']>>;
  operation_not_starts_with?: InputMaybe<Scalars['String']>;
  operation_starts_with?: InputMaybe<Scalars['String']>;
  originalFunctionName?: InputMaybe<Scalars['String']>;
  originalFunctionName_contains?: InputMaybe<Scalars['String']>;
  originalFunctionName_ends_with?: InputMaybe<Scalars['String']>;
  originalFunctionName_gt?: InputMaybe<Scalars['String']>;
  originalFunctionName_gte?: InputMaybe<Scalars['String']>;
  originalFunctionName_in?: InputMaybe<Array<Scalars['String']>>;
  originalFunctionName_lt?: InputMaybe<Scalars['String']>;
  originalFunctionName_lte?: InputMaybe<Scalars['String']>;
  originalFunctionName_not?: InputMaybe<Scalars['String']>;
  originalFunctionName_not_contains?: InputMaybe<Scalars['String']>;
  originalFunctionName_not_ends_with?: InputMaybe<Scalars['String']>;
  originalFunctionName_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalFunctionName_not_starts_with?: InputMaybe<Scalars['String']>;
  originalFunctionName_starts_with?: InputMaybe<Scalars['String']>;
  originalServiceName?: InputMaybe<Scalars['String']>;
  originalServiceName_contains?: InputMaybe<Scalars['String']>;
  originalServiceName_ends_with?: InputMaybe<Scalars['String']>;
  originalServiceName_gt?: InputMaybe<Scalars['String']>;
  originalServiceName_gte?: InputMaybe<Scalars['String']>;
  originalServiceName_in?: InputMaybe<Array<Scalars['String']>>;
  originalServiceName_lt?: InputMaybe<Scalars['String']>;
  originalServiceName_lte?: InputMaybe<Scalars['String']>;
  originalServiceName_not?: InputMaybe<Scalars['String']>;
  originalServiceName_not_contains?: InputMaybe<Scalars['String']>;
  originalServiceName_not_ends_with?: InputMaybe<Scalars['String']>;
  originalServiceName_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalServiceName_not_starts_with?: InputMaybe<Scalars['String']>;
  originalServiceName_starts_with?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
  serviceName_contains?: InputMaybe<Scalars['String']>;
  serviceName_ends_with?: InputMaybe<Scalars['String']>;
  serviceName_gt?: InputMaybe<Scalars['String']>;
  serviceName_gte?: InputMaybe<Scalars['String']>;
  serviceName_in?: InputMaybe<Array<Scalars['String']>>;
  serviceName_lt?: InputMaybe<Scalars['String']>;
  serviceName_lte?: InputMaybe<Scalars['String']>;
  serviceName_not?: InputMaybe<Scalars['String']>;
  serviceName_not_contains?: InputMaybe<Scalars['String']>;
  serviceName_not_ends_with?: InputMaybe<Scalars['String']>;
  serviceName_not_in?: InputMaybe<Array<Scalars['String']>>;
  serviceName_not_starts_with?: InputMaybe<Scalars['String']>;
  serviceName_starts_with?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
  vennRequestId?: InputMaybe<Scalars['String']>;
  vennRequestId_contains?: InputMaybe<Scalars['String']>;
  vennRequestId_ends_with?: InputMaybe<Scalars['String']>;
  vennRequestId_gt?: InputMaybe<Scalars['String']>;
  vennRequestId_gte?: InputMaybe<Scalars['String']>;
  vennRequestId_in?: InputMaybe<Array<Scalars['String']>>;
  vennRequestId_lt?: InputMaybe<Scalars['String']>;
  vennRequestId_lte?: InputMaybe<Scalars['String']>;
  vennRequestId_not?: InputMaybe<Scalars['String']>;
  vennRequestId_not_contains?: InputMaybe<Scalars['String']>;
  vennRequestId_not_ends_with?: InputMaybe<Scalars['String']>;
  vennRequestId_not_in?: InputMaybe<Array<Scalars['String']>>;
  vennRequestId_not_starts_with?: InputMaybe<Scalars['String']>;
  vennRequestId_starts_with?: InputMaybe<Scalars['String']>;
  xrayTraceId?: InputMaybe<Scalars['String']>;
  xrayTraceId_contains?: InputMaybe<Scalars['String']>;
  xrayTraceId_ends_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_gt?: InputMaybe<Scalars['String']>;
  xrayTraceId_gte?: InputMaybe<Scalars['String']>;
  xrayTraceId_in?: InputMaybe<Array<Scalars['String']>>;
  xrayTraceId_lt?: InputMaybe<Scalars['String']>;
  xrayTraceId_lte?: InputMaybe<Scalars['String']>;
  xrayTraceId_not?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_contains?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_ends_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_in?: InputMaybe<Array<Scalars['String']>>;
  xrayTraceId_not_starts_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_starts_with?: InputMaybe<Scalars['String']>;
};

export type AuditWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<AuditWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<AuditWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<AuditWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entityId?: InputMaybe<Scalars['String']>;
  entityId_contains?: InputMaybe<Scalars['String']>;
  entityId_ends_with?: InputMaybe<Scalars['String']>;
  entityId_gt?: InputMaybe<Scalars['String']>;
  entityId_gte?: InputMaybe<Scalars['String']>;
  entityId_in?: InputMaybe<Array<Scalars['String']>>;
  entityId_lt?: InputMaybe<Scalars['String']>;
  entityId_lte?: InputMaybe<Scalars['String']>;
  entityId_not?: InputMaybe<Scalars['String']>;
  entityId_not_contains?: InputMaybe<Scalars['String']>;
  entityId_not_ends_with?: InputMaybe<Scalars['String']>;
  entityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  entityId_not_starts_with?: InputMaybe<Scalars['String']>;
  entityId_starts_with?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  entityName_contains?: InputMaybe<Scalars['String']>;
  entityName_ends_with?: InputMaybe<Scalars['String']>;
  entityName_gt?: InputMaybe<Scalars['String']>;
  entityName_gte?: InputMaybe<Scalars['String']>;
  entityName_in?: InputMaybe<Array<Scalars['String']>>;
  entityName_lt?: InputMaybe<Scalars['String']>;
  entityName_lte?: InputMaybe<Scalars['String']>;
  entityName_not?: InputMaybe<Scalars['String']>;
  entityName_not_contains?: InputMaybe<Scalars['String']>;
  entityName_not_ends_with?: InputMaybe<Scalars['String']>;
  entityName_not_in?: InputMaybe<Array<Scalars['String']>>;
  entityName_not_starts_with?: InputMaybe<Scalars['String']>;
  entityName_starts_with?: InputMaybe<Scalars['String']>;
  functionName?: InputMaybe<Scalars['String']>;
  functionName_contains?: InputMaybe<Scalars['String']>;
  functionName_ends_with?: InputMaybe<Scalars['String']>;
  functionName_gt?: InputMaybe<Scalars['String']>;
  functionName_gte?: InputMaybe<Scalars['String']>;
  functionName_in?: InputMaybe<Array<Scalars['String']>>;
  functionName_lt?: InputMaybe<Scalars['String']>;
  functionName_lte?: InputMaybe<Scalars['String']>;
  functionName_not?: InputMaybe<Scalars['String']>;
  functionName_not_contains?: InputMaybe<Scalars['String']>;
  functionName_not_ends_with?: InputMaybe<Scalars['String']>;
  functionName_not_in?: InputMaybe<Array<Scalars['String']>>;
  functionName_not_starts_with?: InputMaybe<Scalars['String']>;
  functionName_starts_with?: InputMaybe<Scalars['String']>;
  graphqlQuery?: InputMaybe<Scalars['String']>;
  graphqlQuery_contains?: InputMaybe<Scalars['String']>;
  graphqlQuery_ends_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_gt?: InputMaybe<Scalars['String']>;
  graphqlQuery_gte?: InputMaybe<Scalars['String']>;
  graphqlQuery_in?: InputMaybe<Array<Scalars['String']>>;
  graphqlQuery_lt?: InputMaybe<Scalars['String']>;
  graphqlQuery_lte?: InputMaybe<Scalars['String']>;
  graphqlQuery_not?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_contains?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_ends_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_not_in?: InputMaybe<Array<Scalars['String']>>;
  graphqlQuery_not_starts_with?: InputMaybe<Scalars['String']>;
  graphqlQuery_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  impersonated?: InputMaybe<Scalars['Boolean']>;
  impersonated_not?: InputMaybe<Scalars['Boolean']>;
  operation?: InputMaybe<Scalars['String']>;
  operation_contains?: InputMaybe<Scalars['String']>;
  operation_ends_with?: InputMaybe<Scalars['String']>;
  operation_gt?: InputMaybe<Scalars['String']>;
  operation_gte?: InputMaybe<Scalars['String']>;
  operation_in?: InputMaybe<Array<Scalars['String']>>;
  operation_lt?: InputMaybe<Scalars['String']>;
  operation_lte?: InputMaybe<Scalars['String']>;
  operation_not?: InputMaybe<Scalars['String']>;
  operation_not_contains?: InputMaybe<Scalars['String']>;
  operation_not_ends_with?: InputMaybe<Scalars['String']>;
  operation_not_in?: InputMaybe<Array<Scalars['String']>>;
  operation_not_starts_with?: InputMaybe<Scalars['String']>;
  operation_starts_with?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
  serviceName_contains?: InputMaybe<Scalars['String']>;
  serviceName_ends_with?: InputMaybe<Scalars['String']>;
  serviceName_gt?: InputMaybe<Scalars['String']>;
  serviceName_gte?: InputMaybe<Scalars['String']>;
  serviceName_in?: InputMaybe<Array<Scalars['String']>>;
  serviceName_lt?: InputMaybe<Scalars['String']>;
  serviceName_lte?: InputMaybe<Scalars['String']>;
  serviceName_not?: InputMaybe<Scalars['String']>;
  serviceName_not_contains?: InputMaybe<Scalars['String']>;
  serviceName_not_ends_with?: InputMaybe<Scalars['String']>;
  serviceName_not_in?: InputMaybe<Array<Scalars['String']>>;
  serviceName_not_starts_with?: InputMaybe<Scalars['String']>;
  serviceName_starts_with?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
  xrayTraceId?: InputMaybe<Scalars['String']>;
  xrayTraceId_contains?: InputMaybe<Scalars['String']>;
  xrayTraceId_ends_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_gt?: InputMaybe<Scalars['String']>;
  xrayTraceId_gte?: InputMaybe<Scalars['String']>;
  xrayTraceId_in?: InputMaybe<Array<Scalars['String']>>;
  xrayTraceId_lt?: InputMaybe<Scalars['String']>;
  xrayTraceId_lte?: InputMaybe<Scalars['String']>;
  xrayTraceId_not?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_contains?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_ends_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_not_in?: InputMaybe<Array<Scalars['String']>>;
  xrayTraceId_not_starts_with?: InputMaybe<Scalars['String']>;
  xrayTraceId_starts_with?: InputMaybe<Scalars['String']>;
};

export type AuditWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AutoBillingSettings = {
  __typename?: 'AutoBillingSettings';
  daysOfMonth: Array<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  maximumChargableAmount: Scalars['Int'];
  reportSlackChannelName?: Maybe<Scalars['String']>;
};

export enum BillingNotificationTemplateName {
  FINAL_NOTICE = 'final_notice',
  FIRST_PAYMENT = 'first_payment',
  MONTHLY_BILL = 'monthly_bill',
  MONTHLY_BILL_REPORT = 'monthly_bill_report',
  PAYMENT_SETUP_EMAIL_TO_VIRTUAL_MEMBER = 'payment_setup_email_to_virtual_member',
  RECEIPT = 'receipt',
  SECOND_NOTICE = 'second_notice'
}

export type BackgroundCheckSettings = {
  __typename?: 'BackgroundCheckSettings';
  enabled: Scalars['Boolean'];
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Long'];
};

export type Bedroom = {
  __typename?: 'Bedroom';
  _empty?: Maybe<Scalars['String']>;
  askedRent?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  leaseContracts?: Maybe<Array<Maybe<LeaseContract>>>;
  name: Scalars['String'];
  product?: Maybe<Product>;
  squareMeters?: Maybe<Scalars['Int']>;
  unit: Unit;
  updatedAt: Scalars['DateTime'];
};

export type BedroomConnection = {
  __typename?: 'BedroomConnection';
  aggregate: AggregateBedroom;
  edges: Array<Maybe<BedroomEdge>>;
  pageInfo: PageInfo;
};

export type BedroomCreateInput = {
  askedRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  product?: InputMaybe<ProductCreateOneInput>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  unit: UnitCreateOneWithoutBedroomsInput;
};

export type BedroomCreateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<BedroomWhereUniqueInput>>;
  create?: InputMaybe<Array<BedroomCreateWithoutUnitInput>>;
};

export type BedroomCreateOneInput = {
  connect?: InputMaybe<BedroomWhereUniqueInput>;
  create?: InputMaybe<BedroomCreateInput>;
};

export type BedroomCreateWithoutUnitInput = {
  askedRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  product?: InputMaybe<ProductCreateOneInput>;
  squareMeters?: InputMaybe<Scalars['Int']>;
};

export type BedroomEdge = {
  __typename?: 'BedroomEdge';
  cursor: Scalars['String'];
  node: Bedroom;
};

export enum BedroomOrderByInput {
  ASKEDRENT_ASC = 'askedRent_ASC',
  ASKEDRENT_DESC = 'askedRent_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  SQUAREMETERS_ASC = 'squareMeters_ASC',
  SQUAREMETERS_DESC = 'squareMeters_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type BedroomScalarWhereInput = {
  AND?: InputMaybe<Array<BedroomScalarWhereInput>>;
  NOT?: InputMaybe<Array<BedroomScalarWhereInput>>;
  OR?: InputMaybe<Array<BedroomScalarWhereInput>>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BedroomUpdateDataInput = {
  askedRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductUpdateOneInput>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutBedroomsInput>;
};

export type BedroomUpdateInput = {
  askedRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductUpdateOneInput>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutBedroomsInput>;
};

export type BedroomUpdateManyDataInput = {
  askedRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  squareMeters?: InputMaybe<Scalars['Int']>;
};

export type BedroomUpdateManyWithWhereNestedInput = {
  data: BedroomUpdateManyDataInput;
  where: BedroomScalarWhereInput;
};

export type BedroomUpdateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<BedroomWhereUniqueInput>>;
  create?: InputMaybe<Array<BedroomCreateWithoutUnitInput>>;
  delete?: InputMaybe<Array<BedroomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BedroomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BedroomWhereUniqueInput>>;
  set?: InputMaybe<Array<BedroomWhereUniqueInput>>;
  update?: InputMaybe<Array<BedroomUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<BedroomUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<BedroomUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type BedroomUpdateOneInput = {
  connect?: InputMaybe<BedroomWhereUniqueInput>;
  create?: InputMaybe<BedroomCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BedroomUpdateDataInput>;
  upsert?: InputMaybe<BedroomUpsertNestedInput>;
};

export type BedroomUpdateWithWhereUniqueWithoutUnitInput = {
  data: BedroomUpdateWithoutUnitDataInput;
  where: BedroomWhereUniqueInput;
};

export type BedroomUpdateWithoutUnitDataInput = {
  askedRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductUpdateOneInput>;
  squareMeters?: InputMaybe<Scalars['Int']>;
};

export type BedroomUpsertNestedInput = {
  create: BedroomCreateInput;
  update: BedroomUpdateDataInput;
};

export type BedroomUpsertWithWhereUniqueWithoutUnitInput = {
  create: BedroomCreateWithoutUnitInput;
  update: BedroomUpdateWithoutUnitDataInput;
  where: BedroomWhereUniqueInput;
};

export type BedroomWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<BedroomWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<BedroomWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<BedroomWhereContractsPersistencyInput>>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_every?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  leaseContracts_none?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  locks_every?: InputMaybe<LockWhereContractsPersistencyInput>;
  locks_none?: InputMaybe<LockWhereContractsPersistencyInput>;
  locks_some?: InputMaybe<LockWhereContractsPersistencyInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductWhereContractsPersistencyInput>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  unit?: InputMaybe<UnitWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BedroomWhereInput = {
  AND?: InputMaybe<Array<BedroomWhereInput>>;
  NOT?: InputMaybe<Array<BedroomWhereInput>>;
  OR?: InputMaybe<Array<BedroomWhereInput>>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  coordinate_intersects?: InputMaybe<PolygonInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_none?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductWhereInput>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  unit?: InputMaybe<UnitWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BedroomWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<BedroomWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<BedroomWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<BedroomWherePersistencyContractsInput>>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_every?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_none?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  locks_every?: InputMaybe<LockWherePersistencyContractsInput>;
  locks_none?: InputMaybe<LockWherePersistencyContractsInput>;
  locks_some?: InputMaybe<LockWherePersistencyContractsInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductWherePersistencyContractsInput>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  unit?: InputMaybe<UnitWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BedroomWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Bill = {
  __typename?: 'Bill';
  amount?: Maybe<Scalars['Float']>;
  amountDue?: Maybe<Scalars['Float']>;
  billableFromDate: Scalars['DateTime'];
  billingMonth?: Maybe<Scalars['Int']>;
  billingYear?: Maybe<Scalars['Int']>;
  computedAmount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  externalBill?: Maybe<ExternalBill>;
  hood: Hood;
  id: Scalars['ID'];
  isOverdue?: Maybe<Scalars['Boolean']>;
  isPeriodic: Scalars['Boolean'];
  lateFee?: Maybe<Scalars['Float']>;
  orderNumber?: Maybe<Scalars['String']>;
  payerUser?: Maybe<User>;
  paymentRequestEmails?: Maybe<Array<PaymentRequestEmail>>;
  paymentRequestEmails_debug?: Maybe<Array<Maybe<PaymentRequestEmail>>>;
  paymentTransactions?: Maybe<Array<PaymentTransaction>>;
  relatedUsers?: Maybe<Array<User>>;
  status?: Maybe<BillStatus>;
  taxAmount?: Maybe<Scalars['Float']>;
  /** Amount including transaction fee */
  totalAmount?: Maybe<Scalars['Float']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactions?: Maybe<Array<Transaction>>;
  type: BillType;
  units?: Maybe<Array<Unit>>;
  updatedAt: Scalars['DateTime'];
  user: User;
};


export type BillPaymentRequestEmailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentRequestEmailOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentRequestEmailWhereInput>;
};


export type BillPaymentTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentTransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};


export type BillRelatedUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type BillTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type BillUnitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnitOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitWhereInput>;
};

export type BillConnection = {
  __typename?: 'BillConnection';
  aggregate: AggregateBill;
  edges: Array<Maybe<BillEdge>>;
  pageInfo: PageInfo;
};

export type BillCreateInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate: Scalars['DateTime'];
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillCreateOneInput>;
  hood: HoodCreateOneInput;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserCreateOneInput>;
  paymentRequestEmails?: InputMaybe<PaymentRequestEmailCreateManyWithoutBillsInput>;
  paymentTransactions?: InputMaybe<PaymentTransactionCreateManyWithoutBillInput>;
  relatedUsers?: InputMaybe<UserCreateManyInput>;
  transactions?: InputMaybe<TransactionCreateManyWithoutBillInput>;
  type: BillType;
  units?: InputMaybe<UnitCreateManyInput>;
  user: UserCreateOneInput;
};

export type BillCreateManyInput = {
  connect?: InputMaybe<Array<BillWhereUniqueInput>>;
  create?: InputMaybe<Array<BillCreateInput>>;
};

export type BillCreateManyWithoutPaymentRequestEmailsInput = {
  connect?: InputMaybe<Array<BillWhereUniqueInput>>;
  create?: InputMaybe<Array<BillCreateWithoutPaymentRequestEmailsInput>>;
};

export type BillCreateOneInput = {
  connect?: InputMaybe<BillWhereUniqueInput>;
  create?: InputMaybe<BillCreateInput>;
};

export type BillCreateOneWithoutPaymentTransactionsInput = {
  connect?: InputMaybe<BillWhereUniqueInput>;
  create?: InputMaybe<BillCreateWithoutPaymentTransactionsInput>;
};

export type BillCreateOneWithoutTransactionsInput = {
  connect?: InputMaybe<BillWhereUniqueInput>;
  create?: InputMaybe<BillCreateWithoutTransactionsInput>;
};

export type BillCreateWithoutPaymentRequestEmailsInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate: Scalars['DateTime'];
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillCreateOneInput>;
  hood: HoodCreateOneInput;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserCreateOneInput>;
  paymentTransactions?: InputMaybe<PaymentTransactionCreateManyWithoutBillInput>;
  relatedUsers?: InputMaybe<UserCreateManyInput>;
  transactions?: InputMaybe<TransactionCreateManyWithoutBillInput>;
  type: BillType;
  units?: InputMaybe<UnitCreateManyInput>;
  user: UserCreateOneInput;
};

export type BillCreateWithoutPaymentTransactionsInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate: Scalars['DateTime'];
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillCreateOneInput>;
  hood: HoodCreateOneInput;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserCreateOneInput>;
  paymentRequestEmails?: InputMaybe<PaymentRequestEmailCreateManyWithoutBillsInput>;
  relatedUsers?: InputMaybe<UserCreateManyInput>;
  transactions?: InputMaybe<TransactionCreateManyWithoutBillInput>;
  type: BillType;
  units?: InputMaybe<UnitCreateManyInput>;
  user: UserCreateOneInput;
};

export type BillCreateWithoutTransactionsInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate: Scalars['DateTime'];
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillCreateOneInput>;
  hood: HoodCreateOneInput;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserCreateOneInput>;
  paymentRequestEmails?: InputMaybe<PaymentRequestEmailCreateManyWithoutBillsInput>;
  paymentTransactions?: InputMaybe<PaymentTransactionCreateManyWithoutBillInput>;
  relatedUsers?: InputMaybe<UserCreateManyInput>;
  type: BillType;
  units?: InputMaybe<UnitCreateManyInput>;
  user: UserCreateOneInput;
};

export type BillEdge = {
  __typename?: 'BillEdge';
  cursor: Scalars['String'];
  node: Bill;
};

export type BillIdToAmount = {
  amount: Scalars['Float'];
  billId: Scalars['String'];
};

export type BillIdToAmountDue = {
  __typename?: 'BillIdToAmountDue';
  amountDue: Scalars['Float'];
  billId: Scalars['String'];
};

export enum BillOrderByInput {
  AMOUNTDUE_ASC = 'amountDue_ASC',
  AMOUNTDUE_DESC = 'amountDue_DESC',
  BILLABLEFROMDATE_ASC = 'billableFromDate_ASC',
  BILLABLEFROMDATE_DESC = 'billableFromDate_DESC',
  COMPUTEDAMOUNT_ASC = 'computedAmount_ASC',
  COMPUTEDAMOUNT_DESC = 'computedAmount_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DUEDATE_ASC = 'dueDate_ASC',
  DUEDATE_DESC = 'dueDate_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISOVERDUE_ASC = 'isOverdue_ASC',
  ISOVERDUE_DESC = 'isOverdue_DESC',
  ISPERIODIC_ASC = 'isPeriodic_ASC',
  ISPERIODIC_DESC = 'isPeriodic_DESC',
  ORDERNUMBER_ASC = 'orderNumber_ASC',
  ORDERNUMBER_DESC = 'orderNumber_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type BillScalarWhereInput = {
  AND?: InputMaybe<Array<BillScalarWhereInput>>;
  NOT?: InputMaybe<Array<BillScalarWhereInput>>;
  OR?: InputMaybe<Array<BillScalarWhereInput>>;
  amountDue?: InputMaybe<Scalars['Float']>;
  amountDue_gt?: InputMaybe<Scalars['Float']>;
  amountDue_gte?: InputMaybe<Scalars['Float']>;
  amountDue_in?: InputMaybe<Array<Scalars['Float']>>;
  amountDue_lt?: InputMaybe<Scalars['Float']>;
  amountDue_lte?: InputMaybe<Scalars['Float']>;
  amountDue_not?: InputMaybe<Scalars['Float']>;
  amountDue_not_in?: InputMaybe<Array<Scalars['Float']>>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableFromDate_lt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_lte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  computedAmount_gt?: InputMaybe<Scalars['Float']>;
  computedAmount_gte?: InputMaybe<Scalars['Float']>;
  computedAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  computedAmount_lt?: InputMaybe<Scalars['Float']>;
  computedAmount_lte?: InputMaybe<Scalars['Float']>;
  computedAmount_not?: InputMaybe<Scalars['Float']>;
  computedAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  dueDate_gt?: InputMaybe<Scalars['DateTime']>;
  dueDate_gte?: InputMaybe<Scalars['DateTime']>;
  dueDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dueDate_lt?: InputMaybe<Scalars['DateTime']>;
  dueDate_lte?: InputMaybe<Scalars['DateTime']>;
  dueDate_not?: InputMaybe<Scalars['DateTime']>;
  dueDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isOverdue_not?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  isPeriodic_not?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  orderNumber_contains?: InputMaybe<Scalars['String']>;
  orderNumber_ends_with?: InputMaybe<Scalars['String']>;
  orderNumber_gt?: InputMaybe<Scalars['String']>;
  orderNumber_gte?: InputMaybe<Scalars['String']>;
  orderNumber_in?: InputMaybe<Array<Scalars['String']>>;
  orderNumber_lt?: InputMaybe<Scalars['String']>;
  orderNumber_lte?: InputMaybe<Scalars['String']>;
  orderNumber_not?: InputMaybe<Scalars['String']>;
  orderNumber_not_contains?: InputMaybe<Scalars['String']>;
  orderNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  orderNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  orderNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  orderNumber_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BillType>;
  type_in?: InputMaybe<Array<BillType>>;
  type_not?: InputMaybe<BillType>;
  type_not_in?: InputMaybe<Array<BillType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum BillStatus {
  LOCKED = 'LOCKED',
  OPEN = 'OPEN',
  PAID = 'PAID',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_PENDING = 'PAYMENT_PENDING'
}

export enum BillType {
  CREATED_MANUALLY = 'CREATED_MANUALLY',
  EXTERNAL = 'EXTERNAL',
  FIRST_BILL = 'FIRST_BILL',
  IMMEDIATE = 'IMMEDIATE',
  MONTHLY = 'MONTHLY',
  REFUND = 'REFUND',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export type BillUpdateDataInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillUpdateOneInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserUpdateOneInput>;
  paymentRequestEmails?: InputMaybe<PaymentRequestEmailUpdateManyWithoutBillsInput>;
  paymentTransactions?: InputMaybe<PaymentTransactionUpdateManyWithoutBillInput>;
  relatedUsers?: InputMaybe<UserUpdateManyInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutBillInput>;
  type?: InputMaybe<BillType>;
  units?: InputMaybe<UnitUpdateManyInput>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type BillUpdateInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillUpdateOneInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserUpdateOneInput>;
  paymentRequestEmails?: InputMaybe<PaymentRequestEmailUpdateManyWithoutBillsInput>;
  paymentTransactions?: InputMaybe<PaymentTransactionUpdateManyWithoutBillInput>;
  relatedUsers?: InputMaybe<UserUpdateManyInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutBillInput>;
  type?: InputMaybe<BillType>;
  units?: InputMaybe<UnitUpdateManyInput>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type BillUpdateManyDataInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BillType>;
};

export type BillUpdateManyInput = {
  connect?: InputMaybe<Array<BillWhereUniqueInput>>;
  create?: InputMaybe<Array<BillCreateInput>>;
  delete?: InputMaybe<Array<BillWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BillScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BillWhereUniqueInput>>;
  set?: InputMaybe<Array<BillWhereUniqueInput>>;
  update?: InputMaybe<Array<BillUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<BillUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<BillUpsertWithWhereUniqueNestedInput>>;
};

export type BillUpdateManyMutationInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BillType>;
};

export type BillUpdateManyWithWhereNestedInput = {
  data: BillUpdateManyDataInput;
  where: BillScalarWhereInput;
};

export type BillUpdateManyWithoutPaymentRequestEmailsInput = {
  connect?: InputMaybe<Array<BillWhereUniqueInput>>;
  create?: InputMaybe<Array<BillCreateWithoutPaymentRequestEmailsInput>>;
  delete?: InputMaybe<Array<BillWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BillScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BillWhereUniqueInput>>;
  set?: InputMaybe<Array<BillWhereUniqueInput>>;
  update?: InputMaybe<Array<BillUpdateWithWhereUniqueWithoutPaymentRequestEmailsInput>>;
  updateMany?: InputMaybe<Array<BillUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<BillUpsertWithWhereUniqueWithoutPaymentRequestEmailsInput>>;
};

export type BillUpdateOneRequiredInput = {
  connect?: InputMaybe<BillWhereUniqueInput>;
  create?: InputMaybe<BillCreateInput>;
  update?: InputMaybe<BillUpdateDataInput>;
  upsert?: InputMaybe<BillUpsertNestedInput>;
};

export type BillUpdateOneRequiredWithoutPaymentTransactionsInput = {
  connect?: InputMaybe<BillWhereUniqueInput>;
  create?: InputMaybe<BillCreateWithoutPaymentTransactionsInput>;
  update?: InputMaybe<BillUpdateWithoutPaymentTransactionsDataInput>;
  upsert?: InputMaybe<BillUpsertWithoutPaymentTransactionsInput>;
};

export type BillUpdateOneWithoutTransactionsInput = {
  connect?: InputMaybe<BillWhereUniqueInput>;
  create?: InputMaybe<BillCreateWithoutTransactionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BillUpdateWithoutTransactionsDataInput>;
  upsert?: InputMaybe<BillUpsertWithoutTransactionsInput>;
};

export type BillUpdateWithWhereUniqueNestedInput = {
  data: BillUpdateDataInput;
  where: BillWhereUniqueInput;
};

export type BillUpdateWithWhereUniqueWithoutPaymentRequestEmailsInput = {
  data: BillUpdateWithoutPaymentRequestEmailsDataInput;
  where: BillWhereUniqueInput;
};

export type BillUpdateWithoutPaymentRequestEmailsDataInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillUpdateOneInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserUpdateOneInput>;
  paymentTransactions?: InputMaybe<PaymentTransactionUpdateManyWithoutBillInput>;
  relatedUsers?: InputMaybe<UserUpdateManyInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutBillInput>;
  type?: InputMaybe<BillType>;
  units?: InputMaybe<UnitUpdateManyInput>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type BillUpdateWithoutPaymentTransactionsDataInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillUpdateOneInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserUpdateOneInput>;
  paymentRequestEmails?: InputMaybe<PaymentRequestEmailUpdateManyWithoutBillsInput>;
  relatedUsers?: InputMaybe<UserUpdateManyInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutBillInput>;
  type?: InputMaybe<BillType>;
  units?: InputMaybe<UnitUpdateManyInput>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type BillUpdateWithoutTransactionsDataInput = {
  amountDue?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillUpdateOneInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserUpdateOneInput>;
  paymentRequestEmails?: InputMaybe<PaymentRequestEmailUpdateManyWithoutBillsInput>;
  paymentTransactions?: InputMaybe<PaymentTransactionUpdateManyWithoutBillInput>;
  relatedUsers?: InputMaybe<UserUpdateManyInput>;
  type?: InputMaybe<BillType>;
  units?: InputMaybe<UnitUpdateManyInput>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type BillUpsertNestedInput = {
  create: BillCreateInput;
  update: BillUpdateDataInput;
};

export type BillUpsertWithWhereUniqueNestedInput = {
  create: BillCreateInput;
  update: BillUpdateDataInput;
  where: BillWhereUniqueInput;
};

export type BillUpsertWithWhereUniqueWithoutPaymentRequestEmailsInput = {
  create: BillCreateWithoutPaymentRequestEmailsInput;
  update: BillUpdateWithoutPaymentRequestEmailsDataInput;
  where: BillWhereUniqueInput;
};

export type BillUpsertWithoutPaymentTransactionsInput = {
  create: BillCreateWithoutPaymentTransactionsInput;
  update: BillUpdateWithoutPaymentTransactionsDataInput;
};

export type BillUpsertWithoutTransactionsInput = {
  create: BillCreateWithoutTransactionsInput;
  update: BillUpdateWithoutTransactionsDataInput;
};

export type BillWhereBillingMonthInput = {
  billingMonth: Scalars['Int'];
  billingYear: Scalars['Int'];
};

export type BillWhereBillingYearAndMonthInput = {
  billingMonth: Scalars['Int'];
  billingYear: Scalars['Int'];
};

export type BillWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<BillWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<BillWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<BillWhereContractsPersistencyInput>>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableFromDate_lt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_lte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  isPeriodic_not?: InputMaybe<Scalars['Boolean']>;
  payerUser?: InputMaybe<UserWhereContractsPersistencyInput>;
  paymentRequestEmails_every?: InputMaybe<PaymentRequestEmailWhereContractsPersistencyInput>;
  paymentRequestEmails_none?: InputMaybe<PaymentRequestEmailWhereContractsPersistencyInput>;
  paymentRequestEmails_some?: InputMaybe<PaymentRequestEmailWhereContractsPersistencyInput>;
  paymentTransactions_every?: InputMaybe<PaymentTransactionWhereContractsPersistencyInput>;
  paymentTransactions_none?: InputMaybe<PaymentTransactionWhereContractsPersistencyInput>;
  paymentTransactions_some?: InputMaybe<PaymentTransactionWhereContractsPersistencyInput>;
  transactions_every?: InputMaybe<TransactionWhereContractsPersistencyInput>;
  transactions_none?: InputMaybe<TransactionWhereContractsPersistencyInput>;
  transactions_some?: InputMaybe<TransactionWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type BillWhereInput = {
  AND?: InputMaybe<Array<BillWhereInput>>;
  NOT?: InputMaybe<Array<BillWhereInput>>;
  OR?: InputMaybe<Array<BillWhereInput>>;
  amountDue?: InputMaybe<Scalars['Float']>;
  amountDue_gt?: InputMaybe<Scalars['Float']>;
  amountDue_gte?: InputMaybe<Scalars['Float']>;
  amountDue_in?: InputMaybe<Array<Scalars['Float']>>;
  amountDue_lt?: InputMaybe<Scalars['Float']>;
  amountDue_lte?: InputMaybe<Scalars['Float']>;
  amountDue_not?: InputMaybe<Scalars['Float']>;
  amountDue_not_in?: InputMaybe<Array<Scalars['Float']>>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableFromDate_lt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_lte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billingMonth?: InputMaybe<BillWhereBillingMonthInput>;
  billingMonth_gt?: InputMaybe<BillWhereBillingMonthInput>;
  billingMonth_gte?: InputMaybe<BillWhereBillingMonthInput>;
  billingMonth_lt?: InputMaybe<BillWhereBillingMonthInput>;
  billingMonth_lte?: InputMaybe<BillWhereBillingMonthInput>;
  billingYear?: InputMaybe<Scalars['Int']>;
  billingYearAndMonth?: InputMaybe<BillWhereBillingYearAndMonthInput>;
  billingYear_gt?: InputMaybe<Scalars['Int']>;
  billingYear_gte?: InputMaybe<Scalars['Int']>;
  billingYear_lt?: InputMaybe<Scalars['Int']>;
  billingYear_lte?: InputMaybe<Scalars['Int']>;
  computedAmount?: InputMaybe<Scalars['Float']>;
  computedAmount_gt?: InputMaybe<Scalars['Float']>;
  computedAmount_gte?: InputMaybe<Scalars['Float']>;
  computedAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  computedAmount_lt?: InputMaybe<Scalars['Float']>;
  computedAmount_lte?: InputMaybe<Scalars['Float']>;
  computedAmount_not?: InputMaybe<Scalars['Float']>;
  computedAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  dueDate_gt?: InputMaybe<Scalars['DateTime']>;
  dueDate_gte?: InputMaybe<Scalars['DateTime']>;
  dueDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dueDate_lt?: InputMaybe<Scalars['DateTime']>;
  dueDate_lte?: InputMaybe<Scalars['DateTime']>;
  dueDate_not?: InputMaybe<Scalars['DateTime']>;
  dueDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  externalBill?: InputMaybe<ExternalBillWhereInput>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isOverdue?: InputMaybe<Scalars['Boolean']>;
  isOverdue_not?: InputMaybe<Scalars['Boolean']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  isPeriodic_not?: InputMaybe<Scalars['Boolean']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  orderNumber_contains?: InputMaybe<Scalars['String']>;
  orderNumber_ends_with?: InputMaybe<Scalars['String']>;
  orderNumber_gt?: InputMaybe<Scalars['String']>;
  orderNumber_gte?: InputMaybe<Scalars['String']>;
  orderNumber_in?: InputMaybe<Array<Scalars['String']>>;
  orderNumber_lt?: InputMaybe<Scalars['String']>;
  orderNumber_lte?: InputMaybe<Scalars['String']>;
  orderNumber_not?: InputMaybe<Scalars['String']>;
  orderNumber_not_contains?: InputMaybe<Scalars['String']>;
  orderNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  orderNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  orderNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  orderNumber_starts_with?: InputMaybe<Scalars['String']>;
  payerUser?: InputMaybe<UserWhereInput>;
  paymentRequestEmails_every?: InputMaybe<PaymentRequestEmailWhereInput>;
  paymentRequestEmails_none?: InputMaybe<PaymentRequestEmailWhereInput>;
  paymentRequestEmails_some?: InputMaybe<PaymentRequestEmailWhereInput>;
  paymentTransactions_every?: InputMaybe<PaymentTransactionWhereInput>;
  paymentTransactions_none?: InputMaybe<PaymentTransactionWhereInput>;
  paymentTransactions_some?: InputMaybe<PaymentTransactionWhereInput>;
  relatedUsers_every?: InputMaybe<UserWhereInput>;
  relatedUsers_none?: InputMaybe<UserWhereInput>;
  relatedUsers_some?: InputMaybe<UserWhereInput>;
  status?: InputMaybe<BillStatus>;
  transactions_every?: InputMaybe<TransactionWhereInput>;
  transactions_none?: InputMaybe<TransactionWhereInput>;
  transactions_some?: InputMaybe<TransactionWhereInput>;
  type?: InputMaybe<BillType>;
  type_in?: InputMaybe<Array<BillType>>;
  type_not?: InputMaybe<BillType>;
  type_not_in?: InputMaybe<Array<BillType>>;
  units_every?: InputMaybe<UnitWhereInput>;
  units_none?: InputMaybe<UnitWhereInput>;
  units_some?: InputMaybe<UnitWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
};

export type BillWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<BillWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<BillWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<BillWherePersistencyContractsInput>>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableFromDate_lt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_lte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isPeriodic?: InputMaybe<Scalars['Boolean']>;
  isPeriodic_not?: InputMaybe<Scalars['Boolean']>;
  payerUser?: InputMaybe<UserWherePersistencyContractsInput>;
  paymentRequestEmails_every?: InputMaybe<PaymentRequestEmailWherePersistencyContractsInput>;
  paymentRequestEmails_none?: InputMaybe<PaymentRequestEmailWherePersistencyContractsInput>;
  paymentRequestEmails_some?: InputMaybe<PaymentRequestEmailWherePersistencyContractsInput>;
  paymentTransactions_every?: InputMaybe<PaymentTransactionWherePersistencyContractsInput>;
  paymentTransactions_none?: InputMaybe<PaymentTransactionWherePersistencyContractsInput>;
  paymentTransactions_some?: InputMaybe<PaymentTransactionWherePersistencyContractsInput>;
  transactions_every?: InputMaybe<TransactionWherePersistencyContractsInput>;
  transactions_none?: InputMaybe<TransactionWherePersistencyContractsInput>;
  transactions_some?: InputMaybe<TransactionWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type BillWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type BillableItem = {
  __typename?: 'BillableItem';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  endDateModified?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  internalNote?: Maybe<Scalars['String']>;
  isBillable?: Maybe<Scalars['Boolean']>;
  leaseContract?: Maybe<LeaseContract>;
  leaseContractId?: Maybe<Scalars['String']>;
  membershipContract?: Maybe<MembershipContract>;
  membershipContractId?: Maybe<Scalars['String']>;
  originalEntityId?: Maybe<Scalars['String']>;
  originalEntityName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status?: Maybe<BillableItemStatus>;
  subProduct: SubProduct;
  transactionDescription: Scalars['String'];
  transactions?: Maybe<Array<Transaction>>;
  updatedAt: Scalars['DateTime'];
  user: User;
  vennEmployee?: Maybe<User>;
};


export type BillableItemTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type BillableItemConnection = {
  __typename?: 'BillableItemConnection';
  aggregate: AggregateBillableItem;
  edges: Array<Maybe<BillableItemEdge>>;
  pageInfo: PageInfo;
};

export type BillableItemCreateContractsPersistencyInput = {
  amount: Scalars['Float'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  internalNote?: InputMaybe<Scalars['String']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  subProduct: SubProductCreateOneContractsPersistencyInput;
  transactionDescription: Scalars['String'];
  user: UserCreateOneContractsPersistencyInput;
};

export type BillableItemCreateInput = {
  amount: Scalars['Float'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  endDateModified?: InputMaybe<Scalars['Boolean']>;
  internalNote?: InputMaybe<Scalars['String']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  leaseContractId?: InputMaybe<Scalars['String']>;
  membershipContractId?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status?: InputMaybe<BillableItemStatus>;
  subProduct: SubProductCreateOneInput;
  transactionDescription: Scalars['String'];
  transactions?: InputMaybe<TransactionCreateManyWithoutBillableItemInput>;
  user: UserCreateOneInput;
  vennEmployee?: InputMaybe<UserCreateOneInput>;
};

export type BillableItemCreateOneWithoutTransactionsInput = {
  connect?: InputMaybe<BillableItemWhereUniqueInput>;
  create?: InputMaybe<BillableItemCreateWithoutTransactionsInput>;
};

export type BillableItemCreateWithoutTransactionsInput = {
  amount: Scalars['Float'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  endDateModified?: InputMaybe<Scalars['Boolean']>;
  internalNote?: InputMaybe<Scalars['String']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  leaseContractId?: InputMaybe<Scalars['String']>;
  membershipContractId?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status?: InputMaybe<BillableItemStatus>;
  subProduct: SubProductCreateOneInput;
  transactionDescription: Scalars['String'];
  user: UserCreateOneInput;
  vennEmployee?: InputMaybe<UserCreateOneInput>;
};

export type BillableItemEdge = {
  __typename?: 'BillableItemEdge';
  cursor: Scalars['String'];
  node: BillableItem;
};

export enum BillableItemOrderByInput {
  AMOUNT_ASC = 'amount_ASC',
  AMOUNT_DESC = 'amount_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENDDATEMODIFIED_ASC = 'endDateModified_ASC',
  ENDDATEMODIFIED_DESC = 'endDateModified_DESC',
  ENDDATE_ASC = 'endDate_ASC',
  ENDDATE_DESC = 'endDate_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  INTERNALNOTE_ASC = 'internalNote_ASC',
  INTERNALNOTE_DESC = 'internalNote_DESC',
  ISBILLABLE_ASC = 'isBillable_ASC',
  ISBILLABLE_DESC = 'isBillable_DESC',
  LEASECONTRACTID_ASC = 'leaseContractId_ASC',
  LEASECONTRACTID_DESC = 'leaseContractId_DESC',
  MEMBERSHIPCONTRACTID_ASC = 'membershipContractId_ASC',
  MEMBERSHIPCONTRACTID_DESC = 'membershipContractId_DESC',
  ORIGINALENTITYID_ASC = 'originalEntityId_ASC',
  ORIGINALENTITYID_DESC = 'originalEntityId_DESC',
  ORIGINALENTITYNAME_ASC = 'originalEntityName_ASC',
  ORIGINALENTITYNAME_DESC = 'originalEntityName_DESC',
  STARTDATE_ASC = 'startDate_ASC',
  STARTDATE_DESC = 'startDate_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  TRANSACTIONDESCRIPTION_ASC = 'transactionDescription_ASC',
  TRANSACTIONDESCRIPTION_DESC = 'transactionDescription_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export enum BillableItemStatus {
  INIT_DONE = 'INIT_DONE',
  INIT_IN_PROCESS = 'INIT_IN_PROCESS',
  NEW = 'NEW'
}

export type BillableItemUpdateContractsPersistencyInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  internalNote?: InputMaybe<Scalars['String']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  transactionDescription?: InputMaybe<Scalars['String']>;
};

export type BillableItemUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDateModified?: InputMaybe<Scalars['Boolean']>;
  internalNote?: InputMaybe<Scalars['String']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  leaseContractId?: InputMaybe<Scalars['String']>;
  membershipContractId?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<BillableItemStatus>;
  subProduct?: InputMaybe<SubProductUpdateOneRequiredInput>;
  transactionDescription?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutBillableItemInput>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  vennEmployee?: InputMaybe<UserUpdateOneInput>;
};

export type BillableItemUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDateModified?: InputMaybe<Scalars['Boolean']>;
  internalNote?: InputMaybe<Scalars['String']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  leaseContractId?: InputMaybe<Scalars['String']>;
  membershipContractId?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<BillableItemStatus>;
  transactionDescription?: InputMaybe<Scalars['String']>;
};

export type BillableItemUpdateOneRequiredWithoutTransactionsInput = {
  connect?: InputMaybe<BillableItemWhereUniqueInput>;
  create?: InputMaybe<BillableItemCreateWithoutTransactionsInput>;
  update?: InputMaybe<BillableItemUpdateWithoutTransactionsDataInput>;
  upsert?: InputMaybe<BillableItemUpsertWithoutTransactionsInput>;
};

export type BillableItemUpdateWithWhereUniqueWithoutLeaseContractInput = {
  data: BillableItemUpdateContractsPersistencyInput;
  where: BillableItemWhereUniqueContractsPersistencyInput;
};

export type BillableItemUpdateWithoutTransactionsDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDateModified?: InputMaybe<Scalars['Boolean']>;
  internalNote?: InputMaybe<Scalars['String']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  leaseContractId?: InputMaybe<Scalars['String']>;
  membershipContractId?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<BillableItemStatus>;
  subProduct?: InputMaybe<SubProductUpdateOneRequiredInput>;
  transactionDescription?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  vennEmployee?: InputMaybe<UserUpdateOneInput>;
};

export type BillableItemUpsertWithoutTransactionsInput = {
  create: BillableItemCreateWithoutTransactionsInput;
  update: BillableItemUpdateWithoutTransactionsDataInput;
};

export type BillableItemWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<BillableItemWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<BillableItemWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<BillableItemWhereContractsPersistencyInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalNote?: InputMaybe<Scalars['String']>;
  internalNote_contains?: InputMaybe<Scalars['String']>;
  internalNote_ends_with?: InputMaybe<Scalars['String']>;
  internalNote_gt?: InputMaybe<Scalars['String']>;
  internalNote_gte?: InputMaybe<Scalars['String']>;
  internalNote_in?: InputMaybe<Array<Scalars['String']>>;
  internalNote_lt?: InputMaybe<Scalars['String']>;
  internalNote_lte?: InputMaybe<Scalars['String']>;
  internalNote_not?: InputMaybe<Scalars['String']>;
  internalNote_not_contains?: InputMaybe<Scalars['String']>;
  internalNote_not_ends_with?: InputMaybe<Scalars['String']>;
  internalNote_not_in?: InputMaybe<Array<Scalars['String']>>;
  internalNote_not_starts_with?: InputMaybe<Scalars['String']>;
  internalNote_starts_with?: InputMaybe<Scalars['String']>;
  leaseContract?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  membershipContract?: InputMaybe<MembershipContractWhereContractsPersistencyInput>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityId_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_gt?: InputMaybe<Scalars['String']>;
  originalEntityId_gte?: InputMaybe<Scalars['String']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_lt?: InputMaybe<Scalars['String']>;
  originalEntityId_lte?: InputMaybe<Scalars['String']>;
  originalEntityId_not?: InputMaybe<Scalars['String']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  originalEntityName_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_gt?: InputMaybe<Scalars['String']>;
  originalEntityName_gte?: InputMaybe<Scalars['String']>;
  originalEntityName_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_lt?: InputMaybe<Scalars['String']>;
  originalEntityName_lte?: InputMaybe<Scalars['String']>;
  originalEntityName_not?: InputMaybe<Scalars['String']>;
  originalEntityName_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName_starts_with?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<ContractsPersistencyBillableItemStatus>;
  status_in?: InputMaybe<Array<ContractsPersistencyBillableItemStatus>>;
  status_not?: InputMaybe<ContractsPersistencyBillableItemStatus>;
  status_not_in?: InputMaybe<Array<ContractsPersistencyBillableItemStatus>>;
  subProduct?: InputMaybe<SubProductWhereContractsPersistencyInput>;
  transactionDescription?: InputMaybe<Scalars['String']>;
  transactionDescription_contains?: InputMaybe<Scalars['String']>;
  transactionDescription_ends_with?: InputMaybe<Scalars['String']>;
  transactionDescription_gt?: InputMaybe<Scalars['String']>;
  transactionDescription_gte?: InputMaybe<Scalars['String']>;
  transactionDescription_in?: InputMaybe<Array<Scalars['String']>>;
  transactionDescription_lt?: InputMaybe<Scalars['String']>;
  transactionDescription_lte?: InputMaybe<Scalars['String']>;
  transactionDescription_not?: InputMaybe<Scalars['String']>;
  transactionDescription_not_contains?: InputMaybe<Scalars['String']>;
  transactionDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  transactionDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  transactionDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  transactionDescription_starts_with?: InputMaybe<Scalars['String']>;
  transactions_every?: InputMaybe<TransactionWhereContractsPersistencyInput>;
  transactions_none?: InputMaybe<TransactionWhereContractsPersistencyInput>;
  transactions_some?: InputMaybe<TransactionWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
  vennEmployee?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type BillableItemWhereInput = {
  AND?: InputMaybe<Array<BillableItemWhereInput>>;
  NOT?: InputMaybe<Array<BillableItemWhereInput>>;
  OR?: InputMaybe<Array<BillableItemWhereInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDateModified?: InputMaybe<Scalars['Boolean']>;
  endDateModified_not?: InputMaybe<Scalars['Boolean']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalNote?: InputMaybe<Scalars['String']>;
  internalNote_contains?: InputMaybe<Scalars['String']>;
  internalNote_ends_with?: InputMaybe<Scalars['String']>;
  internalNote_gt?: InputMaybe<Scalars['String']>;
  internalNote_gte?: InputMaybe<Scalars['String']>;
  internalNote_in?: InputMaybe<Array<Scalars['String']>>;
  internalNote_lt?: InputMaybe<Scalars['String']>;
  internalNote_lte?: InputMaybe<Scalars['String']>;
  internalNote_not?: InputMaybe<Scalars['String']>;
  internalNote_not_contains?: InputMaybe<Scalars['String']>;
  internalNote_not_ends_with?: InputMaybe<Scalars['String']>;
  internalNote_not_in?: InputMaybe<Array<Scalars['String']>>;
  internalNote_not_starts_with?: InputMaybe<Scalars['String']>;
  internalNote_starts_with?: InputMaybe<Scalars['String']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  isBillable_not?: InputMaybe<Scalars['Boolean']>;
  leaseContract?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContractId?: InputMaybe<Scalars['String']>;
  leaseContractId_contains?: InputMaybe<Scalars['String']>;
  leaseContractId_ends_with?: InputMaybe<Scalars['String']>;
  leaseContractId_gt?: InputMaybe<Scalars['String']>;
  leaseContractId_gte?: InputMaybe<Scalars['String']>;
  leaseContractId_in?: InputMaybe<Array<Scalars['String']>>;
  leaseContractId_lt?: InputMaybe<Scalars['String']>;
  leaseContractId_lte?: InputMaybe<Scalars['String']>;
  leaseContractId_not?: InputMaybe<Scalars['String']>;
  leaseContractId_not_contains?: InputMaybe<Scalars['String']>;
  leaseContractId_not_ends_with?: InputMaybe<Scalars['String']>;
  leaseContractId_not_in?: InputMaybe<Array<Scalars['String']>>;
  leaseContractId_not_starts_with?: InputMaybe<Scalars['String']>;
  leaseContractId_starts_with?: InputMaybe<Scalars['String']>;
  membershipContract?: InputMaybe<MembershipContractWherePersistencyContractsInput>;
  membershipContractId?: InputMaybe<Scalars['String']>;
  membershipContractId_contains?: InputMaybe<Scalars['String']>;
  membershipContractId_ends_with?: InputMaybe<Scalars['String']>;
  membershipContractId_gt?: InputMaybe<Scalars['String']>;
  membershipContractId_gte?: InputMaybe<Scalars['String']>;
  membershipContractId_in?: InputMaybe<Array<Scalars['String']>>;
  membershipContractId_lt?: InputMaybe<Scalars['String']>;
  membershipContractId_lte?: InputMaybe<Scalars['String']>;
  membershipContractId_not?: InputMaybe<Scalars['String']>;
  membershipContractId_not_contains?: InputMaybe<Scalars['String']>;
  membershipContractId_not_ends_with?: InputMaybe<Scalars['String']>;
  membershipContractId_not_in?: InputMaybe<Array<Scalars['String']>>;
  membershipContractId_not_starts_with?: InputMaybe<Scalars['String']>;
  membershipContractId_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityId_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_gt?: InputMaybe<Scalars['String']>;
  originalEntityId_gte?: InputMaybe<Scalars['String']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_lt?: InputMaybe<Scalars['String']>;
  originalEntityId_lte?: InputMaybe<Scalars['String']>;
  originalEntityId_not?: InputMaybe<Scalars['String']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  originalEntityName_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_gt?: InputMaybe<Scalars['String']>;
  originalEntityName_gte?: InputMaybe<Scalars['String']>;
  originalEntityName_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_lt?: InputMaybe<Scalars['String']>;
  originalEntityName_lte?: InputMaybe<Scalars['String']>;
  originalEntityName_not?: InputMaybe<Scalars['String']>;
  originalEntityName_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName_starts_with?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<BillableItemStatus>;
  status_in?: InputMaybe<Array<BillableItemStatus>>;
  status_not?: InputMaybe<BillableItemStatus>;
  status_not_in?: InputMaybe<Array<BillableItemStatus>>;
  subProduct?: InputMaybe<SubProductWhereInput>;
  transactionDescription?: InputMaybe<Scalars['String']>;
  transactionDescription_contains?: InputMaybe<Scalars['String']>;
  transactionDescription_ends_with?: InputMaybe<Scalars['String']>;
  transactionDescription_gt?: InputMaybe<Scalars['String']>;
  transactionDescription_gte?: InputMaybe<Scalars['String']>;
  transactionDescription_in?: InputMaybe<Array<Scalars['String']>>;
  transactionDescription_lt?: InputMaybe<Scalars['String']>;
  transactionDescription_lte?: InputMaybe<Scalars['String']>;
  transactionDescription_not?: InputMaybe<Scalars['String']>;
  transactionDescription_not_contains?: InputMaybe<Scalars['String']>;
  transactionDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  transactionDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  transactionDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  transactionDescription_starts_with?: InputMaybe<Scalars['String']>;
  transactions_every?: InputMaybe<TransactionWhereInput>;
  transactions_none?: InputMaybe<TransactionWhereInput>;
  transactions_some?: InputMaybe<TransactionWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
  vennEmployee?: InputMaybe<UserWhereInput>;
};

export type BillableItemWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<BillableItemWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<BillableItemWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<BillableItemWherePersistencyContractsInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalNote?: InputMaybe<Scalars['String']>;
  internalNote_contains?: InputMaybe<Scalars['String']>;
  internalNote_ends_with?: InputMaybe<Scalars['String']>;
  internalNote_gt?: InputMaybe<Scalars['String']>;
  internalNote_gte?: InputMaybe<Scalars['String']>;
  internalNote_in?: InputMaybe<Array<Scalars['String']>>;
  internalNote_lt?: InputMaybe<Scalars['String']>;
  internalNote_lte?: InputMaybe<Scalars['String']>;
  internalNote_not?: InputMaybe<Scalars['String']>;
  internalNote_not_contains?: InputMaybe<Scalars['String']>;
  internalNote_not_ends_with?: InputMaybe<Scalars['String']>;
  internalNote_not_in?: InputMaybe<Array<Scalars['String']>>;
  internalNote_not_starts_with?: InputMaybe<Scalars['String']>;
  internalNote_starts_with?: InputMaybe<Scalars['String']>;
  leaseContract?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  membershipContract?: InputMaybe<MembershipContractWherePersistencyContractsInput>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityId_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_gt?: InputMaybe<Scalars['String']>;
  originalEntityId_gte?: InputMaybe<Scalars['String']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_lt?: InputMaybe<Scalars['String']>;
  originalEntityId_lte?: InputMaybe<Scalars['String']>;
  originalEntityId_not?: InputMaybe<Scalars['String']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  originalEntityName_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_gt?: InputMaybe<Scalars['String']>;
  originalEntityName_gte?: InputMaybe<Scalars['String']>;
  originalEntityName_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_lt?: InputMaybe<Scalars['String']>;
  originalEntityName_lte?: InputMaybe<Scalars['String']>;
  originalEntityName_not?: InputMaybe<Scalars['String']>;
  originalEntityName_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName_starts_with?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<PersistencyContractsBillableItemStatus>;
  status_in?: InputMaybe<Array<PersistencyContractsBillableItemStatus>>;
  status_not?: InputMaybe<PersistencyContractsBillableItemStatus>;
  status_not_in?: InputMaybe<Array<PersistencyContractsBillableItemStatus>>;
  subProduct?: InputMaybe<SubProductWherePersistencyContractsInput>;
  transactionDescription?: InputMaybe<Scalars['String']>;
  transactionDescription_contains?: InputMaybe<Scalars['String']>;
  transactionDescription_ends_with?: InputMaybe<Scalars['String']>;
  transactionDescription_gt?: InputMaybe<Scalars['String']>;
  transactionDescription_gte?: InputMaybe<Scalars['String']>;
  transactionDescription_in?: InputMaybe<Array<Scalars['String']>>;
  transactionDescription_lt?: InputMaybe<Scalars['String']>;
  transactionDescription_lte?: InputMaybe<Scalars['String']>;
  transactionDescription_not?: InputMaybe<Scalars['String']>;
  transactionDescription_not_contains?: InputMaybe<Scalars['String']>;
  transactionDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  transactionDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  transactionDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  transactionDescription_starts_with?: InputMaybe<Scalars['String']>;
  transactions_every?: InputMaybe<TransactionWherePersistencyContractsInput>;
  transactions_none?: InputMaybe<TransactionWherePersistencyContractsInput>;
  transactions_some?: InputMaybe<TransactionWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
  vennEmployee?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type BillableItemWhereUniqueContractsPersistencyInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type BillableItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type BillingNotificationsSettings = {
  __typename?: 'BillingNotificationsSettings';
  SMSNotifications?: Maybe<SmsNotificationSettings>;
  emailNotifications?: Maybe<EmailNotificationSettings>;
  pushNotifications?: Maybe<PushNotificationSettings>;
};

export type BlockMemberProfileInput = {
  ownerId: Scalars['String'];
};

export type BlockingUsers = {
  __typename?: 'BlockingUsers';
  userIds: Array<Maybe<Scalars['String']>>;
};

export type Building = {
  __typename?: 'Building';
  _empty?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  airtableId?: Maybe<Scalars['String']>;
  baseRent?: Maybe<Scalars['Float']>;
  buildingNumber: Scalars['Int'];
  buildingSetting?: Maybe<BuildingSetting>;
  buildingStatus: BuildingStatus;
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contractEndDate?: Maybe<Scalars['DateTime']>;
  contractSignedDate?: Maybe<Scalars['DateTime']>;
  contractStartDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  featuredSlides?: Maybe<Array<FeaturedSlide>>;
  hood: Hood;
  id: Scalars['ID'];
  landlordContractType?: Maybe<LandlordContractType>;
  legalName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfFloors?: Maybe<Scalars['Int']>;
  officeAddress?: Maybe<Scalars['String']>;
  parcel?: Maybe<Scalars['String']>;
  plot?: Maybe<Scalars['String']>;
  propertyManagementFees?: Maybe<Scalars['Json']>;
  propertyManagementOffice?: Maybe<PropertyManagementOffice>;
  section?: Maybe<Scalars['String']>;
  streetName: Scalars['String'];
  units?: Maybe<Array<Unit>>;
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
};


export type BuildingFeaturedSlidesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeaturedSlideOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeaturedSlideWhereInput>;
};


export type BuildingUnitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnitOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitWhereInput>;
};


export type BuildingUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type BuildingConnection = {
  __typename?: 'BuildingConnection';
  aggregate: AggregateBuilding;
  edges: Array<Maybe<BuildingEdge>>;
  pageInfo: PageInfo;
};

export type BuildingCreateInput = {
  address?: InputMaybe<AddressCreateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber: Scalars['Int'];
  buildingSetting?: InputMaybe<BuildingSettingCreateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideCreateManyWithoutBuildingsInput>;
  hood: HoodCreateOneInput;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeCreateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName: Scalars['String'];
  units?: InputMaybe<UnitCreateManyWithoutBuildingInput>;
  users?: InputMaybe<UserCreateManyWithoutComputedBuildingInput>;
};

export type BuildingCreateManyWithoutFeaturedSlidesInput = {
  connect?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  create?: InputMaybe<Array<BuildingCreateWithoutFeaturedSlidesInput>>;
};

export type BuildingCreateOneInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateInput>;
};

export type BuildingCreateOneWithoutBuildingSettingInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateWithoutBuildingSettingInput>;
};

export type BuildingCreateOneWithoutUnitsInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateWithoutUnitsInput>;
};

export type BuildingCreateOneWithoutUsersInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateWithoutUsersInput>;
};

export type BuildingCreateWithoutBuildingSettingInput = {
  address?: InputMaybe<AddressCreateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber: Scalars['Int'];
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideCreateManyWithoutBuildingsInput>;
  hood: HoodCreateOneInput;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeCreateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName: Scalars['String'];
  units?: InputMaybe<UnitCreateManyWithoutBuildingInput>;
  users?: InputMaybe<UserCreateManyWithoutComputedBuildingInput>;
};

export type BuildingCreateWithoutFeaturedSlidesInput = {
  address?: InputMaybe<AddressCreateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber: Scalars['Int'];
  buildingSetting?: InputMaybe<BuildingSettingCreateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  hood: HoodCreateOneInput;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeCreateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName: Scalars['String'];
  units?: InputMaybe<UnitCreateManyWithoutBuildingInput>;
  users?: InputMaybe<UserCreateManyWithoutComputedBuildingInput>;
};

export type BuildingCreateWithoutUnitsInput = {
  address?: InputMaybe<AddressCreateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber: Scalars['Int'];
  buildingSetting?: InputMaybe<BuildingSettingCreateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideCreateManyWithoutBuildingsInput>;
  hood: HoodCreateOneInput;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeCreateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName: Scalars['String'];
  users?: InputMaybe<UserCreateManyWithoutComputedBuildingInput>;
};

export type BuildingCreateWithoutUsersInput = {
  address?: InputMaybe<AddressCreateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber: Scalars['Int'];
  buildingSetting?: InputMaybe<BuildingSettingCreateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideCreateManyWithoutBuildingsInput>;
  hood: HoodCreateOneInput;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeCreateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName: Scalars['String'];
  units?: InputMaybe<UnitCreateManyWithoutBuildingInput>;
};

export type BuildingEdge = {
  __typename?: 'BuildingEdge';
  cursor: Scalars['String'];
  node: Building;
};

export type BuildingNearByInput = {
  distanceInMeters: Scalars['Int'];
  placeTypes: Array<PlaceTypes>;
};

export enum BuildingOrderByInput {
  AIRTABLEID_ASC = 'airtableId_ASC',
  AIRTABLEID_DESC = 'airtableId_DESC',
  BUILDINGNUMBER_ASC = 'buildingNumber_ASC',
  BUILDINGNUMBER_DESC = 'buildingNumber_DESC',
  BUILDINGSTATUS_ASC = 'buildingStatus_ASC',
  BUILDINGSTATUS_DESC = 'buildingStatus_DESC',
  CONTACTEMAIL_ASC = 'contactEmail_ASC',
  CONTACTEMAIL_DESC = 'contactEmail_DESC',
  CONTACTPHONE_ASC = 'contactPhone_ASC',
  CONTACTPHONE_DESC = 'contactPhone_DESC',
  CONTRACTENDDATE_ASC = 'contractEndDate_ASC',
  CONTRACTENDDATE_DESC = 'contractEndDate_DESC',
  CONTRACTSIGNEDDATE_ASC = 'contractSignedDate_ASC',
  CONTRACTSIGNEDDATE_DESC = 'contractSignedDate_DESC',
  CONTRACTSTARTDATE_ASC = 'contractStartDate_ASC',
  CONTRACTSTARTDATE_DESC = 'contractStartDate_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  EXTERNALID_ASC = 'externalId_ASC',
  EXTERNALID_DESC = 'externalId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  LANDLORDCONTRACTTYPE_ASC = 'landlordContractType_ASC',
  LANDLORDCONTRACTTYPE_DESC = 'landlordContractType_DESC',
  LEGALNAME_ASC = 'legalName_ASC',
  LEGALNAME_DESC = 'legalName_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  NUMBEROFFLOORS_ASC = 'numberOfFloors_ASC',
  NUMBEROFFLOORS_DESC = 'numberOfFloors_DESC',
  OFFICEADDRESS_ASC = 'officeAddress_ASC',
  OFFICEADDRESS_DESC = 'officeAddress_DESC',
  PARCEL_ASC = 'parcel_ASC',
  PARCEL_DESC = 'parcel_DESC',
  PLOT_ASC = 'plot_ASC',
  PLOT_DESC = 'plot_DESC',
  PROPERTYMANAGEMENTFEES_ASC = 'propertyManagementFees_ASC',
  PROPERTYMANAGEMENTFEES_DESC = 'propertyManagementFees_DESC',
  SECTION_ASC = 'section_ASC',
  SECTION_DESC = 'section_DESC',
  STREETNAME_ASC = 'streetName_ASC',
  STREETNAME_DESC = 'streetName_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type BuildingScalarWhereInput = {
  AND?: InputMaybe<Array<BuildingScalarWhereInput>>;
  NOT?: InputMaybe<Array<BuildingScalarWhereInput>>;
  OR?: InputMaybe<Array<BuildingScalarWhereInput>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingNumber_gt?: InputMaybe<Scalars['Int']>;
  buildingNumber_gte?: InputMaybe<Scalars['Int']>;
  buildingNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingNumber_lt?: InputMaybe<Scalars['Int']>;
  buildingNumber_lte?: InputMaybe<Scalars['Int']>;
  buildingNumber_not?: InputMaybe<Scalars['Int']>;
  buildingNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  buildingStatus_in?: InputMaybe<Array<BuildingStatus>>;
  buildingStatus_not?: InputMaybe<BuildingStatus>;
  buildingStatus_not_in?: InputMaybe<Array<BuildingStatus>>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactEmail_contains?: InputMaybe<Scalars['String']>;
  contactEmail_ends_with?: InputMaybe<Scalars['String']>;
  contactEmail_gt?: InputMaybe<Scalars['String']>;
  contactEmail_gte?: InputMaybe<Scalars['String']>;
  contactEmail_in?: InputMaybe<Array<Scalars['String']>>;
  contactEmail_lt?: InputMaybe<Scalars['String']>;
  contactEmail_lte?: InputMaybe<Scalars['String']>;
  contactEmail_not?: InputMaybe<Scalars['String']>;
  contactEmail_not_contains?: InputMaybe<Scalars['String']>;
  contactEmail_not_ends_with?: InputMaybe<Scalars['String']>;
  contactEmail_not_in?: InputMaybe<Array<Scalars['String']>>;
  contactEmail_not_starts_with?: InputMaybe<Scalars['String']>;
  contactEmail_starts_with?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactPhone_contains?: InputMaybe<Scalars['String']>;
  contactPhone_ends_with?: InputMaybe<Scalars['String']>;
  contactPhone_gt?: InputMaybe<Scalars['String']>;
  contactPhone_gte?: InputMaybe<Scalars['String']>;
  contactPhone_in?: InputMaybe<Array<Scalars['String']>>;
  contactPhone_lt?: InputMaybe<Scalars['String']>;
  contactPhone_lte?: InputMaybe<Scalars['String']>;
  contactPhone_not?: InputMaybe<Scalars['String']>;
  contactPhone_not_contains?: InputMaybe<Scalars['String']>;
  contactPhone_not_ends_with?: InputMaybe<Scalars['String']>;
  contactPhone_not_in?: InputMaybe<Array<Scalars['String']>>;
  contactPhone_not_starts_with?: InputMaybe<Scalars['String']>;
  contactPhone_starts_with?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  landlordContractType_in?: InputMaybe<Array<LandlordContractType>>;
  landlordContractType_not?: InputMaybe<LandlordContractType>;
  landlordContractType_not_in?: InputMaybe<Array<LandlordContractType>>;
  legalName?: InputMaybe<Scalars['String']>;
  legalName_contains?: InputMaybe<Scalars['String']>;
  legalName_ends_with?: InputMaybe<Scalars['String']>;
  legalName_gt?: InputMaybe<Scalars['String']>;
  legalName_gte?: InputMaybe<Scalars['String']>;
  legalName_in?: InputMaybe<Array<Scalars['String']>>;
  legalName_lt?: InputMaybe<Scalars['String']>;
  legalName_lte?: InputMaybe<Scalars['String']>;
  legalName_not?: InputMaybe<Scalars['String']>;
  legalName_not_contains?: InputMaybe<Scalars['String']>;
  legalName_not_ends_with?: InputMaybe<Scalars['String']>;
  legalName_not_in?: InputMaybe<Array<Scalars['String']>>;
  legalName_not_starts_with?: InputMaybe<Scalars['String']>;
  legalName_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_in?: InputMaybe<Array<Scalars['Int']>>;
  numberOfFloors_lt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_lte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not_in?: InputMaybe<Array<Scalars['Int']>>;
  officeAddress?: InputMaybe<Scalars['String']>;
  officeAddress_contains?: InputMaybe<Scalars['String']>;
  officeAddress_ends_with?: InputMaybe<Scalars['String']>;
  officeAddress_gt?: InputMaybe<Scalars['String']>;
  officeAddress_gte?: InputMaybe<Scalars['String']>;
  officeAddress_in?: InputMaybe<Array<Scalars['String']>>;
  officeAddress_lt?: InputMaybe<Scalars['String']>;
  officeAddress_lte?: InputMaybe<Scalars['String']>;
  officeAddress_not?: InputMaybe<Scalars['String']>;
  officeAddress_not_contains?: InputMaybe<Scalars['String']>;
  officeAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  officeAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  officeAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  officeAddress_starts_with?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  parcel_contains?: InputMaybe<Scalars['String']>;
  parcel_ends_with?: InputMaybe<Scalars['String']>;
  parcel_gt?: InputMaybe<Scalars['String']>;
  parcel_gte?: InputMaybe<Scalars['String']>;
  parcel_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_lt?: InputMaybe<Scalars['String']>;
  parcel_lte?: InputMaybe<Scalars['String']>;
  parcel_not?: InputMaybe<Scalars['String']>;
  parcel_not_contains?: InputMaybe<Scalars['String']>;
  parcel_not_ends_with?: InputMaybe<Scalars['String']>;
  parcel_not_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_not_starts_with?: InputMaybe<Scalars['String']>;
  parcel_starts_with?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  plot_contains?: InputMaybe<Scalars['String']>;
  plot_ends_with?: InputMaybe<Scalars['String']>;
  plot_gt?: InputMaybe<Scalars['String']>;
  plot_gte?: InputMaybe<Scalars['String']>;
  plot_in?: InputMaybe<Array<Scalars['String']>>;
  plot_lt?: InputMaybe<Scalars['String']>;
  plot_lte?: InputMaybe<Scalars['String']>;
  plot_not?: InputMaybe<Scalars['String']>;
  plot_not_contains?: InputMaybe<Scalars['String']>;
  plot_not_ends_with?: InputMaybe<Scalars['String']>;
  plot_not_in?: InputMaybe<Array<Scalars['String']>>;
  plot_not_starts_with?: InputMaybe<Scalars['String']>;
  plot_starts_with?: InputMaybe<Scalars['String']>;
  section?: InputMaybe<Scalars['String']>;
  section_contains?: InputMaybe<Scalars['String']>;
  section_ends_with?: InputMaybe<Scalars['String']>;
  section_gt?: InputMaybe<Scalars['String']>;
  section_gte?: InputMaybe<Scalars['String']>;
  section_in?: InputMaybe<Array<Scalars['String']>>;
  section_lt?: InputMaybe<Scalars['String']>;
  section_lte?: InputMaybe<Scalars['String']>;
  section_not?: InputMaybe<Scalars['String']>;
  section_not_contains?: InputMaybe<Scalars['String']>;
  section_not_ends_with?: InputMaybe<Scalars['String']>;
  section_not_in?: InputMaybe<Array<Scalars['String']>>;
  section_not_starts_with?: InputMaybe<Scalars['String']>;
  section_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BuildingSetting = {
  __typename?: 'BuildingSetting';
  _empty?: Maybe<Scalars['String']>;
  building: Building;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  locksEnabled?: Maybe<Scalars['Boolean']>;
  packages?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};

export type BuildingSettingCreateInput = {
  building: BuildingCreateOneWithoutBuildingSettingInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  locksEnabled?: InputMaybe<Scalars['Boolean']>;
  packages?: InputMaybe<Scalars['Boolean']>;
};

export type BuildingSettingCreateOneWithoutBuildingInput = {
  connect?: InputMaybe<BuildingSettingWhereUniqueInput>;
  create?: InputMaybe<BuildingSettingCreateWithoutBuildingInput>;
};

export type BuildingSettingCreateWithoutBuildingInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  locksEnabled?: InputMaybe<Scalars['Boolean']>;
  packages?: InputMaybe<Scalars['Boolean']>;
};

export enum BuildingSettingOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  LOCKSENABLED_ASC = 'locksEnabled_ASC',
  LOCKSENABLED_DESC = 'locksEnabled_DESC',
  PACKAGES_ASC = 'packages_ASC',
  PACKAGES_DESC = 'packages_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type BuildingSettingUpdateInput = {
  building?: InputMaybe<BuildingUpdateOneRequiredWithoutBuildingSettingInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  locksEnabled?: InputMaybe<Scalars['Boolean']>;
  packages?: InputMaybe<Scalars['Boolean']>;
};

export type BuildingSettingUpdateOneWithoutBuildingInput = {
  connect?: InputMaybe<BuildingSettingWhereUniqueInput>;
  create?: InputMaybe<BuildingSettingCreateWithoutBuildingInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BuildingSettingUpdateWithoutBuildingDataInput>;
  upsert?: InputMaybe<BuildingSettingUpsertWithoutBuildingInput>;
};

export type BuildingSettingUpdateWithoutBuildingDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  locksEnabled?: InputMaybe<Scalars['Boolean']>;
  packages?: InputMaybe<Scalars['Boolean']>;
};

export type BuildingSettingUpsertWithoutBuildingInput = {
  create: BuildingSettingCreateWithoutBuildingInput;
  update: BuildingSettingUpdateWithoutBuildingDataInput;
};

export type BuildingSettingWhereInput = {
  AND?: InputMaybe<Array<BuildingSettingWhereInput>>;
  NOT?: InputMaybe<Array<BuildingSettingWhereInput>>;
  OR?: InputMaybe<Array<BuildingSettingWhereInput>>;
  building?: InputMaybe<BuildingWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locksEnabled?: InputMaybe<Scalars['Boolean']>;
  locksEnabled_not?: InputMaybe<Scalars['Boolean']>;
  packages?: InputMaybe<Scalars['Boolean']>;
  packages_not?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BuildingSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum BuildingStatus {
  CHURNED = 'CHURNED',
  PIPELINE = 'PIPELINE',
  SIGNED_SETUP_PERIOD = 'SIGNED_SETUP_PERIOD',
  SIGNED_STABILISED_PERIOD = 'SIGNED_STABILISED_PERIOD',
  UNDER_CONTRACT = 'UNDER_CONTRACT'
}

export enum BuildingStatusPersistency {
  CHURNED = 'CHURNED',
  PIPELINE = 'PIPELINE',
  SIGNED_SETUP_PERIOD = 'SIGNED_SETUP_PERIOD',
  SIGNED_STABILISED_PERIOD = 'SIGNED_STABILISED_PERIOD',
  UNDER_CONTRACT = 'UNDER_CONTRACT'
}

export type BuildingUpdateDataInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingSetting?: InputMaybe<BuildingSettingUpdateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideUpdateManyWithoutBuildingsInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<UnitUpdateManyWithoutBuildingInput>;
  users?: InputMaybe<UserUpdateManyWithoutComputedBuildingInput>;
};

export type BuildingUpdateInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingSetting?: InputMaybe<BuildingSettingUpdateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideUpdateManyWithoutBuildingsInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<UnitUpdateManyWithoutBuildingInput>;
  users?: InputMaybe<UserUpdateManyWithoutComputedBuildingInput>;
};

export type BuildingUpdateManyDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  section?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
};

export type BuildingUpdateManyWithWhereNestedInput = {
  data: BuildingUpdateManyDataInput;
  where: BuildingScalarWhereInput;
};

export type BuildingUpdateManyWithoutFeaturedSlidesInput = {
  connect?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  create?: InputMaybe<Array<BuildingCreateWithoutFeaturedSlidesInput>>;
  delete?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BuildingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  set?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  update?: InputMaybe<Array<BuildingUpdateWithWhereUniqueWithoutFeaturedSlidesInput>>;
  updateMany?: InputMaybe<Array<BuildingUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<BuildingUpsertWithWhereUniqueWithoutFeaturedSlidesInput>>;
};

export type BuildingUpdateOneInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BuildingUpdateDataInput>;
  upsert?: InputMaybe<BuildingUpsertNestedInput>;
};

export type BuildingUpdateOneRequiredInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateInput>;
  update?: InputMaybe<BuildingUpdateDataInput>;
  upsert?: InputMaybe<BuildingUpsertNestedInput>;
};

export type BuildingUpdateOneRequiredWithoutBuildingSettingInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateWithoutBuildingSettingInput>;
  update?: InputMaybe<BuildingUpdateWithoutBuildingSettingDataInput>;
  upsert?: InputMaybe<BuildingUpsertWithoutBuildingSettingInput>;
};

export type BuildingUpdateOneRequiredWithoutUnitsInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateWithoutUnitsInput>;
  update?: InputMaybe<BuildingUpdateWithoutUnitsDataInput>;
  upsert?: InputMaybe<BuildingUpsertWithoutUnitsInput>;
};

export type BuildingUpdateOneWithoutUsersInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  create?: InputMaybe<BuildingCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BuildingUpdateWithoutUsersDataInput>;
  upsert?: InputMaybe<BuildingUpsertWithoutUsersInput>;
};

export type BuildingUpdateWithWhereUniqueWithoutFeaturedSlidesInput = {
  data: BuildingUpdateWithoutFeaturedSlidesDataInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingUpdateWithoutBuildingSettingDataInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideUpdateManyWithoutBuildingsInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<UnitUpdateManyWithoutBuildingInput>;
  users?: InputMaybe<UserUpdateManyWithoutComputedBuildingInput>;
};

export type BuildingUpdateWithoutFeaturedSlidesDataInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingSetting?: InputMaybe<BuildingSettingUpdateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<UnitUpdateManyWithoutBuildingInput>;
  users?: InputMaybe<UserUpdateManyWithoutComputedBuildingInput>;
};

export type BuildingUpdateWithoutUnitsDataInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingSetting?: InputMaybe<BuildingSettingUpdateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideUpdateManyWithoutBuildingsInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<UserUpdateManyWithoutComputedBuildingInput>;
};

export type BuildingUpdateWithoutUsersDataInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingSetting?: InputMaybe<BuildingSettingUpdateOneWithoutBuildingInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  featuredSlides?: InputMaybe<FeaturedSlideUpdateManyWithoutBuildingsInput>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  officeAddress?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  propertyManagementFees?: InputMaybe<Scalars['Json']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  section?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<UnitUpdateManyWithoutBuildingInput>;
};

export type BuildingUpsertNestedInput = {
  create: BuildingCreateInput;
  update: BuildingUpdateDataInput;
};

export type BuildingUpsertWithWhereUniqueWithoutFeaturedSlidesInput = {
  create: BuildingCreateWithoutFeaturedSlidesInput;
  update: BuildingUpdateWithoutFeaturedSlidesDataInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingUpsertWithoutBuildingSettingInput = {
  create: BuildingCreateWithoutBuildingSettingInput;
  update: BuildingUpdateWithoutBuildingSettingDataInput;
};

export type BuildingUpsertWithoutUnitsInput = {
  create: BuildingCreateWithoutUnitsInput;
  update: BuildingUpdateWithoutUnitsDataInput;
};

export type BuildingUpsertWithoutUsersInput = {
  create: BuildingCreateWithoutUsersInput;
  update: BuildingUpdateWithoutUsersDataInput;
};

export type BuildingWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<BuildingWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<BuildingWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<BuildingWhereContractsPersistencyInput>>;
  address?: InputMaybe<AddressWhereContractsPersistencyInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingNumber_gt?: InputMaybe<Scalars['Int']>;
  buildingNumber_gte?: InputMaybe<Scalars['Int']>;
  buildingNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingNumber_lt?: InputMaybe<Scalars['Int']>;
  buildingNumber_lte?: InputMaybe<Scalars['Int']>;
  buildingNumber_not?: InputMaybe<Scalars['Int']>;
  buildingNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingStatus?: InputMaybe<ContractsPersistencyBuildingStatus>;
  buildingStatus_in?: InputMaybe<Array<ContractsPersistencyBuildingStatus>>;
  buildingStatus_not?: InputMaybe<ContractsPersistencyBuildingStatus>;
  buildingStatus_not_in?: InputMaybe<Array<ContractsPersistencyBuildingStatus>>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  events_every?: InputMaybe<EventWhereContractsPersistencyInput>;
  events_none?: InputMaybe<EventWhereContractsPersistencyInput>;
  events_some?: InputMaybe<EventWhereContractsPersistencyInput>;
  featuredSlides_every?: InputMaybe<FeaturedSlideWhereContractsPersistencyInput>;
  featuredSlides_none?: InputMaybe<FeaturedSlideWhereContractsPersistencyInput>;
  featuredSlides_some?: InputMaybe<FeaturedSlideWhereContractsPersistencyInput>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  landlordContractType?: InputMaybe<ContractsPersistencyLandlordContractType>;
  landlordContractType_in?: InputMaybe<Array<ContractsPersistencyLandlordContractType>>;
  landlordContractType_not?: InputMaybe<ContractsPersistencyLandlordContractType>;
  landlordContractType_not_in?: InputMaybe<Array<ContractsPersistencyLandlordContractType>>;
  locks_every?: InputMaybe<LockWhereContractsPersistencyInput>;
  locks_none?: InputMaybe<LockWhereContractsPersistencyInput>;
  locks_some?: InputMaybe<LockWhereContractsPersistencyInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_in?: InputMaybe<Array<Scalars['Int']>>;
  numberOfFloors_lt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_lte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not_in?: InputMaybe<Array<Scalars['Int']>>;
  parcel?: InputMaybe<Scalars['String']>;
  parcel_contains?: InputMaybe<Scalars['String']>;
  parcel_ends_with?: InputMaybe<Scalars['String']>;
  parcel_gt?: InputMaybe<Scalars['String']>;
  parcel_gte?: InputMaybe<Scalars['String']>;
  parcel_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_lt?: InputMaybe<Scalars['String']>;
  parcel_lte?: InputMaybe<Scalars['String']>;
  parcel_not?: InputMaybe<Scalars['String']>;
  parcel_not_contains?: InputMaybe<Scalars['String']>;
  parcel_not_ends_with?: InputMaybe<Scalars['String']>;
  parcel_not_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_not_starts_with?: InputMaybe<Scalars['String']>;
  parcel_starts_with?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  plot_contains?: InputMaybe<Scalars['String']>;
  plot_ends_with?: InputMaybe<Scalars['String']>;
  plot_gt?: InputMaybe<Scalars['String']>;
  plot_gte?: InputMaybe<Scalars['String']>;
  plot_in?: InputMaybe<Array<Scalars['String']>>;
  plot_lt?: InputMaybe<Scalars['String']>;
  plot_lte?: InputMaybe<Scalars['String']>;
  plot_not?: InputMaybe<Scalars['String']>;
  plot_not_contains?: InputMaybe<Scalars['String']>;
  plot_not_ends_with?: InputMaybe<Scalars['String']>;
  plot_not_in?: InputMaybe<Array<Scalars['String']>>;
  plot_not_starts_with?: InputMaybe<Scalars['String']>;
  plot_starts_with?: InputMaybe<Scalars['String']>;
  section?: InputMaybe<Scalars['String']>;
  section_contains?: InputMaybe<Scalars['String']>;
  section_ends_with?: InputMaybe<Scalars['String']>;
  section_gt?: InputMaybe<Scalars['String']>;
  section_gte?: InputMaybe<Scalars['String']>;
  section_in?: InputMaybe<Array<Scalars['String']>>;
  section_lt?: InputMaybe<Scalars['String']>;
  section_lte?: InputMaybe<Scalars['String']>;
  section_not?: InputMaybe<Scalars['String']>;
  section_not_contains?: InputMaybe<Scalars['String']>;
  section_not_ends_with?: InputMaybe<Scalars['String']>;
  section_not_in?: InputMaybe<Array<Scalars['String']>>;
  section_not_starts_with?: InputMaybe<Scalars['String']>;
  section_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  units_every?: InputMaybe<UnitWhereContractsPersistencyInput>;
  units_none?: InputMaybe<UnitWhereContractsPersistencyInput>;
  units_some?: InputMaybe<UnitWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BuildingWhereInput = {
  AND?: InputMaybe<Array<BuildingWhereInput>>;
  NOT?: InputMaybe<Array<BuildingWhereInput>>;
  OR?: InputMaybe<Array<BuildingWhereInput>>;
  address?: InputMaybe<AddressWhereInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingNumber_gt?: InputMaybe<Scalars['Int']>;
  buildingNumber_gte?: InputMaybe<Scalars['Int']>;
  buildingNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingNumber_lt?: InputMaybe<Scalars['Int']>;
  buildingNumber_lte?: InputMaybe<Scalars['Int']>;
  buildingNumber_not?: InputMaybe<Scalars['Int']>;
  buildingNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingSetting?: InputMaybe<BuildingSettingWhereInput>;
  buildingStatus?: InputMaybe<BuildingStatus>;
  buildingStatus_in?: InputMaybe<Array<BuildingStatus>>;
  buildingStatus_not?: InputMaybe<BuildingStatus>;
  buildingStatus_not_in?: InputMaybe<Array<BuildingStatus>>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactEmail_contains?: InputMaybe<Scalars['String']>;
  contactEmail_ends_with?: InputMaybe<Scalars['String']>;
  contactEmail_gt?: InputMaybe<Scalars['String']>;
  contactEmail_gte?: InputMaybe<Scalars['String']>;
  contactEmail_in?: InputMaybe<Array<Scalars['String']>>;
  contactEmail_lt?: InputMaybe<Scalars['String']>;
  contactEmail_lte?: InputMaybe<Scalars['String']>;
  contactEmail_not?: InputMaybe<Scalars['String']>;
  contactEmail_not_contains?: InputMaybe<Scalars['String']>;
  contactEmail_not_ends_with?: InputMaybe<Scalars['String']>;
  contactEmail_not_in?: InputMaybe<Array<Scalars['String']>>;
  contactEmail_not_starts_with?: InputMaybe<Scalars['String']>;
  contactEmail_starts_with?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactPhone_contains?: InputMaybe<Scalars['String']>;
  contactPhone_ends_with?: InputMaybe<Scalars['String']>;
  contactPhone_gt?: InputMaybe<Scalars['String']>;
  contactPhone_gte?: InputMaybe<Scalars['String']>;
  contactPhone_in?: InputMaybe<Array<Scalars['String']>>;
  contactPhone_lt?: InputMaybe<Scalars['String']>;
  contactPhone_lte?: InputMaybe<Scalars['String']>;
  contactPhone_not?: InputMaybe<Scalars['String']>;
  contactPhone_not_contains?: InputMaybe<Scalars['String']>;
  contactPhone_not_ends_with?: InputMaybe<Scalars['String']>;
  contactPhone_not_in?: InputMaybe<Array<Scalars['String']>>;
  contactPhone_not_starts_with?: InputMaybe<Scalars['String']>;
  contactPhone_starts_with?: InputMaybe<Scalars['String']>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  coordinate_intersects?: InputMaybe<PolygonInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  featuredSlides_every?: InputMaybe<FeaturedSlideWhereInput>;
  featuredSlides_none?: InputMaybe<FeaturedSlideWhereInput>;
  featuredSlides_some?: InputMaybe<FeaturedSlideWhereInput>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  landlordContractType?: InputMaybe<LandlordContractType>;
  landlordContractType_in?: InputMaybe<Array<LandlordContractType>>;
  landlordContractType_not?: InputMaybe<LandlordContractType>;
  landlordContractType_not_in?: InputMaybe<Array<LandlordContractType>>;
  legalName?: InputMaybe<Scalars['String']>;
  legalName_contains?: InputMaybe<Scalars['String']>;
  legalName_ends_with?: InputMaybe<Scalars['String']>;
  legalName_gt?: InputMaybe<Scalars['String']>;
  legalName_gte?: InputMaybe<Scalars['String']>;
  legalName_in?: InputMaybe<Array<Scalars['String']>>;
  legalName_lt?: InputMaybe<Scalars['String']>;
  legalName_lte?: InputMaybe<Scalars['String']>;
  legalName_not?: InputMaybe<Scalars['String']>;
  legalName_not_contains?: InputMaybe<Scalars['String']>;
  legalName_not_ends_with?: InputMaybe<Scalars['String']>;
  legalName_not_in?: InputMaybe<Array<Scalars['String']>>;
  legalName_not_starts_with?: InputMaybe<Scalars['String']>;
  legalName_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  near_by?: InputMaybe<BuildingNearByInput>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_in?: InputMaybe<Array<Scalars['Int']>>;
  numberOfFloors_lt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_lte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not_in?: InputMaybe<Array<Scalars['Int']>>;
  officeAddress?: InputMaybe<Scalars['String']>;
  officeAddress_contains?: InputMaybe<Scalars['String']>;
  officeAddress_ends_with?: InputMaybe<Scalars['String']>;
  officeAddress_gt?: InputMaybe<Scalars['String']>;
  officeAddress_gte?: InputMaybe<Scalars['String']>;
  officeAddress_in?: InputMaybe<Array<Scalars['String']>>;
  officeAddress_lt?: InputMaybe<Scalars['String']>;
  officeAddress_lte?: InputMaybe<Scalars['String']>;
  officeAddress_not?: InputMaybe<Scalars['String']>;
  officeAddress_not_contains?: InputMaybe<Scalars['String']>;
  officeAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  officeAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  officeAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  officeAddress_starts_with?: InputMaybe<Scalars['String']>;
  parcel?: InputMaybe<Scalars['String']>;
  parcel_contains?: InputMaybe<Scalars['String']>;
  parcel_ends_with?: InputMaybe<Scalars['String']>;
  parcel_gt?: InputMaybe<Scalars['String']>;
  parcel_gte?: InputMaybe<Scalars['String']>;
  parcel_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_lt?: InputMaybe<Scalars['String']>;
  parcel_lte?: InputMaybe<Scalars['String']>;
  parcel_not?: InputMaybe<Scalars['String']>;
  parcel_not_contains?: InputMaybe<Scalars['String']>;
  parcel_not_ends_with?: InputMaybe<Scalars['String']>;
  parcel_not_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_not_starts_with?: InputMaybe<Scalars['String']>;
  parcel_starts_with?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  plot_contains?: InputMaybe<Scalars['String']>;
  plot_ends_with?: InputMaybe<Scalars['String']>;
  plot_gt?: InputMaybe<Scalars['String']>;
  plot_gte?: InputMaybe<Scalars['String']>;
  plot_in?: InputMaybe<Array<Scalars['String']>>;
  plot_lt?: InputMaybe<Scalars['String']>;
  plot_lte?: InputMaybe<Scalars['String']>;
  plot_not?: InputMaybe<Scalars['String']>;
  plot_not_contains?: InputMaybe<Scalars['String']>;
  plot_not_ends_with?: InputMaybe<Scalars['String']>;
  plot_not_in?: InputMaybe<Array<Scalars['String']>>;
  plot_not_starts_with?: InputMaybe<Scalars['String']>;
  plot_starts_with?: InputMaybe<Scalars['String']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeWhereInput>;
  section?: InputMaybe<Scalars['String']>;
  section_contains?: InputMaybe<Scalars['String']>;
  section_ends_with?: InputMaybe<Scalars['String']>;
  section_gt?: InputMaybe<Scalars['String']>;
  section_gte?: InputMaybe<Scalars['String']>;
  section_in?: InputMaybe<Array<Scalars['String']>>;
  section_lt?: InputMaybe<Scalars['String']>;
  section_lte?: InputMaybe<Scalars['String']>;
  section_not?: InputMaybe<Scalars['String']>;
  section_not_contains?: InputMaybe<Scalars['String']>;
  section_not_ends_with?: InputMaybe<Scalars['String']>;
  section_not_in?: InputMaybe<Array<Scalars['String']>>;
  section_not_starts_with?: InputMaybe<Scalars['String']>;
  section_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  units_every?: InputMaybe<UnitWhereInput>;
  units_none?: InputMaybe<UnitWhereInput>;
  units_some?: InputMaybe<UnitWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  users_every?: InputMaybe<UserWhereInput>;
  users_none?: InputMaybe<UserWhereInput>;
  users_some?: InputMaybe<UserWhereInput>;
};

export type BuildingWhereInputPersistency = {
  AND?: InputMaybe<Array<BuildingWhereInputPersistency>>;
  NOT?: InputMaybe<Array<BuildingWhereInputPersistency>>;
  OR?: InputMaybe<Array<BuildingWhereInputPersistency>>;
  address?: InputMaybe<AddressWhereInputPersistency>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingNumber_gt?: InputMaybe<Scalars['Int']>;
  buildingNumber_gte?: InputMaybe<Scalars['Int']>;
  buildingNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingNumber_lt?: InputMaybe<Scalars['Int']>;
  buildingNumber_lte?: InputMaybe<Scalars['Int']>;
  buildingNumber_not?: InputMaybe<Scalars['Int']>;
  buildingNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingStatus?: InputMaybe<BuildingStatusPersistency>;
  buildingStatus_in?: InputMaybe<Array<BuildingStatusPersistency>>;
  buildingStatus_not?: InputMaybe<BuildingStatusPersistency>;
  buildingStatus_not_in?: InputMaybe<Array<BuildingStatusPersistency>>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInputPersistency>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  landlordContractType?: InputMaybe<LandlordContractTypePersistency>;
  landlordContractType_in?: InputMaybe<Array<LandlordContractTypePersistency>>;
  landlordContractType_not?: InputMaybe<LandlordContractTypePersistency>;
  landlordContractType_not_in?: InputMaybe<Array<LandlordContractTypePersistency>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_in?: InputMaybe<Array<Scalars['Int']>>;
  numberOfFloors_lt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_lte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not_in?: InputMaybe<Array<Scalars['Int']>>;
  parcel?: InputMaybe<Scalars['String']>;
  parcel_contains?: InputMaybe<Scalars['String']>;
  parcel_ends_with?: InputMaybe<Scalars['String']>;
  parcel_gt?: InputMaybe<Scalars['String']>;
  parcel_gte?: InputMaybe<Scalars['String']>;
  parcel_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_lt?: InputMaybe<Scalars['String']>;
  parcel_lte?: InputMaybe<Scalars['String']>;
  parcel_not?: InputMaybe<Scalars['String']>;
  parcel_not_contains?: InputMaybe<Scalars['String']>;
  parcel_not_ends_with?: InputMaybe<Scalars['String']>;
  parcel_not_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_not_starts_with?: InputMaybe<Scalars['String']>;
  parcel_starts_with?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  plot_contains?: InputMaybe<Scalars['String']>;
  plot_ends_with?: InputMaybe<Scalars['String']>;
  plot_gt?: InputMaybe<Scalars['String']>;
  plot_gte?: InputMaybe<Scalars['String']>;
  plot_in?: InputMaybe<Array<Scalars['String']>>;
  plot_lt?: InputMaybe<Scalars['String']>;
  plot_lte?: InputMaybe<Scalars['String']>;
  plot_not?: InputMaybe<Scalars['String']>;
  plot_not_contains?: InputMaybe<Scalars['String']>;
  plot_not_ends_with?: InputMaybe<Scalars['String']>;
  plot_not_in?: InputMaybe<Array<Scalars['String']>>;
  plot_not_starts_with?: InputMaybe<Scalars['String']>;
  plot_starts_with?: InputMaybe<Scalars['String']>;
  section?: InputMaybe<Scalars['String']>;
  section_contains?: InputMaybe<Scalars['String']>;
  section_ends_with?: InputMaybe<Scalars['String']>;
  section_gt?: InputMaybe<Scalars['String']>;
  section_gte?: InputMaybe<Scalars['String']>;
  section_in?: InputMaybe<Array<Scalars['String']>>;
  section_lt?: InputMaybe<Scalars['String']>;
  section_lte?: InputMaybe<Scalars['String']>;
  section_not?: InputMaybe<Scalars['String']>;
  section_not_contains?: InputMaybe<Scalars['String']>;
  section_not_ends_with?: InputMaybe<Scalars['String']>;
  section_not_in?: InputMaybe<Array<Scalars['String']>>;
  section_not_starts_with?: InputMaybe<Scalars['String']>;
  section_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  units_every?: InputMaybe<UnitWhereInputPersistency>;
  units_none?: InputMaybe<UnitWhereInputPersistency>;
  units_some?: InputMaybe<UnitWhereInputPersistency>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BuildingWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<BuildingWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<BuildingWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<BuildingWherePersistencyContractsInput>>;
  address?: InputMaybe<AddressWherePersistencyContractsInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['Int']>;
  buildingNumber_gt?: InputMaybe<Scalars['Int']>;
  buildingNumber_gte?: InputMaybe<Scalars['Int']>;
  buildingNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingNumber_lt?: InputMaybe<Scalars['Int']>;
  buildingNumber_lte?: InputMaybe<Scalars['Int']>;
  buildingNumber_not?: InputMaybe<Scalars['Int']>;
  buildingNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  buildingStatus?: InputMaybe<PersistencyContractsBuildingStatus>;
  buildingStatus_in?: InputMaybe<Array<PersistencyContractsBuildingStatus>>;
  buildingStatus_not?: InputMaybe<PersistencyContractsBuildingStatus>;
  buildingStatus_not_in?: InputMaybe<Array<PersistencyContractsBuildingStatus>>;
  contractEndDate?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not?: InputMaybe<Scalars['DateTime']>;
  contractEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractSignedDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not?: InputMaybe<Scalars['DateTime']>;
  contractSignedDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  contractStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not?: InputMaybe<Scalars['DateTime']>;
  contractStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  events_every?: InputMaybe<EventWherePersistencyContractsInput>;
  events_none?: InputMaybe<EventWherePersistencyContractsInput>;
  events_some?: InputMaybe<EventWherePersistencyContractsInput>;
  featuredSlides_every?: InputMaybe<FeaturedSlideWherePersistencyContractsInput>;
  featuredSlides_none?: InputMaybe<FeaturedSlideWherePersistencyContractsInput>;
  featuredSlides_some?: InputMaybe<FeaturedSlideWherePersistencyContractsInput>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  landlordContractType?: InputMaybe<PersistencyContractsLandlordContractType>;
  landlordContractType_in?: InputMaybe<Array<PersistencyContractsLandlordContractType>>;
  landlordContractType_not?: InputMaybe<PersistencyContractsLandlordContractType>;
  landlordContractType_not_in?: InputMaybe<Array<PersistencyContractsLandlordContractType>>;
  locks_every?: InputMaybe<LockWherePersistencyContractsInput>;
  locks_none?: InputMaybe<LockWherePersistencyContractsInput>;
  locks_some?: InputMaybe<LockWherePersistencyContractsInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  numberOfFloors?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_gte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_in?: InputMaybe<Array<Scalars['Int']>>;
  numberOfFloors_lt?: InputMaybe<Scalars['Int']>;
  numberOfFloors_lte?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not?: InputMaybe<Scalars['Int']>;
  numberOfFloors_not_in?: InputMaybe<Array<Scalars['Int']>>;
  parcel?: InputMaybe<Scalars['String']>;
  parcel_contains?: InputMaybe<Scalars['String']>;
  parcel_ends_with?: InputMaybe<Scalars['String']>;
  parcel_gt?: InputMaybe<Scalars['String']>;
  parcel_gte?: InputMaybe<Scalars['String']>;
  parcel_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_lt?: InputMaybe<Scalars['String']>;
  parcel_lte?: InputMaybe<Scalars['String']>;
  parcel_not?: InputMaybe<Scalars['String']>;
  parcel_not_contains?: InputMaybe<Scalars['String']>;
  parcel_not_ends_with?: InputMaybe<Scalars['String']>;
  parcel_not_in?: InputMaybe<Array<Scalars['String']>>;
  parcel_not_starts_with?: InputMaybe<Scalars['String']>;
  parcel_starts_with?: InputMaybe<Scalars['String']>;
  plot?: InputMaybe<Scalars['String']>;
  plot_contains?: InputMaybe<Scalars['String']>;
  plot_ends_with?: InputMaybe<Scalars['String']>;
  plot_gt?: InputMaybe<Scalars['String']>;
  plot_gte?: InputMaybe<Scalars['String']>;
  plot_in?: InputMaybe<Array<Scalars['String']>>;
  plot_lt?: InputMaybe<Scalars['String']>;
  plot_lte?: InputMaybe<Scalars['String']>;
  plot_not?: InputMaybe<Scalars['String']>;
  plot_not_contains?: InputMaybe<Scalars['String']>;
  plot_not_ends_with?: InputMaybe<Scalars['String']>;
  plot_not_in?: InputMaybe<Array<Scalars['String']>>;
  plot_not_starts_with?: InputMaybe<Scalars['String']>;
  plot_starts_with?: InputMaybe<Scalars['String']>;
  section?: InputMaybe<Scalars['String']>;
  section_contains?: InputMaybe<Scalars['String']>;
  section_ends_with?: InputMaybe<Scalars['String']>;
  section_gt?: InputMaybe<Scalars['String']>;
  section_gte?: InputMaybe<Scalars['String']>;
  section_in?: InputMaybe<Array<Scalars['String']>>;
  section_lt?: InputMaybe<Scalars['String']>;
  section_lte?: InputMaybe<Scalars['String']>;
  section_not?: InputMaybe<Scalars['String']>;
  section_not_contains?: InputMaybe<Scalars['String']>;
  section_not_ends_with?: InputMaybe<Scalars['String']>;
  section_not_in?: InputMaybe<Array<Scalars['String']>>;
  section_not_starts_with?: InputMaybe<Scalars['String']>;
  section_starts_with?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetName_contains?: InputMaybe<Scalars['String']>;
  streetName_ends_with?: InputMaybe<Scalars['String']>;
  streetName_gt?: InputMaybe<Scalars['String']>;
  streetName_gte?: InputMaybe<Scalars['String']>;
  streetName_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_lt?: InputMaybe<Scalars['String']>;
  streetName_lte?: InputMaybe<Scalars['String']>;
  streetName_not?: InputMaybe<Scalars['String']>;
  streetName_not_contains?: InputMaybe<Scalars['String']>;
  streetName_not_ends_with?: InputMaybe<Scalars['String']>;
  streetName_not_in?: InputMaybe<Array<Scalars['String']>>;
  streetName_not_starts_with?: InputMaybe<Scalars['String']>;
  streetName_starts_with?: InputMaybe<Scalars['String']>;
  units_every?: InputMaybe<UnitWherePersistencyContractsInput>;
  units_none?: InputMaybe<UnitWherePersistencyContractsInput>;
  units_some?: InputMaybe<UnitWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type BuildingWhereUniqueInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Byline = {
  __typename?: 'Byline';
  count?: Maybe<Scalars['Int']>;
  type?: Maybe<BylineType>;
  value?: Maybe<Scalars['String']>;
};

export enum BylineType {
  COMMENT = 'COMMENT',
  DISCUSSION = 'DISCUSSION',
  EVENT_CHECKED_IN = 'EVENT_CHECKED_IN',
  GOOD_VIBES = 'GOOD_VIBES',
  HAVE_PET_BOTH = 'HAVE_PET_BOTH',
  LIKE = 'LIKE',
  NEW_NEIGHBOR = 'NEW_NEIGHBOR',
  NEW_NEIGHBOR_BOTH = 'NEW_NEIGHBOR_BOTH',
  SAME_BUILDING = 'SAME_BUILDING',
  SAME_GROUP = 'SAME_GROUP',
  SAME_INDUSTRY_OCCUPATION = 'SAME_INDUSTRY_OCCUPATION',
  SAME_MOVE_IN_DAY = 'SAME_MOVE_IN_DAY',
  VETERAN_NEIGHBOR = 'VETERAN_NEIGHBOR',
  VETERAN_NEIGHBOR_BOTH = 'VETERAN_NEIGHBOR_BOTH'
}

export enum ContractType {
  LEASECONTRACT = 'LeaseContract',
  MEMBERSHIPCONTRACT = 'MembershipContract'
}

export type CalculateTransactionFeeInput = {
  addTransactionConstant: Scalars['Boolean'];
  amount: Scalars['Float'];
  paymentMethodType: PaymentMethodType;
};

export type CalculateTransactionFeeResponse = {
  __typename?: 'CalculateTransactionFeeResponse';
  amount: Scalars['Float'];
  paymentMethodType: PaymentMethodType;
  percentage: Scalars['Float'];
  transactionConstant?: Maybe<Scalars['Float']>;
};

export type CalculateTransferFeeInput = {
  addTransferConstant: Scalars['Boolean'];
  amount: Scalars['Float'];
};

export type CalculateTransferFeeResponse = {
  __typename?: 'CalculateTransferFeeResponse';
  amount: Scalars['Float'];
  percentage: Scalars['Float'];
  transferConstant?: Maybe<Scalars['Float']>;
};

export type Calendar = {
  __typename?: 'Calendar';
  createdAt: Scalars['DateTime'];
  cutoffTime?: Maybe<Scalars['Float']>;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood?: Maybe<Hood>;
  hoodName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intervalDuration: Scalars['Int'];
  maxAmountSeats?: Maybe<Scalars['Int']>;
  quotaMaxDaysAhead: Scalars['Int'];
  quotaMaxPerDay: Scalars['Int'];
  quotaMaxPerWeek: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type CalendarBookable = {
  calendar?: Maybe<Calendar>;
  openingHours?: Maybe<Scalars['String']>;
};

export type CalendarBookableInput = {
  id: Scalars['ID'];
  openingHours?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CalendarBookableType>;
};

export enum CalendarBookableType {
  CALENDAR = 'CALENDAR',
  SERVICE_PRODUCT = 'SERVICE_PRODUCT',
  SHARED_SPACE = 'SHARED_SPACE'
}

export type CalendarBooking = {
  __typename?: 'CalendarBooking';
  calendar: Calendar;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  owner?: Maybe<User>;
  ownerId: Scalars['String'];
  startTime: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CalendarBookingConnection = {
  __typename?: 'CalendarBookingConnection';
  aggregate: AggregateCalendarBooking;
  edges: Array<Maybe<CalendarBookingEdge>>;
  pageInfo: PageInfo;
};

export type CalendarBookingCreateInput = {
  calendar: CalendarCreateOneInput;
  comment?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endTime: Scalars['DateTime'];
  ownerId: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type CalendarBookingEdge = {
  __typename?: 'CalendarBookingEdge';
  cursor: Scalars['String'];
  node: CalendarBooking;
};

export enum CalendarBookingOrderByInput {
  COMMENT_ASC = 'comment_ASC',
  COMMENT_DESC = 'comment_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENDTIME_ASC = 'endTime_ASC',
  ENDTIME_DESC = 'endTime_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  OWNERID_ASC = 'ownerId_ASC',
  OWNERID_DESC = 'ownerId_DESC',
  STARTTIME_ASC = 'startTime_ASC',
  STARTTIME_DESC = 'startTime_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type CalendarBookingUpdateInput = {
  calendar?: InputMaybe<CalendarUpdateOneRequiredInput>;
  comment?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  ownerId?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type CalendarBookingWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<CalendarBookingWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<CalendarBookingWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<CalendarBookingWhereContractsPersistencyInput>>;
  calendar?: InputMaybe<CalendarWhereContractsPersistencyInput>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  endTime_gt?: InputMaybe<Scalars['DateTime']>;
  endTime_gte?: InputMaybe<Scalars['DateTime']>;
  endTime_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endTime_lt?: InputMaybe<Scalars['DateTime']>;
  endTime_lte?: InputMaybe<Scalars['DateTime']>;
  endTime_not?: InputMaybe<Scalars['DateTime']>;
  endTime_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  owner?: InputMaybe<UserWhereContractsPersistencyInput>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  startTime_gt?: InputMaybe<Scalars['DateTime']>;
  startTime_gte?: InputMaybe<Scalars['DateTime']>;
  startTime_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startTime_lt?: InputMaybe<Scalars['DateTime']>;
  startTime_lte?: InputMaybe<Scalars['DateTime']>;
  startTime_not?: InputMaybe<Scalars['DateTime']>;
  startTime_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CalendarBookingWhereInput = {
  AND?: InputMaybe<Array<CalendarBookingWhereInput>>;
  NOT?: InputMaybe<Array<CalendarBookingWhereInput>>;
  OR?: InputMaybe<Array<CalendarBookingWhereInput>>;
  calendar?: InputMaybe<CalendarWhereInput>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  endTime_gt?: InputMaybe<Scalars['DateTime']>;
  endTime_gte?: InputMaybe<Scalars['DateTime']>;
  endTime_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endTime_lt?: InputMaybe<Scalars['DateTime']>;
  endTime_lte?: InputMaybe<Scalars['DateTime']>;
  endTime_not?: InputMaybe<Scalars['DateTime']>;
  endTime_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  ownerId?: InputMaybe<Scalars['String']>;
  ownerId_contains?: InputMaybe<Scalars['String']>;
  ownerId_ends_with?: InputMaybe<Scalars['String']>;
  ownerId_gt?: InputMaybe<Scalars['String']>;
  ownerId_gte?: InputMaybe<Scalars['String']>;
  ownerId_in?: InputMaybe<Array<Scalars['String']>>;
  ownerId_lt?: InputMaybe<Scalars['String']>;
  ownerId_lte?: InputMaybe<Scalars['String']>;
  ownerId_not?: InputMaybe<Scalars['String']>;
  ownerId_not_contains?: InputMaybe<Scalars['String']>;
  ownerId_not_ends_with?: InputMaybe<Scalars['String']>;
  ownerId_not_in?: InputMaybe<Array<Scalars['String']>>;
  ownerId_not_starts_with?: InputMaybe<Scalars['String']>;
  ownerId_starts_with?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  startTime_gt?: InputMaybe<Scalars['DateTime']>;
  startTime_gte?: InputMaybe<Scalars['DateTime']>;
  startTime_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startTime_lt?: InputMaybe<Scalars['DateTime']>;
  startTime_lte?: InputMaybe<Scalars['DateTime']>;
  startTime_not?: InputMaybe<Scalars['DateTime']>;
  startTime_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CalendarBookingWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<CalendarBookingWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<CalendarBookingWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<CalendarBookingWherePersistencyContractsInput>>;
  calendar?: InputMaybe<CalendarWherePersistencyContractsInput>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  endTime_gt?: InputMaybe<Scalars['DateTime']>;
  endTime_gte?: InputMaybe<Scalars['DateTime']>;
  endTime_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endTime_lt?: InputMaybe<Scalars['DateTime']>;
  endTime_lte?: InputMaybe<Scalars['DateTime']>;
  endTime_not?: InputMaybe<Scalars['DateTime']>;
  endTime_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  owner?: InputMaybe<UserWherePersistencyContractsInput>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  startTime_gt?: InputMaybe<Scalars['DateTime']>;
  startTime_gte?: InputMaybe<Scalars['DateTime']>;
  startTime_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startTime_lt?: InputMaybe<Scalars['DateTime']>;
  startTime_lte?: InputMaybe<Scalars['DateTime']>;
  startTime_not?: InputMaybe<Scalars['DateTime']>;
  startTime_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CalendarBookingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CalendarConnection = {
  __typename?: 'CalendarConnection';
  aggregate: AggregateCalendar;
  edges: Array<Maybe<CalendarEdge>>;
  pageInfo: PageInfo;
};

export type CalendarCreateInput = {
  cutoffTime?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName?: InputMaybe<Scalars['String']>;
  intervalDuration: Scalars['Int'];
  maxAmountSeats?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead: Scalars['Int'];
  quotaMaxPerDay: Scalars['Int'];
  quotaMaxPerWeek: Scalars['Int'];
};

export type CalendarCreateOneInput = {
  connect?: InputMaybe<CalendarWhereUniqueInput>;
  create?: InputMaybe<CalendarCreateInput>;
};

export type CalendarEdge = {
  __typename?: 'CalendarEdge';
  cursor: Scalars['String'];
  node: Calendar;
};

export enum CalendarOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  CUTOFFTIME_ASC = 'cutoffTime_ASC',
  CUTOFFTIME_DESC = 'cutoffTime_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  HOODNAME_ASC = 'hoodName_ASC',
  HOODNAME_DESC = 'hoodName_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  INTERVALDURATION_ASC = 'intervalDuration_ASC',
  INTERVALDURATION_DESC = 'intervalDuration_DESC',
  MAXAMOUNTSEATS_ASC = 'maxAmountSeats_ASC',
  MAXAMOUNTSEATS_DESC = 'maxAmountSeats_DESC',
  QUOTAMAXDAYSAHEAD_ASC = 'quotaMaxDaysAhead_ASC',
  QUOTAMAXDAYSAHEAD_DESC = 'quotaMaxDaysAhead_DESC',
  QUOTAMAXPERDAY_ASC = 'quotaMaxPerDay_ASC',
  QUOTAMAXPERDAY_DESC = 'quotaMaxPerDay_DESC',
  QUOTAMAXPERWEEK_ASC = 'quotaMaxPerWeek_ASC',
  QUOTAMAXPERWEEK_DESC = 'quotaMaxPerWeek_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type CalendarTimeSlot = {
  __typename?: 'CalendarTimeSlot';
  available: Scalars['Boolean'];
  currentAmountSeats: Scalars['Int'];
  endTime: Scalars['DateTime'];
  maxAmountSeats: Scalars['Int'];
  startTime: Scalars['DateTime'];
};

export type CalendarTimeSlotInput = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type CalendarUpdateDataInput = {
  cutoffTime?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName?: InputMaybe<Scalars['String']>;
  intervalDuration?: InputMaybe<Scalars['Int']>;
  maxAmountSeats?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek?: InputMaybe<Scalars['Int']>;
};

export type CalendarUpdateInput = {
  cutoffTime?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName?: InputMaybe<Scalars['String']>;
  intervalDuration?: InputMaybe<Scalars['Int']>;
  maxAmountSeats?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek?: InputMaybe<Scalars['Int']>;
};

export type CalendarUpdateOneRequiredInput = {
  connect?: InputMaybe<CalendarWhereUniqueInput>;
  create?: InputMaybe<CalendarCreateInput>;
  update?: InputMaybe<CalendarUpdateDataInput>;
  upsert?: InputMaybe<CalendarUpsertNestedInput>;
};

export type CalendarUpsertNestedInput = {
  create: CalendarCreateInput;
  update: CalendarUpdateDataInput;
};

export type CalendarWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<CalendarWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<CalendarWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<CalendarWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  intervalDuration?: InputMaybe<Scalars['Int']>;
  intervalDuration_gt?: InputMaybe<Scalars['Int']>;
  intervalDuration_gte?: InputMaybe<Scalars['Int']>;
  intervalDuration_in?: InputMaybe<Array<Scalars['Int']>>;
  intervalDuration_lt?: InputMaybe<Scalars['Int']>;
  intervalDuration_lte?: InputMaybe<Scalars['Int']>;
  intervalDuration_not?: InputMaybe<Scalars['Int']>;
  intervalDuration_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxDaysAhead?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxDaysAhead_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_not?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerDay?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerDay_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_not?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerWeek?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerWeek_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_not?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_not_in?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CalendarWhereInput = {
  AND?: InputMaybe<Array<CalendarWhereInput>>;
  NOT?: InputMaybe<Array<CalendarWhereInput>>;
  OR?: InputMaybe<Array<CalendarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  cutoffTime?: InputMaybe<Scalars['Float']>;
  cutoffTime_gt?: InputMaybe<Scalars['Float']>;
  cutoffTime_gte?: InputMaybe<Scalars['Float']>;
  cutoffTime_in?: InputMaybe<Array<Scalars['Float']>>;
  cutoffTime_lt?: InputMaybe<Scalars['Float']>;
  cutoffTime_lte?: InputMaybe<Scalars['Float']>;
  cutoffTime_not?: InputMaybe<Scalars['Float']>;
  cutoffTime_not_in?: InputMaybe<Array<Scalars['Float']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hoodName?: InputMaybe<Scalars['String']>;
  hoodName_contains?: InputMaybe<Scalars['String']>;
  hoodName_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_gt?: InputMaybe<Scalars['String']>;
  hoodName_gte?: InputMaybe<Scalars['String']>;
  hoodName_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_lt?: InputMaybe<Scalars['String']>;
  hoodName_lte?: InputMaybe<Scalars['String']>;
  hoodName_not?: InputMaybe<Scalars['String']>;
  hoodName_not_contains?: InputMaybe<Scalars['String']>;
  hoodName_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  intervalDuration?: InputMaybe<Scalars['Int']>;
  intervalDuration_gt?: InputMaybe<Scalars['Int']>;
  intervalDuration_gte?: InputMaybe<Scalars['Int']>;
  intervalDuration_in?: InputMaybe<Array<Scalars['Int']>>;
  intervalDuration_lt?: InputMaybe<Scalars['Int']>;
  intervalDuration_lte?: InputMaybe<Scalars['Int']>;
  intervalDuration_not?: InputMaybe<Scalars['Int']>;
  intervalDuration_not_in?: InputMaybe<Array<Scalars['Int']>>;
  maxAmountSeats?: InputMaybe<Scalars['Int']>;
  maxAmountSeats_gt?: InputMaybe<Scalars['Int']>;
  maxAmountSeats_gte?: InputMaybe<Scalars['Int']>;
  maxAmountSeats_in?: InputMaybe<Array<Scalars['Int']>>;
  maxAmountSeats_lt?: InputMaybe<Scalars['Int']>;
  maxAmountSeats_lte?: InputMaybe<Scalars['Int']>;
  maxAmountSeats_not?: InputMaybe<Scalars['Int']>;
  maxAmountSeats_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxDaysAhead?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxDaysAhead_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_not?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerDay?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerDay_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_not?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerWeek?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerWeek_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_not?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_not_in?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CalendarWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<CalendarWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<CalendarWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<CalendarWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  intervalDuration?: InputMaybe<Scalars['Int']>;
  intervalDuration_gt?: InputMaybe<Scalars['Int']>;
  intervalDuration_gte?: InputMaybe<Scalars['Int']>;
  intervalDuration_in?: InputMaybe<Array<Scalars['Int']>>;
  intervalDuration_lt?: InputMaybe<Scalars['Int']>;
  intervalDuration_lte?: InputMaybe<Scalars['Int']>;
  intervalDuration_not?: InputMaybe<Scalars['Int']>;
  intervalDuration_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxDaysAhead?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxDaysAhead_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_not?: InputMaybe<Scalars['Int']>;
  quotaMaxDaysAhead_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerDay?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerDay_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_not?: InputMaybe<Scalars['Int']>;
  quotaMaxPerDay_not_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerWeek?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_gt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_gte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_in?: InputMaybe<Array<Scalars['Int']>>;
  quotaMaxPerWeek_lt?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_lte?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_not?: InputMaybe<Scalars['Int']>;
  quotaMaxPerWeek_not_in?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CalendarWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CancelAttendanceEventInput = {
  eventAttendeeId: Scalars['ID'];
};

export type CancelCalendarBookingResult = {
  __typename?: 'CancelCalendarBookingResult';
  calendarBooking?: Maybe<CalendarBooking>;
};

export type CheckIfTimeSlotsAvailableResult = {
  __typename?: 'CheckIfTimeSlotsAvailableResult';
  available: Scalars['Boolean'];
};

export enum ClassificationCategory {
  ACCEPTED = 'ACCEPTED',
  INAPPROPRIATE_LANGUAGE = 'INAPPROPRIATE_LANGUAGE',
  LANDLORD_COMPLAINT = 'LANDLORD_COMPLAINT'
}

export enum ClassificationEntityType {
  COMMENT = 'COMMENT',
  SOCIAL_MOMENT = 'SOCIAL_MOMENT'
}

export type Comment = {
  __typename?: 'Comment';
  _empty?: Maybe<Scalars['String']>;
  author: MemberProfile;
  classification?: Maybe<Array<Maybe<ClassificationCategory>>>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  message: Scalars['String'];
  originalEntityId: Scalars['String'];
  originalEntityName: Scalars['String'];
  socialReactionsAggregate?: Maybe<SocialReactionsAggregate>;
  updatedAt: Scalars['DateTime'];
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  aggregate: AggregateComment;
  edges: Array<Maybe<CommentEdge>>;
  pageInfo: PageInfo;
};

export type CommentCreateInput = {
  author: MemberProfileCreateOneInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<CommentCreateimagesInput>;
  message: Scalars['String'];
  originalEntityId: Scalars['String'];
  originalEntityName: Scalars['String'];
};

export type CommentCreateimagesInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String'];
  node: Comment;
};

export enum CommentOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  MESSAGE_ASC = 'message_ASC',
  MESSAGE_DESC = 'message_DESC',
  ORIGINALENTITYID_ASC = 'originalEntityId_ASC',
  ORIGINALENTITYID_DESC = 'originalEntityId_DESC',
  ORIGINALENTITYNAME_ASC = 'originalEntityName_ASC',
  ORIGINALENTITYNAME_DESC = 'originalEntityName_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type CommentUpdateInput = {
  author?: InputMaybe<MemberProfileUpdateOneRequiredInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<CommentUpdateimagesInput>;
  message?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
};

export type CommentUpdateimagesInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommentWhereInput = {
  AND?: InputMaybe<Array<CommentWhereInput>>;
  NOT?: InputMaybe<Array<CommentWhereInput>>;
  OR?: InputMaybe<Array<CommentWhereInput>>;
  author?: InputMaybe<MemberProfileWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  images_contains?: InputMaybe<Scalars['String']>;
  images_contains_every?: InputMaybe<Array<Scalars['String']>>;
  images_contains_some?: InputMaybe<Array<Scalars['String']>>;
  message?: InputMaybe<Scalars['String']>;
  message_contains?: InputMaybe<Scalars['String']>;
  message_ends_with?: InputMaybe<Scalars['String']>;
  message_gt?: InputMaybe<Scalars['String']>;
  message_gte?: InputMaybe<Scalars['String']>;
  message_in?: InputMaybe<Array<Scalars['String']>>;
  message_lt?: InputMaybe<Scalars['String']>;
  message_lte?: InputMaybe<Scalars['String']>;
  message_not?: InputMaybe<Scalars['String']>;
  message_not_contains?: InputMaybe<Scalars['String']>;
  message_not_ends_with?: InputMaybe<Scalars['String']>;
  message_not_in?: InputMaybe<Array<Scalars['String']>>;
  message_not_starts_with?: InputMaybe<Scalars['String']>;
  message_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityId_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_gt?: InputMaybe<Scalars['String']>;
  originalEntityId_gte?: InputMaybe<Scalars['String']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_lt?: InputMaybe<Scalars['String']>;
  originalEntityId_lte?: InputMaybe<Scalars['String']>;
  originalEntityId_not?: InputMaybe<Scalars['String']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  originalEntityName_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_gt?: InputMaybe<Scalars['String']>;
  originalEntityName_gte?: InputMaybe<Scalars['String']>;
  originalEntityName_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_lt?: InputMaybe<Scalars['String']>;
  originalEntityName_lte?: InputMaybe<Scalars['String']>;
  originalEntityName_not?: InputMaybe<Scalars['String']>;
  originalEntityName_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CommunityTitle = {
  __typename?: 'CommunityTitle';
  _empty?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  memberProfile?: Maybe<MemberProfile>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CommunityTitleConnection = {
  __typename?: 'CommunityTitleConnection';
  aggregate: AggregateCommunityTitle;
  edges: Array<Maybe<CommunityTitleEdge>>;
  pageInfo: PageInfo;
};

export type CommunityTitleCreateInput = {
  contextEntityId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  memberProfile?: InputMaybe<MemberProfileCreateOneWithoutCommunityTitlesInput>;
  title: Scalars['String'];
};

export type CommunityTitleCreateManyWithoutMemberProfileInput = {
  connect?: InputMaybe<Array<CommunityTitleWhereUniqueInput>>;
  create?: InputMaybe<Array<CommunityTitleCreateWithoutMemberProfileInput>>;
};

export type CommunityTitleCreateWithoutMemberProfileInput = {
  contextEntityId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CommunityTitleEdge = {
  __typename?: 'CommunityTitleEdge';
  cursor: Scalars['String'];
  node: CommunityTitle;
};

export enum CommunityTitleOrderByInput {
  CONTEXTENTITYID_ASC = 'contextEntityId_ASC',
  CONTEXTENTITYID_DESC = 'contextEntityId_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type CommunityTitleScalarWhereInput = {
  AND?: InputMaybe<Array<CommunityTitleScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommunityTitleScalarWhereInput>>;
  OR?: InputMaybe<Array<CommunityTitleScalarWhereInput>>;
  contextEntityId?: InputMaybe<Scalars['String']>;
  contextEntityId_contains?: InputMaybe<Scalars['String']>;
  contextEntityId_ends_with?: InputMaybe<Scalars['String']>;
  contextEntityId_gt?: InputMaybe<Scalars['String']>;
  contextEntityId_gte?: InputMaybe<Scalars['String']>;
  contextEntityId_in?: InputMaybe<Array<Scalars['String']>>;
  contextEntityId_lt?: InputMaybe<Scalars['String']>;
  contextEntityId_lte?: InputMaybe<Scalars['String']>;
  contextEntityId_not?: InputMaybe<Scalars['String']>;
  contextEntityId_not_contains?: InputMaybe<Scalars['String']>;
  contextEntityId_not_ends_with?: InputMaybe<Scalars['String']>;
  contextEntityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  contextEntityId_not_starts_with?: InputMaybe<Scalars['String']>;
  contextEntityId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_ends_with?: InputMaybe<Scalars['String']>;
  title_gt?: InputMaybe<Scalars['String']>;
  title_gte?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  title_lt?: InputMaybe<Scalars['String']>;
  title_lte?: InputMaybe<Scalars['String']>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CommunityTitleUpdateInput = {
  contextEntityId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  memberProfile?: InputMaybe<MemberProfileUpdateOneWithoutCommunityTitlesInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type CommunityTitleUpdateManyDataInput = {
  contextEntityId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CommunityTitleUpdateManyWithWhereNestedInput = {
  data: CommunityTitleUpdateManyDataInput;
  where: CommunityTitleScalarWhereInput;
};

export type CommunityTitleUpdateManyWithoutMemberProfileInput = {
  connect?: InputMaybe<Array<CommunityTitleWhereUniqueInput>>;
  create?: InputMaybe<Array<CommunityTitleCreateWithoutMemberProfileInput>>;
  delete?: InputMaybe<Array<CommunityTitleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityTitleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityTitleWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityTitleWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityTitleUpdateWithWhereUniqueWithoutMemberProfileInput>>;
  updateMany?: InputMaybe<Array<CommunityTitleUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<CommunityTitleUpsertWithWhereUniqueWithoutMemberProfileInput>>;
};

export type CommunityTitleUpdateWithWhereUniqueWithoutMemberProfileInput = {
  data: CommunityTitleUpdateWithoutMemberProfileDataInput;
  where: CommunityTitleWhereUniqueInput;
};

export type CommunityTitleUpdateWithoutMemberProfileDataInput = {
  contextEntityId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CommunityTitleUpsertWithWhereUniqueWithoutMemberProfileInput = {
  create: CommunityTitleCreateWithoutMemberProfileInput;
  update: CommunityTitleUpdateWithoutMemberProfileDataInput;
  where: CommunityTitleWhereUniqueInput;
};

export type CommunityTitleWhereInput = {
  AND?: InputMaybe<Array<CommunityTitleWhereInput>>;
  NOT?: InputMaybe<Array<CommunityTitleWhereInput>>;
  OR?: InputMaybe<Array<CommunityTitleWhereInput>>;
  contextEntityId?: InputMaybe<Scalars['String']>;
  contextEntityId_contains?: InputMaybe<Scalars['String']>;
  contextEntityId_ends_with?: InputMaybe<Scalars['String']>;
  contextEntityId_gt?: InputMaybe<Scalars['String']>;
  contextEntityId_gte?: InputMaybe<Scalars['String']>;
  contextEntityId_in?: InputMaybe<Array<Scalars['String']>>;
  contextEntityId_lt?: InputMaybe<Scalars['String']>;
  contextEntityId_lte?: InputMaybe<Scalars['String']>;
  contextEntityId_not?: InputMaybe<Scalars['String']>;
  contextEntityId_not_contains?: InputMaybe<Scalars['String']>;
  contextEntityId_not_ends_with?: InputMaybe<Scalars['String']>;
  contextEntityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  contextEntityId_not_starts_with?: InputMaybe<Scalars['String']>;
  contextEntityId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  memberProfile?: InputMaybe<MemberProfileWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_ends_with?: InputMaybe<Scalars['String']>;
  title_gt?: InputMaybe<Scalars['String']>;
  title_gte?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  title_lt?: InputMaybe<Scalars['String']>;
  title_lte?: InputMaybe<Scalars['String']>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CommunityTitleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ContractBillableItemCreateContractsPersistencyInput = {
  connect?: InputMaybe<Array<BillableItemWhereUniqueContractsPersistencyInput>>;
  create?: InputMaybe<Array<BillableItemCreateContractsPersistencyInput>>;
};

export type ContractBillableItemUpdateContractsPersistencyInput = {
  create?: InputMaybe<Array<BillableItemCreateContractsPersistencyInput>>;
  delete?: InputMaybe<Array<BillableItemWhereUniqueContractsPersistencyInput>>;
  update?: InputMaybe<Array<BillableItemUpdateWithWhereUniqueWithoutLeaseContractInput>>;
};

export type ContractEventConfiguration = {
  __typename?: 'ContractEventConfiguration';
  enabled: Scalars['Boolean'];
  eventType: ContractEventType;
  when: Array<Scalars['Int']>;
};

export enum ContractEventType {
  CONTRACT_ENDED_X_DAYS_AGO = 'CONTRACT_ENDED_X_DAYS_AGO',
  CONTRACT_STARTED_X_DAYS_AGO = 'CONTRACT_STARTED_X_DAYS_AGO',
  CONTRACT_WILL_END_IN_X_DAYS = 'CONTRACT_WILL_END_IN_X_DAYS',
  CONTRACT_WILL_START_IN_X_DAYS = 'CONTRACT_WILL_START_IN_X_DAYS'
}

export type ContractIdsInput = {
  leaseContractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  membershipContractIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ContractSettings = {
  __typename?: 'ContractSettings';
  backgroundCheck: BackgroundCheckSettings;
  events: EventsSettings;
  holdDeposit: HoldDepositSettings;
  hoodName: Scalars['String'];
  notifications: NotificationSettings;
  securityDeposit: SecurityDepositSettings;
};

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  APPLICANT = 'APPLICANT',
  CANCELLED = 'CANCELLED',
  EVICTED = 'EVICTED',
  FUTURE = 'FUTURE',
  INACTIVE = 'INACTIVE'
}

export enum ContractTimeline {
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  PAST = 'PAST'
}

export enum ContractsPersistencyAddressType {
  BILLING_ADDRESS = 'BILLING_ADDRESS'
}

export enum ContractsPersistencyBillableItemStatus {
  INIT_DONE = 'INIT_DONE',
  INIT_IN_PROCESS = 'INIT_IN_PROCESS',
  NEW = 'NEW'
}

export enum ContractsPersistencyBuildingStatus {
  CHURNED = 'CHURNED',
  PIPELINE = 'PIPELINE',
  SIGNED_SETUP_PERIOD = 'SIGNED_SETUP_PERIOD',
  SIGNED_STABILISED_PERIOD = 'SIGNED_STABILISED_PERIOD',
  UNDER_CONTRACT = 'UNDER_CONTRACT'
}

export enum ContractsPersistencyContractTimelineStatus {
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  PAST = 'PAST'
}

export enum ContractsPersistencyCountryCode {
  DE = 'DE',
  IL = 'IL',
  US = 'US'
}

export enum ContractsPersistencyGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NA = 'NA',
  NON_BINARY = 'NON_BINARY'
}

export enum ContractsPersistencyLandlordContractType {
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  VENN_CO_OWNERSHIP_LEASE = 'VENN_CO_OWNERSHIP_LEASE',
  VENN_LEASE = 'VENN_LEASE'
}

export enum ContractsPersistencyLockProvider {
  AUGUST = 'AUGUST',
  SDS = 'SDS'
}

export enum ContractsPersistencyManualContractsPersistencyPaymentType {
  CASH = 'CASH',
  CHECK = 'CHECK',
  SAP = 'SAP'
}

export enum ContractsPersistencyNotificationDestinationType {
  SLACK = 'SLACK',
  SMS = 'SMS'
}

export enum ContractsPersistencyNotificationSourceType {
  AIRTABLE = 'AIRTABLE',
  PAYMENT_SERVICE = 'PAYMENT_SERVICE'
}

export enum ContractsPersistencyNotificationStatus {
  FAILURE = 'FAILURE',
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  RETRY = 'RETRY',
  SUCCESS = 'SUCCESS'
}

export enum ContractsPersistencyNotificationType {
  BANK_AUTHORIZATION_EXPIRING = 'BANK_AUTHORIZATION_EXPIRING',
  BILLING_NOTICE = 'BILLING_NOTICE',
  CHARGE_CSV_IMPORT_REPORT = 'CHARGE_CSV_IMPORT_REPORT',
  END_OF_CONTRACT = 'END_OF_CONTRACT',
  INVALID_AIRTABLE_MEMBER_ROW = 'INVALID_AIRTABLE_MEMBER_ROW'
}

export enum ContractsPersistencyPaymentMethodType {
  ACH = 'ACH',
  CREDIT_CARD = 'CREDIT_CARD',
  MANUAL_ACH = 'MANUAL_ACH',
  MANUAL_BANK = 'MANUAL_BANK',
  SEPA = 'SEPA'
}

export enum ContractsPersistencyPaymentRequestEmailStatus {
  INITIALIZED = 'INITIALIZED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  MEMBER_DATA_ERROR = 'MEMBER_DATA_ERROR',
  RECEIVED = 'RECEIVED',
  SENDING_FAILURE = 'SENDING_FAILURE',
  SENT = 'SENT'
}

export enum ContractsPersistencyPaymentRequestStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum ContractsPersistencyPaymentTransactionStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum ContractsPersistencyPaymentType {
  DEPOSIT = 'DEPOSIT',
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  GENERIC = 'GENERIC',
  MONTHLY = 'MONTHLY',
  WAITING_LIST = 'WAITING_LIST'
}

export enum ContractsPersistencyPlaceTypes {
  COMMUNITY_BUILDING = 'COMMUNITY_BUILDING',
  EVENT = 'EVENT',
  LANDMARK = 'LANDMARK',
  LOCAL_BUSINESS = 'LOCAL_BUSINESS',
  SHARED_SPACE = 'SHARED_SPACE',
  SUBWAY = 'SUBWAY'
}

export enum ContractsPersistencyProductStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE'
}

export enum ContractsPersistencyRentingType {
  BY_BEDROOM = 'BY_BEDROOM',
  WHOLE_UNIT = 'WHOLE_UNIT'
}

export enum ContractsPersistencyServiceCallStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum ContractsPersistencyServiceType {
  HANDYMAN = 'HANDYMAN',
  VINI = 'VINI'
}

export enum ContractsPersistencySurveyAnswer {
  SATISFACTION_NOT_DISAPPOINTED = 'SATISFACTION_NOT_DISAPPOINTED',
  SATISFACTION_SOMEHOW_DISAPPOINTED = 'SATISFACTION_SOMEHOW_DISAPPOINTED',
  SATISFACTION_VERY_DISAPPOINTED = 'SATISFACTION_VERY_DISAPPOINTED'
}

export enum ContractsPersistencySurveyType {
  SATISFACTION = 'SATISFACTION'
}

export enum ContractsPersistencyUnitType {
  DUPLEX = 'DUPLEX',
  STUDIO = 'STUDIO'
}

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CoordinatesInput = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export enum CountryCode {
  DE = 'DE',
  IL = 'IL',
  US = 'US'
}

export enum CountryCodePersistency {
  DE = 'DE',
  IL = 'IL',
  US = 'US'
}

export type CreateEventSuggestionImage = {
  format?: InputMaybe<Scalars['String']>;
  publicId: Scalars['String'];
  resourceType?: InputMaybe<Scalars['String']>;
};

export type CreateEventSuggestionInput = {
  date: Scalars['DateTime'];
  description: Scalars['String'];
  helpDetails?: InputMaybe<Scalars['String']>;
  hoodId: Scalars['ID'];
  image: CreateEventSuggestionImage;
  location?: InputMaybe<Scalars['String']>;
  locationDetails?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizers: Scalars['String'];
  timeframe: CreateEventSuggestionTimeframe;
  userId: Scalars['ID'];
};

export type CreateEventSuggestionResult = {
  __typename?: 'CreateEventSuggestionResult';
  eventId: Scalars['ID'];
};

export enum CreateEventSuggestionTimeframe {
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
  MORNING = 'MORNING'
}

export type CreateInterestGroupInput = {
  createdByUserId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  groupSuggestedByNeighbor?: InputMaybe<Scalars['Boolean']>;
  hoodId: Scalars['ID'];
  image: Scalars['String'];
  isOneWay?: InputMaybe<Scalars['Boolean']>;
  isPublic: Scalars['Boolean'];
  moderatorsIds?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  _empty?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CurrencyConnection = {
  __typename?: 'CurrencyConnection';
  aggregate: AggregateCurrency;
  edges: Array<Maybe<CurrencyEdge>>;
  pageInfo: PageInfo;
};

export type CurrencyCreateInput = {
  code: Scalars['String'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
};

export type CurrencyCreateOneInput = {
  connect?: InputMaybe<CurrencyWhereUniqueInput>;
  create?: InputMaybe<CurrencyCreateInput>;
};

export type CurrencyEdge = {
  __typename?: 'CurrencyEdge';
  cursor: Scalars['String'];
  node: Currency;
};

export enum CurrencyOrderByInput {
  CODE_ASC = 'code_ASC',
  CODE_DESC = 'code_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  SYMBOL_ASC = 'symbol_ASC',
  SYMBOL_DESC = 'symbol_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type CurrencyUpdateDataInput = {
  code?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
};

export type CurrencyUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
};

export type CurrencyUpdateOneRequiredInput = {
  connect?: InputMaybe<CurrencyWhereUniqueInput>;
  create?: InputMaybe<CurrencyCreateInput>;
  update?: InputMaybe<CurrencyUpdateDataInput>;
  upsert?: InputMaybe<CurrencyUpsertNestedInput>;
};

export type CurrencyUpsertNestedInput = {
  create: CurrencyCreateInput;
  update: CurrencyUpdateDataInput;
};

export type CurrencyWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<CurrencyWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<CurrencyWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<CurrencyWhereContractsPersistencyInput>>;
  code?: InputMaybe<Scalars['String']>;
  code_contains?: InputMaybe<Scalars['String']>;
  code_ends_with?: InputMaybe<Scalars['String']>;
  code_gt?: InputMaybe<Scalars['String']>;
  code_gte?: InputMaybe<Scalars['String']>;
  code_in?: InputMaybe<Array<Scalars['String']>>;
  code_lt?: InputMaybe<Scalars['String']>;
  code_lte?: InputMaybe<Scalars['String']>;
  code_not?: InputMaybe<Scalars['String']>;
  code_not_contains?: InputMaybe<Scalars['String']>;
  code_not_ends_with?: InputMaybe<Scalars['String']>;
  code_not_in?: InputMaybe<Array<Scalars['String']>>;
  code_not_starts_with?: InputMaybe<Scalars['String']>;
  code_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CurrencyWhereInput = {
  AND?: InputMaybe<Array<CurrencyWhereInput>>;
  NOT?: InputMaybe<Array<CurrencyWhereInput>>;
  OR?: InputMaybe<Array<CurrencyWhereInput>>;
  code?: InputMaybe<Scalars['String']>;
  code_contains?: InputMaybe<Scalars['String']>;
  code_ends_with?: InputMaybe<Scalars['String']>;
  code_gt?: InputMaybe<Scalars['String']>;
  code_gte?: InputMaybe<Scalars['String']>;
  code_in?: InputMaybe<Array<Scalars['String']>>;
  code_lt?: InputMaybe<Scalars['String']>;
  code_lte?: InputMaybe<Scalars['String']>;
  code_not?: InputMaybe<Scalars['String']>;
  code_not_contains?: InputMaybe<Scalars['String']>;
  code_not_ends_with?: InputMaybe<Scalars['String']>;
  code_not_in?: InputMaybe<Array<Scalars['String']>>;
  code_not_starts_with?: InputMaybe<Scalars['String']>;
  code_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CurrencyWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<CurrencyWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<CurrencyWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<CurrencyWherePersistencyContractsInput>>;
  code?: InputMaybe<Scalars['String']>;
  code_contains?: InputMaybe<Scalars['String']>;
  code_ends_with?: InputMaybe<Scalars['String']>;
  code_gt?: InputMaybe<Scalars['String']>;
  code_gte?: InputMaybe<Scalars['String']>;
  code_in?: InputMaybe<Array<Scalars['String']>>;
  code_lt?: InputMaybe<Scalars['String']>;
  code_lte?: InputMaybe<Scalars['String']>;
  code_not?: InputMaybe<Scalars['String']>;
  code_not_contains?: InputMaybe<Scalars['String']>;
  code_not_ends_with?: InputMaybe<Scalars['String']>;
  code_not_in?: InputMaybe<Array<Scalars['String']>>;
  code_not_starts_with?: InputMaybe<Scalars['String']>;
  code_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type CurrencyWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type DeleteInterestGroupResponse = {
  __typename?: 'DeleteInterestGroupResponse';
  deletedAt: Scalars['String'];
  id: Scalars['String'];
};

export type DeliveryPackage = {
  __typename?: 'DeliveryPackage';
  arrivedOnDate?: Maybe<Scalars['DateTime']>;
  courier?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  packageNumber?: Maybe<Scalars['String']>;
  pickupDate?: Maybe<Scalars['DateTime']>;
  returnedOnDate?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  senderAddress?: Maybe<Scalars['String']>;
  status: DeliveryStatus;
  trackingLink?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
  userMarkPickedUp?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['String']>;
};

export type DeliveryPackageConnection = {
  __typename?: 'DeliveryPackageConnection';
  aggregate: AggregateDeliveryPackage;
  edges: Array<Maybe<DeliveryPackageEdge>>;
  pageInfo: PageInfo;
};

export type DeliveryPackageCreateInput = {
  arrivedOnDate?: InputMaybe<Scalars['DateTime']>;
  courier?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  packageNumber?: InputMaybe<Scalars['String']>;
  pickupDate?: InputMaybe<Scalars['DateTime']>;
  returnedOnDate?: InputMaybe<Scalars['DateTime']>;
  sender?: InputMaybe<Scalars['String']>;
  senderAddress?: InputMaybe<Scalars['String']>;
  status: DeliveryStatus;
  trackingLink?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  userMarkPickedUp?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type DeliveryPackageEdge = {
  __typename?: 'DeliveryPackageEdge';
  cursor: Scalars['String'];
  node: DeliveryPackage;
};

export enum DeliveryPackageOrderByInput {
  ARRIVEDONDATE_ASC = 'arrivedOnDate_ASC',
  ARRIVEDONDATE_DESC = 'arrivedOnDate_DESC',
  COURIER_ASC = 'courier_ASC',
  COURIER_DESC = 'courier_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PACKAGENUMBER_ASC = 'packageNumber_ASC',
  PACKAGENUMBER_DESC = 'packageNumber_DESC',
  PICKUPDATE_ASC = 'pickupDate_ASC',
  PICKUPDATE_DESC = 'pickupDate_DESC',
  RETURNEDONDATE_ASC = 'returnedOnDate_ASC',
  RETURNEDONDATE_DESC = 'returnedOnDate_DESC',
  SENDERADDRESS_ASC = 'senderAddress_ASC',
  SENDERADDRESS_DESC = 'senderAddress_DESC',
  SENDER_ASC = 'sender_ASC',
  SENDER_DESC = 'sender_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  TRACKINGLINK_ASC = 'trackingLink_ASC',
  TRACKINGLINK_DESC = 'trackingLink_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC',
  USERMARKPICKEDUP_ASC = 'userMarkPickedUp_ASC',
  USERMARKPICKEDUP_DESC = 'userMarkPickedUp_DESC',
  WEIGHT_ASC = 'weight_ASC',
  WEIGHT_DESC = 'weight_DESC'
}

export type DeliveryPackageUpdateInput = {
  arrivedOnDate?: InputMaybe<Scalars['DateTime']>;
  courier?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  packageNumber?: InputMaybe<Scalars['String']>;
  pickupDate?: InputMaybe<Scalars['DateTime']>;
  returnedOnDate?: InputMaybe<Scalars['DateTime']>;
  sender?: InputMaybe<Scalars['String']>;
  senderAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DeliveryStatus>;
  trackingLink?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
  userMarkPickedUp?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type DeliveryPackageWhereInput = {
  AND?: InputMaybe<Array<DeliveryPackageWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryPackageWhereInput>>;
  OR?: InputMaybe<Array<DeliveryPackageWhereInput>>;
  arrivedOnDate?: InputMaybe<Scalars['DateTime']>;
  arrivedOnDate_gt?: InputMaybe<Scalars['DateTime']>;
  arrivedOnDate_gte?: InputMaybe<Scalars['DateTime']>;
  arrivedOnDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  arrivedOnDate_lt?: InputMaybe<Scalars['DateTime']>;
  arrivedOnDate_lte?: InputMaybe<Scalars['DateTime']>;
  arrivedOnDate_not?: InputMaybe<Scalars['DateTime']>;
  arrivedOnDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  courier?: InputMaybe<Scalars['String']>;
  courier_contains?: InputMaybe<Scalars['String']>;
  courier_ends_with?: InputMaybe<Scalars['String']>;
  courier_gt?: InputMaybe<Scalars['String']>;
  courier_gte?: InputMaybe<Scalars['String']>;
  courier_in?: InputMaybe<Array<Scalars['String']>>;
  courier_lt?: InputMaybe<Scalars['String']>;
  courier_lte?: InputMaybe<Scalars['String']>;
  courier_not?: InputMaybe<Scalars['String']>;
  courier_not_contains?: InputMaybe<Scalars['String']>;
  courier_not_ends_with?: InputMaybe<Scalars['String']>;
  courier_not_in?: InputMaybe<Array<Scalars['String']>>;
  courier_not_starts_with?: InputMaybe<Scalars['String']>;
  courier_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  packageNumber?: InputMaybe<Scalars['String']>;
  packageNumber_contains?: InputMaybe<Scalars['String']>;
  packageNumber_ends_with?: InputMaybe<Scalars['String']>;
  packageNumber_gt?: InputMaybe<Scalars['String']>;
  packageNumber_gte?: InputMaybe<Scalars['String']>;
  packageNumber_in?: InputMaybe<Array<Scalars['String']>>;
  packageNumber_lt?: InputMaybe<Scalars['String']>;
  packageNumber_lte?: InputMaybe<Scalars['String']>;
  packageNumber_not?: InputMaybe<Scalars['String']>;
  packageNumber_not_contains?: InputMaybe<Scalars['String']>;
  packageNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  packageNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  packageNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  packageNumber_starts_with?: InputMaybe<Scalars['String']>;
  pickupDate?: InputMaybe<Scalars['DateTime']>;
  pickupDate_gt?: InputMaybe<Scalars['DateTime']>;
  pickupDate_gte?: InputMaybe<Scalars['DateTime']>;
  pickupDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  pickupDate_lt?: InputMaybe<Scalars['DateTime']>;
  pickupDate_lte?: InputMaybe<Scalars['DateTime']>;
  pickupDate_not?: InputMaybe<Scalars['DateTime']>;
  pickupDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  returnedOnDate?: InputMaybe<Scalars['DateTime']>;
  returnedOnDate_gt?: InputMaybe<Scalars['DateTime']>;
  returnedOnDate_gte?: InputMaybe<Scalars['DateTime']>;
  returnedOnDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  returnedOnDate_lt?: InputMaybe<Scalars['DateTime']>;
  returnedOnDate_lte?: InputMaybe<Scalars['DateTime']>;
  returnedOnDate_not?: InputMaybe<Scalars['DateTime']>;
  returnedOnDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  sender?: InputMaybe<Scalars['String']>;
  senderAddress?: InputMaybe<Scalars['String']>;
  senderAddress_contains?: InputMaybe<Scalars['String']>;
  senderAddress_ends_with?: InputMaybe<Scalars['String']>;
  senderAddress_gt?: InputMaybe<Scalars['String']>;
  senderAddress_gte?: InputMaybe<Scalars['String']>;
  senderAddress_in?: InputMaybe<Array<Scalars['String']>>;
  senderAddress_lt?: InputMaybe<Scalars['String']>;
  senderAddress_lte?: InputMaybe<Scalars['String']>;
  senderAddress_not?: InputMaybe<Scalars['String']>;
  senderAddress_not_contains?: InputMaybe<Scalars['String']>;
  senderAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  senderAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  senderAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  senderAddress_starts_with?: InputMaybe<Scalars['String']>;
  sender_contains?: InputMaybe<Scalars['String']>;
  sender_ends_with?: InputMaybe<Scalars['String']>;
  sender_gt?: InputMaybe<Scalars['String']>;
  sender_gte?: InputMaybe<Scalars['String']>;
  sender_in?: InputMaybe<Array<Scalars['String']>>;
  sender_lt?: InputMaybe<Scalars['String']>;
  sender_lte?: InputMaybe<Scalars['String']>;
  sender_not?: InputMaybe<Scalars['String']>;
  sender_not_contains?: InputMaybe<Scalars['String']>;
  sender_not_ends_with?: InputMaybe<Scalars['String']>;
  sender_not_in?: InputMaybe<Array<Scalars['String']>>;
  sender_not_starts_with?: InputMaybe<Scalars['String']>;
  sender_starts_with?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DeliveryStatus>;
  status_in?: InputMaybe<Array<DeliveryStatus>>;
  status_not?: InputMaybe<DeliveryStatus>;
  status_not_in?: InputMaybe<Array<DeliveryStatus>>;
  trackingLink?: InputMaybe<Scalars['String']>;
  trackingLink_contains?: InputMaybe<Scalars['String']>;
  trackingLink_ends_with?: InputMaybe<Scalars['String']>;
  trackingLink_gt?: InputMaybe<Scalars['String']>;
  trackingLink_gte?: InputMaybe<Scalars['String']>;
  trackingLink_in?: InputMaybe<Array<Scalars['String']>>;
  trackingLink_lt?: InputMaybe<Scalars['String']>;
  trackingLink_lte?: InputMaybe<Scalars['String']>;
  trackingLink_not?: InputMaybe<Scalars['String']>;
  trackingLink_not_contains?: InputMaybe<Scalars['String']>;
  trackingLink_not_ends_with?: InputMaybe<Scalars['String']>;
  trackingLink_not_in?: InputMaybe<Array<Scalars['String']>>;
  trackingLink_not_starts_with?: InputMaybe<Scalars['String']>;
  trackingLink_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
  userMarkPickedUp?: InputMaybe<Scalars['Boolean']>;
  userMarkPickedUp_not?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['String']>;
  weight_contains?: InputMaybe<Scalars['String']>;
  weight_ends_with?: InputMaybe<Scalars['String']>;
  weight_gt?: InputMaybe<Scalars['String']>;
  weight_gte?: InputMaybe<Scalars['String']>;
  weight_in?: InputMaybe<Array<Scalars['String']>>;
  weight_lt?: InputMaybe<Scalars['String']>;
  weight_lte?: InputMaybe<Scalars['String']>;
  weight_not?: InputMaybe<Scalars['String']>;
  weight_not_contains?: InputMaybe<Scalars['String']>;
  weight_not_ends_with?: InputMaybe<Scalars['String']>;
  weight_not_in?: InputMaybe<Array<Scalars['String']>>;
  weight_not_starts_with?: InputMaybe<Scalars['String']>;
  weight_starts_with?: InputMaybe<Scalars['String']>;
};

export type DeliveryPackageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum DeliveryStatus {
  ARRIVED = 'ARRIVED',
  PICKED_UP = 'PICKED_UP',
  RETURNED = 'RETURNED'
}

export type DispatchConfig = {
  __typename?: 'DispatchConfig';
  dayOfMonthToSend?: Maybe<Scalars['Int']>;
  emailSubject?: Maybe<Scalars['String']>;
  isForBillOfNextMonth?: Maybe<Scalars['Boolean']>;
  sendOverdueBills?: Maybe<Scalars['Boolean']>;
  templateName?: Maybe<BillingNotificationTemplateName>;
};

export type DoorAccessWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<DoorAccessWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<DoorAccessWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<DoorAccessWhereContractsPersistencyInput>>;
  accessTime?: InputMaybe<Scalars['DateTime']>;
  accessTime_gt?: InputMaybe<Scalars['DateTime']>;
  accessTime_gte?: InputMaybe<Scalars['DateTime']>;
  accessTime_in?: InputMaybe<Array<Scalars['DateTime']>>;
  accessTime_lt?: InputMaybe<Scalars['DateTime']>;
  accessTime_lte?: InputMaybe<Scalars['DateTime']>;
  accessTime_not?: InputMaybe<Scalars['DateTime']>;
  accessTime_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  accessType?: InputMaybe<Scalars['String']>;
  accessType_contains?: InputMaybe<Scalars['String']>;
  accessType_ends_with?: InputMaybe<Scalars['String']>;
  accessType_gt?: InputMaybe<Scalars['String']>;
  accessType_gte?: InputMaybe<Scalars['String']>;
  accessType_in?: InputMaybe<Array<Scalars['String']>>;
  accessType_lt?: InputMaybe<Scalars['String']>;
  accessType_lte?: InputMaybe<Scalars['String']>;
  accessType_not?: InputMaybe<Scalars['String']>;
  accessType_not_contains?: InputMaybe<Scalars['String']>;
  accessType_not_ends_with?: InputMaybe<Scalars['String']>;
  accessType_not_in?: InputMaybe<Array<Scalars['String']>>;
  accessType_not_starts_with?: InputMaybe<Scalars['String']>;
  accessType_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  doorId?: InputMaybe<Scalars['Int']>;
  doorId_gt?: InputMaybe<Scalars['Int']>;
  doorId_gte?: InputMaybe<Scalars['Int']>;
  doorId_in?: InputMaybe<Array<Scalars['Int']>>;
  doorId_lt?: InputMaybe<Scalars['Int']>;
  doorId_lte?: InputMaybe<Scalars['Int']>;
  doorId_not?: InputMaybe<Scalars['Int']>;
  doorId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['String']>;
  memberId_contains?: InputMaybe<Scalars['String']>;
  memberId_ends_with?: InputMaybe<Scalars['String']>;
  memberId_gt?: InputMaybe<Scalars['String']>;
  memberId_gte?: InputMaybe<Scalars['String']>;
  memberId_in?: InputMaybe<Array<Scalars['String']>>;
  memberId_lt?: InputMaybe<Scalars['String']>;
  memberId_lte?: InputMaybe<Scalars['String']>;
  memberId_not?: InputMaybe<Scalars['String']>;
  memberId_not_contains?: InputMaybe<Scalars['String']>;
  memberId_not_ends_with?: InputMaybe<Scalars['String']>;
  memberId_not_in?: InputMaybe<Array<Scalars['String']>>;
  memberId_not_starts_with?: InputMaybe<Scalars['String']>;
  memberId_starts_with?: InputMaybe<Scalars['String']>;
  spaceId?: InputMaybe<Scalars['String']>;
  spaceId_contains?: InputMaybe<Scalars['String']>;
  spaceId_ends_with?: InputMaybe<Scalars['String']>;
  spaceId_gt?: InputMaybe<Scalars['String']>;
  spaceId_gte?: InputMaybe<Scalars['String']>;
  spaceId_in?: InputMaybe<Array<Scalars['String']>>;
  spaceId_lt?: InputMaybe<Scalars['String']>;
  spaceId_lte?: InputMaybe<Scalars['String']>;
  spaceId_not?: InputMaybe<Scalars['String']>;
  spaceId_not_contains?: InputMaybe<Scalars['String']>;
  spaceId_not_ends_with?: InputMaybe<Scalars['String']>;
  spaceId_not_in?: InputMaybe<Array<Scalars['String']>>;
  spaceId_not_starts_with?: InputMaybe<Scalars['String']>;
  spaceId_starts_with?: InputMaybe<Scalars['String']>;
  statusUpdated?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_gt?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_gte?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_in?: InputMaybe<Array<Scalars['DateTime']>>;
  statusUpdated_lt?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_lte?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_not?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DoorAccessWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<DoorAccessWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<DoorAccessWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<DoorAccessWherePersistencyContractsInput>>;
  accessTime?: InputMaybe<Scalars['DateTime']>;
  accessTime_gt?: InputMaybe<Scalars['DateTime']>;
  accessTime_gte?: InputMaybe<Scalars['DateTime']>;
  accessTime_in?: InputMaybe<Array<Scalars['DateTime']>>;
  accessTime_lt?: InputMaybe<Scalars['DateTime']>;
  accessTime_lte?: InputMaybe<Scalars['DateTime']>;
  accessTime_not?: InputMaybe<Scalars['DateTime']>;
  accessTime_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  accessType?: InputMaybe<Scalars['String']>;
  accessType_contains?: InputMaybe<Scalars['String']>;
  accessType_ends_with?: InputMaybe<Scalars['String']>;
  accessType_gt?: InputMaybe<Scalars['String']>;
  accessType_gte?: InputMaybe<Scalars['String']>;
  accessType_in?: InputMaybe<Array<Scalars['String']>>;
  accessType_lt?: InputMaybe<Scalars['String']>;
  accessType_lte?: InputMaybe<Scalars['String']>;
  accessType_not?: InputMaybe<Scalars['String']>;
  accessType_not_contains?: InputMaybe<Scalars['String']>;
  accessType_not_ends_with?: InputMaybe<Scalars['String']>;
  accessType_not_in?: InputMaybe<Array<Scalars['String']>>;
  accessType_not_starts_with?: InputMaybe<Scalars['String']>;
  accessType_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  doorId?: InputMaybe<Scalars['Int']>;
  doorId_gt?: InputMaybe<Scalars['Int']>;
  doorId_gte?: InputMaybe<Scalars['Int']>;
  doorId_in?: InputMaybe<Array<Scalars['Int']>>;
  doorId_lt?: InputMaybe<Scalars['Int']>;
  doorId_lte?: InputMaybe<Scalars['Int']>;
  doorId_not?: InputMaybe<Scalars['Int']>;
  doorId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['String']>;
  memberId_contains?: InputMaybe<Scalars['String']>;
  memberId_ends_with?: InputMaybe<Scalars['String']>;
  memberId_gt?: InputMaybe<Scalars['String']>;
  memberId_gte?: InputMaybe<Scalars['String']>;
  memberId_in?: InputMaybe<Array<Scalars['String']>>;
  memberId_lt?: InputMaybe<Scalars['String']>;
  memberId_lte?: InputMaybe<Scalars['String']>;
  memberId_not?: InputMaybe<Scalars['String']>;
  memberId_not_contains?: InputMaybe<Scalars['String']>;
  memberId_not_ends_with?: InputMaybe<Scalars['String']>;
  memberId_not_in?: InputMaybe<Array<Scalars['String']>>;
  memberId_not_starts_with?: InputMaybe<Scalars['String']>;
  memberId_starts_with?: InputMaybe<Scalars['String']>;
  spaceId?: InputMaybe<Scalars['String']>;
  spaceId_contains?: InputMaybe<Scalars['String']>;
  spaceId_ends_with?: InputMaybe<Scalars['String']>;
  spaceId_gt?: InputMaybe<Scalars['String']>;
  spaceId_gte?: InputMaybe<Scalars['String']>;
  spaceId_in?: InputMaybe<Array<Scalars['String']>>;
  spaceId_lt?: InputMaybe<Scalars['String']>;
  spaceId_lte?: InputMaybe<Scalars['String']>;
  spaceId_not?: InputMaybe<Scalars['String']>;
  spaceId_not_contains?: InputMaybe<Scalars['String']>;
  spaceId_not_ends_with?: InputMaybe<Scalars['String']>;
  spaceId_not_in?: InputMaybe<Array<Scalars['String']>>;
  spaceId_not_starts_with?: InputMaybe<Scalars['String']>;
  spaceId_starts_with?: InputMaybe<Scalars['String']>;
  statusUpdated?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_gt?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_gte?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_in?: InputMaybe<Array<Scalars['DateTime']>>;
  statusUpdated_lt?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_lte?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_not?: InputMaybe<Scalars['DateTime']>;
  statusUpdated_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EmailNotificationSettings = {
  __typename?: 'EmailNotificationSettings';
  dispatchConfig?: Maybe<Array<Maybe<DispatchConfig>>>;
  enabled: Scalars['Boolean'];
  fromAddress?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  _empty?: Maybe<Scalars['String']>;
  admission?: Maybe<Scalars['Float']>;
  attendanceCancellable?: Maybe<Scalars['Boolean']>;
  availableTickets?: Maybe<Scalars['Int']>;
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars['String']>;
  buildingIds: Array<Scalars['String']>;
  buildings?: Maybe<Array<Maybe<Building>>>;
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventAttendees?: Maybe<Array<EventAttendee>>;
  hood?: Maybe<Hood>;
  hoodId: Scalars['String'];
  id: Scalars['ID'];
  images?: Maybe<Scalars['Json']>;
  maxTickets?: Maybe<Scalars['Int']>;
  myEventRating?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizers?: Maybe<Scalars['Json']>;
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['String']>;
  publish?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EventStatus>;
  survey?: Maybe<Survey>;
  surveyId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  virtualLink?: Maybe<Scalars['String']>;
};


export type EventEventAttendeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EventAttendeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventAttendeeWhereInput>;
};

export type EventAttendee = {
  __typename?: 'EventAttendee';
  billableItemIds: Array<Scalars['String']>;
  billableItems?: Maybe<Array<Maybe<BillableItem>>>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: Event;
  eventRating?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: AttendanceStatus;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type EventAttendeeConnection = {
  __typename?: 'EventAttendeeConnection';
  aggregate: AggregateEventAttendee;
  edges: Array<Maybe<EventAttendeeEdge>>;
  pageInfo: PageInfo;
};

export type EventAttendeeCreateInput = {
  billableItemIds?: InputMaybe<EventAttendeeCreatebillableItemIdsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  event: EventCreateOneWithoutEventAttendeesInput;
  status?: InputMaybe<AttendanceStatus>;
  userId: Scalars['String'];
};

export type EventAttendeeCreateManyWithoutEventInput = {
  connect?: InputMaybe<Array<EventAttendeeWhereUniqueInput>>;
  create?: InputMaybe<Array<EventAttendeeCreateWithoutEventInput>>;
};

export type EventAttendeeCreateWithoutEventInput = {
  billableItemIds?: InputMaybe<EventAttendeeCreatebillableItemIdsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AttendanceStatus>;
  userId: Scalars['String'];
};

export type EventAttendeeCreatebillableItemIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type EventAttendeeEdge = {
  __typename?: 'EventAttendeeEdge';
  cursor: Scalars['String'];
  node: EventAttendee;
};

export enum EventAttendeeOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type EventAttendeeScalarWhereInput = {
  AND?: InputMaybe<Array<EventAttendeeScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventAttendeeScalarWhereInput>>;
  OR?: InputMaybe<Array<EventAttendeeScalarWhereInput>>;
  billableItemIds_contains?: InputMaybe<Scalars['String']>;
  billableItemIds_contains_every?: InputMaybe<Array<Scalars['String']>>;
  billableItemIds_contains_some?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<AttendanceStatus>;
  status_in?: InputMaybe<Array<AttendanceStatus>>;
  status_not?: InputMaybe<AttendanceStatus>;
  status_not_in?: InputMaybe<Array<AttendanceStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type EventAttendeeUpdateInput = {
  billableItemIds?: InputMaybe<EventAttendeeUpdatebillableItemIdsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventAttendeesInput>;
  status?: InputMaybe<AttendanceStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type EventAttendeeUpdateManyDataInput = {
  billableItemIds?: InputMaybe<EventAttendeeUpdatebillableItemIdsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AttendanceStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type EventAttendeeUpdateManyWithWhereNestedInput = {
  data: EventAttendeeUpdateManyDataInput;
  where: EventAttendeeScalarWhereInput;
};

export type EventAttendeeUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<EventAttendeeWhereUniqueInput>>;
  create?: InputMaybe<Array<EventAttendeeCreateWithoutEventInput>>;
  delete?: InputMaybe<Array<EventAttendeeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventAttendeeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventAttendeeWhereUniqueInput>>;
  set?: InputMaybe<Array<EventAttendeeWhereUniqueInput>>;
  update?: InputMaybe<Array<EventAttendeeUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<EventAttendeeUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<EventAttendeeUpsertWithWhereUniqueWithoutEventInput>>;
};

export type EventAttendeeUpdateWithWhereUniqueWithoutEventInput = {
  data: EventAttendeeUpdateWithoutEventDataInput;
  where: EventAttendeeWhereUniqueInput;
};

export type EventAttendeeUpdateWithoutEventDataInput = {
  billableItemIds?: InputMaybe<EventAttendeeUpdatebillableItemIdsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AttendanceStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type EventAttendeeUpdatebillableItemIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type EventAttendeeUpsertWithWhereUniqueWithoutEventInput = {
  create: EventAttendeeCreateWithoutEventInput;
  update: EventAttendeeUpdateWithoutEventDataInput;
  where: EventAttendeeWhereUniqueInput;
};

export type EventAttendeeWhereInput = {
  AND?: InputMaybe<Array<EventAttendeeWhereInput>>;
  NOT?: InputMaybe<Array<EventAttendeeWhereInput>>;
  OR?: InputMaybe<Array<EventAttendeeWhereInput>>;
  billableItemIds_contains?: InputMaybe<Scalars['String']>;
  billableItemIds_contains_every?: InputMaybe<Array<Scalars['String']>>;
  billableItemIds_contains_some?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  event?: InputMaybe<EventWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<AttendanceStatus>;
  status_in?: InputMaybe<Array<AttendanceStatus>>;
  status_not?: InputMaybe<AttendanceStatus>;
  status_not_in?: InputMaybe<Array<AttendanceStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type EventAttendeeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type EventAttendeesGroupby = {
  __typename?: 'EventAttendeesGroupby';
  count?: Maybe<Scalars['Int']>;
  status?: Maybe<AttendanceStatus>;
  userId?: Maybe<Scalars['String']>;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  aggregate: AggregateEvent;
  edges: Array<Maybe<EventEdge>>;
  pageInfo: PageInfo;
};

export type EventCreateInput = {
  admission?: InputMaybe<Scalars['Float']>;
  buildingId?: InputMaybe<Scalars['String']>;
  buildingIds?: InputMaybe<EventCreatebuildingIdsInput>;
  category?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventAttendees?: InputMaybe<EventAttendeeCreateManyWithoutEventInput>;
  hoodId: Scalars['String'];
  images?: InputMaybe<Scalars['Json']>;
  maxTickets?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  organizers?: InputMaybe<Scalars['Json']>;
  placeId?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  surveyId?: InputMaybe<Scalars['String']>;
  virtualLink?: InputMaybe<Scalars['String']>;
};

export type EventCreateOneWithoutEventAttendeesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  create?: InputMaybe<EventCreateWithoutEventAttendeesInput>;
};

export type EventCreateWithoutEventAttendeesInput = {
  admission?: InputMaybe<Scalars['Float']>;
  buildingId?: InputMaybe<Scalars['String']>;
  buildingIds?: InputMaybe<EventCreatebuildingIdsInput>;
  category?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId: Scalars['String'];
  images?: InputMaybe<Scalars['Json']>;
  maxTickets?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  organizers?: InputMaybe<Scalars['Json']>;
  placeId?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  surveyId?: InputMaybe<Scalars['String']>;
  virtualLink?: InputMaybe<Scalars['String']>;
};

export type EventCreatebuildingIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node: Event;
};

export enum EventOrderByInput {
  ADMISSION_ASC = 'admission_ASC',
  ADMISSION_DESC = 'admission_DESC',
  BUILDINGID_ASC = 'buildingId_ASC',
  BUILDINGID_DESC = 'buildingId_DESC',
  CATEGORY_ASC = 'category_ASC',
  CATEGORY_DESC = 'category_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ENDDATE_ASC = 'endDate_ASC',
  ENDDATE_DESC = 'endDate_DESC',
  HOODID_ASC = 'hoodId_ASC',
  HOODID_DESC = 'hoodId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGES_ASC = 'images_ASC',
  IMAGES_DESC = 'images_DESC',
  MAXTICKETS_ASC = 'maxTickets_ASC',
  MAXTICKETS_DESC = 'maxTickets_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  ORGANIZERS_ASC = 'organizers_ASC',
  ORGANIZERS_DESC = 'organizers_DESC',
  PLACEID_ASC = 'placeId_ASC',
  PLACEID_DESC = 'placeId_DESC',
  PUBLISH_ASC = 'publish_ASC',
  PUBLISH_DESC = 'publish_DESC',
  STARTDATE_ASC = 'startDate_ASC',
  STARTDATE_DESC = 'startDate_DESC',
  SURVEYID_ASC = 'surveyId_ASC',
  SURVEYID_DESC = 'surveyId_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VIRTUALLINK_ASC = 'virtualLink_ASC',
  VIRTUALLINK_DESC = 'virtualLink_DESC'
}

export enum EventStatus {
  FUTURE = 'FUTURE',
  ON_GOING = 'ON_GOING',
  PAST = 'PAST'
}

export type EventUpdateInput = {
  admission?: InputMaybe<Scalars['Float']>;
  buildingId?: InputMaybe<Scalars['String']>;
  buildingIds?: InputMaybe<EventUpdatebuildingIdsInput>;
  category?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventAttendees?: InputMaybe<EventAttendeeUpdateManyWithoutEventInput>;
  hoodId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  maxTickets?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organizers?: InputMaybe<Scalars['Json']>;
  placeId?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  surveyId?: InputMaybe<Scalars['String']>;
  virtualLink?: InputMaybe<Scalars['String']>;
};

export type EventUpdateOneRequiredWithoutEventAttendeesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  create?: InputMaybe<EventCreateWithoutEventAttendeesInput>;
  update?: InputMaybe<EventUpdateWithoutEventAttendeesDataInput>;
  upsert?: InputMaybe<EventUpsertWithoutEventAttendeesInput>;
};

export type EventUpdateWithoutEventAttendeesDataInput = {
  admission?: InputMaybe<Scalars['Float']>;
  buildingId?: InputMaybe<Scalars['String']>;
  buildingIds?: InputMaybe<EventUpdatebuildingIdsInput>;
  category?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  maxTickets?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organizers?: InputMaybe<Scalars['Json']>;
  placeId?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  surveyId?: InputMaybe<Scalars['String']>;
  virtualLink?: InputMaybe<Scalars['String']>;
};

export type EventUpdatebuildingIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type EventUpsertWithoutEventAttendeesInput = {
  create: EventCreateWithoutEventAttendeesInput;
  update: EventUpdateWithoutEventAttendeesDataInput;
};

export type EventWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<EventWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<EventWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<EventWhereContractsPersistencyInput>>;
  admission?: InputMaybe<Scalars['Float']>;
  admission_gt?: InputMaybe<Scalars['Float']>;
  admission_gte?: InputMaybe<Scalars['Float']>;
  admission_in?: InputMaybe<Array<Scalars['Float']>>;
  admission_lt?: InputMaybe<Scalars['Float']>;
  admission_lte?: InputMaybe<Scalars['Float']>;
  admission_not?: InputMaybe<Scalars['Float']>;
  admission_not_in?: InputMaybe<Array<Scalars['Float']>>;
  building?: InputMaybe<BuildingWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  place?: InputMaybe<PlaceWhereContractsPersistencyInput>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  admission?: InputMaybe<Scalars['Float']>;
  admission_gt?: InputMaybe<Scalars['Float']>;
  admission_gte?: InputMaybe<Scalars['Float']>;
  admission_in?: InputMaybe<Array<Scalars['Float']>>;
  admission_lt?: InputMaybe<Scalars['Float']>;
  admission_lte?: InputMaybe<Scalars['Float']>;
  admission_not?: InputMaybe<Scalars['Float']>;
  admission_not_in?: InputMaybe<Array<Scalars['Float']>>;
  building?: InputMaybe<BuildingWhereInputPersistency>;
  buildingId?: InputMaybe<Scalars['String']>;
  buildingId_contains?: InputMaybe<Scalars['String']>;
  buildingId_ends_with?: InputMaybe<Scalars['String']>;
  buildingId_gt?: InputMaybe<Scalars['String']>;
  buildingId_gte?: InputMaybe<Scalars['String']>;
  buildingId_in?: InputMaybe<Array<Scalars['String']>>;
  buildingId_lt?: InputMaybe<Scalars['String']>;
  buildingId_lte?: InputMaybe<Scalars['String']>;
  buildingId_not?: InputMaybe<Scalars['String']>;
  buildingId_not_contains?: InputMaybe<Scalars['String']>;
  buildingId_not_ends_with?: InputMaybe<Scalars['String']>;
  buildingId_not_in?: InputMaybe<Array<Scalars['String']>>;
  buildingId_not_starts_with?: InputMaybe<Scalars['String']>;
  buildingId_starts_with?: InputMaybe<Scalars['String']>;
  buildingIds_contains?: InputMaybe<Scalars['String']>;
  buildingIds_contains_every?: InputMaybe<Array<Scalars['String']>>;
  buildingIds_contains_some?: InputMaybe<Array<Scalars['String']>>;
  category?: InputMaybe<Scalars['String']>;
  category_contains?: InputMaybe<Scalars['String']>;
  category_ends_with?: InputMaybe<Scalars['String']>;
  category_gt?: InputMaybe<Scalars['String']>;
  category_gte?: InputMaybe<Scalars['String']>;
  category_in?: InputMaybe<Array<Scalars['String']>>;
  category_lt?: InputMaybe<Scalars['String']>;
  category_lte?: InputMaybe<Scalars['String']>;
  category_not?: InputMaybe<Scalars['String']>;
  category_not_contains?: InputMaybe<Scalars['String']>;
  category_not_ends_with?: InputMaybe<Scalars['String']>;
  category_not_in?: InputMaybe<Array<Scalars['String']>>;
  category_not_starts_with?: InputMaybe<Scalars['String']>;
  category_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  eventAttendees_every?: InputMaybe<EventAttendeeWhereInput>;
  eventAttendees_none?: InputMaybe<EventAttendeeWhereInput>;
  eventAttendees_some?: InputMaybe<EventAttendeeWhereInput>;
  hoodId?: InputMaybe<Scalars['String']>;
  hoodId_contains?: InputMaybe<Scalars['String']>;
  hoodId_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_gt?: InputMaybe<Scalars['String']>;
  hoodId_gte?: InputMaybe<Scalars['String']>;
  hoodId_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_lt?: InputMaybe<Scalars['String']>;
  hoodId_lte?: InputMaybe<Scalars['String']>;
  hoodId_not?: InputMaybe<Scalars['String']>;
  hoodId_not_contains?: InputMaybe<Scalars['String']>;
  hoodId_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  maxTickets?: InputMaybe<Scalars['Int']>;
  maxTickets_gt?: InputMaybe<Scalars['Int']>;
  maxTickets_gte?: InputMaybe<Scalars['Int']>;
  maxTickets_in?: InputMaybe<Array<Scalars['Int']>>;
  maxTickets_lt?: InputMaybe<Scalars['Int']>;
  maxTickets_lte?: InputMaybe<Scalars['Int']>;
  maxTickets_not?: InputMaybe<Scalars['Int']>;
  maxTickets_not_in?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  place?: InputMaybe<PlaceWhereInputPersistency>;
  placeId?: InputMaybe<Scalars['String']>;
  placeId_contains?: InputMaybe<Scalars['String']>;
  placeId_ends_with?: InputMaybe<Scalars['String']>;
  placeId_gt?: InputMaybe<Scalars['String']>;
  placeId_gte?: InputMaybe<Scalars['String']>;
  placeId_in?: InputMaybe<Array<Scalars['String']>>;
  placeId_lt?: InputMaybe<Scalars['String']>;
  placeId_lte?: InputMaybe<Scalars['String']>;
  placeId_not?: InputMaybe<Scalars['String']>;
  placeId_not_contains?: InputMaybe<Scalars['String']>;
  placeId_not_ends_with?: InputMaybe<Scalars['String']>;
  placeId_not_in?: InputMaybe<Array<Scalars['String']>>;
  placeId_not_starts_with?: InputMaybe<Scalars['String']>;
  placeId_starts_with?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  surveyId?: InputMaybe<Scalars['String']>;
  surveyId_contains?: InputMaybe<Scalars['String']>;
  surveyId_ends_with?: InputMaybe<Scalars['String']>;
  surveyId_gt?: InputMaybe<Scalars['String']>;
  surveyId_gte?: InputMaybe<Scalars['String']>;
  surveyId_in?: InputMaybe<Array<Scalars['String']>>;
  surveyId_lt?: InputMaybe<Scalars['String']>;
  surveyId_lte?: InputMaybe<Scalars['String']>;
  surveyId_not?: InputMaybe<Scalars['String']>;
  surveyId_not_contains?: InputMaybe<Scalars['String']>;
  surveyId_not_ends_with?: InputMaybe<Scalars['String']>;
  surveyId_not_in?: InputMaybe<Array<Scalars['String']>>;
  surveyId_not_starts_with?: InputMaybe<Scalars['String']>;
  surveyId_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  virtualLink?: InputMaybe<Scalars['String']>;
  virtualLink_contains?: InputMaybe<Scalars['String']>;
  virtualLink_ends_with?: InputMaybe<Scalars['String']>;
  virtualLink_gt?: InputMaybe<Scalars['String']>;
  virtualLink_gte?: InputMaybe<Scalars['String']>;
  virtualLink_in?: InputMaybe<Array<Scalars['String']>>;
  virtualLink_lt?: InputMaybe<Scalars['String']>;
  virtualLink_lte?: InputMaybe<Scalars['String']>;
  virtualLink_not?: InputMaybe<Scalars['String']>;
  virtualLink_not_contains?: InputMaybe<Scalars['String']>;
  virtualLink_not_ends_with?: InputMaybe<Scalars['String']>;
  virtualLink_not_in?: InputMaybe<Array<Scalars['String']>>;
  virtualLink_not_starts_with?: InputMaybe<Scalars['String']>;
  virtualLink_starts_with?: InputMaybe<Scalars['String']>;
};

export type EventWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<EventWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<EventWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<EventWherePersistencyContractsInput>>;
  admission?: InputMaybe<Scalars['Float']>;
  admission_gt?: InputMaybe<Scalars['Float']>;
  admission_gte?: InputMaybe<Scalars['Float']>;
  admission_in?: InputMaybe<Array<Scalars['Float']>>;
  admission_lt?: InputMaybe<Scalars['Float']>;
  admission_lte?: InputMaybe<Scalars['Float']>;
  admission_not?: InputMaybe<Scalars['Float']>;
  admission_not_in?: InputMaybe<Array<Scalars['Float']>>;
  building?: InputMaybe<BuildingWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  place?: InputMaybe<PlaceWherePersistencyContractsInput>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type EventsSettings = {
  __typename?: 'EventsSettings';
  contractEndedXDaysAgo: ContractEventConfiguration;
  contractStartedXDaysAgo: ContractEventConfiguration;
  contractWillEndInXDays: ContractEventConfiguration;
  contractWillStartInXDays: ContractEventConfiguration;
};

export type ExtendContractInput = {
  billableItemIds?: InputMaybe<Array<Scalars['ID']>>;
  contractId: Scalars['ID'];
  contractType: ContractType;
  extensionDate: Scalars['DateTime'];
};

export type ExtendContractResult = {
  __typename?: 'ExtendContractResult';
  /** @deprecated Will be replaced by real status */
  dummy?: Maybe<Scalars['String']>;
};

export type ExtendedEntity = {
  __typename?: 'ExtendedEntity';
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};

export type ExternalBill = {
  __typename?: 'ExternalBill';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  externalId: Scalars['String'];
  externalReceipts?: Maybe<Array<ExternalReceipt>>;
  id: Scalars['ID'];
  link: Scalars['String'];
  metadata?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type ExternalBillExternalReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalReceiptOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalReceiptWhereInput>;
};

export type ExternalBillConnection = {
  __typename?: 'ExternalBillConnection';
  aggregate: AggregateExternalBill;
  edges: Array<Maybe<ExternalBillEdge>>;
  pageInfo: PageInfo;
};

export type ExternalBillCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  externalId: Scalars['String'];
  externalReceipts?: InputMaybe<ExternalReceiptCreateManyWithoutExternalBillsInput>;
  link: Scalars['String'];
  metadata?: InputMaybe<Scalars['Json']>;
};

export type ExternalBillCreateManyWithoutExternalReceiptsInput = {
  connect?: InputMaybe<Array<ExternalBillWhereUniqueInput>>;
  create?: InputMaybe<Array<ExternalBillCreateWithoutExternalReceiptsInput>>;
};

export type ExternalBillCreateOneInput = {
  connect?: InputMaybe<ExternalBillWhereUniqueInput>;
  create?: InputMaybe<ExternalBillCreateInput>;
};

export type ExternalBillCreateWithoutExternalReceiptsInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  externalId: Scalars['String'];
  link: Scalars['String'];
  metadata?: InputMaybe<Scalars['Json']>;
};

export type ExternalBillEdge = {
  __typename?: 'ExternalBillEdge';
  cursor: Scalars['String'];
  node: ExternalBill;
};

export enum ExternalBillOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  EXTERNALID_ASC = 'externalId_ASC',
  EXTERNALID_DESC = 'externalId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  LINK_ASC = 'link_ASC',
  LINK_DESC = 'link_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ExternalBillReceipt = {
  __typename?: 'ExternalBillReceipt';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalBill: ExternalBill;
  externalReceipt: ExternalReceipt;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type ExternalBillReceiptCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalBill: ExternalBillCreateOneInput;
  externalReceipt: ExternalReceiptCreateOneInput;
};

export enum ExternalBillReceiptOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ExternalBillReceiptUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalBill?: InputMaybe<ExternalBillUpdateOneRequiredInput>;
  externalReceipt?: InputMaybe<ExternalReceiptUpdateOneRequiredInput>;
};

export type ExternalBillReceiptWhereInput = {
  AND?: InputMaybe<Array<ExternalBillReceiptWhereInput>>;
  NOT?: InputMaybe<Array<ExternalBillReceiptWhereInput>>;
  OR?: InputMaybe<Array<ExternalBillReceiptWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalBill?: InputMaybe<ExternalBillWhereInput>;
  externalReceipt?: InputMaybe<ExternalReceiptWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ExternalBillReceiptWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ExternalBillScalarWhereInput = {
  AND?: InputMaybe<Array<ExternalBillScalarWhereInput>>;
  NOT?: InputMaybe<Array<ExternalBillScalarWhereInput>>;
  OR?: InputMaybe<Array<ExternalBillScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  link_contains?: InputMaybe<Scalars['String']>;
  link_ends_with?: InputMaybe<Scalars['String']>;
  link_gt?: InputMaybe<Scalars['String']>;
  link_gte?: InputMaybe<Scalars['String']>;
  link_in?: InputMaybe<Array<Scalars['String']>>;
  link_lt?: InputMaybe<Scalars['String']>;
  link_lte?: InputMaybe<Scalars['String']>;
  link_not?: InputMaybe<Scalars['String']>;
  link_not_contains?: InputMaybe<Scalars['String']>;
  link_not_ends_with?: InputMaybe<Scalars['String']>;
  link_not_in?: InputMaybe<Array<Scalars['String']>>;
  link_not_starts_with?: InputMaybe<Scalars['String']>;
  link_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ExternalBillUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalReceipts?: InputMaybe<ExternalReceiptUpdateManyWithoutExternalBillsInput>;
  link?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['Json']>;
};

export type ExternalBillUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalReceipts?: InputMaybe<ExternalReceiptUpdateManyWithoutExternalBillsInput>;
  link?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['Json']>;
};

export type ExternalBillUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['Json']>;
};

export type ExternalBillUpdateManyWithWhereNestedInput = {
  data: ExternalBillUpdateManyDataInput;
  where: ExternalBillScalarWhereInput;
};

export type ExternalBillUpdateManyWithoutExternalReceiptsInput = {
  connect?: InputMaybe<Array<ExternalBillWhereUniqueInput>>;
  create?: InputMaybe<Array<ExternalBillCreateWithoutExternalReceiptsInput>>;
  delete?: InputMaybe<Array<ExternalBillWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ExternalBillScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ExternalBillWhereUniqueInput>>;
  set?: InputMaybe<Array<ExternalBillWhereUniqueInput>>;
  update?: InputMaybe<Array<ExternalBillUpdateWithWhereUniqueWithoutExternalReceiptsInput>>;
  updateMany?: InputMaybe<Array<ExternalBillUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<ExternalBillUpsertWithWhereUniqueWithoutExternalReceiptsInput>>;
};

export type ExternalBillUpdateOneInput = {
  connect?: InputMaybe<ExternalBillWhereUniqueInput>;
  create?: InputMaybe<ExternalBillCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ExternalBillUpdateDataInput>;
  upsert?: InputMaybe<ExternalBillUpsertNestedInput>;
};

export type ExternalBillUpdateOneRequiredInput = {
  connect?: InputMaybe<ExternalBillWhereUniqueInput>;
  create?: InputMaybe<ExternalBillCreateInput>;
  update?: InputMaybe<ExternalBillUpdateDataInput>;
  upsert?: InputMaybe<ExternalBillUpsertNestedInput>;
};

export type ExternalBillUpdateWithWhereUniqueWithoutExternalReceiptsInput = {
  data: ExternalBillUpdateWithoutExternalReceiptsDataInput;
  where: ExternalBillWhereUniqueInput;
};

export type ExternalBillUpdateWithoutExternalReceiptsDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['Json']>;
};

export type ExternalBillUpsertNestedInput = {
  create: ExternalBillCreateInput;
  update: ExternalBillUpdateDataInput;
};

export type ExternalBillUpsertWithWhereUniqueWithoutExternalReceiptsInput = {
  create: ExternalBillCreateWithoutExternalReceiptsInput;
  update: ExternalBillUpdateWithoutExternalReceiptsDataInput;
  where: ExternalBillWhereUniqueInput;
};

export type ExternalBillWhereInput = {
  AND?: InputMaybe<Array<ExternalBillWhereInput>>;
  NOT?: InputMaybe<Array<ExternalBillWhereInput>>;
  OR?: InputMaybe<Array<ExternalBillWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  externalReceipts_every?: InputMaybe<ExternalReceiptWhereInput>;
  externalReceipts_none?: InputMaybe<ExternalReceiptWhereInput>;
  externalReceipts_some?: InputMaybe<ExternalReceiptWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  link_contains?: InputMaybe<Scalars['String']>;
  link_ends_with?: InputMaybe<Scalars['String']>;
  link_gt?: InputMaybe<Scalars['String']>;
  link_gte?: InputMaybe<Scalars['String']>;
  link_in?: InputMaybe<Array<Scalars['String']>>;
  link_lt?: InputMaybe<Scalars['String']>;
  link_lte?: InputMaybe<Scalars['String']>;
  link_not?: InputMaybe<Scalars['String']>;
  link_not_contains?: InputMaybe<Scalars['String']>;
  link_not_ends_with?: InputMaybe<Scalars['String']>;
  link_not_in?: InputMaybe<Array<Scalars['String']>>;
  link_not_starts_with?: InputMaybe<Scalars['String']>;
  link_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ExternalBillWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ExternalReceipt = {
  __typename?: 'ExternalReceipt';
  _empty?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalBills?: Maybe<Array<ExternalBill>>;
  id: Scalars['ID'];
  paymentDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
};


export type ExternalReceiptExternalBillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalBillOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalBillWhereInput>;
};

export type ExternalReceiptConnection = {
  __typename?: 'ExternalReceiptConnection';
  aggregate: AggregateExternalReceipt;
  edges: Array<Maybe<ExternalReceiptEdge>>;
  pageInfo: PageInfo;
};

export type ExternalReceiptCreateInput = {
  amount: Scalars['Float'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalBills?: InputMaybe<ExternalBillCreateManyWithoutExternalReceiptsInput>;
  paymentDate: Scalars['DateTime'];
  user: UserCreateOneInput;
};

export type ExternalReceiptCreateManyWithoutExternalBillsInput = {
  connect?: InputMaybe<Array<ExternalReceiptWhereUniqueInput>>;
  create?: InputMaybe<Array<ExternalReceiptCreateWithoutExternalBillsInput>>;
};

export type ExternalReceiptCreateOneInput = {
  connect?: InputMaybe<ExternalReceiptWhereUniqueInput>;
  create?: InputMaybe<ExternalReceiptCreateInput>;
};

export type ExternalReceiptCreateWithoutExternalBillsInput = {
  amount: Scalars['Float'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  paymentDate: Scalars['DateTime'];
  user: UserCreateOneInput;
};

export type ExternalReceiptEdge = {
  __typename?: 'ExternalReceiptEdge';
  cursor: Scalars['String'];
  node: ExternalReceipt;
};

export enum ExternalReceiptOrderByInput {
  AMOUNT_ASC = 'amount_ASC',
  AMOUNT_DESC = 'amount_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PAYMENTDATE_ASC = 'paymentDate_ASC',
  PAYMENTDATE_DESC = 'paymentDate_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ExternalReceiptScalarWhereInput = {
  AND?: InputMaybe<Array<ExternalReceiptScalarWhereInput>>;
  NOT?: InputMaybe<Array<ExternalReceiptScalarWhereInput>>;
  OR?: InputMaybe<Array<ExternalReceiptScalarWhereInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  paymentDate_gt?: InputMaybe<Scalars['DateTime']>;
  paymentDate_gte?: InputMaybe<Scalars['DateTime']>;
  paymentDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  paymentDate_lt?: InputMaybe<Scalars['DateTime']>;
  paymentDate_lte?: InputMaybe<Scalars['DateTime']>;
  paymentDate_not?: InputMaybe<Scalars['DateTime']>;
  paymentDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ExternalReceiptUpdateDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalBills?: InputMaybe<ExternalBillUpdateManyWithoutExternalReceiptsInput>;
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type ExternalReceiptUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalBills?: InputMaybe<ExternalBillUpdateManyWithoutExternalReceiptsInput>;
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type ExternalReceiptUpdateManyDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  paymentDate?: InputMaybe<Scalars['DateTime']>;
};

export type ExternalReceiptUpdateManyWithWhereNestedInput = {
  data: ExternalReceiptUpdateManyDataInput;
  where: ExternalReceiptScalarWhereInput;
};

export type ExternalReceiptUpdateManyWithoutExternalBillsInput = {
  connect?: InputMaybe<Array<ExternalReceiptWhereUniqueInput>>;
  create?: InputMaybe<Array<ExternalReceiptCreateWithoutExternalBillsInput>>;
  delete?: InputMaybe<Array<ExternalReceiptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ExternalReceiptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ExternalReceiptWhereUniqueInput>>;
  set?: InputMaybe<Array<ExternalReceiptWhereUniqueInput>>;
  update?: InputMaybe<Array<ExternalReceiptUpdateWithWhereUniqueWithoutExternalBillsInput>>;
  updateMany?: InputMaybe<Array<ExternalReceiptUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<ExternalReceiptUpsertWithWhereUniqueWithoutExternalBillsInput>>;
};

export type ExternalReceiptUpdateOneRequiredInput = {
  connect?: InputMaybe<ExternalReceiptWhereUniqueInput>;
  create?: InputMaybe<ExternalReceiptCreateInput>;
  update?: InputMaybe<ExternalReceiptUpdateDataInput>;
  upsert?: InputMaybe<ExternalReceiptUpsertNestedInput>;
};

export type ExternalReceiptUpdateWithWhereUniqueWithoutExternalBillsInput = {
  data: ExternalReceiptUpdateWithoutExternalBillsDataInput;
  where: ExternalReceiptWhereUniqueInput;
};

export type ExternalReceiptUpdateWithoutExternalBillsDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type ExternalReceiptUpsertNestedInput = {
  create: ExternalReceiptCreateInput;
  update: ExternalReceiptUpdateDataInput;
};

export type ExternalReceiptUpsertWithWhereUniqueWithoutExternalBillsInput = {
  create: ExternalReceiptCreateWithoutExternalBillsInput;
  update: ExternalReceiptUpdateWithoutExternalBillsDataInput;
  where: ExternalReceiptWhereUniqueInput;
};

export type ExternalReceiptWhereInput = {
  AND?: InputMaybe<Array<ExternalReceiptWhereInput>>;
  NOT?: InputMaybe<Array<ExternalReceiptWhereInput>>;
  OR?: InputMaybe<Array<ExternalReceiptWhereInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalBills_every?: InputMaybe<ExternalBillWhereInput>;
  externalBills_none?: InputMaybe<ExternalBillWhereInput>;
  externalBills_some?: InputMaybe<ExternalBillWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  paymentDate_gt?: InputMaybe<Scalars['DateTime']>;
  paymentDate_gte?: InputMaybe<Scalars['DateTime']>;
  paymentDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  paymentDate_lt?: InputMaybe<Scalars['DateTime']>;
  paymentDate_lte?: InputMaybe<Scalars['DateTime']>;
  paymentDate_not?: InputMaybe<Scalars['DateTime']>;
  paymentDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
};

export type ExternalReceiptWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Feature = {
  __typename?: 'Feature';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood?: Maybe<Hood>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  openToEveryone: Scalars['Boolean'];
  openToSpecificUsers?: Maybe<Array<User>>;
  openToVennEmployees: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};


export type FeatureOpenToSpecificUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type FeatureAnnouncement = {
  __typename?: 'FeatureAnnouncement';
  _empty?: Maybe<Scalars['String']>;
  appVersion: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hoods?: Maybe<Array<Hood>>;
  id: Scalars['ID'];
  image: Scalars['Json'];
  publish: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  viniMessage: Scalars['String'];
};


export type FeatureAnnouncementHoodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodWhereInput>;
};

export type FeatureAnnouncementConnection = {
  __typename?: 'FeatureAnnouncementConnection';
  aggregate: AggregateFeatureAnnouncement;
  edges: Array<Maybe<FeatureAnnouncementEdge>>;
  pageInfo: PageInfo;
};

export type FeatureAnnouncementCreateInput = {
  appVersion: Scalars['String'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hoods?: InputMaybe<HoodCreateManyInput>;
  image: Scalars['Json'];
  publish?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  viniMessage: Scalars['String'];
};

export type FeatureAnnouncementEdge = {
  __typename?: 'FeatureAnnouncementEdge';
  cursor: Scalars['String'];
  node: FeatureAnnouncement;
};

export enum FeatureAnnouncementOrderByInput {
  APPVERSION_ASC = 'appVersion_ASC',
  APPVERSION_DESC = 'appVersion_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGE_ASC = 'image_ASC',
  IMAGE_DESC = 'image_DESC',
  PUBLISH_ASC = 'publish_ASC',
  PUBLISH_DESC = 'publish_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VINIMESSAGE_ASC = 'viniMessage_ASC',
  VINIMESSAGE_DESC = 'viniMessage_DESC'
}

export type FeatureAnnouncementUpdateInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  hoods?: InputMaybe<HoodUpdateManyInput>;
  image?: InputMaybe<Scalars['Json']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  viniMessage?: InputMaybe<Scalars['String']>;
};

export type FeatureAnnouncementWhereInput = {
  AND?: InputMaybe<Array<FeatureAnnouncementWhereInput>>;
  NOT?: InputMaybe<Array<FeatureAnnouncementWhereInput>>;
  OR?: InputMaybe<Array<FeatureAnnouncementWhereInput>>;
  appVersion?: InputMaybe<Scalars['String']>;
  appVersion_contains?: InputMaybe<Scalars['String']>;
  appVersion_ends_with?: InputMaybe<Scalars['String']>;
  appVersion_gt?: InputMaybe<Scalars['String']>;
  appVersion_gte?: InputMaybe<Scalars['String']>;
  appVersion_in?: InputMaybe<Array<Scalars['String']>>;
  appVersion_lt?: InputMaybe<Scalars['String']>;
  appVersion_lte?: InputMaybe<Scalars['String']>;
  appVersion_not?: InputMaybe<Scalars['String']>;
  appVersion_not_contains?: InputMaybe<Scalars['String']>;
  appVersion_not_ends_with?: InputMaybe<Scalars['String']>;
  appVersion_not_in?: InputMaybe<Array<Scalars['String']>>;
  appVersion_not_starts_with?: InputMaybe<Scalars['String']>;
  appVersion_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  hoods_every?: InputMaybe<HoodWhereInput>;
  hoods_none?: InputMaybe<HoodWhereInput>;
  hoods_some?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_ends_with?: InputMaybe<Scalars['String']>;
  title_gt?: InputMaybe<Scalars['String']>;
  title_gte?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  title_lt?: InputMaybe<Scalars['String']>;
  title_lte?: InputMaybe<Scalars['String']>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  viniMessage?: InputMaybe<Scalars['String']>;
  viniMessage_contains?: InputMaybe<Scalars['String']>;
  viniMessage_ends_with?: InputMaybe<Scalars['String']>;
  viniMessage_gt?: InputMaybe<Scalars['String']>;
  viniMessage_gte?: InputMaybe<Scalars['String']>;
  viniMessage_in?: InputMaybe<Array<Scalars['String']>>;
  viniMessage_lt?: InputMaybe<Scalars['String']>;
  viniMessage_lte?: InputMaybe<Scalars['String']>;
  viniMessage_not?: InputMaybe<Scalars['String']>;
  viniMessage_not_contains?: InputMaybe<Scalars['String']>;
  viniMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  viniMessage_not_in?: InputMaybe<Array<Scalars['String']>>;
  viniMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  viniMessage_starts_with?: InputMaybe<Scalars['String']>;
};

export type FeatureAnnouncementWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FeatureConnection = {
  __typename?: 'FeatureConnection';
  aggregate: AggregateFeature;
  edges: Array<Maybe<FeatureEdge>>;
  pageInfo: PageInfo;
};

export type FeatureCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodCreateOneInput>;
  key: Scalars['String'];
  name: Scalars['String'];
  openToEveryone: Scalars['Boolean'];
  openToSpecificUsers?: InputMaybe<UserCreateManyInput>;
  openToVennEmployees: Scalars['Boolean'];
};

export type FeatureEdge = {
  __typename?: 'FeatureEdge';
  cursor: Scalars['String'];
  node: Feature;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export enum FeatureOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  KEY_ASC = 'key_ASC',
  KEY_DESC = 'key_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  OPENTOEVERYONE_ASC = 'openToEveryone_ASC',
  OPENTOEVERYONE_DESC = 'openToEveryone_DESC',
  OPENTOVENNEMPLOYEES_ASC = 'openToVennEmployees_ASC',
  OPENTOVENNEMPLOYEES_DESC = 'openToVennEmployees_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type FeatureUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneInput>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  openToEveryone?: InputMaybe<Scalars['Boolean']>;
  openToSpecificUsers?: InputMaybe<UserUpdateManyInput>;
  openToVennEmployees?: InputMaybe<Scalars['Boolean']>;
};

export type FeatureWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<FeatureWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<FeatureWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<FeatureWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_ends_with?: InputMaybe<Scalars['String']>;
  key_gt?: InputMaybe<Scalars['String']>;
  key_gte?: InputMaybe<Scalars['String']>;
  key_in?: InputMaybe<Array<Scalars['String']>>;
  key_lt?: InputMaybe<Scalars['String']>;
  key_lte?: InputMaybe<Scalars['String']>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<Scalars['String']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  key_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openToEveryone?: InputMaybe<Scalars['Boolean']>;
  openToEveryone_not?: InputMaybe<Scalars['Boolean']>;
  openToSpecificUsers_every?: InputMaybe<UserWhereContractsPersistencyInput>;
  openToSpecificUsers_none?: InputMaybe<UserWhereContractsPersistencyInput>;
  openToSpecificUsers_some?: InputMaybe<UserWhereContractsPersistencyInput>;
  openToVennEmployees?: InputMaybe<Scalars['Boolean']>;
  openToVennEmployees_not?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeatureWhereInput = {
  AND?: InputMaybe<Array<FeatureWhereInput>>;
  NOT?: InputMaybe<Array<FeatureWhereInput>>;
  OR?: InputMaybe<Array<FeatureWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_ends_with?: InputMaybe<Scalars['String']>;
  key_gt?: InputMaybe<Scalars['String']>;
  key_gte?: InputMaybe<Scalars['String']>;
  key_in?: InputMaybe<Array<Scalars['String']>>;
  key_lt?: InputMaybe<Scalars['String']>;
  key_lte?: InputMaybe<Scalars['String']>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<Scalars['String']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  key_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openToEveryone?: InputMaybe<Scalars['Boolean']>;
  openToEveryone_not?: InputMaybe<Scalars['Boolean']>;
  openToSpecificUsers_every?: InputMaybe<UserWhereInput>;
  openToSpecificUsers_none?: InputMaybe<UserWhereInput>;
  openToSpecificUsers_some?: InputMaybe<UserWhereInput>;
  openToVennEmployees?: InputMaybe<Scalars['Boolean']>;
  openToVennEmployees_not?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeatureWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<FeatureWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<FeatureWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<FeatureWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_ends_with?: InputMaybe<Scalars['String']>;
  key_gt?: InputMaybe<Scalars['String']>;
  key_gte?: InputMaybe<Scalars['String']>;
  key_in?: InputMaybe<Array<Scalars['String']>>;
  key_lt?: InputMaybe<Scalars['String']>;
  key_lte?: InputMaybe<Scalars['String']>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<Scalars['String']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  key_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openToEveryone?: InputMaybe<Scalars['Boolean']>;
  openToEveryone_not?: InputMaybe<Scalars['Boolean']>;
  openToSpecificUsers_every?: InputMaybe<UserWherePersistencyContractsInput>;
  openToSpecificUsers_none?: InputMaybe<UserWherePersistencyContractsInput>;
  openToSpecificUsers_some?: InputMaybe<UserWherePersistencyContractsInput>;
  openToVennEmployees?: InputMaybe<Scalars['Boolean']>;
  openToVennEmployees_not?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeatureWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type FeaturedSlide = {
  __typename?: 'FeaturedSlide';
  buildings?: Maybe<Array<Building>>;
  callToAction?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood: Hood;
  id: Scalars['ID'];
  images?: Maybe<Scalars['Json']>;
  linkType?: Maybe<LinkType>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  publish?: Maybe<Scalars['Boolean']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  template: Scalars['String'];
  textFields?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type FeaturedSlideBuildingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BuildingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingWhereInput>;
};

export type FeaturedSlideConnection = {
  __typename?: 'FeaturedSlideConnection';
  aggregate: AggregateFeaturedSlide;
  edges: Array<Maybe<FeaturedSlideEdge>>;
  pageInfo: PageInfo;
};

export type FeaturedSlideCreateInput = {
  buildings?: InputMaybe<BuildingCreateManyWithoutFeaturedSlidesInput>;
  callToAction?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood: HoodCreateOneInput;
  images?: InputMaybe<Scalars['Json']>;
  linkType?: InputMaybe<LinkType>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  template: Scalars['String'];
  textFields?: InputMaybe<Scalars['Json']>;
};

export type FeaturedSlideCreateManyWithoutBuildingsInput = {
  connect?: InputMaybe<Array<FeaturedSlideWhereUniqueInput>>;
  create?: InputMaybe<Array<FeaturedSlideCreateWithoutBuildingsInput>>;
};

export type FeaturedSlideCreateWithoutBuildingsInput = {
  callToAction?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood: HoodCreateOneInput;
  images?: InputMaybe<Scalars['Json']>;
  linkType?: InputMaybe<LinkType>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  template: Scalars['String'];
  textFields?: InputMaybe<Scalars['Json']>;
};

export type FeaturedSlideEdge = {
  __typename?: 'FeaturedSlideEdge';
  cursor: Scalars['String'];
  node: FeaturedSlide;
};

export enum FeaturedSlideOrderByInput {
  CALLTOACTION_ASC = 'callToAction_ASC',
  CALLTOACTION_DESC = 'callToAction_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGES_ASC = 'images_ASC',
  IMAGES_DESC = 'images_DESC',
  LINKTYPE_ASC = 'linkType_ASC',
  LINKTYPE_DESC = 'linkType_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PRIORITY_ASC = 'priority_ASC',
  PRIORITY_DESC = 'priority_DESC',
  PUBLISHENDDATE_ASC = 'publishEndDate_ASC',
  PUBLISHENDDATE_DESC = 'publishEndDate_DESC',
  PUBLISHSTARTDATE_ASC = 'publishStartDate_ASC',
  PUBLISHSTARTDATE_DESC = 'publishStartDate_DESC',
  TEMPLATE_ASC = 'template_ASC',
  TEMPLATE_DESC = 'template_DESC',
  TEXTFIELDS_ASC = 'textFields_ASC',
  TEXTFIELDS_DESC = 'textFields_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type FeaturedSlidePriorityActionsResult = {
  __typename?: 'FeaturedSlidePriorityActionsResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type FeaturedSlideScalarWhereInput = {
  AND?: InputMaybe<Array<FeaturedSlideScalarWhereInput>>;
  NOT?: InputMaybe<Array<FeaturedSlideScalarWhereInput>>;
  OR?: InputMaybe<Array<FeaturedSlideScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  linkType?: InputMaybe<LinkType>;
  linkType_in?: InputMaybe<Array<LinkType>>;
  linkType_not?: InputMaybe<LinkType>;
  linkType_not_in?: InputMaybe<Array<LinkType>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  priority_gt?: InputMaybe<Scalars['Int']>;
  priority_gte?: InputMaybe<Scalars['Int']>;
  priority_in?: InputMaybe<Array<Scalars['Int']>>;
  priority_lt?: InputMaybe<Scalars['Int']>;
  priority_lte?: InputMaybe<Scalars['Int']>;
  priority_not?: InputMaybe<Scalars['Int']>;
  priority_not_in?: InputMaybe<Array<Scalars['Int']>>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_not?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_not?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  template?: InputMaybe<Scalars['String']>;
  template_contains?: InputMaybe<Scalars['String']>;
  template_ends_with?: InputMaybe<Scalars['String']>;
  template_gt?: InputMaybe<Scalars['String']>;
  template_gte?: InputMaybe<Scalars['String']>;
  template_in?: InputMaybe<Array<Scalars['String']>>;
  template_lt?: InputMaybe<Scalars['String']>;
  template_lte?: InputMaybe<Scalars['String']>;
  template_not?: InputMaybe<Scalars['String']>;
  template_not_contains?: InputMaybe<Scalars['String']>;
  template_not_ends_with?: InputMaybe<Scalars['String']>;
  template_not_in?: InputMaybe<Array<Scalars['String']>>;
  template_not_starts_with?: InputMaybe<Scalars['String']>;
  template_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeaturedSlideUpdateInput = {
  buildings?: InputMaybe<BuildingUpdateManyWithoutFeaturedSlidesInput>;
  callToAction?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  images?: InputMaybe<Scalars['Json']>;
  linkType?: InputMaybe<LinkType>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  template?: InputMaybe<Scalars['String']>;
  textFields?: InputMaybe<Scalars['Json']>;
};

export type FeaturedSlideUpdateManyDataInput = {
  callToAction?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<Scalars['Json']>;
  linkType?: InputMaybe<LinkType>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  template?: InputMaybe<Scalars['String']>;
  textFields?: InputMaybe<Scalars['Json']>;
};

export type FeaturedSlideUpdateManyWithWhereNestedInput = {
  data: FeaturedSlideUpdateManyDataInput;
  where: FeaturedSlideScalarWhereInput;
};

export type FeaturedSlideUpdateManyWithoutBuildingsInput = {
  connect?: InputMaybe<Array<FeaturedSlideWhereUniqueInput>>;
  create?: InputMaybe<Array<FeaturedSlideCreateWithoutBuildingsInput>>;
  delete?: InputMaybe<Array<FeaturedSlideWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeaturedSlideScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeaturedSlideWhereUniqueInput>>;
  set?: InputMaybe<Array<FeaturedSlideWhereUniqueInput>>;
  update?: InputMaybe<Array<FeaturedSlideUpdateWithWhereUniqueWithoutBuildingsInput>>;
  updateMany?: InputMaybe<Array<FeaturedSlideUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<FeaturedSlideUpsertWithWhereUniqueWithoutBuildingsInput>>;
};

export type FeaturedSlideUpdateWithWhereUniqueWithoutBuildingsInput = {
  data: FeaturedSlideUpdateWithoutBuildingsDataInput;
  where: FeaturedSlideWhereUniqueInput;
};

export type FeaturedSlideUpdateWithoutBuildingsDataInput = {
  callToAction?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  images?: InputMaybe<Scalars['Json']>;
  linkType?: InputMaybe<LinkType>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  template?: InputMaybe<Scalars['String']>;
  textFields?: InputMaybe<Scalars['Json']>;
};

export type FeaturedSlideUpsertWithWhereUniqueWithoutBuildingsInput = {
  create: FeaturedSlideCreateWithoutBuildingsInput;
  update: FeaturedSlideUpdateWithoutBuildingsDataInput;
  where: FeaturedSlideWhereUniqueInput;
};

export type FeaturedSlideWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<FeaturedSlideWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<FeaturedSlideWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<FeaturedSlideWhereContractsPersistencyInput>>;
  building?: InputMaybe<BuildingWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  priority_gt?: InputMaybe<Scalars['Int']>;
  priority_gte?: InputMaybe<Scalars['Int']>;
  priority_in?: InputMaybe<Array<Scalars['Int']>>;
  priority_lt?: InputMaybe<Scalars['Int']>;
  priority_lte?: InputMaybe<Scalars['Int']>;
  priority_not?: InputMaybe<Scalars['Int']>;
  priority_not_in?: InputMaybe<Array<Scalars['Int']>>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_not?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_not?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  template?: InputMaybe<Scalars['String']>;
  template_contains?: InputMaybe<Scalars['String']>;
  template_ends_with?: InputMaybe<Scalars['String']>;
  template_gt?: InputMaybe<Scalars['String']>;
  template_gte?: InputMaybe<Scalars['String']>;
  template_in?: InputMaybe<Array<Scalars['String']>>;
  template_lt?: InputMaybe<Scalars['String']>;
  template_lte?: InputMaybe<Scalars['String']>;
  template_not?: InputMaybe<Scalars['String']>;
  template_not_contains?: InputMaybe<Scalars['String']>;
  template_not_ends_with?: InputMaybe<Scalars['String']>;
  template_not_in?: InputMaybe<Array<Scalars['String']>>;
  template_not_starts_with?: InputMaybe<Scalars['String']>;
  template_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeaturedSlideWhereInput = {
  AND?: InputMaybe<Array<FeaturedSlideWhereInput>>;
  NOT?: InputMaybe<Array<FeaturedSlideWhereInput>>;
  OR?: InputMaybe<Array<FeaturedSlideWhereInput>>;
  buildings_every?: InputMaybe<BuildingWhereInput>;
  buildings_none?: InputMaybe<BuildingWhereInput>;
  buildings_some?: InputMaybe<BuildingWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  linkType?: InputMaybe<LinkType>;
  linkType_in?: InputMaybe<Array<LinkType>>;
  linkType_not?: InputMaybe<LinkType>;
  linkType_not_in?: InputMaybe<Array<LinkType>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  priority_gt?: InputMaybe<Scalars['Int']>;
  priority_gte?: InputMaybe<Scalars['Int']>;
  priority_in?: InputMaybe<Array<Scalars['Int']>>;
  priority_lt?: InputMaybe<Scalars['Int']>;
  priority_lte?: InputMaybe<Scalars['Int']>;
  priority_not?: InputMaybe<Scalars['Int']>;
  priority_not_in?: InputMaybe<Array<Scalars['Int']>>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_not?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_not?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  template?: InputMaybe<Scalars['String']>;
  template_contains?: InputMaybe<Scalars['String']>;
  template_ends_with?: InputMaybe<Scalars['String']>;
  template_gt?: InputMaybe<Scalars['String']>;
  template_gte?: InputMaybe<Scalars['String']>;
  template_in?: InputMaybe<Array<Scalars['String']>>;
  template_lt?: InputMaybe<Scalars['String']>;
  template_lte?: InputMaybe<Scalars['String']>;
  template_not?: InputMaybe<Scalars['String']>;
  template_not_contains?: InputMaybe<Scalars['String']>;
  template_not_ends_with?: InputMaybe<Scalars['String']>;
  template_not_in?: InputMaybe<Array<Scalars['String']>>;
  template_not_starts_with?: InputMaybe<Scalars['String']>;
  template_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeaturedSlideWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<FeaturedSlideWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<FeaturedSlideWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<FeaturedSlideWherePersistencyContractsInput>>;
  building?: InputMaybe<BuildingWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  priority_gt?: InputMaybe<Scalars['Int']>;
  priority_gte?: InputMaybe<Scalars['Int']>;
  priority_in?: InputMaybe<Array<Scalars['Int']>>;
  priority_lt?: InputMaybe<Scalars['Int']>;
  priority_lte?: InputMaybe<Scalars['Int']>;
  priority_not?: InputMaybe<Scalars['Int']>;
  priority_not_in?: InputMaybe<Array<Scalars['Int']>>;
  publishEndDate?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_not?: InputMaybe<Scalars['DateTime']>;
  publishEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishStartDate?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishStartDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_not?: InputMaybe<Scalars['DateTime']>;
  publishStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  template?: InputMaybe<Scalars['String']>;
  template_contains?: InputMaybe<Scalars['String']>;
  template_ends_with?: InputMaybe<Scalars['String']>;
  template_gt?: InputMaybe<Scalars['String']>;
  template_gte?: InputMaybe<Scalars['String']>;
  template_in?: InputMaybe<Array<Scalars['String']>>;
  template_lt?: InputMaybe<Scalars['String']>;
  template_lte?: InputMaybe<Scalars['String']>;
  template_not?: InputMaybe<Scalars['String']>;
  template_not_contains?: InputMaybe<Scalars['String']>;
  template_not_ends_with?: InputMaybe<Scalars['String']>;
  template_not_in?: InputMaybe<Array<Scalars['String']>>;
  template_not_starts_with?: InputMaybe<Scalars['String']>;
  template_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeaturedSlideWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FeedClassification = {
  __typename?: 'FeedClassification';
  _empty?: Maybe<Scalars['String']>;
  aiProvider: AiProvider;
  classification: Array<ClassificationCategory>;
  classificationScore?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: ClassificationEntityType;
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FeedClassificationConnection = {
  __typename?: 'FeedClassificationConnection';
  aggregate: AggregateFeedClassification;
  edges: Array<Maybe<FeedClassificationEdge>>;
  pageInfo: PageInfo;
};

export type FeedClassificationCreateInput = {
  aiProvider: AiProvider;
  classification?: InputMaybe<FeedClassificationCreateclassificationInput>;
  classificationScore?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: ClassificationEntityType;
  text: Scalars['String'];
};

export type FeedClassificationCreateclassificationInput = {
  set?: InputMaybe<Array<ClassificationCategory>>;
};

export type FeedClassificationEdge = {
  __typename?: 'FeedClassificationEdge';
  cursor: Scalars['String'];
  node: FeedClassification;
};

export enum FeedClassificationOrderByInput {
  AIPROVIDER_ASC = 'aiProvider_ASC',
  AIPROVIDER_DESC = 'aiProvider_DESC',
  CLASSIFICATIONSCORE_ASC = 'classificationScore_ASC',
  CLASSIFICATIONSCORE_DESC = 'classificationScore_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENTITYID_ASC = 'entityId_ASC',
  ENTITYID_DESC = 'entityId_DESC',
  ENTITYTYPE_ASC = 'entityType_ASC',
  ENTITYTYPE_DESC = 'entityType_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  TEXT_ASC = 'text_ASC',
  TEXT_DESC = 'text_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type FeedClassificationUpdateInput = {
  aiProvider?: InputMaybe<AiProvider>;
  classification?: InputMaybe<FeedClassificationUpdateclassificationInput>;
  classificationScore?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<ClassificationEntityType>;
  text?: InputMaybe<Scalars['String']>;
};

export type FeedClassificationUpdateclassificationInput = {
  set?: InputMaybe<Array<ClassificationCategory>>;
};

export type FeedClassificationWhereInput = {
  AND?: InputMaybe<Array<FeedClassificationWhereInput>>;
  NOT?: InputMaybe<Array<FeedClassificationWhereInput>>;
  OR?: InputMaybe<Array<FeedClassificationWhereInput>>;
  aiProvider?: InputMaybe<AiProvider>;
  aiProvider_in?: InputMaybe<Array<AiProvider>>;
  aiProvider_not?: InputMaybe<AiProvider>;
  aiProvider_not_in?: InputMaybe<Array<AiProvider>>;
  classificationScore?: InputMaybe<Scalars['String']>;
  classificationScore_contains?: InputMaybe<Scalars['String']>;
  classificationScore_ends_with?: InputMaybe<Scalars['String']>;
  classificationScore_gt?: InputMaybe<Scalars['String']>;
  classificationScore_gte?: InputMaybe<Scalars['String']>;
  classificationScore_in?: InputMaybe<Array<Scalars['String']>>;
  classificationScore_lt?: InputMaybe<Scalars['String']>;
  classificationScore_lte?: InputMaybe<Scalars['String']>;
  classificationScore_not?: InputMaybe<Scalars['String']>;
  classificationScore_not_contains?: InputMaybe<Scalars['String']>;
  classificationScore_not_ends_with?: InputMaybe<Scalars['String']>;
  classificationScore_not_in?: InputMaybe<Array<Scalars['String']>>;
  classificationScore_not_starts_with?: InputMaybe<Scalars['String']>;
  classificationScore_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entityId?: InputMaybe<Scalars['String']>;
  entityId_contains?: InputMaybe<Scalars['String']>;
  entityId_ends_with?: InputMaybe<Scalars['String']>;
  entityId_gt?: InputMaybe<Scalars['String']>;
  entityId_gte?: InputMaybe<Scalars['String']>;
  entityId_in?: InputMaybe<Array<Scalars['String']>>;
  entityId_lt?: InputMaybe<Scalars['String']>;
  entityId_lte?: InputMaybe<Scalars['String']>;
  entityId_not?: InputMaybe<Scalars['String']>;
  entityId_not_contains?: InputMaybe<Scalars['String']>;
  entityId_not_ends_with?: InputMaybe<Scalars['String']>;
  entityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  entityId_not_starts_with?: InputMaybe<Scalars['String']>;
  entityId_starts_with?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<ClassificationEntityType>;
  entityType_in?: InputMaybe<Array<ClassificationEntityType>>;
  entityType_not?: InputMaybe<ClassificationEntityType>;
  entityType_not_in?: InputMaybe<Array<ClassificationEntityType>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_ends_with?: InputMaybe<Scalars['String']>;
  text_gt?: InputMaybe<Scalars['String']>;
  text_gte?: InputMaybe<Scalars['String']>;
  text_in?: InputMaybe<Array<Scalars['String']>>;
  text_lt?: InputMaybe<Scalars['String']>;
  text_lte?: InputMaybe<Scalars['String']>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<Scalars['String']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  text_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeedClassificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FetchCsatSurveysInput = {
  hoodId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type FetchCsatSurveysResponse = {
  __typename?: 'FetchCsatSurveysResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Maybe<SurveyQuestion>>>;
  surveyDescription?: Maybe<Scalars['String']>;
  surveyTitle?: Maybe<Scalars['String']>;
  uiAction?: Maybe<Scalars['String']>;
  uiScreen?: Maybe<Scalars['String']>;
};

export type FindNeighborsQueryOutput = {
  __typename?: 'FindNeighborsQueryOutput';
  memberProfiles: Array<MemberProfile>;
  seed: Scalars['Int'];
};

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NA = 'NA',
  NON_BINARY = 'NON_BINARY'
}

export type HoldDepositSettings = {
  __typename?: 'HoldDepositSettings';
  amount?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
};

export type Hood = {
  __typename?: 'Hood';
  _empty?: Maybe<Scalars['String']>;
  communityManagers?: Maybe<Array<User>>;
  coordinates?: Maybe<Scalars['Json']>;
  country: CountryCode;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  propertyManagementOffice?: Maybe<PropertyManagementOffice>;
  settings?: Maybe<Settings>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  uiMetadata?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type HoodCommunityManagersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type HoodConnection = {
  __typename?: 'HoodConnection';
  aggregate: AggregateHood;
  edges: Array<Maybe<HoodEdge>>;
  pageInfo: PageInfo;
};

export type HoodCreateInput = {
  communityManagers?: InputMaybe<UserCreateManyWithoutHoodInput>;
  coordinates?: InputMaybe<Scalars['Json']>;
  country: CountryCode;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeCreateOneInput>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  uiMetadata?: InputMaybe<Scalars['Json']>;
};

export type HoodCreateManyInput = {
  connect?: InputMaybe<Array<HoodWhereUniqueInput>>;
  create?: InputMaybe<Array<HoodCreateInput>>;
};

export type HoodCreateOneInput = {
  connect?: InputMaybe<HoodWhereUniqueInput>;
  create?: InputMaybe<HoodCreateInput>;
};

export type HoodCreateOneWithoutCommunityManagersInput = {
  connect?: InputMaybe<HoodWhereUniqueInput>;
  create?: InputMaybe<HoodCreateWithoutCommunityManagersInput>;
};

export type HoodCreateWithoutCommunityManagersInput = {
  coordinates?: InputMaybe<Scalars['Json']>;
  country: CountryCode;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeCreateOneInput>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  uiMetadata?: InputMaybe<Scalars['Json']>;
};

export type HoodEdge = {
  __typename?: 'HoodEdge';
  cursor: Scalars['String'];
  node: Hood;
};

export enum HoodOrderByInput {
  COORDINATES_ASC = 'coordinates_ASC',
  COORDINATES_DESC = 'coordinates_DESC',
  COUNTRY_ASC = 'country_ASC',
  COUNTRY_DESC = 'country_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DISPLAYNAME_ASC = 'displayName_ASC',
  DISPLAYNAME_DESC = 'displayName_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  LANGUAGE_ASC = 'language_ASC',
  LANGUAGE_DESC = 'language_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  STATE_ASC = 'state_ASC',
  STATE_DESC = 'state_DESC',
  TIMEZONE_ASC = 'timezone_ASC',
  TIMEZONE_DESC = 'timezone_DESC',
  UIMETADATA_ASC = 'uiMetadata_ASC',
  UIMETADATA_DESC = 'uiMetadata_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type HoodScalarWhereInput = {
  AND?: InputMaybe<Array<HoodScalarWhereInput>>;
  NOT?: InputMaybe<Array<HoodScalarWhereInput>>;
  OR?: InputMaybe<Array<HoodScalarWhereInput>>;
  country?: InputMaybe<CountryCode>;
  country_in?: InputMaybe<Array<CountryCode>>;
  country_not?: InputMaybe<CountryCode>;
  country_not_in?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  displayName?: InputMaybe<Scalars['String']>;
  displayName_contains?: InputMaybe<Scalars['String']>;
  displayName_ends_with?: InputMaybe<Scalars['String']>;
  displayName_gt?: InputMaybe<Scalars['String']>;
  displayName_gte?: InputMaybe<Scalars['String']>;
  displayName_in?: InputMaybe<Array<Scalars['String']>>;
  displayName_lt?: InputMaybe<Scalars['String']>;
  displayName_lte?: InputMaybe<Scalars['String']>;
  displayName_not?: InputMaybe<Scalars['String']>;
  displayName_not_contains?: InputMaybe<Scalars['String']>;
  displayName_not_ends_with?: InputMaybe<Scalars['String']>;
  displayName_not_in?: InputMaybe<Array<Scalars['String']>>;
  displayName_not_starts_with?: InputMaybe<Scalars['String']>;
  displayName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['String']>;
  language_contains?: InputMaybe<Scalars['String']>;
  language_ends_with?: InputMaybe<Scalars['String']>;
  language_gt?: InputMaybe<Scalars['String']>;
  language_gte?: InputMaybe<Scalars['String']>;
  language_in?: InputMaybe<Array<Scalars['String']>>;
  language_lt?: InputMaybe<Scalars['String']>;
  language_lte?: InputMaybe<Scalars['String']>;
  language_not?: InputMaybe<Scalars['String']>;
  language_not_contains?: InputMaybe<Scalars['String']>;
  language_not_ends_with?: InputMaybe<Scalars['String']>;
  language_not_in?: InputMaybe<Array<Scalars['String']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']>;
  language_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  timezone_contains?: InputMaybe<Scalars['String']>;
  timezone_ends_with?: InputMaybe<Scalars['String']>;
  timezone_gt?: InputMaybe<Scalars['String']>;
  timezone_gte?: InputMaybe<Scalars['String']>;
  timezone_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_lt?: InputMaybe<Scalars['String']>;
  timezone_lte?: InputMaybe<Scalars['String']>;
  timezone_not?: InputMaybe<Scalars['String']>;
  timezone_not_contains?: InputMaybe<Scalars['String']>;
  timezone_not_ends_with?: InputMaybe<Scalars['String']>;
  timezone_not_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_not_starts_with?: InputMaybe<Scalars['String']>;
  timezone_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type HoodSetting = {
  __typename?: 'HoodSetting';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  group: HoodSettingsGroup;
  id: Scalars['ID'];
  isArray?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name: Scalars['String'];
  settingValues?: Maybe<Array<HoodSettingValue>>;
  type: HoodSettingType;
  updatedAt: Scalars['DateTime'];
};


export type HoodSettingSettingValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingValueOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingValueWhereInput>;
};

export type HoodSettingConnection = {
  __typename?: 'HoodSettingConnection';
  aggregate: AggregateHoodSetting;
  edges: Array<Maybe<HoodSettingEdge>>;
  pageInfo: PageInfo;
};

export type HoodSettingCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  group: HoodSettingsGroupCreateOneWithoutSettingsInput;
  isArray?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name: Scalars['String'];
  settingValues?: InputMaybe<HoodSettingValueCreateManyWithoutHoodSettingInput>;
  type: HoodSettingType;
};

export type HoodSettingCreateManyWithoutGroupInput = {
  connect?: InputMaybe<Array<HoodSettingWhereUniqueInput>>;
  create?: InputMaybe<Array<HoodSettingCreateWithoutGroupInput>>;
};

export type HoodSettingCreateOneWithoutSettingValuesInput = {
  connect?: InputMaybe<HoodSettingWhereUniqueInput>;
  create?: InputMaybe<HoodSettingCreateWithoutSettingValuesInput>;
};

export type HoodSettingCreateWithoutGroupInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  isArray?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name: Scalars['String'];
  settingValues?: InputMaybe<HoodSettingValueCreateManyWithoutHoodSettingInput>;
  type: HoodSettingType;
};

export type HoodSettingCreateWithoutSettingValuesInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  group: HoodSettingsGroupCreateOneWithoutSettingsInput;
  isArray?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name: Scalars['String'];
  type: HoodSettingType;
};

export type HoodSettingEdge = {
  __typename?: 'HoodSettingEdge';
  cursor: Scalars['String'];
  node: HoodSetting;
};

export enum HoodSettingOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISARRAY_ASC = 'isArray_ASC',
  ISARRAY_DESC = 'isArray_DESC',
  KEY_ASC = 'key_ASC',
  KEY_DESC = 'key_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type HoodSettingScalarWhereInput = {
  AND?: InputMaybe<Array<HoodSettingScalarWhereInput>>;
  NOT?: InputMaybe<Array<HoodSettingScalarWhereInput>>;
  OR?: InputMaybe<Array<HoodSettingScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isArray?: InputMaybe<Scalars['Boolean']>;
  isArray_not?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_ends_with?: InputMaybe<Scalars['String']>;
  key_gt?: InputMaybe<Scalars['String']>;
  key_gte?: InputMaybe<Scalars['String']>;
  key_in?: InputMaybe<Array<Scalars['String']>>;
  key_lt?: InputMaybe<Scalars['String']>;
  key_lte?: InputMaybe<Scalars['String']>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<Scalars['String']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  key_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<HoodSettingType>;
  type_in?: InputMaybe<Array<HoodSettingType>>;
  type_not?: InputMaybe<HoodSettingType>;
  type_not_in?: InputMaybe<Array<HoodSettingType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum HoodSettingType {
  BOOLEAN = 'BOOLEAN',
  COLOR = 'COLOR',
  DATE = 'DATE',
  IMAGE = 'IMAGE',
  JSON = 'JSON',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export type HoodSettingUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<HoodSettingsGroupUpdateOneRequiredWithoutSettingsInput>;
  isArray?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  settingValues?: InputMaybe<HoodSettingValueUpdateManyWithoutHoodSettingInput>;
  type?: InputMaybe<HoodSettingType>;
};

export type HoodSettingUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  isArray?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<HoodSettingType>;
};

export type HoodSettingUpdateManyWithWhereNestedInput = {
  data: HoodSettingUpdateManyDataInput;
  where: HoodSettingScalarWhereInput;
};

export type HoodSettingUpdateManyWithoutGroupInput = {
  connect?: InputMaybe<Array<HoodSettingWhereUniqueInput>>;
  create?: InputMaybe<Array<HoodSettingCreateWithoutGroupInput>>;
  delete?: InputMaybe<Array<HoodSettingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HoodSettingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HoodSettingWhereUniqueInput>>;
  set?: InputMaybe<Array<HoodSettingWhereUniqueInput>>;
  update?: InputMaybe<Array<HoodSettingUpdateWithWhereUniqueWithoutGroupInput>>;
  updateMany?: InputMaybe<Array<HoodSettingUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<HoodSettingUpsertWithWhereUniqueWithoutGroupInput>>;
};

export type HoodSettingUpdateOneRequiredWithoutSettingValuesInput = {
  connect?: InputMaybe<HoodSettingWhereUniqueInput>;
  create?: InputMaybe<HoodSettingCreateWithoutSettingValuesInput>;
  update?: InputMaybe<HoodSettingUpdateWithoutSettingValuesDataInput>;
  upsert?: InputMaybe<HoodSettingUpsertWithoutSettingValuesInput>;
};

export type HoodSettingUpdateWithWhereUniqueWithoutGroupInput = {
  data: HoodSettingUpdateWithoutGroupDataInput;
  where: HoodSettingWhereUniqueInput;
};

export type HoodSettingUpdateWithoutGroupDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  isArray?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  settingValues?: InputMaybe<HoodSettingValueUpdateManyWithoutHoodSettingInput>;
  type?: InputMaybe<HoodSettingType>;
};

export type HoodSettingUpdateWithoutSettingValuesDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<HoodSettingsGroupUpdateOneRequiredWithoutSettingsInput>;
  isArray?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<HoodSettingType>;
};

export type HoodSettingUpsertWithWhereUniqueWithoutGroupInput = {
  create: HoodSettingCreateWithoutGroupInput;
  update: HoodSettingUpdateWithoutGroupDataInput;
  where: HoodSettingWhereUniqueInput;
};

export type HoodSettingUpsertWithoutSettingValuesInput = {
  create: HoodSettingCreateWithoutSettingValuesInput;
  update: HoodSettingUpdateWithoutSettingValuesDataInput;
};

export type HoodSettingValue = {
  __typename?: 'HoodSettingValue';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood?: Maybe<Hood>;
  hoodId: Scalars['String'];
  hoodSetting: HoodSetting;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['String']>;
};

export type HoodSettingValueConnection = {
  __typename?: 'HoodSettingValueConnection';
  aggregate: AggregateHoodSettingValue;
  edges: Array<Maybe<HoodSettingValueEdge>>;
  pageInfo: PageInfo;
};

export type HoodSettingValueCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodId: Scalars['String'];
  hoodSetting: HoodSettingCreateOneWithoutSettingValuesInput;
  value?: InputMaybe<Scalars['String']>;
};

export type HoodSettingValueCreateManyWithoutHoodSettingInput = {
  connect?: InputMaybe<Array<HoodSettingValueWhereUniqueInput>>;
  create?: InputMaybe<Array<HoodSettingValueCreateWithoutHoodSettingInput>>;
};

export type HoodSettingValueCreateWithoutHoodSettingInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodId: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type HoodSettingValueEdge = {
  __typename?: 'HoodSettingValueEdge';
  cursor: Scalars['String'];
  node: HoodSettingValue;
};

export enum HoodSettingValueOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  HOODID_ASC = 'hoodId_ASC',
  HOODID_DESC = 'hoodId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC'
}

export type HoodSettingValueScalarWhereInput = {
  AND?: InputMaybe<Array<HoodSettingValueScalarWhereInput>>;
  NOT?: InputMaybe<Array<HoodSettingValueScalarWhereInput>>;
  OR?: InputMaybe<Array<HoodSettingValueScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hoodId?: InputMaybe<Scalars['String']>;
  hoodId_contains?: InputMaybe<Scalars['String']>;
  hoodId_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_gt?: InputMaybe<Scalars['String']>;
  hoodId_gte?: InputMaybe<Scalars['String']>;
  hoodId_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_lt?: InputMaybe<Scalars['String']>;
  hoodId_lte?: InputMaybe<Scalars['String']>;
  hoodId_not?: InputMaybe<Scalars['String']>;
  hoodId_not_contains?: InputMaybe<Scalars['String']>;
  hoodId_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_ends_with?: InputMaybe<Scalars['String']>;
  value_gt?: InputMaybe<Scalars['String']>;
  value_gte?: InputMaybe<Scalars['String']>;
  value_in?: InputMaybe<Array<Scalars['String']>>;
  value_lt?: InputMaybe<Scalars['String']>;
  value_lte?: InputMaybe<Scalars['String']>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<Scalars['String']>>;
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export type HoodSettingValueUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  hoodSetting?: InputMaybe<HoodSettingUpdateOneRequiredWithoutSettingValuesInput>;
  value?: InputMaybe<Scalars['String']>;
};

export type HoodSettingValueUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type HoodSettingValueUpdateManyWithWhereNestedInput = {
  data: HoodSettingValueUpdateManyDataInput;
  where: HoodSettingValueScalarWhereInput;
};

export type HoodSettingValueUpdateManyWithoutHoodSettingInput = {
  connect?: InputMaybe<Array<HoodSettingValueWhereUniqueInput>>;
  create?: InputMaybe<Array<HoodSettingValueCreateWithoutHoodSettingInput>>;
  delete?: InputMaybe<Array<HoodSettingValueWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HoodSettingValueScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HoodSettingValueWhereUniqueInput>>;
  set?: InputMaybe<Array<HoodSettingValueWhereUniqueInput>>;
  update?: InputMaybe<Array<HoodSettingValueUpdateWithWhereUniqueWithoutHoodSettingInput>>;
  updateMany?: InputMaybe<Array<HoodSettingValueUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<HoodSettingValueUpsertWithWhereUniqueWithoutHoodSettingInput>>;
};

export type HoodSettingValueUpdateWithWhereUniqueWithoutHoodSettingInput = {
  data: HoodSettingValueUpdateWithoutHoodSettingDataInput;
  where: HoodSettingValueWhereUniqueInput;
};

export type HoodSettingValueUpdateWithoutHoodSettingDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type HoodSettingValueUpsertWithWhereUniqueWithoutHoodSettingInput = {
  create: HoodSettingValueCreateWithoutHoodSettingInput;
  update: HoodSettingValueUpdateWithoutHoodSettingDataInput;
  where: HoodSettingValueWhereUniqueInput;
};

export type HoodSettingValueWhereInput = {
  AND?: InputMaybe<Array<HoodSettingValueWhereInput>>;
  NOT?: InputMaybe<Array<HoodSettingValueWhereInput>>;
  OR?: InputMaybe<Array<HoodSettingValueWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hoodId?: InputMaybe<Scalars['String']>;
  hoodId_contains?: InputMaybe<Scalars['String']>;
  hoodId_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_gt?: InputMaybe<Scalars['String']>;
  hoodId_gte?: InputMaybe<Scalars['String']>;
  hoodId_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_lt?: InputMaybe<Scalars['String']>;
  hoodId_lte?: InputMaybe<Scalars['String']>;
  hoodId_not?: InputMaybe<Scalars['String']>;
  hoodId_not_contains?: InputMaybe<Scalars['String']>;
  hoodId_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodId_starts_with?: InputMaybe<Scalars['String']>;
  hoodSetting?: InputMaybe<HoodSettingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_ends_with?: InputMaybe<Scalars['String']>;
  value_gt?: InputMaybe<Scalars['String']>;
  value_gte?: InputMaybe<Scalars['String']>;
  value_in?: InputMaybe<Array<Scalars['String']>>;
  value_lt?: InputMaybe<Scalars['String']>;
  value_lte?: InputMaybe<Scalars['String']>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<Scalars['String']>>;
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export type HoodSettingValueWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type HoodSettingWhereInput = {
  AND?: InputMaybe<Array<HoodSettingWhereInput>>;
  NOT?: InputMaybe<Array<HoodSettingWhereInput>>;
  OR?: InputMaybe<Array<HoodSettingWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<HoodSettingsGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isArray?: InputMaybe<Scalars['Boolean']>;
  isArray_not?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_ends_with?: InputMaybe<Scalars['String']>;
  key_gt?: InputMaybe<Scalars['String']>;
  key_gte?: InputMaybe<Scalars['String']>;
  key_in?: InputMaybe<Array<Scalars['String']>>;
  key_lt?: InputMaybe<Scalars['String']>;
  key_lte?: InputMaybe<Scalars['String']>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<Scalars['String']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  key_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  settingValues_every?: InputMaybe<HoodSettingValueWhereInput>;
  settingValues_none?: InputMaybe<HoodSettingValueWhereInput>;
  settingValues_some?: InputMaybe<HoodSettingValueWhereInput>;
  type?: InputMaybe<HoodSettingType>;
  type_in?: InputMaybe<Array<HoodSettingType>>;
  type_not?: InputMaybe<HoodSettingType>;
  type_not_in?: InputMaybe<Array<HoodSettingType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type HoodSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type HoodSettingsGroup = {
  __typename?: 'HoodSettingsGroup';
  _empty?: Maybe<Scalars['String']>;
  children?: Maybe<Array<HoodSettingsGroup>>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isRoot?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  parent?: Maybe<HoodSettingsGroup>;
  settings?: Maybe<Array<HoodSetting>>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type HoodSettingsGroupChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingsGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingsGroupWhereInput>;
};


export type HoodSettingsGroupSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingWhereInput>;
};

export type HoodSettingsGroupConnection = {
  __typename?: 'HoodSettingsGroupConnection';
  aggregate: AggregateHoodSettingsGroup;
  edges: Array<Maybe<HoodSettingsGroupEdge>>;
  pageInfo: PageInfo;
};

export type HoodSettingsGroupCreateInput = {
  children?: InputMaybe<HoodSettingsGroupCreateManyInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  parent?: InputMaybe<HoodSettingsGroupCreateOneInput>;
  settings?: InputMaybe<HoodSettingCreateManyWithoutGroupInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type HoodSettingsGroupCreateManyInput = {
  connect?: InputMaybe<Array<HoodSettingsGroupWhereUniqueInput>>;
  create?: InputMaybe<Array<HoodSettingsGroupCreateInput>>;
};

export type HoodSettingsGroupCreateOneInput = {
  connect?: InputMaybe<HoodSettingsGroupWhereUniqueInput>;
  create?: InputMaybe<HoodSettingsGroupCreateInput>;
};

export type HoodSettingsGroupCreateOneWithoutSettingsInput = {
  connect?: InputMaybe<HoodSettingsGroupWhereUniqueInput>;
  create?: InputMaybe<HoodSettingsGroupCreateWithoutSettingsInput>;
};

export type HoodSettingsGroupCreateWithoutSettingsInput = {
  children?: InputMaybe<HoodSettingsGroupCreateManyInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  parent?: InputMaybe<HoodSettingsGroupCreateOneInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type HoodSettingsGroupEdge = {
  __typename?: 'HoodSettingsGroupEdge';
  cursor: Scalars['String'];
  node: HoodSettingsGroup;
};

export enum HoodSettingsGroupOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISROOT_ASC = 'isRoot_ASC',
  ISROOT_DESC = 'isRoot_DESC',
  KEY_ASC = 'key_ASC',
  KEY_DESC = 'key_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type HoodSettingsGroupScalarWhereInput = {
  AND?: InputMaybe<Array<HoodSettingsGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<HoodSettingsGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<HoodSettingsGroupScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  isRoot_not?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_ends_with?: InputMaybe<Scalars['String']>;
  key_gt?: InputMaybe<Scalars['String']>;
  key_gte?: InputMaybe<Scalars['String']>;
  key_in?: InputMaybe<Array<Scalars['String']>>;
  key_lt?: InputMaybe<Scalars['String']>;
  key_lte?: InputMaybe<Scalars['String']>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<Scalars['String']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  key_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_ends_with?: InputMaybe<Scalars['String']>;
  title_gt?: InputMaybe<Scalars['String']>;
  title_gte?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  title_lt?: InputMaybe<Scalars['String']>;
  title_lte?: InputMaybe<Scalars['String']>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type HoodSettingsGroupUpdateDataInput = {
  children?: InputMaybe<HoodSettingsGroupUpdateManyInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<HoodSettingsGroupUpdateOneInput>;
  settings?: InputMaybe<HoodSettingUpdateManyWithoutGroupInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type HoodSettingsGroupUpdateInput = {
  children?: InputMaybe<HoodSettingsGroupUpdateManyInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<HoodSettingsGroupUpdateOneInput>;
  settings?: InputMaybe<HoodSettingUpdateManyWithoutGroupInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type HoodSettingsGroupUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type HoodSettingsGroupUpdateManyInput = {
  connect?: InputMaybe<Array<HoodSettingsGroupWhereUniqueInput>>;
  create?: InputMaybe<Array<HoodSettingsGroupCreateInput>>;
  delete?: InputMaybe<Array<HoodSettingsGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HoodSettingsGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HoodSettingsGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<HoodSettingsGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<HoodSettingsGroupUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<HoodSettingsGroupUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<HoodSettingsGroupUpsertWithWhereUniqueNestedInput>>;
};

export type HoodSettingsGroupUpdateManyWithWhereNestedInput = {
  data: HoodSettingsGroupUpdateManyDataInput;
  where: HoodSettingsGroupScalarWhereInput;
};

export type HoodSettingsGroupUpdateOneInput = {
  connect?: InputMaybe<HoodSettingsGroupWhereUniqueInput>;
  create?: InputMaybe<HoodSettingsGroupCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<HoodSettingsGroupUpdateDataInput>;
  upsert?: InputMaybe<HoodSettingsGroupUpsertNestedInput>;
};

export type HoodSettingsGroupUpdateOneRequiredWithoutSettingsInput = {
  connect?: InputMaybe<HoodSettingsGroupWhereUniqueInput>;
  create?: InputMaybe<HoodSettingsGroupCreateWithoutSettingsInput>;
  update?: InputMaybe<HoodSettingsGroupUpdateWithoutSettingsDataInput>;
  upsert?: InputMaybe<HoodSettingsGroupUpsertWithoutSettingsInput>;
};

export type HoodSettingsGroupUpdateWithWhereUniqueNestedInput = {
  data: HoodSettingsGroupUpdateDataInput;
  where: HoodSettingsGroupWhereUniqueInput;
};

export type HoodSettingsGroupUpdateWithoutSettingsDataInput = {
  children?: InputMaybe<HoodSettingsGroupUpdateManyInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<HoodSettingsGroupUpdateOneInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type HoodSettingsGroupUpsertNestedInput = {
  create: HoodSettingsGroupCreateInput;
  update: HoodSettingsGroupUpdateDataInput;
};

export type HoodSettingsGroupUpsertWithWhereUniqueNestedInput = {
  create: HoodSettingsGroupCreateInput;
  update: HoodSettingsGroupUpdateDataInput;
  where: HoodSettingsGroupWhereUniqueInput;
};

export type HoodSettingsGroupUpsertWithoutSettingsInput = {
  create: HoodSettingsGroupCreateWithoutSettingsInput;
  update: HoodSettingsGroupUpdateWithoutSettingsDataInput;
};

export type HoodSettingsGroupWhereInput = {
  AND?: InputMaybe<Array<HoodSettingsGroupWhereInput>>;
  NOT?: InputMaybe<Array<HoodSettingsGroupWhereInput>>;
  OR?: InputMaybe<Array<HoodSettingsGroupWhereInput>>;
  children_every?: InputMaybe<HoodSettingsGroupWhereInput>;
  children_none?: InputMaybe<HoodSettingsGroupWhereInput>;
  children_some?: InputMaybe<HoodSettingsGroupWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  isRoot_not?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_ends_with?: InputMaybe<Scalars['String']>;
  key_gt?: InputMaybe<Scalars['String']>;
  key_gte?: InputMaybe<Scalars['String']>;
  key_in?: InputMaybe<Array<Scalars['String']>>;
  key_lt?: InputMaybe<Scalars['String']>;
  key_lte?: InputMaybe<Scalars['String']>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<Scalars['String']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  key_starts_with?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<HoodSettingsGroupWhereInput>;
  settings_every?: InputMaybe<HoodSettingWhereInput>;
  settings_none?: InputMaybe<HoodSettingWhereInput>;
  settings_some?: InputMaybe<HoodSettingWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_ends_with?: InputMaybe<Scalars['String']>;
  title_gt?: InputMaybe<Scalars['String']>;
  title_gte?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  title_lt?: InputMaybe<Scalars['String']>;
  title_lte?: InputMaybe<Scalars['String']>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type HoodSettingsGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type HoodUpdateDataInput = {
  communityManagers?: InputMaybe<UserUpdateManyWithoutHoodInput>;
  coordinates?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<CountryCode>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  uiMetadata?: InputMaybe<Scalars['Json']>;
};

export type HoodUpdateInput = {
  communityManagers?: InputMaybe<UserUpdateManyWithoutHoodInput>;
  coordinates?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<CountryCode>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  uiMetadata?: InputMaybe<Scalars['Json']>;
};

export type HoodUpdateManyDataInput = {
  coordinates?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<CountryCode>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  uiMetadata?: InputMaybe<Scalars['Json']>;
};

export type HoodUpdateManyInput = {
  connect?: InputMaybe<Array<HoodWhereUniqueInput>>;
  create?: InputMaybe<Array<HoodCreateInput>>;
  delete?: InputMaybe<Array<HoodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HoodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HoodWhereUniqueInput>>;
  set?: InputMaybe<Array<HoodWhereUniqueInput>>;
  update?: InputMaybe<Array<HoodUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<HoodUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<HoodUpsertWithWhereUniqueNestedInput>>;
};

export type HoodUpdateManyWithWhereNestedInput = {
  data: HoodUpdateManyDataInput;
  where: HoodScalarWhereInput;
};

export type HoodUpdateOneInput = {
  connect?: InputMaybe<HoodWhereUniqueInput>;
  create?: InputMaybe<HoodCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<HoodUpdateDataInput>;
  upsert?: InputMaybe<HoodUpsertNestedInput>;
};

export type HoodUpdateOneRequiredInput = {
  connect?: InputMaybe<HoodWhereUniqueInput>;
  create?: InputMaybe<HoodCreateInput>;
  update?: InputMaybe<HoodUpdateDataInput>;
  upsert?: InputMaybe<HoodUpsertNestedInput>;
};

export type HoodUpdateOneRequiredWithoutCommunityManagersInput = {
  connect?: InputMaybe<HoodWhereUniqueInput>;
  create?: InputMaybe<HoodCreateWithoutCommunityManagersInput>;
  update?: InputMaybe<HoodUpdateWithoutCommunityManagersDataInput>;
  upsert?: InputMaybe<HoodUpsertWithoutCommunityManagersInput>;
};

export type HoodUpdateWithWhereUniqueNestedInput = {
  data: HoodUpdateDataInput;
  where: HoodWhereUniqueInput;
};

export type HoodUpdateWithoutCommunityManagersDataInput = {
  coordinates?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<CountryCode>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeUpdateOneInput>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  uiMetadata?: InputMaybe<Scalars['Json']>;
};

export type HoodUpsertNestedInput = {
  create: HoodCreateInput;
  update: HoodUpdateDataInput;
};

export type HoodUpsertWithWhereUniqueNestedInput = {
  create: HoodCreateInput;
  update: HoodUpdateDataInput;
  where: HoodWhereUniqueInput;
};

export type HoodUpsertWithoutCommunityManagersInput = {
  create: HoodCreateWithoutCommunityManagersInput;
  update: HoodUpdateWithoutCommunityManagersDataInput;
};

export type HoodWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<HoodWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<HoodWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<HoodWhereContractsPersistencyInput>>;
  country?: InputMaybe<ContractsPersistencyCountryCode>;
  country_in?: InputMaybe<Array<ContractsPersistencyCountryCode>>;
  country_not?: InputMaybe<ContractsPersistencyCountryCode>;
  country_not_in?: InputMaybe<Array<ContractsPersistencyCountryCode>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['String']>;
  language_contains?: InputMaybe<Scalars['String']>;
  language_ends_with?: InputMaybe<Scalars['String']>;
  language_gt?: InputMaybe<Scalars['String']>;
  language_gte?: InputMaybe<Scalars['String']>;
  language_in?: InputMaybe<Array<Scalars['String']>>;
  language_lt?: InputMaybe<Scalars['String']>;
  language_lte?: InputMaybe<Scalars['String']>;
  language_not?: InputMaybe<Scalars['String']>;
  language_not_contains?: InputMaybe<Scalars['String']>;
  language_not_ends_with?: InputMaybe<Scalars['String']>;
  language_not_in?: InputMaybe<Array<Scalars['String']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']>;
  language_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  timezone_contains?: InputMaybe<Scalars['String']>;
  timezone_ends_with?: InputMaybe<Scalars['String']>;
  timezone_gt?: InputMaybe<Scalars['String']>;
  timezone_gte?: InputMaybe<Scalars['String']>;
  timezone_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_lt?: InputMaybe<Scalars['String']>;
  timezone_lte?: InputMaybe<Scalars['String']>;
  timezone_not?: InputMaybe<Scalars['String']>;
  timezone_not_contains?: InputMaybe<Scalars['String']>;
  timezone_not_ends_with?: InputMaybe<Scalars['String']>;
  timezone_not_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_not_starts_with?: InputMaybe<Scalars['String']>;
  timezone_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type HoodWhereInput = {
  AND?: InputMaybe<Array<HoodWhereInput>>;
  NOT?: InputMaybe<Array<HoodWhereInput>>;
  OR?: InputMaybe<Array<HoodWhereInput>>;
  communityManagers_every?: InputMaybe<UserWhereInput>;
  communityManagers_none?: InputMaybe<UserWhereInput>;
  communityManagers_some?: InputMaybe<UserWhereInput>;
  country?: InputMaybe<CountryCode>;
  country_in?: InputMaybe<Array<CountryCode>>;
  country_not?: InputMaybe<CountryCode>;
  country_not_in?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  displayName?: InputMaybe<Scalars['String']>;
  displayName_contains?: InputMaybe<Scalars['String']>;
  displayName_ends_with?: InputMaybe<Scalars['String']>;
  displayName_gt?: InputMaybe<Scalars['String']>;
  displayName_gte?: InputMaybe<Scalars['String']>;
  displayName_in?: InputMaybe<Array<Scalars['String']>>;
  displayName_lt?: InputMaybe<Scalars['String']>;
  displayName_lte?: InputMaybe<Scalars['String']>;
  displayName_not?: InputMaybe<Scalars['String']>;
  displayName_not_contains?: InputMaybe<Scalars['String']>;
  displayName_not_ends_with?: InputMaybe<Scalars['String']>;
  displayName_not_in?: InputMaybe<Array<Scalars['String']>>;
  displayName_not_starts_with?: InputMaybe<Scalars['String']>;
  displayName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['String']>;
  language_contains?: InputMaybe<Scalars['String']>;
  language_ends_with?: InputMaybe<Scalars['String']>;
  language_gt?: InputMaybe<Scalars['String']>;
  language_gte?: InputMaybe<Scalars['String']>;
  language_in?: InputMaybe<Array<Scalars['String']>>;
  language_lt?: InputMaybe<Scalars['String']>;
  language_lte?: InputMaybe<Scalars['String']>;
  language_not?: InputMaybe<Scalars['String']>;
  language_not_contains?: InputMaybe<Scalars['String']>;
  language_not_ends_with?: InputMaybe<Scalars['String']>;
  language_not_in?: InputMaybe<Array<Scalars['String']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']>;
  language_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  propertyManagementOffice?: InputMaybe<PropertyManagementOfficeWhereInput>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  timezone_contains?: InputMaybe<Scalars['String']>;
  timezone_ends_with?: InputMaybe<Scalars['String']>;
  timezone_gt?: InputMaybe<Scalars['String']>;
  timezone_gte?: InputMaybe<Scalars['String']>;
  timezone_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_lt?: InputMaybe<Scalars['String']>;
  timezone_lte?: InputMaybe<Scalars['String']>;
  timezone_not?: InputMaybe<Scalars['String']>;
  timezone_not_contains?: InputMaybe<Scalars['String']>;
  timezone_not_ends_with?: InputMaybe<Scalars['String']>;
  timezone_not_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_not_starts_with?: InputMaybe<Scalars['String']>;
  timezone_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type HoodWhereInputPersistency = {
  AND?: InputMaybe<Array<HoodWhereInputPersistency>>;
  NOT?: InputMaybe<Array<HoodWhereInputPersistency>>;
  OR?: InputMaybe<Array<HoodWhereInputPersistency>>;
  country?: InputMaybe<CountryCodePersistency>;
  country_in?: InputMaybe<Array<CountryCodePersistency>>;
  country_not?: InputMaybe<CountryCodePersistency>;
  country_not_in?: InputMaybe<Array<CountryCodePersistency>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['String']>;
  language_contains?: InputMaybe<Scalars['String']>;
  language_ends_with?: InputMaybe<Scalars['String']>;
  language_gt?: InputMaybe<Scalars['String']>;
  language_gte?: InputMaybe<Scalars['String']>;
  language_in?: InputMaybe<Array<Scalars['String']>>;
  language_lt?: InputMaybe<Scalars['String']>;
  language_lte?: InputMaybe<Scalars['String']>;
  language_not?: InputMaybe<Scalars['String']>;
  language_not_contains?: InputMaybe<Scalars['String']>;
  language_not_ends_with?: InputMaybe<Scalars['String']>;
  language_not_in?: InputMaybe<Array<Scalars['String']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']>;
  language_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  timezone_contains?: InputMaybe<Scalars['String']>;
  timezone_ends_with?: InputMaybe<Scalars['String']>;
  timezone_gt?: InputMaybe<Scalars['String']>;
  timezone_gte?: InputMaybe<Scalars['String']>;
  timezone_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_lt?: InputMaybe<Scalars['String']>;
  timezone_lte?: InputMaybe<Scalars['String']>;
  timezone_not?: InputMaybe<Scalars['String']>;
  timezone_not_contains?: InputMaybe<Scalars['String']>;
  timezone_not_ends_with?: InputMaybe<Scalars['String']>;
  timezone_not_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_not_starts_with?: InputMaybe<Scalars['String']>;
  timezone_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type HoodWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<HoodWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<HoodWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<HoodWherePersistencyContractsInput>>;
  country?: InputMaybe<PersistencyContractsCountryCode>;
  country_in?: InputMaybe<Array<PersistencyContractsCountryCode>>;
  country_not?: InputMaybe<PersistencyContractsCountryCode>;
  country_not_in?: InputMaybe<Array<PersistencyContractsCountryCode>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['String']>;
  language_contains?: InputMaybe<Scalars['String']>;
  language_ends_with?: InputMaybe<Scalars['String']>;
  language_gt?: InputMaybe<Scalars['String']>;
  language_gte?: InputMaybe<Scalars['String']>;
  language_in?: InputMaybe<Array<Scalars['String']>>;
  language_lt?: InputMaybe<Scalars['String']>;
  language_lte?: InputMaybe<Scalars['String']>;
  language_not?: InputMaybe<Scalars['String']>;
  language_not_contains?: InputMaybe<Scalars['String']>;
  language_not_ends_with?: InputMaybe<Scalars['String']>;
  language_not_in?: InputMaybe<Array<Scalars['String']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']>;
  language_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  timezone_contains?: InputMaybe<Scalars['String']>;
  timezone_ends_with?: InputMaybe<Scalars['String']>;
  timezone_gt?: InputMaybe<Scalars['String']>;
  timezone_gte?: InputMaybe<Scalars['String']>;
  timezone_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_lt?: InputMaybe<Scalars['String']>;
  timezone_lte?: InputMaybe<Scalars['String']>;
  timezone_not?: InputMaybe<Scalars['String']>;
  timezone_not_contains?: InputMaybe<Scalars['String']>;
  timezone_not_ends_with?: InputMaybe<Scalars['String']>;
  timezone_not_in?: InputMaybe<Array<Scalars['String']>>;
  timezone_not_starts_with?: InputMaybe<Scalars['String']>;
  timezone_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type HoodWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type InitializeContractsResult = {
  __typename?: 'InitializeContractsResult';
  /** @deprecated Will be replaced by real status */
  dummy?: Maybe<Scalars['String']>;
};

export type InteractingMemberProfile = {
  __typename?: 'InteractingMemberProfile';
  communityTitles?: Maybe<Array<Maybe<CommunityTitle>>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type InterestGroup = {
  __typename?: 'InterestGroup';
  createdAt?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  groupSuggestedByNeighbor?: Maybe<Scalars['Boolean']>;
  hoodId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  isGroup?: Maybe<Scalars['Boolean']>;
  isOneWay?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  memberCount?: Maybe<Scalars['Int']>;
  memberProfilesPreview?: Maybe<Array<Maybe<InteractingMemberProfile>>>;
  moderatorsIds?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
};

export type InterestGroupsConnection = {
  __typename?: 'InterestGroupsConnection';
  aggregate?: Maybe<AggregateInterestGroup>;
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  paymentTransactionIds: Array<Scalars['String']>;
  paymentTransactions: Array<PaymentTransaction>;
  status: InvoiceStatus;
  totalTransactionsAmount: Scalars['Float'];
  totalTransactionsFee: Scalars['Float'];
  transferFee: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  aggregate: AggregateInvoice;
  edges: Array<Maybe<InvoiceEdge>>;
  pageInfo: PageInfo;
};

export type InvoiceCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  paymentTransactionIds?: InputMaybe<InvoiceCreatepaymentTransactionIdsInput>;
  status: InvoiceStatus;
  totalTransactionsAmount: Scalars['Float'];
  totalTransactionsFee: Scalars['Float'];
  transferFee: Scalars['Float'];
  userId: Scalars['String'];
};

export type InvoiceCreatepaymentTransactionIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['String'];
  node: Invoice;
};

export enum InvoiceOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  INVOICENUMBER_ASC = 'invoiceNumber_ASC',
  INVOICENUMBER_DESC = 'invoiceNumber_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  TOTALTRANSACTIONSAMOUNT_ASC = 'totalTransactionsAmount_ASC',
  TOTALTRANSACTIONSAMOUNT_DESC = 'totalTransactionsAmount_DESC',
  TOTALTRANSACTIONSFEE_ASC = 'totalTransactionsFee_ASC',
  TOTALTRANSACTIONSFEE_DESC = 'totalTransactionsFee_DESC',
  TRANSFERFEE_ASC = 'transferFee_ASC',
  TRANSFERFEE_DESC = 'transferFee_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export enum InvoiceStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export type InvoiceUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  paymentTransactionIds?: InputMaybe<InvoiceUpdatepaymentTransactionIdsInput>;
  status?: InputMaybe<InvoiceStatus>;
  totalTransactionsAmount?: InputMaybe<Scalars['Float']>;
  totalTransactionsFee?: InputMaybe<Scalars['Float']>;
  transferFee?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type InvoiceUpdatepaymentTransactionIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  invoiceNumber_contains?: InputMaybe<Scalars['String']>;
  invoiceNumber_ends_with?: InputMaybe<Scalars['String']>;
  invoiceNumber_gt?: InputMaybe<Scalars['String']>;
  invoiceNumber_gte?: InputMaybe<Scalars['String']>;
  invoiceNumber_in?: InputMaybe<Array<Scalars['String']>>;
  invoiceNumber_lt?: InputMaybe<Scalars['String']>;
  invoiceNumber_lte?: InputMaybe<Scalars['String']>;
  invoiceNumber_not?: InputMaybe<Scalars['String']>;
  invoiceNumber_not_contains?: InputMaybe<Scalars['String']>;
  invoiceNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  invoiceNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  invoiceNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  invoiceNumber_starts_with?: InputMaybe<Scalars['String']>;
  paymentTransactionIds_contains?: InputMaybe<Scalars['String']>;
  paymentTransactionIds_contains_every?: InputMaybe<Array<Scalars['String']>>;
  paymentTransactionIds_contains_some?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<InvoiceStatus>;
  status_in?: InputMaybe<Array<InvoiceStatus>>;
  status_not?: InputMaybe<InvoiceStatus>;
  status_not_in?: InputMaybe<Array<InvoiceStatus>>;
  totalTransactionsAmount?: InputMaybe<Scalars['Float']>;
  totalTransactionsAmount_gt?: InputMaybe<Scalars['Float']>;
  totalTransactionsAmount_gte?: InputMaybe<Scalars['Float']>;
  totalTransactionsAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  totalTransactionsAmount_lt?: InputMaybe<Scalars['Float']>;
  totalTransactionsAmount_lte?: InputMaybe<Scalars['Float']>;
  totalTransactionsAmount_not?: InputMaybe<Scalars['Float']>;
  totalTransactionsAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  totalTransactionsFee?: InputMaybe<Scalars['Float']>;
  totalTransactionsFee_gt?: InputMaybe<Scalars['Float']>;
  totalTransactionsFee_gte?: InputMaybe<Scalars['Float']>;
  totalTransactionsFee_in?: InputMaybe<Array<Scalars['Float']>>;
  totalTransactionsFee_lt?: InputMaybe<Scalars['Float']>;
  totalTransactionsFee_lte?: InputMaybe<Scalars['Float']>;
  totalTransactionsFee_not?: InputMaybe<Scalars['Float']>;
  totalTransactionsFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  transferFee?: InputMaybe<Scalars['Float']>;
  transferFee_gt?: InputMaybe<Scalars['Float']>;
  transferFee_gte?: InputMaybe<Scalars['Float']>;
  transferFee_in?: InputMaybe<Array<Scalars['Float']>>;
  transferFee_lt?: InputMaybe<Scalars['Float']>;
  transferFee_lte?: InputMaybe<Scalars['Float']>;
  transferFee_not?: InputMaybe<Scalars['Float']>;
  transferFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type InvoiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum LandlordContractType {
  PASS_THROUGH = 'PASS_THROUGH',
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  RENT_GUARANTEE = 'RENT_GUARANTEE',
  REVENUE_SHARE = 'REVENUE_SHARE',
  VENN_CO_OWNERSHIP_LEASE = 'VENN_CO_OWNERSHIP_LEASE',
  VENN_LEASE = 'VENN_LEASE'
}

export enum LandlordContractTypePersistency {
  PASS_THROUGH = 'PASS_THROUGH',
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  RENT_GUARANTEE = 'RENT_GUARANTEE',
  REVENUE_SHARE = 'REVENUE_SHARE',
  VENN_CO_OWNERSHIP_LEASE = 'VENN_CO_OWNERSHIP_LEASE',
  VENN_LEASE = 'VENN_LEASE'
}

export type LeaseContract = {
  __typename?: 'LeaseContract';
  bedroom?: Maybe<Bedroom>;
  bedroomId?: Maybe<Scalars['String']>;
  billableItems: Array<BillableItem>;
  buildingId?: Maybe<Scalars['String']>;
  computedTimeline: ContractTimeline;
  /** @deprecated Replaced by computedTimeline  */
  contractTimeline?: Maybe<LeaseContractTimelineStatus>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  evictionNotice: Scalars['Boolean'];
  externalId?: Maybe<Scalars['String']>;
  hood?: Maybe<Hood>;
  hoodId: Scalars['String'];
  id: Scalars['ID'];
  isCommercialHousing: Scalars['Boolean'];
  isCommercialUse: Scalars['Boolean'];
  isDraft: Scalars['Boolean'];
  isMasterLease: Scalars['Boolean'];
  isMonthToMonth: Scalars['Boolean'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  isRegulated: Scalars['Boolean'];
  isTerminatedEarly: Scalars['Boolean'];
  lateFeeApplicable: Scalars['Boolean'];
  lateFeeGracePeriod: Scalars['Int'];
  member?: Maybe<Member>;
  metadata?: Maybe<Scalars['Json']>;
  moveInDate?: Maybe<Scalars['DateTime']>;
  moveOutDate?: Maybe<Scalars['DateTime']>;
  nonRenewable: Scalars['Boolean'];
  originalEndDate: Scalars['DateTime'];
  renewalIntentAnswerDate?: Maybe<Scalars['DateTime']>;
  renewalIntentComment?: Maybe<Scalars['String']>;
  renewalIntentStatus?: Maybe<RenewalIntentStatus>;
  renewalStatus?: Maybe<RenewalStatus>;
  rent: Scalars['Float'];
  securityDepositAmount?: Maybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  status?: Maybe<ContractStatus>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};


export type LeaseContractBillableItemsArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type LeaseContractConnection = {
  __typename?: 'LeaseContractConnection';
  aggregate: AggregateLeaseContract;
  edges: Array<Maybe<LeaseContractEdge>>;
  pageInfo: PageInfo;
};

export type LeaseContractCreateInput = {
  bedroomId?: InputMaybe<Scalars['String']>;
  billableItems?: InputMaybe<ContractBillableItemCreateContractsPersistencyInput>;
  buildingId?: InputMaybe<Scalars['String']>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  evictionNotice?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  hoodId: Scalars['String'];
  isCommercialHousing?: InputMaybe<Scalars['Boolean']>;
  isCommercialUse?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isMasterLease?: InputMaybe<Scalars['Boolean']>;
  isMonthToMonth?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isRegulated?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  lateFeeGracePeriod?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  moveInDate?: InputMaybe<Scalars['DateTime']>;
  moveOutDate?: InputMaybe<Scalars['DateTime']>;
  nonRenewable?: InputMaybe<Scalars['Boolean']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentComment?: InputMaybe<Scalars['String']>;
  renewalIntentStatus?: InputMaybe<RenewalIntentStatus>;
  renewalStatus?: InputMaybe<RenewalStatus>;
  rent: Scalars['Float'];
  securityDepositAmount?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  status?: InputMaybe<ContractStatus>;
  unitId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type LeaseContractCreateOneInput = {
  connect?: InputMaybe<LeaseContractWhereUniqueInput>;
  create?: InputMaybe<LeaseContractCreateInput>;
};

export type LeaseContractEdge = {
  __typename?: 'LeaseContractEdge';
  cursor: Scalars['String'];
  node: LeaseContract;
};

export enum LeaseContractOrderByInput {
  BEDROOMID_ASC = 'bedroomId_ASC',
  BEDROOMID_DESC = 'bedroomId_DESC',
  BUILDINGID_ASC = 'buildingId_ASC',
  BUILDINGID_DESC = 'buildingId_DESC',
  COMPUTEDTIMELINE_ASC = 'computedTimeline_ASC',
  COMPUTEDTIMELINE_DESC = 'computedTimeline_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENDDATE_ASC = 'endDate_ASC',
  ENDDATE_DESC = 'endDate_DESC',
  EVICTIONNOTICE_ASC = 'evictionNotice_ASC',
  EVICTIONNOTICE_DESC = 'evictionNotice_DESC',
  EXTERNALID_ASC = 'externalId_ASC',
  EXTERNALID_DESC = 'externalId_DESC',
  HOODID_ASC = 'hoodId_ASC',
  HOODID_DESC = 'hoodId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISCOMMERCIALHOUSING_ASC = 'isCommercialHousing_ASC',
  ISCOMMERCIALHOUSING_DESC = 'isCommercialHousing_DESC',
  ISCOMMERCIALUSE_ASC = 'isCommercialUse_ASC',
  ISCOMMERCIALUSE_DESC = 'isCommercialUse_DESC',
  ISDRAFT_ASC = 'isDraft_ASC',
  ISDRAFT_DESC = 'isDraft_DESC',
  ISMASTERLEASE_ASC = 'isMasterLease_ASC',
  ISMASTERLEASE_DESC = 'isMasterLease_DESC',
  ISMONTHTOMONTH_ASC = 'isMonthToMonth_ASC',
  ISMONTHTOMONTH_DESC = 'isMonthToMonth_DESC',
  ISPRIMARY_ASC = 'isPrimary_ASC',
  ISPRIMARY_DESC = 'isPrimary_DESC',
  ISREGULATED_ASC = 'isRegulated_ASC',
  ISREGULATED_DESC = 'isRegulated_DESC',
  LATEFEEAPPLICABLE_ASC = 'lateFeeApplicable_ASC',
  LATEFEEAPPLICABLE_DESC = 'lateFeeApplicable_DESC',
  LATEFEEGRACEPERIOD_ASC = 'lateFeeGracePeriod_ASC',
  LATEFEEGRACEPERIOD_DESC = 'lateFeeGracePeriod_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  MOVEINDATE_ASC = 'moveInDate_ASC',
  MOVEINDATE_DESC = 'moveInDate_DESC',
  MOVEOUTDATE_ASC = 'moveOutDate_ASC',
  MOVEOUTDATE_DESC = 'moveOutDate_DESC',
  NONRENEWABLE_ASC = 'nonRenewable_ASC',
  NONRENEWABLE_DESC = 'nonRenewable_DESC',
  ORIGINALENDDATE_ASC = 'originalEndDate_ASC',
  ORIGINALENDDATE_DESC = 'originalEndDate_DESC',
  RENEWALINTENTANSWERDATE_ASC = 'renewalIntentAnswerDate_ASC',
  RENEWALINTENTANSWERDATE_DESC = 'renewalIntentAnswerDate_DESC',
  RENEWALINTENTCOMMENT_ASC = 'renewalIntentComment_ASC',
  RENEWALINTENTCOMMENT_DESC = 'renewalIntentComment_DESC',
  RENEWALINTENTSTATUS_ASC = 'renewalIntentStatus_ASC',
  RENEWALINTENTSTATUS_DESC = 'renewalIntentStatus_DESC',
  RENEWALSTATUS_ASC = 'renewalStatus_ASC',
  RENEWALSTATUS_DESC = 'renewalStatus_DESC',
  RENT_ASC = 'rent_ASC',
  RENT_DESC = 'rent_DESC',
  SECURITYDEPOSITAMOUNT_ASC = 'securityDepositAmount_ASC',
  SECURITYDEPOSITAMOUNT_DESC = 'securityDepositAmount_DESC',
  STARTDATE_ASC = 'startDate_ASC',
  STARTDATE_DESC = 'startDate_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  UNITID_ASC = 'unitId_ASC',
  UNITID_DESC = 'unitId_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export enum LeaseContractTimelineStatus {
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  PAST = 'PAST'
}

export type LeaseContractTimelineWhereInput = {
  computedTimeline_in?: InputMaybe<Array<ContractTimeline>>;
};

export type LeaseContractUpdateDataInput = {
  bedroomId?: InputMaybe<Scalars['String']>;
  billableItems?: InputMaybe<ContractBillableItemUpdateContractsPersistencyInput>;
  buildingId?: InputMaybe<Scalars['String']>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  evictionNotice?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isCommercialHousing?: InputMaybe<Scalars['Boolean']>;
  isCommercialUse?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isMasterLease?: InputMaybe<Scalars['Boolean']>;
  isMonthToMonth?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isRegulated?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  lateFeeGracePeriod?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  moveInDate?: InputMaybe<Scalars['DateTime']>;
  moveOutDate?: InputMaybe<Scalars['DateTime']>;
  nonRenewable?: InputMaybe<Scalars['Boolean']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentComment?: InputMaybe<Scalars['String']>;
  renewalIntentStatus?: InputMaybe<RenewalIntentStatus>;
  renewalStatus?: InputMaybe<RenewalStatus>;
  rent?: InputMaybe<Scalars['Float']>;
  securityDepositAmount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ContractStatus>;
  unitId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type LeaseContractUpdateInput = {
  bedroomId?: InputMaybe<Scalars['String']>;
  billableItems?: InputMaybe<ContractBillableItemUpdateContractsPersistencyInput>;
  buildingId?: InputMaybe<Scalars['String']>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  evictionNotice?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isCommercialHousing?: InputMaybe<Scalars['Boolean']>;
  isCommercialUse?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isMasterLease?: InputMaybe<Scalars['Boolean']>;
  isMonthToMonth?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isRegulated?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  lateFeeGracePeriod?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  moveInDate?: InputMaybe<Scalars['DateTime']>;
  moveOutDate?: InputMaybe<Scalars['DateTime']>;
  nonRenewable?: InputMaybe<Scalars['Boolean']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentComment?: InputMaybe<Scalars['String']>;
  renewalIntentStatus?: InputMaybe<RenewalIntentStatus>;
  renewalStatus?: InputMaybe<RenewalStatus>;
  rent?: InputMaybe<Scalars['Float']>;
  securityDepositAmount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ContractStatus>;
  unitId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type LeaseContractUpdateManyMutationInput = {
  bedroomId?: InputMaybe<Scalars['String']>;
  buildingId?: InputMaybe<Scalars['String']>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  evictionNotice?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isCommercialHousing?: InputMaybe<Scalars['Boolean']>;
  isCommercialUse?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isMasterLease?: InputMaybe<Scalars['Boolean']>;
  isMonthToMonth?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isRegulated?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  lateFeeGracePeriod?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  moveInDate?: InputMaybe<Scalars['DateTime']>;
  moveOutDate?: InputMaybe<Scalars['DateTime']>;
  nonRenewable?: InputMaybe<Scalars['Boolean']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentComment?: InputMaybe<Scalars['String']>;
  renewalIntentStatus?: InputMaybe<RenewalIntentStatus>;
  renewalStatus?: InputMaybe<RenewalStatus>;
  rent?: InputMaybe<Scalars['Float']>;
  securityDepositAmount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ContractStatus>;
  unitId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type LeaseContractUpdateOneInput = {
  connect?: InputMaybe<LeaseContractWhereUniqueInput>;
  create?: InputMaybe<LeaseContractCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<LeaseContractUpdateDataInput>;
  upsert?: InputMaybe<LeaseContractUpsertNestedInput>;
};

export type LeaseContractUpsertNestedInput = {
  create: LeaseContractCreateInput;
  update: LeaseContractUpdateDataInput;
};

export type LeaseContractWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<LeaseContractWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<LeaseContractWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<LeaseContractWhereContractsPersistencyInput>>;
  bedroom?: InputMaybe<BedroomWhereContractsPersistencyInput>;
  billableItems_every?: InputMaybe<BillableItemWhereContractsPersistencyInput>;
  billableItems_none?: InputMaybe<BillableItemWhereContractsPersistencyInput>;
  billableItems_some?: InputMaybe<BillableItemWhereContractsPersistencyInput>;
  contractTimeline?: InputMaybe<ContractsPersistencyContractTimelineStatus>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isDraft_not?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable_not?: InputMaybe<Scalars['Boolean']>;
  lateFeeGracePeriod?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_gt?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_gte?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_in?: InputMaybe<Array<Scalars['Int']>>;
  lateFeeGracePeriod_lt?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_lte?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_not?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_not_in?: InputMaybe<Array<Scalars['Int']>>;
  member?: InputMaybe<MemberWhereContractsPersistencyInput>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  originalEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  rent?: InputMaybe<Scalars['Float']>;
  rent_gt?: InputMaybe<Scalars['Float']>;
  rent_gte?: InputMaybe<Scalars['Float']>;
  rent_in?: InputMaybe<Array<Scalars['Float']>>;
  rent_lt?: InputMaybe<Scalars['Float']>;
  rent_lte?: InputMaybe<Scalars['Float']>;
  rent_not?: InputMaybe<Scalars['Float']>;
  rent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  securityDepositAmount?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_gt?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_gte?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  securityDepositAmount_lt?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_lte?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_not?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  unit?: InputMaybe<UnitWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type LeaseContractWhereInput = {
  AND?: InputMaybe<Array<LeaseContractWhereInput>>;
  NOT?: InputMaybe<Array<LeaseContractWhereInput>>;
  OR?: InputMaybe<Array<LeaseContractWhereInput>>;
  bedroom?: InputMaybe<BedroomWhereContractsPersistencyInput>;
  bedroomId?: InputMaybe<Scalars['String']>;
  bedroomId_contains?: InputMaybe<Scalars['String']>;
  bedroomId_ends_with?: InputMaybe<Scalars['String']>;
  bedroomId_gt?: InputMaybe<Scalars['String']>;
  bedroomId_gte?: InputMaybe<Scalars['String']>;
  bedroomId_in?: InputMaybe<Array<Scalars['String']>>;
  bedroomId_lt?: InputMaybe<Scalars['String']>;
  bedroomId_lte?: InputMaybe<Scalars['String']>;
  bedroomId_not?: InputMaybe<Scalars['String']>;
  bedroomId_not_contains?: InputMaybe<Scalars['String']>;
  bedroomId_not_ends_with?: InputMaybe<Scalars['String']>;
  bedroomId_not_in?: InputMaybe<Array<Scalars['String']>>;
  bedroomId_not_starts_with?: InputMaybe<Scalars['String']>;
  bedroomId_starts_with?: InputMaybe<Scalars['String']>;
  buildingId?: InputMaybe<Scalars['String']>;
  buildingId_contains?: InputMaybe<Scalars['String']>;
  buildingId_ends_with?: InputMaybe<Scalars['String']>;
  buildingId_gt?: InputMaybe<Scalars['String']>;
  buildingId_gte?: InputMaybe<Scalars['String']>;
  buildingId_in?: InputMaybe<Array<Scalars['String']>>;
  buildingId_lt?: InputMaybe<Scalars['String']>;
  buildingId_lte?: InputMaybe<Scalars['String']>;
  buildingId_not?: InputMaybe<Scalars['String']>;
  buildingId_not_contains?: InputMaybe<Scalars['String']>;
  buildingId_not_ends_with?: InputMaybe<Scalars['String']>;
  buildingId_not_in?: InputMaybe<Array<Scalars['String']>>;
  buildingId_not_starts_with?: InputMaybe<Scalars['String']>;
  buildingId_starts_with?: InputMaybe<Scalars['String']>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  computedTimeline_in?: InputMaybe<Array<ContractTimeline>>;
  computedTimeline_not?: InputMaybe<ContractTimeline>;
  computedTimeline_not_in?: InputMaybe<Array<ContractTimeline>>;
  contractTimeline?: InputMaybe<LeaseContractTimelineStatus>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  evictionNotice?: InputMaybe<Scalars['Boolean']>;
  evictionNotice_not?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  hoodId?: InputMaybe<Scalars['String']>;
  hoodId_contains?: InputMaybe<Scalars['String']>;
  hoodId_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_gt?: InputMaybe<Scalars['String']>;
  hoodId_gte?: InputMaybe<Scalars['String']>;
  hoodId_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_lt?: InputMaybe<Scalars['String']>;
  hoodId_lte?: InputMaybe<Scalars['String']>;
  hoodId_not?: InputMaybe<Scalars['String']>;
  hoodId_not_contains?: InputMaybe<Scalars['String']>;
  hoodId_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isCommercialHousing?: InputMaybe<Scalars['Boolean']>;
  isCommercialHousing_not?: InputMaybe<Scalars['Boolean']>;
  isCommercialUse?: InputMaybe<Scalars['Boolean']>;
  isCommercialUse_not?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isDraft_not?: InputMaybe<Scalars['Boolean']>;
  isMasterLease?: InputMaybe<Scalars['Boolean']>;
  isMasterLease_not?: InputMaybe<Scalars['Boolean']>;
  isMonthToMonth?: InputMaybe<Scalars['Boolean']>;
  isMonthToMonth_not?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isPrimary_not?: InputMaybe<Scalars['Boolean']>;
  isRegulated?: InputMaybe<Scalars['Boolean']>;
  isRegulated_not?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable_not?: InputMaybe<Scalars['Boolean']>;
  lateFeeGracePeriod?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_gt?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_gte?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_in?: InputMaybe<Array<Scalars['Int']>>;
  lateFeeGracePeriod_lt?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_lte?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_not?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_not_in?: InputMaybe<Array<Scalars['Int']>>;
  member?: InputMaybe<MemberWhereContractsPersistencyInput>;
  moveInDate?: InputMaybe<Scalars['DateTime']>;
  moveInDate_gt?: InputMaybe<Scalars['DateTime']>;
  moveInDate_gte?: InputMaybe<Scalars['DateTime']>;
  moveInDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  moveInDate_lt?: InputMaybe<Scalars['DateTime']>;
  moveInDate_lte?: InputMaybe<Scalars['DateTime']>;
  moveInDate_not?: InputMaybe<Scalars['DateTime']>;
  moveInDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  moveOutDate?: InputMaybe<Scalars['DateTime']>;
  moveOutDate_gt?: InputMaybe<Scalars['DateTime']>;
  moveOutDate_gte?: InputMaybe<Scalars['DateTime']>;
  moveOutDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  moveOutDate_lt?: InputMaybe<Scalars['DateTime']>;
  moveOutDate_lte?: InputMaybe<Scalars['DateTime']>;
  moveOutDate_not?: InputMaybe<Scalars['DateTime']>;
  moveOutDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  nonRenewable?: InputMaybe<Scalars['Boolean']>;
  nonRenewable_not?: InputMaybe<Scalars['Boolean']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  originalEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  renewalIntentAnswerDate?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate_gt?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate_gte?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  renewalIntentAnswerDate_lt?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate_lte?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate_not?: InputMaybe<Scalars['DateTime']>;
  renewalIntentAnswerDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  renewalIntentComment?: InputMaybe<Scalars['String']>;
  renewalIntentComment_contains?: InputMaybe<Scalars['String']>;
  renewalIntentComment_ends_with?: InputMaybe<Scalars['String']>;
  renewalIntentComment_gt?: InputMaybe<Scalars['String']>;
  renewalIntentComment_gte?: InputMaybe<Scalars['String']>;
  renewalIntentComment_in?: InputMaybe<Array<Scalars['String']>>;
  renewalIntentComment_lt?: InputMaybe<Scalars['String']>;
  renewalIntentComment_lte?: InputMaybe<Scalars['String']>;
  renewalIntentComment_not?: InputMaybe<Scalars['String']>;
  renewalIntentComment_not_contains?: InputMaybe<Scalars['String']>;
  renewalIntentComment_not_ends_with?: InputMaybe<Scalars['String']>;
  renewalIntentComment_not_in?: InputMaybe<Array<Scalars['String']>>;
  renewalIntentComment_not_starts_with?: InputMaybe<Scalars['String']>;
  renewalIntentComment_starts_with?: InputMaybe<Scalars['String']>;
  renewalIntentStatus?: InputMaybe<RenewalIntentStatus>;
  renewalIntentStatus_in?: InputMaybe<Array<RenewalIntentStatus>>;
  renewalIntentStatus_not?: InputMaybe<RenewalIntentStatus>;
  renewalIntentStatus_not_in?: InputMaybe<Array<RenewalIntentStatus>>;
  renewalStatus?: InputMaybe<RenewalStatus>;
  renewalStatus_in?: InputMaybe<Array<RenewalStatus>>;
  renewalStatus_not?: InputMaybe<RenewalStatus>;
  renewalStatus_not_in?: InputMaybe<Array<RenewalStatus>>;
  rent?: InputMaybe<Scalars['Float']>;
  rent_gt?: InputMaybe<Scalars['Float']>;
  rent_gte?: InputMaybe<Scalars['Float']>;
  rent_in?: InputMaybe<Array<Scalars['Float']>>;
  rent_lt?: InputMaybe<Scalars['Float']>;
  rent_lte?: InputMaybe<Scalars['Float']>;
  rent_not?: InputMaybe<Scalars['Float']>;
  rent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  securityDepositAmount?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_gt?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_gte?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  securityDepositAmount_lt?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_lte?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_not?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<ContractStatus>;
  status_in?: InputMaybe<Array<ContractStatus>>;
  status_not?: InputMaybe<ContractStatus>;
  status_not_in?: InputMaybe<Array<ContractStatus>>;
  unit?: InputMaybe<UnitWhereContractsPersistencyInput>;
  unitId?: InputMaybe<Scalars['String']>;
  unitId_contains?: InputMaybe<Scalars['String']>;
  unitId_ends_with?: InputMaybe<Scalars['String']>;
  unitId_gt?: InputMaybe<Scalars['String']>;
  unitId_gte?: InputMaybe<Scalars['String']>;
  unitId_in?: InputMaybe<Array<Scalars['String']>>;
  unitId_lt?: InputMaybe<Scalars['String']>;
  unitId_lte?: InputMaybe<Scalars['String']>;
  unitId_not?: InputMaybe<Scalars['String']>;
  unitId_not_contains?: InputMaybe<Scalars['String']>;
  unitId_not_ends_with?: InputMaybe<Scalars['String']>;
  unitId_not_in?: InputMaybe<Array<Scalars['String']>>;
  unitId_not_starts_with?: InputMaybe<Scalars['String']>;
  unitId_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type LeaseContractWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<LeaseContractWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<LeaseContractWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<LeaseContractWherePersistencyContractsInput>>;
  bedroom?: InputMaybe<BedroomWherePersistencyContractsInput>;
  billableItems_every?: InputMaybe<BillableItemWherePersistencyContractsInput>;
  billableItems_none?: InputMaybe<BillableItemWherePersistencyContractsInput>;
  billableItems_some?: InputMaybe<BillableItemWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isDraft_not?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable?: InputMaybe<Scalars['Boolean']>;
  lateFeeApplicable_not?: InputMaybe<Scalars['Boolean']>;
  lateFeeGracePeriod?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_gt?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_gte?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_in?: InputMaybe<Array<Scalars['Int']>>;
  lateFeeGracePeriod_lt?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_lte?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_not?: InputMaybe<Scalars['Int']>;
  lateFeeGracePeriod_not_in?: InputMaybe<Array<Scalars['Int']>>;
  member?: InputMaybe<MemberWherePersistencyContractsInput>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  originalEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  rent?: InputMaybe<Scalars['Float']>;
  rent_gt?: InputMaybe<Scalars['Float']>;
  rent_gte?: InputMaybe<Scalars['Float']>;
  rent_in?: InputMaybe<Array<Scalars['Float']>>;
  rent_lt?: InputMaybe<Scalars['Float']>;
  rent_lte?: InputMaybe<Scalars['Float']>;
  rent_not?: InputMaybe<Scalars['Float']>;
  rent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  securityDepositAmount?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_gt?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_gte?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  securityDepositAmount_lt?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_lte?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_not?: InputMaybe<Scalars['Float']>;
  securityDepositAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  unit?: InputMaybe<UnitWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type LeaseContractWhereUniqueInput = {
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum LinkType {
  CHATGROUP = 'chatGroup',
  CREATEDISCUSSION = 'createDiscussion',
  CUSTOM = 'custom',
  EVENT = 'event',
  GENERALINFOPAGE = 'generalInfoPage',
  PLACE = 'place',
  SELFPROFILE = 'selfProfile',
  SERVICE = 'service',
  USER = 'user'
}

export type LockWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<LockWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<LockWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<LockWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isLockAssigned?: InputMaybe<Scalars['Boolean']>;
  isLockAssigned_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<ContractsPersistencyLockProvider>;
  provider_in?: InputMaybe<Array<ContractsPersistencyLockProvider>>;
  provider_not?: InputMaybe<ContractsPersistencyLockProvider>;
  provider_not_in?: InputMaybe<Array<ContractsPersistencyLockProvider>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type LockWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<LockWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<LockWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<LockWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isLockAssigned?: InputMaybe<Scalars['Boolean']>;
  isLockAssigned_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<PersistencyContractsLockProvider>;
  provider_in?: InputMaybe<Array<PersistencyContractsLockProvider>>;
  provider_not?: InputMaybe<PersistencyContractsLockProvider>;
  provider_not_in?: InputMaybe<Array<PersistencyContractsLockProvider>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum ManualPaymentType {
  ACH = 'ACH',
  CASH = 'CASH',
  CHECK = 'CHECK',
  PAYPAL = 'PAYPAL',
  SAP = 'SAP'
}

export type Member = {
  __typename?: 'Member';
  addresses?: Maybe<Array<MemberAddress>>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Gender>;
  hood?: Maybe<Hood>;
  /** @deprecated Hoods is no longer supported, use hood instead */
  hoods?: Maybe<Array<Maybe<Hood>>>;
  id: Scalars['ID'];
  leaseContracts?: Maybe<Array<Maybe<LeaseContract>>>;
  membershipContracts?: Maybe<Array<Maybe<MembershipContract>>>;
  nationality?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};


export type MemberAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberAddressOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberAddressWhereInput>;
};


export type MemberLeaseContractsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LeaseContractOrderByInput>;
  where?: InputMaybe<LeaseContractTimelineWhereInput>;
};


export type MemberMembershipContractsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MembershipContractOrderByInput>;
};

export type MemberAddress = {
  __typename?: 'MemberAddress';
  _empty?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  addressType?: Maybe<AddressType>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  member: Member;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zipcode: Scalars['String'];
};

export type MemberAddressConnection = {
  __typename?: 'MemberAddressConnection';
  aggregate: AggregateMemberAddress;
  edges: Array<Maybe<MemberAddressEdge>>;
  pageInfo: PageInfo;
};

export type MemberAddressCreateInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  city: Scalars['String'];
  country: Scalars['String'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  member: MemberCreateOneWithoutAddressesInput;
  state?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type MemberAddressCreateManyWithoutMemberInput = {
  connect?: InputMaybe<Array<MemberAddressWhereUniqueInput>>;
  create?: InputMaybe<Array<MemberAddressCreateWithoutMemberInput>>;
};

export type MemberAddressCreateWithoutMemberInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  city: Scalars['String'];
  country: Scalars['String'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type MemberAddressEdge = {
  __typename?: 'MemberAddressEdge';
  cursor: Scalars['String'];
  node: MemberAddress;
};

export enum MemberAddressOrderByInput {
  ADDRESS1_ASC = 'address1_ASC',
  ADDRESS1_DESC = 'address1_DESC',
  ADDRESS2_ASC = 'address2_ASC',
  ADDRESS2_DESC = 'address2_DESC',
  ADDRESSTYPE_ASC = 'addressType_ASC',
  ADDRESSTYPE_DESC = 'addressType_DESC',
  CITY_ASC = 'city_ASC',
  CITY_DESC = 'city_DESC',
  COUNTRY_ASC = 'country_ASC',
  COUNTRY_DESC = 'country_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISDEFAULT_ASC = 'isDefault_ASC',
  ISDEFAULT_DESC = 'isDefault_DESC',
  STATE_ASC = 'state_ASC',
  STATE_DESC = 'state_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  ZIPCODE_ASC = 'zipcode_ASC',
  ZIPCODE_DESC = 'zipcode_DESC'
}

export type MemberAddressScalarWhereInput = {
  AND?: InputMaybe<Array<MemberAddressScalarWhereInput>>;
  NOT?: InputMaybe<Array<MemberAddressScalarWhereInput>>;
  OR?: InputMaybe<Array<MemberAddressScalarWhereInput>>;
  address1?: InputMaybe<Scalars['String']>;
  address1_contains?: InputMaybe<Scalars['String']>;
  address1_ends_with?: InputMaybe<Scalars['String']>;
  address1_gt?: InputMaybe<Scalars['String']>;
  address1_gte?: InputMaybe<Scalars['String']>;
  address1_in?: InputMaybe<Array<Scalars['String']>>;
  address1_lt?: InputMaybe<Scalars['String']>;
  address1_lte?: InputMaybe<Scalars['String']>;
  address1_not?: InputMaybe<Scalars['String']>;
  address1_not_contains?: InputMaybe<Scalars['String']>;
  address1_not_ends_with?: InputMaybe<Scalars['String']>;
  address1_not_in?: InputMaybe<Array<Scalars['String']>>;
  address1_not_starts_with?: InputMaybe<Scalars['String']>;
  address1_starts_with?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address2_contains?: InputMaybe<Scalars['String']>;
  address2_ends_with?: InputMaybe<Scalars['String']>;
  address2_gt?: InputMaybe<Scalars['String']>;
  address2_gte?: InputMaybe<Scalars['String']>;
  address2_in?: InputMaybe<Array<Scalars['String']>>;
  address2_lt?: InputMaybe<Scalars['String']>;
  address2_lte?: InputMaybe<Scalars['String']>;
  address2_not?: InputMaybe<Scalars['String']>;
  address2_not_contains?: InputMaybe<Scalars['String']>;
  address2_not_ends_with?: InputMaybe<Scalars['String']>;
  address2_not_in?: InputMaybe<Array<Scalars['String']>>;
  address2_not_starts_with?: InputMaybe<Scalars['String']>;
  address2_starts_with?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  addressType_in?: InputMaybe<Array<AddressType>>;
  addressType_not?: InputMaybe<AddressType>;
  addressType_not_in?: InputMaybe<Array<AddressType>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_ends_with?: InputMaybe<Scalars['String']>;
  city_gt?: InputMaybe<Scalars['String']>;
  city_gte?: InputMaybe<Scalars['String']>;
  city_in?: InputMaybe<Array<Scalars['String']>>;
  city_lt?: InputMaybe<Scalars['String']>;
  city_lte?: InputMaybe<Scalars['String']>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_ends_with?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<Scalars['String']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']>;
  city_starts_with?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_ends_with?: InputMaybe<Scalars['String']>;
  country_gt?: InputMaybe<Scalars['String']>;
  country_gte?: InputMaybe<Scalars['String']>;
  country_in?: InputMaybe<Array<Scalars['String']>>;
  country_lt?: InputMaybe<Scalars['String']>;
  country_lte?: InputMaybe<Scalars['String']>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_ends_with?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<Scalars['String']>>;
  country_not_starts_with?: InputMaybe<Scalars['String']>;
  country_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_gt?: InputMaybe<Scalars['String']>;
  zipcode_gte?: InputMaybe<Scalars['String']>;
  zipcode_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_lt?: InputMaybe<Scalars['String']>;
  zipcode_lte?: InputMaybe<Scalars['String']>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_not_starts_with?: InputMaybe<Scalars['String']>;
  zipcode_starts_with?: InputMaybe<Scalars['String']>;
};

export type MemberAddressUpdateInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutAddressesInput>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type MemberAddressUpdateManyDataInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type MemberAddressUpdateManyWithWhereNestedInput = {
  data: MemberAddressUpdateManyDataInput;
  where: MemberAddressScalarWhereInput;
};

export type MemberAddressUpdateManyWithoutMemberInput = {
  connect?: InputMaybe<Array<MemberAddressWhereUniqueInput>>;
  create?: InputMaybe<Array<MemberAddressCreateWithoutMemberInput>>;
  delete?: InputMaybe<Array<MemberAddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MemberAddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MemberAddressWhereUniqueInput>>;
  set?: InputMaybe<Array<MemberAddressWhereUniqueInput>>;
  update?: InputMaybe<Array<MemberAddressUpdateWithWhereUniqueWithoutMemberInput>>;
  updateMany?: InputMaybe<Array<MemberAddressUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<MemberAddressUpsertWithWhereUniqueWithoutMemberInput>>;
};

export type MemberAddressUpdateWithWhereUniqueWithoutMemberInput = {
  data: MemberAddressUpdateWithoutMemberDataInput;
  where: MemberAddressWhereUniqueInput;
};

export type MemberAddressUpdateWithoutMemberDataInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type MemberAddressUpsertWithWhereUniqueWithoutMemberInput = {
  create: MemberAddressCreateWithoutMemberInput;
  update: MemberAddressUpdateWithoutMemberDataInput;
  where: MemberAddressWhereUniqueInput;
};

export type MemberAddressWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<MemberAddressWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<MemberAddressWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<MemberAddressWhereContractsPersistencyInput>>;
  address1?: InputMaybe<Scalars['String']>;
  address1_contains?: InputMaybe<Scalars['String']>;
  address1_ends_with?: InputMaybe<Scalars['String']>;
  address1_gt?: InputMaybe<Scalars['String']>;
  address1_gte?: InputMaybe<Scalars['String']>;
  address1_in?: InputMaybe<Array<Scalars['String']>>;
  address1_lt?: InputMaybe<Scalars['String']>;
  address1_lte?: InputMaybe<Scalars['String']>;
  address1_not?: InputMaybe<Scalars['String']>;
  address1_not_contains?: InputMaybe<Scalars['String']>;
  address1_not_ends_with?: InputMaybe<Scalars['String']>;
  address1_not_in?: InputMaybe<Array<Scalars['String']>>;
  address1_not_starts_with?: InputMaybe<Scalars['String']>;
  address1_starts_with?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address2_contains?: InputMaybe<Scalars['String']>;
  address2_ends_with?: InputMaybe<Scalars['String']>;
  address2_gt?: InputMaybe<Scalars['String']>;
  address2_gte?: InputMaybe<Scalars['String']>;
  address2_in?: InputMaybe<Array<Scalars['String']>>;
  address2_lt?: InputMaybe<Scalars['String']>;
  address2_lte?: InputMaybe<Scalars['String']>;
  address2_not?: InputMaybe<Scalars['String']>;
  address2_not_contains?: InputMaybe<Scalars['String']>;
  address2_not_ends_with?: InputMaybe<Scalars['String']>;
  address2_not_in?: InputMaybe<Array<Scalars['String']>>;
  address2_not_starts_with?: InputMaybe<Scalars['String']>;
  address2_starts_with?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<ContractsPersistencyAddressType>;
  addressType_in?: InputMaybe<Array<ContractsPersistencyAddressType>>;
  addressType_not?: InputMaybe<ContractsPersistencyAddressType>;
  addressType_not_in?: InputMaybe<Array<ContractsPersistencyAddressType>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_ends_with?: InputMaybe<Scalars['String']>;
  city_gt?: InputMaybe<Scalars['String']>;
  city_gte?: InputMaybe<Scalars['String']>;
  city_in?: InputMaybe<Array<Scalars['String']>>;
  city_lt?: InputMaybe<Scalars['String']>;
  city_lte?: InputMaybe<Scalars['String']>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_ends_with?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<Scalars['String']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']>;
  city_starts_with?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_ends_with?: InputMaybe<Scalars['String']>;
  country_gt?: InputMaybe<Scalars['String']>;
  country_gte?: InputMaybe<Scalars['String']>;
  country_in?: InputMaybe<Array<Scalars['String']>>;
  country_lt?: InputMaybe<Scalars['String']>;
  country_lte?: InputMaybe<Scalars['String']>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_ends_with?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<Scalars['String']>>;
  country_not_starts_with?: InputMaybe<Scalars['String']>;
  country_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWhereContractsPersistencyInput>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_gt?: InputMaybe<Scalars['String']>;
  zipcode_gte?: InputMaybe<Scalars['String']>;
  zipcode_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_lt?: InputMaybe<Scalars['String']>;
  zipcode_lte?: InputMaybe<Scalars['String']>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_not_starts_with?: InputMaybe<Scalars['String']>;
  zipcode_starts_with?: InputMaybe<Scalars['String']>;
};

export type MemberAddressWhereInput = {
  AND?: InputMaybe<Array<MemberAddressWhereInput>>;
  NOT?: InputMaybe<Array<MemberAddressWhereInput>>;
  OR?: InputMaybe<Array<MemberAddressWhereInput>>;
  address1?: InputMaybe<Scalars['String']>;
  address1_contains?: InputMaybe<Scalars['String']>;
  address1_ends_with?: InputMaybe<Scalars['String']>;
  address1_gt?: InputMaybe<Scalars['String']>;
  address1_gte?: InputMaybe<Scalars['String']>;
  address1_in?: InputMaybe<Array<Scalars['String']>>;
  address1_lt?: InputMaybe<Scalars['String']>;
  address1_lte?: InputMaybe<Scalars['String']>;
  address1_not?: InputMaybe<Scalars['String']>;
  address1_not_contains?: InputMaybe<Scalars['String']>;
  address1_not_ends_with?: InputMaybe<Scalars['String']>;
  address1_not_in?: InputMaybe<Array<Scalars['String']>>;
  address1_not_starts_with?: InputMaybe<Scalars['String']>;
  address1_starts_with?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address2_contains?: InputMaybe<Scalars['String']>;
  address2_ends_with?: InputMaybe<Scalars['String']>;
  address2_gt?: InputMaybe<Scalars['String']>;
  address2_gte?: InputMaybe<Scalars['String']>;
  address2_in?: InputMaybe<Array<Scalars['String']>>;
  address2_lt?: InputMaybe<Scalars['String']>;
  address2_lte?: InputMaybe<Scalars['String']>;
  address2_not?: InputMaybe<Scalars['String']>;
  address2_not_contains?: InputMaybe<Scalars['String']>;
  address2_not_ends_with?: InputMaybe<Scalars['String']>;
  address2_not_in?: InputMaybe<Array<Scalars['String']>>;
  address2_not_starts_with?: InputMaybe<Scalars['String']>;
  address2_starts_with?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  addressType_in?: InputMaybe<Array<AddressType>>;
  addressType_not?: InputMaybe<AddressType>;
  addressType_not_in?: InputMaybe<Array<AddressType>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_ends_with?: InputMaybe<Scalars['String']>;
  city_gt?: InputMaybe<Scalars['String']>;
  city_gte?: InputMaybe<Scalars['String']>;
  city_in?: InputMaybe<Array<Scalars['String']>>;
  city_lt?: InputMaybe<Scalars['String']>;
  city_lte?: InputMaybe<Scalars['String']>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_ends_with?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<Scalars['String']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']>;
  city_starts_with?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_ends_with?: InputMaybe<Scalars['String']>;
  country_gt?: InputMaybe<Scalars['String']>;
  country_gte?: InputMaybe<Scalars['String']>;
  country_in?: InputMaybe<Array<Scalars['String']>>;
  country_lt?: InputMaybe<Scalars['String']>;
  country_lte?: InputMaybe<Scalars['String']>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_ends_with?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<Scalars['String']>>;
  country_not_starts_with?: InputMaybe<Scalars['String']>;
  country_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWhereInput>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_gt?: InputMaybe<Scalars['String']>;
  zipcode_gte?: InputMaybe<Scalars['String']>;
  zipcode_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_lt?: InputMaybe<Scalars['String']>;
  zipcode_lte?: InputMaybe<Scalars['String']>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_not_starts_with?: InputMaybe<Scalars['String']>;
  zipcode_starts_with?: InputMaybe<Scalars['String']>;
};

export type MemberAddressWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<MemberAddressWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<MemberAddressWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<MemberAddressWherePersistencyContractsInput>>;
  address1?: InputMaybe<Scalars['String']>;
  address1_contains?: InputMaybe<Scalars['String']>;
  address1_ends_with?: InputMaybe<Scalars['String']>;
  address1_gt?: InputMaybe<Scalars['String']>;
  address1_gte?: InputMaybe<Scalars['String']>;
  address1_in?: InputMaybe<Array<Scalars['String']>>;
  address1_lt?: InputMaybe<Scalars['String']>;
  address1_lte?: InputMaybe<Scalars['String']>;
  address1_not?: InputMaybe<Scalars['String']>;
  address1_not_contains?: InputMaybe<Scalars['String']>;
  address1_not_ends_with?: InputMaybe<Scalars['String']>;
  address1_not_in?: InputMaybe<Array<Scalars['String']>>;
  address1_not_starts_with?: InputMaybe<Scalars['String']>;
  address1_starts_with?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address2_contains?: InputMaybe<Scalars['String']>;
  address2_ends_with?: InputMaybe<Scalars['String']>;
  address2_gt?: InputMaybe<Scalars['String']>;
  address2_gte?: InputMaybe<Scalars['String']>;
  address2_in?: InputMaybe<Array<Scalars['String']>>;
  address2_lt?: InputMaybe<Scalars['String']>;
  address2_lte?: InputMaybe<Scalars['String']>;
  address2_not?: InputMaybe<Scalars['String']>;
  address2_not_contains?: InputMaybe<Scalars['String']>;
  address2_not_ends_with?: InputMaybe<Scalars['String']>;
  address2_not_in?: InputMaybe<Array<Scalars['String']>>;
  address2_not_starts_with?: InputMaybe<Scalars['String']>;
  address2_starts_with?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<PersistencyContractsAddressType>;
  addressType_in?: InputMaybe<Array<PersistencyContractsAddressType>>;
  addressType_not?: InputMaybe<PersistencyContractsAddressType>;
  addressType_not_in?: InputMaybe<Array<PersistencyContractsAddressType>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_ends_with?: InputMaybe<Scalars['String']>;
  city_gt?: InputMaybe<Scalars['String']>;
  city_gte?: InputMaybe<Scalars['String']>;
  city_in?: InputMaybe<Array<Scalars['String']>>;
  city_lt?: InputMaybe<Scalars['String']>;
  city_lte?: InputMaybe<Scalars['String']>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_ends_with?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<Scalars['String']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']>;
  city_starts_with?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_ends_with?: InputMaybe<Scalars['String']>;
  country_gt?: InputMaybe<Scalars['String']>;
  country_gte?: InputMaybe<Scalars['String']>;
  country_in?: InputMaybe<Array<Scalars['String']>>;
  country_lt?: InputMaybe<Scalars['String']>;
  country_lte?: InputMaybe<Scalars['String']>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_ends_with?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<Scalars['String']>>;
  country_not_starts_with?: InputMaybe<Scalars['String']>;
  country_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWherePersistencyContractsInput>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_gt?: InputMaybe<Scalars['String']>;
  zipcode_gte?: InputMaybe<Scalars['String']>;
  zipcode_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_lt?: InputMaybe<Scalars['String']>;
  zipcode_lte?: InputMaybe<Scalars['String']>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_ends_with?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<Scalars['String']>>;
  zipcode_not_starts_with?: InputMaybe<Scalars['String']>;
  zipcode_starts_with?: InputMaybe<Scalars['String']>;
};

export type MemberAddressWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MemberConnection = {
  __typename?: 'MemberConnection';
  aggregate: AggregateMember;
  edges: Array<Maybe<MemberEdge>>;
  pageInfo: PageInfo;
};

export type MemberCreateInput = {
  addresses?: InputMaybe<MemberAddressCreateManyWithoutMemberInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Gender>;
  nationality?: InputMaybe<Scalars['String']>;
  user: UserCreateOneWithoutMemberInput;
};

export type MemberCreateOneInput = {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  create?: InputMaybe<MemberCreateInput>;
};

export type MemberCreateOneWithoutAddressesInput = {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  create?: InputMaybe<MemberCreateWithoutAddressesInput>;
};

export type MemberCreateOneWithoutUserInput = {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  create?: InputMaybe<MemberCreateWithoutUserInput>;
};

export type MemberCreateWithoutAddressesInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Gender>;
  nationality?: InputMaybe<Scalars['String']>;
  user: UserCreateOneWithoutMemberInput;
};

export type MemberCreateWithoutUserInput = {
  addresses?: InputMaybe<MemberAddressCreateManyWithoutMemberInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Gender>;
  nationality?: InputMaybe<Scalars['String']>;
};

export type MemberEdge = {
  __typename?: 'MemberEdge';
  cursor: Scalars['String'];
  node: Member;
};

export enum MemberOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DATEOFBIRTH_ASC = 'dateOfBirth_ASC',
  DATEOFBIRTH_DESC = 'dateOfBirth_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  GENDER_ASC = 'gender_ASC',
  GENDER_DESC = 'gender_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NATIONALITY_ASC = 'nationality_ASC',
  NATIONALITY_DESC = 'nationality_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type MemberPaymentOsCustomer = {
  __typename?: 'MemberPaymentOsCustomer';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  paymentOsId?: Maybe<Scalars['String']>;
  paymentOsRef?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vennAirtableId?: Maybe<Scalars['String']>;
};

export type MemberPaymentOsCustomerCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  paymentOsId?: InputMaybe<Scalars['String']>;
  paymentOsRef?: InputMaybe<Scalars['String']>;
  vennAirtableId?: InputMaybe<Scalars['String']>;
};

export enum MemberPaymentOsCustomerOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PAYMENTOSID_ASC = 'paymentOsId_ASC',
  PAYMENTOSID_DESC = 'paymentOsId_DESC',
  PAYMENTOSREF_ASC = 'paymentOsRef_ASC',
  PAYMENTOSREF_DESC = 'paymentOsRef_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VENNAIRTABLEID_ASC = 'vennAirtableId_ASC',
  VENNAIRTABLEID_DESC = 'vennAirtableId_DESC'
}

export type MemberPaymentOsCustomerUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  paymentOsId?: InputMaybe<Scalars['String']>;
  paymentOsRef?: InputMaybe<Scalars['String']>;
  vennAirtableId?: InputMaybe<Scalars['String']>;
};

export type MemberPaymentOsCustomerWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<MemberPaymentOsCustomerWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<MemberPaymentOsCustomerWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<MemberPaymentOsCustomerWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paymentOsId?: InputMaybe<Scalars['String']>;
  paymentOsId_contains?: InputMaybe<Scalars['String']>;
  paymentOsId_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsId_gt?: InputMaybe<Scalars['String']>;
  paymentOsId_gte?: InputMaybe<Scalars['String']>;
  paymentOsId_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsId_lt?: InputMaybe<Scalars['String']>;
  paymentOsId_lte?: InputMaybe<Scalars['String']>;
  paymentOsId_not?: InputMaybe<Scalars['String']>;
  paymentOsId_not_contains?: InputMaybe<Scalars['String']>;
  paymentOsId_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsId_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsId_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsId_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsRef?: InputMaybe<Scalars['String']>;
  paymentOsRef_contains?: InputMaybe<Scalars['String']>;
  paymentOsRef_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_gt?: InputMaybe<Scalars['String']>;
  paymentOsRef_gte?: InputMaybe<Scalars['String']>;
  paymentOsRef_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsRef_lt?: InputMaybe<Scalars['String']>;
  paymentOsRef_lte?: InputMaybe<Scalars['String']>;
  paymentOsRef_not?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_contains?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsRef_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  vennAirtableId?: InputMaybe<Scalars['String']>;
  vennAirtableId_contains?: InputMaybe<Scalars['String']>;
  vennAirtableId_ends_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_gt?: InputMaybe<Scalars['String']>;
  vennAirtableId_gte?: InputMaybe<Scalars['String']>;
  vennAirtableId_in?: InputMaybe<Array<Scalars['String']>>;
  vennAirtableId_lt?: InputMaybe<Scalars['String']>;
  vennAirtableId_lte?: InputMaybe<Scalars['String']>;
  vennAirtableId_not?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_contains?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  vennAirtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_starts_with?: InputMaybe<Scalars['String']>;
};

export type MemberPaymentOsCustomerWhereInput = {
  AND?: InputMaybe<Array<MemberPaymentOsCustomerWhereInput>>;
  NOT?: InputMaybe<Array<MemberPaymentOsCustomerWhereInput>>;
  OR?: InputMaybe<Array<MemberPaymentOsCustomerWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paymentOsId?: InputMaybe<Scalars['String']>;
  paymentOsId_contains?: InputMaybe<Scalars['String']>;
  paymentOsId_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsId_gt?: InputMaybe<Scalars['String']>;
  paymentOsId_gte?: InputMaybe<Scalars['String']>;
  paymentOsId_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsId_lt?: InputMaybe<Scalars['String']>;
  paymentOsId_lte?: InputMaybe<Scalars['String']>;
  paymentOsId_not?: InputMaybe<Scalars['String']>;
  paymentOsId_not_contains?: InputMaybe<Scalars['String']>;
  paymentOsId_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsId_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsId_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsId_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsRef?: InputMaybe<Scalars['String']>;
  paymentOsRef_contains?: InputMaybe<Scalars['String']>;
  paymentOsRef_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_gt?: InputMaybe<Scalars['String']>;
  paymentOsRef_gte?: InputMaybe<Scalars['String']>;
  paymentOsRef_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsRef_lt?: InputMaybe<Scalars['String']>;
  paymentOsRef_lte?: InputMaybe<Scalars['String']>;
  paymentOsRef_not?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_contains?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsRef_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  vennAirtableId?: InputMaybe<Scalars['String']>;
  vennAirtableId_contains?: InputMaybe<Scalars['String']>;
  vennAirtableId_ends_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_gt?: InputMaybe<Scalars['String']>;
  vennAirtableId_gte?: InputMaybe<Scalars['String']>;
  vennAirtableId_in?: InputMaybe<Array<Scalars['String']>>;
  vennAirtableId_lt?: InputMaybe<Scalars['String']>;
  vennAirtableId_lte?: InputMaybe<Scalars['String']>;
  vennAirtableId_not?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_contains?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  vennAirtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_starts_with?: InputMaybe<Scalars['String']>;
};

export type MemberPaymentOsCustomerWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<MemberPaymentOsCustomerWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<MemberPaymentOsCustomerWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<MemberPaymentOsCustomerWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paymentOsId?: InputMaybe<Scalars['String']>;
  paymentOsId_contains?: InputMaybe<Scalars['String']>;
  paymentOsId_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsId_gt?: InputMaybe<Scalars['String']>;
  paymentOsId_gte?: InputMaybe<Scalars['String']>;
  paymentOsId_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsId_lt?: InputMaybe<Scalars['String']>;
  paymentOsId_lte?: InputMaybe<Scalars['String']>;
  paymentOsId_not?: InputMaybe<Scalars['String']>;
  paymentOsId_not_contains?: InputMaybe<Scalars['String']>;
  paymentOsId_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsId_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsId_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsId_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsRef?: InputMaybe<Scalars['String']>;
  paymentOsRef_contains?: InputMaybe<Scalars['String']>;
  paymentOsRef_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_gt?: InputMaybe<Scalars['String']>;
  paymentOsRef_gte?: InputMaybe<Scalars['String']>;
  paymentOsRef_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsRef_lt?: InputMaybe<Scalars['String']>;
  paymentOsRef_lte?: InputMaybe<Scalars['String']>;
  paymentOsRef_not?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_contains?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentOsRef_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentOsRef_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  vennAirtableId?: InputMaybe<Scalars['String']>;
  vennAirtableId_contains?: InputMaybe<Scalars['String']>;
  vennAirtableId_ends_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_gt?: InputMaybe<Scalars['String']>;
  vennAirtableId_gte?: InputMaybe<Scalars['String']>;
  vennAirtableId_in?: InputMaybe<Array<Scalars['String']>>;
  vennAirtableId_lt?: InputMaybe<Scalars['String']>;
  vennAirtableId_lte?: InputMaybe<Scalars['String']>;
  vennAirtableId_not?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_contains?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  vennAirtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  vennAirtableId_starts_with?: InputMaybe<Scalars['String']>;
};

export type MemberPaymentOsCustomerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  paymentOsId?: InputMaybe<Scalars['String']>;
  vennAirtableId?: InputMaybe<Scalars['String']>;
};

export type MemberProfile = {
  __typename?: 'MemberProfile';
  address?: Maybe<MemberProfileAddressField>;
  biography?: Maybe<MemberProfileStringField>;
  blockStatus?: Maybe<MemberProfileBlockStatus>;
  byline?: Maybe<Byline>;
  callToAction?: Maybe<MemberProfileCallToActionField>;
  communityTitles?: Maybe<Array<CommunityTitle>>;
  completionPercentage?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<MemberProfileDateField>;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dummy?: Maybe<Scalars['String']>;
  firstName?: Maybe<MemberProfileStringField>;
  fullName?: Maybe<Scalars['String']>;
  hoodId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  image?: Maybe<MemberProfileStringField>;
  interestGroups?: Maybe<Array<Maybe<InterestGroup>>>;
  lastName?: Maybe<MemberProfileStringField>;
  memberSince?: Maybe<MemberProfileDateField>;
  occupation?: Maybe<MemberProfileStringField>;
  pets?: Maybe<MemberProfilePetsField>;
  pronouns?: Maybe<MemberProfilePronounsField>;
  quote?: Maybe<MemberProfileStringField>;
  socialLinks?: Maybe<MemberProfileSocialLinksField>;
  socialTags?: Maybe<MemberProfileSocialTagsField>;
  superpowers?: Maybe<MemberProfileStringField>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  website?: Maybe<MemberProfileStringField>;
};


export type MemberProfileCommunityTitlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommunityTitleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityTitleWhereInput>;
};

export type MemberProfileAddressField = {
  __typename?: 'MemberProfileAddressField';
  address?: Maybe<Address>;
  bedroom?: Maybe<Bedroom>;
  building?: Maybe<Building>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dummy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  privacy: MemberProfilePrivacy;
  unit?: Maybe<Unit>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export type MemberProfileAddressFieldConnection = {
  __typename?: 'MemberProfileAddressFieldConnection';
  aggregate: AggregateMemberProfileAddressField;
  edges: Array<Maybe<MemberProfileAddressFieldEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfileAddressFieldCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  privacy: MemberProfilePrivacy;
  userId: Scalars['ID'];
};

export type MemberProfileAddressFieldCreateOneInput = {
  connect?: InputMaybe<MemberProfileAddressFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileAddressFieldCreateInput>;
};

export type MemberProfileAddressFieldEdge = {
  __typename?: 'MemberProfileAddressFieldEdge';
  cursor: Scalars['String'];
  node: MemberProfileAddressField;
};

export enum MemberProfileAddressFieldOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DUMMY_ASC = 'dummy_ASC',
  DUMMY_DESC = 'dummy_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PRIVACY_ASC = 'privacy_ASC',
  PRIVACY_DESC = 'privacy_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type MemberProfileAddressFieldUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileAddressFieldUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileAddressFieldUpdateOneInput = {
  connect?: InputMaybe<MemberProfileAddressFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileAddressFieldCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfileAddressFieldUpdateDataInput>;
  upsert?: InputMaybe<MemberProfileAddressFieldUpsertNestedInput>;
};

export type MemberProfileAddressFieldUpsertNestedInput = {
  create: MemberProfileAddressFieldCreateInput;
  update: MemberProfileAddressFieldUpdateDataInput;
};

export type MemberProfileAddressFieldWhereInput = {
  AND?: InputMaybe<Array<MemberProfileAddressFieldWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfileAddressFieldWhereInput>>;
  OR?: InputMaybe<Array<MemberProfileAddressFieldWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dummy?: InputMaybe<Scalars['String']>;
  dummy_contains?: InputMaybe<Scalars['String']>;
  dummy_ends_with?: InputMaybe<Scalars['String']>;
  dummy_gt?: InputMaybe<Scalars['String']>;
  dummy_gte?: InputMaybe<Scalars['String']>;
  dummy_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_lt?: InputMaybe<Scalars['String']>;
  dummy_lte?: InputMaybe<Scalars['String']>;
  dummy_not?: InputMaybe<Scalars['String']>;
  dummy_not_contains?: InputMaybe<Scalars['String']>;
  dummy_not_ends_with?: InputMaybe<Scalars['String']>;
  dummy_not_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_not_starts_with?: InputMaybe<Scalars['String']>;
  dummy_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  privacy_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  privacy_not?: InputMaybe<MemberProfilePrivacy>;
  privacy_not_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileAddressFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileBlock = {
  __typename?: 'MemberProfileBlock';
  _empty?: Maybe<Scalars['String']>;
  blockedUserId: Scalars['String'];
  blockingUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type MemberProfileBlockConnection = {
  __typename?: 'MemberProfileBlockConnection';
  aggregate: AggregateMemberProfileBlock;
  edges: Array<Maybe<MemberProfileBlockEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfileBlockCreateInput = {
  blockedUserId: Scalars['String'];
  blockingUserId: Scalars['String'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MemberProfileBlockEdge = {
  __typename?: 'MemberProfileBlockEdge';
  cursor: Scalars['String'];
  node: MemberProfileBlock;
};

export enum MemberProfileBlockOrderByInput {
  BLOCKEDUSERID_ASC = 'blockedUserId_ASC',
  BLOCKEDUSERID_DESC = 'blockedUserId_DESC',
  BLOCKINGUSERID_ASC = 'blockingUserId_ASC',
  BLOCKINGUSERID_DESC = 'blockingUserId_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export enum MemberProfileBlockStatus {
  BLOCKED_BY_CURRENT_USER = 'BLOCKED_BY_CURRENT_USER',
  BLOCKED_BY_PROFILE_OWNER = 'BLOCKED_BY_PROFILE_OWNER',
  MUTUAL_BLOCK = 'MUTUAL_BLOCK',
  NOT_BLOCKED = 'NOT_BLOCKED'
}

export type MemberProfileBlockUpdateInput = {
  blockedUserId?: InputMaybe<Scalars['String']>;
  blockingUserId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MemberProfileBlockWhereInput = {
  AND?: InputMaybe<Array<MemberProfileBlockWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfileBlockWhereInput>>;
  OR?: InputMaybe<Array<MemberProfileBlockWhereInput>>;
  blockedUserId?: InputMaybe<Scalars['String']>;
  blockedUserId_contains?: InputMaybe<Scalars['String']>;
  blockedUserId_ends_with?: InputMaybe<Scalars['String']>;
  blockedUserId_gt?: InputMaybe<Scalars['String']>;
  blockedUserId_gte?: InputMaybe<Scalars['String']>;
  blockedUserId_in?: InputMaybe<Array<Scalars['String']>>;
  blockedUserId_lt?: InputMaybe<Scalars['String']>;
  blockedUserId_lte?: InputMaybe<Scalars['String']>;
  blockedUserId_not?: InputMaybe<Scalars['String']>;
  blockedUserId_not_contains?: InputMaybe<Scalars['String']>;
  blockedUserId_not_ends_with?: InputMaybe<Scalars['String']>;
  blockedUserId_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockedUserId_not_starts_with?: InputMaybe<Scalars['String']>;
  blockedUserId_starts_with?: InputMaybe<Scalars['String']>;
  blockingUserId?: InputMaybe<Scalars['String']>;
  blockingUserId_contains?: InputMaybe<Scalars['String']>;
  blockingUserId_ends_with?: InputMaybe<Scalars['String']>;
  blockingUserId_gt?: InputMaybe<Scalars['String']>;
  blockingUserId_gte?: InputMaybe<Scalars['String']>;
  blockingUserId_in?: InputMaybe<Array<Scalars['String']>>;
  blockingUserId_lt?: InputMaybe<Scalars['String']>;
  blockingUserId_lte?: InputMaybe<Scalars['String']>;
  blockingUserId_not?: InputMaybe<Scalars['String']>;
  blockingUserId_not_contains?: InputMaybe<Scalars['String']>;
  blockingUserId_not_ends_with?: InputMaybe<Scalars['String']>;
  blockingUserId_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockingUserId_not_starts_with?: InputMaybe<Scalars['String']>;
  blockingUserId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type MemberProfileBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileCallToActionField = {
  __typename?: 'MemberProfileCallToActionField';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  privacy: MemberProfilePrivacy;
  target?: Maybe<Scalars['String']>;
  type?: Maybe<MemberProfileCallToActionType>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export type MemberProfileCallToActionFieldConnection = {
  __typename?: 'MemberProfileCallToActionFieldConnection';
  aggregate: AggregateMemberProfileCallToActionField;
  edges: Array<Maybe<MemberProfileCallToActionFieldEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfileCallToActionFieldCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy: MemberProfilePrivacy;
  target?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MemberProfileCallToActionType>;
  userId: Scalars['ID'];
};

export type MemberProfileCallToActionFieldCreateOneInput = {
  connect?: InputMaybe<MemberProfileCallToActionFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileCallToActionFieldCreateInput>;
};

export type MemberProfileCallToActionFieldEdge = {
  __typename?: 'MemberProfileCallToActionFieldEdge';
  cursor: Scalars['String'];
  node: MemberProfileCallToActionField;
};

export enum MemberProfileCallToActionFieldOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PRIVACY_ASC = 'privacy_ASC',
  PRIVACY_DESC = 'privacy_DESC',
  TARGET_ASC = 'target_ASC',
  TARGET_DESC = 'target_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type MemberProfileCallToActionFieldUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  target?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MemberProfileCallToActionType>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileCallToActionFieldUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  target?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MemberProfileCallToActionType>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileCallToActionFieldUpdateOneInput = {
  connect?: InputMaybe<MemberProfileCallToActionFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileCallToActionFieldCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfileCallToActionFieldUpdateDataInput>;
  upsert?: InputMaybe<MemberProfileCallToActionFieldUpsertNestedInput>;
};

export type MemberProfileCallToActionFieldUpsertNestedInput = {
  create: MemberProfileCallToActionFieldCreateInput;
  update: MemberProfileCallToActionFieldUpdateDataInput;
};

export type MemberProfileCallToActionFieldWhereInput = {
  AND?: InputMaybe<Array<MemberProfileCallToActionFieldWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfileCallToActionFieldWhereInput>>;
  OR?: InputMaybe<Array<MemberProfileCallToActionFieldWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  privacy_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  privacy_not?: InputMaybe<MemberProfilePrivacy>;
  privacy_not_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  target?: InputMaybe<Scalars['String']>;
  target_contains?: InputMaybe<Scalars['String']>;
  target_ends_with?: InputMaybe<Scalars['String']>;
  target_gt?: InputMaybe<Scalars['String']>;
  target_gte?: InputMaybe<Scalars['String']>;
  target_in?: InputMaybe<Array<Scalars['String']>>;
  target_lt?: InputMaybe<Scalars['String']>;
  target_lte?: InputMaybe<Scalars['String']>;
  target_not?: InputMaybe<Scalars['String']>;
  target_not_contains?: InputMaybe<Scalars['String']>;
  target_not_ends_with?: InputMaybe<Scalars['String']>;
  target_not_in?: InputMaybe<Array<Scalars['String']>>;
  target_not_starts_with?: InputMaybe<Scalars['String']>;
  target_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MemberProfileCallToActionType>;
  type_in?: InputMaybe<Array<MemberProfileCallToActionType>>;
  type_not?: InputMaybe<MemberProfileCallToActionType>;
  type_not_in?: InputMaybe<Array<MemberProfileCallToActionType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileCallToActionFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum MemberProfileCallToActionType {
  SMS = 'SMS'
}

export type MemberProfileConnection = {
  __typename?: 'MemberProfileConnection';
  aggregate: AggregateMemberProfile;
  edges: Array<Maybe<MemberProfileEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfileCreateInput = {
  address?: InputMaybe<MemberProfileAddressFieldCreateOneInput>;
  biography?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  callToAction?: InputMaybe<MemberProfileCallToActionFieldCreateOneInput>;
  communityTitles?: InputMaybe<CommunityTitleCreateManyWithoutMemberProfileInput>;
  dateOfBirth?: InputMaybe<MemberProfileDateFieldCreateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  hoodId?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  lastName?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  memberSince?: InputMaybe<MemberProfileDateFieldCreateOneInput>;
  occupation?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  pets?: InputMaybe<MemberProfilePetsFieldCreateOneInput>;
  pronouns?: InputMaybe<MemberProfilePronounsFieldCreateOneInput>;
  quote?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  socialLinks?: InputMaybe<MemberProfileSocialLinksFieldCreateOneInput>;
  socialTags?: InputMaybe<MemberProfileSocialTagsFieldCreateOneInput>;
  superpowers?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  userId: Scalars['ID'];
  website?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
};

export type MemberProfileCreateOneInput = {
  connect?: InputMaybe<MemberProfileWhereUniqueInput>;
  create?: InputMaybe<MemberProfileCreateInput>;
};

export type MemberProfileCreateOneWithoutCommunityTitlesInput = {
  connect?: InputMaybe<MemberProfileWhereUniqueInput>;
  create?: InputMaybe<MemberProfileCreateWithoutCommunityTitlesInput>;
};

export type MemberProfileCreateWithoutCommunityTitlesInput = {
  address?: InputMaybe<MemberProfileAddressFieldCreateOneInput>;
  biography?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  callToAction?: InputMaybe<MemberProfileCallToActionFieldCreateOneInput>;
  dateOfBirth?: InputMaybe<MemberProfileDateFieldCreateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  hoodId?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  lastName?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  memberSince?: InputMaybe<MemberProfileDateFieldCreateOneInput>;
  occupation?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  pets?: InputMaybe<MemberProfilePetsFieldCreateOneInput>;
  pronouns?: InputMaybe<MemberProfilePronounsFieldCreateOneInput>;
  quote?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  socialLinks?: InputMaybe<MemberProfileSocialLinksFieldCreateOneInput>;
  socialTags?: InputMaybe<MemberProfileSocialTagsFieldCreateOneInput>;
  superpowers?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  userId: Scalars['ID'];
  website?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
};

export type MemberProfileDateField = {
  __typename?: 'MemberProfileDateField';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  privacy: MemberProfilePrivacy;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  value?: Maybe<Scalars['DateTime']>;
};

export type MemberProfileDateFieldConnection = {
  __typename?: 'MemberProfileDateFieldConnection';
  aggregate: AggregateMemberProfileDateField;
  edges: Array<Maybe<MemberProfileDateFieldEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfileDateFieldCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy: MemberProfilePrivacy;
  userId: Scalars['ID'];
  value?: InputMaybe<Scalars['DateTime']>;
};

export type MemberProfileDateFieldCreateOneInput = {
  connect?: InputMaybe<MemberProfileDateFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileDateFieldCreateInput>;
};

export type MemberProfileDateFieldEdge = {
  __typename?: 'MemberProfileDateFieldEdge';
  cursor: Scalars['String'];
  node: MemberProfileDateField;
};

export enum MemberProfileDateFieldOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PRIVACY_ASC = 'privacy_ASC',
  PRIVACY_DESC = 'privacy_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC'
}

export type MemberProfileDateFieldUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['DateTime']>;
};

export type MemberProfileDateFieldUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['DateTime']>;
};

export type MemberProfileDateFieldUpdateOneInput = {
  connect?: InputMaybe<MemberProfileDateFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileDateFieldCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfileDateFieldUpdateDataInput>;
  upsert?: InputMaybe<MemberProfileDateFieldUpsertNestedInput>;
};

export type MemberProfileDateFieldUpsertNestedInput = {
  create: MemberProfileDateFieldCreateInput;
  update: MemberProfileDateFieldUpdateDataInput;
};

export type MemberProfileDateFieldWhereInput = {
  AND?: InputMaybe<Array<MemberProfileDateFieldWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfileDateFieldWhereInput>>;
  OR?: InputMaybe<Array<MemberProfileDateFieldWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  privacy_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  privacy_not?: InputMaybe<MemberProfilePrivacy>;
  privacy_not_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['DateTime']>;
  value_gt?: InputMaybe<Scalars['DateTime']>;
  value_gte?: InputMaybe<Scalars['DateTime']>;
  value_in?: InputMaybe<Array<Scalars['DateTime']>>;
  value_lt?: InputMaybe<Scalars['DateTime']>;
  value_lte?: InputMaybe<Scalars['DateTime']>;
  value_not?: InputMaybe<Scalars['DateTime']>;
  value_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type MemberProfileDateFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileEdge = {
  __typename?: 'MemberProfileEdge';
  cursor: Scalars['String'];
  node: MemberProfile;
};

export enum MemberProfileOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DUMMY_ASC = 'dummy_ASC',
  DUMMY_DESC = 'dummy_DESC',
  HOODID_ASC = 'hoodId_ASC',
  HOODID_DESC = 'hoodId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type MemberProfilePetsField = {
  __typename?: 'MemberProfilePetsField';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  pets?: Maybe<Array<Pet>>;
  privacy: MemberProfilePrivacy;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};


export type MemberProfilePetsFieldPetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PetWhereInput>;
};

export type MemberProfilePetsFieldConnection = {
  __typename?: 'MemberProfilePetsFieldConnection';
  aggregate: AggregateMemberProfilePetsField;
  edges: Array<Maybe<MemberProfilePetsFieldEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfilePetsFieldCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  pets?: InputMaybe<PetCreateManyWithoutMemberProfilePetsFieldInput>;
  privacy: MemberProfilePrivacy;
  userId: Scalars['ID'];
};

export type MemberProfilePetsFieldCreateOneInput = {
  connect?: InputMaybe<MemberProfilePetsFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfilePetsFieldCreateInput>;
};

export type MemberProfilePetsFieldCreateOneWithoutPetsInput = {
  connect?: InputMaybe<MemberProfilePetsFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfilePetsFieldCreateWithoutPetsInput>;
};

export type MemberProfilePetsFieldCreateWithoutPetsInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy: MemberProfilePrivacy;
  userId: Scalars['ID'];
};

export type MemberProfilePetsFieldEdge = {
  __typename?: 'MemberProfilePetsFieldEdge';
  cursor: Scalars['String'];
  node: MemberProfilePetsField;
};

export enum MemberProfilePetsFieldOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PRIVACY_ASC = 'privacy_ASC',
  PRIVACY_DESC = 'privacy_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type MemberProfilePetsFieldUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  pets?: InputMaybe<PetUpdateManyWithoutMemberProfilePetsFieldInput>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfilePetsFieldUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  pets?: InputMaybe<PetUpdateManyWithoutMemberProfilePetsFieldInput>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfilePetsFieldUpdateOneInput = {
  connect?: InputMaybe<MemberProfilePetsFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfilePetsFieldCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfilePetsFieldUpdateDataInput>;
  upsert?: InputMaybe<MemberProfilePetsFieldUpsertNestedInput>;
};

export type MemberProfilePetsFieldUpdateOneWithoutPetsInput = {
  connect?: InputMaybe<MemberProfilePetsFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfilePetsFieldCreateWithoutPetsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfilePetsFieldUpdateWithoutPetsDataInput>;
  upsert?: InputMaybe<MemberProfilePetsFieldUpsertWithoutPetsInput>;
};

export type MemberProfilePetsFieldUpdateWithoutPetsDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfilePetsFieldUpsertNestedInput = {
  create: MemberProfilePetsFieldCreateInput;
  update: MemberProfilePetsFieldUpdateDataInput;
};

export type MemberProfilePetsFieldUpsertWithoutPetsInput = {
  create: MemberProfilePetsFieldCreateWithoutPetsInput;
  update: MemberProfilePetsFieldUpdateWithoutPetsDataInput;
};

export type MemberProfilePetsFieldWhereInput = {
  AND?: InputMaybe<Array<MemberProfilePetsFieldWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfilePetsFieldWhereInput>>;
  OR?: InputMaybe<Array<MemberProfilePetsFieldWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  pets_every?: InputMaybe<PetWhereInput>;
  pets_none?: InputMaybe<PetWhereInput>;
  pets_some?: InputMaybe<PetWhereInput>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  privacy_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  privacy_not?: InputMaybe<MemberProfilePrivacy>;
  privacy_not_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
};

export type MemberProfilePetsFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum MemberProfilePrivacy {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export enum MemberProfilePronouns {
  HE_HIM_HIS = 'HE_HIM_HIS',
  OTHER = 'OTHER',
  SHE_HER_HERS = 'SHE_HER_HERS',
  THEY_THEM_THEIR = 'THEY_THEM_THEIR'
}

export type MemberProfilePronounsField = {
  __typename?: 'MemberProfilePronounsField';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customPronouns?: Maybe<Scalars['String']>;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  privacy: MemberProfilePrivacy;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  value?: Maybe<MemberProfilePronouns>;
};

export type MemberProfilePronounsFieldConnection = {
  __typename?: 'MemberProfilePronounsFieldConnection';
  aggregate: AggregateMemberProfilePronounsField;
  edges: Array<Maybe<MemberProfilePronounsFieldEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfilePronounsFieldCreateInput = {
  customPronouns?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy: MemberProfilePrivacy;
  userId: Scalars['ID'];
  value?: InputMaybe<MemberProfilePronouns>;
};

export type MemberProfilePronounsFieldCreateOneInput = {
  connect?: InputMaybe<MemberProfilePronounsFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfilePronounsFieldCreateInput>;
};

export type MemberProfilePronounsFieldEdge = {
  __typename?: 'MemberProfilePronounsFieldEdge';
  cursor: Scalars['String'];
  node: MemberProfilePronounsField;
};

export enum MemberProfilePronounsFieldOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  CUSTOMPRONOUNS_ASC = 'customPronouns_ASC',
  CUSTOMPRONOUNS_DESC = 'customPronouns_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PRIVACY_ASC = 'privacy_ASC',
  PRIVACY_DESC = 'privacy_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC'
}

export type MemberProfilePronounsFieldUpdateDataInput = {
  customPronouns?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<MemberProfilePronouns>;
};

export type MemberProfilePronounsFieldUpdateInput = {
  customPronouns?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<MemberProfilePronouns>;
};

export type MemberProfilePronounsFieldUpdateOneInput = {
  connect?: InputMaybe<MemberProfilePronounsFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfilePronounsFieldCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfilePronounsFieldUpdateDataInput>;
  upsert?: InputMaybe<MemberProfilePronounsFieldUpsertNestedInput>;
};

export type MemberProfilePronounsFieldUpsertNestedInput = {
  create: MemberProfilePronounsFieldCreateInput;
  update: MemberProfilePronounsFieldUpdateDataInput;
};

export type MemberProfilePronounsFieldWhereInput = {
  AND?: InputMaybe<Array<MemberProfilePronounsFieldWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfilePronounsFieldWhereInput>>;
  OR?: InputMaybe<Array<MemberProfilePronounsFieldWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  customPronouns?: InputMaybe<Scalars['String']>;
  customPronouns_contains?: InputMaybe<Scalars['String']>;
  customPronouns_ends_with?: InputMaybe<Scalars['String']>;
  customPronouns_gt?: InputMaybe<Scalars['String']>;
  customPronouns_gte?: InputMaybe<Scalars['String']>;
  customPronouns_in?: InputMaybe<Array<Scalars['String']>>;
  customPronouns_lt?: InputMaybe<Scalars['String']>;
  customPronouns_lte?: InputMaybe<Scalars['String']>;
  customPronouns_not?: InputMaybe<Scalars['String']>;
  customPronouns_not_contains?: InputMaybe<Scalars['String']>;
  customPronouns_not_ends_with?: InputMaybe<Scalars['String']>;
  customPronouns_not_in?: InputMaybe<Array<Scalars['String']>>;
  customPronouns_not_starts_with?: InputMaybe<Scalars['String']>;
  customPronouns_starts_with?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  privacy_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  privacy_not?: InputMaybe<MemberProfilePrivacy>;
  privacy_not_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<MemberProfilePronouns>;
  value_in?: InputMaybe<Array<MemberProfilePronouns>>;
  value_not?: InputMaybe<MemberProfilePronouns>;
  value_not_in?: InputMaybe<Array<MemberProfilePronouns>>;
};

export type MemberProfilePronounsFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileSocialLinksField = {
  __typename?: 'MemberProfileSocialLinksField';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  facebook?: Maybe<MemberProfileStringField>;
  id: Scalars['ID'];
  instagram?: Maybe<MemberProfileStringField>;
  linkedin?: Maybe<MemberProfileStringField>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  youtube?: Maybe<MemberProfileStringField>;
};

export type MemberProfileSocialLinksFieldConnection = {
  __typename?: 'MemberProfileSocialLinksFieldConnection';
  aggregate: AggregateMemberProfileSocialLinksField;
  edges: Array<Maybe<MemberProfileSocialLinksFieldEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfileSocialLinksFieldCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  facebook?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  instagram?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  linkedin?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
  userId: Scalars['ID'];
  youtube?: InputMaybe<MemberProfileStringFieldCreateOneInput>;
};

export type MemberProfileSocialLinksFieldCreateOneInput = {
  connect?: InputMaybe<MemberProfileSocialLinksFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileSocialLinksFieldCreateInput>;
};

export type MemberProfileSocialLinksFieldEdge = {
  __typename?: 'MemberProfileSocialLinksFieldEdge';
  cursor: Scalars['String'];
  node: MemberProfileSocialLinksField;
};

export enum MemberProfileSocialLinksFieldOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type MemberProfileSocialLinksFieldUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  facebook?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  instagram?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  linkedin?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  userId?: InputMaybe<Scalars['ID']>;
  youtube?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
};

export type MemberProfileSocialLinksFieldUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  facebook?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  instagram?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  linkedin?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  userId?: InputMaybe<Scalars['ID']>;
  youtube?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
};

export type MemberProfileSocialLinksFieldUpdateOneInput = {
  connect?: InputMaybe<MemberProfileSocialLinksFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileSocialLinksFieldCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfileSocialLinksFieldUpdateDataInput>;
  upsert?: InputMaybe<MemberProfileSocialLinksFieldUpsertNestedInput>;
};

export type MemberProfileSocialLinksFieldUpsertNestedInput = {
  create: MemberProfileSocialLinksFieldCreateInput;
  update: MemberProfileSocialLinksFieldUpdateDataInput;
};

export type MemberProfileSocialLinksFieldWhereInput = {
  AND?: InputMaybe<Array<MemberProfileSocialLinksFieldWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfileSocialLinksFieldWhereInput>>;
  OR?: InputMaybe<Array<MemberProfileSocialLinksFieldWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  facebook?: InputMaybe<MemberProfileStringFieldWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  instagram?: InputMaybe<MemberProfileStringFieldWhereInput>;
  linkedin?: InputMaybe<MemberProfileStringFieldWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
  youtube?: InputMaybe<MemberProfileStringFieldWhereInput>;
};

export type MemberProfileSocialLinksFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileSocialTagsField = {
  __typename?: 'MemberProfileSocialTagsField';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dummy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  privacy: MemberProfilePrivacy;
  tags?: Maybe<Array<SocialTag>>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};


export type MemberProfileSocialTagsFieldTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialTagOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialTagWhereInput>;
};

export type MemberProfileSocialTagsFieldConnection = {
  __typename?: 'MemberProfileSocialTagsFieldConnection';
  aggregate: AggregateMemberProfileSocialTagsField;
  edges: Array<Maybe<MemberProfileSocialTagsFieldEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfileSocialTagsFieldCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  privacy: MemberProfilePrivacy;
  tags?: InputMaybe<SocialTagCreateManyInput>;
  userId: Scalars['ID'];
};

export type MemberProfileSocialTagsFieldCreateOneInput = {
  connect?: InputMaybe<MemberProfileSocialTagsFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileSocialTagsFieldCreateInput>;
};

export type MemberProfileSocialTagsFieldEdge = {
  __typename?: 'MemberProfileSocialTagsFieldEdge';
  cursor: Scalars['String'];
  node: MemberProfileSocialTagsField;
};

export enum MemberProfileSocialTagsFieldOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DUMMY_ASC = 'dummy_ASC',
  DUMMY_DESC = 'dummy_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PRIVACY_ASC = 'privacy_ASC',
  PRIVACY_DESC = 'privacy_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type MemberProfileSocialTagsFieldUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  tags?: InputMaybe<SocialTagUpdateManyInput>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileSocialTagsFieldUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  tags?: InputMaybe<SocialTagUpdateManyInput>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileSocialTagsFieldUpdateOneInput = {
  connect?: InputMaybe<MemberProfileSocialTagsFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileSocialTagsFieldCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfileSocialTagsFieldUpdateDataInput>;
  upsert?: InputMaybe<MemberProfileSocialTagsFieldUpsertNestedInput>;
};

export type MemberProfileSocialTagsFieldUpsertNestedInput = {
  create: MemberProfileSocialTagsFieldCreateInput;
  update: MemberProfileSocialTagsFieldUpdateDataInput;
};

export type MemberProfileSocialTagsFieldWhereInput = {
  AND?: InputMaybe<Array<MemberProfileSocialTagsFieldWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfileSocialTagsFieldWhereInput>>;
  OR?: InputMaybe<Array<MemberProfileSocialTagsFieldWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dummy?: InputMaybe<Scalars['String']>;
  dummy_contains?: InputMaybe<Scalars['String']>;
  dummy_ends_with?: InputMaybe<Scalars['String']>;
  dummy_gt?: InputMaybe<Scalars['String']>;
  dummy_gte?: InputMaybe<Scalars['String']>;
  dummy_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_lt?: InputMaybe<Scalars['String']>;
  dummy_lte?: InputMaybe<Scalars['String']>;
  dummy_not?: InputMaybe<Scalars['String']>;
  dummy_not_contains?: InputMaybe<Scalars['String']>;
  dummy_not_ends_with?: InputMaybe<Scalars['String']>;
  dummy_not_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_not_starts_with?: InputMaybe<Scalars['String']>;
  dummy_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  privacy_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  privacy_not?: InputMaybe<MemberProfilePrivacy>;
  privacy_not_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  tags_every?: InputMaybe<SocialTagWhereInput>;
  tags_none?: InputMaybe<SocialTagWhereInput>;
  tags_some?: InputMaybe<SocialTagWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileSocialTagsFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileStringField = {
  __typename?: 'MemberProfileStringField';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  privacy: MemberProfilePrivacy;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type MemberProfileStringFieldConnection = {
  __typename?: 'MemberProfileStringFieldConnection';
  aggregate: AggregateMemberProfileStringField;
  edges: Array<Maybe<MemberProfileStringFieldEdge>>;
  pageInfo: PageInfo;
};

export type MemberProfileStringFieldCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy: MemberProfilePrivacy;
  userId: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};

export type MemberProfileStringFieldCreateOneInput = {
  connect?: InputMaybe<MemberProfileStringFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileStringFieldCreateInput>;
};

export type MemberProfileStringFieldEdge = {
  __typename?: 'MemberProfileStringFieldEdge';
  cursor: Scalars['String'];
  node: MemberProfileStringField;
};

export enum MemberProfileStringFieldOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PRIVACY_ASC = 'privacy_ASC',
  PRIVACY_DESC = 'privacy_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC'
}

export type MemberProfileStringFieldUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type MemberProfileStringFieldUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  userId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type MemberProfileStringFieldUpdateOneInput = {
  connect?: InputMaybe<MemberProfileStringFieldWhereUniqueInput>;
  create?: InputMaybe<MemberProfileStringFieldCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfileStringFieldUpdateDataInput>;
  upsert?: InputMaybe<MemberProfileStringFieldUpsertNestedInput>;
};

export type MemberProfileStringFieldUpsertNestedInput = {
  create: MemberProfileStringFieldCreateInput;
  update: MemberProfileStringFieldUpdateDataInput;
};

export type MemberProfileStringFieldWhereInput = {
  AND?: InputMaybe<Array<MemberProfileStringFieldWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfileStringFieldWhereInput>>;
  OR?: InputMaybe<Array<MemberProfileStringFieldWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
  privacy_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  privacy_not?: InputMaybe<MemberProfilePrivacy>;
  privacy_not_in?: InputMaybe<Array<MemberProfilePrivacy>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_ends_with?: InputMaybe<Scalars['String']>;
  value_gt?: InputMaybe<Scalars['String']>;
  value_gte?: InputMaybe<Scalars['String']>;
  value_in?: InputMaybe<Array<Scalars['String']>>;
  value_lt?: InputMaybe<Scalars['String']>;
  value_lte?: InputMaybe<Scalars['String']>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<Scalars['String']>>;
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export type MemberProfileStringFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MemberProfileUpdateDataInput = {
  address?: InputMaybe<MemberProfileAddressFieldUpdateOneInput>;
  biography?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  callToAction?: InputMaybe<MemberProfileCallToActionFieldUpdateOneInput>;
  communityTitles?: InputMaybe<CommunityTitleUpdateManyWithoutMemberProfileInput>;
  dateOfBirth?: InputMaybe<MemberProfileDateFieldUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  hoodId?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  lastName?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  memberSince?: InputMaybe<MemberProfileDateFieldUpdateOneInput>;
  occupation?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  pets?: InputMaybe<MemberProfilePetsFieldUpdateOneInput>;
  pronouns?: InputMaybe<MemberProfilePronounsFieldUpdateOneInput>;
  quote?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  socialLinks?: InputMaybe<MemberProfileSocialLinksFieldUpdateOneInput>;
  socialTags?: InputMaybe<MemberProfileSocialTagsFieldUpdateOneInput>;
  superpowers?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  userId?: InputMaybe<Scalars['ID']>;
  website?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
};

export type MemberProfileUpdateInput = {
  address?: InputMaybe<MemberProfileAddressFieldUpdateOneInput>;
  biography?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  callToAction?: InputMaybe<MemberProfileCallToActionFieldUpdateOneInput>;
  communityTitles?: InputMaybe<CommunityTitleUpdateManyWithoutMemberProfileInput>;
  dateOfBirth?: InputMaybe<MemberProfileDateFieldUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  hoodId?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  lastName?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  memberSince?: InputMaybe<MemberProfileDateFieldUpdateOneInput>;
  occupation?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  pets?: InputMaybe<MemberProfilePetsFieldUpdateOneInput>;
  pronouns?: InputMaybe<MemberProfilePronounsFieldUpdateOneInput>;
  quote?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  socialLinks?: InputMaybe<MemberProfileSocialLinksFieldUpdateOneInput>;
  socialTags?: InputMaybe<MemberProfileSocialTagsFieldUpdateOneInput>;
  superpowers?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  userId?: InputMaybe<Scalars['ID']>;
  website?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
};

export type MemberProfileUpdateOneRequiredInput = {
  connect?: InputMaybe<MemberProfileWhereUniqueInput>;
  create?: InputMaybe<MemberProfileCreateInput>;
  update?: InputMaybe<MemberProfileUpdateDataInput>;
  upsert?: InputMaybe<MemberProfileUpsertNestedInput>;
};

export type MemberProfileUpdateOneWithoutCommunityTitlesInput = {
  connect?: InputMaybe<MemberProfileWhereUniqueInput>;
  create?: InputMaybe<MemberProfileCreateWithoutCommunityTitlesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberProfileUpdateWithoutCommunityTitlesDataInput>;
  upsert?: InputMaybe<MemberProfileUpsertWithoutCommunityTitlesInput>;
};

export type MemberProfileUpdateWithoutCommunityTitlesDataInput = {
  address?: InputMaybe<MemberProfileAddressFieldUpdateOneInput>;
  biography?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  callToAction?: InputMaybe<MemberProfileCallToActionFieldUpdateOneInput>;
  dateOfBirth?: InputMaybe<MemberProfileDateFieldUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  hoodId?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  lastName?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  memberSince?: InputMaybe<MemberProfileDateFieldUpdateOneInput>;
  occupation?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  pets?: InputMaybe<MemberProfilePetsFieldUpdateOneInput>;
  pronouns?: InputMaybe<MemberProfilePronounsFieldUpdateOneInput>;
  quote?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  socialLinks?: InputMaybe<MemberProfileSocialLinksFieldUpdateOneInput>;
  socialTags?: InputMaybe<MemberProfileSocialTagsFieldUpdateOneInput>;
  superpowers?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
  userId?: InputMaybe<Scalars['ID']>;
  website?: InputMaybe<MemberProfileStringFieldUpdateOneInput>;
};

export type MemberProfileUpsertNestedInput = {
  create: MemberProfileCreateInput;
  update: MemberProfileUpdateDataInput;
};

export type MemberProfileUpsertWithoutCommunityTitlesInput = {
  create: MemberProfileCreateWithoutCommunityTitlesInput;
  update: MemberProfileUpdateWithoutCommunityTitlesDataInput;
};

export type MemberProfileWhereInput = {
  AND?: InputMaybe<Array<MemberProfileWhereInput>>;
  NOT?: InputMaybe<Array<MemberProfileWhereInput>>;
  OR?: InputMaybe<Array<MemberProfileWhereInput>>;
  address?: InputMaybe<MemberProfileAddressFieldWhereInput>;
  biography?: InputMaybe<MemberProfileStringFieldWhereInput>;
  callToAction?: InputMaybe<MemberProfileCallToActionFieldWhereInput>;
  communityTitles_every?: InputMaybe<CommunityTitleWhereInput>;
  communityTitles_none?: InputMaybe<CommunityTitleWhereInput>;
  communityTitles_some?: InputMaybe<CommunityTitleWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dateOfBirth?: InputMaybe<MemberProfileDateFieldWhereInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dummy?: InputMaybe<Scalars['String']>;
  dummy_contains?: InputMaybe<Scalars['String']>;
  dummy_ends_with?: InputMaybe<Scalars['String']>;
  dummy_gt?: InputMaybe<Scalars['String']>;
  dummy_gte?: InputMaybe<Scalars['String']>;
  dummy_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_lt?: InputMaybe<Scalars['String']>;
  dummy_lte?: InputMaybe<Scalars['String']>;
  dummy_not?: InputMaybe<Scalars['String']>;
  dummy_not_contains?: InputMaybe<Scalars['String']>;
  dummy_not_ends_with?: InputMaybe<Scalars['String']>;
  dummy_not_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_not_starts_with?: InputMaybe<Scalars['String']>;
  dummy_starts_with?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<MemberProfileStringFieldWhereInput>;
  hoodId?: InputMaybe<Scalars['ID']>;
  hoodId_contains?: InputMaybe<Scalars['ID']>;
  hoodId_ends_with?: InputMaybe<Scalars['ID']>;
  hoodId_gt?: InputMaybe<Scalars['ID']>;
  hoodId_gte?: InputMaybe<Scalars['ID']>;
  hoodId_in?: InputMaybe<Array<Scalars['ID']>>;
  hoodId_lt?: InputMaybe<Scalars['ID']>;
  hoodId_lte?: InputMaybe<Scalars['ID']>;
  hoodId_not?: InputMaybe<Scalars['ID']>;
  hoodId_not_contains?: InputMaybe<Scalars['ID']>;
  hoodId_not_ends_with?: InputMaybe<Scalars['ID']>;
  hoodId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  hoodId_not_starts_with?: InputMaybe<Scalars['ID']>;
  hoodId_starts_with?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<MemberProfileStringFieldWhereInput>;
  lastName?: InputMaybe<MemberProfileStringFieldWhereInput>;
  memberSince?: InputMaybe<MemberProfileDateFieldWhereInput>;
  occupation?: InputMaybe<MemberProfileStringFieldWhereInput>;
  pets?: InputMaybe<MemberProfilePetsFieldWhereInput>;
  pronouns?: InputMaybe<MemberProfilePronounsFieldWhereInput>;
  quote?: InputMaybe<MemberProfileStringFieldWhereInput>;
  socialLinks?: InputMaybe<MemberProfileSocialLinksFieldWhereInput>;
  socialTags?: InputMaybe<MemberProfileSocialTagsFieldWhereInput>;
  superpowers?: InputMaybe<MemberProfileStringFieldWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
  website?: InputMaybe<MemberProfileStringFieldWhereInput>;
};

export type MemberProfileWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
  visibleAddress?: InputMaybe<Scalars['Boolean']>;
};

export enum MemberType {
  LEGACY_TENANT = 'LEGACY_TENANT',
  MEMBER = 'MEMBER',
  NEIGHBOUR = 'NEIGHBOUR'
}

export type MemberUpdateDataInput = {
  addresses?: InputMaybe<MemberAddressUpdateManyWithoutMemberInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Gender>;
  nationality?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutMemberInput>;
};

export type MemberUpdateInput = {
  addresses?: InputMaybe<MemberAddressUpdateManyWithoutMemberInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Gender>;
  nationality?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutMemberInput>;
};

export type MemberUpdateManyMutationInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Gender>;
  nationality?: InputMaybe<Scalars['String']>;
};

export type MemberUpdateOneRequiredInput = {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  create?: InputMaybe<MemberCreateInput>;
  update?: InputMaybe<MemberUpdateDataInput>;
  upsert?: InputMaybe<MemberUpsertNestedInput>;
};

export type MemberUpdateOneRequiredWithoutAddressesInput = {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  create?: InputMaybe<MemberCreateWithoutAddressesInput>;
  update?: InputMaybe<MemberUpdateWithoutAddressesDataInput>;
  upsert?: InputMaybe<MemberUpsertWithoutAddressesInput>;
};

export type MemberUpdateOneWithoutUserInput = {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  create?: InputMaybe<MemberCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MemberUpdateWithoutUserDataInput>;
  upsert?: InputMaybe<MemberUpsertWithoutUserInput>;
};

export type MemberUpdateWithoutAddressesDataInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Gender>;
  nationality?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutMemberInput>;
};

export type MemberUpdateWithoutUserDataInput = {
  addresses?: InputMaybe<MemberAddressUpdateManyWithoutMemberInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Gender>;
  nationality?: InputMaybe<Scalars['String']>;
};

export type MemberUpsertNestedInput = {
  create: MemberCreateInput;
  update: MemberUpdateDataInput;
};

export type MemberUpsertWithoutAddressesInput = {
  create: MemberCreateWithoutAddressesInput;
  update: MemberUpdateWithoutAddressesDataInput;
};

export type MemberUpsertWithoutUserInput = {
  create: MemberCreateWithoutUserInput;
  update: MemberUpdateWithoutUserDataInput;
};

export type MemberWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<MemberWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<MemberWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<MemberWhereContractsPersistencyInput>>;
  addresses_every?: InputMaybe<MemberAddressWhereContractsPersistencyInput>;
  addresses_none?: InputMaybe<MemberAddressWhereContractsPersistencyInput>;
  addresses_some?: InputMaybe<MemberAddressWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_gt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_gte?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dateOfBirth_lt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_lte?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_not?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  gender?: InputMaybe<ContractsPersistencyGender>;
  gender_in?: InputMaybe<Array<ContractsPersistencyGender>>;
  gender_not?: InputMaybe<ContractsPersistencyGender>;
  gender_not_in?: InputMaybe<Array<ContractsPersistencyGender>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_every?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  leaseContracts_none?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  membershipContracts_every?: InputMaybe<MembershipContractWhereContractsPersistencyInput>;
  membershipContracts_none?: InputMaybe<MembershipContractWhereContractsPersistencyInput>;
  membershipContracts_some?: InputMaybe<MembershipContractWhereContractsPersistencyInput>;
  nationality?: InputMaybe<Scalars['String']>;
  nationality_contains?: InputMaybe<Scalars['String']>;
  nationality_ends_with?: InputMaybe<Scalars['String']>;
  nationality_gt?: InputMaybe<Scalars['String']>;
  nationality_gte?: InputMaybe<Scalars['String']>;
  nationality_in?: InputMaybe<Array<Scalars['String']>>;
  nationality_lt?: InputMaybe<Scalars['String']>;
  nationality_lte?: InputMaybe<Scalars['String']>;
  nationality_not?: InputMaybe<Scalars['String']>;
  nationality_not_contains?: InputMaybe<Scalars['String']>;
  nationality_not_ends_with?: InputMaybe<Scalars['String']>;
  nationality_not_in?: InputMaybe<Array<Scalars['String']>>;
  nationality_not_starts_with?: InputMaybe<Scalars['String']>;
  nationality_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type MemberWhereInput = {
  AND?: InputMaybe<Array<MemberWhereInput>>;
  NOT?: InputMaybe<Array<MemberWhereInput>>;
  OR?: InputMaybe<Array<MemberWhereInput>>;
  addresses_every?: InputMaybe<MemberAddressWhereInput>;
  addresses_none?: InputMaybe<MemberAddressWhereInput>;
  addresses_some?: InputMaybe<MemberAddressWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_gt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_gte?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dateOfBirth_lt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_lte?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_not?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  gender?: InputMaybe<Gender>;
  gender_in?: InputMaybe<Array<Gender>>;
  gender_not?: InputMaybe<Gender>;
  gender_not_in?: InputMaybe<Array<Gender>>;
  hood?: InputMaybe<HoodWhereInput>;
  hoods_some?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_none?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  membershipContracts_none?: InputMaybe<MembershipContractWherePersistencyContractsInput>;
  membershipContracts_some?: InputMaybe<MembershipContractWherePersistencyContractsInput>;
  nationality?: InputMaybe<Scalars['String']>;
  nationality_contains?: InputMaybe<Scalars['String']>;
  nationality_ends_with?: InputMaybe<Scalars['String']>;
  nationality_gt?: InputMaybe<Scalars['String']>;
  nationality_gte?: InputMaybe<Scalars['String']>;
  nationality_in?: InputMaybe<Array<Scalars['String']>>;
  nationality_lt?: InputMaybe<Scalars['String']>;
  nationality_lte?: InputMaybe<Scalars['String']>;
  nationality_not?: InputMaybe<Scalars['String']>;
  nationality_not_contains?: InputMaybe<Scalars['String']>;
  nationality_not_ends_with?: InputMaybe<Scalars['String']>;
  nationality_not_in?: InputMaybe<Array<Scalars['String']>>;
  nationality_not_starts_with?: InputMaybe<Scalars['String']>;
  nationality_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
};

export type MemberWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<MemberWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<MemberWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<MemberWherePersistencyContractsInput>>;
  addresses_every?: InputMaybe<MemberAddressWherePersistencyContractsInput>;
  addresses_none?: InputMaybe<MemberAddressWherePersistencyContractsInput>;
  addresses_some?: InputMaybe<MemberAddressWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_gt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_gte?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_in?: InputMaybe<Array<Scalars['DateTime']>>;
  dateOfBirth_lt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_lte?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_not?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  gender?: InputMaybe<PersistencyContractsGender>;
  gender_in?: InputMaybe<Array<PersistencyContractsGender>>;
  gender_not?: InputMaybe<PersistencyContractsGender>;
  gender_not_in?: InputMaybe<Array<PersistencyContractsGender>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_every?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_none?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  membershipContracts_every?: InputMaybe<MembershipContractWherePersistencyContractsInput>;
  membershipContracts_none?: InputMaybe<MembershipContractWherePersistencyContractsInput>;
  membershipContracts_some?: InputMaybe<MembershipContractWherePersistencyContractsInput>;
  nationality?: InputMaybe<Scalars['String']>;
  nationality_contains?: InputMaybe<Scalars['String']>;
  nationality_ends_with?: InputMaybe<Scalars['String']>;
  nationality_gt?: InputMaybe<Scalars['String']>;
  nationality_gte?: InputMaybe<Scalars['String']>;
  nationality_in?: InputMaybe<Array<Scalars['String']>>;
  nationality_lt?: InputMaybe<Scalars['String']>;
  nationality_lte?: InputMaybe<Scalars['String']>;
  nationality_not?: InputMaybe<Scalars['String']>;
  nationality_not_contains?: InputMaybe<Scalars['String']>;
  nationality_not_ends_with?: InputMaybe<Scalars['String']>;
  nationality_not_in?: InputMaybe<Array<Scalars['String']>>;
  nationality_not_starts_with?: InputMaybe<Scalars['String']>;
  nationality_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type MemberWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MembershipContract = {
  __typename?: 'MembershipContract';
  billableItems: Array<BillableItem>;
  computedTimeline: ContractTimeline;
  /** @deprecated Replaced by computedTimeline  */
  contractTimeline?: Maybe<MembershipContractTimelineStatus>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  hood?: Maybe<Hood>;
  hoodId: Scalars['String'];
  id: Scalars['ID'];
  isDraft?: Maybe<Scalars['Boolean']>;
  isTerminatedEarly: Scalars['Boolean'];
  member?: Maybe<Member>;
  membershipFee: Scalars['Float'];
  membershipPlanProductId: Scalars['String'];
  metadata?: Maybe<Scalars['Json']>;
  originalEndDate: Scalars['DateTime'];
  product?: Maybe<Product>;
  startDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};


export type MembershipContractBillableItemsArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type MembershipContractConnection = {
  __typename?: 'MembershipContractConnection';
  aggregate: AggregateMembershipContract;
  edges: Array<Maybe<MembershipContractEdge>>;
  pageInfo: PageInfo;
};

export type MembershipContractCreateInput = {
  billableItems?: InputMaybe<ContractBillableItemCreateContractsPersistencyInput>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  hoodId: Scalars['String'];
  isDraft?: InputMaybe<Scalars['Boolean']>;
  membershipFee?: InputMaybe<Scalars['Float']>;
  membershipPlanProductId: Scalars['String'];
  metadata?: InputMaybe<Scalars['Json']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type MembershipContractCreateOneInput = {
  connect?: InputMaybe<MembershipContractWhereUniqueInput>;
  create?: InputMaybe<MembershipContractCreateInput>;
};

export type MembershipContractEdge = {
  __typename?: 'MembershipContractEdge';
  cursor: Scalars['String'];
  node: MembershipContract;
};

export enum MembershipContractOrderByInput {
  COMPUTEDTIMELINE_ASC = 'computedTimeline_ASC',
  COMPUTEDTIMELINE_DESC = 'computedTimeline_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENDDATE_ASC = 'endDate_ASC',
  ENDDATE_DESC = 'endDate_DESC',
  HOODID_ASC = 'hoodId_ASC',
  HOODID_DESC = 'hoodId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISDRAFT_ASC = 'isDraft_ASC',
  ISDRAFT_DESC = 'isDraft_DESC',
  MEMBERSHIPFEE_ASC = 'membershipFee_ASC',
  MEMBERSHIPFEE_DESC = 'membershipFee_DESC',
  MEMBERSHIPPLANPRODUCTID_ASC = 'membershipPlanProductId_ASC',
  MEMBERSHIPPLANPRODUCTID_DESC = 'membershipPlanProductId_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  ORIGINALENDDATE_ASC = 'originalEndDate_ASC',
  ORIGINALENDDATE_DESC = 'originalEndDate_DESC',
  STARTDATE_ASC = 'startDate_ASC',
  STARTDATE_DESC = 'startDate_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export enum MembershipContractTimelineStatus {
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  PAST = 'PAST'
}

export type MembershipContractUpdateDataInput = {
  billableItems?: InputMaybe<ContractBillableItemUpdateContractsPersistencyInput>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  membershipFee?: InputMaybe<Scalars['Float']>;
  membershipPlanProductId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['Json']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MembershipContractUpdateInput = {
  billableItems?: InputMaybe<ContractBillableItemUpdateContractsPersistencyInput>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  membershipFee?: InputMaybe<Scalars['Float']>;
  membershipPlanProductId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['Json']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MembershipContractUpdateOneInput = {
  connect?: InputMaybe<MembershipContractWhereUniqueInput>;
  create?: InputMaybe<MembershipContractCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MembershipContractUpdateDataInput>;
  upsert?: InputMaybe<MembershipContractUpsertNestedInput>;
};

export type MembershipContractUpsertNestedInput = {
  create: MembershipContractCreateInput;
  update: MembershipContractUpdateDataInput;
};

export type MembershipContractWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<MembershipContractWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<MembershipContractWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<MembershipContractWhereContractsPersistencyInput>>;
  billableItems_every?: InputMaybe<BillableItemWhereContractsPersistencyInput>;
  billableItems_none?: InputMaybe<BillableItemWhereContractsPersistencyInput>;
  billableItems_some?: InputMaybe<BillableItemWhereContractsPersistencyInput>;
  contractTimeline?: InputMaybe<ContractsPersistencyContractTimelineStatus>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isDraft_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWhereContractsPersistencyInput>;
  membershipFee?: InputMaybe<Scalars['Float']>;
  membershipFee_gt?: InputMaybe<Scalars['Float']>;
  membershipFee_gte?: InputMaybe<Scalars['Float']>;
  membershipFee_in?: InputMaybe<Array<Scalars['Float']>>;
  membershipFee_lt?: InputMaybe<Scalars['Float']>;
  membershipFee_lte?: InputMaybe<Scalars['Float']>;
  membershipFee_not?: InputMaybe<Scalars['Float']>;
  membershipFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  originalEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type MembershipContractWhereInput = {
  AND?: InputMaybe<Array<MembershipContractWhereInput>>;
  NOT?: InputMaybe<Array<MembershipContractWhereInput>>;
  OR?: InputMaybe<Array<MembershipContractWhereInput>>;
  computedTimeline?: InputMaybe<ContractTimeline>;
  computedTimeline_in?: InputMaybe<Array<ContractTimeline>>;
  computedTimeline_not?: InputMaybe<ContractTimeline>;
  computedTimeline_not_in?: InputMaybe<Array<ContractTimeline>>;
  contractTimeline?: InputMaybe<MembershipContractTimelineStatus>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  hoodId?: InputMaybe<Scalars['String']>;
  hoodId_contains?: InputMaybe<Scalars['String']>;
  hoodId_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_gt?: InputMaybe<Scalars['String']>;
  hoodId_gte?: InputMaybe<Scalars['String']>;
  hoodId_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_lt?: InputMaybe<Scalars['String']>;
  hoodId_lte?: InputMaybe<Scalars['String']>;
  hoodId_not?: InputMaybe<Scalars['String']>;
  hoodId_not_contains?: InputMaybe<Scalars['String']>;
  hoodId_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isDraft_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWhereContractsPersistencyInput>;
  membershipFee?: InputMaybe<Scalars['Float']>;
  membershipFee_gt?: InputMaybe<Scalars['Float']>;
  membershipFee_gte?: InputMaybe<Scalars['Float']>;
  membershipFee_in?: InputMaybe<Array<Scalars['Float']>>;
  membershipFee_lt?: InputMaybe<Scalars['Float']>;
  membershipFee_lte?: InputMaybe<Scalars['Float']>;
  membershipFee_not?: InputMaybe<Scalars['Float']>;
  membershipFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  membershipPlanProductId?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_contains?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_ends_with?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_gt?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_gte?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_in?: InputMaybe<Array<Scalars['String']>>;
  membershipPlanProductId_lt?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_lte?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_not?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_not_contains?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_not_ends_with?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_not_in?: InputMaybe<Array<Scalars['String']>>;
  membershipPlanProductId_not_starts_with?: InputMaybe<Scalars['String']>;
  membershipPlanProductId_starts_with?: InputMaybe<Scalars['String']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  originalEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type MembershipContractWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<MembershipContractWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<MembershipContractWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<MembershipContractWherePersistencyContractsInput>>;
  billableItems_every?: InputMaybe<BillableItemWherePersistencyContractsInput>;
  billableItems_none?: InputMaybe<BillableItemWherePersistencyContractsInput>;
  billableItems_some?: InputMaybe<BillableItemWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isDraft_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWherePersistencyContractsInput>;
  membershipFee?: InputMaybe<Scalars['Float']>;
  membershipFee_gt?: InputMaybe<Scalars['Float']>;
  membershipFee_gte?: InputMaybe<Scalars['Float']>;
  membershipFee_in?: InputMaybe<Array<Scalars['Float']>>;
  membershipFee_lt?: InputMaybe<Scalars['Float']>;
  membershipFee_lte?: InputMaybe<Scalars['Float']>;
  membershipFee_not?: InputMaybe<Scalars['Float']>;
  membershipFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  originalEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type MembershipContractWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MobileAppPage = {
  __typename?: 'MobileAppPage';
  _empty?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hood: Hood;
  id: Scalars['ID'];
  image: Scalars['Json'];
  publish: Scalars['Boolean'];
  subTitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MobileAppPageConnection = {
  __typename?: 'MobileAppPageConnection';
  aggregate: AggregateMobileAppPage;
  edges: Array<Maybe<MobileAppPageEdge>>;
  pageInfo: PageInfo;
};

export type MobileAppPageCreateInput = {
  cards?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hood: HoodCreateOneInput;
  image: Scalars['Json'];
  publish?: InputMaybe<Scalars['Boolean']>;
  subTitle: Scalars['String'];
  title: Scalars['String'];
};

export type MobileAppPageEdge = {
  __typename?: 'MobileAppPageEdge';
  cursor: Scalars['String'];
  node: MobileAppPage;
};

export enum MobileAppPageOrderByInput {
  CARDS_ASC = 'cards_ASC',
  CARDS_DESC = 'cards_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGE_ASC = 'image_ASC',
  IMAGE_DESC = 'image_DESC',
  PUBLISH_ASC = 'publish_ASC',
  PUBLISH_DESC = 'publish_DESC',
  SUBTITLE_ASC = 'subTitle_ASC',
  SUBTITLE_DESC = 'subTitle_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type MobileAppPageUpdateInput = {
  cards?: InputMaybe<Scalars['Json']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  image?: InputMaybe<Scalars['Json']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type MobileAppPageWhereInput = {
  AND?: InputMaybe<Array<MobileAppPageWhereInput>>;
  NOT?: InputMaybe<Array<MobileAppPageWhereInput>>;
  OR?: InputMaybe<Array<MobileAppPageWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitle_contains?: InputMaybe<Scalars['String']>;
  subTitle_ends_with?: InputMaybe<Scalars['String']>;
  subTitle_gt?: InputMaybe<Scalars['String']>;
  subTitle_gte?: InputMaybe<Scalars['String']>;
  subTitle_in?: InputMaybe<Array<Scalars['String']>>;
  subTitle_lt?: InputMaybe<Scalars['String']>;
  subTitle_lte?: InputMaybe<Scalars['String']>;
  subTitle_not?: InputMaybe<Scalars['String']>;
  subTitle_not_contains?: InputMaybe<Scalars['String']>;
  subTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  subTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  subTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  subTitle_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_ends_with?: InputMaybe<Scalars['String']>;
  title_gt?: InputMaybe<Scalars['String']>;
  title_gte?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  title_lt?: InputMaybe<Scalars['String']>;
  title_lte?: InputMaybe<Scalars['String']>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type MobileAppPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum MomentPublishStatus {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
  UNPUBLISHED = 'UNPUBLISHED'
}

export enum MomentType {
  DISCUSSION = 'DISCUSSION',
  POLL = 'POLL'
}

export type MomentsFeedInput = {
  hoodName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addComment?: Maybe<Comment>;
  answerSurvey?: Maybe<Survey>;
  approveOrderDelivery: ServiceProductOrder;
  attendEvent?: Maybe<EventAttendee>;
  attendPricedEvent?: Maybe<PricedEventAttendee>;
  blockMemberProfile?: Maybe<MemberProfile>;
  bookOnBehalf?: Maybe<Array<Maybe<Scalars['String']>>>;
  bookTimeSlots?: Maybe<Array<Maybe<CalendarBooking>>>;
  cancelBooking?: Maybe<CancelCalendarBookingResult>;
  cancelServiceProductOrderByBackOffice: ServiceProductOrder;
  cancelSubscriptionContract: SubscriptionContract;
  changeFeaturedSlidePriority?: Maybe<FeaturedSlidePriorityActionsResult>;
  createAddress: Address;
  createAlbertDog: AlbertDog;
  createApplication: Application;
  createAudit: Audit;
  createBedroom: Bedroom;
  createBill: Bill;
  createBillableItem: BillableItem;
  createBuilding: Building;
  createBuildingSetting: BuildingSetting;
  createCalendar: Calendar;
  createCalendarBooking: CalendarBooking;
  createComment: Comment;
  createCommunityTitle: CommunityTitle;
  createCurrency: Currency;
  createDeliveryPackage: DeliveryPackage;
  createDiscussion: SocialMoment;
  createEvent: Event;
  createEventAttendee: EventAttendee;
  createEventSuggestion?: Maybe<CreateEventSuggestionResult>;
  createExternalBill: ExternalBill;
  createExternalBillReceipt: ExternalBillReceipt;
  createExternalReceipt: ExternalReceipt;
  createFeature: Feature;
  createFeatureAnnouncement: FeatureAnnouncement;
  createFeaturedSlide: FeaturedSlide;
  createFeedClassification: FeedClassification;
  createHood: Hood;
  createHoodSetting: HoodSetting;
  createHoodSettingValue: HoodSettingValue;
  createHoodSettingsGroup: HoodSettingsGroup;
  createInterestGroup?: Maybe<InterestGroup>;
  createInvoice: Invoice;
  createLeaseContract: LeaseContract;
  createManyBillableItems: Array<BillableItem>;
  createManyBills: Array<Bill>;
  createManyPaymentTransactions: Array<PaymentTransaction>;
  createManyTransactions: Array<Transaction>;
  createMember: Member;
  createMemberAddress: MemberAddress;
  createMemberPaymentOsCustomer: MemberPaymentOsCustomer;
  createMemberProfile: MemberProfile;
  createMemberProfileAddressField: MemberProfileAddressField;
  createMemberProfileBlock: MemberProfileBlock;
  createMemberProfileCallToActionField: MemberProfileCallToActionField;
  createMemberProfileDateField: MemberProfileDateField;
  createMemberProfilePetsField: MemberProfilePetsField;
  createMemberProfilePronounsField: MemberProfilePronounsField;
  createMemberProfileSocialLinksField: MemberProfileSocialLinksField;
  createMemberProfileSocialTagsField: MemberProfileSocialTagsField;
  createMemberProfileStringField: MemberProfileStringField;
  createMembershipContract: MembershipContract;
  createMobileAppPage: MobileAppPage;
  createPaymentProvider: PaymentProvider;
  createPaymentReceiptEmail: PaymentReceiptEmail;
  createPaymentRequest: PaymentRequest;
  createPaymentRequestEmail: PaymentRequestEmail;
  createPaymentSetting: PaymentSetting;
  createPaymentTransaction: PaymentTransaction;
  createPayoutPlan: PayoutPlan;
  createPet: Pet;
  createPlace: Place;
  createPortfolio: Portfolio;
  createProduct: Product;
  createProductCategory: ProductCategory;
  createProductType: ProductType;
  createPropertyManagementOffice: PropertyManagementOffice;
  createRole: Role;
  createService: Service;
  createServiceCategory: ServiceCategory;
  createServiceProduct: ServiceProduct;
  createServiceProductOrder: ServiceProductOrder;
  createSharedSpace: SharedSpace;
  createSocialInteraction: SocialInteraction;
  createSocialMoment: SocialMoment;
  createSocialMomentSentiment: SocialMomentSentiment;
  createSocialMomentView: SocialMomentView;
  createSocialReaction: SocialReaction;
  createSocialTag: SocialTag;
  createSubProduct: SubProduct;
  createSubscriptionContract: SubscriptionContract;
  createSurvey: Survey;
  createSurveyQuestion: SurveyQuestion;
  createSurveyQuestionResponse: SurveyQuestionResponse;
  createSurveyResult: SurveyResult;
  createSurveyUserAction: SurveyUserAction;
  createTaxRate: TaxRate;
  createTransaction: Transaction;
  createTreasureItem: TreasureItem;
  createUnit: Unit;
  createUnitStatusLog: UnitStatusLog;
  createUser: User;
  createUserApiKey: UserApiKey;
  createUserPaymentMethod: UserPaymentMethod;
  deleteAddress?: Maybe<Address>;
  deleteAlbertDog?: Maybe<AlbertDog>;
  deleteApplication?: Maybe<Application>;
  deleteBedroom?: Maybe<Bedroom>;
  deleteBill?: Maybe<Bill>;
  deleteBillableItem?: Maybe<BillableItem>;
  deleteBuilding?: Maybe<Building>;
  deleteBuildingSetting?: Maybe<BuildingSetting>;
  deleteCalendar?: Maybe<Calendar>;
  deleteCalendarBooking?: Maybe<CalendarBooking>;
  deleteComment?: Maybe<Comment>;
  deleteCommunityTitle?: Maybe<CommunityTitle>;
  deleteCurrency?: Maybe<Currency>;
  deleteDeliveryPackage?: Maybe<DeliveryPackage>;
  deleteEvent?: Maybe<Event>;
  deleteEventAttendee?: Maybe<EventAttendee>;
  deleteExternalBill?: Maybe<ExternalBill>;
  deleteExternalBillReceipt?: Maybe<ExternalBillReceipt>;
  deleteExternalReceipt?: Maybe<ExternalReceipt>;
  deleteFeature?: Maybe<Feature>;
  deleteFeatureAnnouncement?: Maybe<FeatureAnnouncement>;
  deleteFeaturedSlide?: Maybe<FeaturedSlide>;
  deleteFeedClassification?: Maybe<FeedClassification>;
  deleteHood?: Maybe<Hood>;
  deleteHoodSetting?: Maybe<HoodSetting>;
  deleteHoodSettingValue?: Maybe<HoodSettingValue>;
  deleteHoodSettingsGroup?: Maybe<HoodSettingsGroup>;
  deleteInterestGroup?: Maybe<DeleteInterestGroupResponse>;
  deleteInvoice?: Maybe<Invoice>;
  deleteLeaseContract?: Maybe<LeaseContract>;
  deleteMember?: Maybe<Member>;
  deleteMemberAddress?: Maybe<MemberAddress>;
  deleteMemberPaymentOsCustomer?: Maybe<MemberPaymentOsCustomer>;
  deleteMemberProfile?: Maybe<MemberProfile>;
  deleteMemberProfileAddressField?: Maybe<MemberProfileAddressField>;
  deleteMemberProfileBlock?: Maybe<MemberProfileBlock>;
  deleteMemberProfileCallToActionField?: Maybe<MemberProfileCallToActionField>;
  deleteMemberProfileDateField?: Maybe<MemberProfileDateField>;
  deleteMemberProfilePetsField?: Maybe<MemberProfilePetsField>;
  deleteMemberProfilePronounsField?: Maybe<MemberProfilePronounsField>;
  deleteMemberProfileSocialLinksField?: Maybe<MemberProfileSocialLinksField>;
  deleteMemberProfileSocialTagsField?: Maybe<MemberProfileSocialTagsField>;
  deleteMemberProfileStringField?: Maybe<MemberProfileStringField>;
  deleteMembershipContract?: Maybe<MembershipContract>;
  deleteMobileAppPage?: Maybe<MobileAppPage>;
  deletePaymentProvider?: Maybe<PaymentProvider>;
  deletePaymentReceiptEmail?: Maybe<PaymentReceiptEmail>;
  deletePaymentRequest?: Maybe<PaymentRequest>;
  deletePaymentRequestEmail?: Maybe<PaymentRequestEmail>;
  deletePaymentSetting?: Maybe<PaymentSetting>;
  deletePaymentTransaction?: Maybe<PaymentTransaction>;
  deletePayoutPlan?: Maybe<PayoutPlan>;
  deletePet?: Maybe<Pet>;
  deletePlace?: Maybe<Place>;
  deletePortfolio?: Maybe<Portfolio>;
  deleteProduct?: Maybe<Product>;
  deleteProductCategory?: Maybe<ProductCategory>;
  deleteProductType?: Maybe<ProductType>;
  deletePropertyManagementOffice?: Maybe<PropertyManagementOffice>;
  deleteRole?: Maybe<Role>;
  deleteService?: Maybe<Service>;
  deleteServiceCategory?: Maybe<ServiceCategory>;
  deleteServiceProduct?: Maybe<ServiceProduct>;
  deleteServiceProductOrder?: Maybe<ServiceProductOrder>;
  deleteSharedSpace?: Maybe<SharedSpace>;
  deleteSocialInteraction?: Maybe<SocialInteraction>;
  deleteSocialMoment?: Maybe<SocialMoment>;
  deleteSocialMomentSentiment?: Maybe<SocialMomentSentiment>;
  deleteSocialMomentView?: Maybe<SocialMomentView>;
  deleteSocialReaction?: Maybe<SocialReaction>;
  deleteSocialTag?: Maybe<SocialTag>;
  deleteSubProduct?: Maybe<SubProduct>;
  deleteSubscriptionContract?: Maybe<SubscriptionContract>;
  deleteSurvey?: Maybe<Survey>;
  deleteSurveyQuestion?: Maybe<SurveyQuestion>;
  deleteSurveyQuestionResponse?: Maybe<SurveyQuestionResponse>;
  deleteSurveyResult?: Maybe<SurveyResult>;
  deleteSurveyUserAction?: Maybe<SurveyUserAction>;
  deleteTaxRate?: Maybe<TaxRate>;
  deleteTransaction?: Maybe<Transaction>;
  deleteTreasureItem?: Maybe<TreasureItem>;
  deleteUnit?: Maybe<Unit>;
  deleteUnitStatusLog?: Maybe<UnitStatusLog>;
  deleteUser?: Maybe<User>;
  deleteUserApiKey?: Maybe<UserApiKey>;
  deleteUserPaymentMethod?: Maybe<UserPaymentMethod>;
  extendContract?: Maybe<ExtendContractResult>;
  extractFeaturedSlidePriority?: Maybe<FeaturedSlidePriorityActionsResult>;
  initializeApplication?: Maybe<ApplicationResult>;
  initializeContracts?: Maybe<InitializeContractsResult>;
  insertFeaturedSlidePriority?: Maybe<FeaturedSlidePriorityActionsResult>;
  payWithPayout?: Maybe<PayWithPayoutResponse>;
  purchaseImmediateServiceProduct: PurchaseServiceProductResult;
  purchaseServiceProduct: PurchaseServiceProductResult;
  requestCancelationServiceProductOrder: ServiceProductOrder;
  /** @deprecated Use requestSubscriptionContractCancellation. */
  requestCancelationSubscriptionContract: SubscriptionContract;
  requestSubscriptionContractCancellation: SubscriptionContract;
  revertApplication?: Maybe<ApplicationResult>;
  revertContracts?: Maybe<RevertContractsResult>;
  selfCreateApplication?: Maybe<Application>;
  submitPollVote: SocialMoment;
  submitSurveyResponse?: Maybe<SurveyQuestionResponse>;
  /** @deprecated Use SubscribeToProduct. */
  subscribeServiceProduct: SubscribeServiceProductResult;
  subscribeToProduct?: Maybe<SubscribeToProductResult>;
  terminateContract?: Maybe<TerminateContractResult>;
  terminateMember?: Maybe<TerminateMemberResult>;
  toggleSocialReaction?: Maybe<SocialReaction>;
  toggleVipPass?: Maybe<VipPassHoodSettingValue>;
  unattendEvent?: Maybe<EventAttendee>;
  unblockMemberProfile?: Maybe<MemberProfile>;
  updateAddress?: Maybe<Address>;
  updateAlbertDog?: Maybe<AlbertDog>;
  updateApplication?: Maybe<Application>;
  updateBedroom?: Maybe<Bedroom>;
  updateBill?: Maybe<Bill>;
  updateBillableItem?: Maybe<BillableItem>;
  updateBuilding?: Maybe<Building>;
  updateBuildingSetting?: Maybe<BuildingSetting>;
  updateCalendar?: Maybe<Calendar>;
  updateCalendarBooking?: Maybe<CalendarBooking>;
  updateComment?: Maybe<Comment>;
  updateCommunityTitle?: Maybe<CommunityTitle>;
  updateCurrency?: Maybe<Currency>;
  updateDeliveryPackage?: Maybe<DeliveryPackage>;
  updateDiscussion: SocialMoment;
  updateEvent?: Maybe<Event>;
  updateEventAttendee?: Maybe<EventAttendee>;
  updateExternalBill?: Maybe<ExternalBill>;
  updateExternalBillReceipt?: Maybe<ExternalBillReceipt>;
  updateExternalReceipt?: Maybe<ExternalReceipt>;
  updateFeature?: Maybe<Feature>;
  updateFeatureAnnouncement?: Maybe<FeatureAnnouncement>;
  updateFeaturedSlide?: Maybe<FeaturedSlide>;
  updateFeedClassification?: Maybe<FeedClassification>;
  updateHood?: Maybe<Hood>;
  updateHoodSetting?: Maybe<HoodSetting>;
  updateHoodSettingValue?: Maybe<HoodSettingValue>;
  updateHoodSettingsGroup?: Maybe<HoodSettingsGroup>;
  updateInterestGroup?: Maybe<InterestGroup>;
  updateInvoice?: Maybe<Invoice>;
  updateLeaseContract?: Maybe<LeaseContract>;
  updateManyBillableItems: BatchPayload;
  updateManyBills: BatchPayload;
  updateManyLeaseContracts: BatchPayload;
  updateManyMembers: BatchPayload;
  updateManyPaymentTransactions: BatchPayload;
  updateManySocialInteractions: BatchPayload;
  updateManyTransactions: BatchPayload;
  updateManyUsers: BatchPayload;
  updateMember?: Maybe<Member>;
  updateMemberAddress?: Maybe<MemberAddress>;
  updateMemberPaymentOsCustomer?: Maybe<MemberPaymentOsCustomer>;
  updateMemberProfile?: Maybe<MemberProfile>;
  updateMemberProfileAddressField?: Maybe<MemberProfileAddressField>;
  updateMemberProfileBlock?: Maybe<MemberProfileBlock>;
  updateMemberProfileCallToActionField?: Maybe<MemberProfileCallToActionField>;
  updateMemberProfileDateField?: Maybe<MemberProfileDateField>;
  updateMemberProfilePetsField?: Maybe<MemberProfilePetsField>;
  updateMemberProfilePronounsField?: Maybe<MemberProfilePronounsField>;
  updateMemberProfileSocialLinksField?: Maybe<MemberProfileSocialLinksField>;
  updateMemberProfileSocialTagsField?: Maybe<MemberProfileSocialTagsField>;
  updateMemberProfileStringField?: Maybe<MemberProfileStringField>;
  updateMembershipContract?: Maybe<MembershipContract>;
  updateMobileAppPage?: Maybe<MobileAppPage>;
  updatePaymentProvider?: Maybe<PaymentProvider>;
  updatePaymentReceiptEmail?: Maybe<PaymentReceiptEmail>;
  updatePaymentRequest?: Maybe<PaymentRequest>;
  updatePaymentRequestEmail?: Maybe<PaymentRequestEmail>;
  updatePaymentSetting?: Maybe<PaymentSetting>;
  updatePaymentTransaction?: Maybe<PaymentTransaction>;
  updatePayoutPlan?: Maybe<PayoutPlan>;
  updatePet?: Maybe<Pet>;
  updatePlace?: Maybe<Place>;
  updatePortfolio?: Maybe<Portfolio>;
  updateProduct?: Maybe<Product>;
  updateProductCategory?: Maybe<ProductCategory>;
  updateProductType?: Maybe<ProductType>;
  updatePropertyManagementOffice?: Maybe<PropertyManagementOffice>;
  updateRole?: Maybe<Role>;
  updateService?: Maybe<Service>;
  updateServiceCategory?: Maybe<ServiceCategory>;
  updateServiceProduct?: Maybe<ServiceProduct>;
  updateServiceProductOrder?: Maybe<ServiceProductOrder>;
  updateSharedSpace?: Maybe<SharedSpace>;
  updateSocialInteraction?: Maybe<SocialInteraction>;
  updateSocialMoment?: Maybe<SocialMoment>;
  updateSocialMomentSentiment?: Maybe<SocialMomentSentiment>;
  updateSocialMomentView?: Maybe<SocialMomentView>;
  updateSocialReaction?: Maybe<SocialReaction>;
  updateSocialTag?: Maybe<SocialTag>;
  updateSocialTagsForMemberProfile?: Maybe<MemberProfile>;
  updateSubProduct?: Maybe<SubProduct>;
  updateSubscriptionContract?: Maybe<SubscriptionContract>;
  updateSurvey?: Maybe<Survey>;
  updateSurveyQuestion?: Maybe<SurveyQuestion>;
  updateSurveyQuestionResponse?: Maybe<SurveyQuestionResponse>;
  updateSurveyResult?: Maybe<SurveyResult>;
  updateSurveyUserAction?: Maybe<SurveyUserAction>;
  updateTaxRate?: Maybe<TaxRate>;
  updateTransaction?: Maybe<Transaction>;
  updateTreasureItem?: Maybe<TreasureItem>;
  updateUnit?: Maybe<Unit>;
  updateUnitStatusLog?: Maybe<UnitStatusLog>;
  updateUser?: Maybe<User>;
  updateUserApiKey?: Maybe<UserApiKey>;
  updateUserPaymentMethod?: Maybe<UserPaymentMethod>;
  upsertQuestionResponse?: Maybe<SurveyQuestionResponse>;
  userViewedMoment?: Maybe<SocialMomentView>;
};


export type MutationAddCommentArgs = {
  data: AddCommentInput;
};


export type MutationAnswerSurveyArgs = {
  responses?: InputMaybe<Array<InputMaybe<SurveyQuestionResponseCreateInput>>>;
  surveyName: Scalars['String'];
};


export type MutationApproveOrderDeliveryArgs = {
  where: ServiceProductOrderWhereUniqueInput;
};


export type MutationAttendEventArgs = {
  data: AttendanceEventInput;
};


export type MutationAttendPricedEventArgs = {
  data?: InputMaybe<AttendancePricedEventInput>;
};


export type MutationBlockMemberProfileArgs = {
  data: BlockMemberProfileInput;
};


export type MutationBookOnBehalfArgs = {
  comment?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['ID'];
  resource: CalendarBookableInput;
  slots?: InputMaybe<Array<CalendarTimeSlotInput>>;
};


export type MutationBookTimeSlotsArgs = {
  resource?: InputMaybe<CalendarBookableInput>;
  slots?: InputMaybe<Array<CalendarTimeSlotInput>>;
};


export type MutationCancelBookingArgs = {
  calendarBookingId: Scalars['String'];
};


export type MutationCancelServiceProductOrderByBackOfficeArgs = {
  where: ServiceProductOrderWhereUniqueInput;
};


export type MutationCancelSubscriptionContractArgs = {
  where: SubscriptionContractWhereUniqueInput;
};


export type MutationChangeFeaturedSlidePriorityArgs = {
  data: InsertFeaturedSlidePriorityInput;
};


export type MutationCreateAddressArgs = {
  data: AddressCreateInput;
};


export type MutationCreateAlbertDogArgs = {
  data: AlbertDogCreateInput;
};


export type MutationCreateApplicationArgs = {
  data: ApplicationCreateInput;
};


export type MutationCreateAuditArgs = {
  data: AuditCreateInput;
};


export type MutationCreateBedroomArgs = {
  data: BedroomCreateInput;
};


export type MutationCreateBillArgs = {
  data: BillCreateInput;
};


export type MutationCreateBillableItemArgs = {
  data: BillableItemCreateInput;
};


export type MutationCreateBuildingArgs = {
  data: BuildingCreateInput;
};


export type MutationCreateBuildingSettingArgs = {
  data: BuildingSettingCreateInput;
};


export type MutationCreateCalendarArgs = {
  data: CalendarCreateInput;
};


export type MutationCreateCalendarBookingArgs = {
  data: CalendarBookingCreateInput;
};


export type MutationCreateCommentArgs = {
  data: CommentCreateInput;
};


export type MutationCreateCommunityTitleArgs = {
  data: CommunityTitleCreateInput;
};


export type MutationCreateCurrencyArgs = {
  data: CurrencyCreateInput;
};


export type MutationCreateDeliveryPackageArgs = {
  data: DeliveryPackageCreateInput;
};


export type MutationCreateDiscussionArgs = {
  momentData: SocialMomentCreateInput;
  pollData?: InputMaybe<PollCreateInput>;
};


export type MutationCreateEventArgs = {
  data: EventCreateInput;
};


export type MutationCreateEventAttendeeArgs = {
  data: EventAttendeeCreateInput;
};


export type MutationCreateEventSuggestionArgs = {
  data: CreateEventSuggestionInput;
};


export type MutationCreateExternalBillArgs = {
  data: ExternalBillCreateInput;
};


export type MutationCreateExternalBillReceiptArgs = {
  data: ExternalBillReceiptCreateInput;
};


export type MutationCreateExternalReceiptArgs = {
  data: ExternalReceiptCreateInput;
};


export type MutationCreateFeatureArgs = {
  data: FeatureCreateInput;
};


export type MutationCreateFeatureAnnouncementArgs = {
  data: FeatureAnnouncementCreateInput;
};


export type MutationCreateFeaturedSlideArgs = {
  data: FeaturedSlideCreateInput;
};


export type MutationCreateFeedClassificationArgs = {
  data: FeedClassificationCreateInput;
};


export type MutationCreateHoodArgs = {
  data: HoodCreateInput;
};


export type MutationCreateHoodSettingArgs = {
  data: HoodSettingCreateInput;
};


export type MutationCreateHoodSettingValueArgs = {
  data: HoodSettingValueCreateInput;
};


export type MutationCreateHoodSettingsGroupArgs = {
  data: HoodSettingsGroupCreateInput;
};


export type MutationCreateInterestGroupArgs = {
  data: CreateInterestGroupInput;
};


export type MutationCreateInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationCreateLeaseContractArgs = {
  data: LeaseContractCreateInput;
};


export type MutationCreateManyBillableItemsArgs = {
  data: Array<BillableItemCreateInput>;
};


export type MutationCreateManyBillsArgs = {
  data: Array<BillCreateInput>;
};


export type MutationCreateManyPaymentTransactionsArgs = {
  data: Array<PaymentTransactionCreateInput>;
};


export type MutationCreateManyTransactionsArgs = {
  data: Array<TransactionCreateInput>;
};


export type MutationCreateMemberArgs = {
  data: MemberCreateInput;
};


export type MutationCreateMemberAddressArgs = {
  data: MemberAddressCreateInput;
};


export type MutationCreateMemberPaymentOsCustomerArgs = {
  data: MemberPaymentOsCustomerCreateInput;
};


export type MutationCreateMemberProfileArgs = {
  data: MemberProfileCreateInput;
};


export type MutationCreateMemberProfileAddressFieldArgs = {
  data: MemberProfileAddressFieldCreateInput;
};


export type MutationCreateMemberProfileBlockArgs = {
  data: MemberProfileBlockCreateInput;
};


export type MutationCreateMemberProfileCallToActionFieldArgs = {
  data: MemberProfileCallToActionFieldCreateInput;
};


export type MutationCreateMemberProfileDateFieldArgs = {
  data: MemberProfileDateFieldCreateInput;
};


export type MutationCreateMemberProfilePetsFieldArgs = {
  data: MemberProfilePetsFieldCreateInput;
};


export type MutationCreateMemberProfilePronounsFieldArgs = {
  data: MemberProfilePronounsFieldCreateInput;
};


export type MutationCreateMemberProfileSocialLinksFieldArgs = {
  data: MemberProfileSocialLinksFieldCreateInput;
};


export type MutationCreateMemberProfileSocialTagsFieldArgs = {
  data: MemberProfileSocialTagsFieldCreateInput;
};


export type MutationCreateMemberProfileStringFieldArgs = {
  data: MemberProfileStringFieldCreateInput;
};


export type MutationCreateMembershipContractArgs = {
  data: MembershipContractCreateInput;
};


export type MutationCreateMobileAppPageArgs = {
  data: MobileAppPageCreateInput;
};


export type MutationCreatePaymentProviderArgs = {
  data: PaymentProviderCreateInput;
};


export type MutationCreatePaymentReceiptEmailArgs = {
  data: PaymentReceiptEmailCreateInput;
};


export type MutationCreatePaymentRequestArgs = {
  data: PaymentRequestCreateInput;
};


export type MutationCreatePaymentRequestEmailArgs = {
  data: PaymentRequestEmailCreateInput;
};


export type MutationCreatePaymentSettingArgs = {
  data: PaymentSettingCreateInput;
};


export type MutationCreatePaymentTransactionArgs = {
  data: PaymentTransactionCreateInput;
};


export type MutationCreatePayoutPlanArgs = {
  data: PayoutPlanCreateInput;
};


export type MutationCreatePetArgs = {
  data: PetCreateInput;
};


export type MutationCreatePlaceArgs = {
  data: PlaceCreateInput;
};


export type MutationCreatePortfolioArgs = {
  data: PortfolioCreateInput;
};


export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateProductCategoryArgs = {
  data: ProductCategoryCreateInput;
};


export type MutationCreateProductTypeArgs = {
  data: ProductTypeCreateInput;
};


export type MutationCreatePropertyManagementOfficeArgs = {
  data: PropertyManagementOfficeCreateInput;
};


export type MutationCreateRoleArgs = {
  data: RoleCreateInput;
};


export type MutationCreateServiceArgs = {
  data: ServiceCreateInput;
};


export type MutationCreateServiceCategoryArgs = {
  data: ServiceCategoryCreateInput;
};


export type MutationCreateServiceProductArgs = {
  data: ServiceProductCreateInput;
};


export type MutationCreateServiceProductOrderArgs = {
  data: ServiceProductOrderCreateInput;
};


export type MutationCreateSharedSpaceArgs = {
  data: SharedSpaceCreateInput;
};


export type MutationCreateSocialInteractionArgs = {
  data: SocialInteractionCreateInput;
};


export type MutationCreateSocialMomentArgs = {
  data: SocialMomentCreateInput;
};


export type MutationCreateSocialMomentSentimentArgs = {
  data: SocialMomentSentimentCreateInput;
};


export type MutationCreateSocialMomentViewArgs = {
  data: SocialMomentViewCreateInput;
};


export type MutationCreateSocialReactionArgs = {
  data: SocialReactionCreateInput;
};


export type MutationCreateSocialTagArgs = {
  data: SocialTagCreateInput;
};


export type MutationCreateSubProductArgs = {
  data: SubProductCreateInput;
};


export type MutationCreateSubscriptionContractArgs = {
  data: SubscriptionContractCreateInput;
};


export type MutationCreateSurveyArgs = {
  data: SurveyCreateInput;
};


export type MutationCreateSurveyQuestionArgs = {
  data: SurveyQuestionCreateInput;
};


export type MutationCreateSurveyQuestionResponseArgs = {
  data: SurveyQuestionResponseCreateInput;
};


export type MutationCreateSurveyResultArgs = {
  data: SurveyResultCreateInput;
};


export type MutationCreateSurveyUserActionArgs = {
  data: SurveyUserActionCreateInput;
};


export type MutationCreateTaxRateArgs = {
  data: TaxRateCreateInput;
};


export type MutationCreateTransactionArgs = {
  data: TransactionCreateInput;
};


export type MutationCreateTreasureItemArgs = {
  data: TreasureItemCreateInput;
};


export type MutationCreateUnitArgs = {
  data: UnitCreateInput;
};


export type MutationCreateUnitStatusLogArgs = {
  data: UnitStatusLogCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUserApiKeyArgs = {
  data: UserApiKeyCreateInput;
};


export type MutationCreateUserPaymentMethodArgs = {
  data: UserPaymentMethodCreateInput;
};


export type MutationDeleteAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type MutationDeleteAlbertDogArgs = {
  where: AlbertDogWhereUniqueInput;
};


export type MutationDeleteApplicationArgs = {
  where: ApplicationWhereUniqueInput;
};


export type MutationDeleteBedroomArgs = {
  where: BedroomWhereUniqueInput;
};


export type MutationDeleteBillArgs = {
  where: BillWhereUniqueInput;
};


export type MutationDeleteBillableItemArgs = {
  where: BillableItemWhereUniqueInput;
};


export type MutationDeleteBuildingArgs = {
  where: BuildingWhereUniqueInput;
};


export type MutationDeleteBuildingSettingArgs = {
  where: BuildingSettingWhereUniqueInput;
};


export type MutationDeleteCalendarArgs = {
  where: CalendarWhereUniqueInput;
};


export type MutationDeleteCalendarBookingArgs = {
  where: CalendarBookingWhereUniqueInput;
};


export type MutationDeleteCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationDeleteCommunityTitleArgs = {
  where: CommunityTitleWhereUniqueInput;
};


export type MutationDeleteCurrencyArgs = {
  where: CurrencyWhereUniqueInput;
};


export type MutationDeleteDeliveryPackageArgs = {
  where: DeliveryPackageWhereUniqueInput;
};


export type MutationDeleteEventArgs = {
  where: EventWhereUniqueInput;
};


export type MutationDeleteEventAttendeeArgs = {
  where: EventAttendeeWhereUniqueInput;
};


export type MutationDeleteExternalBillArgs = {
  where: ExternalBillWhereUniqueInput;
};


export type MutationDeleteExternalBillReceiptArgs = {
  where: ExternalBillReceiptWhereUniqueInput;
};


export type MutationDeleteExternalReceiptArgs = {
  where: ExternalReceiptWhereUniqueInput;
};


export type MutationDeleteFeatureArgs = {
  where: FeatureWhereUniqueInput;
};


export type MutationDeleteFeatureAnnouncementArgs = {
  where: FeatureAnnouncementWhereUniqueInput;
};


export type MutationDeleteFeaturedSlideArgs = {
  where: FeaturedSlideWhereUniqueInput;
};


export type MutationDeleteFeedClassificationArgs = {
  where: FeedClassificationWhereUniqueInput;
};


export type MutationDeleteHoodArgs = {
  where: HoodWhereUniqueInput;
};


export type MutationDeleteHoodSettingArgs = {
  where: HoodSettingWhereUniqueInput;
};


export type MutationDeleteHoodSettingValueArgs = {
  where: HoodSettingValueWhereUniqueInput;
};


export type MutationDeleteHoodSettingsGroupArgs = {
  where: HoodSettingsGroupWhereUniqueInput;
};


export type MutationDeleteInterestGroupArgs = {
  where: QueryInterestGroupUniqueWhereInput;
};


export type MutationDeleteInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationDeleteLeaseContractArgs = {
  where: LeaseContractWhereUniqueInput;
};


export type MutationDeleteMemberArgs = {
  where: MemberWhereUniqueInput;
};


export type MutationDeleteMemberAddressArgs = {
  where: MemberAddressWhereUniqueInput;
};


export type MutationDeleteMemberPaymentOsCustomerArgs = {
  where: MemberPaymentOsCustomerWhereUniqueInput;
};


export type MutationDeleteMemberProfileArgs = {
  where: MemberProfileWhereUniqueInput;
};


export type MutationDeleteMemberProfileAddressFieldArgs = {
  where: MemberProfileAddressFieldWhereUniqueInput;
};


export type MutationDeleteMemberProfileBlockArgs = {
  where: MemberProfileBlockWhereUniqueInput;
};


export type MutationDeleteMemberProfileCallToActionFieldArgs = {
  where: MemberProfileCallToActionFieldWhereUniqueInput;
};


export type MutationDeleteMemberProfileDateFieldArgs = {
  where: MemberProfileDateFieldWhereUniqueInput;
};


export type MutationDeleteMemberProfilePetsFieldArgs = {
  where: MemberProfilePetsFieldWhereUniqueInput;
};


export type MutationDeleteMemberProfilePronounsFieldArgs = {
  where: MemberProfilePronounsFieldWhereUniqueInput;
};


export type MutationDeleteMemberProfileSocialLinksFieldArgs = {
  where: MemberProfileSocialLinksFieldWhereUniqueInput;
};


export type MutationDeleteMemberProfileSocialTagsFieldArgs = {
  where: MemberProfileSocialTagsFieldWhereUniqueInput;
};


export type MutationDeleteMemberProfileStringFieldArgs = {
  where: MemberProfileStringFieldWhereUniqueInput;
};


export type MutationDeleteMembershipContractArgs = {
  where: MembershipContractWhereUniqueInput;
};


export type MutationDeleteMobileAppPageArgs = {
  where: MobileAppPageWhereUniqueInput;
};


export type MutationDeletePaymentProviderArgs = {
  where: PaymentProviderWhereUniqueInput;
};


export type MutationDeletePaymentReceiptEmailArgs = {
  where: PaymentReceiptEmailWhereUniqueInput;
};


export type MutationDeletePaymentRequestArgs = {
  where: PaymentRequestWhereUniqueInput;
};


export type MutationDeletePaymentRequestEmailArgs = {
  where: PaymentRequestEmailWhereUniqueInput;
};


export type MutationDeletePaymentSettingArgs = {
  where: PaymentSettingWhereUniqueInput;
};


export type MutationDeletePaymentTransactionArgs = {
  where: PaymentTransactionWhereUniqueInput;
};


export type MutationDeletePayoutPlanArgs = {
  where: PayoutPlanWhereUniqueInput;
};


export type MutationDeletePetArgs = {
  where: PetWhereUniqueInput;
};


export type MutationDeletePlaceArgs = {
  where: PlaceWhereUniqueInput;
};


export type MutationDeletePortfolioArgs = {
  where: PortfolioWhereUniqueInput;
};


export type MutationDeleteProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationDeleteProductCategoryArgs = {
  where: ProductCategoryWhereUniqueInput;
};


export type MutationDeleteProductTypeArgs = {
  where: ProductTypeWhereUniqueInput;
};


export type MutationDeletePropertyManagementOfficeArgs = {
  where: PropertyManagementOfficeWhereUniqueInput;
};


export type MutationDeleteRoleArgs = {
  where: RoleWhereUniqueInput;
};


export type MutationDeleteServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type MutationDeleteServiceCategoryArgs = {
  where: ServiceCategoryWhereUniqueInput;
};


export type MutationDeleteServiceProductArgs = {
  where: ServiceProductWhereUniqueInput;
};


export type MutationDeleteServiceProductOrderArgs = {
  where: ServiceProductOrderWhereUniqueInput;
};


export type MutationDeleteSharedSpaceArgs = {
  where: SharedSpaceWhereUniqueInput;
};


export type MutationDeleteSocialInteractionArgs = {
  where: SocialInteractionWhereUniqueInput;
};


export type MutationDeleteSocialMomentArgs = {
  where: SocialMomentWhereUniqueInput;
};


export type MutationDeleteSocialMomentSentimentArgs = {
  where: SocialMomentSentimentWhereUniqueInput;
};


export type MutationDeleteSocialMomentViewArgs = {
  where: SocialMomentViewWhereUniqueInput;
};


export type MutationDeleteSocialReactionArgs = {
  where: SocialReactionWhereUniqueInput;
};


export type MutationDeleteSocialTagArgs = {
  where: SocialTagWhereUniqueInput;
};


export type MutationDeleteSubProductArgs = {
  where: SubProductWhereUniqueInput;
};


export type MutationDeleteSubscriptionContractArgs = {
  where: SubscriptionContractWhereUniqueInput;
};


export type MutationDeleteSurveyArgs = {
  where: SurveyWhereUniqueInput;
};


export type MutationDeleteSurveyQuestionArgs = {
  where: SurveyQuestionWhereUniqueInput;
};


export type MutationDeleteSurveyQuestionResponseArgs = {
  where: SurveyQuestionResponseWhereUniqueInput;
};


export type MutationDeleteSurveyResultArgs = {
  where: SurveyResultWhereUniqueInput;
};


export type MutationDeleteSurveyUserActionArgs = {
  where: SurveyUserActionWhereUniqueInput;
};


export type MutationDeleteTaxRateArgs = {
  where: TaxRateWhereUniqueInput;
};


export type MutationDeleteTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type MutationDeleteTreasureItemArgs = {
  where: TreasureItemWhereUniqueInput;
};


export type MutationDeleteUnitArgs = {
  where: UnitWhereUniqueInput;
};


export type MutationDeleteUnitStatusLogArgs = {
  where: UnitStatusLogWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteUserApiKeyArgs = {
  where: UserApiKeyWhereUniqueInput;
};


export type MutationDeleteUserPaymentMethodArgs = {
  where: UserPaymentMethodWhereUniqueInput;
};


export type MutationExtendContractArgs = {
  data: ExtendContractInput;
};


export type MutationExtractFeaturedSlidePriorityArgs = {
  data: ExtractFeaturedSlidePriorityInput;
};


export type MutationInitializeApplicationArgs = {
  data: ApplicationInput;
};


export type MutationInitializeContractsArgs = {
  data: ContractIdsInput;
};


export type MutationInsertFeaturedSlidePriorityArgs = {
  data: InsertFeaturedSlidePriorityInput;
};


export type MutationPayWithPayoutArgs = {
  data: PayWithPayoutInput;
};


export type MutationPurchaseImmediateServiceProductArgs = {
  data: PurchaseImmediateProductInput;
};


export type MutationPurchaseServiceProductArgs = {
  data: PurchaseProductInput;
};


export type MutationRequestCancelationServiceProductOrderArgs = {
  where: ServiceProductOrderWhereUniqueInput;
};


export type MutationRequestCancelationSubscriptionContractArgs = {
  where: SubscriptionContractWhereUniqueInput;
};


export type MutationRequestSubscriptionContractCancellationArgs = {
  where: SubscriptionContractWhereUniqueInput;
};


export type MutationRevertApplicationArgs = {
  data: ApplicationInput;
};


export type MutationRevertContractsArgs = {
  data: ContractIdsInput;
};


export type MutationSelfCreateApplicationArgs = {
  data?: InputMaybe<SelfCreateApplicationInput>;
};


export type MutationSubmitPollVoteArgs = {
  data: SubmitPollVoteInput;
  where: SocialMomentWhereUniqueInput;
};


export type MutationSubmitSurveyResponseArgs = {
  data: SubmitSurveyResponseInput;
};


export type MutationSubscribeServiceProductArgs = {
  data: SubscribeProductInput;
};


export type MutationSubscribeToProductArgs = {
  data: SubscribeToProductInput;
};


export type MutationTerminateContractArgs = {
  data: TerminateContractInput;
};


export type MutationTerminateMemberArgs = {
  data: TerminateMemberInput;
};


export type MutationToggleSocialReactionArgs = {
  data: ToggleSocialReactionInput;
};


export type MutationToggleVipPassArgs = {
  data?: InputMaybe<ToggleVipPassInput>;
};


export type MutationUnattendEventArgs = {
  data: CancelAttendanceEventInput;
};


export type MutationUnblockMemberProfileArgs = {
  data: UnblockMemberProfileInput;
};


export type MutationUpdateAddressArgs = {
  data: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};


export type MutationUpdateAlbertDogArgs = {
  data: AlbertDogUpdateInput;
  where: AlbertDogWhereUniqueInput;
};


export type MutationUpdateApplicationArgs = {
  data: ApplicationUpdateInput;
  where: ApplicationWhereUniqueInput;
};


export type MutationUpdateBedroomArgs = {
  data: BedroomUpdateInput;
  where: BedroomWhereUniqueInput;
};


export type MutationUpdateBillArgs = {
  data: BillUpdateInput;
  where: BillWhereUniqueInput;
};


export type MutationUpdateBillableItemArgs = {
  data: BillableItemUpdateInput;
  where: BillableItemWhereUniqueInput;
};


export type MutationUpdateBuildingArgs = {
  data: BuildingUpdateInput;
  where: BuildingWhereUniqueInput;
};


export type MutationUpdateBuildingSettingArgs = {
  data: BuildingSettingUpdateInput;
  where: BuildingSettingWhereUniqueInput;
};


export type MutationUpdateCalendarArgs = {
  data: CalendarUpdateInput;
  where: CalendarWhereUniqueInput;
};


export type MutationUpdateCalendarBookingArgs = {
  data: CalendarBookingUpdateInput;
  where: CalendarBookingWhereUniqueInput;
};


export type MutationUpdateCommentArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpdateCommunityTitleArgs = {
  data: CommunityTitleUpdateInput;
  where: CommunityTitleWhereUniqueInput;
};


export type MutationUpdateCurrencyArgs = {
  data: CurrencyUpdateInput;
  where: CurrencyWhereUniqueInput;
};


export type MutationUpdateDeliveryPackageArgs = {
  data: DeliveryPackageUpdateInput;
  where: DeliveryPackageWhereUniqueInput;
};


export type MutationUpdateDiscussionArgs = {
  momentData: SocialMomentUpdateInput;
  pollData?: InputMaybe<PollUpdateInput>;
  where: SocialMomentWhereUniqueInput;
};


export type MutationUpdateEventArgs = {
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
};


export type MutationUpdateEventAttendeeArgs = {
  data: EventAttendeeUpdateInput;
  where: EventAttendeeWhereUniqueInput;
};


export type MutationUpdateExternalBillArgs = {
  data: ExternalBillUpdateInput;
  where: ExternalBillWhereUniqueInput;
};


export type MutationUpdateExternalBillReceiptArgs = {
  data: ExternalBillReceiptUpdateInput;
  where: ExternalBillReceiptWhereUniqueInput;
};


export type MutationUpdateExternalReceiptArgs = {
  data: ExternalReceiptUpdateInput;
  where: ExternalReceiptWhereUniqueInput;
};


export type MutationUpdateFeatureArgs = {
  data: FeatureUpdateInput;
  where: FeatureWhereUniqueInput;
};


export type MutationUpdateFeatureAnnouncementArgs = {
  data: FeatureAnnouncementUpdateInput;
  where: FeatureAnnouncementWhereUniqueInput;
};


export type MutationUpdateFeaturedSlideArgs = {
  data: FeaturedSlideUpdateInput;
  where: FeaturedSlideWhereUniqueInput;
};


export type MutationUpdateFeedClassificationArgs = {
  data: FeedClassificationUpdateInput;
  where: FeedClassificationWhereUniqueInput;
};


export type MutationUpdateHoodArgs = {
  data: HoodUpdateInput;
  where: HoodWhereUniqueInput;
};


export type MutationUpdateHoodSettingArgs = {
  data: HoodSettingUpdateInput;
  where: HoodSettingWhereUniqueInput;
};


export type MutationUpdateHoodSettingValueArgs = {
  data: HoodSettingValueUpdateInput;
  where: HoodSettingValueWhereUniqueInput;
};


export type MutationUpdateHoodSettingsGroupArgs = {
  data: HoodSettingsGroupUpdateInput;
  where: HoodSettingsGroupWhereUniqueInput;
};


export type MutationUpdateInterestGroupArgs = {
  data: UpdateInterestGroupInput;
  where: QueryInterestGroupUniqueWhereInput;
};


export type MutationUpdateInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationUpdateLeaseContractArgs = {
  data: LeaseContractUpdateInput;
  where: LeaseContractWhereUniqueInput;
};


export type MutationUpdateManyBillableItemsArgs = {
  data: BillableItemUpdateManyMutationInput;
  where?: InputMaybe<BillableItemWhereInput>;
};


export type MutationUpdateManyBillsArgs = {
  data: BillUpdateManyMutationInput;
  where?: InputMaybe<BillWhereInput>;
};


export type MutationUpdateManyLeaseContractsArgs = {
  data: LeaseContractUpdateManyMutationInput;
  where?: InputMaybe<LeaseContractWhereInput>;
};


export type MutationUpdateManyMembersArgs = {
  data: MemberUpdateManyMutationInput;
  where?: InputMaybe<MemberWhereInput>;
};


export type MutationUpdateManyPaymentTransactionsArgs = {
  data: PaymentTransactionUpdateManyMutationInput;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};


export type MutationUpdateManySocialInteractionsArgs = {
  data: SocialInteractionUpdateManyMutationInput;
  where?: InputMaybe<SocialInteractionWhereInput>;
};


export type MutationUpdateManyTransactionsArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: InputMaybe<TransactionWhereInput>;
};


export type MutationUpdateManyUsersArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateMemberArgs = {
  data: MemberUpdateInput;
  where: MemberWhereUniqueInput;
};


export type MutationUpdateMemberAddressArgs = {
  data: MemberAddressUpdateInput;
  where: MemberAddressWhereUniqueInput;
};


export type MutationUpdateMemberPaymentOsCustomerArgs = {
  data: MemberPaymentOsCustomerUpdateInput;
  where: MemberPaymentOsCustomerWhereUniqueInput;
};


export type MutationUpdateMemberProfileArgs = {
  data: MemberProfileUpdateInput;
  where: MemberProfileWhereUniqueInput;
};


export type MutationUpdateMemberProfileAddressFieldArgs = {
  data: MemberProfileAddressFieldUpdateInput;
  where: MemberProfileAddressFieldWhereUniqueInput;
};


export type MutationUpdateMemberProfileBlockArgs = {
  data: MemberProfileBlockUpdateInput;
  where: MemberProfileBlockWhereUniqueInput;
};


export type MutationUpdateMemberProfileCallToActionFieldArgs = {
  data: MemberProfileCallToActionFieldUpdateInput;
  where: MemberProfileCallToActionFieldWhereUniqueInput;
};


export type MutationUpdateMemberProfileDateFieldArgs = {
  data: MemberProfileDateFieldUpdateInput;
  where: MemberProfileDateFieldWhereUniqueInput;
};


export type MutationUpdateMemberProfilePetsFieldArgs = {
  data: MemberProfilePetsFieldUpdateInput;
  where: MemberProfilePetsFieldWhereUniqueInput;
};


export type MutationUpdateMemberProfilePronounsFieldArgs = {
  data: MemberProfilePronounsFieldUpdateInput;
  where: MemberProfilePronounsFieldWhereUniqueInput;
};


export type MutationUpdateMemberProfileSocialLinksFieldArgs = {
  data: MemberProfileSocialLinksFieldUpdateInput;
  where: MemberProfileSocialLinksFieldWhereUniqueInput;
};


export type MutationUpdateMemberProfileSocialTagsFieldArgs = {
  data: MemberProfileSocialTagsFieldUpdateInput;
  where: MemberProfileSocialTagsFieldWhereUniqueInput;
};


export type MutationUpdateMemberProfileStringFieldArgs = {
  data: MemberProfileStringFieldUpdateInput;
  where: MemberProfileStringFieldWhereUniqueInput;
};


export type MutationUpdateMembershipContractArgs = {
  data: MembershipContractUpdateInput;
  where: MembershipContractWhereUniqueInput;
};


export type MutationUpdateMobileAppPageArgs = {
  data: MobileAppPageUpdateInput;
  where: MobileAppPageWhereUniqueInput;
};


export type MutationUpdatePaymentProviderArgs = {
  data: PaymentProviderUpdateInput;
  where: PaymentProviderWhereUniqueInput;
};


export type MutationUpdatePaymentReceiptEmailArgs = {
  data: PaymentReceiptEmailUpdateInput;
  where: PaymentReceiptEmailWhereUniqueInput;
};


export type MutationUpdatePaymentRequestArgs = {
  data: PaymentRequestUpdateInput;
  where: PaymentRequestWhereUniqueInput;
};


export type MutationUpdatePaymentRequestEmailArgs = {
  data: PaymentRequestEmailUpdateInput;
  where: PaymentRequestEmailWhereUniqueInput;
};


export type MutationUpdatePaymentSettingArgs = {
  data: PaymentSettingUpdateInput;
  where: PaymentSettingWhereUniqueInput;
};


export type MutationUpdatePaymentTransactionArgs = {
  data: PaymentTransactionUpdateInput;
  where: PaymentTransactionWhereUniqueInput;
};


export type MutationUpdatePayoutPlanArgs = {
  data: PayoutPlanUpdateInput;
  where: PayoutPlanWhereUniqueInput;
};


export type MutationUpdatePetArgs = {
  data: PetUpdateInput;
  where: PetWhereUniqueInput;
};


export type MutationUpdatePlaceArgs = {
  data: PlaceUpdateInput;
  where: PlaceWhereUniqueInput;
};


export type MutationUpdatePortfolioArgs = {
  data: PortfolioUpdateInput;
  where: PortfolioWhereUniqueInput;
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateProductCategoryArgs = {
  data: ProductCategoryUpdateInput;
  where: ProductCategoryWhereUniqueInput;
};


export type MutationUpdateProductTypeArgs = {
  data: ProductTypeUpdateInput;
  where: ProductTypeWhereUniqueInput;
};


export type MutationUpdatePropertyManagementOfficeArgs = {
  data: PropertyManagementOfficeUpdateInput;
  where: PropertyManagementOfficeWhereUniqueInput;
};


export type MutationUpdateRoleArgs = {
  data: RoleUpdateInput;
  where: RoleWhereUniqueInput;
};


export type MutationUpdateServiceArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};


export type MutationUpdateServiceCategoryArgs = {
  data: ServiceCategoryUpdateInput;
  where: ServiceCategoryWhereUniqueInput;
};


export type MutationUpdateServiceProductArgs = {
  data: ServiceProductUpdateInput;
  where: ServiceProductWhereUniqueInput;
};


export type MutationUpdateServiceProductOrderArgs = {
  data: ServiceProductOrderUpdateInput;
  where: ServiceProductOrderWhereUniqueInput;
};


export type MutationUpdateSharedSpaceArgs = {
  data: SharedSpaceUpdateInput;
  where: SharedSpaceWhereUniqueInput;
};


export type MutationUpdateSocialInteractionArgs = {
  data: SocialInteractionUpdateInput;
  where: SocialInteractionWhereUniqueInput;
};


export type MutationUpdateSocialMomentArgs = {
  data: SocialMomentUpdateInput;
  where: SocialMomentWhereUniqueInput;
};


export type MutationUpdateSocialMomentSentimentArgs = {
  data: SocialMomentSentimentUpdateInput;
  where: SocialMomentSentimentWhereUniqueInput;
};


export type MutationUpdateSocialMomentViewArgs = {
  data: SocialMomentViewUpdateInput;
  where: SocialMomentViewWhereUniqueInput;
};


export type MutationUpdateSocialReactionArgs = {
  data: SocialReactionUpdateInput;
  where: SocialReactionWhereUniqueInput;
};


export type MutationUpdateSocialTagArgs = {
  data: SocialTagUpdateInput;
  where: SocialTagWhereUniqueInput;
};


export type MutationUpdateSocialTagsForMemberProfileArgs = {
  data: SocialTagsForMemberProfileUpdateInput;
  where: MemberProfileWhereUniqueInput;
};


export type MutationUpdateSubProductArgs = {
  data: SubProductUpdateInput;
  where: SubProductWhereUniqueInput;
};


export type MutationUpdateSubscriptionContractArgs = {
  data: SubscriptionContractUpdateInput;
  where: SubscriptionContractWhereUniqueInput;
};


export type MutationUpdateSurveyArgs = {
  data: SurveyUpdateInput;
  where: SurveyWhereUniqueInput;
};


export type MutationUpdateSurveyQuestionArgs = {
  data: SurveyQuestionUpdateInput;
  where: SurveyQuestionWhereUniqueInput;
};


export type MutationUpdateSurveyQuestionResponseArgs = {
  data: SurveyQuestionResponseUpdateInput;
  where: SurveyQuestionResponseWhereUniqueInput;
};


export type MutationUpdateSurveyResultArgs = {
  data: SurveyResultUpdateInput;
  where: SurveyResultWhereUniqueInput;
};


export type MutationUpdateSurveyUserActionArgs = {
  data: SurveyUserActionUpdateInput;
  where: SurveyUserActionWhereUniqueInput;
};


export type MutationUpdateTaxRateArgs = {
  data: TaxRateUpdateInput;
  where: TaxRateWhereUniqueInput;
};


export type MutationUpdateTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationUpdateTreasureItemArgs = {
  data: TreasureItemUpdateInput;
  where: TreasureItemWhereUniqueInput;
};


export type MutationUpdateUnitArgs = {
  data: UnitUpdateInput;
  where: UnitWhereUniqueInput;
};


export type MutationUpdateUnitStatusLogArgs = {
  data: UnitStatusLogUpdateInput;
  where: UnitStatusLogWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateUserApiKeyArgs = {
  data: UserApiKeyUpdateInput;
  where: UserApiKeyWhereUniqueInput;
};


export type MutationUpdateUserPaymentMethodArgs = {
  data: UserPaymentMethodUpdateInput;
  where: UserPaymentMethodWhereUniqueInput;
};


export type MutationUpsertQuestionResponseArgs = {
  data?: InputMaybe<SurveyQuestionResponseCreateInput>;
};


export type MutationUserViewedMomentArgs = {
  data: UserViewedMomentInput;
};

export enum MutationType {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED'
}

export type MyOrdersResult = ServiceProductOrder | SubscriptionContract;

export enum MyOrdersStatus {
  ALL = 'ALL',
  CANCELED = 'CANCELED',
  PAST = 'PAST',
  UPCOMING = 'UPCOMING'
}

export type MyOrdersWhereInput = {
  status: MyOrdersStatus;
};

export enum NearByEntityType {
  BEDROOM = 'BEDROOM',
  BUILDING = 'BUILDING',
  UNIT = 'UNIT'
}

export type NearByInput = {
  distanceInMeters: Scalars['Int'];
  entityType: NearByEntityType;
  id: Scalars['ID'];
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  notifyVennerOnContractAboutToEnd?: Maybe<VennerNotificationConfiguration>;
  notifyVennerOnContractAboutToStart?: Maybe<VennerNotificationConfiguration>;
  notifyVennerOnContractEnded?: Maybe<VennerNotificationConfiguration>;
  notifyVennerOnContractStarted?: Maybe<VennerNotificationConfiguration>;
};

export type NotificationWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<NotificationWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<NotificationWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<NotificationWhereContractsPersistencyInput>>;
  attempts?: InputMaybe<Scalars['Int']>;
  attempts_gt?: InputMaybe<Scalars['Int']>;
  attempts_gte?: InputMaybe<Scalars['Int']>;
  attempts_in?: InputMaybe<Array<Scalars['Int']>>;
  attempts_lt?: InputMaybe<Scalars['Int']>;
  attempts_lte?: InputMaybe<Scalars['Int']>;
  attempts_not?: InputMaybe<Scalars['Int']>;
  attempts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  destinationType?: InputMaybe<ContractsPersistencyNotificationDestinationType>;
  destinationType_in?: InputMaybe<Array<ContractsPersistencyNotificationDestinationType>>;
  destinationType_not?: InputMaybe<ContractsPersistencyNotificationDestinationType>;
  destinationType_not_in?: InputMaybe<Array<ContractsPersistencyNotificationDestinationType>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  message_contains?: InputMaybe<Scalars['String']>;
  message_ends_with?: InputMaybe<Scalars['String']>;
  message_gt?: InputMaybe<Scalars['String']>;
  message_gte?: InputMaybe<Scalars['String']>;
  message_in?: InputMaybe<Array<Scalars['String']>>;
  message_lt?: InputMaybe<Scalars['String']>;
  message_lte?: InputMaybe<Scalars['String']>;
  message_not?: InputMaybe<Scalars['String']>;
  message_not_contains?: InputMaybe<Scalars['String']>;
  message_not_ends_with?: InputMaybe<Scalars['String']>;
  message_not_in?: InputMaybe<Array<Scalars['String']>>;
  message_not_starts_with?: InputMaybe<Scalars['String']>;
  message_starts_with?: InputMaybe<Scalars['String']>;
  notificationType?: InputMaybe<ContractsPersistencyNotificationType>;
  notificationType_in?: InputMaybe<Array<ContractsPersistencyNotificationType>>;
  notificationType_not?: InputMaybe<ContractsPersistencyNotificationType>;
  notificationType_not_in?: InputMaybe<Array<ContractsPersistencyNotificationType>>;
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_gt?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_gte?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  scheduledDate_lt?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_lte?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_not?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  sourceType?: InputMaybe<ContractsPersistencyNotificationSourceType>;
  sourceType_in?: InputMaybe<Array<ContractsPersistencyNotificationSourceType>>;
  sourceType_not?: InputMaybe<ContractsPersistencyNotificationSourceType>;
  sourceType_not_in?: InputMaybe<Array<ContractsPersistencyNotificationSourceType>>;
  status?: InputMaybe<ContractsPersistencyNotificationStatus>;
  status_in?: InputMaybe<Array<ContractsPersistencyNotificationStatus>>;
  status_not?: InputMaybe<ContractsPersistencyNotificationStatus>;
  status_not_in?: InputMaybe<Array<ContractsPersistencyNotificationStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NotificationWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<NotificationWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<NotificationWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<NotificationWherePersistencyContractsInput>>;
  attempts?: InputMaybe<Scalars['Int']>;
  attempts_gt?: InputMaybe<Scalars['Int']>;
  attempts_gte?: InputMaybe<Scalars['Int']>;
  attempts_in?: InputMaybe<Array<Scalars['Int']>>;
  attempts_lt?: InputMaybe<Scalars['Int']>;
  attempts_lte?: InputMaybe<Scalars['Int']>;
  attempts_not?: InputMaybe<Scalars['Int']>;
  attempts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  destinationType?: InputMaybe<PersistencyContractsNotificationDestinationType>;
  destinationType_in?: InputMaybe<Array<PersistencyContractsNotificationDestinationType>>;
  destinationType_not?: InputMaybe<PersistencyContractsNotificationDestinationType>;
  destinationType_not_in?: InputMaybe<Array<PersistencyContractsNotificationDestinationType>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  message_contains?: InputMaybe<Scalars['String']>;
  message_ends_with?: InputMaybe<Scalars['String']>;
  message_gt?: InputMaybe<Scalars['String']>;
  message_gte?: InputMaybe<Scalars['String']>;
  message_in?: InputMaybe<Array<Scalars['String']>>;
  message_lt?: InputMaybe<Scalars['String']>;
  message_lte?: InputMaybe<Scalars['String']>;
  message_not?: InputMaybe<Scalars['String']>;
  message_not_contains?: InputMaybe<Scalars['String']>;
  message_not_ends_with?: InputMaybe<Scalars['String']>;
  message_not_in?: InputMaybe<Array<Scalars['String']>>;
  message_not_starts_with?: InputMaybe<Scalars['String']>;
  message_starts_with?: InputMaybe<Scalars['String']>;
  notificationType?: InputMaybe<PersistencyContractsNotificationType>;
  notificationType_in?: InputMaybe<Array<PersistencyContractsNotificationType>>;
  notificationType_not?: InputMaybe<PersistencyContractsNotificationType>;
  notificationType_not_in?: InputMaybe<Array<PersistencyContractsNotificationType>>;
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_gt?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_gte?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  scheduledDate_lt?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_lte?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_not?: InputMaybe<Scalars['DateTime']>;
  scheduledDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  sourceType?: InputMaybe<PersistencyContractsNotificationSourceType>;
  sourceType_in?: InputMaybe<Array<PersistencyContractsNotificationSourceType>>;
  sourceType_not?: InputMaybe<PersistencyContractsNotificationSourceType>;
  sourceType_not_in?: InputMaybe<Array<PersistencyContractsNotificationSourceType>>;
  status?: InputMaybe<PersistencyContractsNotificationStatus>;
  status_in?: InputMaybe<Array<PersistencyContractsNotificationStatus>>;
  status_not?: InputMaybe<PersistencyContractsNotificationStatus>;
  status_not_in?: InputMaybe<Array<PersistencyContractsNotificationStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PayWithPayoutInput = {
  hoodName: Scalars['String'];
  metadata: PayWithPayoutMetaData;
  paymentMethodId: Scalars['String'];
  totalAmount: Scalars['Float'];
  totalTransactionFeeAmount: Scalars['Float'];
  userEmail: Scalars['String'];
  userId: Scalars['String'];
};

export type PayWithPayoutMetaData = {
  billIdsToAmount: Array<BillIdToAmount>;
};

export type PayWithPayoutResponse = {
  __typename?: 'PayWithPayoutResponse';
  billIdsToNewAmountDue: Array<BillIdToAmountDue>;
  paymentId: Scalars['String'];
  status: PaymentTransactionStatus;
};

export enum PaymentIntentStatus {
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CAPTURE = 'requires_capture',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCEEDED = 'succeeded'
}

export enum PaymentMethodType {
  ACH = 'ACH',
  APPLE_PAY = 'APPLE_PAY',
  CREDIT_CARD = 'CREDIT_CARD',
  GOOGLE_PAY = 'GOOGLE_PAY',
  MANUAL_ACH = 'MANUAL_ACH',
  MANUAL_BANK = 'MANUAL_BANK',
  SEPA = 'SEPA'
}

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentProviderConnection = {
  __typename?: 'PaymentProviderConnection';
  aggregate: AggregatePaymentProvider;
  edges: Array<Maybe<PaymentProviderEdge>>;
  pageInfo: PageInfo;
};

export type PaymentProviderCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type PaymentProviderCreateOneInput = {
  connect?: InputMaybe<PaymentProviderWhereUniqueInput>;
  create?: InputMaybe<PaymentProviderCreateInput>;
};

export type PaymentProviderEdge = {
  __typename?: 'PaymentProviderEdge';
  cursor: Scalars['String'];
  node: PaymentProvider;
};

export enum PaymentProviderForTax {
  MANUAL = 'MANUAL',
  SAP = 'SAP',
  STRIPE = 'STRIPE',
  ZOOZ = 'ZOOZ'
}

export enum PaymentProviderOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type PaymentProviderUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PaymentProviderUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PaymentProviderUpdateOneRequiredInput = {
  connect?: InputMaybe<PaymentProviderWhereUniqueInput>;
  create?: InputMaybe<PaymentProviderCreateInput>;
  update?: InputMaybe<PaymentProviderUpdateDataInput>;
  upsert?: InputMaybe<PaymentProviderUpsertNestedInput>;
};

export type PaymentProviderUpsertNestedInput = {
  create: PaymentProviderCreateInput;
  update: PaymentProviderUpdateDataInput;
};

export type PaymentProviderWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<PaymentProviderWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<PaymentProviderWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<PaymentProviderWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentProviderWhereInput = {
  AND?: InputMaybe<Array<PaymentProviderWhereInput>>;
  NOT?: InputMaybe<Array<PaymentProviderWhereInput>>;
  OR?: InputMaybe<Array<PaymentProviderWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentProviderWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<PaymentProviderWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<PaymentProviderWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<PaymentProviderWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentProviderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PaymentReceiptEmail = {
  __typename?: 'PaymentReceiptEmail';
  _empty?: Maybe<Scalars['String']>;
  bill: Bill;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  member: Member;
  paymentTransaction: PaymentTransaction;
  status: PaymentRequestEmailStatus;
  updatedAt: Scalars['DateTime'];
};

export type PaymentReceiptEmailConnection = {
  __typename?: 'PaymentReceiptEmailConnection';
  aggregate: AggregatePaymentReceiptEmail;
  edges: Array<Maybe<PaymentReceiptEmailEdge>>;
  pageInfo: PageInfo;
};

export type PaymentReceiptEmailCreateInput = {
  bill: BillCreateOneInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  member: MemberCreateOneInput;
  paymentTransaction: PaymentTransactionCreateOneWithoutPaymentReceiptEmailInput;
  status: PaymentRequestEmailStatus;
};

export type PaymentReceiptEmailCreateOneWithoutPaymentTransactionInput = {
  connect?: InputMaybe<PaymentReceiptEmailWhereUniqueInput>;
  create?: InputMaybe<PaymentReceiptEmailCreateWithoutPaymentTransactionInput>;
};

export type PaymentReceiptEmailCreateWithoutPaymentTransactionInput = {
  bill: BillCreateOneInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  member: MemberCreateOneInput;
  status: PaymentRequestEmailStatus;
};

export type PaymentReceiptEmailEdge = {
  __typename?: 'PaymentReceiptEmailEdge';
  cursor: Scalars['String'];
  node: PaymentReceiptEmail;
};

export enum PaymentReceiptEmailOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DETAILS_ASC = 'details_ASC',
  DETAILS_DESC = 'details_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type PaymentReceiptEmailUpdateInput = {
  bill?: InputMaybe<BillUpdateOneRequiredInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  member?: InputMaybe<MemberUpdateOneRequiredInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneRequiredWithoutPaymentReceiptEmailInput>;
  status?: InputMaybe<PaymentRequestEmailStatus>;
};

export type PaymentReceiptEmailUpdateOneWithoutPaymentTransactionInput = {
  connect?: InputMaybe<PaymentReceiptEmailWhereUniqueInput>;
  create?: InputMaybe<PaymentReceiptEmailCreateWithoutPaymentTransactionInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PaymentReceiptEmailUpdateWithoutPaymentTransactionDataInput>;
  upsert?: InputMaybe<PaymentReceiptEmailUpsertWithoutPaymentTransactionInput>;
};

export type PaymentReceiptEmailUpdateWithoutPaymentTransactionDataInput = {
  bill?: InputMaybe<BillUpdateOneRequiredInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  member?: InputMaybe<MemberUpdateOneRequiredInput>;
  status?: InputMaybe<PaymentRequestEmailStatus>;
};

export type PaymentReceiptEmailUpsertWithoutPaymentTransactionInput = {
  create: PaymentReceiptEmailCreateWithoutPaymentTransactionInput;
  update: PaymentReceiptEmailUpdateWithoutPaymentTransactionDataInput;
};

export type PaymentReceiptEmailWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<PaymentReceiptEmailWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<PaymentReceiptEmailWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<PaymentReceiptEmailWhereContractsPersistencyInput>>;
  bill?: InputMaybe<BillWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  details?: InputMaybe<Scalars['String']>;
  details_contains?: InputMaybe<Scalars['String']>;
  details_ends_with?: InputMaybe<Scalars['String']>;
  details_gt?: InputMaybe<Scalars['String']>;
  details_gte?: InputMaybe<Scalars['String']>;
  details_in?: InputMaybe<Array<Scalars['String']>>;
  details_lt?: InputMaybe<Scalars['String']>;
  details_lte?: InputMaybe<Scalars['String']>;
  details_not?: InputMaybe<Scalars['String']>;
  details_not_contains?: InputMaybe<Scalars['String']>;
  details_not_ends_with?: InputMaybe<Scalars['String']>;
  details_not_in?: InputMaybe<Array<Scalars['String']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']>;
  details_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  member?: InputMaybe<MemberWhereContractsPersistencyInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionWhereContractsPersistencyInput>;
  status?: InputMaybe<ContractsPersistencyPaymentRequestEmailStatus>;
  status_in?: InputMaybe<Array<ContractsPersistencyPaymentRequestEmailStatus>>;
  status_not?: InputMaybe<ContractsPersistencyPaymentRequestEmailStatus>;
  status_not_in?: InputMaybe<Array<ContractsPersistencyPaymentRequestEmailStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentReceiptEmailWhereInput = {
  AND?: InputMaybe<Array<PaymentReceiptEmailWhereInput>>;
  NOT?: InputMaybe<Array<PaymentReceiptEmailWhereInput>>;
  OR?: InputMaybe<Array<PaymentReceiptEmailWhereInput>>;
  bill?: InputMaybe<BillWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  details?: InputMaybe<Scalars['String']>;
  details_contains?: InputMaybe<Scalars['String']>;
  details_ends_with?: InputMaybe<Scalars['String']>;
  details_gt?: InputMaybe<Scalars['String']>;
  details_gte?: InputMaybe<Scalars['String']>;
  details_in?: InputMaybe<Array<Scalars['String']>>;
  details_lt?: InputMaybe<Scalars['String']>;
  details_lte?: InputMaybe<Scalars['String']>;
  details_not?: InputMaybe<Scalars['String']>;
  details_not_contains?: InputMaybe<Scalars['String']>;
  details_not_ends_with?: InputMaybe<Scalars['String']>;
  details_not_in?: InputMaybe<Array<Scalars['String']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']>;
  details_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  member?: InputMaybe<MemberWhereInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionWhereInput>;
  status?: InputMaybe<PaymentRequestEmailStatus>;
  status_in?: InputMaybe<Array<PaymentRequestEmailStatus>>;
  status_not?: InputMaybe<PaymentRequestEmailStatus>;
  status_not_in?: InputMaybe<Array<PaymentRequestEmailStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentReceiptEmailWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<PaymentReceiptEmailWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<PaymentReceiptEmailWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<PaymentReceiptEmailWherePersistencyContractsInput>>;
  bill?: InputMaybe<BillWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  details?: InputMaybe<Scalars['String']>;
  details_contains?: InputMaybe<Scalars['String']>;
  details_ends_with?: InputMaybe<Scalars['String']>;
  details_gt?: InputMaybe<Scalars['String']>;
  details_gte?: InputMaybe<Scalars['String']>;
  details_in?: InputMaybe<Array<Scalars['String']>>;
  details_lt?: InputMaybe<Scalars['String']>;
  details_lte?: InputMaybe<Scalars['String']>;
  details_not?: InputMaybe<Scalars['String']>;
  details_not_contains?: InputMaybe<Scalars['String']>;
  details_not_ends_with?: InputMaybe<Scalars['String']>;
  details_not_in?: InputMaybe<Array<Scalars['String']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']>;
  details_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  member?: InputMaybe<MemberWherePersistencyContractsInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionWherePersistencyContractsInput>;
  status?: InputMaybe<PersistencyContractsPaymentRequestEmailStatus>;
  status_in?: InputMaybe<Array<PersistencyContractsPaymentRequestEmailStatus>>;
  status_not?: InputMaybe<PersistencyContractsPaymentRequestEmailStatus>;
  status_not_in?: InputMaybe<Array<PersistencyContractsPaymentRequestEmailStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentReceiptEmailWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  _empty?: Maybe<Scalars['String']>;
  allowedPaymentMethods: Scalars['String'];
  amount: Scalars['Float'];
  authorizeOnly: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  manualPaymentDescription?: Maybe<Scalars['String']>;
  manualPaymentType?: Maybe<ManualPaymentType>;
  paymentIdInPaymentProvider?: Maybe<Scalars['String']>;
  paymentProvider: PaymentProvider;
  status?: Maybe<PaymentRequestStatus>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type PaymentRequestConnection = {
  __typename?: 'PaymentRequestConnection';
  aggregate: AggregatePaymentRequest;
  edges: Array<Maybe<PaymentRequestEdge>>;
  pageInfo: PageInfo;
};

export type PaymentRequestCreateInput = {
  allowedPaymentMethods: Scalars['String'];
  amount: Scalars['Float'];
  authorizeOnly?: InputMaybe<Scalars['Boolean']>;
  currency: CurrencyCreateOneInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  paymentIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentProvider: PaymentProviderCreateOneInput;
  status?: InputMaybe<PaymentRequestStatus>;
  user: UserCreateOneInput;
};

export type PaymentRequestEdge = {
  __typename?: 'PaymentRequestEdge';
  cursor: Scalars['String'];
  node: PaymentRequest;
};

export type PaymentRequestEmail = {
  __typename?: 'PaymentRequestEmail';
  _empty?: Maybe<Scalars['String']>;
  bills?: Maybe<Array<Bill>>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  member: Member;
  paymentType: PaymentType;
  status: PaymentRequestEmailStatus;
  templateName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type PaymentRequestEmailBillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BillOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BillWhereInput>;
};

export type PaymentRequestEmailConnection = {
  __typename?: 'PaymentRequestEmailConnection';
  aggregate: AggregatePaymentRequestEmail;
  edges: Array<Maybe<PaymentRequestEmailEdge>>;
  pageInfo: PageInfo;
};

export type PaymentRequestEmailCreateInput = {
  bills?: InputMaybe<BillCreateManyWithoutPaymentRequestEmailsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  member: MemberCreateOneInput;
  paymentType: PaymentType;
  status: PaymentRequestEmailStatus;
  templateName?: InputMaybe<Scalars['String']>;
};

export type PaymentRequestEmailCreateManyWithoutBillsInput = {
  connect?: InputMaybe<Array<PaymentRequestEmailWhereUniqueInput>>;
  create?: InputMaybe<Array<PaymentRequestEmailCreateWithoutBillsInput>>;
};

export type PaymentRequestEmailCreateWithoutBillsInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  member: MemberCreateOneInput;
  paymentType: PaymentType;
  status: PaymentRequestEmailStatus;
  templateName?: InputMaybe<Scalars['String']>;
};

export type PaymentRequestEmailEdge = {
  __typename?: 'PaymentRequestEmailEdge';
  cursor: Scalars['String'];
  node: PaymentRequestEmail;
};

export enum PaymentRequestEmailOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DETAILS_ASC = 'details_ASC',
  DETAILS_DESC = 'details_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PAYMENTTYPE_ASC = 'paymentType_ASC',
  PAYMENTTYPE_DESC = 'paymentType_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  TEMPLATENAME_ASC = 'templateName_ASC',
  TEMPLATENAME_DESC = 'templateName_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type PaymentRequestEmailScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentRequestEmailScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentRequestEmailScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentRequestEmailScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  details?: InputMaybe<Scalars['String']>;
  details_contains?: InputMaybe<Scalars['String']>;
  details_ends_with?: InputMaybe<Scalars['String']>;
  details_gt?: InputMaybe<Scalars['String']>;
  details_gte?: InputMaybe<Scalars['String']>;
  details_in?: InputMaybe<Array<Scalars['String']>>;
  details_lt?: InputMaybe<Scalars['String']>;
  details_lte?: InputMaybe<Scalars['String']>;
  details_not?: InputMaybe<Scalars['String']>;
  details_not_contains?: InputMaybe<Scalars['String']>;
  details_not_ends_with?: InputMaybe<Scalars['String']>;
  details_not_in?: InputMaybe<Array<Scalars['String']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']>;
  details_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paymentType?: InputMaybe<PaymentType>;
  paymentType_in?: InputMaybe<Array<PaymentType>>;
  paymentType_not?: InputMaybe<PaymentType>;
  paymentType_not_in?: InputMaybe<Array<PaymentType>>;
  status?: InputMaybe<PaymentRequestEmailStatus>;
  status_in?: InputMaybe<Array<PaymentRequestEmailStatus>>;
  status_not?: InputMaybe<PaymentRequestEmailStatus>;
  status_not_in?: InputMaybe<Array<PaymentRequestEmailStatus>>;
  templateName?: InputMaybe<Scalars['String']>;
  templateName_contains?: InputMaybe<Scalars['String']>;
  templateName_ends_with?: InputMaybe<Scalars['String']>;
  templateName_gt?: InputMaybe<Scalars['String']>;
  templateName_gte?: InputMaybe<Scalars['String']>;
  templateName_in?: InputMaybe<Array<Scalars['String']>>;
  templateName_lt?: InputMaybe<Scalars['String']>;
  templateName_lte?: InputMaybe<Scalars['String']>;
  templateName_not?: InputMaybe<Scalars['String']>;
  templateName_not_contains?: InputMaybe<Scalars['String']>;
  templateName_not_ends_with?: InputMaybe<Scalars['String']>;
  templateName_not_in?: InputMaybe<Array<Scalars['String']>>;
  templateName_not_starts_with?: InputMaybe<Scalars['String']>;
  templateName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum PaymentRequestEmailStatus {
  INITIALIZED = 'INITIALIZED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  MEMBER_DATA_ERROR = 'MEMBER_DATA_ERROR',
  RECEIVED = 'RECEIVED',
  SENDING_FAILURE = 'SENDING_FAILURE',
  SENT = 'SENT'
}

export type PaymentRequestEmailUpdateInput = {
  bills?: InputMaybe<BillUpdateManyWithoutPaymentRequestEmailsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  member?: InputMaybe<MemberUpdateOneRequiredInput>;
  paymentType?: InputMaybe<PaymentType>;
  status?: InputMaybe<PaymentRequestEmailStatus>;
  templateName?: InputMaybe<Scalars['String']>;
};

export type PaymentRequestEmailUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  status?: InputMaybe<PaymentRequestEmailStatus>;
  templateName?: InputMaybe<Scalars['String']>;
};

export type PaymentRequestEmailUpdateManyWithWhereNestedInput = {
  data: PaymentRequestEmailUpdateManyDataInput;
  where: PaymentRequestEmailScalarWhereInput;
};

export type PaymentRequestEmailUpdateManyWithoutBillsInput = {
  connect?: InputMaybe<Array<PaymentRequestEmailWhereUniqueInput>>;
  create?: InputMaybe<Array<PaymentRequestEmailCreateWithoutBillsInput>>;
  delete?: InputMaybe<Array<PaymentRequestEmailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentRequestEmailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentRequestEmailWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentRequestEmailWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentRequestEmailUpdateWithWhereUniqueWithoutBillsInput>>;
  updateMany?: InputMaybe<Array<PaymentRequestEmailUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<PaymentRequestEmailUpsertWithWhereUniqueWithoutBillsInput>>;
};

export type PaymentRequestEmailUpdateWithWhereUniqueWithoutBillsInput = {
  data: PaymentRequestEmailUpdateWithoutBillsDataInput;
  where: PaymentRequestEmailWhereUniqueInput;
};

export type PaymentRequestEmailUpdateWithoutBillsDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  member?: InputMaybe<MemberUpdateOneRequiredInput>;
  paymentType?: InputMaybe<PaymentType>;
  status?: InputMaybe<PaymentRequestEmailStatus>;
  templateName?: InputMaybe<Scalars['String']>;
};

export type PaymentRequestEmailUpsertWithWhereUniqueWithoutBillsInput = {
  create: PaymentRequestEmailCreateWithoutBillsInput;
  update: PaymentRequestEmailUpdateWithoutBillsDataInput;
  where: PaymentRequestEmailWhereUniqueInput;
};

export type PaymentRequestEmailWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<PaymentRequestEmailWhereContractsPersistencyInput>>;
  ContractsPersistencyPaymentType?: InputMaybe<ContractsPersistencyPaymentType>;
  ContractsPersistencyPaymentType_in?: InputMaybe<Array<ContractsPersistencyPaymentType>>;
  ContractsPersistencyPaymentType_not?: InputMaybe<ContractsPersistencyPaymentType>;
  ContractsPersistencyPaymentType_not_in?: InputMaybe<Array<ContractsPersistencyPaymentType>>;
  NOT?: InputMaybe<Array<PaymentRequestEmailWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<PaymentRequestEmailWhereContractsPersistencyInput>>;
  bills_every?: InputMaybe<BillWhereContractsPersistencyInput>;
  bills_none?: InputMaybe<BillWhereContractsPersistencyInput>;
  bills_some?: InputMaybe<BillWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  details?: InputMaybe<Scalars['String']>;
  details_contains?: InputMaybe<Scalars['String']>;
  details_ends_with?: InputMaybe<Scalars['String']>;
  details_gt?: InputMaybe<Scalars['String']>;
  details_gte?: InputMaybe<Scalars['String']>;
  details_in?: InputMaybe<Array<Scalars['String']>>;
  details_lt?: InputMaybe<Scalars['String']>;
  details_lte?: InputMaybe<Scalars['String']>;
  details_not?: InputMaybe<Scalars['String']>;
  details_not_contains?: InputMaybe<Scalars['String']>;
  details_not_ends_with?: InputMaybe<Scalars['String']>;
  details_not_in?: InputMaybe<Array<Scalars['String']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']>;
  details_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  member?: InputMaybe<MemberWhereContractsPersistencyInput>;
  status?: InputMaybe<ContractsPersistencyPaymentRequestEmailStatus>;
  status_in?: InputMaybe<Array<ContractsPersistencyPaymentRequestEmailStatus>>;
  status_not?: InputMaybe<ContractsPersistencyPaymentRequestEmailStatus>;
  status_not_in?: InputMaybe<Array<ContractsPersistencyPaymentRequestEmailStatus>>;
  templateName?: InputMaybe<Scalars['String']>;
  templateName_contains?: InputMaybe<Scalars['String']>;
  templateName_ends_with?: InputMaybe<Scalars['String']>;
  templateName_gt?: InputMaybe<Scalars['String']>;
  templateName_gte?: InputMaybe<Scalars['String']>;
  templateName_in?: InputMaybe<Array<Scalars['String']>>;
  templateName_lt?: InputMaybe<Scalars['String']>;
  templateName_lte?: InputMaybe<Scalars['String']>;
  templateName_not?: InputMaybe<Scalars['String']>;
  templateName_not_contains?: InputMaybe<Scalars['String']>;
  templateName_not_ends_with?: InputMaybe<Scalars['String']>;
  templateName_not_in?: InputMaybe<Array<Scalars['String']>>;
  templateName_not_starts_with?: InputMaybe<Scalars['String']>;
  templateName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentRequestEmailWhereInput = {
  AND?: InputMaybe<Array<PaymentRequestEmailWhereInput>>;
  NOT?: InputMaybe<Array<PaymentRequestEmailWhereInput>>;
  OR?: InputMaybe<Array<PaymentRequestEmailWhereInput>>;
  bills_every?: InputMaybe<BillWhereInput>;
  bills_none?: InputMaybe<BillWhereInput>;
  bills_some?: InputMaybe<BillWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  details?: InputMaybe<Scalars['String']>;
  details_contains?: InputMaybe<Scalars['String']>;
  details_ends_with?: InputMaybe<Scalars['String']>;
  details_gt?: InputMaybe<Scalars['String']>;
  details_gte?: InputMaybe<Scalars['String']>;
  details_in?: InputMaybe<Array<Scalars['String']>>;
  details_lt?: InputMaybe<Scalars['String']>;
  details_lte?: InputMaybe<Scalars['String']>;
  details_not?: InputMaybe<Scalars['String']>;
  details_not_contains?: InputMaybe<Scalars['String']>;
  details_not_ends_with?: InputMaybe<Scalars['String']>;
  details_not_in?: InputMaybe<Array<Scalars['String']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']>;
  details_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  member?: InputMaybe<MemberWhereInput>;
  paymentType?: InputMaybe<PaymentType>;
  paymentType_in?: InputMaybe<Array<PaymentType>>;
  paymentType_not?: InputMaybe<PaymentType>;
  paymentType_not_in?: InputMaybe<Array<PaymentType>>;
  status?: InputMaybe<PaymentRequestEmailStatus>;
  status_in?: InputMaybe<Array<PaymentRequestEmailStatus>>;
  status_not?: InputMaybe<PaymentRequestEmailStatus>;
  status_not_in?: InputMaybe<Array<PaymentRequestEmailStatus>>;
  templateName?: InputMaybe<Scalars['String']>;
  templateName_contains?: InputMaybe<Scalars['String']>;
  templateName_ends_with?: InputMaybe<Scalars['String']>;
  templateName_gt?: InputMaybe<Scalars['String']>;
  templateName_gte?: InputMaybe<Scalars['String']>;
  templateName_in?: InputMaybe<Array<Scalars['String']>>;
  templateName_lt?: InputMaybe<Scalars['String']>;
  templateName_lte?: InputMaybe<Scalars['String']>;
  templateName_not?: InputMaybe<Scalars['String']>;
  templateName_not_contains?: InputMaybe<Scalars['String']>;
  templateName_not_ends_with?: InputMaybe<Scalars['String']>;
  templateName_not_in?: InputMaybe<Array<Scalars['String']>>;
  templateName_not_starts_with?: InputMaybe<Scalars['String']>;
  templateName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentRequestEmailWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<PaymentRequestEmailWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<PaymentRequestEmailWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<PaymentRequestEmailWherePersistencyContractsInput>>;
  PersistencyContractsPaymentType?: InputMaybe<PersistencyContractsPaymentType>;
  PersistencyContractsPaymentType_in?: InputMaybe<Array<PersistencyContractsPaymentType>>;
  PersistencyContractsPaymentType_not?: InputMaybe<PersistencyContractsPaymentType>;
  PersistencyContractsPaymentType_not_in?: InputMaybe<Array<PersistencyContractsPaymentType>>;
  bills_every?: InputMaybe<BillWherePersistencyContractsInput>;
  bills_none?: InputMaybe<BillWherePersistencyContractsInput>;
  bills_some?: InputMaybe<BillWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  details?: InputMaybe<Scalars['String']>;
  details_contains?: InputMaybe<Scalars['String']>;
  details_ends_with?: InputMaybe<Scalars['String']>;
  details_gt?: InputMaybe<Scalars['String']>;
  details_gte?: InputMaybe<Scalars['String']>;
  details_in?: InputMaybe<Array<Scalars['String']>>;
  details_lt?: InputMaybe<Scalars['String']>;
  details_lte?: InputMaybe<Scalars['String']>;
  details_not?: InputMaybe<Scalars['String']>;
  details_not_contains?: InputMaybe<Scalars['String']>;
  details_not_ends_with?: InputMaybe<Scalars['String']>;
  details_not_in?: InputMaybe<Array<Scalars['String']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']>;
  details_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  member?: InputMaybe<MemberWherePersistencyContractsInput>;
  status?: InputMaybe<PersistencyContractsPaymentRequestEmailStatus>;
  status_in?: InputMaybe<Array<PersistencyContractsPaymentRequestEmailStatus>>;
  status_not?: InputMaybe<PersistencyContractsPaymentRequestEmailStatus>;
  status_not_in?: InputMaybe<Array<PersistencyContractsPaymentRequestEmailStatus>>;
  templateName?: InputMaybe<Scalars['String']>;
  templateName_contains?: InputMaybe<Scalars['String']>;
  templateName_ends_with?: InputMaybe<Scalars['String']>;
  templateName_gt?: InputMaybe<Scalars['String']>;
  templateName_gte?: InputMaybe<Scalars['String']>;
  templateName_in?: InputMaybe<Array<Scalars['String']>>;
  templateName_lt?: InputMaybe<Scalars['String']>;
  templateName_lte?: InputMaybe<Scalars['String']>;
  templateName_not?: InputMaybe<Scalars['String']>;
  templateName_not_contains?: InputMaybe<Scalars['String']>;
  templateName_not_ends_with?: InputMaybe<Scalars['String']>;
  templateName_not_in?: InputMaybe<Array<Scalars['String']>>;
  templateName_not_starts_with?: InputMaybe<Scalars['String']>;
  templateName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentRequestEmailWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum PaymentRequestOrderByInput {
  ALLOWEDPAYMENTMETHODS_ASC = 'allowedPaymentMethods_ASC',
  ALLOWEDPAYMENTMETHODS_DESC = 'allowedPaymentMethods_DESC',
  AMOUNT_ASC = 'amount_ASC',
  AMOUNT_DESC = 'amount_DESC',
  AUTHORIZEONLY_ASC = 'authorizeOnly_ASC',
  AUTHORIZEONLY_DESC = 'authorizeOnly_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  FAILUREREASON_ASC = 'failureReason_ASC',
  FAILUREREASON_DESC = 'failureReason_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  MANUALPAYMENTDESCRIPTION_ASC = 'manualPaymentDescription_ASC',
  MANUALPAYMENTDESCRIPTION_DESC = 'manualPaymentDescription_DESC',
  MANUALPAYMENTTYPE_ASC = 'manualPaymentType_ASC',
  MANUALPAYMENTTYPE_DESC = 'manualPaymentType_DESC',
  PAYMENTIDINPAYMENTPROVIDER_ASC = 'paymentIdInPaymentProvider_ASC',
  PAYMENTIDINPAYMENTPROVIDER_DESC = 'paymentIdInPaymentProvider_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export enum PaymentRequestStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type PaymentRequestUpdateInput = {
  allowedPaymentMethods?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  authorizeOnly?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<CurrencyUpdateOneRequiredInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  paymentIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderUpdateOneRequiredInput>;
  status?: InputMaybe<PaymentRequestStatus>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type PaymentRequestWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<PaymentRequestWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<PaymentRequestWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<PaymentRequestWhereContractsPersistencyInput>>;
  allowedPaymentMethods?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_contains?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_ends_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_gt?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_gte?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_in?: InputMaybe<Array<Scalars['String']>>;
  allowedPaymentMethods_lt?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_lte?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_contains?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_ends_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_in?: InputMaybe<Array<Scalars['String']>>;
  allowedPaymentMethods_not_starts_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_starts_with?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  authorizeOnly?: InputMaybe<Scalars['Boolean']>;
  authorizeOnly_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  currency?: InputMaybe<CurrencyWhereContractsPersistencyInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  manualContractsPersistencyPaymentType?: InputMaybe<ContractsPersistencyManualContractsPersistencyPaymentType>;
  manualContractsPersistencyPaymentType_in?: InputMaybe<Array<ContractsPersistencyManualContractsPersistencyPaymentType>>;
  manualContractsPersistencyPaymentType_not?: InputMaybe<ContractsPersistencyManualContractsPersistencyPaymentType>;
  manualContractsPersistencyPaymentType_not_in?: InputMaybe<Array<ContractsPersistencyManualContractsPersistencyPaymentType>>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_lt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_lte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_starts_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  paymentIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderWhereContractsPersistencyInput>;
  status?: InputMaybe<ContractsPersistencyPaymentRequestStatus>;
  status_in?: InputMaybe<Array<ContractsPersistencyPaymentRequestStatus>>;
  status_not?: InputMaybe<ContractsPersistencyPaymentRequestStatus>;
  status_not_in?: InputMaybe<Array<ContractsPersistencyPaymentRequestStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type PaymentRequestWhereInput = {
  AND?: InputMaybe<Array<PaymentRequestWhereInput>>;
  NOT?: InputMaybe<Array<PaymentRequestWhereInput>>;
  OR?: InputMaybe<Array<PaymentRequestWhereInput>>;
  allowedPaymentMethods?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_contains?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_ends_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_gt?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_gte?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_in?: InputMaybe<Array<Scalars['String']>>;
  allowedPaymentMethods_lt?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_lte?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_contains?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_ends_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_in?: InputMaybe<Array<Scalars['String']>>;
  allowedPaymentMethods_not_starts_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_starts_with?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  authorizeOnly?: InputMaybe<Scalars['Boolean']>;
  authorizeOnly_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  currency?: InputMaybe<CurrencyWhereInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_lt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_lte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  manualPaymentType_in?: InputMaybe<Array<ManualPaymentType>>;
  manualPaymentType_not?: InputMaybe<ManualPaymentType>;
  manualPaymentType_not_in?: InputMaybe<Array<ManualPaymentType>>;
  paymentIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  paymentIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderWhereInput>;
  status?: InputMaybe<PaymentRequestStatus>;
  status_in?: InputMaybe<Array<PaymentRequestStatus>>;
  status_not?: InputMaybe<PaymentRequestStatus>;
  status_not_in?: InputMaybe<Array<PaymentRequestStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
};

export type PaymentRequestWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<PaymentRequestWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<PaymentRequestWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<PaymentRequestWherePersistencyContractsInput>>;
  allowedPaymentMethods?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_contains?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_ends_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_gt?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_gte?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_in?: InputMaybe<Array<Scalars['String']>>;
  allowedPaymentMethods_lt?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_lte?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_contains?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_ends_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_not_in?: InputMaybe<Array<Scalars['String']>>;
  allowedPaymentMethods_not_starts_with?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods_starts_with?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  authorizeOnly?: InputMaybe<Scalars['Boolean']>;
  authorizeOnly_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  currency?: InputMaybe<CurrencyWherePersistencyContractsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_lt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_lte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_starts_with?: InputMaybe<Scalars['String']>;
  manualPersistencyContractsPaymentType?: InputMaybe<PersistencyContractsManualPersistencyContractsPaymentType>;
  manualPersistencyContractsPaymentType_in?: InputMaybe<Array<PersistencyContractsManualPersistencyContractsPaymentType>>;
  manualPersistencyContractsPaymentType_not?: InputMaybe<PersistencyContractsManualPersistencyContractsPaymentType>;
  manualPersistencyContractsPaymentType_not_in?: InputMaybe<Array<PersistencyContractsManualPersistencyContractsPaymentType>>;
  paymentIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  paymentIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderWherePersistencyContractsInput>;
  status?: InputMaybe<PersistencyContractsPaymentRequestStatus>;
  status_in?: InputMaybe<Array<PersistencyContractsPaymentRequestStatus>>;
  status_not?: InputMaybe<PersistencyContractsPaymentRequestStatus>;
  status_not_in?: InputMaybe<Array<PersistencyContractsPaymentRequestStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type PaymentRequestWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PaymentSetting = {
  __typename?: 'PaymentSetting';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood: Hood;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Json'];
};

export type PaymentSettingConnection = {
  __typename?: 'PaymentSettingConnection';
  aggregate: AggregatePaymentSetting;
  edges: Array<Maybe<PaymentSettingEdge>>;
  pageInfo: PageInfo;
};

export type PaymentSettingCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood: HoodCreateOneInput;
  name: Scalars['String'];
  value: Scalars['Json'];
};

export type PaymentSettingEdge = {
  __typename?: 'PaymentSettingEdge';
  cursor: Scalars['String'];
  node: PaymentSetting;
};

export enum PaymentSettingOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC'
}

export type PaymentSettingUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Json']>;
};

export type PaymentSettingWhereInput = {
  AND?: InputMaybe<Array<PaymentSettingWhereInput>>;
  NOT?: InputMaybe<Array<PaymentSettingWhereInput>>;
  OR?: InputMaybe<Array<PaymentSettingWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PaymentSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PaymentSettings = {
  __typename?: 'PaymentSettings';
  autoBilling: AutoBillingSettings;
  billingNotifications: BillingNotificationsSettings;
  currency: Currency;
  hoodName: Scalars['String'];
  paymentProviderFees?: Maybe<Array<PaymentProviderFeesSetting>>;
  paymentProviders: Array<Maybe<PaymentProvider>>;
  paymentReceipt: PaymentReceiptSettings;
  rentLateFees: RentLateFeesSettings;
};

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  amount: Scalars['Float'];
  bill: Bill;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  manualPaymentDescription?: Maybe<Scalars['String']>;
  manualPaymentType?: Maybe<ManualPaymentType>;
  metadata?: Maybe<Scalars['Json']>;
  paymentProvider: PaymentProvider;
  paymentProviderTransactionId?: Maybe<Scalars['String']>;
  paymentReceiptEmail?: Maybe<PaymentReceiptEmail>;
  status?: Maybe<PaymentTransactionStatus>;
  taxAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  transactionFee: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userPaymentMethod?: Maybe<UserPaymentMethod>;
};

export type PaymentTransactionConnection = {
  __typename?: 'PaymentTransactionConnection';
  aggregate: AggregatePaymentTransaction;
  edges: Array<Maybe<PaymentTransactionEdge>>;
  pageInfo: PageInfo;
};

export type PaymentTransactionCreateInput = {
  amount: Scalars['Float'];
  bill: BillCreateOneWithoutPaymentTransactionsInput;
  currency: CurrencyCreateOneInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentProvider: PaymentProviderCreateOneInput;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  paymentReceiptEmail?: InputMaybe<PaymentReceiptEmailCreateOneWithoutPaymentTransactionInput>;
  status?: InputMaybe<PaymentTransactionStatus>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  user: UserCreateOneInput;
  userPaymentMethod?: InputMaybe<UserPaymentMethodCreateOneInput>;
};

export type PaymentTransactionCreateManyWithoutBillInput = {
  connect?: InputMaybe<Array<PaymentTransactionWhereUniqueInput>>;
  create?: InputMaybe<Array<PaymentTransactionCreateWithoutBillInput>>;
};

export type PaymentTransactionCreateOneWithoutPaymentReceiptEmailInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutPaymentReceiptEmailInput>;
};

export type PaymentTransactionCreateWithoutBillInput = {
  amount: Scalars['Float'];
  currency: CurrencyCreateOneInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentProvider: PaymentProviderCreateOneInput;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  paymentReceiptEmail?: InputMaybe<PaymentReceiptEmailCreateOneWithoutPaymentTransactionInput>;
  status?: InputMaybe<PaymentTransactionStatus>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  user: UserCreateOneInput;
  userPaymentMethod?: InputMaybe<UserPaymentMethodCreateOneInput>;
};

export type PaymentTransactionCreateWithoutPaymentReceiptEmailInput = {
  amount: Scalars['Float'];
  bill: BillCreateOneWithoutPaymentTransactionsInput;
  currency: CurrencyCreateOneInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentProvider: PaymentProviderCreateOneInput;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentTransactionStatus>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  user: UserCreateOneInput;
  userPaymentMethod?: InputMaybe<UserPaymentMethodCreateOneInput>;
};

export type PaymentTransactionEdge = {
  __typename?: 'PaymentTransactionEdge';
  cursor: Scalars['String'];
  node: PaymentTransaction;
};

export enum PaymentTransactionOrderByInput {
  AMOUNT_ASC = 'amount_ASC',
  AMOUNT_DESC = 'amount_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  EXTERNALID_ASC = 'externalId_ASC',
  EXTERNALID_DESC = 'externalId_DESC',
  FAILUREREASON_ASC = 'failureReason_ASC',
  FAILUREREASON_DESC = 'failureReason_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  MANUALPAYMENTDESCRIPTION_ASC = 'manualPaymentDescription_ASC',
  MANUALPAYMENTDESCRIPTION_DESC = 'manualPaymentDescription_DESC',
  MANUALPAYMENTTYPE_ASC = 'manualPaymentType_ASC',
  MANUALPAYMENTTYPE_DESC = 'manualPaymentType_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  PAYMENTPROVIDERTRANSACTIONID_ASC = 'paymentProviderTransactionId_ASC',
  PAYMENTPROVIDERTRANSACTIONID_DESC = 'paymentProviderTransactionId_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  TAXAMOUNT_ASC = 'taxAmount_ASC',
  TAXAMOUNT_DESC = 'taxAmount_DESC',
  TRANSACTIONFEE_ASC = 'transactionFee_ASC',
  TRANSACTIONFEE_DESC = 'transactionFee_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type PaymentTransactionScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentTransactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentTransactionScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentTransactionScalarWhereInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_lt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_lte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  manualPaymentType_in?: InputMaybe<Array<ManualPaymentType>>;
  manualPaymentType_not?: InputMaybe<ManualPaymentType>;
  manualPaymentType_not_in?: InputMaybe<Array<ManualPaymentType>>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_contains?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_ends_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_gt?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_gte?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_in?: InputMaybe<Array<Scalars['String']>>;
  paymentProviderTransactionId_lt?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_lte?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_contains?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentProviderTransactionId_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_starts_with?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentTransactionStatus>;
  status_in?: InputMaybe<Array<PaymentTransactionStatus>>;
  status_not?: InputMaybe<PaymentTransactionStatus>;
  status_not_in?: InputMaybe<Array<PaymentTransactionStatus>>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  taxAmount_gt?: InputMaybe<Scalars['Float']>;
  taxAmount_gte?: InputMaybe<Scalars['Float']>;
  taxAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  taxAmount_lt?: InputMaybe<Scalars['Float']>;
  taxAmount_lte?: InputMaybe<Scalars['Float']>;
  taxAmount_not?: InputMaybe<Scalars['Float']>;
  taxAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  transactionFee_gt?: InputMaybe<Scalars['Float']>;
  transactionFee_gte?: InputMaybe<Scalars['Float']>;
  transactionFee_in?: InputMaybe<Array<Scalars['Float']>>;
  transactionFee_lt?: InputMaybe<Scalars['Float']>;
  transactionFee_lte?: InputMaybe<Scalars['Float']>;
  transactionFee_not?: InputMaybe<Scalars['Float']>;
  transactionFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum PaymentTransactionStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type PaymentTransactionUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bill?: InputMaybe<BillUpdateOneRequiredWithoutPaymentTransactionsInput>;
  currency?: InputMaybe<CurrencyUpdateOneRequiredInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentProvider?: InputMaybe<PaymentProviderUpdateOneRequiredInput>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  paymentReceiptEmail?: InputMaybe<PaymentReceiptEmailUpdateOneWithoutPaymentTransactionInput>;
  status?: InputMaybe<PaymentTransactionStatus>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  userPaymentMethod?: InputMaybe<UserPaymentMethodUpdateOneInput>;
};

export type PaymentTransactionUpdateManyDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentTransactionStatus>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
};

export type PaymentTransactionUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentTransactionStatus>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
};

export type PaymentTransactionUpdateManyWithWhereNestedInput = {
  data: PaymentTransactionUpdateManyDataInput;
  where: PaymentTransactionScalarWhereInput;
};

export type PaymentTransactionUpdateManyWithoutBillInput = {
  connect?: InputMaybe<Array<PaymentTransactionWhereUniqueInput>>;
  create?: InputMaybe<Array<PaymentTransactionCreateWithoutBillInput>>;
  delete?: InputMaybe<Array<PaymentTransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentTransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentTransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentTransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentTransactionUpdateWithWhereUniqueWithoutBillInput>>;
  updateMany?: InputMaybe<Array<PaymentTransactionUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<PaymentTransactionUpsertWithWhereUniqueWithoutBillInput>>;
};

export type PaymentTransactionUpdateOneRequiredWithoutPaymentReceiptEmailInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutPaymentReceiptEmailInput>;
  update?: InputMaybe<PaymentTransactionUpdateWithoutPaymentReceiptEmailDataInput>;
  upsert?: InputMaybe<PaymentTransactionUpsertWithoutPaymentReceiptEmailInput>;
};

export type PaymentTransactionUpdateWithWhereUniqueWithoutBillInput = {
  data: PaymentTransactionUpdateWithoutBillDataInput;
  where: PaymentTransactionWhereUniqueInput;
};

export type PaymentTransactionUpdateWithoutBillDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<CurrencyUpdateOneRequiredInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentProvider?: InputMaybe<PaymentProviderUpdateOneRequiredInput>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  paymentReceiptEmail?: InputMaybe<PaymentReceiptEmailUpdateOneWithoutPaymentTransactionInput>;
  status?: InputMaybe<PaymentTransactionStatus>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  userPaymentMethod?: InputMaybe<UserPaymentMethodUpdateOneInput>;
};

export type PaymentTransactionUpdateWithoutPaymentReceiptEmailDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bill?: InputMaybe<BillUpdateOneRequiredWithoutPaymentTransactionsInput>;
  currency?: InputMaybe<CurrencyUpdateOneRequiredInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['Json']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentProvider?: InputMaybe<PaymentProviderUpdateOneRequiredInput>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentTransactionStatus>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  userPaymentMethod?: InputMaybe<UserPaymentMethodUpdateOneInput>;
};

export type PaymentTransactionUpsertWithWhereUniqueWithoutBillInput = {
  create: PaymentTransactionCreateWithoutBillInput;
  update: PaymentTransactionUpdateWithoutBillDataInput;
  where: PaymentTransactionWhereUniqueInput;
};

export type PaymentTransactionUpsertWithoutPaymentReceiptEmailInput = {
  create: PaymentTransactionCreateWithoutPaymentReceiptEmailInput;
  update: PaymentTransactionUpdateWithoutPaymentReceiptEmailDataInput;
};

export type PaymentTransactionWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<PaymentTransactionWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<PaymentTransactionWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<PaymentTransactionWhereContractsPersistencyInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bill?: InputMaybe<BillWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  currency?: InputMaybe<CurrencyWhereContractsPersistencyInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  manualContractsPersistencyPaymentType?: InputMaybe<ContractsPersistencyManualContractsPersistencyPaymentType>;
  manualContractsPersistencyPaymentType_in?: InputMaybe<Array<ContractsPersistencyManualContractsPersistencyPaymentType>>;
  manualContractsPersistencyPaymentType_not?: InputMaybe<ContractsPersistencyManualContractsPersistencyPaymentType>;
  manualContractsPersistencyPaymentType_not_in?: InputMaybe<Array<ContractsPersistencyManualContractsPersistencyPaymentType>>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_lt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_lte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_starts_with?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderWhereContractsPersistencyInput>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_contains?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_ends_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_gt?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_gte?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_in?: InputMaybe<Array<Scalars['String']>>;
  paymentProviderTransactionId_lt?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_lte?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_contains?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentProviderTransactionId_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_starts_with?: InputMaybe<Scalars['String']>;
  paymentReceiptEmail?: InputMaybe<PaymentReceiptEmailWhereContractsPersistencyInput>;
  status?: InputMaybe<ContractsPersistencyPaymentTransactionStatus>;
  status_in?: InputMaybe<Array<ContractsPersistencyPaymentTransactionStatus>>;
  status_not?: InputMaybe<ContractsPersistencyPaymentTransactionStatus>;
  status_not_in?: InputMaybe<Array<ContractsPersistencyPaymentTransactionStatus>>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  transactionFee_gt?: InputMaybe<Scalars['Float']>;
  transactionFee_gte?: InputMaybe<Scalars['Float']>;
  transactionFee_in?: InputMaybe<Array<Scalars['Float']>>;
  transactionFee_lt?: InputMaybe<Scalars['Float']>;
  transactionFee_lte?: InputMaybe<Scalars['Float']>;
  transactionFee_not?: InputMaybe<Scalars['Float']>;
  transactionFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
  userPaymentMethod?: InputMaybe<UserPaymentMethodWhereContractsPersistencyInput>;
};

export type PaymentTransactionWhereInput = {
  AND?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  NOT?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  OR?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bill?: InputMaybe<BillWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  currency?: InputMaybe<CurrencyWhereInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_lt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_lte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentType?: InputMaybe<ManualPaymentType>;
  manualPaymentType_in?: InputMaybe<Array<ManualPaymentType>>;
  manualPaymentType_not?: InputMaybe<ManualPaymentType>;
  manualPaymentType_not_in?: InputMaybe<Array<ManualPaymentType>>;
  paymentProvider?: InputMaybe<PaymentProviderWhereInput>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_contains?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_ends_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_gt?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_gte?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_in?: InputMaybe<Array<Scalars['String']>>;
  paymentProviderTransactionId_lt?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_lte?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_contains?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentProviderTransactionId_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_starts_with?: InputMaybe<Scalars['String']>;
  paymentReceiptEmail?: InputMaybe<PaymentReceiptEmailWhereInput>;
  status?: InputMaybe<PaymentTransactionStatus>;
  status_in?: InputMaybe<Array<PaymentTransactionStatus>>;
  status_not?: InputMaybe<PaymentTransactionStatus>;
  status_not_in?: InputMaybe<Array<PaymentTransactionStatus>>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  taxAmount_gt?: InputMaybe<Scalars['Float']>;
  taxAmount_gte?: InputMaybe<Scalars['Float']>;
  taxAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  taxAmount_lt?: InputMaybe<Scalars['Float']>;
  taxAmount_lte?: InputMaybe<Scalars['Float']>;
  taxAmount_not?: InputMaybe<Scalars['Float']>;
  taxAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  transactionFee_gt?: InputMaybe<Scalars['Float']>;
  transactionFee_gte?: InputMaybe<Scalars['Float']>;
  transactionFee_in?: InputMaybe<Array<Scalars['Float']>>;
  transactionFee_lt?: InputMaybe<Scalars['Float']>;
  transactionFee_lte?: InputMaybe<Scalars['Float']>;
  transactionFee_not?: InputMaybe<Scalars['Float']>;
  transactionFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
  userPaymentMethod?: InputMaybe<UserPaymentMethodWhereInput>;
};

export type PaymentTransactionWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<PaymentTransactionWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<PaymentTransactionWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<PaymentTransactionWherePersistencyContractsInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bill?: InputMaybe<BillWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  currency?: InputMaybe<CurrencyWherePersistencyContractsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  manualPaymentDescription?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_gte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_lt?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_lte?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_contains?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  manualPaymentDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  manualPaymentDescription_starts_with?: InputMaybe<Scalars['String']>;
  manualPersistencyContractsPaymentType?: InputMaybe<PersistencyContractsManualPersistencyContractsPaymentType>;
  manualPersistencyContractsPaymentType_in?: InputMaybe<Array<PersistencyContractsManualPersistencyContractsPaymentType>>;
  manualPersistencyContractsPaymentType_not?: InputMaybe<PersistencyContractsManualPersistencyContractsPaymentType>;
  manualPersistencyContractsPaymentType_not_in?: InputMaybe<Array<PersistencyContractsManualPersistencyContractsPaymentType>>;
  paymentProvider?: InputMaybe<PaymentProviderWherePersistencyContractsInput>;
  paymentProviderTransactionId?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_contains?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_ends_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_gt?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_gte?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_in?: InputMaybe<Array<Scalars['String']>>;
  paymentProviderTransactionId_lt?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_lte?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_contains?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentProviderTransactionId_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentProviderTransactionId_starts_with?: InputMaybe<Scalars['String']>;
  paymentReceiptEmail?: InputMaybe<PaymentReceiptEmailWherePersistencyContractsInput>;
  status?: InputMaybe<PersistencyContractsPaymentTransactionStatus>;
  status_in?: InputMaybe<Array<PersistencyContractsPaymentTransactionStatus>>;
  status_not?: InputMaybe<PersistencyContractsPaymentTransactionStatus>;
  status_not_in?: InputMaybe<Array<PersistencyContractsPaymentTransactionStatus>>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  transactionFee_gt?: InputMaybe<Scalars['Float']>;
  transactionFee_gte?: InputMaybe<Scalars['Float']>;
  transactionFee_in?: InputMaybe<Array<Scalars['Float']>>;
  transactionFee_lt?: InputMaybe<Scalars['Float']>;
  transactionFee_lte?: InputMaybe<Scalars['Float']>;
  transactionFee_not?: InputMaybe<Scalars['Float']>;
  transactionFee_not_in?: InputMaybe<Array<Scalars['Float']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
  userPaymentMethod?: InputMaybe<UserPaymentMethodWherePersistencyContractsInput>;
};

export type PaymentTransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum PaymentType {
  DEPOSIT = 'DEPOSIT',
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  GENERIC = 'GENERIC',
  MONTHLY = 'MONTHLY',
  WAITING_LIST = 'WAITING_LIST'
}

export type PayoutPlan = {
  __typename?: 'PayoutPlan';
  _empty?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  building: Building;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood: Hood;
  id: Scalars['ID'];
  productCategory: ProductCategory;
  updatedAt: Scalars['DateTime'];
};

export type PayoutPlanConnection = {
  __typename?: 'PayoutPlanConnection';
  aggregate: AggregatePayoutPlan;
  edges: Array<Maybe<PayoutPlanEdge>>;
  pageInfo: PageInfo;
};

export type PayoutPlanCreateInput = {
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  building: BuildingCreateOneInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood: HoodCreateOneInput;
  productCategory: ProductCategoryCreateOneInput;
};

export type PayoutPlanEdge = {
  __typename?: 'PayoutPlanEdge';
  cursor: Scalars['String'];
  node: PayoutPlan;
};

export enum PayoutPlanOrderByInput {
  ACCOUNTID_ASC = 'accountId_ASC',
  ACCOUNTID_DESC = 'accountId_DESC',
  ACCOUNTNAME_ASC = 'accountName_ASC',
  ACCOUNTNAME_DESC = 'accountName_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type PayoutPlanUpdateInput = {
  accountId?: InputMaybe<Scalars['String']>;
  accountName?: InputMaybe<Scalars['String']>;
  building?: InputMaybe<BuildingUpdateOneRequiredInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  productCategory?: InputMaybe<ProductCategoryUpdateOneRequiredInput>;
};

export type PayoutPlanWhereInput = {
  AND?: InputMaybe<Array<PayoutPlanWhereInput>>;
  NOT?: InputMaybe<Array<PayoutPlanWhereInput>>;
  OR?: InputMaybe<Array<PayoutPlanWhereInput>>;
  accountId?: InputMaybe<Scalars['String']>;
  accountId_contains?: InputMaybe<Scalars['String']>;
  accountId_ends_with?: InputMaybe<Scalars['String']>;
  accountId_gt?: InputMaybe<Scalars['String']>;
  accountId_gte?: InputMaybe<Scalars['String']>;
  accountId_in?: InputMaybe<Array<Scalars['String']>>;
  accountId_lt?: InputMaybe<Scalars['String']>;
  accountId_lte?: InputMaybe<Scalars['String']>;
  accountId_not?: InputMaybe<Scalars['String']>;
  accountId_not_contains?: InputMaybe<Scalars['String']>;
  accountId_not_ends_with?: InputMaybe<Scalars['String']>;
  accountId_not_in?: InputMaybe<Array<Scalars['String']>>;
  accountId_not_starts_with?: InputMaybe<Scalars['String']>;
  accountId_starts_with?: InputMaybe<Scalars['String']>;
  accountName?: InputMaybe<Scalars['String']>;
  accountName_contains?: InputMaybe<Scalars['String']>;
  accountName_ends_with?: InputMaybe<Scalars['String']>;
  accountName_gt?: InputMaybe<Scalars['String']>;
  accountName_gte?: InputMaybe<Scalars['String']>;
  accountName_in?: InputMaybe<Array<Scalars['String']>>;
  accountName_lt?: InputMaybe<Scalars['String']>;
  accountName_lte?: InputMaybe<Scalars['String']>;
  accountName_not?: InputMaybe<Scalars['String']>;
  accountName_not_contains?: InputMaybe<Scalars['String']>;
  accountName_not_ends_with?: InputMaybe<Scalars['String']>;
  accountName_not_in?: InputMaybe<Array<Scalars['String']>>;
  accountName_not_starts_with?: InputMaybe<Scalars['String']>;
  accountName_starts_with?: InputMaybe<Scalars['String']>;
  building?: InputMaybe<BuildingWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  productCategory?: InputMaybe<ProductCategoryWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PayoutPlanWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum PersistencyContractsAddressType {
  BILLING_ADDRESS = 'BILLING_ADDRESS'
}

export enum PersistencyContractsBillableItemStatus {
  INIT_DONE = 'INIT_DONE',
  INIT_IN_PROCESS = 'INIT_IN_PROCESS',
  NEW = 'NEW'
}

export enum PersistencyContractsBuildingStatus {
  CHURNED = 'CHURNED',
  PIPELINE = 'PIPELINE',
  SIGNED_SETUP_PERIOD = 'SIGNED_SETUP_PERIOD',
  SIGNED_STABILISED_PERIOD = 'SIGNED_STABILISED_PERIOD',
  UNDER_CONTRACT = 'UNDER_CONTRACT'
}

export enum PersistencyContractsCountryCode {
  DE = 'DE',
  IL = 'IL',
  US = 'US'
}

export enum PersistencyContractsGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NA = 'NA',
  NON_BINARY = 'NON_BINARY'
}

export enum PersistencyContractsLandlordContractType {
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  VENN_CO_OWNERSHIP_LEASE = 'VENN_CO_OWNERSHIP_LEASE',
  VENN_LEASE = 'VENN_LEASE'
}

export enum PersistencyContractsLockProvider {
  AUGUST = 'AUGUST',
  SDS = 'SDS'
}

export enum PersistencyContractsManualPersistencyContractsPaymentType {
  CASH = 'CASH',
  CHECK = 'CHECK',
  SAP = 'SAP'
}

export enum PersistencyContractsNotificationDestinationType {
  SLACK = 'SLACK',
  SMS = 'SMS'
}

export enum PersistencyContractsNotificationSourceType {
  AIRTABLE = 'AIRTABLE',
  PAYMENT_SERVICE = 'PAYMENT_SERVICE'
}

export enum PersistencyContractsNotificationStatus {
  FAILURE = 'FAILURE',
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  RETRY = 'RETRY',
  SUCCESS = 'SUCCESS'
}

export enum PersistencyContractsNotificationType {
  BANK_AUTHORIZATION_EXPIRING = 'BANK_AUTHORIZATION_EXPIRING',
  BILLING_NOTICE = 'BILLING_NOTICE',
  CHARGE_CSV_IMPORT_REPORT = 'CHARGE_CSV_IMPORT_REPORT',
  END_OF_CONTRACT = 'END_OF_CONTRACT',
  INVALID_AIRTABLE_MEMBER_ROW = 'INVALID_AIRTABLE_MEMBER_ROW'
}

export enum PersistencyContractsPaymentMethodType {
  ACH = 'ACH',
  CREDIT_CARD = 'CREDIT_CARD',
  MANUAL_ACH = 'MANUAL_ACH',
  MANUAL_BANK = 'MANUAL_BANK',
  SEPA = 'SEPA'
}

export enum PersistencyContractsPaymentRequestEmailStatus {
  INITIALIZED = 'INITIALIZED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  MEMBER_DATA_ERROR = 'MEMBER_DATA_ERROR',
  RECEIVED = 'RECEIVED',
  SENDING_FAILURE = 'SENDING_FAILURE',
  SENT = 'SENT'
}

export enum PersistencyContractsPaymentRequestStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum PersistencyContractsPaymentTransactionStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum PersistencyContractsPaymentType {
  DEPOSIT = 'DEPOSIT',
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  GENERIC = 'GENERIC',
  MONTHLY = 'MONTHLY',
  WAITING_LIST = 'WAITING_LIST'
}

export enum PersistencyContractsPlaceTypes {
  COMMUNITY_BUILDING = 'COMMUNITY_BUILDING',
  EVENT = 'EVENT',
  LANDMARK = 'LANDMARK',
  LOCAL_BUSINESS = 'LOCAL_BUSINESS',
  SHARED_SPACE = 'SHARED_SPACE',
  SUBWAY = 'SUBWAY'
}

export enum PersistencyContractsProductStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE'
}

export enum PersistencyContractsRentingType {
  BY_BEDROOM = 'BY_BEDROOM',
  WHOLE_UNIT = 'WHOLE_UNIT'
}

export enum PersistencyContractsServiceCallStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum PersistencyContractsServiceType {
  HANDYMAN = 'HANDYMAN',
  VINI = 'VINI'
}

export enum PersistencyContractsSurveyAnswer {
  SATISFACTION_NOT_DISAPPOINTED = 'SATISFACTION_NOT_DISAPPOINTED',
  SATISFACTION_SOMEHOW_DISAPPOINTED = 'SATISFACTION_SOMEHOW_DISAPPOINTED',
  SATISFACTION_VERY_DISAPPOINTED = 'SATISFACTION_VERY_DISAPPOINTED'
}

export enum PersistencyContractsSurveyType {
  SATISFACTION = 'SATISFACTION'
}

export enum PersistencyContractsUnitType {
  DUPLEX = 'DUPLEX',
  STUDIO = 'STUDIO'
}

export type Pet = {
  __typename?: 'Pet';
  _empty?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imgUrl?: Maybe<Scalars['String']>;
  memberProfilePetsField?: Maybe<MemberProfilePetsField>;
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PetConnection = {
  __typename?: 'PetConnection';
  aggregate: AggregatePet;
  edges: Array<Maybe<PetEdge>>;
  pageInfo: PageInfo;
};

export type PetCreateInput = {
  bio?: InputMaybe<Scalars['String']>;
  breed?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  memberProfilePetsField?: InputMaybe<MemberProfilePetsFieldCreateOneWithoutPetsInput>;
  name: Scalars['String'];
  tags?: InputMaybe<PetCreatetagsInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type PetCreateManyWithoutMemberProfilePetsFieldInput = {
  connect?: InputMaybe<Array<PetWhereUniqueInput>>;
  create?: InputMaybe<Array<PetCreateWithoutMemberProfilePetsFieldInput>>;
};

export type PetCreateWithoutMemberProfilePetsFieldInput = {
  bio?: InputMaybe<Scalars['String']>;
  breed?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  tags?: InputMaybe<PetCreatetagsInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type PetCreatetagsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PetEdge = {
  __typename?: 'PetEdge';
  cursor: Scalars['String'];
  node: Pet;
};

export enum PetOrderByInput {
  BIO_ASC = 'bio_ASC',
  BIO_DESC = 'bio_DESC',
  BREED_ASC = 'breed_ASC',
  BREED_DESC = 'breed_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMGURL_ASC = 'imgUrl_ASC',
  IMGURL_DESC = 'imgUrl_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type PetScalarWhereInput = {
  AND?: InputMaybe<Array<PetScalarWhereInput>>;
  NOT?: InputMaybe<Array<PetScalarWhereInput>>;
  OR?: InputMaybe<Array<PetScalarWhereInput>>;
  bio?: InputMaybe<Scalars['String']>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_ends_with?: InputMaybe<Scalars['String']>;
  bio_gt?: InputMaybe<Scalars['String']>;
  bio_gte?: InputMaybe<Scalars['String']>;
  bio_in?: InputMaybe<Array<Scalars['String']>>;
  bio_lt?: InputMaybe<Scalars['String']>;
  bio_lte?: InputMaybe<Scalars['String']>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_ends_with?: InputMaybe<Scalars['String']>;
  bio_not_in?: InputMaybe<Array<Scalars['String']>>;
  bio_not_starts_with?: InputMaybe<Scalars['String']>;
  bio_starts_with?: InputMaybe<Scalars['String']>;
  breed?: InputMaybe<Scalars['String']>;
  breed_contains?: InputMaybe<Scalars['String']>;
  breed_ends_with?: InputMaybe<Scalars['String']>;
  breed_gt?: InputMaybe<Scalars['String']>;
  breed_gte?: InputMaybe<Scalars['String']>;
  breed_in?: InputMaybe<Array<Scalars['String']>>;
  breed_lt?: InputMaybe<Scalars['String']>;
  breed_lte?: InputMaybe<Scalars['String']>;
  breed_not?: InputMaybe<Scalars['String']>;
  breed_not_contains?: InputMaybe<Scalars['String']>;
  breed_not_ends_with?: InputMaybe<Scalars['String']>;
  breed_not_in?: InputMaybe<Array<Scalars['String']>>;
  breed_not_starts_with?: InputMaybe<Scalars['String']>;
  breed_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  imgUrl_contains?: InputMaybe<Scalars['String']>;
  imgUrl_ends_with?: InputMaybe<Scalars['String']>;
  imgUrl_gt?: InputMaybe<Scalars['String']>;
  imgUrl_gte?: InputMaybe<Scalars['String']>;
  imgUrl_in?: InputMaybe<Array<Scalars['String']>>;
  imgUrl_lt?: InputMaybe<Scalars['String']>;
  imgUrl_lte?: InputMaybe<Scalars['String']>;
  imgUrl_not?: InputMaybe<Scalars['String']>;
  imgUrl_not_contains?: InputMaybe<Scalars['String']>;
  imgUrl_not_ends_with?: InputMaybe<Scalars['String']>;
  imgUrl_not_in?: InputMaybe<Array<Scalars['String']>>;
  imgUrl_not_starts_with?: InputMaybe<Scalars['String']>;
  imgUrl_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  tags_contains?: InputMaybe<Scalars['String']>;
  tags_contains_every?: InputMaybe<Array<Scalars['String']>>;
  tags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PetUpdateInput = {
  bio?: InputMaybe<Scalars['String']>;
  breed?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  memberProfilePetsField?: InputMaybe<MemberProfilePetsFieldUpdateOneWithoutPetsInput>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<PetUpdatetagsInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type PetUpdateManyDataInput = {
  bio?: InputMaybe<Scalars['String']>;
  breed?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<PetUpdatetagsInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type PetUpdateManyWithWhereNestedInput = {
  data: PetUpdateManyDataInput;
  where: PetScalarWhereInput;
};

export type PetUpdateManyWithoutMemberProfilePetsFieldInput = {
  connect?: InputMaybe<Array<PetWhereUniqueInput>>;
  create?: InputMaybe<Array<PetCreateWithoutMemberProfilePetsFieldInput>>;
  delete?: InputMaybe<Array<PetWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PetScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PetWhereUniqueInput>>;
  set?: InputMaybe<Array<PetWhereUniqueInput>>;
  update?: InputMaybe<Array<PetUpdateWithWhereUniqueWithoutMemberProfilePetsFieldInput>>;
  updateMany?: InputMaybe<Array<PetUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<PetUpsertWithWhereUniqueWithoutMemberProfilePetsFieldInput>>;
};

export type PetUpdateWithWhereUniqueWithoutMemberProfilePetsFieldInput = {
  data: PetUpdateWithoutMemberProfilePetsFieldDataInput;
  where: PetWhereUniqueInput;
};

export type PetUpdateWithoutMemberProfilePetsFieldDataInput = {
  bio?: InputMaybe<Scalars['String']>;
  breed?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<PetUpdatetagsInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type PetUpdatetagsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PetUpsertWithWhereUniqueWithoutMemberProfilePetsFieldInput = {
  create: PetCreateWithoutMemberProfilePetsFieldInput;
  update: PetUpdateWithoutMemberProfilePetsFieldDataInput;
  where: PetWhereUniqueInput;
};

export type PetWhereInput = {
  AND?: InputMaybe<Array<PetWhereInput>>;
  NOT?: InputMaybe<Array<PetWhereInput>>;
  OR?: InputMaybe<Array<PetWhereInput>>;
  bio?: InputMaybe<Scalars['String']>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_ends_with?: InputMaybe<Scalars['String']>;
  bio_gt?: InputMaybe<Scalars['String']>;
  bio_gte?: InputMaybe<Scalars['String']>;
  bio_in?: InputMaybe<Array<Scalars['String']>>;
  bio_lt?: InputMaybe<Scalars['String']>;
  bio_lte?: InputMaybe<Scalars['String']>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_ends_with?: InputMaybe<Scalars['String']>;
  bio_not_in?: InputMaybe<Array<Scalars['String']>>;
  bio_not_starts_with?: InputMaybe<Scalars['String']>;
  bio_starts_with?: InputMaybe<Scalars['String']>;
  breed?: InputMaybe<Scalars['String']>;
  breed_contains?: InputMaybe<Scalars['String']>;
  breed_ends_with?: InputMaybe<Scalars['String']>;
  breed_gt?: InputMaybe<Scalars['String']>;
  breed_gte?: InputMaybe<Scalars['String']>;
  breed_in?: InputMaybe<Array<Scalars['String']>>;
  breed_lt?: InputMaybe<Scalars['String']>;
  breed_lte?: InputMaybe<Scalars['String']>;
  breed_not?: InputMaybe<Scalars['String']>;
  breed_not_contains?: InputMaybe<Scalars['String']>;
  breed_not_ends_with?: InputMaybe<Scalars['String']>;
  breed_not_in?: InputMaybe<Array<Scalars['String']>>;
  breed_not_starts_with?: InputMaybe<Scalars['String']>;
  breed_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  imgUrl_contains?: InputMaybe<Scalars['String']>;
  imgUrl_ends_with?: InputMaybe<Scalars['String']>;
  imgUrl_gt?: InputMaybe<Scalars['String']>;
  imgUrl_gte?: InputMaybe<Scalars['String']>;
  imgUrl_in?: InputMaybe<Array<Scalars['String']>>;
  imgUrl_lt?: InputMaybe<Scalars['String']>;
  imgUrl_lte?: InputMaybe<Scalars['String']>;
  imgUrl_not?: InputMaybe<Scalars['String']>;
  imgUrl_not_contains?: InputMaybe<Scalars['String']>;
  imgUrl_not_ends_with?: InputMaybe<Scalars['String']>;
  imgUrl_not_in?: InputMaybe<Array<Scalars['String']>>;
  imgUrl_not_starts_with?: InputMaybe<Scalars['String']>;
  imgUrl_starts_with?: InputMaybe<Scalars['String']>;
  memberProfilePetsField?: InputMaybe<MemberProfilePetsFieldWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  tags_contains?: InputMaybe<Scalars['String']>;
  tags_contains_every?: InputMaybe<Array<Scalars['String']>>;
  tags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Place = {
  __typename?: 'Place';
  address?: Maybe<Address>;
  bookableAsset?: Maybe<Scalars['Json']>;
  building?: Maybe<Building>;
  category?: Maybe<PlaceCategory>;
  createdAt: Scalars['DateTime'];
  currentlyOpen?: Maybe<Scalars['Boolean']>;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  hood?: Maybe<Hood>;
  id: Scalars['ID'];
  images?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  openingDate?: Maybe<Scalars['DateTime']>;
  openingHours?: Maybe<Scalars['String']>;
  publish?: Maybe<Scalars['Boolean']>;
  sharedSpace?: Maybe<SharedSpace>;
  type: PlaceTypes;
  updatedAt: Scalars['DateTime'];
};

export enum PlaceCategory {
  ART_GALLERY = 'ART_GALLERY',
  BAR = 'BAR',
  BEAUTY_SALON = 'BEAUTY_SALON',
  CAFE = 'CAFE',
  GYM = 'GYM',
  LAUNDRY = 'LAUNDRY',
  MEAL_DELIVERY = 'MEAL_DELIVERY',
  MEAL_TAKEAWAY = 'MEAL_TAKEAWAY',
  MOVIE_THEATER = 'MOVIE_THEATER',
  MUSEUM = 'MUSEUM',
  NIGHT_CLUB = 'NIGHT_CLUB',
  RESTAURANT = 'RESTAURANT',
  SPA = 'SPA',
  STORE = 'STORE',
  SUBWAY_STATION = 'SUBWAY_STATION',
  SUPERMARKET = 'SUPERMARKET'
}

export enum PlaceCategoryPersistency {
  ART_GALLERY = 'ART_GALLERY',
  BAR = 'BAR',
  BEAUTY_SALON = 'BEAUTY_SALON',
  CAFE = 'CAFE',
  GYM = 'GYM',
  LAUNDRY = 'LAUNDRY',
  MEAL_DELIVERY = 'MEAL_DELIVERY',
  MEAL_TAKEAWAY = 'MEAL_TAKEAWAY',
  MOVIE_THEATER = 'MOVIE_THEATER',
  MUSEUM = 'MUSEUM',
  NIGHT_CLUB = 'NIGHT_CLUB',
  RESTAURANT = 'RESTAURANT',
  SPA = 'SPA',
  STORE = 'STORE',
  SUBWAY_STATION = 'SUBWAY_STATION',
  SUPERMARKET = 'SUPERMARKET'
}

export type PlaceConnection = {
  __typename?: 'PlaceConnection';
  aggregate: AggregatePlace;
  edges: Array<Maybe<PlaceEdge>>;
  pageInfo: PageInfo;
};

export type PlaceCreateInput = {
  address?: InputMaybe<AddressCreateOneInput>;
  building?: InputMaybe<BuildingCreateOneInput>;
  category?: InputMaybe<PlaceCategory>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodCreateOneInput>;
  images?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  openingDate?: InputMaybe<Scalars['DateTime']>;
  openingHours?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  sharedSpace?: InputMaybe<SharedSpaceCreateOneInput>;
  type: PlaceTypes;
};

export type PlaceEdge = {
  __typename?: 'PlaceEdge';
  cursor: Scalars['String'];
  node: Place;
};

export enum PlaceOrderByInput {
  CATEGORY_ASC = 'category_ASC',
  CATEGORY_DESC = 'category_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  EXCERPT_ASC = 'excerpt_ASC',
  EXCERPT_DESC = 'excerpt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGES_ASC = 'images_ASC',
  IMAGES_DESC = 'images_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  OPENINGDATE_ASC = 'openingDate_ASC',
  OPENINGDATE_DESC = 'openingDate_DESC',
  OPENINGHOURS_ASC = 'openingHours_ASC',
  OPENINGHOURS_DESC = 'openingHours_DESC',
  PUBLISH_ASC = 'publish_ASC',
  PUBLISH_DESC = 'publish_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export enum PlaceTypes {
  COMMUNITY_BUILDING = 'COMMUNITY_BUILDING',
  EVENT = 'EVENT',
  LANDMARK = 'LANDMARK',
  LOCAL_BUSINESS = 'LOCAL_BUSINESS',
  SHARED_SPACE = 'SHARED_SPACE',
  SUBWAY = 'SUBWAY'
}

export enum PlaceTypesPersistency {
  COMMUNITY_BUILDING = 'COMMUNITY_BUILDING',
  EVENT = 'EVENT',
  LANDMARK = 'LANDMARK',
  LOCAL_BUSINESS = 'LOCAL_BUSINESS',
  SHARED_SPACE = 'SHARED_SPACE',
  SUBWAY = 'SUBWAY'
}

export type PlaceUpdateInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  building?: InputMaybe<BuildingUpdateOneInput>;
  category?: InputMaybe<PlaceCategory>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneInput>;
  images?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<Scalars['String']>;
  openingDate?: InputMaybe<Scalars['DateTime']>;
  openingHours?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  sharedSpace?: InputMaybe<SharedSpaceUpdateOneInput>;
  type?: InputMaybe<PlaceTypes>;
};

export type PlaceWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<PlaceWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<PlaceWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<PlaceWhereContractsPersistencyInput>>;
  address?: InputMaybe<AddressWhereContractsPersistencyInput>;
  building?: InputMaybe<BuildingWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  events_every?: InputMaybe<EventWhereContractsPersistencyInput>;
  events_none?: InputMaybe<EventWhereContractsPersistencyInput>;
  events_some?: InputMaybe<EventWhereContractsPersistencyInput>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingDate?: InputMaybe<Scalars['DateTime']>;
  openingDate_gt?: InputMaybe<Scalars['DateTime']>;
  openingDate_gte?: InputMaybe<Scalars['DateTime']>;
  openingDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  openingDate_lt?: InputMaybe<Scalars['DateTime']>;
  openingDate_lte?: InputMaybe<Scalars['DateTime']>;
  openingDate_not?: InputMaybe<Scalars['DateTime']>;
  openingDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  sharedSpace?: InputMaybe<SharedSpaceWhereContractsPersistencyInput>;
  type?: InputMaybe<ContractsPersistencyPlaceTypes>;
  type_in?: InputMaybe<Array<ContractsPersistencyPlaceTypes>>;
  type_not?: InputMaybe<ContractsPersistencyPlaceTypes>;
  type_not_in?: InputMaybe<Array<ContractsPersistencyPlaceTypes>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PlaceWhereInput = {
  AND?: InputMaybe<Array<PlaceWhereInput>>;
  NOT?: InputMaybe<Array<PlaceWhereInput>>;
  OR?: InputMaybe<Array<PlaceWhereInput>>;
  address?: InputMaybe<AddressWhereInput>;
  building?: InputMaybe<BuildingWhereInput>;
  category?: InputMaybe<PlaceCategory>;
  category_in?: InputMaybe<Array<PlaceCategory>>;
  category_not?: InputMaybe<PlaceCategory>;
  category_not_in?: InputMaybe<Array<PlaceCategory>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  excerpt_contains?: InputMaybe<Scalars['String']>;
  excerpt_ends_with?: InputMaybe<Scalars['String']>;
  excerpt_gt?: InputMaybe<Scalars['String']>;
  excerpt_gte?: InputMaybe<Scalars['String']>;
  excerpt_in?: InputMaybe<Array<Scalars['String']>>;
  excerpt_lt?: InputMaybe<Scalars['String']>;
  excerpt_lte?: InputMaybe<Scalars['String']>;
  excerpt_not?: InputMaybe<Scalars['String']>;
  excerpt_not_contains?: InputMaybe<Scalars['String']>;
  excerpt_not_ends_with?: InputMaybe<Scalars['String']>;
  excerpt_not_in?: InputMaybe<Array<Scalars['String']>>;
  excerpt_not_starts_with?: InputMaybe<Scalars['String']>;
  excerpt_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  nearBy?: InputMaybe<NearByInput>;
  openingDate?: InputMaybe<Scalars['DateTime']>;
  openingDate_gt?: InputMaybe<Scalars['DateTime']>;
  openingDate_gte?: InputMaybe<Scalars['DateTime']>;
  openingDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  openingDate_lt?: InputMaybe<Scalars['DateTime']>;
  openingDate_lte?: InputMaybe<Scalars['DateTime']>;
  openingDate_not?: InputMaybe<Scalars['DateTime']>;
  openingDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  sharedSpace?: InputMaybe<SharedSpaceWhereInput>;
  type?: InputMaybe<PlaceTypes>;
  type_in?: InputMaybe<Array<PlaceTypes>>;
  type_not?: InputMaybe<PlaceTypes>;
  type_not_in?: InputMaybe<Array<PlaceTypes>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PlaceWhereInputPersistency = {
  AND?: InputMaybe<Array<PlaceWhereInputPersistency>>;
  NOT?: InputMaybe<Array<PlaceWhereInputPersistency>>;
  OR?: InputMaybe<Array<PlaceWhereInputPersistency>>;
  address?: InputMaybe<AddressWhereInputPersistency>;
  building?: InputMaybe<BuildingWhereInputPersistency>;
  category?: InputMaybe<PlaceCategoryPersistency>;
  category_in?: InputMaybe<Array<PlaceCategoryPersistency>>;
  category_not?: InputMaybe<PlaceCategoryPersistency>;
  category_not_in?: InputMaybe<Array<PlaceCategoryPersistency>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  excerpt_contains?: InputMaybe<Scalars['String']>;
  excerpt_ends_with?: InputMaybe<Scalars['String']>;
  excerpt_gt?: InputMaybe<Scalars['String']>;
  excerpt_gte?: InputMaybe<Scalars['String']>;
  excerpt_in?: InputMaybe<Array<Scalars['String']>>;
  excerpt_lt?: InputMaybe<Scalars['String']>;
  excerpt_lte?: InputMaybe<Scalars['String']>;
  excerpt_not?: InputMaybe<Scalars['String']>;
  excerpt_not_contains?: InputMaybe<Scalars['String']>;
  excerpt_not_ends_with?: InputMaybe<Scalars['String']>;
  excerpt_not_in?: InputMaybe<Array<Scalars['String']>>;
  excerpt_not_starts_with?: InputMaybe<Scalars['String']>;
  excerpt_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWhereInputPersistency>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingDate?: InputMaybe<Scalars['DateTime']>;
  openingDate_gt?: InputMaybe<Scalars['DateTime']>;
  openingDate_gte?: InputMaybe<Scalars['DateTime']>;
  openingDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  openingDate_lt?: InputMaybe<Scalars['DateTime']>;
  openingDate_lte?: InputMaybe<Scalars['DateTime']>;
  openingDate_not?: InputMaybe<Scalars['DateTime']>;
  openingDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  sharedSpace?: InputMaybe<SharedSpaceWhereInputPersistency>;
  type?: InputMaybe<PlaceTypesPersistency>;
  type_in?: InputMaybe<Array<PlaceTypesPersistency>>;
  type_not?: InputMaybe<PlaceTypesPersistency>;
  type_not_in?: InputMaybe<Array<PlaceTypesPersistency>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PlaceWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<PlaceWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<PlaceWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<PlaceWherePersistencyContractsInput>>;
  address?: InputMaybe<AddressWherePersistencyContractsInput>;
  building?: InputMaybe<BuildingWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  events_every?: InputMaybe<EventWherePersistencyContractsInput>;
  events_none?: InputMaybe<EventWherePersistencyContractsInput>;
  events_some?: InputMaybe<EventWherePersistencyContractsInput>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingDate?: InputMaybe<Scalars['DateTime']>;
  openingDate_gt?: InputMaybe<Scalars['DateTime']>;
  openingDate_gte?: InputMaybe<Scalars['DateTime']>;
  openingDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  openingDate_lt?: InputMaybe<Scalars['DateTime']>;
  openingDate_lte?: InputMaybe<Scalars['DateTime']>;
  openingDate_not?: InputMaybe<Scalars['DateTime']>;
  openingDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  publish_not?: InputMaybe<Scalars['Boolean']>;
  sharedSpace?: InputMaybe<SharedSpaceWherePersistencyContractsInput>;
  type?: InputMaybe<PersistencyContractsPlaceTypes>;
  type_in?: InputMaybe<Array<PersistencyContractsPlaceTypes>>;
  type_not?: InputMaybe<PersistencyContractsPlaceTypes>;
  type_not_in?: InputMaybe<Array<PersistencyContractsPlaceTypes>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PlaceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PollCreateInput = {
  endDate: Scalars['DateTime'];
  options: Array<Scalars['String']>;
};

export type PollUpdateInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  options?: InputMaybe<Array<Scalars['String']>>;
};

export type Polygon = {
  __typename?: 'Polygon';
  coordinates?: Maybe<Array<Maybe<Coordinate>>>;
};

export type PolygonInput = {
  coordinates?: InputMaybe<Array<InputMaybe<CoordinatesInput>>>;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hoods?: Maybe<Array<Hood>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  subDomain?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type PortfolioHoodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodWhereInput>;
};

export type PortfolioConnection = {
  __typename?: 'PortfolioConnection';
  aggregate: AggregatePortfolio;
  edges: Array<Maybe<PortfolioEdge>>;
  pageInfo: PageInfo;
};

export type PortfolioCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoods?: InputMaybe<HoodCreateManyInput>;
  name: Scalars['String'];
  subDomain?: InputMaybe<Scalars['String']>;
};

export type PortfolioCreateOneInput = {
  connect?: InputMaybe<PortfolioWhereUniqueInput>;
  create?: InputMaybe<PortfolioCreateInput>;
};

export type PortfolioEdge = {
  __typename?: 'PortfolioEdge';
  cursor: Scalars['String'];
  node: Portfolio;
};

export enum PortfolioOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  SUBDOMAIN_ASC = 'subDomain_ASC',
  SUBDOMAIN_DESC = 'subDomain_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type PortfolioUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoods?: InputMaybe<HoodUpdateManyInput>;
  name?: InputMaybe<Scalars['String']>;
  subDomain?: InputMaybe<Scalars['String']>;
};

export type PortfolioUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoods?: InputMaybe<HoodUpdateManyInput>;
  name?: InputMaybe<Scalars['String']>;
  subDomain?: InputMaybe<Scalars['String']>;
};

export type PortfolioUpdateOneRequiredInput = {
  connect?: InputMaybe<PortfolioWhereUniqueInput>;
  create?: InputMaybe<PortfolioCreateInput>;
  update?: InputMaybe<PortfolioUpdateDataInput>;
  upsert?: InputMaybe<PortfolioUpsertNestedInput>;
};

export type PortfolioUpsertNestedInput = {
  create: PortfolioCreateInput;
  update: PortfolioUpdateDataInput;
};

export type PortfolioWhereInput = {
  AND?: InputMaybe<Array<PortfolioWhereInput>>;
  NOT?: InputMaybe<Array<PortfolioWhereInput>>;
  OR?: InputMaybe<Array<PortfolioWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hoods_every?: InputMaybe<HoodWhereInput>;
  hoods_none?: InputMaybe<HoodWhereInput>;
  hoods_some?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  subDomain?: InputMaybe<Scalars['String']>;
  subDomain_contains?: InputMaybe<Scalars['String']>;
  subDomain_ends_with?: InputMaybe<Scalars['String']>;
  subDomain_gt?: InputMaybe<Scalars['String']>;
  subDomain_gte?: InputMaybe<Scalars['String']>;
  subDomain_in?: InputMaybe<Array<Scalars['String']>>;
  subDomain_lt?: InputMaybe<Scalars['String']>;
  subDomain_lte?: InputMaybe<Scalars['String']>;
  subDomain_not?: InputMaybe<Scalars['String']>;
  subDomain_not_contains?: InputMaybe<Scalars['String']>;
  subDomain_not_ends_with?: InputMaybe<Scalars['String']>;
  subDomain_not_in?: InputMaybe<Array<Scalars['String']>>;
  subDomain_not_starts_with?: InputMaybe<Scalars['String']>;
  subDomain_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PortfolioWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum PriceUnitType {
  DAY = 'DAY',
  HOUR = 'HOUR',
  KG = 'KG',
  LB = 'LB',
  MONTH = 'MONTH',
  PERSON = 'PERSON',
  WEEK = 'WEEK',
  YEAR = 'YEAR'
}

export type PricedEventAttendee = {
  __typename?: 'PricedEventAttendee';
  attendee: EventAttendee;
  clientSecret?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood: Hood;
  id: Scalars['ID'];
  isAutoGenerated: Scalars['Boolean'];
  name: Scalars['String'];
  originalEntityId?: Maybe<Scalars['ID']>;
  originalEntityType?: Maybe<Scalars['String']>;
  productCategory: ProductCategory;
  status: ProductStatus;
  subProducts?: Maybe<Array<SubProduct>>;
  updatedAt: Scalars['DateTime'];
};


export type ProductSubProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SubProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubProductWhereInput>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  products?: Maybe<Array<Product>>;
  relevantProductTypes?: Maybe<Array<ProductType>>;
  updatedAt: Scalars['DateTime'];
};


export type ProductCategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type ProductCategoryRelevantProductTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductTypeWhereInput>;
};

export type ProductCategoryConnection = {
  __typename?: 'ProductCategoryConnection';
  aggregate: AggregateProductCategory;
  edges: Array<Maybe<ProductCategoryEdge>>;
  pageInfo: PageInfo;
};

export type ProductCategoryCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateManyWithoutProductCategoryInput>;
  relevantProductTypes?: InputMaybe<ProductTypeCreateManyWithoutProductCategoryInput>;
};

export type ProductCategoryCreateOneInput = {
  connect?: InputMaybe<ProductCategoryWhereUniqueInput>;
  create?: InputMaybe<ProductCategoryCreateInput>;
};

export type ProductCategoryCreateOneWithoutProductsInput = {
  connect?: InputMaybe<ProductCategoryWhereUniqueInput>;
  create?: InputMaybe<ProductCategoryCreateWithoutProductsInput>;
};

export type ProductCategoryCreateOneWithoutRelevantProductTypesInput = {
  connect?: InputMaybe<ProductCategoryWhereUniqueInput>;
  create?: InputMaybe<ProductCategoryCreateWithoutRelevantProductTypesInput>;
};

export type ProductCategoryCreateWithoutProductsInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  relevantProductTypes?: InputMaybe<ProductTypeCreateManyWithoutProductCategoryInput>;
};

export type ProductCategoryCreateWithoutRelevantProductTypesInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateManyWithoutProductCategoryInput>;
};

export type ProductCategoryEdge = {
  __typename?: 'ProductCategoryEdge';
  cursor: Scalars['String'];
  node: ProductCategory;
};

export enum ProductCategoryOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ProductCategoryUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductUpdateManyWithoutProductCategoryInput>;
  relevantProductTypes?: InputMaybe<ProductTypeUpdateManyWithoutProductCategoryInput>;
};

export type ProductCategoryUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductUpdateManyWithoutProductCategoryInput>;
  relevantProductTypes?: InputMaybe<ProductTypeUpdateManyWithoutProductCategoryInput>;
};

export type ProductCategoryUpdateOneRequiredInput = {
  connect?: InputMaybe<ProductCategoryWhereUniqueInput>;
  create?: InputMaybe<ProductCategoryCreateInput>;
  update?: InputMaybe<ProductCategoryUpdateDataInput>;
  upsert?: InputMaybe<ProductCategoryUpsertNestedInput>;
};

export type ProductCategoryUpdateOneRequiredWithoutProductsInput = {
  connect?: InputMaybe<ProductCategoryWhereUniqueInput>;
  create?: InputMaybe<ProductCategoryCreateWithoutProductsInput>;
  update?: InputMaybe<ProductCategoryUpdateWithoutProductsDataInput>;
  upsert?: InputMaybe<ProductCategoryUpsertWithoutProductsInput>;
};

export type ProductCategoryUpdateOneWithoutRelevantProductTypesInput = {
  connect?: InputMaybe<ProductCategoryWhereUniqueInput>;
  create?: InputMaybe<ProductCategoryCreateWithoutRelevantProductTypesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductCategoryUpdateWithoutRelevantProductTypesDataInput>;
  upsert?: InputMaybe<ProductCategoryUpsertWithoutRelevantProductTypesInput>;
};

export type ProductCategoryUpdateWithoutProductsDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  relevantProductTypes?: InputMaybe<ProductTypeUpdateManyWithoutProductCategoryInput>;
};

export type ProductCategoryUpdateWithoutRelevantProductTypesDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductUpdateManyWithoutProductCategoryInput>;
};

export type ProductCategoryUpsertNestedInput = {
  create: ProductCategoryCreateInput;
  update: ProductCategoryUpdateDataInput;
};

export type ProductCategoryUpsertWithoutProductsInput = {
  create: ProductCategoryCreateWithoutProductsInput;
  update: ProductCategoryUpdateWithoutProductsDataInput;
};

export type ProductCategoryUpsertWithoutRelevantProductTypesInput = {
  create: ProductCategoryCreateWithoutRelevantProductTypesInput;
  update: ProductCategoryUpdateWithoutRelevantProductTypesDataInput;
};

export type ProductCategoryWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<ProductCategoryWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<ProductCategoryWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<ProductCategoryWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWhereContractsPersistencyInput>;
  products_none?: InputMaybe<ProductWhereContractsPersistencyInput>;
  products_some?: InputMaybe<ProductWhereContractsPersistencyInput>;
  relevantProductTypes_every?: InputMaybe<ProductTypeWhereContractsPersistencyInput>;
  relevantProductTypes_none?: InputMaybe<ProductTypeWhereContractsPersistencyInput>;
  relevantProductTypes_some?: InputMaybe<ProductTypeWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductCategoryWhereInput = {
  AND?: InputMaybe<Array<ProductCategoryWhereInput>>;
  NOT?: InputMaybe<Array<ProductCategoryWhereInput>>;
  OR?: InputMaybe<Array<ProductCategoryWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  relevantProductTypes_every?: InputMaybe<ProductTypeWhereInput>;
  relevantProductTypes_none?: InputMaybe<ProductTypeWhereInput>;
  relevantProductTypes_some?: InputMaybe<ProductTypeWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductCategoryWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<ProductCategoryWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<ProductCategoryWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<ProductCategoryWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWherePersistencyContractsInput>;
  products_none?: InputMaybe<ProductWherePersistencyContractsInput>;
  products_some?: InputMaybe<ProductWherePersistencyContractsInput>;
  relevantProductTypes_every?: InputMaybe<ProductTypeWherePersistencyContractsInput>;
  relevantProductTypes_none?: InputMaybe<ProductTypeWherePersistencyContractsInput>;
  relevantProductTypes_some?: InputMaybe<ProductTypeWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  aggregate: AggregateProduct;
  edges: Array<Maybe<ProductEdge>>;
  pageInfo: PageInfo;
};

export type ProductCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood: HoodCreateOneInput;
  isAutoGenerated: Scalars['Boolean'];
  name: Scalars['String'];
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  productCategory: ProductCategoryCreateOneWithoutProductsInput;
  status?: InputMaybe<ProductStatus>;
  subProducts?: InputMaybe<SubProductCreateManyWithoutProductInput>;
};

export type ProductCreateManyWithoutProductCategoryInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutProductCategoryInput>>;
};

export type ProductCreateOneInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  create?: InputMaybe<ProductCreateInput>;
};

export type ProductCreateOneWithoutSubProductsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  create?: InputMaybe<ProductCreateWithoutSubProductsInput>;
};

export type ProductCreateWithoutProductCategoryInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood: HoodCreateOneInput;
  isAutoGenerated: Scalars['Boolean'];
  name: Scalars['String'];
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  subProducts?: InputMaybe<SubProductCreateManyWithoutProductInput>;
};

export type ProductCreateWithoutSubProductsInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood: HoodCreateOneInput;
  isAutoGenerated: Scalars['Boolean'];
  name: Scalars['String'];
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  productCategory: ProductCategoryCreateOneWithoutProductsInput;
  status?: InputMaybe<ProductStatus>;
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['String'];
  node: Product;
};

export enum ProductOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISAUTOGENERATED_ASC = 'isAutoGenerated_ASC',
  ISAUTOGENERATED_DESC = 'isAutoGenerated_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  ORIGINALENTITYID_ASC = 'originalEntityId_ASC',
  ORIGINALENTITYID_DESC = 'originalEntityId_DESC',
  ORIGINALENTITYTYPE_ASC = 'originalEntityType_ASC',
  ORIGINALENTITYTYPE_DESC = 'originalEntityType_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ProductScalarWhereInput = {
  AND?: InputMaybe<Array<ProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityId_contains?: InputMaybe<Scalars['ID']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_gt?: InputMaybe<Scalars['ID']>;
  originalEntityId_gte?: InputMaybe<Scalars['ID']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['ID']>>;
  originalEntityId_lt?: InputMaybe<Scalars['ID']>;
  originalEntityId_lte?: InputMaybe<Scalars['ID']>;
  originalEntityId_not?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  originalEntityType_contains?: InputMaybe<Scalars['String']>;
  originalEntityType_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityType_gt?: InputMaybe<Scalars['String']>;
  originalEntityType_gte?: InputMaybe<Scalars['String']>;
  originalEntityType_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityType_lt?: InputMaybe<Scalars['String']>;
  originalEntityType_lte?: InputMaybe<Scalars['String']>;
  originalEntityType_not?: InputMaybe<Scalars['String']>;
  originalEntityType_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityType_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityType_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityType_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityType_starts_with?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  status_in?: InputMaybe<Array<ProductStatus>>;
  status_not?: InputMaybe<ProductStatus>;
  status_not_in?: InputMaybe<Array<ProductStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE'
}

export type ProductType = {
  __typename?: 'ProductType';
  _empty?: Maybe<Scalars['String']>;
  allowManualTransaction?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  productCategory?: Maybe<ProductCategory>;
  sapIdentifier: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductTypeConnection = {
  __typename?: 'ProductTypeConnection';
  aggregate: AggregateProductType;
  edges: Array<Maybe<ProductTypeEdge>>;
  pageInfo: PageInfo;
};

export type ProductTypeCreateInput = {
  allowManualTransaction?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  productCategory?: InputMaybe<ProductCategoryCreateOneWithoutRelevantProductTypesInput>;
  sapIdentifier: Scalars['String'];
};

export type ProductTypeCreateManyWithoutProductCategoryInput = {
  connect?: InputMaybe<Array<ProductTypeWhereUniqueInput>>;
  create?: InputMaybe<Array<ProductTypeCreateWithoutProductCategoryInput>>;
};

export type ProductTypeCreateOneInput = {
  connect?: InputMaybe<ProductTypeWhereUniqueInput>;
  create?: InputMaybe<ProductTypeCreateInput>;
};

export type ProductTypeCreateWithoutProductCategoryInput = {
  allowManualTransaction?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  sapIdentifier: Scalars['String'];
};

export type ProductTypeEdge = {
  __typename?: 'ProductTypeEdge';
  cursor: Scalars['String'];
  node: ProductType;
};

export enum ProductTypeOrderByInput {
  ALLOWMANUALTRANSACTION_ASC = 'allowManualTransaction_ASC',
  ALLOWMANUALTRANSACTION_DESC = 'allowManualTransaction_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  SAPIDENTIFIER_ASC = 'sapIdentifier_ASC',
  SAPIDENTIFIER_DESC = 'sapIdentifier_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ProductTypeScalarWhereInput = {
  AND?: InputMaybe<Array<ProductTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductTypeScalarWhereInput>>;
  allowManualTransaction?: InputMaybe<Scalars['Boolean']>;
  allowManualTransaction_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
  sapIdentifier_contains?: InputMaybe<Scalars['String']>;
  sapIdentifier_ends_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_gt?: InputMaybe<Scalars['String']>;
  sapIdentifier_gte?: InputMaybe<Scalars['String']>;
  sapIdentifier_in?: InputMaybe<Array<Scalars['String']>>;
  sapIdentifier_lt?: InputMaybe<Scalars['String']>;
  sapIdentifier_lte?: InputMaybe<Scalars['String']>;
  sapIdentifier_not?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_contains?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_ends_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_in?: InputMaybe<Array<Scalars['String']>>;
  sapIdentifier_not_starts_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductTypeUpdateDataInput = {
  allowManualTransaction?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryUpdateOneWithoutRelevantProductTypesInput>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
};

export type ProductTypeUpdateInput = {
  allowManualTransaction?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryUpdateOneWithoutRelevantProductTypesInput>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
};

export type ProductTypeUpdateManyDataInput = {
  allowManualTransaction?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
};

export type ProductTypeUpdateManyWithWhereNestedInput = {
  data: ProductTypeUpdateManyDataInput;
  where: ProductTypeScalarWhereInput;
};

export type ProductTypeUpdateManyWithoutProductCategoryInput = {
  connect?: InputMaybe<Array<ProductTypeWhereUniqueInput>>;
  create?: InputMaybe<Array<ProductTypeCreateWithoutProductCategoryInput>>;
  delete?: InputMaybe<Array<ProductTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductTypeUpdateWithWhereUniqueWithoutProductCategoryInput>>;
  updateMany?: InputMaybe<Array<ProductTypeUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<ProductTypeUpsertWithWhereUniqueWithoutProductCategoryInput>>;
};

export type ProductTypeUpdateOneRequiredInput = {
  connect?: InputMaybe<ProductTypeWhereUniqueInput>;
  create?: InputMaybe<ProductTypeCreateInput>;
  update?: InputMaybe<ProductTypeUpdateDataInput>;
  upsert?: InputMaybe<ProductTypeUpsertNestedInput>;
};

export type ProductTypeUpdateWithWhereUniqueWithoutProductCategoryInput = {
  data: ProductTypeUpdateWithoutProductCategoryDataInput;
  where: ProductTypeWhereUniqueInput;
};

export type ProductTypeUpdateWithoutProductCategoryDataInput = {
  allowManualTransaction?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
};

export type ProductTypeUpsertNestedInput = {
  create: ProductTypeCreateInput;
  update: ProductTypeUpdateDataInput;
};

export type ProductTypeUpsertWithWhereUniqueWithoutProductCategoryInput = {
  create: ProductTypeCreateWithoutProductCategoryInput;
  update: ProductTypeUpdateWithoutProductCategoryDataInput;
  where: ProductTypeWhereUniqueInput;
};

export type ProductTypeWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<ProductTypeWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<ProductTypeWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<ProductTypeWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  productCategories_every?: InputMaybe<ProductCategoryWhereContractsPersistencyInput>;
  productCategories_none?: InputMaybe<ProductCategoryWhereContractsPersistencyInput>;
  productCategories_some?: InputMaybe<ProductCategoryWhereContractsPersistencyInput>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
  sapIdentifier_contains?: InputMaybe<Scalars['String']>;
  sapIdentifier_ends_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_gt?: InputMaybe<Scalars['String']>;
  sapIdentifier_gte?: InputMaybe<Scalars['String']>;
  sapIdentifier_in?: InputMaybe<Array<Scalars['String']>>;
  sapIdentifier_lt?: InputMaybe<Scalars['String']>;
  sapIdentifier_lte?: InputMaybe<Scalars['String']>;
  sapIdentifier_not?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_contains?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_ends_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_in?: InputMaybe<Array<Scalars['String']>>;
  sapIdentifier_not_starts_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductTypeWhereInput = {
  AND?: InputMaybe<Array<ProductTypeWhereInput>>;
  NOT?: InputMaybe<Array<ProductTypeWhereInput>>;
  OR?: InputMaybe<Array<ProductTypeWhereInput>>;
  allowManualTransaction?: InputMaybe<Scalars['Boolean']>;
  allowManualTransaction_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryWhereInput>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
  sapIdentifier_contains?: InputMaybe<Scalars['String']>;
  sapIdentifier_ends_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_gt?: InputMaybe<Scalars['String']>;
  sapIdentifier_gte?: InputMaybe<Scalars['String']>;
  sapIdentifier_in?: InputMaybe<Array<Scalars['String']>>;
  sapIdentifier_lt?: InputMaybe<Scalars['String']>;
  sapIdentifier_lte?: InputMaybe<Scalars['String']>;
  sapIdentifier_not?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_contains?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_ends_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_in?: InputMaybe<Array<Scalars['String']>>;
  sapIdentifier_not_starts_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductTypeWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<ProductTypeWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<ProductTypeWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<ProductTypeWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  productCategories_every?: InputMaybe<ProductCategoryWherePersistencyContractsInput>;
  productCategories_none?: InputMaybe<ProductCategoryWherePersistencyContractsInput>;
  productCategories_some?: InputMaybe<ProductCategoryWherePersistencyContractsInput>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
  sapIdentifier_contains?: InputMaybe<Scalars['String']>;
  sapIdentifier_ends_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_gt?: InputMaybe<Scalars['String']>;
  sapIdentifier_gte?: InputMaybe<Scalars['String']>;
  sapIdentifier_in?: InputMaybe<Array<Scalars['String']>>;
  sapIdentifier_lt?: InputMaybe<Scalars['String']>;
  sapIdentifier_lte?: InputMaybe<Scalars['String']>;
  sapIdentifier_not?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_contains?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_ends_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_not_in?: InputMaybe<Array<Scalars['String']>>;
  sapIdentifier_not_starts_with?: InputMaybe<Scalars['String']>;
  sapIdentifier_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  sapIdentifier?: InputMaybe<Scalars['String']>;
};

export type ProductUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryUpdateOneRequiredWithoutProductsInput>;
  status?: InputMaybe<ProductStatus>;
  subProducts?: InputMaybe<SubProductUpdateManyWithoutProductInput>;
};

export type ProductUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryUpdateOneRequiredWithoutProductsInput>;
  status?: InputMaybe<ProductStatus>;
  subProducts?: InputMaybe<SubProductUpdateManyWithoutProductInput>;
};

export type ProductUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
};

export type ProductUpdateManyWithWhereNestedInput = {
  data: ProductUpdateManyDataInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithoutProductCategoryInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutProductCategoryInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutProductCategoryInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutProductCategoryInput>>;
};

export type ProductUpdateOneInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  create?: InputMaybe<ProductCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductUpdateDataInput>;
  upsert?: InputMaybe<ProductUpsertNestedInput>;
};

export type ProductUpdateOneRequiredWithoutSubProductsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  create?: InputMaybe<ProductCreateWithoutSubProductsInput>;
  update?: InputMaybe<ProductUpdateWithoutSubProductsDataInput>;
  upsert?: InputMaybe<ProductUpsertWithoutSubProductsInput>;
};

export type ProductUpdateWithWhereUniqueWithoutProductCategoryInput = {
  data: ProductUpdateWithoutProductCategoryDataInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithoutProductCategoryDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  subProducts?: InputMaybe<SubProductUpdateManyWithoutProductInput>;
};

export type ProductUpdateWithoutSubProductsDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryUpdateOneRequiredWithoutProductsInput>;
  status?: InputMaybe<ProductStatus>;
};

export type ProductUpsertNestedInput = {
  create: ProductCreateInput;
  update: ProductUpdateDataInput;
};

export type ProductUpsertWithWhereUniqueWithoutProductCategoryInput = {
  create: ProductCreateWithoutProductCategoryInput;
  update: ProductUpdateWithoutProductCategoryDataInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutSubProductsInput = {
  create: ProductCreateWithoutSubProductsInput;
  update: ProductUpdateWithoutSubProductsDataInput;
};

export type ProductWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<ProductWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<ProductWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<ProductWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityId_contains?: InputMaybe<Scalars['ID']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_gt?: InputMaybe<Scalars['ID']>;
  originalEntityId_gte?: InputMaybe<Scalars['ID']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['ID']>>;
  originalEntityId_lt?: InputMaybe<Scalars['ID']>;
  originalEntityId_lte?: InputMaybe<Scalars['ID']>;
  originalEntityId_not?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  originalEntityType_contains?: InputMaybe<Scalars['String']>;
  originalEntityType_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityType_gt?: InputMaybe<Scalars['String']>;
  originalEntityType_gte?: InputMaybe<Scalars['String']>;
  originalEntityType_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityType_lt?: InputMaybe<Scalars['String']>;
  originalEntityType_lte?: InputMaybe<Scalars['String']>;
  originalEntityType_not?: InputMaybe<Scalars['String']>;
  originalEntityType_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityType_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityType_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityType_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityType_starts_with?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryWhereContractsPersistencyInput>;
  status?: InputMaybe<ContractsPersistencyProductStatus>;
  status_in?: InputMaybe<Array<ContractsPersistencyProductStatus>>;
  status_not?: InputMaybe<ContractsPersistencyProductStatus>;
  status_not_in?: InputMaybe<Array<ContractsPersistencyProductStatus>>;
  subProducts_every?: InputMaybe<SubProductWhereContractsPersistencyInput>;
  subProducts_none?: InputMaybe<SubProductWhereContractsPersistencyInput>;
  subProducts_some?: InputMaybe<SubProductWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityId_contains?: InputMaybe<Scalars['ID']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_gt?: InputMaybe<Scalars['ID']>;
  originalEntityId_gte?: InputMaybe<Scalars['ID']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['ID']>>;
  originalEntityId_lt?: InputMaybe<Scalars['ID']>;
  originalEntityId_lte?: InputMaybe<Scalars['ID']>;
  originalEntityId_not?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  originalEntityType_contains?: InputMaybe<Scalars['String']>;
  originalEntityType_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityType_gt?: InputMaybe<Scalars['String']>;
  originalEntityType_gte?: InputMaybe<Scalars['String']>;
  originalEntityType_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityType_lt?: InputMaybe<Scalars['String']>;
  originalEntityType_lte?: InputMaybe<Scalars['String']>;
  originalEntityType_not?: InputMaybe<Scalars['String']>;
  originalEntityType_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityType_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityType_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityType_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityType_starts_with?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryWhereInput>;
  status?: InputMaybe<ProductStatus>;
  status_in?: InputMaybe<Array<ProductStatus>>;
  status_not?: InputMaybe<ProductStatus>;
  status_not_in?: InputMaybe<Array<ProductStatus>>;
  subProducts_every?: InputMaybe<SubProductWhereInput>;
  subProducts_none?: InputMaybe<SubProductWhereInput>;
  subProducts_some?: InputMaybe<SubProductWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<ProductWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<ProductWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<ProductWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId?: InputMaybe<Scalars['ID']>;
  originalEntityId_contains?: InputMaybe<Scalars['ID']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_gt?: InputMaybe<Scalars['ID']>;
  originalEntityId_gte?: InputMaybe<Scalars['ID']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['ID']>>;
  originalEntityId_lt?: InputMaybe<Scalars['ID']>;
  originalEntityId_lte?: InputMaybe<Scalars['ID']>;
  originalEntityId_not?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityType?: InputMaybe<Scalars['String']>;
  originalEntityType_contains?: InputMaybe<Scalars['String']>;
  originalEntityType_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityType_gt?: InputMaybe<Scalars['String']>;
  originalEntityType_gte?: InputMaybe<Scalars['String']>;
  originalEntityType_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityType_lt?: InputMaybe<Scalars['String']>;
  originalEntityType_lte?: InputMaybe<Scalars['String']>;
  originalEntityType_not?: InputMaybe<Scalars['String']>;
  originalEntityType_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityType_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityType_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityType_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityType_starts_with?: InputMaybe<Scalars['String']>;
  productCategory?: InputMaybe<ProductCategoryWherePersistencyContractsInput>;
  status?: InputMaybe<PersistencyContractsProductStatus>;
  status_in?: InputMaybe<Array<PersistencyContractsProductStatus>>;
  status_not?: InputMaybe<PersistencyContractsProductStatus>;
  status_not_in?: InputMaybe<Array<PersistencyContractsProductStatus>>;
  subProducts_every?: InputMaybe<SubProductWherePersistencyContractsInput>;
  subProducts_none?: InputMaybe<SubProductWherePersistencyContractsInput>;
  subProducts_some?: InputMaybe<SubProductWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PropertyManagementOffice = {
  __typename?: 'PropertyManagementOffice';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  portfolio: Portfolio;
  updatedAt: Scalars['DateTime'];
};

export type PropertyManagementOfficeConnection = {
  __typename?: 'PropertyManagementOfficeConnection';
  aggregate: AggregatePropertyManagementOffice;
  edges: Array<Maybe<PropertyManagementOfficeEdge>>;
  pageInfo: PageInfo;
};

export type PropertyManagementOfficeCreateInput = {
  address?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  portfolio: PortfolioCreateOneInput;
};

export type PropertyManagementOfficeCreateOneInput = {
  connect?: InputMaybe<PropertyManagementOfficeWhereUniqueInput>;
  create?: InputMaybe<PropertyManagementOfficeCreateInput>;
};

export type PropertyManagementOfficeEdge = {
  __typename?: 'PropertyManagementOfficeEdge';
  cursor: Scalars['String'];
  node: PropertyManagementOffice;
};

export enum PropertyManagementOfficeOrderByInput {
  ADDRESS_ASC = 'address_ASC',
  ADDRESS_DESC = 'address_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  EMAIL_ASC = 'email_ASC',
  EMAIL_DESC = 'email_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISDEFAULT_ASC = 'isDefault_ASC',
  ISDEFAULT_DESC = 'isDefault_DESC',
  PHONE_ASC = 'phone_ASC',
  PHONE_DESC = 'phone_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type PropertyManagementOfficeUpdateDataInput = {
  address?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  portfolio?: InputMaybe<PortfolioUpdateOneRequiredInput>;
};

export type PropertyManagementOfficeUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  portfolio?: InputMaybe<PortfolioUpdateOneRequiredInput>;
};

export type PropertyManagementOfficeUpdateOneInput = {
  connect?: InputMaybe<PropertyManagementOfficeWhereUniqueInput>;
  create?: InputMaybe<PropertyManagementOfficeCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PropertyManagementOfficeUpdateDataInput>;
  upsert?: InputMaybe<PropertyManagementOfficeUpsertNestedInput>;
};

export type PropertyManagementOfficeUpsertNestedInput = {
  create: PropertyManagementOfficeCreateInput;
  update: PropertyManagementOfficeUpdateDataInput;
};

export type PropertyManagementOfficeWhereInput = {
  AND?: InputMaybe<Array<PropertyManagementOfficeWhereInput>>;
  NOT?: InputMaybe<Array<PropertyManagementOfficeWhereInput>>;
  OR?: InputMaybe<Array<PropertyManagementOfficeWhereInput>>;
  address?: InputMaybe<Scalars['String']>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_ends_with?: InputMaybe<Scalars['String']>;
  address_gt?: InputMaybe<Scalars['String']>;
  address_gte?: InputMaybe<Scalars['String']>;
  address_in?: InputMaybe<Array<Scalars['String']>>;
  address_lt?: InputMaybe<Scalars['String']>;
  address_lte?: InputMaybe<Scalars['String']>;
  address_not?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_ends_with?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<Scalars['String']>>;
  address_not_starts_with?: InputMaybe<Scalars['String']>;
  address_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_ends_with?: InputMaybe<Scalars['String']>;
  email_gt?: InputMaybe<Scalars['String']>;
  email_gte?: InputMaybe<Scalars['String']>;
  email_in?: InputMaybe<Array<Scalars['String']>>;
  email_lt?: InputMaybe<Scalars['String']>;
  email_lte?: InputMaybe<Scalars['String']>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_ends_with?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<Scalars['String']>>;
  email_not_starts_with?: InputMaybe<Scalars['String']>;
  email_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_contains?: InputMaybe<Scalars['String']>;
  phone_ends_with?: InputMaybe<Scalars['String']>;
  phone_gt?: InputMaybe<Scalars['String']>;
  phone_gte?: InputMaybe<Scalars['String']>;
  phone_in?: InputMaybe<Array<Scalars['String']>>;
  phone_lt?: InputMaybe<Scalars['String']>;
  phone_lte?: InputMaybe<Scalars['String']>;
  phone_not?: InputMaybe<Scalars['String']>;
  phone_not_contains?: InputMaybe<Scalars['String']>;
  phone_not_ends_with?: InputMaybe<Scalars['String']>;
  phone_not_in?: InputMaybe<Array<Scalars['String']>>;
  phone_not_starts_with?: InputMaybe<Scalars['String']>;
  phone_starts_with?: InputMaybe<Scalars['String']>;
  portfolio?: InputMaybe<PortfolioWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type PropertyManagementOfficeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PushDispatchConfig = {
  __typename?: 'PushDispatchConfig';
  dayOfMonthToSend?: Maybe<Scalars['Int']>;
  isForBillOfNextMonth?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationSettings = {
  __typename?: 'PushNotificationSettings';
  billingProviderName?: Maybe<Scalars['String']>;
  dispatchConfig?: Maybe<Array<Maybe<PushDispatchConfig>>>;
  enabled: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  _billsSkipAuthAndPagination?: Maybe<Array<Bill>>;
  address?: Maybe<Address>;
  addresses: Array<Address>;
  addressesConnection: AddressConnection;
  albertDog?: Maybe<AlbertDog>;
  albertDogs: Array<AlbertDog>;
  albertDogsConnection: AlbertDogConnection;
  allFeatures: Array<Feature>;
  application?: Maybe<Application>;
  applications: Array<Application>;
  applicationsConnection: ApplicationConnection;
  audit?: Maybe<Audit>;
  audits: Array<Audit>;
  auditsConnection: AuditConnection;
  bedroom?: Maybe<Bedroom>;
  bedrooms: Array<Bedroom>;
  bedroomsConnection: BedroomConnection;
  bill?: Maybe<Bill>;
  billableItem?: Maybe<BillableItem>;
  billableItems: Array<BillableItem>;
  billableItemsConnection: BillableItemConnection;
  bills: Array<Bill>;
  billsConnection: BillConnection;
  blockingMemberProfiles: BlockingUsers;
  building?: Maybe<Building>;
  buildingSetting?: Maybe<BuildingSetting>;
  buildingSettings: Array<BuildingSetting>;
  buildings: Array<Building>;
  buildingsConnection: BuildingConnection;
  calculateTransactionFee?: Maybe<CalculateTransactionFeeResponse>;
  calculateTransferFee?: Maybe<CalculateTransferFeeResponse>;
  calendar?: Maybe<Calendar>;
  calendarBooking?: Maybe<CalendarBooking>;
  calendarBookings: Array<CalendarBooking>;
  calendarBookingsConnection: CalendarBookingConnection;
  calendars: Array<Calendar>;
  calendarsConnection: CalendarConnection;
  checkIfTimeSlotsAvailable: CheckIfTimeSlotsAvailableResult;
  comment?: Maybe<Comment>;
  comments: Array<Comment>;
  commentsConnection: CommentConnection;
  communityTitle?: Maybe<CommunityTitle>;
  communityTitles: Array<CommunityTitle>;
  communityTitlesConnection: CommunityTitleConnection;
  currencies: Array<Currency>;
  currenciesConnection: CurrencyConnection;
  currency?: Maybe<Currency>;
  deliveryPackage?: Maybe<DeliveryPackage>;
  deliveryPackages: Array<DeliveryPackage>;
  deliveryPackagesConnection: DeliveryPackageConnection;
  event?: Maybe<Event>;
  eventAttendee?: Maybe<EventAttendee>;
  eventAttendees: Array<EventAttendee>;
  eventAttendeesConnection: EventAttendeeConnection;
  events: Array<Event>;
  eventsConnection: EventConnection;
  extendedEntities?: Maybe<Array<Maybe<ExtendedEntity>>>;
  externalBill?: Maybe<ExternalBill>;
  externalBillReceipt?: Maybe<ExternalBillReceipt>;
  externalBillReceipts: Array<ExternalBillReceipt>;
  externalBills: Array<ExternalBill>;
  externalBillsConnection: ExternalBillConnection;
  externalReceipt?: Maybe<ExternalReceipt>;
  externalReceipts: Array<ExternalReceipt>;
  externalReceiptsConnection: ExternalReceiptConnection;
  feature?: Maybe<Feature>;
  featureAnnouncement?: Maybe<FeatureAnnouncement>;
  featureAnnouncements: Array<FeatureAnnouncement>;
  featureAnnouncementsConnection: FeatureAnnouncementConnection;
  featuredSlide?: Maybe<FeaturedSlide>;
  featuredSlides: Array<FeaturedSlide>;
  featuredSlidesConnection: FeaturedSlideConnection;
  featuredSlidesSearchSuggestions?: Maybe<Array<Maybe<Scalars['String']>>>;
  features: Array<Feature>;
  featuresConnection: FeatureConnection;
  feedClassification?: Maybe<FeedClassification>;
  feedClassifications: Array<FeedClassification>;
  feedClassificationsConnection: FeedClassificationConnection;
  fetchCsatSurveys?: Maybe<FetchCsatSurveysResponse>;
  findMemberProfiles?: Maybe<Array<Maybe<MemberProfile>>>;
  findNeighbors?: Maybe<FindNeighborsQueryOutput>;
  getTimeSlotsForDates?: Maybe<Array<Maybe<CalendarTimeSlot>>>;
  groupEventAttendeesByUserIdAndStatus: Array<EventAttendeesGroupby>;
  hood?: Maybe<Hood>;
  hoodSetting?: Maybe<HoodSetting>;
  hoodSettingValue?: Maybe<HoodSettingValue>;
  hoodSettingValues: Array<HoodSettingValue>;
  hoodSettingValuesConnection: HoodSettingValueConnection;
  hoodSettings: Array<HoodSetting>;
  hoodSettingsConnection: HoodSettingConnection;
  hoodSettingsGroup?: Maybe<HoodSettingsGroup>;
  hoodSettingsGroups: Array<HoodSettingsGroup>;
  hoodSettingsGroupsConnection: HoodSettingsGroupConnection;
  hoods: Array<Hood>;
  hoodsConnection: HoodConnection;
  interestGroup?: Maybe<InterestGroup>;
  interestGroups?: Maybe<Array<Maybe<InterestGroup>>>;
  interestGroupsConnection?: Maybe<InterestGroupsConnection>;
  invoice?: Maybe<Invoice>;
  invoices: Array<Invoice>;
  invoicesConnection: InvoiceConnection;
  leaseContract?: Maybe<LeaseContract>;
  leaseContracts: Array<LeaseContract>;
  leaseContractsConnection: LeaseContractConnection;
  member?: Maybe<Member>;
  memberAddress?: Maybe<MemberAddress>;
  memberAddresses: Array<MemberAddress>;
  memberAddressesConnection: MemberAddressConnection;
  memberPaymentOsCustomer?: Maybe<MemberPaymentOsCustomer>;
  memberPaymentOsCustomers: Array<MemberPaymentOsCustomer>;
  memberProfile?: Maybe<MemberProfile>;
  memberProfileAddressField?: Maybe<MemberProfileAddressField>;
  memberProfileAddressFields: Array<MemberProfileAddressField>;
  memberProfileAddressFieldsConnection: MemberProfileAddressFieldConnection;
  memberProfileBlock?: Maybe<MemberProfileBlock>;
  memberProfileBlocks: Array<MemberProfileBlock>;
  memberProfileBlocksConnection: MemberProfileBlockConnection;
  memberProfileCallToActionField?: Maybe<MemberProfileCallToActionField>;
  memberProfileCallToActionFields: Array<MemberProfileCallToActionField>;
  memberProfileCallToActionFieldsConnection: MemberProfileCallToActionFieldConnection;
  memberProfileDateField?: Maybe<MemberProfileDateField>;
  memberProfileDateFields: Array<MemberProfileDateField>;
  memberProfileDateFieldsConnection: MemberProfileDateFieldConnection;
  memberProfilePetsField?: Maybe<MemberProfilePetsField>;
  memberProfilePetsFields: Array<MemberProfilePetsField>;
  memberProfilePetsFieldsConnection: MemberProfilePetsFieldConnection;
  memberProfilePronounsField?: Maybe<MemberProfilePronounsField>;
  memberProfilePronounsFields: Array<MemberProfilePronounsField>;
  memberProfilePronounsFieldsConnection: MemberProfilePronounsFieldConnection;
  memberProfileSocialLinksField?: Maybe<MemberProfileSocialLinksField>;
  memberProfileSocialLinksFields: Array<MemberProfileSocialLinksField>;
  memberProfileSocialLinksFieldsConnection: MemberProfileSocialLinksFieldConnection;
  memberProfileSocialTagsField?: Maybe<MemberProfileSocialTagsField>;
  memberProfileSocialTagsFields: Array<MemberProfileSocialTagsField>;
  memberProfileSocialTagsFieldsConnection: MemberProfileSocialTagsFieldConnection;
  memberProfileStringField?: Maybe<MemberProfileStringField>;
  memberProfileStringFields: Array<MemberProfileStringField>;
  memberProfileStringFieldsConnection: MemberProfileStringFieldConnection;
  memberProfiles: Array<MemberProfile>;
  memberProfilesConnection: MemberProfileConnection;
  members: Array<Member>;
  membersConnection: MemberConnection;
  membershipContract?: Maybe<MembershipContract>;
  membershipContracts: Array<MembershipContract>;
  membershipContractsConnection: MembershipContractConnection;
  mobileAppPage?: Maybe<MobileAppPage>;
  mobileAppPages: Array<MobileAppPage>;
  mobileAppPagesConnection: MobileAppPageConnection;
  momentsFeed?: Maybe<Array<Maybe<SocialMoment>>>;
  myBookedTimeSlots?: Maybe<Array<Maybe<CalendarBooking>>>;
  myOrders: Array<MyOrdersResult>;
  paymentProvider?: Maybe<PaymentProvider>;
  paymentProviders: Array<PaymentProvider>;
  paymentProvidersConnection: PaymentProviderConnection;
  paymentReceiptEmail?: Maybe<PaymentReceiptEmail>;
  paymentReceiptEmails: Array<PaymentReceiptEmail>;
  paymentReceiptEmailsConnection: PaymentReceiptEmailConnection;
  paymentRequest?: Maybe<PaymentRequest>;
  paymentRequestEmail?: Maybe<PaymentRequestEmail>;
  paymentRequestEmails: Array<PaymentRequestEmail>;
  paymentRequestEmailsConnection: PaymentRequestEmailConnection;
  paymentRequests: Array<PaymentRequest>;
  paymentRequestsConnection: PaymentRequestConnection;
  paymentSetting?: Maybe<PaymentSetting>;
  paymentSettings: Array<PaymentSetting>;
  paymentSettingsConnection: PaymentSettingConnection;
  paymentTransaction?: Maybe<PaymentTransaction>;
  paymentTransactions: Array<PaymentTransaction>;
  paymentTransactionsConnection: PaymentTransactionConnection;
  payoutPlan?: Maybe<PayoutPlan>;
  payoutPlans: Array<PayoutPlan>;
  payoutPlansConnection: PayoutPlanConnection;
  pet?: Maybe<Pet>;
  pets: Array<Pet>;
  petsConnection: PetConnection;
  place?: Maybe<Place>;
  places: Array<Place>;
  placesConnection: PlaceConnection;
  portfolio?: Maybe<Portfolio>;
  portfolios: Array<Portfolio>;
  portfoliosConnection: PortfolioConnection;
  product?: Maybe<Product>;
  productCategories: Array<ProductCategory>;
  productCategoriesConnection: ProductCategoryConnection;
  productCategory?: Maybe<ProductCategory>;
  productType?: Maybe<ProductType>;
  productTypes: Array<ProductType>;
  productTypesConnection: ProductTypeConnection;
  products: Array<Product>;
  productsConnection: ProductConnection;
  propertyManagementOffice?: Maybe<PropertyManagementOffice>;
  propertyManagementOffices: Array<PropertyManagementOffice>;
  propertyManagementOfficesConnection: PropertyManagementOfficeConnection;
  role?: Maybe<Role>;
  roles: Array<Role>;
  rolesConnection: RoleConnection;
  searchFeaturedSlides?: Maybe<Array<Maybe<FeaturedSlide>>>;
  searchSocialMoments?: Maybe<Array<Maybe<SocialMoment>>>;
  service?: Maybe<Service>;
  serviceCategories: Array<ServiceCategory>;
  serviceCategoriesConnection: ServiceCategoryConnection;
  serviceCategory?: Maybe<ServiceCategory>;
  serviceProduct?: Maybe<ServiceProduct>;
  serviceProductOrder?: Maybe<ServiceProductOrder>;
  serviceProductOrders: Array<ServiceProductOrder>;
  serviceProductOrdersConnection: ServiceProductOrderConnection;
  serviceProducts: Array<ServiceProduct>;
  serviceProductsConnection: ServiceProductConnection;
  services: Array<Service>;
  servicesConnection: ServiceConnection;
  sharedSpace?: Maybe<SharedSpace>;
  sharedSpaces: Array<SharedSpace>;
  sharedSpacesConnection: SharedSpaceConnection;
  socialInteraction?: Maybe<SocialInteraction>;
  socialInteractions: Array<SocialInteraction>;
  socialInteractionsConnection: SocialInteractionConnection;
  socialMoment?: Maybe<SocialMoment>;
  socialMomentSearchSuggestions?: Maybe<Array<Maybe<Scalars['String']>>>;
  socialMomentSentiment?: Maybe<SocialMomentSentiment>;
  socialMomentSentiments: Array<SocialMomentSentiment>;
  socialMomentSentimentsConnection: SocialMomentSentimentConnection;
  socialMomentView?: Maybe<SocialMomentView>;
  socialMomentViews: Array<SocialMomentView>;
  socialMomentViewsConnection: SocialMomentViewConnection;
  socialMoments: Array<SocialMoment>;
  socialMomentsConnection: SocialMomentConnection;
  socialReaction?: Maybe<SocialReaction>;
  socialReactions: Array<SocialReaction>;
  socialReactionsConnection: SocialReactionConnection;
  socialTag?: Maybe<SocialTag>;
  socialTags: Array<SocialTag>;
  socialTagsConnection: SocialTagConnection;
  subProduct?: Maybe<SubProduct>;
  subProducts: Array<SubProduct>;
  subProductsConnection: SubProductConnection;
  subscriptionContract?: Maybe<SubscriptionContract>;
  subscriptionContracts: Array<SubscriptionContract>;
  subscriptionContractsConnection: SubscriptionContractConnection;
  survey?: Maybe<Survey>;
  surveyQuestion?: Maybe<SurveyQuestion>;
  surveyQuestionResponse?: Maybe<SurveyQuestionResponse>;
  surveyQuestionResponses: Array<SurveyQuestionResponse>;
  surveyQuestionResponsesConnection: SurveyQuestionResponseConnection;
  surveyQuestions: Array<SurveyQuestion>;
  surveyQuestionsConnection: SurveyQuestionConnection;
  surveyResult?: Maybe<SurveyResult>;
  surveyResults: Array<SurveyResult>;
  surveyResultsConnection: SurveyResultConnection;
  surveyUserAction?: Maybe<SurveyUserAction>;
  surveyUserActions: Array<SurveyUserAction>;
  surveyUserActionsConnection: SurveyUserActionConnection;
  surveys: Array<Survey>;
  surveysConnection: SurveyConnection;
  taxRate?: Maybe<TaxRate>;
  taxRates: Array<TaxRate>;
  taxRatesConnection: TaxRateConnection;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  transactionsConnection: TransactionConnection;
  treasureItem?: Maybe<TreasureItem>;
  treasureItems: Array<TreasureItem>;
  treasureItemsConnection: TreasureItemConnection;
  unit?: Maybe<Unit>;
  unitStatusLog?: Maybe<UnitStatusLog>;
  unitStatusLogs: Array<UnitStatusLog>;
  unitStatusLogsConnection: UnitStatusLogConnection;
  units: Array<Unit>;
  unitsConnection: UnitConnection;
  user?: Maybe<User>;
  userApiKey?: Maybe<UserApiKey>;
  userApiKeys: Array<UserApiKey>;
  userApiKeysConnection: UserApiKeyConnection;
  userFeatureFlags: Array<FeatureFlag>;
  userPaymentMethod?: Maybe<UserPaymentMethod>;
  userPaymentMethods: Array<UserPaymentMethod>;
  userPaymentMethodsConnection: UserPaymentMethodConnection;
  users: Array<User>;
  usersConnection: UserConnection;
  warmupFeatureService?: Maybe<Scalars['Boolean']>;
  warmupPersistency?: Maybe<Scalars['String']>;
};


export type QueryBillsSkipAuthAndPaginationArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BillOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BillWhereInput>;
};


export type QueryAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AddressOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAddressesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AddressOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAlbertDogArgs = {
  where: AlbertDogWhereUniqueInput;
};


export type QueryAlbertDogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AlbertDogOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AlbertDogWhereInput>;
};


export type QueryAlbertDogsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AlbertDogOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AlbertDogWhereInput>;
};


export type QueryAllFeaturesArgs = {
  orderBy?: InputMaybe<FeatureOrderByInput>;
  where?: InputMaybe<FeatureWhereInput>;
};


export type QueryApplicationArgs = {
  where: ApplicationWhereUniqueInput;
};


export type QueryApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ApplicationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ApplicationWhereInput>;
};


export type QueryApplicationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ApplicationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ApplicationWhereInput>;
};


export type QueryAuditArgs = {
  where: AuditWhereUniqueInput;
};


export type QueryAuditsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuditOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditWhereInput>;
};


export type QueryAuditsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuditOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditWhereInput>;
};


export type QueryBedroomArgs = {
  where: BedroomWhereUniqueInput;
};


export type QueryBedroomsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BedroomOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BedroomWhereInput>;
};


export type QueryBedroomsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BedroomOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BedroomWhereInput>;
};


export type QueryBillArgs = {
  where: BillWhereUniqueInput;
};


export type QueryBillableItemArgs = {
  where: BillableItemWhereUniqueInput;
};


export type QueryBillableItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BillableItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BillableItemWhereInput>;
};


export type QueryBillableItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BillableItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BillableItemWhereInput>;
};


export type QueryBillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BillOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BillWhereInput>;
};


export type QueryBillsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BillOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BillWhereInput>;
};


export type QueryBuildingArgs = {
  where: BuildingWhereUniqueInput;
};


export type QueryBuildingSettingArgs = {
  where: BuildingSettingWhereUniqueInput;
};


export type QueryBuildingSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BuildingSettingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingSettingWhereInput>;
};


export type QueryBuildingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BuildingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingWhereInput>;
};


export type QueryBuildingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BuildingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingWhereInput>;
};


export type QueryCalculateTransactionFeeArgs = {
  data: CalculateTransactionFeeInput;
};


export type QueryCalculateTransferFeeArgs = {
  data: CalculateTransferFeeInput;
};


export type QueryCalendarArgs = {
  where: CalendarWhereUniqueInput;
};


export type QueryCalendarBookingArgs = {
  where: CalendarBookingWhereUniqueInput;
};


export type QueryCalendarBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CalendarBookingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarBookingWhereInput>;
};


export type QueryCalendarBookingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CalendarBookingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarBookingWhereInput>;
};


export type QueryCalendarsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CalendarOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarWhereInput>;
};


export type QueryCalendarsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CalendarOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarWhereInput>;
};


export type QueryCheckIfTimeSlotsAvailableArgs = {
  resource?: InputMaybe<CalendarBookableInput>;
  slots: Array<CalendarTimeSlotInput>;
};


export type QueryCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryCommentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryCommunityTitleArgs = {
  where: CommunityTitleWhereUniqueInput;
};


export type QueryCommunityTitlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommunityTitleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityTitleWhereInput>;
};


export type QueryCommunityTitlesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommunityTitleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityTitleWhereInput>;
};


export type QueryCurrenciesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CurrencyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CurrencyWhereInput>;
};


export type QueryCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CurrencyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CurrencyWhereInput>;
};


export type QueryCurrencyArgs = {
  where: CurrencyWhereUniqueInput;
};


export type QueryDeliveryPackageArgs = {
  where: DeliveryPackageWhereUniqueInput;
};


export type QueryDeliveryPackagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DeliveryPackageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeliveryPackageWhereInput>;
};


export type QueryDeliveryPackagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DeliveryPackageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeliveryPackageWhereInput>;
};


export type QueryEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryEventAttendeeArgs = {
  where: EventAttendeeWhereUniqueInput;
};


export type QueryEventAttendeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EventAttendeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventAttendeeWhereInput>;
};


export type QueryEventAttendeesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EventAttendeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventAttendeeWhereInput>;
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EventOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EventOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryExternalBillArgs = {
  where: ExternalBillWhereUniqueInput;
};


export type QueryExternalBillReceiptArgs = {
  where: ExternalBillReceiptWhereUniqueInput;
};


export type QueryExternalBillReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalBillReceiptOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalBillReceiptWhereInput>;
};


export type QueryExternalBillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalBillOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalBillWhereInput>;
};


export type QueryExternalBillsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalBillOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalBillWhereInput>;
};


export type QueryExternalReceiptArgs = {
  where: ExternalReceiptWhereUniqueInput;
};


export type QueryExternalReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalReceiptOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalReceiptWhereInput>;
};


export type QueryExternalReceiptsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalReceiptOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalReceiptWhereInput>;
};


export type QueryFeatureArgs = {
  where: FeatureWhereUniqueInput;
};


export type QueryFeatureAnnouncementArgs = {
  where: FeatureAnnouncementWhereUniqueInput;
};


export type QueryFeatureAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeatureAnnouncementOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeatureAnnouncementWhereInput>;
};


export type QueryFeatureAnnouncementsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeatureAnnouncementOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeatureAnnouncementWhereInput>;
};


export type QueryFeaturedSlideArgs = {
  where: FeaturedSlideWhereUniqueInput;
};


export type QueryFeaturedSlidesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeaturedSlideOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeaturedSlideWhereInput>;
};


export type QueryFeaturedSlidesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeaturedSlideOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeaturedSlideWhereInput>;
};


export type QueryFeaturedSlidesSearchSuggestionsArgs = {
  data: SearchFeaturedSlidesInput;
};


export type QueryFeaturesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeatureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeatureWhereInput>;
};


export type QueryFeaturesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeatureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeatureWhereInput>;
};


export type QueryFeedClassificationArgs = {
  where: FeedClassificationWhereUniqueInput;
};


export type QueryFeedClassificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeedClassificationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedClassificationWhereInput>;
};


export type QueryFeedClassificationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FeedClassificationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedClassificationWhereInput>;
};


export type QueryFetchCsatSurveysArgs = {
  where?: InputMaybe<FetchCsatSurveysInput>;
};


export type QueryFindMemberProfilesArgs = {
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  where: QueryMemberProfileFindWhereInput;
};


export type QueryFindNeighborsArgs = {
  first: Scalars['Int'];
  seed?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTimeSlotsForDatesArgs = {
  endTime: Scalars['DateTime'];
  resource: CalendarBookableInput;
  startTime: Scalars['DateTime'];
};


export type QueryGroupEventAttendeesByUserIdAndStatusArgs = {
  where?: InputMaybe<EventAttendeeWhereInput>;
};


export type QueryHoodArgs = {
  where: HoodWhereUniqueInput;
};


export type QueryHoodSettingArgs = {
  where: HoodSettingWhereUniqueInput;
};


export type QueryHoodSettingValueArgs = {
  where: HoodSettingValueWhereUniqueInput;
};


export type QueryHoodSettingValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingValueOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingValueWhereInput>;
};


export type QueryHoodSettingValuesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingValueOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingValueWhereInput>;
};


export type QueryHoodSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingWhereInput>;
};


export type QueryHoodSettingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingWhereInput>;
};


export type QueryHoodSettingsGroupArgs = {
  where: HoodSettingsGroupWhereUniqueInput;
};


export type QueryHoodSettingsGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingsGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingsGroupWhereInput>;
};


export type QueryHoodSettingsGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodSettingsGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodSettingsGroupWhereInput>;
};


export type QueryHoodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodWhereInput>;
};


export type QueryHoodsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HoodOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HoodWhereInput>;
};


export type QueryInterestGroupArgs = {
  skipCache?: InputMaybe<Scalars['Boolean']>;
  where: QueryInterestGroupUniqueWhereInput;
  withMembers?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInterestGroupsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  skipCache?: InputMaybe<Scalars['Boolean']>;
  where: QueryInterestGroupFindWhereInput;
  withMembers?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInterestGroupsConnectionArgs = {
  where: QueryInterestGroupFindWhereInput;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InvoiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryInvoicesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InvoiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryLeaseContractArgs = {
  where: LeaseContractWhereUniqueInput;
};


export type QueryLeaseContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LeaseContractOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeaseContractWhereInput>;
};


export type QueryLeaseContractsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LeaseContractOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeaseContractWhereInput>;
};


export type QueryMemberArgs = {
  where: MemberWhereUniqueInput;
};


export type QueryMemberAddressArgs = {
  where: MemberAddressWhereUniqueInput;
};


export type QueryMemberAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberAddressOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberAddressWhereInput>;
};


export type QueryMemberAddressesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberAddressOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberAddressWhereInput>;
};


export type QueryMemberPaymentOsCustomerArgs = {
  where: MemberPaymentOsCustomerWhereUniqueInput;
};


export type QueryMemberPaymentOsCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberPaymentOsCustomerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberPaymentOsCustomerWhereInput>;
};


export type QueryMemberProfileArgs = {
  where: MemberProfileWhereUniqueInput;
};


export type QueryMemberProfileAddressFieldArgs = {
  where: MemberProfileAddressFieldWhereUniqueInput;
};


export type QueryMemberProfileAddressFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileAddressFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileAddressFieldWhereInput>;
};


export type QueryMemberProfileAddressFieldsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileAddressFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileAddressFieldWhereInput>;
};


export type QueryMemberProfileBlockArgs = {
  where: MemberProfileBlockWhereUniqueInput;
};


export type QueryMemberProfileBlocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileBlockWhereInput>;
};


export type QueryMemberProfileBlocksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileBlockWhereInput>;
};


export type QueryMemberProfileCallToActionFieldArgs = {
  where: MemberProfileCallToActionFieldWhereUniqueInput;
};


export type QueryMemberProfileCallToActionFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileCallToActionFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileCallToActionFieldWhereInput>;
};


export type QueryMemberProfileCallToActionFieldsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileCallToActionFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileCallToActionFieldWhereInput>;
};


export type QueryMemberProfileDateFieldArgs = {
  where: MemberProfileDateFieldWhereUniqueInput;
};


export type QueryMemberProfileDateFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileDateFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileDateFieldWhereInput>;
};


export type QueryMemberProfileDateFieldsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileDateFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileDateFieldWhereInput>;
};


export type QueryMemberProfilePetsFieldArgs = {
  where: MemberProfilePetsFieldWhereUniqueInput;
};


export type QueryMemberProfilePetsFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfilePetsFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfilePetsFieldWhereInput>;
};


export type QueryMemberProfilePetsFieldsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfilePetsFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfilePetsFieldWhereInput>;
};


export type QueryMemberProfilePronounsFieldArgs = {
  where: MemberProfilePronounsFieldWhereUniqueInput;
};


export type QueryMemberProfilePronounsFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfilePronounsFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfilePronounsFieldWhereInput>;
};


export type QueryMemberProfilePronounsFieldsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfilePronounsFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfilePronounsFieldWhereInput>;
};


export type QueryMemberProfileSocialLinksFieldArgs = {
  where: MemberProfileSocialLinksFieldWhereUniqueInput;
};


export type QueryMemberProfileSocialLinksFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileSocialLinksFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileSocialLinksFieldWhereInput>;
};


export type QueryMemberProfileSocialLinksFieldsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileSocialLinksFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileSocialLinksFieldWhereInput>;
};


export type QueryMemberProfileSocialTagsFieldArgs = {
  where: MemberProfileSocialTagsFieldWhereUniqueInput;
};


export type QueryMemberProfileSocialTagsFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileSocialTagsFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileSocialTagsFieldWhereInput>;
};


export type QueryMemberProfileSocialTagsFieldsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileSocialTagsFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileSocialTagsFieldWhereInput>;
};


export type QueryMemberProfileStringFieldArgs = {
  where: MemberProfileStringFieldWhereUniqueInput;
};


export type QueryMemberProfileStringFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileStringFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileStringFieldWhereInput>;
};


export type QueryMemberProfileStringFieldsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileStringFieldOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileStringFieldWhereInput>;
};


export type QueryMemberProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileWhereInput>;
};


export type QueryMemberProfilesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberProfileWhereInput>;
};


export type QueryMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberWhereInput>;
};


export type QueryMembersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MemberOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberWhereInput>;
};


export type QueryMembershipContractArgs = {
  where: MembershipContractWhereUniqueInput;
};


export type QueryMembershipContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MembershipContractOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MembershipContractWhereInput>;
};


export type QueryMembershipContractsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MembershipContractOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MembershipContractWhereInput>;
};


export type QueryMobileAppPageArgs = {
  where: MobileAppPageWhereUniqueInput;
};


export type QueryMobileAppPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MobileAppPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MobileAppPageWhereInput>;
};


export type QueryMobileAppPagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MobileAppPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MobileAppPageWhereInput>;
};


export type QueryMomentsFeedArgs = {
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  where: MomentsFeedInput;
};


export type QueryMyBookedTimeSlotsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  resource: CalendarBookableInput;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryMyOrdersArgs = {
  firstServiceProductOrders?: InputMaybe<Scalars['Int']>;
  firstSubscriptionContracts?: InputMaybe<Scalars['Int']>;
  skipServiceProductOrders?: InputMaybe<Scalars['Int']>;
  skipSubscriptionContracts?: InputMaybe<Scalars['Int']>;
  where: MyOrdersWhereInput;
};


export type QueryPaymentProviderArgs = {
  where: PaymentProviderWhereUniqueInput;
};


export type QueryPaymentProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentProviderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentProviderWhereInput>;
};


export type QueryPaymentProvidersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentProviderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentProviderWhereInput>;
};


export type QueryPaymentReceiptEmailArgs = {
  where: PaymentReceiptEmailWhereUniqueInput;
};


export type QueryPaymentReceiptEmailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentReceiptEmailOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentReceiptEmailWhereInput>;
};


export type QueryPaymentReceiptEmailsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentReceiptEmailOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentReceiptEmailWhereInput>;
};


export type QueryPaymentRequestArgs = {
  where: PaymentRequestWhereUniqueInput;
};


export type QueryPaymentRequestEmailArgs = {
  where: PaymentRequestEmailWhereUniqueInput;
};


export type QueryPaymentRequestEmailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentRequestEmailOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentRequestEmailWhereInput>;
};


export type QueryPaymentRequestEmailsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentRequestEmailOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentRequestEmailWhereInput>;
};


export type QueryPaymentRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentRequestOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentRequestWhereInput>;
};


export type QueryPaymentRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentRequestOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentRequestWhereInput>;
};


export type QueryPaymentSettingArgs = {
  where: PaymentSettingWhereUniqueInput;
};


export type QueryPaymentSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentSettingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentSettingWhereInput>;
};


export type QueryPaymentSettingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentSettingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentSettingWhereInput>;
};


export type QueryPaymentTransactionArgs = {
  where: PaymentTransactionWhereUniqueInput;
};


export type QueryPaymentTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentTransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};


export type QueryPaymentTransactionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PaymentTransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};


export type QueryPayoutPlanArgs = {
  where: PayoutPlanWhereUniqueInput;
};


export type QueryPayoutPlansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PayoutPlanOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayoutPlanWhereInput>;
};


export type QueryPayoutPlansConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PayoutPlanOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayoutPlanWhereInput>;
};


export type QueryPetArgs = {
  where: PetWhereUniqueInput;
};


export type QueryPetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PetWhereInput>;
};


export type QueryPetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PetWhereInput>;
};


export type QueryPlaceArgs = {
  where: PlaceWhereUniqueInput;
};


export type QueryPlacesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PlaceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaceWhereInput>;
};


export type QueryPlacesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PlaceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaceWhereInput>;
};


export type QueryPortfolioArgs = {
  where: PortfolioWhereUniqueInput;
};


export type QueryPortfoliosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PortfolioOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PortfolioWhereInput>;
};


export type QueryPortfoliosConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PortfolioOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PortfolioWhereInput>;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductCategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductCategoryWhereInput>;
};


export type QueryProductCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductCategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductCategoryWhereInput>;
};


export type QueryProductCategoryArgs = {
  where: ProductCategoryWhereUniqueInput;
};


export type QueryProductTypeArgs = {
  where: ProductTypeWhereUniqueInput;
};


export type QueryProductTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductTypeWhereInput>;
};


export type QueryProductTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductTypeWhereInput>;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryPropertyManagementOfficeArgs = {
  where: PropertyManagementOfficeWhereUniqueInput;
};


export type QueryPropertyManagementOfficesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PropertyManagementOfficeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PropertyManagementOfficeWhereInput>;
};


export type QueryPropertyManagementOfficesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PropertyManagementOfficeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PropertyManagementOfficeWhereInput>;
};


export type QueryRoleArgs = {
  where: RoleWhereUniqueInput;
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RoleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleWhereInput>;
};


export type QueryRolesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RoleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleWhereInput>;
};


export type QuerySearchFeaturedSlidesArgs = {
  data: SearchFeaturedSlidesInput;
};


export type QuerySearchSocialMomentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  hoodName: Scalars['String'];
  q: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type QueryServiceCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceCategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceCategoryWhereInput>;
};


export type QueryServiceCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceCategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceCategoryWhereInput>;
};


export type QueryServiceCategoryArgs = {
  where: ServiceCategoryWhereUniqueInput;
};


export type QueryServiceProductArgs = {
  where: ServiceProductWhereUniqueInput;
};


export type QueryServiceProductOrderArgs = {
  where: ServiceProductOrderWhereUniqueInput;
};


export type QueryServiceProductOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceProductOrderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceProductOrderWhereInput>;
};


export type QueryServiceProductOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceProductOrderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceProductOrderWhereInput>;
};


export type QueryServiceProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceProductWhereInput>;
};


export type QueryServiceProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceProductWhereInput>;
};


export type QueryServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceWhereInput>;
};


export type QueryServicesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceWhereInput>;
};


export type QuerySharedSpaceArgs = {
  where: SharedSpaceWhereUniqueInput;
};


export type QuerySharedSpacesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SharedSpaceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SharedSpaceWhereInput>;
};


export type QuerySharedSpacesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SharedSpaceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SharedSpaceWhereInput>;
};


export type QuerySocialInteractionArgs = {
  where: SocialInteractionWhereUniqueInput;
};


export type QuerySocialInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialInteractionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialInteractionWhereInput>;
};


export type QuerySocialInteractionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialInteractionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialInteractionWhereInput>;
};


export type QuerySocialMomentArgs = {
  where: SocialMomentWhereUniqueInput;
};


export type QuerySocialMomentSearchSuggestionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  hoodName: Scalars['String'];
  q: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
};


export type QuerySocialMomentSentimentArgs = {
  where: SocialMomentSentimentWhereUniqueInput;
};


export type QuerySocialMomentSentimentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialMomentSentimentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialMomentSentimentWhereInput>;
};


export type QuerySocialMomentSentimentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialMomentSentimentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialMomentSentimentWhereInput>;
};


export type QuerySocialMomentViewArgs = {
  where: SocialMomentViewWhereUniqueInput;
};


export type QuerySocialMomentViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialMomentViewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialMomentViewWhereInput>;
};


export type QuerySocialMomentViewsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialMomentViewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialMomentViewWhereInput>;
};


export type QuerySocialMomentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialMomentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialMomentWhereInput>;
};


export type QuerySocialMomentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialMomentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialMomentWhereInput>;
};


export type QuerySocialReactionArgs = {
  where: SocialReactionWhereUniqueInput;
};


export type QuerySocialReactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialReactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialReactionWhereInput>;
};


export type QuerySocialReactionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialReactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialReactionWhereInput>;
};


export type QuerySocialTagArgs = {
  where: SocialTagWhereUniqueInput;
};


export type QuerySocialTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialTagOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialTagWhereInput>;
};


export type QuerySocialTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SocialTagOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialTagWhereInput>;
};


export type QuerySubProductArgs = {
  where: SubProductWhereUniqueInput;
};


export type QuerySubProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SubProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubProductWhereInput>;
};


export type QuerySubProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SubProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubProductWhereInput>;
};


export type QuerySubscriptionContractArgs = {
  where: SubscriptionContractWhereUniqueInput;
};


export type QuerySubscriptionContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SubscriptionContractOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionContractWhereInput>;
};


export type QuerySubscriptionContractsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SubscriptionContractOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionContractWhereInput>;
};


export type QuerySurveyArgs = {
  where: SurveyWhereUniqueInput;
};


export type QuerySurveyQuestionArgs = {
  where: SurveyQuestionWhereUniqueInput;
};


export type QuerySurveyQuestionResponseArgs = {
  where: SurveyQuestionResponseWhereUniqueInput;
};


export type QuerySurveyQuestionResponsesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyQuestionResponseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyQuestionResponseWhereInput>;
};


export type QuerySurveyQuestionResponsesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyQuestionResponseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyQuestionResponseWhereInput>;
};


export type QuerySurveyQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyQuestionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyQuestionWhereInput>;
};


export type QuerySurveyQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyQuestionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyQuestionWhereInput>;
};


export type QuerySurveyResultArgs = {
  where: SurveyResultWhereUniqueInput;
};


export type QuerySurveyResultsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyResultOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyResultWhereInput>;
};


export type QuerySurveyResultsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyResultOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyResultWhereInput>;
};


export type QuerySurveyUserActionArgs = {
  where: SurveyUserActionWhereUniqueInput;
};


export type QuerySurveyUserActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyUserActionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyUserActionWhereInput>;
};


export type QuerySurveyUserActionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyUserActionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyUserActionWhereInput>;
};


export type QuerySurveysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyWhereInput>;
};


export type QuerySurveysConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyWhereInput>;
};


export type QueryTaxRateArgs = {
  where: TaxRateWhereUniqueInput;
};


export type QueryTaxRatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TaxRateOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaxRateWhereInput>;
};


export type QueryTaxRatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TaxRateOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaxRateWhereInput>;
};


export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryTransactionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryTreasureItemArgs = {
  where: TreasureItemWhereUniqueInput;
};


export type QueryTreasureItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TreasureItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TreasureItemWhereInput>;
};


export type QueryTreasureItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TreasureItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TreasureItemWhereInput>;
};


export type QueryUnitArgs = {
  where: UnitWhereUniqueInput;
};


export type QueryUnitStatusLogArgs = {
  where: UnitStatusLogWhereUniqueInput;
};


export type QueryUnitStatusLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnitStatusLogOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitStatusLogWhereInput>;
};


export type QueryUnitStatusLogsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnitStatusLogOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitStatusLogWhereInput>;
};


export type QueryUnitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnitOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitWhereInput>;
};


export type QueryUnitsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnitOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUserApiKeyArgs = {
  where: UserApiKeyWhereUniqueInput;
};


export type QueryUserApiKeysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserApiKeyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserApiKeyWhereInput>;
};


export type QueryUserApiKeysConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserApiKeyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserApiKeyWhereInput>;
};


export type QueryUserFeatureFlagsArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryUserPaymentMethodArgs = {
  where: UserPaymentMethodWhereUniqueInput;
};


export type QueryUserPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserPaymentMethodOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPaymentMethodWhereInput>;
};


export type QueryUserPaymentMethodsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserPaymentMethodOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPaymentMethodWhereInput>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryInterestGroupFindArgs = {
  where: QueryInterestGroupFindWhereInput;
};

export type QueryInterestGroupFindWhereInput = {
  buildingId?: InputMaybe<Scalars['String']>;
  hoodId: Scalars['ID'];
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
  includePrivate?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInterestGroupUniqueWhereInput = {
  id: Scalars['ID'];
};

export type QueryMemberProfileFindArgs = {
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  where: QueryMemberProfileFindWhereInput;
};

export type QueryMemberProfileFindWhereInput = {
  hoodId: Scalars['ID'];
  keyword?: InputMaybe<Scalars['String']>;
};

export enum RenewalIntentStatus {
  NOT_RELEVANT = 'NOT_RELEVANT',
  NOT_RENEWING = 'NOT_RENEWING',
  NOT_SURE = 'NOT_SURE',
  NO_RESPONSE = 'NO_RESPONSE',
  PENDING = 'PENDING',
  RENEWING = 'RENEWING',
  TRANSFER = 'TRANSFER'
}

export enum RenewalStatus {
  NOT_RELEVANT = 'NOT_RELEVANT',
  NOT_RENEWABLE = 'NOT_RENEWABLE',
  NOT_RENEWED = 'NOT_RENEWED',
  RENEWED = 'RENEWED',
  TRANSFERRED = 'TRANSFERRED'
}

export enum RentingType {
  BY_BEDROOM = 'BY_BEDROOM',
  WHOLE_UNIT = 'WHOLE_UNIT'
}

export enum RentingTypePersistency {
  BY_BEDROOM = 'BY_BEDROOM',
  WHOLE_UNIT = 'WHOLE_UNIT'
}

export enum ResidentialStatus {
  CANCELLED = 'Cancelled',
  EVICTED = 'Evicted',
  LIVING = 'Living',
  MOVEIN = 'MoveIn',
  MOVEDOUT = 'MovedOut',
  NOTRELEVANT = 'NotRelevant',
  PENDINGMOVEIN = 'PendingMoveIn'
}

export type RevertContractsResult = {
  __typename?: 'RevertContractsResult';
  /** @deprecated Will be replaced by real status */
  dummy?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood?: Maybe<Hood>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
};


export type RoleUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  aggregate: AggregateRole;
  edges: Array<Maybe<RoleEdge>>;
  pageInfo: PageInfo;
};

export type RoleCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodCreateOneInput>;
  name: Scalars['String'];
  users?: InputMaybe<UserCreateManyWithoutRolesInput>;
};

export type RoleCreateManyWithoutUsersInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutUsersInput>>;
};

export type RoleCreateWithoutUsersInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodCreateOneInput>;
  name: Scalars['String'];
};

export type RoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String'];
  node: Role;
};

export enum RoleOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type RoleScalarWhereInput = {
  AND?: InputMaybe<Array<RoleScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoleScalarWhereInput>>;
  OR?: InputMaybe<Array<RoleScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type RoleUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneInput>;
  name?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<UserUpdateManyWithoutRolesInput>;
};

export type RoleUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type RoleUpdateManyWithWhereNestedInput = {
  data: RoleUpdateManyDataInput;
  where: RoleScalarWhereInput;
};

export type RoleUpdateManyWithoutUsersInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<RoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleWhereUniqueInput>>;
  update?: InputMaybe<Array<RoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<RoleUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<RoleUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type RoleUpdateWithWhereUniqueWithoutUsersInput = {
  data: RoleUpdateWithoutUsersDataInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpdateWithoutUsersDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type RoleUpsertWithWhereUniqueWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  update: RoleUpdateWithoutUsersDataInput;
  where: RoleWhereUniqueInput;
};

export type RoleWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<RoleWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<RoleWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<RoleWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  users_every?: InputMaybe<UserWhereContractsPersistencyInput>;
  users_none?: InputMaybe<UserWhereContractsPersistencyInput>;
  users_some?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  users_every?: InputMaybe<UserWhereInput>;
  users_none?: InputMaybe<UserWhereInput>;
  users_some?: InputMaybe<UserWhereInput>;
};

export type RoleWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<RoleWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<RoleWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<RoleWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  users_every?: InputMaybe<UserWherePersistencyContractsInput>;
  users_none?: InputMaybe<UserWherePersistencyContractsInput>;
  users_some?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type RoleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SmsNotificationSettings = {
  __typename?: 'SMSNotificationSettings';
  dispatchConfig?: Maybe<Array<Maybe<DispatchConfig>>>;
  enabled: Scalars['Boolean'];
};

export type SecurityDepositSettings = {
  __typename?: 'SecurityDepositSettings';
  amount?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
};

export type SelfCreateApplicationInput = {
  bedroomId?: InputMaybe<Scalars['String']>;
  unitId: Scalars['String'];
};

export enum Sentiment {
  MIXED = 'MIXED',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE'
}

export type Service = {
  __typename?: 'Service';
  cancellationPolicy?: Maybe<Scalars['String']>;
  category: ServiceCategory;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  fromPrice?: Maybe<Scalars['Float']>;
  hood?: Maybe<Hood>;
  hoodName: Scalars['String'];
  id: Scalars['ID'];
  images?: Maybe<Scalars['Json']>;
  isCancellationPolicyEnabled?: Maybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: Maybe<Scalars['Boolean']>;
  isVennOperated?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Place>;
  locationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  products?: Maybe<Array<ServiceProduct>>;
  providerName: Scalars['String'];
  published?: Maybe<Scalars['Boolean']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type ServiceProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceProductWhereInput>;
};

export type ServiceCallWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<ServiceCallWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<ServiceCallWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<ServiceCallWhereContractsPersistencyInput>>;
  additionalContactDetails?: InputMaybe<Scalars['String']>;
  additionalContactDetails_contains?: InputMaybe<Scalars['String']>;
  additionalContactDetails_ends_with?: InputMaybe<Scalars['String']>;
  additionalContactDetails_gt?: InputMaybe<Scalars['String']>;
  additionalContactDetails_gte?: InputMaybe<Scalars['String']>;
  additionalContactDetails_in?: InputMaybe<Array<Scalars['String']>>;
  additionalContactDetails_lt?: InputMaybe<Scalars['String']>;
  additionalContactDetails_lte?: InputMaybe<Scalars['String']>;
  additionalContactDetails_not?: InputMaybe<Scalars['String']>;
  additionalContactDetails_not_contains?: InputMaybe<Scalars['String']>;
  additionalContactDetails_not_ends_with?: InputMaybe<Scalars['String']>;
  additionalContactDetails_not_in?: InputMaybe<Array<Scalars['String']>>;
  additionalContactDetails_not_starts_with?: InputMaybe<Scalars['String']>;
  additionalContactDetails_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  service?: InputMaybe<ServiceWhereContractsPersistencyInput>;
  serviceDate?: InputMaybe<Scalars['DateTime']>;
  serviceDate_gt?: InputMaybe<Scalars['DateTime']>;
  serviceDate_gte?: InputMaybe<Scalars['DateTime']>;
  serviceDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  serviceDate_lt?: InputMaybe<Scalars['DateTime']>;
  serviceDate_lte?: InputMaybe<Scalars['DateTime']>;
  serviceDate_not?: InputMaybe<Scalars['DateTime']>;
  serviceDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  sharedSpace?: InputMaybe<SharedSpaceWhereContractsPersistencyInput>;
  status?: InputMaybe<ContractsPersistencyServiceCallStatus>;
  status_in?: InputMaybe<Array<ContractsPersistencyServiceCallStatus>>;
  status_not?: InputMaybe<ContractsPersistencyServiceCallStatus>;
  status_not_in?: InputMaybe<Array<ContractsPersistencyServiceCallStatus>>;
  storagePrefix?: InputMaybe<Scalars['String']>;
  storagePrefix_contains?: InputMaybe<Scalars['String']>;
  storagePrefix_ends_with?: InputMaybe<Scalars['String']>;
  storagePrefix_gt?: InputMaybe<Scalars['String']>;
  storagePrefix_gte?: InputMaybe<Scalars['String']>;
  storagePrefix_in?: InputMaybe<Array<Scalars['String']>>;
  storagePrefix_lt?: InputMaybe<Scalars['String']>;
  storagePrefix_lte?: InputMaybe<Scalars['String']>;
  storagePrefix_not?: InputMaybe<Scalars['String']>;
  storagePrefix_not_contains?: InputMaybe<Scalars['String']>;
  storagePrefix_not_ends_with?: InputMaybe<Scalars['String']>;
  storagePrefix_not_in?: InputMaybe<Array<Scalars['String']>>;
  storagePrefix_not_starts_with?: InputMaybe<Scalars['String']>;
  storagePrefix_starts_with?: InputMaybe<Scalars['String']>;
  timeFrameEnd?: InputMaybe<Scalars['String']>;
  timeFrameEnd_contains?: InputMaybe<Scalars['String']>;
  timeFrameEnd_ends_with?: InputMaybe<Scalars['String']>;
  timeFrameEnd_gt?: InputMaybe<Scalars['String']>;
  timeFrameEnd_gte?: InputMaybe<Scalars['String']>;
  timeFrameEnd_in?: InputMaybe<Array<Scalars['String']>>;
  timeFrameEnd_lt?: InputMaybe<Scalars['String']>;
  timeFrameEnd_lte?: InputMaybe<Scalars['String']>;
  timeFrameEnd_not?: InputMaybe<Scalars['String']>;
  timeFrameEnd_not_contains?: InputMaybe<Scalars['String']>;
  timeFrameEnd_not_ends_with?: InputMaybe<Scalars['String']>;
  timeFrameEnd_not_in?: InputMaybe<Array<Scalars['String']>>;
  timeFrameEnd_not_starts_with?: InputMaybe<Scalars['String']>;
  timeFrameEnd_starts_with?: InputMaybe<Scalars['String']>;
  timeFrameStart?: InputMaybe<Scalars['String']>;
  timeFrameStart_contains?: InputMaybe<Scalars['String']>;
  timeFrameStart_ends_with?: InputMaybe<Scalars['String']>;
  timeFrameStart_gt?: InputMaybe<Scalars['String']>;
  timeFrameStart_gte?: InputMaybe<Scalars['String']>;
  timeFrameStart_in?: InputMaybe<Array<Scalars['String']>>;
  timeFrameStart_lt?: InputMaybe<Scalars['String']>;
  timeFrameStart_lte?: InputMaybe<Scalars['String']>;
  timeFrameStart_not?: InputMaybe<Scalars['String']>;
  timeFrameStart_not_contains?: InputMaybe<Scalars['String']>;
  timeFrameStart_not_ends_with?: InputMaybe<Scalars['String']>;
  timeFrameStart_not_in?: InputMaybe<Array<Scalars['String']>>;
  timeFrameStart_not_starts_with?: InputMaybe<Scalars['String']>;
  timeFrameStart_starts_with?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<UnitWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type ServiceCallWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<ServiceCallWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<ServiceCallWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<ServiceCallWherePersistencyContractsInput>>;
  additionalContactDetails?: InputMaybe<Scalars['String']>;
  additionalContactDetails_contains?: InputMaybe<Scalars['String']>;
  additionalContactDetails_ends_with?: InputMaybe<Scalars['String']>;
  additionalContactDetails_gt?: InputMaybe<Scalars['String']>;
  additionalContactDetails_gte?: InputMaybe<Scalars['String']>;
  additionalContactDetails_in?: InputMaybe<Array<Scalars['String']>>;
  additionalContactDetails_lt?: InputMaybe<Scalars['String']>;
  additionalContactDetails_lte?: InputMaybe<Scalars['String']>;
  additionalContactDetails_not?: InputMaybe<Scalars['String']>;
  additionalContactDetails_not_contains?: InputMaybe<Scalars['String']>;
  additionalContactDetails_not_ends_with?: InputMaybe<Scalars['String']>;
  additionalContactDetails_not_in?: InputMaybe<Array<Scalars['String']>>;
  additionalContactDetails_not_starts_with?: InputMaybe<Scalars['String']>;
  additionalContactDetails_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  service?: InputMaybe<ServiceWherePersistencyContractsInput>;
  serviceDate?: InputMaybe<Scalars['DateTime']>;
  serviceDate_gt?: InputMaybe<Scalars['DateTime']>;
  serviceDate_gte?: InputMaybe<Scalars['DateTime']>;
  serviceDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  serviceDate_lt?: InputMaybe<Scalars['DateTime']>;
  serviceDate_lte?: InputMaybe<Scalars['DateTime']>;
  serviceDate_not?: InputMaybe<Scalars['DateTime']>;
  serviceDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  sharedSpace?: InputMaybe<SharedSpaceWherePersistencyContractsInput>;
  status?: InputMaybe<PersistencyContractsServiceCallStatus>;
  status_in?: InputMaybe<Array<PersistencyContractsServiceCallStatus>>;
  status_not?: InputMaybe<PersistencyContractsServiceCallStatus>;
  status_not_in?: InputMaybe<Array<PersistencyContractsServiceCallStatus>>;
  storagePrefix?: InputMaybe<Scalars['String']>;
  storagePrefix_contains?: InputMaybe<Scalars['String']>;
  storagePrefix_ends_with?: InputMaybe<Scalars['String']>;
  storagePrefix_gt?: InputMaybe<Scalars['String']>;
  storagePrefix_gte?: InputMaybe<Scalars['String']>;
  storagePrefix_in?: InputMaybe<Array<Scalars['String']>>;
  storagePrefix_lt?: InputMaybe<Scalars['String']>;
  storagePrefix_lte?: InputMaybe<Scalars['String']>;
  storagePrefix_not?: InputMaybe<Scalars['String']>;
  storagePrefix_not_contains?: InputMaybe<Scalars['String']>;
  storagePrefix_not_ends_with?: InputMaybe<Scalars['String']>;
  storagePrefix_not_in?: InputMaybe<Array<Scalars['String']>>;
  storagePrefix_not_starts_with?: InputMaybe<Scalars['String']>;
  storagePrefix_starts_with?: InputMaybe<Scalars['String']>;
  timeFrameEnd?: InputMaybe<Scalars['String']>;
  timeFrameEnd_contains?: InputMaybe<Scalars['String']>;
  timeFrameEnd_ends_with?: InputMaybe<Scalars['String']>;
  timeFrameEnd_gt?: InputMaybe<Scalars['String']>;
  timeFrameEnd_gte?: InputMaybe<Scalars['String']>;
  timeFrameEnd_in?: InputMaybe<Array<Scalars['String']>>;
  timeFrameEnd_lt?: InputMaybe<Scalars['String']>;
  timeFrameEnd_lte?: InputMaybe<Scalars['String']>;
  timeFrameEnd_not?: InputMaybe<Scalars['String']>;
  timeFrameEnd_not_contains?: InputMaybe<Scalars['String']>;
  timeFrameEnd_not_ends_with?: InputMaybe<Scalars['String']>;
  timeFrameEnd_not_in?: InputMaybe<Array<Scalars['String']>>;
  timeFrameEnd_not_starts_with?: InputMaybe<Scalars['String']>;
  timeFrameEnd_starts_with?: InputMaybe<Scalars['String']>;
  timeFrameStart?: InputMaybe<Scalars['String']>;
  timeFrameStart_contains?: InputMaybe<Scalars['String']>;
  timeFrameStart_ends_with?: InputMaybe<Scalars['String']>;
  timeFrameStart_gt?: InputMaybe<Scalars['String']>;
  timeFrameStart_gte?: InputMaybe<Scalars['String']>;
  timeFrameStart_in?: InputMaybe<Array<Scalars['String']>>;
  timeFrameStart_lt?: InputMaybe<Scalars['String']>;
  timeFrameStart_lte?: InputMaybe<Scalars['String']>;
  timeFrameStart_not?: InputMaybe<Scalars['String']>;
  timeFrameStart_not_contains?: InputMaybe<Scalars['String']>;
  timeFrameStart_not_ends_with?: InputMaybe<Scalars['String']>;
  timeFrameStart_not_in?: InputMaybe<Array<Scalars['String']>>;
  timeFrameStart_not_starts_with?: InputMaybe<Scalars['String']>;
  timeFrameStart_starts_with?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<UnitWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  clientVisibleName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood?: Maybe<Hood>;
  hoodName: Scalars['String'];
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Int']>;
  services?: Maybe<Array<Service>>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ServiceCategoryServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ServiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceCategoryConnection = {
  __typename?: 'ServiceCategoryConnection';
  aggregate: AggregateServiceCategory;
  edges: Array<Maybe<ServiceCategoryEdge>>;
  pageInfo: PageInfo;
};

export type ServiceCategoryCreateInput = {
  clientVisibleName: Scalars['String'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  services?: InputMaybe<ServiceCreateManyWithoutCategoryInput>;
  type: Scalars['String'];
};

export type ServiceCategoryCreateOneWithoutServicesInput = {
  connect?: InputMaybe<ServiceCategoryWhereUniqueInput>;
  create?: InputMaybe<ServiceCategoryCreateWithoutServicesInput>;
};

export type ServiceCategoryCreateWithoutServicesInput = {
  clientVisibleName: Scalars['String'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type ServiceCategoryEdge = {
  __typename?: 'ServiceCategoryEdge';
  cursor: Scalars['String'];
  node: ServiceCategory;
};

export enum ServiceCategoryOrderByInput {
  CLIENTVISIBLENAME_ASC = 'clientVisibleName_ASC',
  CLIENTVISIBLENAME_DESC = 'clientVisibleName_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  HOODNAME_ASC = 'hoodName_ASC',
  HOODNAME_DESC = 'hoodName_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PRIORITY_ASC = 'priority_ASC',
  PRIORITY_DESC = 'priority_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ServiceCategoryUpdateInput = {
  clientVisibleName?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  services?: InputMaybe<ServiceUpdateManyWithoutCategoryInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type ServiceCategoryUpdateOneRequiredWithoutServicesInput = {
  connect?: InputMaybe<ServiceCategoryWhereUniqueInput>;
  create?: InputMaybe<ServiceCategoryCreateWithoutServicesInput>;
  update?: InputMaybe<ServiceCategoryUpdateWithoutServicesDataInput>;
  upsert?: InputMaybe<ServiceCategoryUpsertWithoutServicesInput>;
};

export type ServiceCategoryUpdateWithoutServicesDataInput = {
  clientVisibleName?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ServiceCategoryUpsertWithoutServicesInput = {
  create: ServiceCategoryCreateWithoutServicesInput;
  update: ServiceCategoryUpdateWithoutServicesDataInput;
};

export type ServiceCategoryWhereInput = {
  AND?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  NOT?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  OR?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  clientVisibleName?: InputMaybe<Scalars['String']>;
  clientVisibleName_contains?: InputMaybe<Scalars['String']>;
  clientVisibleName_ends_with?: InputMaybe<Scalars['String']>;
  clientVisibleName_gt?: InputMaybe<Scalars['String']>;
  clientVisibleName_gte?: InputMaybe<Scalars['String']>;
  clientVisibleName_in?: InputMaybe<Array<Scalars['String']>>;
  clientVisibleName_lt?: InputMaybe<Scalars['String']>;
  clientVisibleName_lte?: InputMaybe<Scalars['String']>;
  clientVisibleName_not?: InputMaybe<Scalars['String']>;
  clientVisibleName_not_contains?: InputMaybe<Scalars['String']>;
  clientVisibleName_not_ends_with?: InputMaybe<Scalars['String']>;
  clientVisibleName_not_in?: InputMaybe<Array<Scalars['String']>>;
  clientVisibleName_not_starts_with?: InputMaybe<Scalars['String']>;
  clientVisibleName_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hoodName?: InputMaybe<Scalars['String']>;
  hoodName_contains?: InputMaybe<Scalars['String']>;
  hoodName_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_gt?: InputMaybe<Scalars['String']>;
  hoodName_gte?: InputMaybe<Scalars['String']>;
  hoodName_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_lt?: InputMaybe<Scalars['String']>;
  hoodName_lte?: InputMaybe<Scalars['String']>;
  hoodName_not?: InputMaybe<Scalars['String']>;
  hoodName_not_contains?: InputMaybe<Scalars['String']>;
  hoodName_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  priority?: InputMaybe<Scalars['Int']>;
  priority_gt?: InputMaybe<Scalars['Int']>;
  priority_gte?: InputMaybe<Scalars['Int']>;
  priority_in?: InputMaybe<Array<Scalars['Int']>>;
  priority_lt?: InputMaybe<Scalars['Int']>;
  priority_lte?: InputMaybe<Scalars['Int']>;
  priority_not?: InputMaybe<Scalars['Int']>;
  priority_not_in?: InputMaybe<Array<Scalars['Int']>>;
  services_every?: InputMaybe<ServiceWhereInput>;
  services_none?: InputMaybe<ServiceWhereInput>;
  services_some?: InputMaybe<ServiceWhereInput>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ServiceCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ServiceConnection = {
  __typename?: 'ServiceConnection';
  aggregate: AggregateService;
  edges: Array<Maybe<ServiceEdge>>;
  pageInfo: PageInfo;
};

export type ServiceCreateInput = {
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  category: ServiceCategoryCreateOneWithoutServicesInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hoodName: Scalars['String'];
  images?: InputMaybe<Scalars['Json']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  products?: InputMaybe<ServiceProductCreateManyWithoutServiceInput>;
  providerName: Scalars['String'];
  published?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
};

export type ServiceCreateManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  create?: InputMaybe<Array<ServiceCreateWithoutCategoryInput>>;
};

export type ServiceCreateOneWithoutProductsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  create?: InputMaybe<ServiceCreateWithoutProductsInput>;
};

export type ServiceCreateWithoutCategoryInput = {
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hoodName: Scalars['String'];
  images?: InputMaybe<Scalars['Json']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  products?: InputMaybe<ServiceProductCreateManyWithoutServiceInput>;
  providerName: Scalars['String'];
  published?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
};

export type ServiceCreateWithoutProductsInput = {
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  category: ServiceCategoryCreateOneWithoutServicesInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hoodName: Scalars['String'];
  images?: InputMaybe<Scalars['Json']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  providerName: Scalars['String'];
  published?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
};

export type ServiceEdge = {
  __typename?: 'ServiceEdge';
  cursor: Scalars['String'];
  node: Service;
};

export enum ServiceOrderByInput {
  CANCELLATIONPOLICY_ASC = 'cancellationPolicy_ASC',
  CANCELLATIONPOLICY_DESC = 'cancellationPolicy_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  EXCERPT_ASC = 'excerpt_ASC',
  EXCERPT_DESC = 'excerpt_DESC',
  HOODNAME_ASC = 'hoodName_ASC',
  HOODNAME_DESC = 'hoodName_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGES_ASC = 'images_ASC',
  IMAGES_DESC = 'images_DESC',
  ISCANCELLATIONPOLICYENABLED_ASC = 'isCancellationPolicyEnabled_ASC',
  ISCANCELLATIONPOLICYENABLED_DESC = 'isCancellationPolicyEnabled_DESC',
  ISTERMSANDCONDITIONSENABLED_ASC = 'isTermsAndConditionsEnabled_ASC',
  ISTERMSANDCONDITIONSENABLED_DESC = 'isTermsAndConditionsEnabled_DESC',
  ISVENNOPERATED_ASC = 'isVennOperated_ASC',
  ISVENNOPERATED_DESC = 'isVennOperated_DESC',
  LOCATIONID_ASC = 'locationId_ASC',
  LOCATIONID_DESC = 'locationId_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PROVIDERNAME_ASC = 'providerName_ASC',
  PROVIDERNAME_DESC = 'providerName_DESC',
  PUBLISHED_ASC = 'published_ASC',
  PUBLISHED_DESC = 'published_DESC',
  TERMSANDCONDITIONS_ASC = 'termsAndConditions_ASC',
  TERMSANDCONDITIONS_DESC = 'termsAndConditions_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ServiceProduct = {
  __typename?: 'ServiceProduct';
  /** actualPrice will return the actual price for the product - either price or discountPrice */
  actualPrice?: Maybe<Scalars['Float']>;
  /** actualPriceWithTax will return the actual price for the product including the tax amount */
  actualPriceWithTax?: Maybe<Scalars['Float']>;
  calendar?: Maybe<Calendar>;
  calendarId?: Maybe<Scalars['String']>;
  cardsDetails?: Maybe<Scalars['Json']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  currentUserSubscriptionContract?: Maybe<SubscriptionContract>;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discountPrice?: Maybe<Scalars['Float']>;
  externalProductId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Scalars['Json']>;
  isScheduledProduct?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  openingHours?: Maybe<Scalars['String']>;
  pickupLocation?: Maybe<Place>;
  pickupLocationId?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceUnit?: Maybe<PriceUnitType>;
  published: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  requireDeliveryApproval?: Maybe<Scalars['Boolean']>;
  schedulingDescription?: Maybe<Scalars['String']>;
  service: Service;
  subProduct?: Maybe<SubProduct>;
  subProductId?: Maybe<Scalars['String']>;
  taxAmount?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<TaxRate>;
  type: ServiceProductType;
  updatedAt: Scalars['DateTime'];
};

export type ServiceProductConnection = {
  __typename?: 'ServiceProductConnection';
  aggregate: AggregateServiceProduct;
  edges: Array<Maybe<ServiceProductEdge>>;
  pageInfo: PageInfo;
};

export type ServiceProductCreateInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  cardsDetails?: InputMaybe<Scalars['Json']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isScheduledProduct?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  openingHours?: InputMaybe<Scalars['String']>;
  pickupLocationId?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  priceUnit?: InputMaybe<PriceUnitType>;
  published: Scalars['Boolean'];
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requireDeliveryApproval?: InputMaybe<Scalars['Boolean']>;
  schedulingDescription?: InputMaybe<Scalars['String']>;
  service: ServiceCreateOneWithoutProductsInput;
  subProductId?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<TaxRateCreateOneInput>;
  type: ServiceProductType;
};

export type ServiceProductCreateManyWithoutServiceInput = {
  connect?: InputMaybe<Array<ServiceProductWhereUniqueInput>>;
  create?: InputMaybe<Array<ServiceProductCreateWithoutServiceInput>>;
};

export type ServiceProductCreateOneInput = {
  connect?: InputMaybe<ServiceProductWhereUniqueInput>;
  create?: InputMaybe<ServiceProductCreateInput>;
};

export type ServiceProductCreateWithoutServiceInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  cardsDetails?: InputMaybe<Scalars['Json']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isScheduledProduct?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  openingHours?: InputMaybe<Scalars['String']>;
  pickupLocationId?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  priceUnit?: InputMaybe<PriceUnitType>;
  published: Scalars['Boolean'];
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requireDeliveryApproval?: InputMaybe<Scalars['Boolean']>;
  schedulingDescription?: InputMaybe<Scalars['String']>;
  subProductId?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<TaxRateCreateOneInput>;
  type: ServiceProductType;
};

export type ServiceProductEdge = {
  __typename?: 'ServiceProductEdge';
  cursor: Scalars['String'];
  node: ServiceProduct;
};

export type ServiceProductOrder = {
  __typename?: 'ServiceProductOrder';
  billableItem?: Maybe<BillableItem>;
  billableItemId?: Maybe<Scalars['String']>;
  billingDate?: Maybe<Scalars['DateTime']>;
  calendarBooking?: Maybe<CalendarBooking>;
  calendarBookingId?: Maybe<Scalars['String']>;
  cancellable?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  serviceProduct: ServiceProduct;
  status: ServiceProductOrderStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export enum ServiceProductOrderByInput {
  CALENDARID_ASC = 'calendarId_ASC',
  CALENDARID_DESC = 'calendarId_DESC',
  CARDSDETAILS_ASC = 'cardsDetails_ASC',
  CARDSDETAILS_DESC = 'cardsDetails_DESC',
  COST_ASC = 'cost_ASC',
  COST_DESC = 'cost_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  DISCOUNTPRICE_ASC = 'discountPrice_ASC',
  DISCOUNTPRICE_DESC = 'discountPrice_DESC',
  EXTERNALPRODUCTID_ASC = 'externalProductId_ASC',
  EXTERNALPRODUCTID_DESC = 'externalProductId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGES_ASC = 'images_ASC',
  IMAGES_DESC = 'images_DESC',
  ISSCHEDULEDPRODUCT_ASC = 'isScheduledProduct_ASC',
  ISSCHEDULEDPRODUCT_DESC = 'isScheduledProduct_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  OPENINGHOURS_ASC = 'openingHours_ASC',
  OPENINGHOURS_DESC = 'openingHours_DESC',
  PICKUPLOCATIONID_ASC = 'pickupLocationId_ASC',
  PICKUPLOCATIONID_DESC = 'pickupLocationId_DESC',
  PRICEUNIT_ASC = 'priceUnit_ASC',
  PRICEUNIT_DESC = 'priceUnit_DESC',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
  PUBLISHEDAT_ASC = 'publishedAt_ASC',
  PUBLISHEDAT_DESC = 'publishedAt_DESC',
  PUBLISHED_ASC = 'published_ASC',
  PUBLISHED_DESC = 'published_DESC',
  REQUIREDELIVERYAPPROVAL_ASC = 'requireDeliveryApproval_ASC',
  REQUIREDELIVERYAPPROVAL_DESC = 'requireDeliveryApproval_DESC',
  SCHEDULINGDESCRIPTION_ASC = 'schedulingDescription_ASC',
  SCHEDULINGDESCRIPTION_DESC = 'schedulingDescription_DESC',
  SUBPRODUCTID_ASC = 'subProductId_ASC',
  SUBPRODUCTID_DESC = 'subProductId_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type ServiceProductOrderConnection = {
  __typename?: 'ServiceProductOrderConnection';
  aggregate: AggregateServiceProductOrder;
  edges: Array<Maybe<ServiceProductOrderEdge>>;
  pageInfo: PageInfo;
};

export type ServiceProductOrderCreateInput = {
  billableItemId?: InputMaybe<Scalars['String']>;
  calendarBookingId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  serviceProduct: ServiceProductCreateOneInput;
  status: ServiceProductOrderStatus;
  userId: Scalars['String'];
};

export type ServiceProductOrderEdge = {
  __typename?: 'ServiceProductOrderEdge';
  cursor: Scalars['String'];
  node: ServiceProductOrder;
};

export enum ServiceProductOrderOrderByInput {
  BILLABLEITEMID_ASC = 'billableItemId_ASC',
  BILLABLEITEMID_DESC = 'billableItemId_DESC',
  CALENDARBOOKINGID_ASC = 'calendarBookingId_ASC',
  CALENDARBOOKINGID_DESC = 'calendarBookingId_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export enum ServiceProductOrderStatus {
  CANCELATION_PENDING = 'CANCELATION_PENDING',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING'
}

export type ServiceProductOrderUpdateInput = {
  billableItemId?: InputMaybe<Scalars['String']>;
  calendarBookingId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  serviceProduct?: InputMaybe<ServiceProductUpdateOneRequiredInput>;
  status?: InputMaybe<ServiceProductOrderStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ServiceProductOrderWhereInput = {
  AND?: InputMaybe<Array<ServiceProductOrderWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProductOrderWhereInput>>;
  OR?: InputMaybe<Array<ServiceProductOrderWhereInput>>;
  billableItemId?: InputMaybe<Scalars['String']>;
  billableItemId_contains?: InputMaybe<Scalars['String']>;
  billableItemId_ends_with?: InputMaybe<Scalars['String']>;
  billableItemId_gt?: InputMaybe<Scalars['String']>;
  billableItemId_gte?: InputMaybe<Scalars['String']>;
  billableItemId_in?: InputMaybe<Array<Scalars['String']>>;
  billableItemId_lt?: InputMaybe<Scalars['String']>;
  billableItemId_lte?: InputMaybe<Scalars['String']>;
  billableItemId_not?: InputMaybe<Scalars['String']>;
  billableItemId_not_contains?: InputMaybe<Scalars['String']>;
  billableItemId_not_ends_with?: InputMaybe<Scalars['String']>;
  billableItemId_not_in?: InputMaybe<Array<Scalars['String']>>;
  billableItemId_not_starts_with?: InputMaybe<Scalars['String']>;
  billableItemId_starts_with?: InputMaybe<Scalars['String']>;
  calendarBookingId?: InputMaybe<Scalars['String']>;
  calendarBookingId_contains?: InputMaybe<Scalars['String']>;
  calendarBookingId_ends_with?: InputMaybe<Scalars['String']>;
  calendarBookingId_gt?: InputMaybe<Scalars['String']>;
  calendarBookingId_gte?: InputMaybe<Scalars['String']>;
  calendarBookingId_in?: InputMaybe<Array<Scalars['String']>>;
  calendarBookingId_lt?: InputMaybe<Scalars['String']>;
  calendarBookingId_lte?: InputMaybe<Scalars['String']>;
  calendarBookingId_not?: InputMaybe<Scalars['String']>;
  calendarBookingId_not_contains?: InputMaybe<Scalars['String']>;
  calendarBookingId_not_ends_with?: InputMaybe<Scalars['String']>;
  calendarBookingId_not_in?: InputMaybe<Array<Scalars['String']>>;
  calendarBookingId_not_starts_with?: InputMaybe<Scalars['String']>;
  calendarBookingId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  serviceProduct?: InputMaybe<ServiceProductWhereInput>;
  status?: InputMaybe<ServiceProductOrderStatus>;
  status_in?: InputMaybe<Array<ServiceProductOrderStatus>>;
  status_not?: InputMaybe<ServiceProductOrderStatus>;
  status_not_in?: InputMaybe<Array<ServiceProductOrderStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type ServiceProductOrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ServiceProductScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceProductScalarWhereInput>>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarId_contains?: InputMaybe<Scalars['String']>;
  calendarId_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_gt?: InputMaybe<Scalars['String']>;
  calendarId_gte?: InputMaybe<Scalars['String']>;
  calendarId_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_lt?: InputMaybe<Scalars['String']>;
  calendarId_lte?: InputMaybe<Scalars['String']>;
  calendarId_not?: InputMaybe<Scalars['String']>;
  calendarId_not_contains?: InputMaybe<Scalars['String']>;
  calendarId_not_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_not_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_not_starts_with?: InputMaybe<Scalars['String']>;
  calendarId_starts_with?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  cost_gt?: InputMaybe<Scalars['Float']>;
  cost_gte?: InputMaybe<Scalars['Float']>;
  cost_in?: InputMaybe<Array<Scalars['Float']>>;
  cost_lt?: InputMaybe<Scalars['Float']>;
  cost_lte?: InputMaybe<Scalars['Float']>;
  cost_not?: InputMaybe<Scalars['Float']>;
  cost_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  discountPrice_gt?: InputMaybe<Scalars['Float']>;
  discountPrice_gte?: InputMaybe<Scalars['Float']>;
  discountPrice_in?: InputMaybe<Array<Scalars['Float']>>;
  discountPrice_lt?: InputMaybe<Scalars['Float']>;
  discountPrice_lte?: InputMaybe<Scalars['Float']>;
  discountPrice_not?: InputMaybe<Scalars['Float']>;
  discountPrice_not_in?: InputMaybe<Array<Scalars['Float']>>;
  externalProductId?: InputMaybe<Scalars['String']>;
  externalProductId_contains?: InputMaybe<Scalars['String']>;
  externalProductId_ends_with?: InputMaybe<Scalars['String']>;
  externalProductId_gt?: InputMaybe<Scalars['String']>;
  externalProductId_gte?: InputMaybe<Scalars['String']>;
  externalProductId_in?: InputMaybe<Array<Scalars['String']>>;
  externalProductId_lt?: InputMaybe<Scalars['String']>;
  externalProductId_lte?: InputMaybe<Scalars['String']>;
  externalProductId_not?: InputMaybe<Scalars['String']>;
  externalProductId_not_contains?: InputMaybe<Scalars['String']>;
  externalProductId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalProductId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalProductId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalProductId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isScheduledProduct?: InputMaybe<Scalars['Boolean']>;
  isScheduledProduct_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  pickupLocationId?: InputMaybe<Scalars['String']>;
  pickupLocationId_contains?: InputMaybe<Scalars['String']>;
  pickupLocationId_ends_with?: InputMaybe<Scalars['String']>;
  pickupLocationId_gt?: InputMaybe<Scalars['String']>;
  pickupLocationId_gte?: InputMaybe<Scalars['String']>;
  pickupLocationId_in?: InputMaybe<Array<Scalars['String']>>;
  pickupLocationId_lt?: InputMaybe<Scalars['String']>;
  pickupLocationId_lte?: InputMaybe<Scalars['String']>;
  pickupLocationId_not?: InputMaybe<Scalars['String']>;
  pickupLocationId_not_contains?: InputMaybe<Scalars['String']>;
  pickupLocationId_not_ends_with?: InputMaybe<Scalars['String']>;
  pickupLocationId_not_in?: InputMaybe<Array<Scalars['String']>>;
  pickupLocationId_not_starts_with?: InputMaybe<Scalars['String']>;
  pickupLocationId_starts_with?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceUnit?: InputMaybe<PriceUnitType>;
  priceUnit_in?: InputMaybe<Array<PriceUnitType>>;
  priceUnit_not?: InputMaybe<PriceUnitType>;
  priceUnit_not_in?: InputMaybe<Array<PriceUnitType>>;
  price_gt?: InputMaybe<Scalars['Float']>;
  price_gte?: InputMaybe<Scalars['Float']>;
  price_in?: InputMaybe<Array<Scalars['Float']>>;
  price_lt?: InputMaybe<Scalars['Float']>;
  price_lte?: InputMaybe<Scalars['Float']>;
  price_not?: InputMaybe<Scalars['Float']>;
  price_not_in?: InputMaybe<Array<Scalars['Float']>>;
  published?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  published_not?: InputMaybe<Scalars['Boolean']>;
  requireDeliveryApproval?: InputMaybe<Scalars['Boolean']>;
  requireDeliveryApproval_not?: InputMaybe<Scalars['Boolean']>;
  schedulingDescription?: InputMaybe<Scalars['String']>;
  schedulingDescription_contains?: InputMaybe<Scalars['String']>;
  schedulingDescription_ends_with?: InputMaybe<Scalars['String']>;
  schedulingDescription_gt?: InputMaybe<Scalars['String']>;
  schedulingDescription_gte?: InputMaybe<Scalars['String']>;
  schedulingDescription_in?: InputMaybe<Array<Scalars['String']>>;
  schedulingDescription_lt?: InputMaybe<Scalars['String']>;
  schedulingDescription_lte?: InputMaybe<Scalars['String']>;
  schedulingDescription_not?: InputMaybe<Scalars['String']>;
  schedulingDescription_not_contains?: InputMaybe<Scalars['String']>;
  schedulingDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  schedulingDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  schedulingDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  schedulingDescription_starts_with?: InputMaybe<Scalars['String']>;
  subProductId?: InputMaybe<Scalars['String']>;
  subProductId_contains?: InputMaybe<Scalars['String']>;
  subProductId_ends_with?: InputMaybe<Scalars['String']>;
  subProductId_gt?: InputMaybe<Scalars['String']>;
  subProductId_gte?: InputMaybe<Scalars['String']>;
  subProductId_in?: InputMaybe<Array<Scalars['String']>>;
  subProductId_lt?: InputMaybe<Scalars['String']>;
  subProductId_lte?: InputMaybe<Scalars['String']>;
  subProductId_not?: InputMaybe<Scalars['String']>;
  subProductId_not_contains?: InputMaybe<Scalars['String']>;
  subProductId_not_ends_with?: InputMaybe<Scalars['String']>;
  subProductId_not_in?: InputMaybe<Array<Scalars['String']>>;
  subProductId_not_starts_with?: InputMaybe<Scalars['String']>;
  subProductId_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ServiceProductType>;
  type_in?: InputMaybe<Array<ServiceProductType>>;
  type_not?: InputMaybe<ServiceProductType>;
  type_not_in?: InputMaybe<Array<ServiceProductType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum ServiceProductType {
  ON_DEMAND = 'ON_DEMAND',
  RECURRING = 'RECURRING'
}

export type ServiceProductUpdateDataInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  cardsDetails?: InputMaybe<Scalars['Json']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isScheduledProduct?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  pickupLocationId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceUnit?: InputMaybe<PriceUnitType>;
  published?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requireDeliveryApproval?: InputMaybe<Scalars['Boolean']>;
  schedulingDescription?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutProductsInput>;
  subProductId?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<TaxRateUpdateOneInput>;
  type?: InputMaybe<ServiceProductType>;
};

export type ServiceProductUpdateInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  cardsDetails?: InputMaybe<Scalars['Json']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isScheduledProduct?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  pickupLocationId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceUnit?: InputMaybe<PriceUnitType>;
  published?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requireDeliveryApproval?: InputMaybe<Scalars['Boolean']>;
  schedulingDescription?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutProductsInput>;
  subProductId?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<TaxRateUpdateOneInput>;
  type?: InputMaybe<ServiceProductType>;
};

export type ServiceProductUpdateManyDataInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  cardsDetails?: InputMaybe<Scalars['Json']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isScheduledProduct?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  pickupLocationId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceUnit?: InputMaybe<PriceUnitType>;
  published?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requireDeliveryApproval?: InputMaybe<Scalars['Boolean']>;
  schedulingDescription?: InputMaybe<Scalars['String']>;
  subProductId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ServiceProductType>;
};

export type ServiceProductUpdateManyWithWhereNestedInput = {
  data: ServiceProductUpdateManyDataInput;
  where: ServiceProductScalarWhereInput;
};

export type ServiceProductUpdateManyWithoutServiceInput = {
  connect?: InputMaybe<Array<ServiceProductWhereUniqueInput>>;
  create?: InputMaybe<Array<ServiceProductCreateWithoutServiceInput>>;
  delete?: InputMaybe<Array<ServiceProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceProductUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<ServiceProductUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<ServiceProductUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type ServiceProductUpdateOneRequiredInput = {
  connect?: InputMaybe<ServiceProductWhereUniqueInput>;
  create?: InputMaybe<ServiceProductCreateInput>;
  update?: InputMaybe<ServiceProductUpdateDataInput>;
  upsert?: InputMaybe<ServiceProductUpsertNestedInput>;
};

export type ServiceProductUpdateWithWhereUniqueWithoutServiceInput = {
  data: ServiceProductUpdateWithoutServiceDataInput;
  where: ServiceProductWhereUniqueInput;
};

export type ServiceProductUpdateWithoutServiceDataInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  cardsDetails?: InputMaybe<Scalars['Json']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isScheduledProduct?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  pickupLocationId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceUnit?: InputMaybe<PriceUnitType>;
  published?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requireDeliveryApproval?: InputMaybe<Scalars['Boolean']>;
  schedulingDescription?: InputMaybe<Scalars['String']>;
  subProductId?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<TaxRateUpdateOneInput>;
  type?: InputMaybe<ServiceProductType>;
};

export type ServiceProductUpsertNestedInput = {
  create: ServiceProductCreateInput;
  update: ServiceProductUpdateDataInput;
};

export type ServiceProductUpsertWithWhereUniqueWithoutServiceInput = {
  create: ServiceProductCreateWithoutServiceInput;
  update: ServiceProductUpdateWithoutServiceDataInput;
  where: ServiceProductWhereUniqueInput;
};

export type ServiceProductWhereInput = {
  AND?: InputMaybe<Array<ServiceProductWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProductWhereInput>>;
  OR?: InputMaybe<Array<ServiceProductWhereInput>>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarId_contains?: InputMaybe<Scalars['String']>;
  calendarId_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_gt?: InputMaybe<Scalars['String']>;
  calendarId_gte?: InputMaybe<Scalars['String']>;
  calendarId_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_lt?: InputMaybe<Scalars['String']>;
  calendarId_lte?: InputMaybe<Scalars['String']>;
  calendarId_not?: InputMaybe<Scalars['String']>;
  calendarId_not_contains?: InputMaybe<Scalars['String']>;
  calendarId_not_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_not_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_not_starts_with?: InputMaybe<Scalars['String']>;
  calendarId_starts_with?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  cost_gt?: InputMaybe<Scalars['Float']>;
  cost_gte?: InputMaybe<Scalars['Float']>;
  cost_in?: InputMaybe<Array<Scalars['Float']>>;
  cost_lt?: InputMaybe<Scalars['Float']>;
  cost_lte?: InputMaybe<Scalars['Float']>;
  cost_not?: InputMaybe<Scalars['Float']>;
  cost_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  discountPrice_gt?: InputMaybe<Scalars['Float']>;
  discountPrice_gte?: InputMaybe<Scalars['Float']>;
  discountPrice_in?: InputMaybe<Array<Scalars['Float']>>;
  discountPrice_lt?: InputMaybe<Scalars['Float']>;
  discountPrice_lte?: InputMaybe<Scalars['Float']>;
  discountPrice_not?: InputMaybe<Scalars['Float']>;
  discountPrice_not_in?: InputMaybe<Array<Scalars['Float']>>;
  externalProductId?: InputMaybe<Scalars['String']>;
  externalProductId_contains?: InputMaybe<Scalars['String']>;
  externalProductId_ends_with?: InputMaybe<Scalars['String']>;
  externalProductId_gt?: InputMaybe<Scalars['String']>;
  externalProductId_gte?: InputMaybe<Scalars['String']>;
  externalProductId_in?: InputMaybe<Array<Scalars['String']>>;
  externalProductId_lt?: InputMaybe<Scalars['String']>;
  externalProductId_lte?: InputMaybe<Scalars['String']>;
  externalProductId_not?: InputMaybe<Scalars['String']>;
  externalProductId_not_contains?: InputMaybe<Scalars['String']>;
  externalProductId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalProductId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalProductId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalProductId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isScheduledProduct?: InputMaybe<Scalars['Boolean']>;
  isScheduledProduct_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  pickupLocationId?: InputMaybe<Scalars['String']>;
  pickupLocationId_contains?: InputMaybe<Scalars['String']>;
  pickupLocationId_ends_with?: InputMaybe<Scalars['String']>;
  pickupLocationId_gt?: InputMaybe<Scalars['String']>;
  pickupLocationId_gte?: InputMaybe<Scalars['String']>;
  pickupLocationId_in?: InputMaybe<Array<Scalars['String']>>;
  pickupLocationId_lt?: InputMaybe<Scalars['String']>;
  pickupLocationId_lte?: InputMaybe<Scalars['String']>;
  pickupLocationId_not?: InputMaybe<Scalars['String']>;
  pickupLocationId_not_contains?: InputMaybe<Scalars['String']>;
  pickupLocationId_not_ends_with?: InputMaybe<Scalars['String']>;
  pickupLocationId_not_in?: InputMaybe<Array<Scalars['String']>>;
  pickupLocationId_not_starts_with?: InputMaybe<Scalars['String']>;
  pickupLocationId_starts_with?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceUnit?: InputMaybe<PriceUnitType>;
  priceUnit_in?: InputMaybe<Array<PriceUnitType>>;
  priceUnit_not?: InputMaybe<PriceUnitType>;
  priceUnit_not_in?: InputMaybe<Array<PriceUnitType>>;
  price_gt?: InputMaybe<Scalars['Float']>;
  price_gte?: InputMaybe<Scalars['Float']>;
  price_in?: InputMaybe<Array<Scalars['Float']>>;
  price_lt?: InputMaybe<Scalars['Float']>;
  price_lte?: InputMaybe<Scalars['Float']>;
  price_not?: InputMaybe<Scalars['Float']>;
  price_not_in?: InputMaybe<Array<Scalars['Float']>>;
  published?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  published_not?: InputMaybe<Scalars['Boolean']>;
  requireDeliveryApproval?: InputMaybe<Scalars['Boolean']>;
  requireDeliveryApproval_not?: InputMaybe<Scalars['Boolean']>;
  schedulingDescription?: InputMaybe<Scalars['String']>;
  schedulingDescription_contains?: InputMaybe<Scalars['String']>;
  schedulingDescription_ends_with?: InputMaybe<Scalars['String']>;
  schedulingDescription_gt?: InputMaybe<Scalars['String']>;
  schedulingDescription_gte?: InputMaybe<Scalars['String']>;
  schedulingDescription_in?: InputMaybe<Array<Scalars['String']>>;
  schedulingDescription_lt?: InputMaybe<Scalars['String']>;
  schedulingDescription_lte?: InputMaybe<Scalars['String']>;
  schedulingDescription_not?: InputMaybe<Scalars['String']>;
  schedulingDescription_not_contains?: InputMaybe<Scalars['String']>;
  schedulingDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  schedulingDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  schedulingDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  schedulingDescription_starts_with?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<ServiceWhereInput>;
  subProductId?: InputMaybe<Scalars['String']>;
  subProductId_contains?: InputMaybe<Scalars['String']>;
  subProductId_ends_with?: InputMaybe<Scalars['String']>;
  subProductId_gt?: InputMaybe<Scalars['String']>;
  subProductId_gte?: InputMaybe<Scalars['String']>;
  subProductId_in?: InputMaybe<Array<Scalars['String']>>;
  subProductId_lt?: InputMaybe<Scalars['String']>;
  subProductId_lte?: InputMaybe<Scalars['String']>;
  subProductId_not?: InputMaybe<Scalars['String']>;
  subProductId_not_contains?: InputMaybe<Scalars['String']>;
  subProductId_not_ends_with?: InputMaybe<Scalars['String']>;
  subProductId_not_in?: InputMaybe<Array<Scalars['String']>>;
  subProductId_not_starts_with?: InputMaybe<Scalars['String']>;
  subProductId_starts_with?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<TaxRateWhereInput>;
  type?: InputMaybe<ServiceProductType>;
  type_in?: InputMaybe<Array<ServiceProductType>>;
  type_not?: InputMaybe<ServiceProductType>;
  type_not_in?: InputMaybe<Array<ServiceProductType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ServiceProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ServiceScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceScalarWhereInput>>;
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  cancellationPolicy_contains?: InputMaybe<Scalars['String']>;
  cancellationPolicy_ends_with?: InputMaybe<Scalars['String']>;
  cancellationPolicy_gt?: InputMaybe<Scalars['String']>;
  cancellationPolicy_gte?: InputMaybe<Scalars['String']>;
  cancellationPolicy_in?: InputMaybe<Array<Scalars['String']>>;
  cancellationPolicy_lt?: InputMaybe<Scalars['String']>;
  cancellationPolicy_lte?: InputMaybe<Scalars['String']>;
  cancellationPolicy_not?: InputMaybe<Scalars['String']>;
  cancellationPolicy_not_contains?: InputMaybe<Scalars['String']>;
  cancellationPolicy_not_ends_with?: InputMaybe<Scalars['String']>;
  cancellationPolicy_not_in?: InputMaybe<Array<Scalars['String']>>;
  cancellationPolicy_not_starts_with?: InputMaybe<Scalars['String']>;
  cancellationPolicy_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  excerpt_contains?: InputMaybe<Scalars['String']>;
  excerpt_ends_with?: InputMaybe<Scalars['String']>;
  excerpt_gt?: InputMaybe<Scalars['String']>;
  excerpt_gte?: InputMaybe<Scalars['String']>;
  excerpt_in?: InputMaybe<Array<Scalars['String']>>;
  excerpt_lt?: InputMaybe<Scalars['String']>;
  excerpt_lte?: InputMaybe<Scalars['String']>;
  excerpt_not?: InputMaybe<Scalars['String']>;
  excerpt_not_contains?: InputMaybe<Scalars['String']>;
  excerpt_not_ends_with?: InputMaybe<Scalars['String']>;
  excerpt_not_in?: InputMaybe<Array<Scalars['String']>>;
  excerpt_not_starts_with?: InputMaybe<Scalars['String']>;
  excerpt_starts_with?: InputMaybe<Scalars['String']>;
  hoodName?: InputMaybe<Scalars['String']>;
  hoodName_contains?: InputMaybe<Scalars['String']>;
  hoodName_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_gt?: InputMaybe<Scalars['String']>;
  hoodName_gte?: InputMaybe<Scalars['String']>;
  hoodName_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_lt?: InputMaybe<Scalars['String']>;
  hoodName_lte?: InputMaybe<Scalars['String']>;
  hoodName_not?: InputMaybe<Scalars['String']>;
  hoodName_not_contains?: InputMaybe<Scalars['String']>;
  hoodName_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isCancellationPolicyEnabled_not?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled_not?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  isVennOperated_not?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  locationId_contains?: InputMaybe<Scalars['String']>;
  locationId_ends_with?: InputMaybe<Scalars['String']>;
  locationId_gt?: InputMaybe<Scalars['String']>;
  locationId_gte?: InputMaybe<Scalars['String']>;
  locationId_in?: InputMaybe<Array<Scalars['String']>>;
  locationId_lt?: InputMaybe<Scalars['String']>;
  locationId_lte?: InputMaybe<Scalars['String']>;
  locationId_not?: InputMaybe<Scalars['String']>;
  locationId_not_contains?: InputMaybe<Scalars['String']>;
  locationId_not_ends_with?: InputMaybe<Scalars['String']>;
  locationId_not_in?: InputMaybe<Array<Scalars['String']>>;
  locationId_not_starts_with?: InputMaybe<Scalars['String']>;
  locationId_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  providerName?: InputMaybe<Scalars['String']>;
  providerName_contains?: InputMaybe<Scalars['String']>;
  providerName_ends_with?: InputMaybe<Scalars['String']>;
  providerName_gt?: InputMaybe<Scalars['String']>;
  providerName_gte?: InputMaybe<Scalars['String']>;
  providerName_in?: InputMaybe<Array<Scalars['String']>>;
  providerName_lt?: InputMaybe<Scalars['String']>;
  providerName_lte?: InputMaybe<Scalars['String']>;
  providerName_not?: InputMaybe<Scalars['String']>;
  providerName_not_contains?: InputMaybe<Scalars['String']>;
  providerName_not_ends_with?: InputMaybe<Scalars['String']>;
  providerName_not_in?: InputMaybe<Array<Scalars['String']>>;
  providerName_not_starts_with?: InputMaybe<Scalars['String']>;
  providerName_starts_with?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_not?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
  termsAndConditions_contains?: InputMaybe<Scalars['String']>;
  termsAndConditions_ends_with?: InputMaybe<Scalars['String']>;
  termsAndConditions_gt?: InputMaybe<Scalars['String']>;
  termsAndConditions_gte?: InputMaybe<Scalars['String']>;
  termsAndConditions_in?: InputMaybe<Array<Scalars['String']>>;
  termsAndConditions_lt?: InputMaybe<Scalars['String']>;
  termsAndConditions_lte?: InputMaybe<Scalars['String']>;
  termsAndConditions_not?: InputMaybe<Scalars['String']>;
  termsAndConditions_not_contains?: InputMaybe<Scalars['String']>;
  termsAndConditions_not_ends_with?: InputMaybe<Scalars['String']>;
  termsAndConditions_not_in?: InputMaybe<Array<Scalars['String']>>;
  termsAndConditions_not_starts_with?: InputMaybe<Scalars['String']>;
  termsAndConditions_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ServiceUpdateInput = {
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<ServiceCategoryUpdateOneRequiredWithoutServicesInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hoodName?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ServiceProductUpdateManyWithoutServiceInput>;
  providerName?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
};

export type ServiceUpdateManyDataInput = {
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hoodName?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  providerName?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
};

export type ServiceUpdateManyWithWhereNestedInput = {
  data: ServiceUpdateManyDataInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  create?: InputMaybe<Array<ServiceCreateWithoutCategoryInput>>;
  delete?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<ServiceUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<ServiceUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type ServiceUpdateOneRequiredWithoutProductsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  create?: InputMaybe<ServiceCreateWithoutProductsInput>;
  update?: InputMaybe<ServiceUpdateWithoutProductsDataInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutProductsInput>;
};

export type ServiceUpdateWithWhereUniqueWithoutCategoryInput = {
  data: ServiceUpdateWithoutCategoryDataInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithoutCategoryDataInput = {
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hoodName?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ServiceProductUpdateManyWithoutServiceInput>;
  providerName?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
};

export type ServiceUpdateWithoutProductsDataInput = {
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<ServiceCategoryUpdateOneRequiredWithoutServicesInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hoodName?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['Json']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  providerName?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
};

export type ServiceUpsertWithWhereUniqueWithoutCategoryInput = {
  create: ServiceCreateWithoutCategoryInput;
  update: ServiceUpdateWithoutCategoryDataInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithoutProductsInput = {
  create: ServiceCreateWithoutProductsInput;
  update: ServiceUpdateWithoutProductsDataInput;
};

export type ServiceWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<ServiceWhereContractsPersistencyInput>>;
  ContractsPersistencyServiceType?: InputMaybe<ContractsPersistencyServiceType>;
  ContractsPersistencyServiceType_in?: InputMaybe<Array<ContractsPersistencyServiceType>>;
  ContractsPersistencyServiceType_not?: InputMaybe<ContractsPersistencyServiceType>;
  ContractsPersistencyServiceType_not_in?: InputMaybe<Array<ContractsPersistencyServiceType>>;
  NOT?: InputMaybe<Array<ServiceWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<ServiceWhereContractsPersistencyInput>>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarId_contains?: InputMaybe<Scalars['String']>;
  calendarId_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_gt?: InputMaybe<Scalars['String']>;
  calendarId_gte?: InputMaybe<Scalars['String']>;
  calendarId_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_lt?: InputMaybe<Scalars['String']>;
  calendarId_lte?: InputMaybe<Scalars['String']>;
  calendarId_not?: InputMaybe<Scalars['String']>;
  calendarId_not_contains?: InputMaybe<Scalars['String']>;
  calendarId_not_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_not_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_not_starts_with?: InputMaybe<Scalars['String']>;
  calendarId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  cancellationPolicy?: InputMaybe<Scalars['String']>;
  cancellationPolicy_contains?: InputMaybe<Scalars['String']>;
  cancellationPolicy_ends_with?: InputMaybe<Scalars['String']>;
  cancellationPolicy_gt?: InputMaybe<Scalars['String']>;
  cancellationPolicy_gte?: InputMaybe<Scalars['String']>;
  cancellationPolicy_in?: InputMaybe<Array<Scalars['String']>>;
  cancellationPolicy_lt?: InputMaybe<Scalars['String']>;
  cancellationPolicy_lte?: InputMaybe<Scalars['String']>;
  cancellationPolicy_not?: InputMaybe<Scalars['String']>;
  cancellationPolicy_not_contains?: InputMaybe<Scalars['String']>;
  cancellationPolicy_not_ends_with?: InputMaybe<Scalars['String']>;
  cancellationPolicy_not_in?: InputMaybe<Array<Scalars['String']>>;
  cancellationPolicy_not_starts_with?: InputMaybe<Scalars['String']>;
  cancellationPolicy_starts_with?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<ServiceCategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  excerpt_contains?: InputMaybe<Scalars['String']>;
  excerpt_ends_with?: InputMaybe<Scalars['String']>;
  excerpt_gt?: InputMaybe<Scalars['String']>;
  excerpt_gte?: InputMaybe<Scalars['String']>;
  excerpt_in?: InputMaybe<Array<Scalars['String']>>;
  excerpt_lt?: InputMaybe<Scalars['String']>;
  excerpt_lte?: InputMaybe<Scalars['String']>;
  excerpt_not?: InputMaybe<Scalars['String']>;
  excerpt_not_contains?: InputMaybe<Scalars['String']>;
  excerpt_not_ends_with?: InputMaybe<Scalars['String']>;
  excerpt_not_in?: InputMaybe<Array<Scalars['String']>>;
  excerpt_not_starts_with?: InputMaybe<Scalars['String']>;
  excerpt_starts_with?: InputMaybe<Scalars['String']>;
  hoodName?: InputMaybe<Scalars['String']>;
  hoodName_contains?: InputMaybe<Scalars['String']>;
  hoodName_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_gt?: InputMaybe<Scalars['String']>;
  hoodName_gte?: InputMaybe<Scalars['String']>;
  hoodName_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_lt?: InputMaybe<Scalars['String']>;
  hoodName_lte?: InputMaybe<Scalars['String']>;
  hoodName_not?: InputMaybe<Scalars['String']>;
  hoodName_not_contains?: InputMaybe<Scalars['String']>;
  hoodName_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isCancellationPolicyEnabled?: InputMaybe<Scalars['Boolean']>;
  isCancellationPolicyEnabled_not?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled?: InputMaybe<Scalars['Boolean']>;
  isTermsAndConditionsEnabled_not?: InputMaybe<Scalars['Boolean']>;
  isVennOperated?: InputMaybe<Scalars['Boolean']>;
  isVennOperated_not?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  locationId_contains?: InputMaybe<Scalars['String']>;
  locationId_ends_with?: InputMaybe<Scalars['String']>;
  locationId_gt?: InputMaybe<Scalars['String']>;
  locationId_gte?: InputMaybe<Scalars['String']>;
  locationId_in?: InputMaybe<Array<Scalars['String']>>;
  locationId_lt?: InputMaybe<Scalars['String']>;
  locationId_lte?: InputMaybe<Scalars['String']>;
  locationId_not?: InputMaybe<Scalars['String']>;
  locationId_not_contains?: InputMaybe<Scalars['String']>;
  locationId_not_ends_with?: InputMaybe<Scalars['String']>;
  locationId_not_in?: InputMaybe<Array<Scalars['String']>>;
  locationId_not_starts_with?: InputMaybe<Scalars['String']>;
  locationId_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ServiceProductWhereInput>;
  products_none?: InputMaybe<ServiceProductWhereInput>;
  products_some?: InputMaybe<ServiceProductWhereInput>;
  providerName?: InputMaybe<Scalars['String']>;
  providerName_contains?: InputMaybe<Scalars['String']>;
  providerName_ends_with?: InputMaybe<Scalars['String']>;
  providerName_gt?: InputMaybe<Scalars['String']>;
  providerName_gte?: InputMaybe<Scalars['String']>;
  providerName_in?: InputMaybe<Array<Scalars['String']>>;
  providerName_lt?: InputMaybe<Scalars['String']>;
  providerName_lte?: InputMaybe<Scalars['String']>;
  providerName_not?: InputMaybe<Scalars['String']>;
  providerName_not_contains?: InputMaybe<Scalars['String']>;
  providerName_not_ends_with?: InputMaybe<Scalars['String']>;
  providerName_not_in?: InputMaybe<Array<Scalars['String']>>;
  providerName_not_starts_with?: InputMaybe<Scalars['String']>;
  providerName_starts_with?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_not?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
  termsAndConditions_contains?: InputMaybe<Scalars['String']>;
  termsAndConditions_ends_with?: InputMaybe<Scalars['String']>;
  termsAndConditions_gt?: InputMaybe<Scalars['String']>;
  termsAndConditions_gte?: InputMaybe<Scalars['String']>;
  termsAndConditions_in?: InputMaybe<Array<Scalars['String']>>;
  termsAndConditions_lt?: InputMaybe<Scalars['String']>;
  termsAndConditions_lte?: InputMaybe<Scalars['String']>;
  termsAndConditions_not?: InputMaybe<Scalars['String']>;
  termsAndConditions_not_contains?: InputMaybe<Scalars['String']>;
  termsAndConditions_not_ends_with?: InputMaybe<Scalars['String']>;
  termsAndConditions_not_in?: InputMaybe<Array<Scalars['String']>>;
  termsAndConditions_not_starts_with?: InputMaybe<Scalars['String']>;
  termsAndConditions_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ServiceWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<ServiceWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<ServiceWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<ServiceWherePersistencyContractsInput>>;
  PersistencyContractsServiceType?: InputMaybe<PersistencyContractsServiceType>;
  PersistencyContractsServiceType_in?: InputMaybe<Array<PersistencyContractsServiceType>>;
  PersistencyContractsServiceType_not?: InputMaybe<PersistencyContractsServiceType>;
  PersistencyContractsServiceType_not_in?: InputMaybe<Array<PersistencyContractsServiceType>>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarId_contains?: InputMaybe<Scalars['String']>;
  calendarId_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_gt?: InputMaybe<Scalars['String']>;
  calendarId_gte?: InputMaybe<Scalars['String']>;
  calendarId_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_lt?: InputMaybe<Scalars['String']>;
  calendarId_lte?: InputMaybe<Scalars['String']>;
  calendarId_not?: InputMaybe<Scalars['String']>;
  calendarId_not_contains?: InputMaybe<Scalars['String']>;
  calendarId_not_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_not_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_not_starts_with?: InputMaybe<Scalars['String']>;
  calendarId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Settings = {
  __typename?: 'Settings';
  contractSettings: ContractSettings;
  hood: Hood;
  hoodName: Scalars['String'];
  paymentSettings?: Maybe<PaymentSettings>;
};

export type SharedSpace = {
  __typename?: 'SharedSpace';
  _empty?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  airtableId?: Maybe<Scalars['String']>;
  bookingEnabled?: Maybe<Scalars['Boolean']>;
  calendar?: Maybe<Calendar>;
  calendarId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hood: Hood;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SharedSpaceConnection = {
  __typename?: 'SharedSpaceConnection';
  aggregate: AggregateSharedSpace;
  edges: Array<Maybe<SharedSpaceEdge>>;
  pageInfo: PageInfo;
};

export type SharedSpaceCreateInput = {
  address?: InputMaybe<AddressCreateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  bookingEnabled?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood: HoodCreateOneInput;
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
};

export type SharedSpaceCreateOneInput = {
  connect?: InputMaybe<SharedSpaceWhereUniqueInput>;
  create?: InputMaybe<SharedSpaceCreateInput>;
};

export type SharedSpaceEdge = {
  __typename?: 'SharedSpaceEdge';
  cursor: Scalars['String'];
  node: SharedSpace;
};

export enum SharedSpaceOrderByInput {
  AIRTABLEID_ASC = 'airtableId_ASC',
  AIRTABLEID_DESC = 'airtableId_DESC',
  BOOKINGENABLED_ASC = 'bookingEnabled_ASC',
  BOOKINGENABLED_DESC = 'bookingEnabled_DESC',
  CALENDARID_ASC = 'calendarId_ASC',
  CALENDARID_DESC = 'calendarId_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  OPENINGHOURS_ASC = 'openingHours_ASC',
  OPENINGHOURS_DESC = 'openingHours_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type SharedSpaceUpdateDataInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  bookingEnabled?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
};

export type SharedSpaceUpdateInput = {
  address?: InputMaybe<AddressUpdateOneInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  bookingEnabled?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
};

export type SharedSpaceUpdateOneInput = {
  connect?: InputMaybe<SharedSpaceWhereUniqueInput>;
  create?: InputMaybe<SharedSpaceCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SharedSpaceUpdateDataInput>;
  upsert?: InputMaybe<SharedSpaceUpsertNestedInput>;
};

export type SharedSpaceUpsertNestedInput = {
  create: SharedSpaceCreateInput;
  update: SharedSpaceUpdateDataInput;
};

export type SharedSpaceWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<SharedSpaceWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<SharedSpaceWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<SharedSpaceWhereContractsPersistencyInput>>;
  address?: InputMaybe<AddressWhereContractsPersistencyInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  bookingEnabled?: InputMaybe<Scalars['Boolean']>;
  bookingEnabled_not?: InputMaybe<Scalars['Boolean']>;
  calendar?: InputMaybe<CalendarWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locks_every?: InputMaybe<LockWhereContractsPersistencyInput>;
  locks_none?: InputMaybe<LockWhereContractsPersistencyInput>;
  locks_some?: InputMaybe<LockWhereContractsPersistencyInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SharedSpaceWhereInput = {
  AND?: InputMaybe<Array<SharedSpaceWhereInput>>;
  NOT?: InputMaybe<Array<SharedSpaceWhereInput>>;
  OR?: InputMaybe<Array<SharedSpaceWhereInput>>;
  address?: InputMaybe<AddressWhereInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  bookingEnabled?: InputMaybe<Scalars['Boolean']>;
  bookingEnabled_not?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarId_contains?: InputMaybe<Scalars['String']>;
  calendarId_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_gt?: InputMaybe<Scalars['String']>;
  calendarId_gte?: InputMaybe<Scalars['String']>;
  calendarId_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_lt?: InputMaybe<Scalars['String']>;
  calendarId_lte?: InputMaybe<Scalars['String']>;
  calendarId_not?: InputMaybe<Scalars['String']>;
  calendarId_not_contains?: InputMaybe<Scalars['String']>;
  calendarId_not_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_not_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_not_starts_with?: InputMaybe<Scalars['String']>;
  calendarId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SharedSpaceWhereInputPersistency = {
  AND?: InputMaybe<Array<SharedSpaceWhereInputPersistency>>;
  NOT?: InputMaybe<Array<SharedSpaceWhereInputPersistency>>;
  OR?: InputMaybe<Array<SharedSpaceWhereInputPersistency>>;
  address?: InputMaybe<AddressWhereInputPersistency>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  bookingEnabled?: InputMaybe<Scalars['Boolean']>;
  bookingEnabled_not?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarId_contains?: InputMaybe<Scalars['String']>;
  calendarId_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_gt?: InputMaybe<Scalars['String']>;
  calendarId_gte?: InputMaybe<Scalars['String']>;
  calendarId_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_lt?: InputMaybe<Scalars['String']>;
  calendarId_lte?: InputMaybe<Scalars['String']>;
  calendarId_not?: InputMaybe<Scalars['String']>;
  calendarId_not_contains?: InputMaybe<Scalars['String']>;
  calendarId_not_ends_with?: InputMaybe<Scalars['String']>;
  calendarId_not_in?: InputMaybe<Array<Scalars['String']>>;
  calendarId_not_starts_with?: InputMaybe<Scalars['String']>;
  calendarId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWhereInputPersistency>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SharedSpaceWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<SharedSpaceWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<SharedSpaceWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<SharedSpaceWherePersistencyContractsInput>>;
  address?: InputMaybe<AddressWherePersistencyContractsInput>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  bookingEnabled?: InputMaybe<Scalars['Boolean']>;
  bookingEnabled_not?: InputMaybe<Scalars['Boolean']>;
  calendar?: InputMaybe<CalendarWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locks_every?: InputMaybe<LockWherePersistencyContractsInput>;
  locks_none?: InputMaybe<LockWherePersistencyContractsInput>;
  locks_some?: InputMaybe<LockWherePersistencyContractsInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['String']>;
  openingHours_contains?: InputMaybe<Scalars['String']>;
  openingHours_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_gt?: InputMaybe<Scalars['String']>;
  openingHours_gte?: InputMaybe<Scalars['String']>;
  openingHours_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_lt?: InputMaybe<Scalars['String']>;
  openingHours_lte?: InputMaybe<Scalars['String']>;
  openingHours_not?: InputMaybe<Scalars['String']>;
  openingHours_not_contains?: InputMaybe<Scalars['String']>;
  openingHours_not_ends_with?: InputMaybe<Scalars['String']>;
  openingHours_not_in?: InputMaybe<Array<Scalars['String']>>;
  openingHours_not_starts_with?: InputMaybe<Scalars['String']>;
  openingHours_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SharedSpaceWhereUniqueInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type SocialInteraction = {
  __typename?: 'SocialInteraction';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fromUser?: Maybe<User>;
  fromUserId: Scalars['ID'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['String']>;
  toUser?: Maybe<User>;
  toUserId: Scalars['ID'];
  type: SocialInteractionType;
  updatedAt: Scalars['DateTime'];
  viewed: Scalars['Boolean'];
};

export type SocialInteractionConnection = {
  __typename?: 'SocialInteractionConnection';
  aggregate: AggregateSocialInteraction;
  edges: Array<Maybe<SocialInteractionEdge>>;
  pageInfo: PageInfo;
};

export type SocialInteractionCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  fromUserId: Scalars['ID'];
  metadata?: InputMaybe<Scalars['String']>;
  toUserId: Scalars['ID'];
  type: SocialInteractionType;
  viewed: Scalars['Boolean'];
};

export type SocialInteractionEdge = {
  __typename?: 'SocialInteractionEdge';
  cursor: Scalars['String'];
  node: SocialInteraction;
};

export enum SocialInteractionOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  FROMUSERID_ASC = 'fromUserId_ASC',
  FROMUSERID_DESC = 'fromUserId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  TOUSERID_ASC = 'toUserId_ASC',
  TOUSERID_DESC = 'toUserId_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VIEWED_ASC = 'viewed_ASC',
  VIEWED_DESC = 'viewed_DESC'
}

export enum SocialInteractionType {
  GOOD_VIBES = 'GOOD_VIBES'
}

export type SocialInteractionUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  fromUserId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['String']>;
  toUserId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<SocialInteractionType>;
  viewed?: InputMaybe<Scalars['Boolean']>;
};

export type SocialInteractionUpdateManyMutationInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  fromUserId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['String']>;
  toUserId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<SocialInteractionType>;
  viewed?: InputMaybe<Scalars['Boolean']>;
};

export type SocialInteractionWhereInput = {
  AND?: InputMaybe<Array<SocialInteractionWhereInput>>;
  NOT?: InputMaybe<Array<SocialInteractionWhereInput>>;
  OR?: InputMaybe<Array<SocialInteractionWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  fromUserId?: InputMaybe<Scalars['ID']>;
  fromUserId_contains?: InputMaybe<Scalars['ID']>;
  fromUserId_ends_with?: InputMaybe<Scalars['ID']>;
  fromUserId_gt?: InputMaybe<Scalars['ID']>;
  fromUserId_gte?: InputMaybe<Scalars['ID']>;
  fromUserId_in?: InputMaybe<Array<Scalars['ID']>>;
  fromUserId_lt?: InputMaybe<Scalars['ID']>;
  fromUserId_lte?: InputMaybe<Scalars['ID']>;
  fromUserId_not?: InputMaybe<Scalars['ID']>;
  fromUserId_not_contains?: InputMaybe<Scalars['ID']>;
  fromUserId_not_ends_with?: InputMaybe<Scalars['ID']>;
  fromUserId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  fromUserId_not_starts_with?: InputMaybe<Scalars['ID']>;
  fromUserId_starts_with?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['String']>;
  metadata_contains?: InputMaybe<Scalars['String']>;
  metadata_ends_with?: InputMaybe<Scalars['String']>;
  metadata_gt?: InputMaybe<Scalars['String']>;
  metadata_gte?: InputMaybe<Scalars['String']>;
  metadata_in?: InputMaybe<Array<Scalars['String']>>;
  metadata_lt?: InputMaybe<Scalars['String']>;
  metadata_lte?: InputMaybe<Scalars['String']>;
  metadata_not?: InputMaybe<Scalars['String']>;
  metadata_not_contains?: InputMaybe<Scalars['String']>;
  metadata_not_ends_with?: InputMaybe<Scalars['String']>;
  metadata_not_in?: InputMaybe<Array<Scalars['String']>>;
  metadata_not_starts_with?: InputMaybe<Scalars['String']>;
  metadata_starts_with?: InputMaybe<Scalars['String']>;
  toUserId?: InputMaybe<Scalars['ID']>;
  toUserId_contains?: InputMaybe<Scalars['ID']>;
  toUserId_ends_with?: InputMaybe<Scalars['ID']>;
  toUserId_gt?: InputMaybe<Scalars['ID']>;
  toUserId_gte?: InputMaybe<Scalars['ID']>;
  toUserId_in?: InputMaybe<Array<Scalars['ID']>>;
  toUserId_lt?: InputMaybe<Scalars['ID']>;
  toUserId_lte?: InputMaybe<Scalars['ID']>;
  toUserId_not?: InputMaybe<Scalars['ID']>;
  toUserId_not_contains?: InputMaybe<Scalars['ID']>;
  toUserId_not_ends_with?: InputMaybe<Scalars['ID']>;
  toUserId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  toUserId_not_starts_with?: InputMaybe<Scalars['ID']>;
  toUserId_starts_with?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<SocialInteractionType>;
  type_in?: InputMaybe<Array<SocialInteractionType>>;
  type_not?: InputMaybe<SocialInteractionType>;
  type_not_in?: InputMaybe<Array<SocialInteractionType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  viewed?: InputMaybe<Scalars['Boolean']>;
  viewed_not?: InputMaybe<Scalars['Boolean']>;
};

export type SocialInteractionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SocialMoment = {
  __typename?: 'SocialMoment';
  buildings?: Maybe<Array<Maybe<Building>>>;
  buildingsList: Array<Scalars['String']>;
  classification?: Maybe<Array<Maybe<ClassificationCategory>>>;
  commentsCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hasUserViewed?: Maybe<Scalars['Boolean']>;
  hood?: Maybe<Hood>;
  hoodName: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  imageAspectRatio?: Maybe<Scalars['Float']>;
  interactingMembers?: Maybe<Array<Maybe<InteractingMemberProfile>>>;
  latestActivity?: Maybe<Scalars['DateTime']>;
  locationLabel?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  ownerId: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  rejectionReason?: Maybe<Scalars['String']>;
  sentiment?: Maybe<SocialMomentSentiment>;
  socialReactionsAggregate?: Maybe<SocialReactionsAggregate>;
  status: MomentPublishStatus;
  survey?: Maybe<Survey>;
  surveyId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type: MomentType;
  updatedAt: Scalars['DateTime'];
};

export type SocialMomentConnection = {
  __typename?: 'SocialMomentConnection';
  aggregate: AggregateSocialMoment;
  edges: Array<Maybe<SocialMomentEdge>>;
  pageInfo: PageInfo;
};

export type SocialMomentCreateInput = {
  buildingsList?: InputMaybe<SocialMomentCreatebuildingsListInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  latestActivity?: InputMaybe<Scalars['DateTime']>;
  locationLabel?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  rejectionReason?: InputMaybe<Scalars['String']>;
  sentiment?: InputMaybe<SocialMomentSentimentCreateOneInput>;
  status: MomentPublishStatus;
  surveyId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MomentType>;
};

export type SocialMomentCreatebuildingsListInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type SocialMomentEdge = {
  __typename?: 'SocialMomentEdge';
  cursor: Scalars['String'];
  node: SocialMoment;
};

export enum SocialMomentOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  HOODNAME_ASC = 'hoodName_ASC',
  HOODNAME_DESC = 'hoodName_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGE_ASC = 'image_ASC',
  IMAGE_DESC = 'image_DESC',
  LATESTACTIVITY_ASC = 'latestActivity_ASC',
  LATESTACTIVITY_DESC = 'latestActivity_DESC',
  LOCATIONLABEL_ASC = 'locationLabel_ASC',
  LOCATIONLABEL_DESC = 'locationLabel_DESC',
  OWNERID_ASC = 'ownerId_ASC',
  OWNERID_DESC = 'ownerId_DESC',
  PUBLISHEDAT_ASC = 'publishedAt_ASC',
  PUBLISHEDAT_DESC = 'publishedAt_DESC',
  REJECTIONREASON_ASC = 'rejectionReason_ASC',
  REJECTIONREASON_DESC = 'rejectionReason_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  SURVEYID_ASC = 'surveyId_ASC',
  SURVEYID_DESC = 'surveyId_DESC',
  TEXT_ASC = 'text_ASC',
  TEXT_DESC = 'text_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type SocialMomentSentiment = {
  __typename?: 'SocialMomentSentiment';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  sentiment: Sentiment;
  sentimentScore: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SocialMomentSentimentConnection = {
  __typename?: 'SocialMomentSentimentConnection';
  aggregate: AggregateSocialMomentSentiment;
  edges: Array<Maybe<SocialMomentSentimentEdge>>;
  pageInfo: PageInfo;
};

export type SocialMomentSentimentCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  sentiment: Sentiment;
  sentimentScore: Scalars['String'];
};

export type SocialMomentSentimentCreateOneInput = {
  connect?: InputMaybe<SocialMomentSentimentWhereUniqueInput>;
  create?: InputMaybe<SocialMomentSentimentCreateInput>;
};

export type SocialMomentSentimentEdge = {
  __typename?: 'SocialMomentSentimentEdge';
  cursor: Scalars['String'];
  node: SocialMomentSentiment;
};

export enum SocialMomentSentimentOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  SENTIMENTSCORE_ASC = 'sentimentScore_ASC',
  SENTIMENTSCORE_DESC = 'sentimentScore_DESC',
  SENTIMENT_ASC = 'sentiment_ASC',
  SENTIMENT_DESC = 'sentiment_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type SocialMomentSentimentUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  sentiment?: InputMaybe<Sentiment>;
  sentimentScore?: InputMaybe<Scalars['String']>;
};

export type SocialMomentSentimentUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  sentiment?: InputMaybe<Sentiment>;
  sentimentScore?: InputMaybe<Scalars['String']>;
};

export type SocialMomentSentimentUpdateOneInput = {
  connect?: InputMaybe<SocialMomentSentimentWhereUniqueInput>;
  create?: InputMaybe<SocialMomentSentimentCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SocialMomentSentimentUpdateDataInput>;
  upsert?: InputMaybe<SocialMomentSentimentUpsertNestedInput>;
};

export type SocialMomentSentimentUpsertNestedInput = {
  create: SocialMomentSentimentCreateInput;
  update: SocialMomentSentimentUpdateDataInput;
};

export type SocialMomentSentimentWhereInput = {
  AND?: InputMaybe<Array<SocialMomentSentimentWhereInput>>;
  NOT?: InputMaybe<Array<SocialMomentSentimentWhereInput>>;
  OR?: InputMaybe<Array<SocialMomentSentimentWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  sentiment?: InputMaybe<Sentiment>;
  sentimentScore?: InputMaybe<Scalars['String']>;
  sentimentScore_contains?: InputMaybe<Scalars['String']>;
  sentimentScore_ends_with?: InputMaybe<Scalars['String']>;
  sentimentScore_gt?: InputMaybe<Scalars['String']>;
  sentimentScore_gte?: InputMaybe<Scalars['String']>;
  sentimentScore_in?: InputMaybe<Array<Scalars['String']>>;
  sentimentScore_lt?: InputMaybe<Scalars['String']>;
  sentimentScore_lte?: InputMaybe<Scalars['String']>;
  sentimentScore_not?: InputMaybe<Scalars['String']>;
  sentimentScore_not_contains?: InputMaybe<Scalars['String']>;
  sentimentScore_not_ends_with?: InputMaybe<Scalars['String']>;
  sentimentScore_not_in?: InputMaybe<Array<Scalars['String']>>;
  sentimentScore_not_starts_with?: InputMaybe<Scalars['String']>;
  sentimentScore_starts_with?: InputMaybe<Scalars['String']>;
  sentiment_in?: InputMaybe<Array<Sentiment>>;
  sentiment_not?: InputMaybe<Sentiment>;
  sentiment_not_in?: InputMaybe<Array<Sentiment>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SocialMomentSentimentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SocialMomentUpdateInput = {
  buildingsList?: InputMaybe<SocialMomentUpdatebuildingsListInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hoodName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  latestActivity?: InputMaybe<Scalars['DateTime']>;
  locationLabel?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  rejectionReason?: InputMaybe<Scalars['String']>;
  sentiment?: InputMaybe<SocialMomentSentimentUpdateOneInput>;
  status?: InputMaybe<MomentPublishStatus>;
  surveyId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MomentType>;
};

export type SocialMomentUpdatebuildingsListInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type SocialMomentView = {
  __typename?: 'SocialMomentView';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  momentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type SocialMomentViewConnection = {
  __typename?: 'SocialMomentViewConnection';
  aggregate: AggregateSocialMomentView;
  edges: Array<Maybe<SocialMomentViewEdge>>;
  pageInfo: PageInfo;
};

export type SocialMomentViewCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  momentId: Scalars['String'];
  userId: Scalars['String'];
};

export type SocialMomentViewEdge = {
  __typename?: 'SocialMomentViewEdge';
  cursor: Scalars['String'];
  node: SocialMomentView;
};

export enum SocialMomentViewOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  MOMENTID_ASC = 'momentId_ASC',
  MOMENTID_DESC = 'momentId_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type SocialMomentViewUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  momentId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SocialMomentViewWhereInput = {
  AND?: InputMaybe<Array<SocialMomentViewWhereInput>>;
  NOT?: InputMaybe<Array<SocialMomentViewWhereInput>>;
  OR?: InputMaybe<Array<SocialMomentViewWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  momentId?: InputMaybe<Scalars['String']>;
  momentId_contains?: InputMaybe<Scalars['String']>;
  momentId_ends_with?: InputMaybe<Scalars['String']>;
  momentId_gt?: InputMaybe<Scalars['String']>;
  momentId_gte?: InputMaybe<Scalars['String']>;
  momentId_in?: InputMaybe<Array<Scalars['String']>>;
  momentId_lt?: InputMaybe<Scalars['String']>;
  momentId_lte?: InputMaybe<Scalars['String']>;
  momentId_not?: InputMaybe<Scalars['String']>;
  momentId_not_contains?: InputMaybe<Scalars['String']>;
  momentId_not_ends_with?: InputMaybe<Scalars['String']>;
  momentId_not_in?: InputMaybe<Array<Scalars['String']>>;
  momentId_not_starts_with?: InputMaybe<Scalars['String']>;
  momentId_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type SocialMomentViewWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SocialMomentWhereInput = {
  AND?: InputMaybe<Array<SocialMomentWhereInput>>;
  NOT?: InputMaybe<Array<SocialMomentWhereInput>>;
  OR?: InputMaybe<Array<SocialMomentWhereInput>>;
  buildingsList_contains?: InputMaybe<Scalars['String']>;
  buildingsList_contains_every?: InputMaybe<Array<Scalars['String']>>;
  buildingsList_contains_some?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hoodName?: InputMaybe<Scalars['String']>;
  hoodName_contains?: InputMaybe<Scalars['String']>;
  hoodName_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_gt?: InputMaybe<Scalars['String']>;
  hoodName_gte?: InputMaybe<Scalars['String']>;
  hoodName_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_lt?: InputMaybe<Scalars['String']>;
  hoodName_lte?: InputMaybe<Scalars['String']>;
  hoodName_not?: InputMaybe<Scalars['String']>;
  hoodName_not_contains?: InputMaybe<Scalars['String']>;
  hoodName_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodName_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodName_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['String']>;
  image_contains?: InputMaybe<Scalars['String']>;
  image_ends_with?: InputMaybe<Scalars['String']>;
  image_gt?: InputMaybe<Scalars['String']>;
  image_gte?: InputMaybe<Scalars['String']>;
  image_in?: InputMaybe<Array<Scalars['String']>>;
  image_lt?: InputMaybe<Scalars['String']>;
  image_lte?: InputMaybe<Scalars['String']>;
  image_not?: InputMaybe<Scalars['String']>;
  image_not_contains?: InputMaybe<Scalars['String']>;
  image_not_ends_with?: InputMaybe<Scalars['String']>;
  image_not_in?: InputMaybe<Array<Scalars['String']>>;
  image_not_starts_with?: InputMaybe<Scalars['String']>;
  image_starts_with?: InputMaybe<Scalars['String']>;
  latestActivity?: InputMaybe<Scalars['DateTime']>;
  latestActivity_gt?: InputMaybe<Scalars['DateTime']>;
  latestActivity_gte?: InputMaybe<Scalars['DateTime']>;
  latestActivity_in?: InputMaybe<Array<Scalars['DateTime']>>;
  latestActivity_lt?: InputMaybe<Scalars['DateTime']>;
  latestActivity_lte?: InputMaybe<Scalars['DateTime']>;
  latestActivity_not?: InputMaybe<Scalars['DateTime']>;
  latestActivity_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  locationLabel?: InputMaybe<Scalars['String']>;
  locationLabel_contains?: InputMaybe<Scalars['String']>;
  locationLabel_ends_with?: InputMaybe<Scalars['String']>;
  locationLabel_gt?: InputMaybe<Scalars['String']>;
  locationLabel_gte?: InputMaybe<Scalars['String']>;
  locationLabel_in?: InputMaybe<Array<Scalars['String']>>;
  locationLabel_lt?: InputMaybe<Scalars['String']>;
  locationLabel_lte?: InputMaybe<Scalars['String']>;
  locationLabel_not?: InputMaybe<Scalars['String']>;
  locationLabel_not_contains?: InputMaybe<Scalars['String']>;
  locationLabel_not_ends_with?: InputMaybe<Scalars['String']>;
  locationLabel_not_in?: InputMaybe<Array<Scalars['String']>>;
  locationLabel_not_starts_with?: InputMaybe<Scalars['String']>;
  locationLabel_starts_with?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  ownerId_contains?: InputMaybe<Scalars['String']>;
  ownerId_ends_with?: InputMaybe<Scalars['String']>;
  ownerId_gt?: InputMaybe<Scalars['String']>;
  ownerId_gte?: InputMaybe<Scalars['String']>;
  ownerId_in?: InputMaybe<Array<Scalars['String']>>;
  ownerId_lt?: InputMaybe<Scalars['String']>;
  ownerId_lte?: InputMaybe<Scalars['String']>;
  ownerId_not?: InputMaybe<Scalars['String']>;
  ownerId_not_contains?: InputMaybe<Scalars['String']>;
  ownerId_not_ends_with?: InputMaybe<Scalars['String']>;
  ownerId_not_in?: InputMaybe<Array<Scalars['String']>>;
  ownerId_not_starts_with?: InputMaybe<Scalars['String']>;
  ownerId_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  rejectionReason?: InputMaybe<Scalars['String']>;
  rejectionReason_contains?: InputMaybe<Scalars['String']>;
  rejectionReason_ends_with?: InputMaybe<Scalars['String']>;
  rejectionReason_gt?: InputMaybe<Scalars['String']>;
  rejectionReason_gte?: InputMaybe<Scalars['String']>;
  rejectionReason_in?: InputMaybe<Array<Scalars['String']>>;
  rejectionReason_lt?: InputMaybe<Scalars['String']>;
  rejectionReason_lte?: InputMaybe<Scalars['String']>;
  rejectionReason_not?: InputMaybe<Scalars['String']>;
  rejectionReason_not_contains?: InputMaybe<Scalars['String']>;
  rejectionReason_not_ends_with?: InputMaybe<Scalars['String']>;
  rejectionReason_not_in?: InputMaybe<Array<Scalars['String']>>;
  rejectionReason_not_starts_with?: InputMaybe<Scalars['String']>;
  rejectionReason_starts_with?: InputMaybe<Scalars['String']>;
  sentiment?: InputMaybe<SocialMomentSentimentWhereInput>;
  status?: InputMaybe<MomentPublishStatus>;
  status_in?: InputMaybe<Array<MomentPublishStatus>>;
  status_not?: InputMaybe<MomentPublishStatus>;
  status_not_in?: InputMaybe<Array<MomentPublishStatus>>;
  surveyId?: InputMaybe<Scalars['String']>;
  surveyId_contains?: InputMaybe<Scalars['String']>;
  surveyId_ends_with?: InputMaybe<Scalars['String']>;
  surveyId_gt?: InputMaybe<Scalars['String']>;
  surveyId_gte?: InputMaybe<Scalars['String']>;
  surveyId_in?: InputMaybe<Array<Scalars['String']>>;
  surveyId_lt?: InputMaybe<Scalars['String']>;
  surveyId_lte?: InputMaybe<Scalars['String']>;
  surveyId_not?: InputMaybe<Scalars['String']>;
  surveyId_not_contains?: InputMaybe<Scalars['String']>;
  surveyId_not_ends_with?: InputMaybe<Scalars['String']>;
  surveyId_not_in?: InputMaybe<Array<Scalars['String']>>;
  surveyId_not_starts_with?: InputMaybe<Scalars['String']>;
  surveyId_starts_with?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_ends_with?: InputMaybe<Scalars['String']>;
  text_gt?: InputMaybe<Scalars['String']>;
  text_gte?: InputMaybe<Scalars['String']>;
  text_in?: InputMaybe<Array<Scalars['String']>>;
  text_lt?: InputMaybe<Scalars['String']>;
  text_lte?: InputMaybe<Scalars['String']>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<Scalars['String']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  text_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MomentType>;
  type_in?: InputMaybe<Array<MomentType>>;
  type_not?: InputMaybe<MomentType>;
  type_not_in?: InputMaybe<Array<MomentType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SocialMomentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SocialReaction = {
  __typename?: 'SocialReaction';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  originalEntityId: Scalars['String'];
  originalEntityName: Scalars['String'];
  type: SocialReactionType;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type SocialReactionConnection = {
  __typename?: 'SocialReactionConnection';
  aggregate: AggregateSocialReaction;
  edges: Array<Maybe<SocialReactionEdge>>;
  pageInfo: PageInfo;
};

export type SocialReactionCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  originalEntityId: Scalars['String'];
  originalEntityName: Scalars['String'];
  type: SocialReactionType;
  userId: Scalars['ID'];
};

export type SocialReactionEdge = {
  __typename?: 'SocialReactionEdge';
  cursor: Scalars['String'];
  node: SocialReaction;
};

export enum SocialReactionOperation {
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}

export enum SocialReactionOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ORIGINALENTITYID_ASC = 'originalEntityId_ASC',
  ORIGINALENTITYID_DESC = 'originalEntityId_DESC',
  ORIGINALENTITYNAME_ASC = 'originalEntityName_ASC',
  ORIGINALENTITYNAME_DESC = 'originalEntityName_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export enum SocialReactionType {
  LIKE = 'LIKE'
}

export type SocialReactionUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SocialReactionType>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type SocialReactionWhereInput = {
  AND?: InputMaybe<Array<SocialReactionWhereInput>>;
  NOT?: InputMaybe<Array<SocialReactionWhereInput>>;
  OR?: InputMaybe<Array<SocialReactionWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  originalEntityId?: InputMaybe<Scalars['String']>;
  originalEntityId_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_gt?: InputMaybe<Scalars['String']>;
  originalEntityId_gte?: InputMaybe<Scalars['String']>;
  originalEntityId_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_lt?: InputMaybe<Scalars['String']>;
  originalEntityId_lte?: InputMaybe<Scalars['String']>;
  originalEntityId_not?: InputMaybe<Scalars['String']>;
  originalEntityId_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityId_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityId_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityId_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityId_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName?: InputMaybe<Scalars['String']>;
  originalEntityName_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_gt?: InputMaybe<Scalars['String']>;
  originalEntityName_gte?: InputMaybe<Scalars['String']>;
  originalEntityName_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_lt?: InputMaybe<Scalars['String']>;
  originalEntityName_lte?: InputMaybe<Scalars['String']>;
  originalEntityName_not?: InputMaybe<Scalars['String']>;
  originalEntityName_not_contains?: InputMaybe<Scalars['String']>;
  originalEntityName_not_ends_with?: InputMaybe<Scalars['String']>;
  originalEntityName_not_in?: InputMaybe<Array<Scalars['String']>>;
  originalEntityName_not_starts_with?: InputMaybe<Scalars['String']>;
  originalEntityName_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SocialReactionType>;
  type_in?: InputMaybe<Array<SocialReactionType>>;
  type_not?: InputMaybe<SocialReactionType>;
  type_not_in?: InputMaybe<Array<SocialReactionType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['ID']>;
  userId_contains?: InputMaybe<Scalars['ID']>;
  userId_ends_with?: InputMaybe<Scalars['ID']>;
  userId_gt?: InputMaybe<Scalars['ID']>;
  userId_gte?: InputMaybe<Scalars['ID']>;
  userId_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_lt?: InputMaybe<Scalars['ID']>;
  userId_lte?: InputMaybe<Scalars['ID']>;
  userId_not?: InputMaybe<Scalars['ID']>;
  userId_not_contains?: InputMaybe<Scalars['ID']>;
  userId_not_ends_with?: InputMaybe<Scalars['ID']>;
  userId_not_in?: InputMaybe<Array<Scalars['ID']>>;
  userId_not_starts_with?: InputMaybe<Scalars['ID']>;
  userId_starts_with?: InputMaybe<Scalars['ID']>;
};

export type SocialReactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SocialReactionsAggregate = {
  __typename?: 'SocialReactionsAggregate';
  LIKE?: Maybe<SocialReactionsAggregateField>;
  id: Scalars['ID'];
};

export type SocialReactionsAggregateField = {
  __typename?: 'SocialReactionsAggregateField';
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isUserReaction?: Maybe<Scalars['Boolean']>;
};

export type SocialTag = {
  __typename?: 'SocialTag';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dummy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type SocialTagConnection = {
  __typename?: 'SocialTagConnection';
  aggregate: AggregateSocialTag;
  edges: Array<Maybe<SocialTagEdge>>;
  pageInfo: PageInfo;
};

export type SocialTagCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type SocialTagCreateManyInput = {
  connect?: InputMaybe<Array<SocialTagWhereUniqueInput>>;
  create?: InputMaybe<Array<SocialTagCreateInput>>;
};

export type SocialTagEdge = {
  __typename?: 'SocialTagEdge';
  cursor: Scalars['String'];
  node: SocialTag;
};

export enum SocialTagOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DUMMY_ASC = 'dummy_ASC',
  DUMMY_DESC = 'dummy_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC'
}

export type SocialTagScalarWhereInput = {
  AND?: InputMaybe<Array<SocialTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<SocialTagScalarWhereInput>>;
  OR?: InputMaybe<Array<SocialTagScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dummy?: InputMaybe<Scalars['String']>;
  dummy_contains?: InputMaybe<Scalars['String']>;
  dummy_ends_with?: InputMaybe<Scalars['String']>;
  dummy_gt?: InputMaybe<Scalars['String']>;
  dummy_gte?: InputMaybe<Scalars['String']>;
  dummy_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_lt?: InputMaybe<Scalars['String']>;
  dummy_lte?: InputMaybe<Scalars['String']>;
  dummy_not?: InputMaybe<Scalars['String']>;
  dummy_not_contains?: InputMaybe<Scalars['String']>;
  dummy_not_ends_with?: InputMaybe<Scalars['String']>;
  dummy_not_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_not_starts_with?: InputMaybe<Scalars['String']>;
  dummy_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_ends_with?: InputMaybe<Scalars['String']>;
  value_gt?: InputMaybe<Scalars['String']>;
  value_gte?: InputMaybe<Scalars['String']>;
  value_in?: InputMaybe<Array<Scalars['String']>>;
  value_lt?: InputMaybe<Scalars['String']>;
  value_lte?: InputMaybe<Scalars['String']>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<Scalars['String']>>;
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export type SocialTagUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SocialTagUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SocialTagUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dummy?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SocialTagUpdateManyInput = {
  connect?: InputMaybe<Array<SocialTagWhereUniqueInput>>;
  create?: InputMaybe<Array<SocialTagCreateInput>>;
  delete?: InputMaybe<Array<SocialTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SocialTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SocialTagWhereUniqueInput>>;
  set?: InputMaybe<Array<SocialTagWhereUniqueInput>>;
  update?: InputMaybe<Array<SocialTagUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<SocialTagUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SocialTagUpsertWithWhereUniqueNestedInput>>;
};

export type SocialTagUpdateManyWithWhereNestedInput = {
  data: SocialTagUpdateManyDataInput;
  where: SocialTagScalarWhereInput;
};

export type SocialTagUpdateWithWhereUniqueNestedInput = {
  data: SocialTagUpdateDataInput;
  where: SocialTagWhereUniqueInput;
};

export type SocialTagUpsertWithWhereUniqueNestedInput = {
  create: SocialTagCreateInput;
  update: SocialTagUpdateDataInput;
  where: SocialTagWhereUniqueInput;
};

export type SocialTagWhereInput = {
  AND?: InputMaybe<Array<SocialTagWhereInput>>;
  NOT?: InputMaybe<Array<SocialTagWhereInput>>;
  OR?: InputMaybe<Array<SocialTagWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dummy?: InputMaybe<Scalars['String']>;
  dummy_contains?: InputMaybe<Scalars['String']>;
  dummy_ends_with?: InputMaybe<Scalars['String']>;
  dummy_gt?: InputMaybe<Scalars['String']>;
  dummy_gte?: InputMaybe<Scalars['String']>;
  dummy_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_lt?: InputMaybe<Scalars['String']>;
  dummy_lte?: InputMaybe<Scalars['String']>;
  dummy_not?: InputMaybe<Scalars['String']>;
  dummy_not_contains?: InputMaybe<Scalars['String']>;
  dummy_not_ends_with?: InputMaybe<Scalars['String']>;
  dummy_not_in?: InputMaybe<Array<Scalars['String']>>;
  dummy_not_starts_with?: InputMaybe<Scalars['String']>;
  dummy_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_ends_with?: InputMaybe<Scalars['String']>;
  value_gt?: InputMaybe<Scalars['String']>;
  value_gte?: InputMaybe<Scalars['String']>;
  value_in?: InputMaybe<Array<Scalars['String']>>;
  value_lt?: InputMaybe<Scalars['String']>;
  value_lte?: InputMaybe<Scalars['String']>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<Scalars['String']>>;
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export type SocialTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SocialTagsForMemberProfileUpdateInput = {
  connect?: InputMaybe<Array<Scalars['String']>>;
  disconnect?: InputMaybe<Array<Scalars['String']>>;
  privacy?: InputMaybe<MemberProfilePrivacy>;
};

export type StatusWhereInput = {
  userMail: Scalars['String'];
};

export type SubProduct = {
  __typename?: 'SubProduct';
  _empty?: Maybe<Scalars['String']>;
  allowEditPrice?: Maybe<Scalars['Boolean']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isAutoGenerated: Scalars['Boolean'];
  isMandatory: Scalars['Boolean'];
  price?: Maybe<Scalars['Float']>;
  product: Product;
  productType: ProductType;
  updatedAt: Scalars['DateTime'];
};

export type SubProductConnection = {
  __typename?: 'SubProductConnection';
  aggregate: AggregateSubProduct;
  edges: Array<Maybe<SubProductEdge>>;
  pageInfo: PageInfo;
};

export type SubProductCreateInput = {
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerated: Scalars['Boolean'];
  isMandatory: Scalars['Boolean'];
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateOneWithoutSubProductsInput;
  productType: ProductTypeCreateOneInput;
};

export type SubProductCreateManyWithoutProductInput = {
  connect?: InputMaybe<Array<SubProductWhereUniqueInput>>;
  create?: InputMaybe<Array<SubProductCreateWithoutProductInput>>;
};

export type SubProductCreateOneContractsPersistencyInput = {
  connect?: InputMaybe<SubProductWhereUniqueContractsPersistencyInput>;
};

export type SubProductCreateOneInput = {
  connect?: InputMaybe<SubProductWhereUniqueInput>;
  create?: InputMaybe<SubProductCreateInput>;
};

export type SubProductCreateWithoutProductInput = {
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerated: Scalars['Boolean'];
  isMandatory: Scalars['Boolean'];
  price?: InputMaybe<Scalars['Float']>;
  productType: ProductTypeCreateOneInput;
};

export type SubProductEdge = {
  __typename?: 'SubProductEdge';
  cursor: Scalars['String'];
  node: SubProduct;
};

export enum SubProductOrderByInput {
  ALLOWEDITPRICE_ASC = 'allowEditPrice_ASC',
  ALLOWEDITPRICE_DESC = 'allowEditPrice_DESC',
  COST_ASC = 'cost_ASC',
  COST_DESC = 'cost_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISAUTOGENERATED_ASC = 'isAutoGenerated_ASC',
  ISAUTOGENERATED_DESC = 'isAutoGenerated_DESC',
  ISMANDATORY_ASC = 'isMandatory_ASC',
  ISMANDATORY_DESC = 'isMandatory_DESC',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type SubProductScalarWhereInput = {
  AND?: InputMaybe<Array<SubProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubProductScalarWhereInput>>;
  OR?: InputMaybe<Array<SubProductScalarWhereInput>>;
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  allowEditPrice_not?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Float']>;
  cost_gt?: InputMaybe<Scalars['Float']>;
  cost_gte?: InputMaybe<Scalars['Float']>;
  cost_in?: InputMaybe<Array<Scalars['Float']>>;
  cost_lt?: InputMaybe<Scalars['Float']>;
  cost_lte?: InputMaybe<Scalars['Float']>;
  cost_not?: InputMaybe<Scalars['Float']>;
  cost_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  isMandatory_not?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  price_gt?: InputMaybe<Scalars['Float']>;
  price_gte?: InputMaybe<Scalars['Float']>;
  price_in?: InputMaybe<Array<Scalars['Float']>>;
  price_lt?: InputMaybe<Scalars['Float']>;
  price_lte?: InputMaybe<Scalars['Float']>;
  price_not?: InputMaybe<Scalars['Float']>;
  price_not_in?: InputMaybe<Array<Scalars['Float']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SubProductUpdateDataInput = {
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutSubProductsInput>;
  productType?: InputMaybe<ProductTypeUpdateOneRequiredInput>;
};

export type SubProductUpdateInput = {
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutSubProductsInput>;
  productType?: InputMaybe<ProductTypeUpdateOneRequiredInput>;
};

export type SubProductUpdateManyDataInput = {
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
};

export type SubProductUpdateManyWithWhereNestedInput = {
  data: SubProductUpdateManyDataInput;
  where: SubProductScalarWhereInput;
};

export type SubProductUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<SubProductWhereUniqueInput>>;
  create?: InputMaybe<Array<SubProductCreateWithoutProductInput>>;
  delete?: InputMaybe<Array<SubProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubProductWhereUniqueInput>>;
  set?: InputMaybe<Array<SubProductWhereUniqueInput>>;
  update?: InputMaybe<Array<SubProductUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<SubProductUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SubProductUpsertWithWhereUniqueWithoutProductInput>>;
};

export type SubProductUpdateOneRequiredInput = {
  connect?: InputMaybe<SubProductWhereUniqueInput>;
  create?: InputMaybe<SubProductCreateInput>;
  update?: InputMaybe<SubProductUpdateDataInput>;
  upsert?: InputMaybe<SubProductUpsertNestedInput>;
};

export type SubProductUpdateWithWhereUniqueWithoutProductInput = {
  data: SubProductUpdateWithoutProductDataInput;
  where: SubProductWhereUniqueInput;
};

export type SubProductUpdateWithoutProductDataInput = {
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductTypeUpdateOneRequiredInput>;
};

export type SubProductUpsertNestedInput = {
  create: SubProductCreateInput;
  update: SubProductUpdateDataInput;
};

export type SubProductUpsertWithWhereUniqueWithoutProductInput = {
  create: SubProductCreateWithoutProductInput;
  update: SubProductUpdateWithoutProductDataInput;
  where: SubProductWhereUniqueInput;
};

export type SubProductWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<SubProductWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<SubProductWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<SubProductWhereContractsPersistencyInput>>;
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  allowEditPrice_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  isMandatory_not?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  price_gt?: InputMaybe<Scalars['Float']>;
  price_gte?: InputMaybe<Scalars['Float']>;
  price_in?: InputMaybe<Array<Scalars['Float']>>;
  price_lt?: InputMaybe<Scalars['Float']>;
  price_lte?: InputMaybe<Scalars['Float']>;
  price_not?: InputMaybe<Scalars['Float']>;
  price_not_in?: InputMaybe<Array<Scalars['Float']>>;
  product?: InputMaybe<ProductWhereContractsPersistencyInput>;
  productType?: InputMaybe<ProductTypeWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SubProductWhereInput = {
  AND?: InputMaybe<Array<SubProductWhereInput>>;
  NOT?: InputMaybe<Array<SubProductWhereInput>>;
  OR?: InputMaybe<Array<SubProductWhereInput>>;
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  allowEditPrice_not?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Float']>;
  cost_gt?: InputMaybe<Scalars['Float']>;
  cost_gte?: InputMaybe<Scalars['Float']>;
  cost_in?: InputMaybe<Array<Scalars['Float']>>;
  cost_lt?: InputMaybe<Scalars['Float']>;
  cost_lte?: InputMaybe<Scalars['Float']>;
  cost_not?: InputMaybe<Scalars['Float']>;
  cost_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  isMandatory_not?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  price_gt?: InputMaybe<Scalars['Float']>;
  price_gte?: InputMaybe<Scalars['Float']>;
  price_in?: InputMaybe<Array<Scalars['Float']>>;
  price_lt?: InputMaybe<Scalars['Float']>;
  price_lte?: InputMaybe<Scalars['Float']>;
  price_not?: InputMaybe<Scalars['Float']>;
  price_not_in?: InputMaybe<Array<Scalars['Float']>>;
  product?: InputMaybe<ProductWhereInput>;
  productType?: InputMaybe<ProductTypeWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SubProductWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<SubProductWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<SubProductWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<SubProductWherePersistencyContractsInput>>;
  allowEditPrice?: InputMaybe<Scalars['Boolean']>;
  allowEditPrice_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']>;
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  isMandatory_not?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  price_gt?: InputMaybe<Scalars['Float']>;
  price_gte?: InputMaybe<Scalars['Float']>;
  price_in?: InputMaybe<Array<Scalars['Float']>>;
  price_lt?: InputMaybe<Scalars['Float']>;
  price_lte?: InputMaybe<Scalars['Float']>;
  price_not?: InputMaybe<Scalars['Float']>;
  price_not_in?: InputMaybe<Array<Scalars['Float']>>;
  product?: InputMaybe<ProductWherePersistencyContractsInput>;
  productType?: InputMaybe<ProductTypeWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SubProductWhereUniqueContractsPersistencyInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SubProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SubmitPollVoteInput = {
  option?: InputMaybe<Scalars['String']>;
};

export type SubmitSurveyResponseInput = {
  comment?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['ID'];
  value: Scalars['String'];
};

export type SubscribeToProductInput = {
  externalSubscriptionId: Scalars['String'];
  serviceProductId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type SubscribeToProductResult = {
  __typename?: 'SubscribeToProductResult';
  subscriptionContract: SubscriptionContract;
};

export type SubscriptionContract = {
  __typename?: 'SubscriptionContract';
  billableItemIds: Array<Scalars['String']>;
  billableItems: Array<BillableItem>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalSubscriptionId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isHandled?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  orderNumber: Scalars['String'];
  originalEndDate?: Maybe<Scalars['DateTime']>;
  serviceProduct: ServiceProduct;
  startDate?: Maybe<Scalars['DateTime']>;
  status: SubscriptionContractStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type SubscriptionContractConnection = {
  __typename?: 'SubscriptionContractConnection';
  aggregate: AggregateSubscriptionContract;
  edges: Array<Maybe<SubscriptionContractEdge>>;
  pageInfo: PageInfo;
};

export type SubscriptionContractCreateInput = {
  billableItemIds?: InputMaybe<SubscriptionContractCreatebillableItemIdsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  externalSubscriptionId?: InputMaybe<Scalars['String']>;
  isHandled?: InputMaybe<Scalars['Boolean']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  serviceProduct: ServiceProductCreateOneInput;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status: SubscriptionContractStatus;
  userId: Scalars['String'];
};

export type SubscriptionContractCreatebillableItemIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type SubscriptionContractEdge = {
  __typename?: 'SubscriptionContractEdge';
  cursor: Scalars['String'];
  node: SubscriptionContract;
};

export enum SubscriptionContractOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENDDATE_ASC = 'endDate_ASC',
  ENDDATE_DESC = 'endDate_DESC',
  EXTERNALSUBSCRIPTIONID_ASC = 'externalSubscriptionId_ASC',
  EXTERNALSUBSCRIPTIONID_DESC = 'externalSubscriptionId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISHANDLED_ASC = 'isHandled_ASC',
  ISHANDLED_DESC = 'isHandled_DESC',
  ORIGINALENDDATE_ASC = 'originalEndDate_ASC',
  ORIGINALENDDATE_DESC = 'originalEndDate_DESC',
  STARTDATE_ASC = 'startDate_ASC',
  STARTDATE_DESC = 'startDate_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export enum SubscriptionContractStatus {
  ACTIVE = 'ACTIVE',
  CANCELATION_PENDING = 'CANCELATION_PENDING',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING'
}

export type SubscriptionContractUpdateInput = {
  billableItemIds?: InputMaybe<SubscriptionContractUpdatebillableItemIdsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  externalSubscriptionId?: InputMaybe<Scalars['String']>;
  isHandled?: InputMaybe<Scalars['Boolean']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  serviceProduct?: InputMaybe<ServiceProductUpdateOneRequiredInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<SubscriptionContractStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionContractUpdatebillableItemIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type SubscriptionContractWhereInput = {
  AND?: InputMaybe<Array<SubscriptionContractWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionContractWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionContractWhereInput>>;
  billableItemIds_contains?: InputMaybe<Scalars['String']>;
  billableItemIds_contains_every?: InputMaybe<Array<Scalars['String']>>;
  billableItemIds_contains_some?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  externalSubscriptionId?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_contains?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_ends_with?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_gt?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_gte?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_in?: InputMaybe<Array<Scalars['String']>>;
  externalSubscriptionId_lt?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_lte?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_not?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_not_contains?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalSubscriptionId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalSubscriptionId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isHandled?: InputMaybe<Scalars['Boolean']>;
  isHandled_not?: InputMaybe<Scalars['Boolean']>;
  originalEndDate?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_gte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  originalEndDate_lt?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_lte?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not?: InputMaybe<Scalars['DateTime']>;
  originalEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  serviceProduct?: InputMaybe<ServiceProductWhereInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<SubscriptionContractStatus>;
  status_in?: InputMaybe<Array<SubscriptionContractStatus>>;
  status_not?: InputMaybe<SubscriptionContractStatus>;
  status_not_in?: InputMaybe<Array<SubscriptionContractStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type SubscriptionContractWhereUniqueInput = {
  externalSubscriptionId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Survey = {
  __typename?: 'Survey';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  hood?: Maybe<Hood>;
  hoodId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isPublicAccess?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  questions?: Maybe<Array<SurveyQuestion>>;
  status?: Maybe<UserSurveyStatus>;
  surveyUserActions?: Maybe<Array<SurveyUserAction>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SurveyTypes>;
  updatedAt: Scalars['DateTime'];
};


export type SurveyQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyQuestionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyQuestionWhereInput>;
};


export type SurveyStatusArgs = {
  where: StatusWhereInput;
};


export type SurveySurveyUserActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyUserActionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyUserActionWhereInput>;
};

export enum SurveyActions {
  COMPLETE_SURVEY = 'COMPLETE_SURVEY',
  SKIP_SURVEY = 'SKIP_SURVEY'
}

export enum SurveyAnswer {
  SATISFACTION_NOT_DISAPPOINTED = 'SATISFACTION_NOT_DISAPPOINTED',
  SATISFACTION_SOMEHOW_DISAPPOINTED = 'SATISFACTION_SOMEHOW_DISAPPOINTED',
  SATISFACTION_VERY_DISAPPOINTED = 'SATISFACTION_VERY_DISAPPOINTED'
}

export type SurveyConnection = {
  __typename?: 'SurveyConnection';
  aggregate: AggregateSurvey;
  edges: Array<Maybe<SurveyEdge>>;
  pageInfo: PageInfo;
};

export type SurveyCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isPublicAccess?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  questions?: InputMaybe<SurveyQuestionCreateManyWithoutSurveyInput>;
  surveyUserActions?: InputMaybe<SurveyUserActionCreateManyWithoutSurveyInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyTypes>;
};

export type SurveyCreateOneWithoutQuestionsInput = {
  connect?: InputMaybe<SurveyWhereUniqueInput>;
  create?: InputMaybe<SurveyCreateWithoutQuestionsInput>;
};

export type SurveyCreateOneWithoutSurveyUserActionsInput = {
  connect?: InputMaybe<SurveyWhereUniqueInput>;
  create?: InputMaybe<SurveyCreateWithoutSurveyUserActionsInput>;
};

export type SurveyCreateWithoutQuestionsInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isPublicAccess?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  surveyUserActions?: InputMaybe<SurveyUserActionCreateManyWithoutSurveyInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyTypes>;
};

export type SurveyCreateWithoutSurveyUserActionsInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isPublicAccess?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  questions?: InputMaybe<SurveyQuestionCreateManyWithoutSurveyInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyTypes>;
};

export type SurveyEdge = {
  __typename?: 'SurveyEdge';
  cursor: Scalars['String'];
  node: Survey;
};

export enum SurveyOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ENABLED_ASC = 'enabled_ASC',
  ENABLED_DESC = 'enabled_DESC',
  ENDDATE_ASC = 'endDate_ASC',
  ENDDATE_DESC = 'endDate_DESC',
  HOODID_ASC = 'hoodId_ASC',
  HOODID_DESC = 'hoodId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISANONYMOUS_ASC = 'isAnonymous_ASC',
  ISANONYMOUS_DESC = 'isAnonymous_DESC',
  ISEXPIRED_ASC = 'isExpired_ASC',
  ISEXPIRED_DESC = 'isExpired_DESC',
  ISPUBLICACCESS_ASC = 'isPublicAccess_ASC',
  ISPUBLICACCESS_DESC = 'isPublicAccess_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  _empty?: Maybe<Scalars['String']>;
  addCommentField?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['Json']>;
  options?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  responses?: Maybe<Array<SurveyQuestionResponse>>;
  survey: Survey;
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  votes: Array<SurveyQuestionVote>;
};


export type SurveyQuestionResponsesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SurveyQuestionResponseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SurveyQuestionResponseWhereInput>;
};

export type SurveyQuestionConnection = {
  __typename?: 'SurveyQuestionConnection';
  aggregate: AggregateSurveyQuestion;
  edges: Array<Maybe<SurveyQuestionEdge>>;
  pageInfo: PageInfo;
};

export type SurveyQuestionCreateInput = {
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  options?: InputMaybe<Scalars['Json']>;
  order?: InputMaybe<Scalars['Int']>;
  responses?: InputMaybe<SurveyQuestionResponseCreateManyWithoutQuestionInput>;
  survey: SurveyCreateOneWithoutQuestionsInput;
  text?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionCreateManyWithoutSurveyInput = {
  connect?: InputMaybe<Array<SurveyQuestionWhereUniqueInput>>;
  create?: InputMaybe<Array<SurveyQuestionCreateWithoutSurveyInput>>;
};

export type SurveyQuestionCreateOneWithoutResponsesInput = {
  connect?: InputMaybe<SurveyQuestionWhereUniqueInput>;
  create?: InputMaybe<SurveyQuestionCreateWithoutResponsesInput>;
};

export type SurveyQuestionCreateWithoutResponsesInput = {
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  options?: InputMaybe<Scalars['Json']>;
  order?: InputMaybe<Scalars['Int']>;
  survey: SurveyCreateOneWithoutQuestionsInput;
  text?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionCreateWithoutSurveyInput = {
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  options?: InputMaybe<Scalars['Json']>;
  order?: InputMaybe<Scalars['Int']>;
  responses?: InputMaybe<SurveyQuestionResponseCreateManyWithoutQuestionInput>;
  text?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionEdge = {
  __typename?: 'SurveyQuestionEdge';
  cursor: Scalars['String'];
  node: SurveyQuestion;
};

export enum SurveyQuestionOrderByInput {
  ADDCOMMENTFIELD_ASC = 'addCommentField_ASC',
  ADDCOMMENTFIELD_DESC = 'addCommentField_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENABLED_ASC = 'enabled_ASC',
  ENABLED_DESC = 'enabled_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  OPTIONS_ASC = 'options_ASC',
  OPTIONS_DESC = 'options_DESC',
  ORDER_ASC = 'order_ASC',
  ORDER_DESC = 'order_DESC',
  TEXT_ASC = 'text_ASC',
  TEXT_DESC = 'text_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type SurveyQuestionResponse = {
  __typename?: 'SurveyQuestionResponse';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  optionIndex?: Maybe<Scalars['Int']>;
  origin?: Maybe<Scalars['String']>;
  question: SurveyQuestion;
  responder?: Maybe<User>;
  responderEmail?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['String']>;
};

export type SurveyQuestionResponseConnection = {
  __typename?: 'SurveyQuestionResponseConnection';
  aggregate: AggregateSurveyQuestionResponse;
  edges: Array<Maybe<SurveyQuestionResponseEdge>>;
  pageInfo: PageInfo;
};

export type SurveyQuestionResponseCreateInput = {
  comment?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  optionIndex?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<Scalars['String']>;
  question: SurveyQuestionCreateOneWithoutResponsesInput;
  responderEmail?: InputMaybe<Scalars['String']>;
  responderId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionResponseCreateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<SurveyQuestionResponseWhereUniqueInput>>;
  create?: InputMaybe<Array<SurveyQuestionResponseCreateWithoutQuestionInput>>;
};

export type SurveyQuestionResponseCreateWithoutQuestionInput = {
  comment?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  optionIndex?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<Scalars['String']>;
  responderId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionResponseEdge = {
  __typename?: 'SurveyQuestionResponseEdge';
  cursor: Scalars['String'];
  node: SurveyQuestionResponse;
};

export enum SurveyQuestionResponseOrderByInput {
  COMMENT_ASC = 'comment_ASC',
  COMMENT_DESC = 'comment_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  OPTIONINDEX_ASC = 'optionIndex_ASC',
  OPTIONINDEX_DESC = 'optionIndex_DESC',
  ORIGIN_ASC = 'origin_ASC',
  ORIGIN_DESC = 'origin_DESC',
  RESPONDERID_ASC = 'responderId_ASC',
  RESPONDERID_DESC = 'responderId_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC'
}

export type SurveyQuestionResponseScalarWhereInput = {
  AND?: InputMaybe<Array<SurveyQuestionResponseScalarWhereInput>>;
  NOT?: InputMaybe<Array<SurveyQuestionResponseScalarWhereInput>>;
  OR?: InputMaybe<Array<SurveyQuestionResponseScalarWhereInput>>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  optionIndex?: InputMaybe<Scalars['Int']>;
  optionIndex_gt?: InputMaybe<Scalars['Int']>;
  optionIndex_gte?: InputMaybe<Scalars['Int']>;
  optionIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  optionIndex_lt?: InputMaybe<Scalars['Int']>;
  optionIndex_lte?: InputMaybe<Scalars['Int']>;
  optionIndex_not?: InputMaybe<Scalars['Int']>;
  optionIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  origin?: InputMaybe<Scalars['String']>;
  origin_contains?: InputMaybe<Scalars['String']>;
  origin_ends_with?: InputMaybe<Scalars['String']>;
  origin_gt?: InputMaybe<Scalars['String']>;
  origin_gte?: InputMaybe<Scalars['String']>;
  origin_in?: InputMaybe<Array<Scalars['String']>>;
  origin_lt?: InputMaybe<Scalars['String']>;
  origin_lte?: InputMaybe<Scalars['String']>;
  origin_not?: InputMaybe<Scalars['String']>;
  origin_not_contains?: InputMaybe<Scalars['String']>;
  origin_not_ends_with?: InputMaybe<Scalars['String']>;
  origin_not_in?: InputMaybe<Array<Scalars['String']>>;
  origin_not_starts_with?: InputMaybe<Scalars['String']>;
  origin_starts_with?: InputMaybe<Scalars['String']>;
  responderId?: InputMaybe<Scalars['String']>;
  responderId_contains?: InputMaybe<Scalars['String']>;
  responderId_ends_with?: InputMaybe<Scalars['String']>;
  responderId_gt?: InputMaybe<Scalars['String']>;
  responderId_gte?: InputMaybe<Scalars['String']>;
  responderId_in?: InputMaybe<Array<Scalars['String']>>;
  responderId_lt?: InputMaybe<Scalars['String']>;
  responderId_lte?: InputMaybe<Scalars['String']>;
  responderId_not?: InputMaybe<Scalars['String']>;
  responderId_not_contains?: InputMaybe<Scalars['String']>;
  responderId_not_ends_with?: InputMaybe<Scalars['String']>;
  responderId_not_in?: InputMaybe<Array<Scalars['String']>>;
  responderId_not_starts_with?: InputMaybe<Scalars['String']>;
  responderId_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_ends_with?: InputMaybe<Scalars['String']>;
  value_gt?: InputMaybe<Scalars['String']>;
  value_gte?: InputMaybe<Scalars['String']>;
  value_in?: InputMaybe<Array<Scalars['String']>>;
  value_lt?: InputMaybe<Scalars['String']>;
  value_lte?: InputMaybe<Scalars['String']>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<Scalars['String']>>;
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionResponseUpdateInput = {
  comment?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  optionIndex?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<SurveyQuestionUpdateOneRequiredWithoutResponsesInput>;
  responderId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionResponseUpdateManyDataInput = {
  comment?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  optionIndex?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<Scalars['String']>;
  responderId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionResponseUpdateManyWithWhereNestedInput = {
  data: SurveyQuestionResponseUpdateManyDataInput;
  where: SurveyQuestionResponseScalarWhereInput;
};

export type SurveyQuestionResponseUpdateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<SurveyQuestionResponseWhereUniqueInput>>;
  create?: InputMaybe<Array<SurveyQuestionResponseCreateWithoutQuestionInput>>;
  delete?: InputMaybe<Array<SurveyQuestionResponseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SurveyQuestionResponseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SurveyQuestionResponseWhereUniqueInput>>;
  set?: InputMaybe<Array<SurveyQuestionResponseWhereUniqueInput>>;
  update?: InputMaybe<Array<SurveyQuestionResponseUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<SurveyQuestionResponseUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SurveyQuestionResponseUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type SurveyQuestionResponseUpdateWithWhereUniqueWithoutQuestionInput = {
  data: SurveyQuestionResponseUpdateWithoutQuestionDataInput;
  where: SurveyQuestionResponseWhereUniqueInput;
};

export type SurveyQuestionResponseUpdateWithoutQuestionDataInput = {
  comment?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  optionIndex?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<Scalars['String']>;
  responderId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionResponseUpsertWithWhereUniqueWithoutQuestionInput = {
  create: SurveyQuestionResponseCreateWithoutQuestionInput;
  update: SurveyQuestionResponseUpdateWithoutQuestionDataInput;
  where: SurveyQuestionResponseWhereUniqueInput;
};

export type SurveyQuestionResponseWhereInput = {
  AND?: InputMaybe<Array<SurveyQuestionResponseWhereInput>>;
  NOT?: InputMaybe<Array<SurveyQuestionResponseWhereInput>>;
  OR?: InputMaybe<Array<SurveyQuestionResponseWhereInput>>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  optionIndex?: InputMaybe<Scalars['Int']>;
  optionIndex_gt?: InputMaybe<Scalars['Int']>;
  optionIndex_gte?: InputMaybe<Scalars['Int']>;
  optionIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  optionIndex_lt?: InputMaybe<Scalars['Int']>;
  optionIndex_lte?: InputMaybe<Scalars['Int']>;
  optionIndex_not?: InputMaybe<Scalars['Int']>;
  optionIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  origin?: InputMaybe<Scalars['String']>;
  origin_contains?: InputMaybe<Scalars['String']>;
  origin_ends_with?: InputMaybe<Scalars['String']>;
  origin_gt?: InputMaybe<Scalars['String']>;
  origin_gte?: InputMaybe<Scalars['String']>;
  origin_in?: InputMaybe<Array<Scalars['String']>>;
  origin_lt?: InputMaybe<Scalars['String']>;
  origin_lte?: InputMaybe<Scalars['String']>;
  origin_not?: InputMaybe<Scalars['String']>;
  origin_not_contains?: InputMaybe<Scalars['String']>;
  origin_not_ends_with?: InputMaybe<Scalars['String']>;
  origin_not_in?: InputMaybe<Array<Scalars['String']>>;
  origin_not_starts_with?: InputMaybe<Scalars['String']>;
  origin_starts_with?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<SurveyQuestionWhereInput>;
  responderId?: InputMaybe<Scalars['String']>;
  responderId_contains?: InputMaybe<Scalars['String']>;
  responderId_ends_with?: InputMaybe<Scalars['String']>;
  responderId_gt?: InputMaybe<Scalars['String']>;
  responderId_gte?: InputMaybe<Scalars['String']>;
  responderId_in?: InputMaybe<Array<Scalars['String']>>;
  responderId_lt?: InputMaybe<Scalars['String']>;
  responderId_lte?: InputMaybe<Scalars['String']>;
  responderId_not?: InputMaybe<Scalars['String']>;
  responderId_not_contains?: InputMaybe<Scalars['String']>;
  responderId_not_ends_with?: InputMaybe<Scalars['String']>;
  responderId_not_in?: InputMaybe<Array<Scalars['String']>>;
  responderId_not_starts_with?: InputMaybe<Scalars['String']>;
  responderId_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_ends_with?: InputMaybe<Scalars['String']>;
  value_gt?: InputMaybe<Scalars['String']>;
  value_gte?: InputMaybe<Scalars['String']>;
  value_in?: InputMaybe<Array<Scalars['String']>>;
  value_lt?: InputMaybe<Scalars['String']>;
  value_lte?: InputMaybe<Scalars['String']>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<Scalars['String']>>;
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionResponseWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SurveyQuestionScalarWhereInput = {
  AND?: InputMaybe<Array<SurveyQuestionScalarWhereInput>>;
  NOT?: InputMaybe<Array<SurveyQuestionScalarWhereInput>>;
  OR?: InputMaybe<Array<SurveyQuestionScalarWhereInput>>;
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  addCommentField_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  enabled_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
  order_gt?: InputMaybe<Scalars['Int']>;
  order_gte?: InputMaybe<Scalars['Int']>;
  order_in?: InputMaybe<Array<Scalars['Int']>>;
  order_lt?: InputMaybe<Scalars['Int']>;
  order_lte?: InputMaybe<Scalars['Int']>;
  order_not?: InputMaybe<Scalars['Int']>;
  order_not_in?: InputMaybe<Array<Scalars['Int']>>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_ends_with?: InputMaybe<Scalars['String']>;
  text_gt?: InputMaybe<Scalars['String']>;
  text_gte?: InputMaybe<Scalars['String']>;
  text_in?: InputMaybe<Array<Scalars['String']>>;
  text_lt?: InputMaybe<Scalars['String']>;
  text_lte?: InputMaybe<Scalars['String']>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<Scalars['String']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  text_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SurveyQuestionUpdateInput = {
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  options?: InputMaybe<Scalars['Json']>;
  order?: InputMaybe<Scalars['Int']>;
  responses?: InputMaybe<SurveyQuestionResponseUpdateManyWithoutQuestionInput>;
  survey?: InputMaybe<SurveyUpdateOneRequiredWithoutQuestionsInput>;
  text?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionUpdateManyDataInput = {
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  options?: InputMaybe<Scalars['Json']>;
  order?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionUpdateManyWithWhereNestedInput = {
  data: SurveyQuestionUpdateManyDataInput;
  where: SurveyQuestionScalarWhereInput;
};

export type SurveyQuestionUpdateManyWithoutSurveyInput = {
  connect?: InputMaybe<Array<SurveyQuestionWhereUniqueInput>>;
  create?: InputMaybe<Array<SurveyQuestionCreateWithoutSurveyInput>>;
  delete?: InputMaybe<Array<SurveyQuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SurveyQuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SurveyQuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<SurveyQuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<SurveyQuestionUpdateWithWhereUniqueWithoutSurveyInput>>;
  updateMany?: InputMaybe<Array<SurveyQuestionUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SurveyQuestionUpsertWithWhereUniqueWithoutSurveyInput>>;
};

export type SurveyQuestionUpdateOneRequiredWithoutResponsesInput = {
  connect?: InputMaybe<SurveyQuestionWhereUniqueInput>;
  create?: InputMaybe<SurveyQuestionCreateWithoutResponsesInput>;
  update?: InputMaybe<SurveyQuestionUpdateWithoutResponsesDataInput>;
  upsert?: InputMaybe<SurveyQuestionUpsertWithoutResponsesInput>;
};

export type SurveyQuestionUpdateWithWhereUniqueWithoutSurveyInput = {
  data: SurveyQuestionUpdateWithoutSurveyDataInput;
  where: SurveyQuestionWhereUniqueInput;
};

export type SurveyQuestionUpdateWithoutResponsesDataInput = {
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  options?: InputMaybe<Scalars['Json']>;
  order?: InputMaybe<Scalars['Int']>;
  survey?: InputMaybe<SurveyUpdateOneRequiredWithoutQuestionsInput>;
  text?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionUpdateWithoutSurveyDataInput = {
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  options?: InputMaybe<Scalars['Json']>;
  order?: InputMaybe<Scalars['Int']>;
  responses?: InputMaybe<SurveyQuestionResponseUpdateManyWithoutQuestionInput>;
  text?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestionUpsertWithWhereUniqueWithoutSurveyInput = {
  create: SurveyQuestionCreateWithoutSurveyInput;
  update: SurveyQuestionUpdateWithoutSurveyDataInput;
  where: SurveyQuestionWhereUniqueInput;
};

export type SurveyQuestionUpsertWithoutResponsesInput = {
  create: SurveyQuestionCreateWithoutResponsesInput;
  update: SurveyQuestionUpdateWithoutResponsesDataInput;
};

export type SurveyQuestionVote = {
  __typename?: 'SurveyQuestionVote';
  count: Scalars['Int'];
  isUserVote: Scalars['Boolean'];
  option: Scalars['String'];
};

export type SurveyQuestionWhereInput = {
  AND?: InputMaybe<Array<SurveyQuestionWhereInput>>;
  NOT?: InputMaybe<Array<SurveyQuestionWhereInput>>;
  OR?: InputMaybe<Array<SurveyQuestionWhereInput>>;
  addCommentField?: InputMaybe<Scalars['Boolean']>;
  addCommentField_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  enabled_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
  order_gt?: InputMaybe<Scalars['Int']>;
  order_gte?: InputMaybe<Scalars['Int']>;
  order_in?: InputMaybe<Array<Scalars['Int']>>;
  order_lt?: InputMaybe<Scalars['Int']>;
  order_lte?: InputMaybe<Scalars['Int']>;
  order_not?: InputMaybe<Scalars['Int']>;
  order_not_in?: InputMaybe<Array<Scalars['Int']>>;
  responses_every?: InputMaybe<SurveyQuestionResponseWhereInput>;
  responses_none?: InputMaybe<SurveyQuestionResponseWhereInput>;
  responses_some?: InputMaybe<SurveyQuestionResponseWhereInput>;
  survey?: InputMaybe<SurveyWhereInput>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_ends_with?: InputMaybe<Scalars['String']>;
  text_gt?: InputMaybe<Scalars['String']>;
  text_gte?: InputMaybe<Scalars['String']>;
  text_in?: InputMaybe<Array<Scalars['String']>>;
  text_lt?: InputMaybe<Scalars['String']>;
  text_lte?: InputMaybe<Scalars['String']>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<Scalars['String']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  text_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SurveyQuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SurveyResult = {
  __typename?: 'SurveyResult';
  _empty?: Maybe<Scalars['String']>;
  answer: SurveyAnswer;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  instance: Scalars['String'];
  type: SurveyType;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type SurveyResultConnection = {
  __typename?: 'SurveyResultConnection';
  aggregate: AggregateSurveyResult;
  edges: Array<Maybe<SurveyResultEdge>>;
  pageInfo: PageInfo;
};

export type SurveyResultCreateInput = {
  answer: SurveyAnswer;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  instance: Scalars['String'];
  type: SurveyType;
  user: UserCreateOneInput;
};

export type SurveyResultEdge = {
  __typename?: 'SurveyResultEdge';
  cursor: Scalars['String'];
  node: SurveyResult;
};

export enum SurveyResultOrderByInput {
  ANSWER_ASC = 'answer_ASC',
  ANSWER_DESC = 'answer_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  INSTANCE_ASC = 'instance_ASC',
  INSTANCE_DESC = 'instance_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type SurveyResultUpdateInput = {
  answer?: InputMaybe<SurveyAnswer>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  instance?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyType>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type SurveyResultWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<SurveyResultWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<SurveyResultWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<SurveyResultWhereContractsPersistencyInput>>;
  answer?: InputMaybe<ContractsPersistencySurveyAnswer>;
  answer_in?: InputMaybe<Array<ContractsPersistencySurveyAnswer>>;
  answer_not?: InputMaybe<ContractsPersistencySurveyAnswer>;
  answer_not_in?: InputMaybe<Array<ContractsPersistencySurveyAnswer>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  instance?: InputMaybe<Scalars['String']>;
  instance_contains?: InputMaybe<Scalars['String']>;
  instance_ends_with?: InputMaybe<Scalars['String']>;
  instance_gt?: InputMaybe<Scalars['String']>;
  instance_gte?: InputMaybe<Scalars['String']>;
  instance_in?: InputMaybe<Array<Scalars['String']>>;
  instance_lt?: InputMaybe<Scalars['String']>;
  instance_lte?: InputMaybe<Scalars['String']>;
  instance_not?: InputMaybe<Scalars['String']>;
  instance_not_contains?: InputMaybe<Scalars['String']>;
  instance_not_ends_with?: InputMaybe<Scalars['String']>;
  instance_not_in?: InputMaybe<Array<Scalars['String']>>;
  instance_not_starts_with?: InputMaybe<Scalars['String']>;
  instance_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ContractsPersistencySurveyType>;
  type_in?: InputMaybe<Array<ContractsPersistencySurveyType>>;
  type_not?: InputMaybe<ContractsPersistencySurveyType>;
  type_not_in?: InputMaybe<Array<ContractsPersistencySurveyType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type SurveyResultWhereInput = {
  AND?: InputMaybe<Array<SurveyResultWhereInput>>;
  NOT?: InputMaybe<Array<SurveyResultWhereInput>>;
  OR?: InputMaybe<Array<SurveyResultWhereInput>>;
  answer?: InputMaybe<SurveyAnswer>;
  answer_in?: InputMaybe<Array<SurveyAnswer>>;
  answer_not?: InputMaybe<SurveyAnswer>;
  answer_not_in?: InputMaybe<Array<SurveyAnswer>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  instance?: InputMaybe<Scalars['String']>;
  instance_contains?: InputMaybe<Scalars['String']>;
  instance_ends_with?: InputMaybe<Scalars['String']>;
  instance_gt?: InputMaybe<Scalars['String']>;
  instance_gte?: InputMaybe<Scalars['String']>;
  instance_in?: InputMaybe<Array<Scalars['String']>>;
  instance_lt?: InputMaybe<Scalars['String']>;
  instance_lte?: InputMaybe<Scalars['String']>;
  instance_not?: InputMaybe<Scalars['String']>;
  instance_not_contains?: InputMaybe<Scalars['String']>;
  instance_not_ends_with?: InputMaybe<Scalars['String']>;
  instance_not_in?: InputMaybe<Array<Scalars['String']>>;
  instance_not_starts_with?: InputMaybe<Scalars['String']>;
  instance_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyType>;
  type_in?: InputMaybe<Array<SurveyType>>;
  type_not?: InputMaybe<SurveyType>;
  type_not_in?: InputMaybe<Array<SurveyType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
};

export type SurveyResultWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<SurveyResultWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<SurveyResultWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<SurveyResultWherePersistencyContractsInput>>;
  answer?: InputMaybe<PersistencyContractsSurveyAnswer>;
  answer_in?: InputMaybe<Array<PersistencyContractsSurveyAnswer>>;
  answer_not?: InputMaybe<PersistencyContractsSurveyAnswer>;
  answer_not_in?: InputMaybe<Array<PersistencyContractsSurveyAnswer>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  instance?: InputMaybe<Scalars['String']>;
  instance_contains?: InputMaybe<Scalars['String']>;
  instance_ends_with?: InputMaybe<Scalars['String']>;
  instance_gt?: InputMaybe<Scalars['String']>;
  instance_gte?: InputMaybe<Scalars['String']>;
  instance_in?: InputMaybe<Array<Scalars['String']>>;
  instance_lt?: InputMaybe<Scalars['String']>;
  instance_lte?: InputMaybe<Scalars['String']>;
  instance_not?: InputMaybe<Scalars['String']>;
  instance_not_contains?: InputMaybe<Scalars['String']>;
  instance_not_ends_with?: InputMaybe<Scalars['String']>;
  instance_not_in?: InputMaybe<Array<Scalars['String']>>;
  instance_not_starts_with?: InputMaybe<Scalars['String']>;
  instance_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PersistencyContractsSurveyType>;
  type_in?: InputMaybe<Array<PersistencyContractsSurveyType>>;
  type_not?: InputMaybe<PersistencyContractsSurveyType>;
  type_not_in?: InputMaybe<Array<PersistencyContractsSurveyType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type SurveyResultWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum SurveyType {
  SATISFACTION = 'SATISFACTION'
}

export enum SurveyTypes {
  CSAT_QUARTERLY = 'CSAT_QUARTERLY'
}

export type SurveyUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isPublicAccess?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<SurveyQuestionUpdateManyWithoutSurveyInput>;
  surveyUserActions?: InputMaybe<SurveyUserActionUpdateManyWithoutSurveyInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyTypes>;
};

export type SurveyUpdateOneRequiredWithoutQuestionsInput = {
  connect?: InputMaybe<SurveyWhereUniqueInput>;
  create?: InputMaybe<SurveyCreateWithoutQuestionsInput>;
  update?: InputMaybe<SurveyUpdateWithoutQuestionsDataInput>;
  upsert?: InputMaybe<SurveyUpsertWithoutQuestionsInput>;
};

export type SurveyUpdateOneRequiredWithoutSurveyUserActionsInput = {
  connect?: InputMaybe<SurveyWhereUniqueInput>;
  create?: InputMaybe<SurveyCreateWithoutSurveyUserActionsInput>;
  update?: InputMaybe<SurveyUpdateWithoutSurveyUserActionsDataInput>;
  upsert?: InputMaybe<SurveyUpsertWithoutSurveyUserActionsInput>;
};

export type SurveyUpdateWithoutQuestionsDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isPublicAccess?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<Scalars['String']>;
  surveyUserActions?: InputMaybe<SurveyUserActionUpdateManyWithoutSurveyInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyTypes>;
};

export type SurveyUpdateWithoutSurveyUserActionsDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hoodId?: InputMaybe<Scalars['String']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isPublicAccess?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<SurveyQuestionUpdateManyWithoutSurveyInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyTypes>;
};

export type SurveyUpsertWithoutQuestionsInput = {
  create: SurveyCreateWithoutQuestionsInput;
  update: SurveyUpdateWithoutQuestionsDataInput;
};

export type SurveyUpsertWithoutSurveyUserActionsInput = {
  create: SurveyCreateWithoutSurveyUserActionsInput;
  update: SurveyUpdateWithoutSurveyUserActionsDataInput;
};

export type SurveyUserAction = {
  __typename?: 'SurveyUserAction';
  action: SurveyActions;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  survey: Survey;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type SurveyUserActionConnection = {
  __typename?: 'SurveyUserActionConnection';
  aggregate: AggregateSurveyUserAction;
  edges: Array<Maybe<SurveyUserActionEdge>>;
  pageInfo: PageInfo;
};

export type SurveyUserActionCreateInput = {
  action: SurveyActions;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  survey: SurveyCreateOneWithoutSurveyUserActionsInput;
  userId: Scalars['String'];
};

export type SurveyUserActionCreateManyWithoutSurveyInput = {
  connect?: InputMaybe<Array<SurveyUserActionWhereUniqueInput>>;
  create?: InputMaybe<Array<SurveyUserActionCreateWithoutSurveyInput>>;
};

export type SurveyUserActionCreateWithoutSurveyInput = {
  action: SurveyActions;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SurveyUserActionEdge = {
  __typename?: 'SurveyUserActionEdge';
  cursor: Scalars['String'];
  node: SurveyUserAction;
};

export enum SurveyUserActionOrderByInput {
  ACTION_ASC = 'action_ASC',
  ACTION_DESC = 'action_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERID_ASC = 'userId_ASC',
  USERID_DESC = 'userId_DESC'
}

export type SurveyUserActionScalarWhereInput = {
  AND?: InputMaybe<Array<SurveyUserActionScalarWhereInput>>;
  NOT?: InputMaybe<Array<SurveyUserActionScalarWhereInput>>;
  OR?: InputMaybe<Array<SurveyUserActionScalarWhereInput>>;
  action?: InputMaybe<SurveyActions>;
  action_in?: InputMaybe<Array<SurveyActions>>;
  action_not?: InputMaybe<SurveyActions>;
  action_not_in?: InputMaybe<Array<SurveyActions>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type SurveyUserActionUpdateInput = {
  action?: InputMaybe<SurveyActions>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  survey?: InputMaybe<SurveyUpdateOneRequiredWithoutSurveyUserActionsInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SurveyUserActionUpdateManyDataInput = {
  action?: InputMaybe<SurveyActions>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SurveyUserActionUpdateManyWithWhereNestedInput = {
  data: SurveyUserActionUpdateManyDataInput;
  where: SurveyUserActionScalarWhereInput;
};

export type SurveyUserActionUpdateManyWithoutSurveyInput = {
  connect?: InputMaybe<Array<SurveyUserActionWhereUniqueInput>>;
  create?: InputMaybe<Array<SurveyUserActionCreateWithoutSurveyInput>>;
  delete?: InputMaybe<Array<SurveyUserActionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SurveyUserActionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SurveyUserActionWhereUniqueInput>>;
  set?: InputMaybe<Array<SurveyUserActionWhereUniqueInput>>;
  update?: InputMaybe<Array<SurveyUserActionUpdateWithWhereUniqueWithoutSurveyInput>>;
  updateMany?: InputMaybe<Array<SurveyUserActionUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SurveyUserActionUpsertWithWhereUniqueWithoutSurveyInput>>;
};

export type SurveyUserActionUpdateWithWhereUniqueWithoutSurveyInput = {
  data: SurveyUserActionUpdateWithoutSurveyDataInput;
  where: SurveyUserActionWhereUniqueInput;
};

export type SurveyUserActionUpdateWithoutSurveyDataInput = {
  action?: InputMaybe<SurveyActions>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SurveyUserActionUpsertWithWhereUniqueWithoutSurveyInput = {
  create: SurveyUserActionCreateWithoutSurveyInput;
  update: SurveyUserActionUpdateWithoutSurveyDataInput;
  where: SurveyUserActionWhereUniqueInput;
};

export type SurveyUserActionWhereInput = {
  AND?: InputMaybe<Array<SurveyUserActionWhereInput>>;
  NOT?: InputMaybe<Array<SurveyUserActionWhereInput>>;
  OR?: InputMaybe<Array<SurveyUserActionWhereInput>>;
  action?: InputMaybe<SurveyActions>;
  action_in?: InputMaybe<Array<SurveyActions>>;
  action_not?: InputMaybe<SurveyActions>;
  action_not_in?: InputMaybe<Array<SurveyActions>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  survey?: InputMaybe<SurveyWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userId?: InputMaybe<Scalars['String']>;
  userId_contains?: InputMaybe<Scalars['String']>;
  userId_ends_with?: InputMaybe<Scalars['String']>;
  userId_gt?: InputMaybe<Scalars['String']>;
  userId_gte?: InputMaybe<Scalars['String']>;
  userId_in?: InputMaybe<Array<Scalars['String']>>;
  userId_lt?: InputMaybe<Scalars['String']>;
  userId_lte?: InputMaybe<Scalars['String']>;
  userId_not?: InputMaybe<Scalars['String']>;
  userId_not_contains?: InputMaybe<Scalars['String']>;
  userId_not_ends_with?: InputMaybe<Scalars['String']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']>>;
  userId_not_starts_with?: InputMaybe<Scalars['String']>;
  userId_starts_with?: InputMaybe<Scalars['String']>;
};

export type SurveyUserActionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SurveyWhereInput = {
  AND?: InputMaybe<Array<SurveyWhereInput>>;
  NOT?: InputMaybe<Array<SurveyWhereInput>>;
  OR?: InputMaybe<Array<SurveyWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  enabled_not?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  hoodId?: InputMaybe<Scalars['String']>;
  hoodId_contains?: InputMaybe<Scalars['String']>;
  hoodId_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_gt?: InputMaybe<Scalars['String']>;
  hoodId_gte?: InputMaybe<Scalars['String']>;
  hoodId_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_lt?: InputMaybe<Scalars['String']>;
  hoodId_lte?: InputMaybe<Scalars['String']>;
  hoodId_not?: InputMaybe<Scalars['String']>;
  hoodId_not_contains?: InputMaybe<Scalars['String']>;
  hoodId_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']>;
  isAnonymous_not?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isExpired_not?: InputMaybe<Scalars['Boolean']>;
  isPublicAccess?: InputMaybe<Scalars['Boolean']>;
  isPublicAccess_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  questions_every?: InputMaybe<SurveyQuestionWhereInput>;
  questions_none?: InputMaybe<SurveyQuestionWhereInput>;
  questions_some?: InputMaybe<SurveyQuestionWhereInput>;
  surveyUserActions_every?: InputMaybe<SurveyUserActionWhereInput>;
  surveyUserActions_none?: InputMaybe<SurveyUserActionWhereInput>;
  surveyUserActions_some?: InputMaybe<SurveyUserActionWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_ends_with?: InputMaybe<Scalars['String']>;
  title_gt?: InputMaybe<Scalars['String']>;
  title_gte?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  title_lt?: InputMaybe<Scalars['String']>;
  title_lte?: InputMaybe<Scalars['String']>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  title_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyTypes>;
  type_in?: InputMaybe<Array<SurveyTypes>>;
  type_not?: InputMaybe<SurveyTypes>;
  type_not_in?: InputMaybe<Array<SurveyTypes>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type SurveyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TaxRate = {
  __typename?: 'TaxRate';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalTaxRateId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paymentProvider?: Maybe<PaymentProviderForTax>;
  state: Scalars['String'];
  taxRateType: TaxRateType;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type TaxRateConnection = {
  __typename?: 'TaxRateConnection';
  aggregate: AggregateTaxRate;
  edges: Array<Maybe<TaxRateEdge>>;
  pageInfo: PageInfo;
};

export type TaxRateCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalTaxRateId?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderForTax>;
  state: Scalars['String'];
  taxRateType: TaxRateType;
  value: Scalars['Float'];
};

export type TaxRateCreateOneInput = {
  connect?: InputMaybe<TaxRateWhereUniqueInput>;
  create?: InputMaybe<TaxRateCreateInput>;
};

export type TaxRateEdge = {
  __typename?: 'TaxRateEdge';
  cursor: Scalars['String'];
  node: TaxRate;
};

export enum TaxRateOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  EXTERNALTAXRATEID_ASC = 'externalTaxRateId_ASC',
  EXTERNALTAXRATEID_DESC = 'externalTaxRateId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  PAYMENTPROVIDER_ASC = 'paymentProvider_ASC',
  PAYMENTPROVIDER_DESC = 'paymentProvider_DESC',
  STATE_ASC = 'state_ASC',
  STATE_DESC = 'state_DESC',
  TAXRATETYPE_ASC = 'taxRateType_ASC',
  TAXRATETYPE_DESC = 'taxRateType_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC'
}

export enum TaxRateType {
  NO_TAX = 'NO_TAX',
  STANDARD_TAX = 'STANDARD_TAX'
}

export type TaxRateUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalTaxRateId?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderForTax>;
  state?: InputMaybe<Scalars['String']>;
  taxRateType?: InputMaybe<TaxRateType>;
  value?: InputMaybe<Scalars['Float']>;
};

export type TaxRateUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  externalTaxRateId?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderForTax>;
  state?: InputMaybe<Scalars['String']>;
  taxRateType?: InputMaybe<TaxRateType>;
  value?: InputMaybe<Scalars['Float']>;
};

export type TaxRateUpdateOneInput = {
  connect?: InputMaybe<TaxRateWhereUniqueInput>;
  create?: InputMaybe<TaxRateCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TaxRateUpdateDataInput>;
  upsert?: InputMaybe<TaxRateUpsertNestedInput>;
};

export type TaxRateUpsertNestedInput = {
  create: TaxRateCreateInput;
  update: TaxRateUpdateDataInput;
};

export type TaxRateWhereInput = {
  AND?: InputMaybe<Array<TaxRateWhereInput>>;
  NOT?: InputMaybe<Array<TaxRateWhereInput>>;
  OR?: InputMaybe<Array<TaxRateWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalTaxRateId?: InputMaybe<Scalars['String']>;
  externalTaxRateId_contains?: InputMaybe<Scalars['String']>;
  externalTaxRateId_ends_with?: InputMaybe<Scalars['String']>;
  externalTaxRateId_gt?: InputMaybe<Scalars['String']>;
  externalTaxRateId_gte?: InputMaybe<Scalars['String']>;
  externalTaxRateId_in?: InputMaybe<Array<Scalars['String']>>;
  externalTaxRateId_lt?: InputMaybe<Scalars['String']>;
  externalTaxRateId_lte?: InputMaybe<Scalars['String']>;
  externalTaxRateId_not?: InputMaybe<Scalars['String']>;
  externalTaxRateId_not_contains?: InputMaybe<Scalars['String']>;
  externalTaxRateId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalTaxRateId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalTaxRateId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalTaxRateId_starts_with?: InputMaybe<Scalars['String']>;
  hoodId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paymentProvider?: InputMaybe<PaymentProviderForTax>;
  paymentProvider_in?: InputMaybe<Array<PaymentProviderForTax>>;
  paymentProvider_not?: InputMaybe<PaymentProviderForTax>;
  paymentProvider_not_in?: InputMaybe<Array<PaymentProviderForTax>>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_ends_with?: InputMaybe<Scalars['String']>;
  state_gt?: InputMaybe<Scalars['String']>;
  state_gte?: InputMaybe<Scalars['String']>;
  state_in?: InputMaybe<Array<Scalars['String']>>;
  state_lt?: InputMaybe<Scalars['String']>;
  state_lte?: InputMaybe<Scalars['String']>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_ends_with?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<Scalars['String']>>;
  state_not_starts_with?: InputMaybe<Scalars['String']>;
  state_starts_with?: InputMaybe<Scalars['String']>;
  taxRateType?: InputMaybe<TaxRateType>;
  taxRateType_in?: InputMaybe<Array<TaxRateType>>;
  taxRateType_not?: InputMaybe<TaxRateType>;
  taxRateType_not_in?: InputMaybe<Array<TaxRateType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  value?: InputMaybe<Scalars['Float']>;
  value_gt?: InputMaybe<Scalars['Float']>;
  value_gte?: InputMaybe<Scalars['Float']>;
  value_in?: InputMaybe<Array<Scalars['Float']>>;
  value_lt?: InputMaybe<Scalars['Float']>;
  value_lte?: InputMaybe<Scalars['Float']>;
  value_not?: InputMaybe<Scalars['Float']>;
  value_not_in?: InputMaybe<Array<Scalars['Float']>>;
};

export type TaxRateWhereUniqueInput = {
  hoodId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type TerminateContractInput = {
  contractId: Scalars['ID'];
  contractType: ContractType;
  terminationDate: Scalars['DateTime'];
};

export type TerminateContractResult = {
  __typename?: 'TerminateContractResult';
  /** @deprecated Will be replaced by real status */
  dummy?: Maybe<Scalars['String']>;
};

export type TerminateMemberInput = {
  terminationDate: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export type TerminateMemberResult = {
  __typename?: 'TerminateMemberResult';
  /** @deprecated Will be replaced by a real status */
  dummy?: Maybe<Scalars['String']>;
};

export type ToEmail = {
  __typename?: 'ToEmail';
  toEmail: Array<Maybe<Scalars['String']>>;
};

export type ToggleSocialReactionInput = {
  operation: SocialReactionOperation;
  originalEntityId: Scalars['String'];
  originalEntityName: Scalars['String'];
  socialReactionType: SocialReactionType;
};

export type ToggleVipPassInput = {
  hoodId: Scalars['String'];
  settingId: Scalars['String'];
  value: Scalars['String'];
};

export type Transaction = {
  __typename?: 'Transaction';
  _empty?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  bill?: Maybe<Bill>;
  billableFromDate: Scalars['DateTime'];
  billableItem: BillableItem;
  billingMonth?: Maybe<Scalars['Int']>;
  billingYear?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  hood: Hood;
  id: Scalars['ID'];
  inverseTransaction?: Maybe<Transaction>;
  isPartOfPeriodicBill: Scalars['Boolean'];
  metadata?: Maybe<Scalars['Json']>;
  sapUnit?: Maybe<Unit>;
  startDate: Scalars['DateTime'];
  taxAmount?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  voidedTransaction?: Maybe<Transaction>;
  voidingStatus?: Maybe<TransactionVoidingStatus>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  aggregate: AggregateTransaction;
  edges: Array<Maybe<TransactionEdge>>;
  pageInfo: PageInfo;
};

export type TransactionCreateInput = {
  amount: Scalars['Float'];
  bill?: InputMaybe<BillCreateOneWithoutTransactionsInput>;
  billableFromDate: Scalars['DateTime'];
  billableItem: BillableItemCreateOneWithoutTransactionsInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  hood: HoodCreateOneInput;
  inverseTransaction?: InputMaybe<TransactionCreateOneInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate: Scalars['DateTime'];
  taxAmount?: InputMaybe<Scalars['Float']>;
  user: UserCreateOneInput;
  voidedTransaction?: InputMaybe<TransactionCreateOneInput>;
};

export type TransactionCreateManyWithoutBillInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutBillInput>>;
};

export type TransactionCreateManyWithoutBillableItemInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutBillableItemInput>>;
};

export type TransactionCreateOneInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  create?: InputMaybe<TransactionCreateInput>;
};

export type TransactionCreateWithoutBillInput = {
  amount: Scalars['Float'];
  billableFromDate: Scalars['DateTime'];
  billableItem: BillableItemCreateOneWithoutTransactionsInput;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  hood: HoodCreateOneInput;
  inverseTransaction?: InputMaybe<TransactionCreateOneInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate: Scalars['DateTime'];
  taxAmount?: InputMaybe<Scalars['Float']>;
  user: UserCreateOneInput;
  voidedTransaction?: InputMaybe<TransactionCreateOneInput>;
};

export type TransactionCreateWithoutBillableItemInput = {
  amount: Scalars['Float'];
  bill?: InputMaybe<BillCreateOneWithoutTransactionsInput>;
  billableFromDate: Scalars['DateTime'];
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  hood: HoodCreateOneInput;
  inverseTransaction?: InputMaybe<TransactionCreateOneInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate: Scalars['DateTime'];
  taxAmount?: InputMaybe<Scalars['Float']>;
  user: UserCreateOneInput;
  voidedTransaction?: InputMaybe<TransactionCreateOneInput>;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  cursor: Scalars['String'];
  node: Transaction;
};

export enum TransactionOrderByInput {
  AMOUNT_ASC = 'amount_ASC',
  AMOUNT_DESC = 'amount_DESC',
  BILLABLEFROMDATE_ASC = 'billableFromDate_ASC',
  BILLABLEFROMDATE_DESC = 'billableFromDate_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ENDDATE_ASC = 'endDate_ASC',
  ENDDATE_DESC = 'endDate_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISPARTOFPERIODICBILL_ASC = 'isPartOfPeriodicBill_ASC',
  ISPARTOFPERIODICBILL_DESC = 'isPartOfPeriodicBill_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  STARTDATE_ASC = 'startDate_ASC',
  STARTDATE_DESC = 'startDate_DESC',
  TAXAMOUNT_ASC = 'taxAmount_ASC',
  TAXAMOUNT_DESC = 'taxAmount_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type TransactionScalarWhereInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableFromDate_lt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_lte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  isPartOfPeriodicBill_not?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  taxAmount_gt?: InputMaybe<Scalars['Float']>;
  taxAmount_gte?: InputMaybe<Scalars['Float']>;
  taxAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  taxAmount_lt?: InputMaybe<Scalars['Float']>;
  taxAmount_lte?: InputMaybe<Scalars['Float']>;
  taxAmount_not?: InputMaybe<Scalars['Float']>;
  taxAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type TransactionUpdateDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bill?: InputMaybe<BillUpdateOneWithoutTransactionsInput>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableItem?: InputMaybe<BillableItemUpdateOneRequiredWithoutTransactionsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  inverseTransaction?: InputMaybe<TransactionUpdateOneInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  voidedTransaction?: InputMaybe<TransactionUpdateOneInput>;
};

export type TransactionUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bill?: InputMaybe<BillUpdateOneWithoutTransactionsInput>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableItem?: InputMaybe<BillableItemUpdateOneRequiredWithoutTransactionsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  inverseTransaction?: InputMaybe<TransactionUpdateOneInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  voidedTransaction?: InputMaybe<TransactionUpdateOneInput>;
};

export type TransactionUpdateManyDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  taxAmount?: InputMaybe<Scalars['Float']>;
};

export type TransactionUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  taxAmount?: InputMaybe<Scalars['Float']>;
};

export type TransactionUpdateManyWithWhereNestedInput = {
  data: TransactionUpdateManyDataInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutBillInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutBillInput>>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutBillInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutBillInput>>;
};

export type TransactionUpdateManyWithoutBillableItemInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutBillableItemInput>>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutBillableItemInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutBillableItemInput>>;
};

export type TransactionUpdateOneInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  create?: InputMaybe<TransactionCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TransactionUpdateDataInput>;
  upsert?: InputMaybe<TransactionUpsertNestedInput>;
};

export type TransactionUpdateWithWhereUniqueWithoutBillInput = {
  data: TransactionUpdateWithoutBillDataInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutBillableItemInput = {
  data: TransactionUpdateWithoutBillableItemDataInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutBillDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableItem?: InputMaybe<BillableItemUpdateOneRequiredWithoutTransactionsInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  inverseTransaction?: InputMaybe<TransactionUpdateOneInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  voidedTransaction?: InputMaybe<TransactionUpdateOneInput>;
};

export type TransactionUpdateWithoutBillableItemDataInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bill?: InputMaybe<BillUpdateOneWithoutTransactionsInput>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  hood?: InputMaybe<HoodUpdateOneRequiredInput>;
  inverseTransaction?: InputMaybe<TransactionUpdateOneInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
  voidedTransaction?: InputMaybe<TransactionUpdateOneInput>;
};

export type TransactionUpsertNestedInput = {
  create: TransactionCreateInput;
  update: TransactionUpdateDataInput;
};

export type TransactionUpsertWithWhereUniqueWithoutBillInput = {
  create: TransactionCreateWithoutBillInput;
  update: TransactionUpdateWithoutBillDataInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutBillableItemInput = {
  create: TransactionCreateWithoutBillableItemInput;
  update: TransactionUpdateWithoutBillableItemDataInput;
  where: TransactionWhereUniqueInput;
};

export enum TransactionVoidingStatus {
  INVERSE = 'INVERSE',
  NONE = 'NONE',
  VOIDED = 'VOIDED'
}

export type TransactionWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<TransactionWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<TransactionWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<TransactionWhereContractsPersistencyInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bill?: InputMaybe<BillWhereContractsPersistencyInput>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableFromDate_lt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_lte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableItem?: InputMaybe<BillableItemWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inverseTransaction?: InputMaybe<TransactionWhereContractsPersistencyInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  isPartOfPeriodicBill_not?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
  voidedTransaction?: InputMaybe<TransactionWhereContractsPersistencyInput>;
};

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bill?: InputMaybe<BillWhereInput>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableFromDate_lt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_lte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableItem?: InputMaybe<BillableItemWhereInput>;
  billingMonth?: InputMaybe<TransactionWhereMonthInput>;
  billingMonth_gt?: InputMaybe<TransactionWhereMonthInput>;
  billingMonth_gte?: InputMaybe<TransactionWhereMonthInput>;
  billingMonth_lt?: InputMaybe<TransactionWhereMonthInput>;
  billingMonth_lte?: InputMaybe<TransactionWhereMonthInput>;
  billingYear?: InputMaybe<Scalars['Int']>;
  billingYear_gt?: InputMaybe<Scalars['Int']>;
  billingYear_gte?: InputMaybe<Scalars['Int']>;
  billingYear_lt?: InputMaybe<Scalars['Int']>;
  billingYear_lte?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  filterVoidedTransactions?: InputMaybe<Scalars['Boolean']>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inverseTransaction?: InputMaybe<TransactionWhereInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  isPartOfPeriodicBill_not?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  taxAmount?: InputMaybe<Scalars['Float']>;
  taxAmount_gt?: InputMaybe<Scalars['Float']>;
  taxAmount_gte?: InputMaybe<Scalars['Float']>;
  taxAmount_in?: InputMaybe<Array<Scalars['Float']>>;
  taxAmount_lt?: InputMaybe<Scalars['Float']>;
  taxAmount_lte?: InputMaybe<Scalars['Float']>;
  taxAmount_not?: InputMaybe<Scalars['Float']>;
  taxAmount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
  voidedTransaction?: InputMaybe<TransactionWhereInput>;
};

export type TransactionWhereMonthInput = {
  billingMonth: Scalars['Int'];
  billingYear: Scalars['Int'];
};

export type TransactionWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<TransactionWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<TransactionWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<TransactionWherePersistencyContractsInput>>;
  amount?: InputMaybe<Scalars['Float']>;
  amount_gt?: InputMaybe<Scalars['Float']>;
  amount_gte?: InputMaybe<Scalars['Float']>;
  amount_in?: InputMaybe<Array<Scalars['Float']>>;
  amount_lt?: InputMaybe<Scalars['Float']>;
  amount_lte?: InputMaybe<Scalars['Float']>;
  amount_not?: InputMaybe<Scalars['Float']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bill?: InputMaybe<BillWherePersistencyContractsInput>;
  billableFromDate?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_gte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableFromDate_lt?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_lte?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not?: InputMaybe<Scalars['DateTime']>;
  billableFromDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  billableItem?: InputMaybe<BillableItemWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inverseTransaction?: InputMaybe<TransactionWherePersistencyContractsInput>;
  isPartOfPeriodicBill?: InputMaybe<Scalars['Boolean']>;
  isPartOfPeriodicBill_not?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
  voidedTransaction?: InputMaybe<TransactionWherePersistencyContractsInput>;
};

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum TreasureCategory {
  BACK_TO_SCHOOL = 'BACK_TO_SCHOOL',
  BEAUTY = 'BEAUTY',
  BOOKS = 'BOOKS',
  COMPUTER_AND_CELL = 'COMPUTER_AND_CELL',
  COOKING_AND_BAKING = 'COOKING_AND_BAKING',
  COSTUMES = 'COSTUMES',
  DIY = 'DIY',
  HOME_AND_GARDEN = 'HOME_AND_GARDEN',
  MENS_FASHION = 'MENS_FASHION',
  OTHER = 'OTHER',
  PARENTS = 'PARENTS',
  SPORTS_AND_CAMPING = 'SPORTS_AND_CAMPING',
  WOMENS_FASHION = 'WOMENS_FASHION'
}

export type TreasureItem = {
  __typename?: 'TreasureItem';
  _empty?: Maybe<Scalars['String']>;
  available: Scalars['Boolean'];
  category: TreasureCategory;
  condition: TreasureItemCondition;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hoodId: Scalars['String'];
  id: Scalars['ID'];
  imageResourceId: Scalars['String'];
  memberProfile: MemberProfile;
  name: Scalars['String'];
  type: TreasureType;
  updatedAt: Scalars['DateTime'];
};

export enum TreasureItemCondition {
  NEW = 'NEW',
  USED_AS_NEW = 'USED_AS_NEW',
  USED_GOOD = 'USED_GOOD'
}

export type TreasureItemConnection = {
  __typename?: 'TreasureItemConnection';
  aggregate: AggregateTreasureItem;
  edges: Array<Maybe<TreasureItemEdge>>;
  pageInfo: PageInfo;
};

export type TreasureItemCreateInput = {
  available: Scalars['Boolean'];
  category: TreasureCategory;
  condition: TreasureItemCondition;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hoodId: Scalars['String'];
  imageResourceId: Scalars['String'];
  memberProfile: MemberProfileCreateOneInput;
  name: Scalars['String'];
  type: TreasureType;
};

export type TreasureItemEdge = {
  __typename?: 'TreasureItemEdge';
  cursor: Scalars['String'];
  node: TreasureItem;
};

export enum TreasureItemOrderByInput {
  AVAILABLE_ASC = 'available_ASC',
  AVAILABLE_DESC = 'available_DESC',
  CATEGORY_ASC = 'category_ASC',
  CATEGORY_DESC = 'category_DESC',
  CONDITION_ASC = 'condition_ASC',
  CONDITION_DESC = 'condition_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  HOODID_ASC = 'hoodId_ASC',
  HOODID_DESC = 'hoodId_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  IMAGERESOURCEID_ASC = 'imageResourceId_ASC',
  IMAGERESOURCEID_DESC = 'imageResourceId_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type TreasureItemUpdateInput = {
  available?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<TreasureCategory>;
  condition?: InputMaybe<TreasureItemCondition>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  hoodId?: InputMaybe<Scalars['String']>;
  imageResourceId?: InputMaybe<Scalars['String']>;
  memberProfile?: InputMaybe<MemberProfileUpdateOneRequiredInput>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TreasureType>;
};

export type TreasureItemWhereInput = {
  AND?: InputMaybe<Array<TreasureItemWhereInput>>;
  NOT?: InputMaybe<Array<TreasureItemWhereInput>>;
  OR?: InputMaybe<Array<TreasureItemWhereInput>>;
  available?: InputMaybe<Scalars['Boolean']>;
  available_not?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<TreasureCategory>;
  category_in?: InputMaybe<Array<TreasureCategory>>;
  category_not?: InputMaybe<TreasureCategory>;
  category_not_in?: InputMaybe<Array<TreasureCategory>>;
  condition?: InputMaybe<TreasureItemCondition>;
  condition_in?: InputMaybe<Array<TreasureItemCondition>>;
  condition_not?: InputMaybe<TreasureItemCondition>;
  condition_not_in?: InputMaybe<Array<TreasureItemCondition>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  hoodId?: InputMaybe<Scalars['String']>;
  hoodId_contains?: InputMaybe<Scalars['String']>;
  hoodId_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_gt?: InputMaybe<Scalars['String']>;
  hoodId_gte?: InputMaybe<Scalars['String']>;
  hoodId_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_lt?: InputMaybe<Scalars['String']>;
  hoodId_lte?: InputMaybe<Scalars['String']>;
  hoodId_not?: InputMaybe<Scalars['String']>;
  hoodId_not_contains?: InputMaybe<Scalars['String']>;
  hoodId_not_ends_with?: InputMaybe<Scalars['String']>;
  hoodId_not_in?: InputMaybe<Array<Scalars['String']>>;
  hoodId_not_starts_with?: InputMaybe<Scalars['String']>;
  hoodId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageResourceId?: InputMaybe<Scalars['String']>;
  imageResourceId_contains?: InputMaybe<Scalars['String']>;
  imageResourceId_ends_with?: InputMaybe<Scalars['String']>;
  imageResourceId_gt?: InputMaybe<Scalars['String']>;
  imageResourceId_gte?: InputMaybe<Scalars['String']>;
  imageResourceId_in?: InputMaybe<Array<Scalars['String']>>;
  imageResourceId_lt?: InputMaybe<Scalars['String']>;
  imageResourceId_lte?: InputMaybe<Scalars['String']>;
  imageResourceId_not?: InputMaybe<Scalars['String']>;
  imageResourceId_not_contains?: InputMaybe<Scalars['String']>;
  imageResourceId_not_ends_with?: InputMaybe<Scalars['String']>;
  imageResourceId_not_in?: InputMaybe<Array<Scalars['String']>>;
  imageResourceId_not_starts_with?: InputMaybe<Scalars['String']>;
  imageResourceId_starts_with?: InputMaybe<Scalars['String']>;
  memberProfile?: InputMaybe<MemberProfileWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TreasureType>;
  type_in?: InputMaybe<Array<TreasureType>>;
  type_not?: InputMaybe<TreasureType>;
  type_not_in?: InputMaybe<Array<TreasureType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type TreasureItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum TreasureType {
  ASK = 'ASK',
  GIVE = 'GIVE',
  LAND = 'LAND'
}

export type UnblockMemberProfileInput = {
  ownerId: Scalars['String'];
};

export type Unit = {
  __typename?: 'Unit';
  airtableId?: Maybe<Scalars['String']>;
  askedRent?: Maybe<Scalars['Float']>;
  availableByDate?: Maybe<Scalars['DateTime']>;
  baseRent?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Array<Bedroom>>;
  building: Building;
  commercialSpace: Scalars['Boolean'];
  contractBaseRent?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  entrance?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  hood?: Maybe<Hood>;
  id: Scalars['ID'];
  leaseContracts?: Maybe<Array<Maybe<LeaseContract>>>;
  metadata?: Maybe<Scalars['Json']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  occupiedByVenn: Scalars['Boolean'];
  product?: Maybe<Product>;
  rentStabilized: Scalars['Boolean'];
  rentingType: RentingType;
  squareMeters?: Maybe<Scalars['Int']>;
  status?: Maybe<UnitStatus>;
  type?: Maybe<UnitType>;
  unitName: Scalars['String'];
  unitStatusLogs?: Maybe<Array<UnitStatusLog>>;
  updatedAt: Scalars['DateTime'];
};


export type UnitBedroomsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BedroomOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BedroomWhereInput>;
};


export type UnitUnitStatusLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnitStatusLogOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitStatusLogWhereInput>;
};

export type UnitConnection = {
  __typename?: 'UnitConnection';
  aggregate: AggregateUnit;
  edges: Array<Maybe<UnitEdge>>;
  pageInfo: PageInfo;
};

export type UnitCreateInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  bedrooms?: InputMaybe<BedroomCreateManyWithoutUnitInput>;
  building: BuildingCreateOneWithoutUnitsInput;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductCreateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType: RentingType;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName: Scalars['String'];
  unitStatusLogs?: InputMaybe<UnitStatusLogCreateManyWithoutUnitInput>;
};

export type UnitCreateManyInput = {
  connect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  create?: InputMaybe<Array<UnitCreateInput>>;
};

export type UnitCreateManyWithoutBuildingInput = {
  connect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  create?: InputMaybe<Array<UnitCreateWithoutBuildingInput>>;
};

export type UnitCreateOneInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  create?: InputMaybe<UnitCreateInput>;
};

export type UnitCreateOneWithoutBedroomsInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  create?: InputMaybe<UnitCreateWithoutBedroomsInput>;
};

export type UnitCreateOneWithoutUnitStatusLogsInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  create?: InputMaybe<UnitCreateWithoutUnitStatusLogsInput>;
};

export type UnitCreateWithoutBedroomsInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  building: BuildingCreateOneWithoutUnitsInput;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductCreateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType: RentingType;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName: Scalars['String'];
  unitStatusLogs?: InputMaybe<UnitStatusLogCreateManyWithoutUnitInput>;
};

export type UnitCreateWithoutBuildingInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  bedrooms?: InputMaybe<BedroomCreateManyWithoutUnitInput>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductCreateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType: RentingType;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName: Scalars['String'];
  unitStatusLogs?: InputMaybe<UnitStatusLogCreateManyWithoutUnitInput>;
};

export type UnitCreateWithoutUnitStatusLogsInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  bedrooms?: InputMaybe<BedroomCreateManyWithoutUnitInput>;
  building: BuildingCreateOneWithoutUnitsInput;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductCreateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType: RentingType;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName: Scalars['String'];
};

export type UnitEdge = {
  __typename?: 'UnitEdge';
  cursor: Scalars['String'];
  node: Unit;
};

export enum UnitOrderByInput {
  AIRTABLEID_ASC = 'airtableId_ASC',
  AIRTABLEID_DESC = 'airtableId_DESC',
  ASKEDRENT_ASC = 'askedRent_ASC',
  ASKEDRENT_DESC = 'askedRent_DESC',
  AVAILABLEBYDATE_ASC = 'availableByDate_ASC',
  AVAILABLEBYDATE_DESC = 'availableByDate_DESC',
  BASERENT_ASC = 'baseRent_ASC',
  BASERENT_DESC = 'baseRent_DESC',
  COMMERCIALSPACE_ASC = 'commercialSpace_ASC',
  COMMERCIALSPACE_DESC = 'commercialSpace_DESC',
  CONTRACTBASERENT_ASC = 'contractBaseRent_ASC',
  CONTRACTBASERENT_DESC = 'contractBaseRent_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENTRANCE_ASC = 'entrance_ASC',
  ENTRANCE_DESC = 'entrance_DESC',
  EXTERNALID_ASC = 'externalId_ASC',
  EXTERNALID_DESC = 'externalId_DESC',
  FLOOR_ASC = 'floor_ASC',
  FLOOR_DESC = 'floor_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  NUMBEROFBATHROOMS_ASC = 'numberOfBathrooms_ASC',
  NUMBEROFBATHROOMS_DESC = 'numberOfBathrooms_DESC',
  OCCUPIEDBYVENN_ASC = 'occupiedByVenn_ASC',
  OCCUPIEDBYVENN_DESC = 'occupiedByVenn_DESC',
  RENTSTABILIZED_ASC = 'rentStabilized_ASC',
  RENTSTABILIZED_DESC = 'rentStabilized_DESC',
  RENTINGTYPE_ASC = 'rentingType_ASC',
  RENTINGTYPE_DESC = 'rentingType_DESC',
  SQUAREMETERS_ASC = 'squareMeters_ASC',
  SQUAREMETERS_DESC = 'squareMeters_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UNITNAME_ASC = 'unitName_ASC',
  UNITNAME_DESC = 'unitName_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type UnitScalarWhereInput = {
  AND?: InputMaybe<Array<UnitScalarWhereInput>>;
  NOT?: InputMaybe<Array<UnitScalarWhereInput>>;
  OR?: InputMaybe<Array<UnitScalarWhereInput>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  availableByDate_gt?: InputMaybe<Scalars['DateTime']>;
  availableByDate_gte?: InputMaybe<Scalars['DateTime']>;
  availableByDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  availableByDate_lt?: InputMaybe<Scalars['DateTime']>;
  availableByDate_lte?: InputMaybe<Scalars['DateTime']>;
  availableByDate_not?: InputMaybe<Scalars['DateTime']>;
  availableByDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  baseRent?: InputMaybe<Scalars['Float']>;
  baseRent_gt?: InputMaybe<Scalars['Float']>;
  baseRent_gte?: InputMaybe<Scalars['Float']>;
  baseRent_in?: InputMaybe<Array<Scalars['Float']>>;
  baseRent_lt?: InputMaybe<Scalars['Float']>;
  baseRent_lte?: InputMaybe<Scalars['Float']>;
  baseRent_not?: InputMaybe<Scalars['Float']>;
  baseRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  commercialSpace_not?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  contractBaseRent_gt?: InputMaybe<Scalars['Float']>;
  contractBaseRent_gte?: InputMaybe<Scalars['Float']>;
  contractBaseRent_in?: InputMaybe<Array<Scalars['Float']>>;
  contractBaseRent_lt?: InputMaybe<Scalars['Float']>;
  contractBaseRent_lte?: InputMaybe<Scalars['Float']>;
  contractBaseRent_not?: InputMaybe<Scalars['Float']>;
  contractBaseRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['Int']>;
  entrance_gt?: InputMaybe<Scalars['Int']>;
  entrance_gte?: InputMaybe<Scalars['Int']>;
  entrance_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance_lt?: InputMaybe<Scalars['Int']>;
  entrance_lte?: InputMaybe<Scalars['Int']>;
  entrance_not?: InputMaybe<Scalars['Int']>;
  entrance_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  floor_gt?: InputMaybe<Scalars['Int']>;
  floor_gte?: InputMaybe<Scalars['Int']>;
  floor_in?: InputMaybe<Array<Scalars['Int']>>;
  floor_lt?: InputMaybe<Scalars['Int']>;
  floor_lte?: InputMaybe<Scalars['Int']>;
  floor_not?: InputMaybe<Scalars['Int']>;
  floor_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_in?: InputMaybe<Array<Scalars['Float']>>;
  numberOfBathrooms_lt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_lte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not_in?: InputMaybe<Array<Scalars['Float']>>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  occupiedByVenn_not?: InputMaybe<Scalars['Boolean']>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentStabilized_not?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingType>;
  rentingType_in?: InputMaybe<Array<RentingType>>;
  rentingType_not?: InputMaybe<RentingType>;
  rentingType_not_in?: InputMaybe<Array<RentingType>>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<UnitType>;
  type_in?: InputMaybe<Array<UnitType>>;
  type_not?: InputMaybe<UnitType>;
  type_not_in?: InputMaybe<Array<UnitType>>;
  unitName?: InputMaybe<Scalars['String']>;
  unitName_contains?: InputMaybe<Scalars['String']>;
  unitName_ends_with?: InputMaybe<Scalars['String']>;
  unitName_gt?: InputMaybe<Scalars['String']>;
  unitName_gte?: InputMaybe<Scalars['String']>;
  unitName_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_lt?: InputMaybe<Scalars['String']>;
  unitName_lte?: InputMaybe<Scalars['String']>;
  unitName_not?: InputMaybe<Scalars['String']>;
  unitName_not_contains?: InputMaybe<Scalars['String']>;
  unitName_not_ends_with?: InputMaybe<Scalars['String']>;
  unitName_not_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_not_starts_with?: InputMaybe<Scalars['String']>;
  unitName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum UnitStatus {
  AVAILABLE = 'AVAILABLE',
  MAINTENANCE = 'MAINTENANCE',
  SETUP = 'SETUP',
  TURNOVER = 'TURNOVER'
}

export type UnitStatusLog = {
  __typename?: 'UnitStatusLog';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  startDate: Scalars['DateTime'];
  status: UnitStatus;
  unit: Unit;
  updatedAt: Scalars['DateTime'];
};

export type UnitStatusLogConnection = {
  __typename?: 'UnitStatusLogConnection';
  aggregate: AggregateUnitStatusLog;
  edges: Array<Maybe<UnitStatusLogEdge>>;
  pageInfo: PageInfo;
};

export type UnitStatusLogCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  status: UnitStatus;
  unit: UnitCreateOneWithoutUnitStatusLogsInput;
};

export type UnitStatusLogCreateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<UnitStatusLogWhereUniqueInput>>;
  create?: InputMaybe<Array<UnitStatusLogCreateWithoutUnitInput>>;
};

export type UnitStatusLogCreateWithoutUnitInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  status: UnitStatus;
};

export type UnitStatusLogEdge = {
  __typename?: 'UnitStatusLogEdge';
  cursor: Scalars['String'];
  node: UnitStatusLog;
};

export enum UnitStatusLogOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ENDDATE_ASC = 'endDate_ASC',
  ENDDATE_DESC = 'endDate_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  STARTDATE_ASC = 'startDate_ASC',
  STARTDATE_DESC = 'startDate_DESC',
  STATUS_ASC = 'status_ASC',
  STATUS_DESC = 'status_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type UnitStatusLogScalarWhereInput = {
  AND?: InputMaybe<Array<UnitStatusLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<UnitStatusLogScalarWhereInput>>;
  OR?: InputMaybe<Array<UnitStatusLogScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<UnitStatus>;
  status_in?: InputMaybe<Array<UnitStatus>>;
  status_not?: InputMaybe<UnitStatus>;
  status_not_in?: InputMaybe<Array<UnitStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UnitStatusLogUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<UnitStatus>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutUnitStatusLogsInput>;
};

export type UnitStatusLogUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<UnitStatus>;
};

export type UnitStatusLogUpdateManyWithWhereNestedInput = {
  data: UnitStatusLogUpdateManyDataInput;
  where: UnitStatusLogScalarWhereInput;
};

export type UnitStatusLogUpdateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<UnitStatusLogWhereUniqueInput>>;
  create?: InputMaybe<Array<UnitStatusLogCreateWithoutUnitInput>>;
  delete?: InputMaybe<Array<UnitStatusLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UnitStatusLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UnitStatusLogWhereUniqueInput>>;
  set?: InputMaybe<Array<UnitStatusLogWhereUniqueInput>>;
  update?: InputMaybe<Array<UnitStatusLogUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<UnitStatusLogUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<UnitStatusLogUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type UnitStatusLogUpdateWithWhereUniqueWithoutUnitInput = {
  data: UnitStatusLogUpdateWithoutUnitDataInput;
  where: UnitStatusLogWhereUniqueInput;
};

export type UnitStatusLogUpdateWithoutUnitDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<UnitStatus>;
};

export type UnitStatusLogUpsertWithWhereUniqueWithoutUnitInput = {
  create: UnitStatusLogCreateWithoutUnitInput;
  update: UnitStatusLogUpdateWithoutUnitDataInput;
  where: UnitStatusLogWhereUniqueInput;
};

export type UnitStatusLogWhereInput = {
  AND?: InputMaybe<Array<UnitStatusLogWhereInput>>;
  NOT?: InputMaybe<Array<UnitStatusLogWhereInput>>;
  OR?: InputMaybe<Array<UnitStatusLogWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<UnitStatus>;
  status_in?: InputMaybe<Array<UnitStatus>>;
  status_not?: InputMaybe<UnitStatus>;
  status_not_in?: InputMaybe<Array<UnitStatus>>;
  unit?: InputMaybe<UnitWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UnitStatusLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum UnitType {
  DUPLEX = 'DUPLEX',
  STUDIO = 'STUDIO'
}

export enum UnitTypePersistency {
  DUPLEX = 'DUPLEX',
  STUDIO = 'STUDIO'
}

export type UnitUpdateDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  bedrooms?: InputMaybe<BedroomUpdateManyWithoutUnitInput>;
  building?: InputMaybe<BuildingUpdateOneRequiredWithoutUnitsInput>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductUpdateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingType>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName?: InputMaybe<Scalars['String']>;
  unitStatusLogs?: InputMaybe<UnitStatusLogUpdateManyWithoutUnitInput>;
};

export type UnitUpdateInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  bedrooms?: InputMaybe<BedroomUpdateManyWithoutUnitInput>;
  building?: InputMaybe<BuildingUpdateOneRequiredWithoutUnitsInput>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductUpdateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingType>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName?: InputMaybe<Scalars['String']>;
  unitStatusLogs?: InputMaybe<UnitStatusLogUpdateManyWithoutUnitInput>;
};

export type UnitUpdateManyDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingType>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName?: InputMaybe<Scalars['String']>;
};

export type UnitUpdateManyInput = {
  connect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  create?: InputMaybe<Array<UnitCreateInput>>;
  delete?: InputMaybe<Array<UnitWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UnitScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  set?: InputMaybe<Array<UnitWhereUniqueInput>>;
  update?: InputMaybe<Array<UnitUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<UnitUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<UnitUpsertWithWhereUniqueNestedInput>>;
};

export type UnitUpdateManyWithWhereNestedInput = {
  data: UnitUpdateManyDataInput;
  where: UnitScalarWhereInput;
};

export type UnitUpdateManyWithoutBuildingInput = {
  connect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  create?: InputMaybe<Array<UnitCreateWithoutBuildingInput>>;
  delete?: InputMaybe<Array<UnitWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UnitScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  set?: InputMaybe<Array<UnitWhereUniqueInput>>;
  update?: InputMaybe<Array<UnitUpdateWithWhereUniqueWithoutBuildingInput>>;
  updateMany?: InputMaybe<Array<UnitUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<UnitUpsertWithWhereUniqueWithoutBuildingInput>>;
};

export type UnitUpdateOneInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  create?: InputMaybe<UnitCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UnitUpdateDataInput>;
  upsert?: InputMaybe<UnitUpsertNestedInput>;
};

export type UnitUpdateOneRequiredWithoutBedroomsInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  create?: InputMaybe<UnitCreateWithoutBedroomsInput>;
  update?: InputMaybe<UnitUpdateWithoutBedroomsDataInput>;
  upsert?: InputMaybe<UnitUpsertWithoutBedroomsInput>;
};

export type UnitUpdateOneRequiredWithoutUnitStatusLogsInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  create?: InputMaybe<UnitCreateWithoutUnitStatusLogsInput>;
  update?: InputMaybe<UnitUpdateWithoutUnitStatusLogsDataInput>;
  upsert?: InputMaybe<UnitUpsertWithoutUnitStatusLogsInput>;
};

export type UnitUpdateWithWhereUniqueNestedInput = {
  data: UnitUpdateDataInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpdateWithWhereUniqueWithoutBuildingInput = {
  data: UnitUpdateWithoutBuildingDataInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpdateWithoutBedroomsDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  building?: InputMaybe<BuildingUpdateOneRequiredWithoutUnitsInput>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductUpdateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingType>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName?: InputMaybe<Scalars['String']>;
  unitStatusLogs?: InputMaybe<UnitStatusLogUpdateManyWithoutUnitInput>;
};

export type UnitUpdateWithoutBuildingDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  bedrooms?: InputMaybe<BedroomUpdateManyWithoutUnitInput>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductUpdateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingType>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName?: InputMaybe<Scalars['String']>;
  unitStatusLogs?: InputMaybe<UnitStatusLogUpdateManyWithoutUnitInput>;
};

export type UnitUpdateWithoutUnitStatusLogsDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  baseRent?: InputMaybe<Scalars['Float']>;
  bedrooms?: InputMaybe<BedroomUpdateManyWithoutUnitInput>;
  building?: InputMaybe<BuildingUpdateOneRequiredWithoutUnitsInput>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entrance?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Json']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductUpdateOneInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingType>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UnitType>;
  unitName?: InputMaybe<Scalars['String']>;
};

export type UnitUpsertNestedInput = {
  create: UnitCreateInput;
  update: UnitUpdateDataInput;
};

export type UnitUpsertWithWhereUniqueNestedInput = {
  create: UnitCreateInput;
  update: UnitUpdateDataInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpsertWithWhereUniqueWithoutBuildingInput = {
  create: UnitCreateWithoutBuildingInput;
  update: UnitUpdateWithoutBuildingDataInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpsertWithoutBedroomsInput = {
  create: UnitCreateWithoutBedroomsInput;
  update: UnitUpdateWithoutBedroomsDataInput;
};

export type UnitUpsertWithoutUnitStatusLogsInput = {
  create: UnitCreateWithoutUnitStatusLogsInput;
  update: UnitUpdateWithoutUnitStatusLogsDataInput;
};

export type UnitWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<UnitWhereContractsPersistencyInput>>;
  ContractsPersistencyRentingType?: InputMaybe<ContractsPersistencyRentingType>;
  ContractsPersistencyRentingType_in?: InputMaybe<Array<ContractsPersistencyRentingType>>;
  ContractsPersistencyRentingType_not?: InputMaybe<ContractsPersistencyRentingType>;
  ContractsPersistencyRentingType_not_in?: InputMaybe<Array<ContractsPersistencyRentingType>>;
  NOT?: InputMaybe<Array<UnitWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<UnitWhereContractsPersistencyInput>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  baseRent?: InputMaybe<Scalars['Float']>;
  baseRent_gt?: InputMaybe<Scalars['Float']>;
  baseRent_gte?: InputMaybe<Scalars['Float']>;
  baseRent_in?: InputMaybe<Array<Scalars['Float']>>;
  baseRent_lt?: InputMaybe<Scalars['Float']>;
  baseRent_lte?: InputMaybe<Scalars['Float']>;
  baseRent_not?: InputMaybe<Scalars['Float']>;
  baseRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bedrooms_every?: InputMaybe<BedroomWhereContractsPersistencyInput>;
  bedrooms_none?: InputMaybe<BedroomWhereContractsPersistencyInput>;
  bedrooms_some?: InputMaybe<BedroomWhereContractsPersistencyInput>;
  building?: InputMaybe<BuildingWhereContractsPersistencyInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['Int']>;
  entrance_gt?: InputMaybe<Scalars['Int']>;
  entrance_gte?: InputMaybe<Scalars['Int']>;
  entrance_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance_lt?: InputMaybe<Scalars['Int']>;
  entrance_lte?: InputMaybe<Scalars['Int']>;
  entrance_not?: InputMaybe<Scalars['Int']>;
  entrance_not_in?: InputMaybe<Array<Scalars['Int']>>;
  floor?: InputMaybe<Scalars['Int']>;
  floor_gt?: InputMaybe<Scalars['Int']>;
  floor_gte?: InputMaybe<Scalars['Int']>;
  floor_in?: InputMaybe<Array<Scalars['Int']>>;
  floor_lt?: InputMaybe<Scalars['Int']>;
  floor_lte?: InputMaybe<Scalars['Int']>;
  floor_not?: InputMaybe<Scalars['Int']>;
  floor_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_every?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  leaseContracts_none?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWhereContractsPersistencyInput>;
  locks_every?: InputMaybe<LockWhereContractsPersistencyInput>;
  locks_none?: InputMaybe<LockWhereContractsPersistencyInput>;
  locks_some?: InputMaybe<LockWhereContractsPersistencyInput>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_in?: InputMaybe<Array<Scalars['Float']>>;
  numberOfBathrooms_lt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_lte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not_in?: InputMaybe<Array<Scalars['Float']>>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  occupiedByVenn_not?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductWhereContractsPersistencyInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentStabilized_not?: InputMaybe<Scalars['Boolean']>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<ContractsPersistencyUnitType>;
  type_in?: InputMaybe<Array<ContractsPersistencyUnitType>>;
  type_not?: InputMaybe<ContractsPersistencyUnitType>;
  type_not_in?: InputMaybe<Array<ContractsPersistencyUnitType>>;
  underConstruction?: InputMaybe<Scalars['Boolean']>;
  underConstruction_not?: InputMaybe<Scalars['Boolean']>;
  unitName?: InputMaybe<Scalars['String']>;
  unitName_contains?: InputMaybe<Scalars['String']>;
  unitName_ends_with?: InputMaybe<Scalars['String']>;
  unitName_gt?: InputMaybe<Scalars['String']>;
  unitName_gte?: InputMaybe<Scalars['String']>;
  unitName_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_lt?: InputMaybe<Scalars['String']>;
  unitName_lte?: InputMaybe<Scalars['String']>;
  unitName_not?: InputMaybe<Scalars['String']>;
  unitName_not_contains?: InputMaybe<Scalars['String']>;
  unitName_not_ends_with?: InputMaybe<Scalars['String']>;
  unitName_not_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_not_starts_with?: InputMaybe<Scalars['String']>;
  unitName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UnitWhereInput = {
  AND?: InputMaybe<Array<UnitWhereInput>>;
  NOT?: InputMaybe<Array<UnitWhereInput>>;
  OR?: InputMaybe<Array<UnitWhereInput>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  availableByDate_gt?: InputMaybe<Scalars['DateTime']>;
  availableByDate_gte?: InputMaybe<Scalars['DateTime']>;
  availableByDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  availableByDate_lt?: InputMaybe<Scalars['DateTime']>;
  availableByDate_lte?: InputMaybe<Scalars['DateTime']>;
  availableByDate_not?: InputMaybe<Scalars['DateTime']>;
  availableByDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  baseRent?: InputMaybe<Scalars['Float']>;
  baseRent_gt?: InputMaybe<Scalars['Float']>;
  baseRent_gte?: InputMaybe<Scalars['Float']>;
  baseRent_in?: InputMaybe<Array<Scalars['Float']>>;
  baseRent_lt?: InputMaybe<Scalars['Float']>;
  baseRent_lte?: InputMaybe<Scalars['Float']>;
  baseRent_not?: InputMaybe<Scalars['Float']>;
  baseRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bedrooms_every?: InputMaybe<BedroomWhereInput>;
  bedrooms_none?: InputMaybe<BedroomWhereInput>;
  bedrooms_some?: InputMaybe<BedroomWhereInput>;
  building?: InputMaybe<BuildingWhereInput>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  commercialSpace_not?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  contractBaseRent_gt?: InputMaybe<Scalars['Float']>;
  contractBaseRent_gte?: InputMaybe<Scalars['Float']>;
  contractBaseRent_in?: InputMaybe<Array<Scalars['Float']>>;
  contractBaseRent_lt?: InputMaybe<Scalars['Float']>;
  contractBaseRent_lte?: InputMaybe<Scalars['Float']>;
  contractBaseRent_not?: InputMaybe<Scalars['Float']>;
  contractBaseRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  coordinate_intersects?: InputMaybe<PolygonInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['Int']>;
  entrance_gt?: InputMaybe<Scalars['Int']>;
  entrance_gte?: InputMaybe<Scalars['Int']>;
  entrance_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance_lt?: InputMaybe<Scalars['Int']>;
  entrance_lte?: InputMaybe<Scalars['Int']>;
  entrance_not?: InputMaybe<Scalars['Int']>;
  entrance_not_in?: InputMaybe<Array<Scalars['Int']>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['Int']>;
  floor_gt?: InputMaybe<Scalars['Int']>;
  floor_gte?: InputMaybe<Scalars['Int']>;
  floor_in?: InputMaybe<Array<Scalars['Int']>>;
  floor_lt?: InputMaybe<Scalars['Int']>;
  floor_lte?: InputMaybe<Scalars['Int']>;
  floor_not?: InputMaybe<Scalars['Int']>;
  floor_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_none?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_in?: InputMaybe<Array<Scalars['Float']>>;
  numberOfBathrooms_lt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_lte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not_in?: InputMaybe<Array<Scalars['Float']>>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  occupiedByVenn_not?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductWhereInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentStabilized_not?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingType>;
  rentingType_in?: InputMaybe<Array<RentingType>>;
  rentingType_not?: InputMaybe<RentingType>;
  rentingType_not_in?: InputMaybe<Array<RentingType>>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<UnitType>;
  type_in?: InputMaybe<Array<UnitType>>;
  type_not?: InputMaybe<UnitType>;
  type_not_in?: InputMaybe<Array<UnitType>>;
  unitName?: InputMaybe<Scalars['String']>;
  unitName_contains?: InputMaybe<Scalars['String']>;
  unitName_ends_with?: InputMaybe<Scalars['String']>;
  unitName_gt?: InputMaybe<Scalars['String']>;
  unitName_gte?: InputMaybe<Scalars['String']>;
  unitName_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_lt?: InputMaybe<Scalars['String']>;
  unitName_lte?: InputMaybe<Scalars['String']>;
  unitName_not?: InputMaybe<Scalars['String']>;
  unitName_not_contains?: InputMaybe<Scalars['String']>;
  unitName_not_ends_with?: InputMaybe<Scalars['String']>;
  unitName_not_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_not_starts_with?: InputMaybe<Scalars['String']>;
  unitName_starts_with?: InputMaybe<Scalars['String']>;
  unitStatusLogs_every?: InputMaybe<UnitStatusLogWhereInput>;
  unitStatusLogs_none?: InputMaybe<UnitStatusLogWhereInput>;
  unitStatusLogs_some?: InputMaybe<UnitStatusLogWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UnitWhereInputPersistency = {
  AND?: InputMaybe<Array<UnitWhereInputPersistency>>;
  NOT?: InputMaybe<Array<UnitWhereInputPersistency>>;
  OR?: InputMaybe<Array<UnitWhereInputPersistency>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  availableByDate?: InputMaybe<Scalars['DateTime']>;
  availableByDate_gt?: InputMaybe<Scalars['DateTime']>;
  availableByDate_gte?: InputMaybe<Scalars['DateTime']>;
  availableByDate_in?: InputMaybe<Array<Scalars['DateTime']>>;
  availableByDate_lt?: InputMaybe<Scalars['DateTime']>;
  availableByDate_lte?: InputMaybe<Scalars['DateTime']>;
  availableByDate_not?: InputMaybe<Scalars['DateTime']>;
  availableByDate_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  baseRent?: InputMaybe<Scalars['Float']>;
  baseRent_gt?: InputMaybe<Scalars['Float']>;
  baseRent_gte?: InputMaybe<Scalars['Float']>;
  baseRent_in?: InputMaybe<Array<Scalars['Float']>>;
  baseRent_lt?: InputMaybe<Scalars['Float']>;
  baseRent_lte?: InputMaybe<Scalars['Float']>;
  baseRent_not?: InputMaybe<Scalars['Float']>;
  baseRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  building?: InputMaybe<BuildingWhereInputPersistency>;
  commercialSpace?: InputMaybe<Scalars['Boolean']>;
  commercialSpace_not?: InputMaybe<Scalars['Boolean']>;
  contractBaseRent?: InputMaybe<Scalars['Float']>;
  contractBaseRent_gt?: InputMaybe<Scalars['Float']>;
  contractBaseRent_gte?: InputMaybe<Scalars['Float']>;
  contractBaseRent_in?: InputMaybe<Array<Scalars['Float']>>;
  contractBaseRent_lt?: InputMaybe<Scalars['Float']>;
  contractBaseRent_lte?: InputMaybe<Scalars['Float']>;
  contractBaseRent_not?: InputMaybe<Scalars['Float']>;
  contractBaseRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['Int']>;
  entrance_gt?: InputMaybe<Scalars['Int']>;
  entrance_gte?: InputMaybe<Scalars['Int']>;
  entrance_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance_lt?: InputMaybe<Scalars['Int']>;
  entrance_lte?: InputMaybe<Scalars['Int']>;
  entrance_not?: InputMaybe<Scalars['Int']>;
  entrance_not_in?: InputMaybe<Array<Scalars['Int']>>;
  floor?: InputMaybe<Scalars['Int']>;
  floor_gt?: InputMaybe<Scalars['Int']>;
  floor_gte?: InputMaybe<Scalars['Int']>;
  floor_in?: InputMaybe<Array<Scalars['Int']>>;
  floor_lt?: InputMaybe<Scalars['Int']>;
  floor_lte?: InputMaybe<Scalars['Int']>;
  floor_not?: InputMaybe<Scalars['Int']>;
  floor_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_in?: InputMaybe<Array<Scalars['Float']>>;
  numberOfBathrooms_lt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_lte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not_in?: InputMaybe<Array<Scalars['Float']>>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  occupiedByVenn_not?: InputMaybe<Scalars['Boolean']>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentStabilized_not?: InputMaybe<Scalars['Boolean']>;
  rentingType?: InputMaybe<RentingTypePersistency>;
  rentingType_in?: InputMaybe<Array<RentingTypePersistency>>;
  rentingType_not?: InputMaybe<RentingTypePersistency>;
  rentingType_not_in?: InputMaybe<Array<RentingTypePersistency>>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<UnitTypePersistency>;
  type_in?: InputMaybe<Array<UnitTypePersistency>>;
  type_not?: InputMaybe<UnitTypePersistency>;
  type_not_in?: InputMaybe<Array<UnitTypePersistency>>;
  unitName?: InputMaybe<Scalars['String']>;
  unitName_contains?: InputMaybe<Scalars['String']>;
  unitName_ends_with?: InputMaybe<Scalars['String']>;
  unitName_gt?: InputMaybe<Scalars['String']>;
  unitName_gte?: InputMaybe<Scalars['String']>;
  unitName_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_lt?: InputMaybe<Scalars['String']>;
  unitName_lte?: InputMaybe<Scalars['String']>;
  unitName_not?: InputMaybe<Scalars['String']>;
  unitName_not_contains?: InputMaybe<Scalars['String']>;
  unitName_not_ends_with?: InputMaybe<Scalars['String']>;
  unitName_not_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_not_starts_with?: InputMaybe<Scalars['String']>;
  unitName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UnitWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<UnitWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<UnitWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<UnitWherePersistencyContractsInput>>;
  PersistencyContractsRentingType?: InputMaybe<PersistencyContractsRentingType>;
  PersistencyContractsRentingType_in?: InputMaybe<Array<PersistencyContractsRentingType>>;
  PersistencyContractsRentingType_not?: InputMaybe<PersistencyContractsRentingType>;
  PersistencyContractsRentingType_not_in?: InputMaybe<Array<PersistencyContractsRentingType>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  askedRent?: InputMaybe<Scalars['Float']>;
  askedRent_gt?: InputMaybe<Scalars['Float']>;
  askedRent_gte?: InputMaybe<Scalars['Float']>;
  askedRent_in?: InputMaybe<Array<Scalars['Float']>>;
  askedRent_lt?: InputMaybe<Scalars['Float']>;
  askedRent_lte?: InputMaybe<Scalars['Float']>;
  askedRent_not?: InputMaybe<Scalars['Float']>;
  askedRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  baseRent?: InputMaybe<Scalars['Float']>;
  baseRent_gt?: InputMaybe<Scalars['Float']>;
  baseRent_gte?: InputMaybe<Scalars['Float']>;
  baseRent_in?: InputMaybe<Array<Scalars['Float']>>;
  baseRent_lt?: InputMaybe<Scalars['Float']>;
  baseRent_lte?: InputMaybe<Scalars['Float']>;
  baseRent_not?: InputMaybe<Scalars['Float']>;
  baseRent_not_in?: InputMaybe<Array<Scalars['Float']>>;
  bedrooms_every?: InputMaybe<BedroomWherePersistencyContractsInput>;
  bedrooms_none?: InputMaybe<BedroomWherePersistencyContractsInput>;
  bedrooms_some?: InputMaybe<BedroomWherePersistencyContractsInput>;
  building?: InputMaybe<BuildingWherePersistencyContractsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance?: InputMaybe<Scalars['Int']>;
  entrance_gt?: InputMaybe<Scalars['Int']>;
  entrance_gte?: InputMaybe<Scalars['Int']>;
  entrance_in?: InputMaybe<Array<Scalars['Int']>>;
  entrance_lt?: InputMaybe<Scalars['Int']>;
  entrance_lte?: InputMaybe<Scalars['Int']>;
  entrance_not?: InputMaybe<Scalars['Int']>;
  entrance_not_in?: InputMaybe<Array<Scalars['Int']>>;
  floor?: InputMaybe<Scalars['Int']>;
  floor_gt?: InputMaybe<Scalars['Int']>;
  floor_gte?: InputMaybe<Scalars['Int']>;
  floor_in?: InputMaybe<Array<Scalars['Int']>>;
  floor_lt?: InputMaybe<Scalars['Int']>;
  floor_lte?: InputMaybe<Scalars['Int']>;
  floor_not?: InputMaybe<Scalars['Int']>;
  floor_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  leaseContracts_every?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_none?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  leaseContracts_some?: InputMaybe<LeaseContractWherePersistencyContractsInput>;
  locks_every?: InputMaybe<LockWherePersistencyContractsInput>;
  locks_none?: InputMaybe<LockWherePersistencyContractsInput>;
  locks_some?: InputMaybe<LockWherePersistencyContractsInput>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_gte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_in?: InputMaybe<Array<Scalars['Float']>>;
  numberOfBathrooms_lt?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_lte?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not?: InputMaybe<Scalars['Float']>;
  numberOfBathrooms_not_in?: InputMaybe<Array<Scalars['Float']>>;
  occupiedByVenn?: InputMaybe<Scalars['Boolean']>;
  occupiedByVenn_not?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductWherePersistencyContractsInput>;
  rentStabilized?: InputMaybe<Scalars['Boolean']>;
  rentStabilized_not?: InputMaybe<Scalars['Boolean']>;
  squareMeters?: InputMaybe<Scalars['Int']>;
  squareMeters_gt?: InputMaybe<Scalars['Int']>;
  squareMeters_gte?: InputMaybe<Scalars['Int']>;
  squareMeters_in?: InputMaybe<Array<Scalars['Int']>>;
  squareMeters_lt?: InputMaybe<Scalars['Int']>;
  squareMeters_lte?: InputMaybe<Scalars['Int']>;
  squareMeters_not?: InputMaybe<Scalars['Int']>;
  squareMeters_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<PersistencyContractsUnitType>;
  type_in?: InputMaybe<Array<PersistencyContractsUnitType>>;
  type_not?: InputMaybe<PersistencyContractsUnitType>;
  type_not_in?: InputMaybe<Array<PersistencyContractsUnitType>>;
  underConstruction?: InputMaybe<Scalars['Boolean']>;
  underConstruction_not?: InputMaybe<Scalars['Boolean']>;
  unitName?: InputMaybe<Scalars['String']>;
  unitName_contains?: InputMaybe<Scalars['String']>;
  unitName_ends_with?: InputMaybe<Scalars['String']>;
  unitName_gt?: InputMaybe<Scalars['String']>;
  unitName_gte?: InputMaybe<Scalars['String']>;
  unitName_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_lt?: InputMaybe<Scalars['String']>;
  unitName_lte?: InputMaybe<Scalars['String']>;
  unitName_not?: InputMaybe<Scalars['String']>;
  unitName_not_contains?: InputMaybe<Scalars['String']>;
  unitName_not_ends_with?: InputMaybe<Scalars['String']>;
  unitName_not_in?: InputMaybe<Array<Scalars['String']>>;
  unitName_not_starts_with?: InputMaybe<Scalars['String']>;
  unitName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UnitWhereUniqueInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type UpdateInterestGroupInput = {
  createdByUserId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  moderatorsIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _empty?: Maybe<Scalars['String']>;
  airtableId?: Maybe<Scalars['String']>;
  autoPayThroughHood?: Maybe<Scalars['Boolean']>;
  bills?: Maybe<Array<Bill>>;
  comment?: Maybe<Scalars['String']>;
  computedBedroom?: Maybe<Bedroom>;
  computedBuilding?: Maybe<Building>;
  computedMemberType?: Maybe<MemberType>;
  computedUnit?: Maybe<Unit>;
  createdAt: Scalars['DateTime'];
  credit?: Maybe<Scalars['Float']>;
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dontRenewContract: Scalars['Boolean'];
  email: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  hood: Hood;
  hubMetadataRaw?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  isVennEmployee?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  legacyTenant: Scalars['Boolean'];
  member?: Maybe<Member>;
  memberProfile?: Maybe<MemberProfile>;
  metadata?: Maybe<Scalars['Json']>;
  middleName?: Maybe<Scalars['String']>;
  missingEmail: Scalars['Boolean'];
  mobilePhone?: Maybe<Scalars['String']>;
  nationalID?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<UserPaymentMethod>>;
  pmsEmail?: Maybe<Scalars['String']>;
  pmsPhone?: Maybe<Scalars['String']>;
  primaryLanguage?: Maybe<Scalars['String']>;
  residentialStatus?: Maybe<ResidentialStatus>;
  roles?: Maybe<Array<Role>>;
  syntheticUser?: Maybe<Scalars['Boolean']>;
  uiMetadata?: Maybe<UserUiMetadata>;
  uiMetadataRaw?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type UserBillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BillOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BillWhereInput>;
};


export type UserMemberProfileArgs = {
  where?: InputMaybe<MemberProfileWhereUniqueInput>;
};


export type UserPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserPaymentMethodOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPaymentMethodWhereInput>;
};


export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RoleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleWhereInput>;
};

export type UserApiKey = {
  __typename?: 'UserApiKey';
  _empty?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hashedApiKey: Scalars['String'];
  id: Scalars['ID'];
  salt: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type UserApiKeyConnection = {
  __typename?: 'UserApiKeyConnection';
  aggregate: AggregateUserApiKey;
  edges: Array<Maybe<UserApiKeyEdge>>;
  pageInfo: PageInfo;
};

export type UserApiKeyCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hashedApiKey: Scalars['String'];
  salt: Scalars['String'];
  user: UserCreateOneInput;
};

export type UserApiKeyEdge = {
  __typename?: 'UserApiKeyEdge';
  cursor: Scalars['String'];
  node: UserApiKey;
};

export enum UserApiKeyOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  HASHEDAPIKEY_ASC = 'hashedApiKey_ASC',
  HASHEDAPIKEY_DESC = 'hashedApiKey_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  SALT_ASC = 'salt_ASC',
  SALT_DESC = 'salt_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type UserApiKeyUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  hashedApiKey?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserUpdateOneRequiredInput>;
};

export type UserApiKeyWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<UserApiKeyWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<UserApiKeyWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<UserApiKeyWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hashedApiKey?: InputMaybe<Scalars['String']>;
  hashedApiKey_contains?: InputMaybe<Scalars['String']>;
  hashedApiKey_ends_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_gt?: InputMaybe<Scalars['String']>;
  hashedApiKey_gte?: InputMaybe<Scalars['String']>;
  hashedApiKey_in?: InputMaybe<Array<Scalars['String']>>;
  hashedApiKey_lt?: InputMaybe<Scalars['String']>;
  hashedApiKey_lte?: InputMaybe<Scalars['String']>;
  hashedApiKey_not?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_contains?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_ends_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_in?: InputMaybe<Array<Scalars['String']>>;
  hashedApiKey_not_starts_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  salt?: InputMaybe<Scalars['String']>;
  salt_contains?: InputMaybe<Scalars['String']>;
  salt_ends_with?: InputMaybe<Scalars['String']>;
  salt_gt?: InputMaybe<Scalars['String']>;
  salt_gte?: InputMaybe<Scalars['String']>;
  salt_in?: InputMaybe<Array<Scalars['String']>>;
  salt_lt?: InputMaybe<Scalars['String']>;
  salt_lte?: InputMaybe<Scalars['String']>;
  salt_not?: InputMaybe<Scalars['String']>;
  salt_not_contains?: InputMaybe<Scalars['String']>;
  salt_not_ends_with?: InputMaybe<Scalars['String']>;
  salt_not_in?: InputMaybe<Array<Scalars['String']>>;
  salt_not_starts_with?: InputMaybe<Scalars['String']>;
  salt_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
};

export type UserApiKeyWhereInput = {
  AND?: InputMaybe<Array<UserApiKeyWhereInput>>;
  NOT?: InputMaybe<Array<UserApiKeyWhereInput>>;
  OR?: InputMaybe<Array<UserApiKeyWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hashedApiKey?: InputMaybe<Scalars['String']>;
  hashedApiKey_contains?: InputMaybe<Scalars['String']>;
  hashedApiKey_ends_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_gt?: InputMaybe<Scalars['String']>;
  hashedApiKey_gte?: InputMaybe<Scalars['String']>;
  hashedApiKey_in?: InputMaybe<Array<Scalars['String']>>;
  hashedApiKey_lt?: InputMaybe<Scalars['String']>;
  hashedApiKey_lte?: InputMaybe<Scalars['String']>;
  hashedApiKey_not?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_contains?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_ends_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_in?: InputMaybe<Array<Scalars['String']>>;
  hashedApiKey_not_starts_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  salt?: InputMaybe<Scalars['String']>;
  salt_contains?: InputMaybe<Scalars['String']>;
  salt_ends_with?: InputMaybe<Scalars['String']>;
  salt_gt?: InputMaybe<Scalars['String']>;
  salt_gte?: InputMaybe<Scalars['String']>;
  salt_in?: InputMaybe<Array<Scalars['String']>>;
  salt_lt?: InputMaybe<Scalars['String']>;
  salt_lte?: InputMaybe<Scalars['String']>;
  salt_not?: InputMaybe<Scalars['String']>;
  salt_not_contains?: InputMaybe<Scalars['String']>;
  salt_not_ends_with?: InputMaybe<Scalars['String']>;
  salt_not_in?: InputMaybe<Array<Scalars['String']>>;
  salt_not_starts_with?: InputMaybe<Scalars['String']>;
  salt_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
};

export type UserApiKeyWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<UserApiKeyWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<UserApiKeyWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<UserApiKeyWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hashedApiKey?: InputMaybe<Scalars['String']>;
  hashedApiKey_contains?: InputMaybe<Scalars['String']>;
  hashedApiKey_ends_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_gt?: InputMaybe<Scalars['String']>;
  hashedApiKey_gte?: InputMaybe<Scalars['String']>;
  hashedApiKey_in?: InputMaybe<Array<Scalars['String']>>;
  hashedApiKey_lt?: InputMaybe<Scalars['String']>;
  hashedApiKey_lte?: InputMaybe<Scalars['String']>;
  hashedApiKey_not?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_contains?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_ends_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_not_in?: InputMaybe<Array<Scalars['String']>>;
  hashedApiKey_not_starts_with?: InputMaybe<Scalars['String']>;
  hashedApiKey_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  salt?: InputMaybe<Scalars['String']>;
  salt_contains?: InputMaybe<Scalars['String']>;
  salt_ends_with?: InputMaybe<Scalars['String']>;
  salt_gt?: InputMaybe<Scalars['String']>;
  salt_gte?: InputMaybe<Scalars['String']>;
  salt_in?: InputMaybe<Array<Scalars['String']>>;
  salt_lt?: InputMaybe<Scalars['String']>;
  salt_lte?: InputMaybe<Scalars['String']>;
  salt_not?: InputMaybe<Scalars['String']>;
  salt_not_contains?: InputMaybe<Scalars['String']>;
  salt_not_ends_with?: InputMaybe<Scalars['String']>;
  salt_not_in?: InputMaybe<Array<Scalars['String']>>;
  salt_not_starts_with?: InputMaybe<Scalars['String']>;
  salt_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
};

export type UserApiKeyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  aggregate: AggregateUser;
  edges: Array<Maybe<UserEdge>>;
  pageInfo: PageInfo;
};

export type UserCreateInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillCreateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomCreateOneInput>;
  computedBuilding?: InputMaybe<BuildingCreateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitCreateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  hood: HoodCreateOneWithoutCommunityManagersInput;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName: Scalars['String'];
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberCreateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodCreateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleCreateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserCreateManyInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  create?: InputMaybe<Array<UserCreateInput>>;
};

export type UserCreateManyWithoutComputedBuildingInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  create?: InputMaybe<Array<UserCreateWithoutComputedBuildingInput>>;
};

export type UserCreateManyWithoutHoodInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  create?: InputMaybe<Array<UserCreateWithoutHoodInput>>;
};

export type UserCreateManyWithoutRolesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  create?: InputMaybe<Array<UserCreateWithoutRolesInput>>;
};

export type UserCreateOneContractsPersistencyInput = {
  connect?: InputMaybe<UserWhereUniqueContractsPersistencyInput>;
};

export type UserCreateOneInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
};

export type UserCreateOneWithoutMemberInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateWithoutMemberInput>;
};

export type UserCreateOneWithoutPaymentMethodsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateWithoutPaymentMethodsInput>;
};

export type UserCreateWithoutComputedBuildingInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillCreateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomCreateOneInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitCreateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  hood: HoodCreateOneWithoutCommunityManagersInput;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName: Scalars['String'];
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberCreateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodCreateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleCreateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserCreateWithoutHoodInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillCreateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomCreateOneInput>;
  computedBuilding?: InputMaybe<BuildingCreateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitCreateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName: Scalars['String'];
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberCreateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodCreateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleCreateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserCreateWithoutMemberInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillCreateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomCreateOneInput>;
  computedBuilding?: InputMaybe<BuildingCreateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitCreateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  hood: HoodCreateOneWithoutCommunityManagersInput;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName: Scalars['String'];
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodCreateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleCreateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserCreateWithoutPaymentMethodsInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillCreateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomCreateOneInput>;
  computedBuilding?: InputMaybe<BuildingCreateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitCreateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  hood: HoodCreateOneWithoutCommunityManagersInput;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName: Scalars['String'];
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberCreateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleCreateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserCreateWithoutRolesInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillCreateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomCreateOneInput>;
  computedBuilding?: InputMaybe<BuildingCreateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitCreateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  hood: HoodCreateOneWithoutCommunityManagersInput;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName: Scalars['String'];
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberCreateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodCreateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export enum UserOrderByInput {
  AIRTABLEID_ASC = 'airtableId_ASC',
  AIRTABLEID_DESC = 'airtableId_DESC',
  AUTOPAYTHROUGHHOOD_ASC = 'autoPayThroughHood_ASC',
  AUTOPAYTHROUGHHOOD_DESC = 'autoPayThroughHood_DESC',
  COMMENT_ASC = 'comment_ASC',
  COMMENT_DESC = 'comment_DESC',
  COMPUTEDMEMBERTYPE_ASC = 'computedMemberType_ASC',
  COMPUTEDMEMBERTYPE_DESC = 'computedMemberType_DESC',
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  DONTRENEWCONTRACT_ASC = 'dontRenewContract_ASC',
  DONTRENEWCONTRACT_DESC = 'dontRenewContract_DESC',
  EMAIL_ASC = 'email_ASC',
  EMAIL_DESC = 'email_DESC',
  EXTERNALID_ASC = 'externalId_ASC',
  EXTERNALID_DESC = 'externalId_DESC',
  FIRSTNAME_ASC = 'firstName_ASC',
  FIRSTNAME_DESC = 'firstName_DESC',
  HUBMETADATARAW_ASC = 'hubMetadataRaw_ASC',
  HUBMETADATARAW_DESC = 'hubMetadataRaw_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  LASTNAME_ASC = 'lastName_ASC',
  LASTNAME_DESC = 'lastName_DESC',
  LEGACYTENANT_ASC = 'legacyTenant_ASC',
  LEGACYTENANT_DESC = 'legacyTenant_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  MIDDLENAME_ASC = 'middleName_ASC',
  MIDDLENAME_DESC = 'middleName_DESC',
  MISSINGEMAIL_ASC = 'missingEmail_ASC',
  MISSINGEMAIL_DESC = 'missingEmail_DESC',
  MOBILEPHONE_ASC = 'mobilePhone_ASC',
  MOBILEPHONE_DESC = 'mobilePhone_DESC',
  NATIONALID_ASC = 'nationalID_ASC',
  NATIONALID_DESC = 'nationalID_DESC',
  PMSEMAIL_ASC = 'pmsEmail_ASC',
  PMSEMAIL_DESC = 'pmsEmail_DESC',
  PMSPHONE_ASC = 'pmsPhone_ASC',
  PMSPHONE_DESC = 'pmsPhone_DESC',
  PRIMARYLANGUAGE_ASC = 'primaryLanguage_ASC',
  PRIMARYLANGUAGE_DESC = 'primaryLanguage_DESC',
  RESIDENTIALSTATUS_ASC = 'residentialStatus_ASC',
  RESIDENTIALSTATUS_DESC = 'residentialStatus_DESC',
  SYNTHETICUSER_ASC = 'syntheticUser_ASC',
  SYNTHETICUSER_DESC = 'syntheticUser_DESC',
  UIMETADATARAW_ASC = 'uiMetadataRaw_ASC',
  UIMETADATARAW_DESC = 'uiMetadataRaw_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC'
}

export type UserPaymentMethod = {
  __typename?: 'UserPaymentMethod';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['Json']>;
  paymentMethodFingerprint?: Maybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: Maybe<Scalars['String']>;
  paymentProvider: PaymentProvider;
  token?: Maybe<Scalars['String']>;
  transactionFeePercent?: Maybe<Scalars['Float']>;
  type: PaymentMethodType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userIdInPaymentProvider?: Maybe<Scalars['String']>;
};

export type UserPaymentMethodConnection = {
  __typename?: 'UserPaymentMethodConnection';
  aggregate: AggregateUserPaymentMethod;
  edges: Array<Maybe<UserPaymentMethodEdge>>;
  pageInfo: PageInfo;
};

export type UserPaymentMethodCreateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentProvider: PaymentProviderCreateOneInput;
  token?: InputMaybe<Scalars['String']>;
  type: PaymentMethodType;
  user: UserCreateOneWithoutPaymentMethodsInput;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodCreateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserPaymentMethodWhereUniqueInput>>;
  create?: InputMaybe<Array<UserPaymentMethodCreateWithoutUserInput>>;
};

export type UserPaymentMethodCreateOneInput = {
  connect?: InputMaybe<UserPaymentMethodWhereUniqueInput>;
  create?: InputMaybe<UserPaymentMethodCreateInput>;
};

export type UserPaymentMethodCreateWithoutUserInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentProvider: PaymentProviderCreateOneInput;
  token?: InputMaybe<Scalars['String']>;
  type: PaymentMethodType;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodEdge = {
  __typename?: 'UserPaymentMethodEdge';
  cursor: Scalars['String'];
  node: UserPaymentMethod;
};

export enum UserPaymentMethodOrderByInput {
  CREATEDAT_ASC = 'createdAt_ASC',
  CREATEDAT_DESC = 'createdAt_DESC',
  DELETEDAT_ASC = 'deletedAt_ASC',
  DELETEDAT_DESC = 'deletedAt_DESC',
  DELETED_ASC = 'deleted_ASC',
  DELETED_DESC = 'deleted_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  ISDEFAULT_ASC = 'isDefault_ASC',
  ISDEFAULT_DESC = 'isDefault_DESC',
  METADATA_ASC = 'metadata_ASC',
  METADATA_DESC = 'metadata_DESC',
  PAYMENTMETHODFINGERPRINT_ASC = 'paymentMethodFingerprint_ASC',
  PAYMENTMETHODFINGERPRINT_DESC = 'paymentMethodFingerprint_DESC',
  PAYMENTMETHODIDINPAYMENTPROVIDER_ASC = 'paymentMethodIdInPaymentProvider_ASC',
  PAYMENTMETHODIDINPAYMENTPROVIDER_DESC = 'paymentMethodIdInPaymentProvider_DESC',
  TOKEN_ASC = 'token_ASC',
  TOKEN_DESC = 'token_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
  UPDATEDAT_ASC = 'updatedAt_ASC',
  UPDATEDAT_DESC = 'updatedAt_DESC',
  USERIDINPAYMENTPROVIDER_ASC = 'userIdInPaymentProvider_ASC',
  USERIDINPAYMENTPROVIDER_DESC = 'userIdInPaymentProvider_DESC'
}

export type UserPaymentMethodScalarWhereInput = {
  AND?: InputMaybe<Array<UserPaymentMethodScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserPaymentMethodScalarWhereInput>>;
  OR?: InputMaybe<Array<UserPaymentMethodScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_contains?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_gt?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_gte?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodFingerprint_lt?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_lte?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_contains?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodFingerprint_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentMethodType>;
  type_in?: InputMaybe<Array<PaymentMethodType>>;
  type_not?: InputMaybe<PaymentMethodType>;
  type_not_in?: InputMaybe<Array<PaymentMethodType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  userIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  userIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodUpdateDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderUpdateOneRequiredInput>;
  token?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentMethodType>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPaymentMethodsInput>;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodUpdateInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderUpdateOneRequiredInput>;
  token?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentMethodType>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPaymentMethodsInput>;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodUpdateManyDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentMethodType>;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodUpdateManyWithWhereNestedInput = {
  data: UserPaymentMethodUpdateManyDataInput;
  where: UserPaymentMethodScalarWhereInput;
};

export type UserPaymentMethodUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserPaymentMethodWhereUniqueInput>>;
  create?: InputMaybe<Array<UserPaymentMethodCreateWithoutUserInput>>;
  delete?: InputMaybe<Array<UserPaymentMethodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPaymentMethodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPaymentMethodWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPaymentMethodWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPaymentMethodUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserPaymentMethodUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<UserPaymentMethodUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserPaymentMethodUpdateOneInput = {
  connect?: InputMaybe<UserPaymentMethodWhereUniqueInput>;
  create?: InputMaybe<UserPaymentMethodCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserPaymentMethodUpdateDataInput>;
  upsert?: InputMaybe<UserPaymentMethodUpsertNestedInput>;
};

export type UserPaymentMethodUpdateWithWhereUniqueWithoutUserInput = {
  data: UserPaymentMethodUpdateWithoutUserDataInput;
  where: UserPaymentMethodWhereUniqueInput;
};

export type UserPaymentMethodUpdateWithoutUserDataInput = {
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderUpdateOneRequiredInput>;
  token?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentMethodType>;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodUpsertNestedInput = {
  create: UserPaymentMethodCreateInput;
  update: UserPaymentMethodUpdateDataInput;
};

export type UserPaymentMethodUpsertWithWhereUniqueWithoutUserInput = {
  create: UserPaymentMethodCreateWithoutUserInput;
  update: UserPaymentMethodUpdateWithoutUserDataInput;
  where: UserPaymentMethodWhereUniqueInput;
};

export type UserPaymentMethodWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<UserPaymentMethodWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<UserPaymentMethodWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<UserPaymentMethodWhereContractsPersistencyInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_contains?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_gt?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_gte?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodFingerprint_lt?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_lte?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_contains?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodFingerprint_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderWhereContractsPersistencyInput>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ContractsPersistencyPaymentMethodType>;
  type_in?: InputMaybe<Array<ContractsPersistencyPaymentMethodType>>;
  type_not?: InputMaybe<ContractsPersistencyPaymentMethodType>;
  type_not_in?: InputMaybe<Array<ContractsPersistencyPaymentMethodType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereContractsPersistencyInput>;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  userIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  userIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodWhereInput = {
  AND?: InputMaybe<Array<UserPaymentMethodWhereInput>>;
  NOT?: InputMaybe<Array<UserPaymentMethodWhereInput>>;
  OR?: InputMaybe<Array<UserPaymentMethodWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_contains?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_gt?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_gte?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodFingerprint_lt?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_lte?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_contains?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodFingerprint_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderWhereInput>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentMethodType>;
  type_in?: InputMaybe<Array<PaymentMethodType>>;
  type_not?: InputMaybe<PaymentMethodType>;
  type_not_in?: InputMaybe<Array<PaymentMethodType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWhereInput>;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  userIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  userIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<UserPaymentMethodWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<UserPaymentMethodWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<UserPaymentMethodWherePersistencyContractsInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']>;
  paymentMethodFingerprint?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_contains?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_gt?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_gte?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodFingerprint_lt?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_lte?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_contains?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodFingerprint_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodFingerprint_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProviderWherePersistencyContractsInput>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PersistencyContractsPaymentMethodType>;
  type_in?: InputMaybe<Array<PersistencyContractsPaymentMethodType>>;
  type_not?: InputMaybe<PersistencyContractsPaymentMethodType>;
  type_not_in?: InputMaybe<Array<PersistencyContractsPaymentMethodType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  user?: InputMaybe<UserWherePersistencyContractsInput>;
  userIdInPaymentProvider?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_contains?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_ends_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_gt?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_gte?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_in?: InputMaybe<Array<Scalars['String']>>;
  userIdInPaymentProvider_lt?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_lte?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_contains?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_ends_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_not_in?: InputMaybe<Array<Scalars['String']>>;
  userIdInPaymentProvider_not_starts_with?: InputMaybe<Scalars['String']>;
  userIdInPaymentProvider_starts_with?: InputMaybe<Scalars['String']>;
};

export type UserPaymentMethodWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  autoPayThroughHood_not?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  computedMemberType?: InputMaybe<MemberType>;
  computedMemberType_in?: InputMaybe<Array<MemberType>>;
  computedMemberType_not?: InputMaybe<MemberType>;
  computedMemberType_not_in?: InputMaybe<Array<MemberType>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  dontRenewContract_not?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_ends_with?: InputMaybe<Scalars['String']>;
  email_gt?: InputMaybe<Scalars['String']>;
  email_gte?: InputMaybe<Scalars['String']>;
  email_in?: InputMaybe<Array<Scalars['String']>>;
  email_lt?: InputMaybe<Scalars['String']>;
  email_lte?: InputMaybe<Scalars['String']>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_ends_with?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<Scalars['String']>>;
  email_not_starts_with?: InputMaybe<Scalars['String']>;
  email_starts_with?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_ends_with?: InputMaybe<Scalars['String']>;
  firstName_gt?: InputMaybe<Scalars['String']>;
  firstName_gte?: InputMaybe<Scalars['String']>;
  firstName_in?: InputMaybe<Array<Scalars['String']>>;
  firstName_lt?: InputMaybe<Scalars['String']>;
  firstName_lte?: InputMaybe<Scalars['String']>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_ends_with?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<Scalars['String']>>;
  firstName_not_starts_with?: InputMaybe<Scalars['String']>;
  firstName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_ends_with?: InputMaybe<Scalars['String']>;
  lastName_gt?: InputMaybe<Scalars['String']>;
  lastName_gte?: InputMaybe<Scalars['String']>;
  lastName_in?: InputMaybe<Array<Scalars['String']>>;
  lastName_lt?: InputMaybe<Scalars['String']>;
  lastName_lte?: InputMaybe<Scalars['String']>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_ends_with?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<Scalars['String']>>;
  lastName_not_starts_with?: InputMaybe<Scalars['String']>;
  lastName_starts_with?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  legacyTenant_not?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['String']>;
  middleName_contains?: InputMaybe<Scalars['String']>;
  middleName_ends_with?: InputMaybe<Scalars['String']>;
  middleName_gt?: InputMaybe<Scalars['String']>;
  middleName_gte?: InputMaybe<Scalars['String']>;
  middleName_in?: InputMaybe<Array<Scalars['String']>>;
  middleName_lt?: InputMaybe<Scalars['String']>;
  middleName_lte?: InputMaybe<Scalars['String']>;
  middleName_not?: InputMaybe<Scalars['String']>;
  middleName_not_contains?: InputMaybe<Scalars['String']>;
  middleName_not_ends_with?: InputMaybe<Scalars['String']>;
  middleName_not_in?: InputMaybe<Array<Scalars['String']>>;
  middleName_not_starts_with?: InputMaybe<Scalars['String']>;
  middleName_starts_with?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  missingEmail_not?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  mobilePhone_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_ends_with?: InputMaybe<Scalars['String']>;
  mobilePhone_gt?: InputMaybe<Scalars['String']>;
  mobilePhone_gte?: InputMaybe<Scalars['String']>;
  mobilePhone_in?: InputMaybe<Array<Scalars['String']>>;
  mobilePhone_lt?: InputMaybe<Scalars['String']>;
  mobilePhone_lte?: InputMaybe<Scalars['String']>;
  mobilePhone_not?: InputMaybe<Scalars['String']>;
  mobilePhone_not_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_not_ends_with?: InputMaybe<Scalars['String']>;
  mobilePhone_not_in?: InputMaybe<Array<Scalars['String']>>;
  mobilePhone_not_starts_with?: InputMaybe<Scalars['String']>;
  mobilePhone_starts_with?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  nationalID_contains?: InputMaybe<Scalars['String']>;
  nationalID_ends_with?: InputMaybe<Scalars['String']>;
  nationalID_gt?: InputMaybe<Scalars['String']>;
  nationalID_gte?: InputMaybe<Scalars['String']>;
  nationalID_in?: InputMaybe<Array<Scalars['String']>>;
  nationalID_lt?: InputMaybe<Scalars['String']>;
  nationalID_lte?: InputMaybe<Scalars['String']>;
  nationalID_not?: InputMaybe<Scalars['String']>;
  nationalID_not_contains?: InputMaybe<Scalars['String']>;
  nationalID_not_ends_with?: InputMaybe<Scalars['String']>;
  nationalID_not_in?: InputMaybe<Array<Scalars['String']>>;
  nationalID_not_starts_with?: InputMaybe<Scalars['String']>;
  nationalID_starts_with?: InputMaybe<Scalars['String']>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsEmail_contains?: InputMaybe<Scalars['String']>;
  pmsEmail_ends_with?: InputMaybe<Scalars['String']>;
  pmsEmail_gt?: InputMaybe<Scalars['String']>;
  pmsEmail_gte?: InputMaybe<Scalars['String']>;
  pmsEmail_in?: InputMaybe<Array<Scalars['String']>>;
  pmsEmail_lt?: InputMaybe<Scalars['String']>;
  pmsEmail_lte?: InputMaybe<Scalars['String']>;
  pmsEmail_not?: InputMaybe<Scalars['String']>;
  pmsEmail_not_contains?: InputMaybe<Scalars['String']>;
  pmsEmail_not_ends_with?: InputMaybe<Scalars['String']>;
  pmsEmail_not_in?: InputMaybe<Array<Scalars['String']>>;
  pmsEmail_not_starts_with?: InputMaybe<Scalars['String']>;
  pmsEmail_starts_with?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  pmsPhone_contains?: InputMaybe<Scalars['String']>;
  pmsPhone_ends_with?: InputMaybe<Scalars['String']>;
  pmsPhone_gt?: InputMaybe<Scalars['String']>;
  pmsPhone_gte?: InputMaybe<Scalars['String']>;
  pmsPhone_in?: InputMaybe<Array<Scalars['String']>>;
  pmsPhone_lt?: InputMaybe<Scalars['String']>;
  pmsPhone_lte?: InputMaybe<Scalars['String']>;
  pmsPhone_not?: InputMaybe<Scalars['String']>;
  pmsPhone_not_contains?: InputMaybe<Scalars['String']>;
  pmsPhone_not_ends_with?: InputMaybe<Scalars['String']>;
  pmsPhone_not_in?: InputMaybe<Array<Scalars['String']>>;
  pmsPhone_not_starts_with?: InputMaybe<Scalars['String']>;
  pmsPhone_starts_with?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  primaryLanguage_contains?: InputMaybe<Scalars['String']>;
  primaryLanguage_ends_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_gt?: InputMaybe<Scalars['String']>;
  primaryLanguage_gte?: InputMaybe<Scalars['String']>;
  primaryLanguage_in?: InputMaybe<Array<Scalars['String']>>;
  primaryLanguage_lt?: InputMaybe<Scalars['String']>;
  primaryLanguage_lte?: InputMaybe<Scalars['String']>;
  primaryLanguage_not?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_contains?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_ends_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_in?: InputMaybe<Array<Scalars['String']>>;
  primaryLanguage_not_starts_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_starts_with?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  residentialStatus_in?: InputMaybe<Array<ResidentialStatus>>;
  residentialStatus_not?: InputMaybe<ResidentialStatus>;
  residentialStatus_not_in?: InputMaybe<Array<ResidentialStatus>>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  syntheticUser_not?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UserSurveyStatus = {
  __typename?: 'UserSurveyStatus';
  answers?: Maybe<Array<Maybe<SurveyQuestionResponse>>>;
  fullySubmitted?: Maybe<Scalars['Boolean']>;
};

export type UserUiMetadata = {
  __typename?: 'UserUiMetadata';
  completedAppOnboarding?: Maybe<Scalars['Boolean']>;
};

export type UserUiMetadataInput = {
  completedAppOnboarding?: InputMaybe<Scalars['Boolean']>;
};

export type UserUpdateDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillUpdateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomUpdateOneInput>;
  computedBuilding?: InputMaybe<BuildingUpdateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneRequiredWithoutCommunityManagersInput>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberUpdateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodUpdateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpdateInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillUpdateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomUpdateOneInput>;
  computedBuilding?: InputMaybe<BuildingUpdateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneRequiredWithoutCommunityManagersInput>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberUpdateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodUpdateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadata?: InputMaybe<UserUiMetadataInput>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpdateManyDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  computedMemberType?: InputMaybe<MemberType>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpdateManyInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  create?: InputMaybe<Array<UserCreateInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueNestedInput>>;
};

export type UserUpdateManyMutationInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  computedMemberType?: InputMaybe<MemberType>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpdateManyWithWhereNestedInput = {
  data: UserUpdateManyDataInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutComputedBuildingInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  create?: InputMaybe<Array<UserCreateWithoutComputedBuildingInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutComputedBuildingInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutComputedBuildingInput>>;
};

export type UserUpdateManyWithoutHoodInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  create?: InputMaybe<Array<UserCreateWithoutHoodInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutHoodInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutHoodInput>>;
};

export type UserUpdateManyWithoutRolesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  create?: InputMaybe<Array<UserCreateWithoutRolesInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutRolesInput>>;
};

export type UserUpdateOneInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateDataInput>;
  upsert?: InputMaybe<UserUpsertNestedInput>;
};

export type UserUpdateOneRequiredInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
  update?: InputMaybe<UserUpdateDataInput>;
  upsert?: InputMaybe<UserUpsertNestedInput>;
};

export type UserUpdateOneRequiredWithoutMemberInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateWithoutMemberInput>;
  update?: InputMaybe<UserUpdateWithoutMemberDataInput>;
  upsert?: InputMaybe<UserUpsertWithoutMemberInput>;
};

export type UserUpdateOneRequiredWithoutPaymentMethodsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateWithoutPaymentMethodsInput>;
  update?: InputMaybe<UserUpdateWithoutPaymentMethodsDataInput>;
  upsert?: InputMaybe<UserUpsertWithoutPaymentMethodsInput>;
};

export type UserUpdateWithWhereUniqueNestedInput = {
  data: UserUpdateDataInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutComputedBuildingInput = {
  data: UserUpdateWithoutComputedBuildingDataInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutHoodInput = {
  data: UserUpdateWithoutHoodDataInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutRolesInput = {
  data: UserUpdateWithoutRolesDataInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutComputedBuildingDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillUpdateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomUpdateOneInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneRequiredWithoutCommunityManagersInput>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberUpdateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodUpdateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpdateWithoutHoodDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillUpdateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomUpdateOneInput>;
  computedBuilding?: InputMaybe<BuildingUpdateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberUpdateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodUpdateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpdateWithoutMemberDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillUpdateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomUpdateOneInput>;
  computedBuilding?: InputMaybe<BuildingUpdateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneRequiredWithoutCommunityManagersInput>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodUpdateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpdateWithoutPaymentMethodsDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillUpdateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomUpdateOneInput>;
  computedBuilding?: InputMaybe<BuildingUpdateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneRequiredWithoutCommunityManagersInput>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberUpdateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpdateWithoutRolesDataInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  bills?: InputMaybe<BillUpdateManyInput>;
  comment?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomUpdateOneInput>;
  computedBuilding?: InputMaybe<BuildingUpdateOneWithoutUsersInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedUnit?: InputMaybe<UnitUpdateOneInput>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodUpdateOneRequiredWithoutCommunityManagersInput>;
  hubMetadataRaw?: InputMaybe<Scalars['Json']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberUpdateOneWithoutUserInput>;
  metadata?: InputMaybe<Scalars['Json']>;
  middleName?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<UserPaymentMethodUpdateManyWithoutUserInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  uiMetadataRaw?: InputMaybe<Scalars['Json']>;
};

export type UserUpsertNestedInput = {
  create: UserCreateInput;
  update: UserUpdateDataInput;
};

export type UserUpsertWithWhereUniqueNestedInput = {
  create: UserCreateInput;
  update: UserUpdateDataInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutComputedBuildingInput = {
  create: UserCreateWithoutComputedBuildingInput;
  update: UserUpdateWithoutComputedBuildingDataInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutHoodInput = {
  create: UserCreateWithoutHoodInput;
  update: UserUpdateWithoutHoodDataInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutRolesInput = {
  create: UserCreateWithoutRolesInput;
  update: UserUpdateWithoutRolesDataInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutMemberInput = {
  create: UserCreateWithoutMemberInput;
  update: UserUpdateWithoutMemberDataInput;
};

export type UserUpsertWithoutPaymentMethodsInput = {
  create: UserCreateWithoutPaymentMethodsInput;
  update: UserUpdateWithoutPaymentMethodsDataInput;
};

export type UserViewedMomentInput = {
  momentId: Scalars['String'];
};

export type UserWhereContractsPersistencyInput = {
  AND?: InputMaybe<Array<UserWhereContractsPersistencyInput>>;
  NOT?: InputMaybe<Array<UserWhereContractsPersistencyInput>>;
  OR?: InputMaybe<Array<UserWhereContractsPersistencyInput>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  autoPayThroughHood_not?: InputMaybe<Scalars['Boolean']>;
  bills_every?: InputMaybe<BillWhereContractsPersistencyInput>;
  bills_none?: InputMaybe<BillWhereContractsPersistencyInput>;
  bills_some?: InputMaybe<BillWhereContractsPersistencyInput>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  dontRenewContract_not?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_ends_with?: InputMaybe<Scalars['String']>;
  email_gt?: InputMaybe<Scalars['String']>;
  email_gte?: InputMaybe<Scalars['String']>;
  email_in?: InputMaybe<Array<Scalars['String']>>;
  email_lt?: InputMaybe<Scalars['String']>;
  email_lte?: InputMaybe<Scalars['String']>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_ends_with?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<Scalars['String']>>;
  email_not_starts_with?: InputMaybe<Scalars['String']>;
  email_starts_with?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_ends_with?: InputMaybe<Scalars['String']>;
  firstName_gt?: InputMaybe<Scalars['String']>;
  firstName_gte?: InputMaybe<Scalars['String']>;
  firstName_in?: InputMaybe<Array<Scalars['String']>>;
  firstName_lt?: InputMaybe<Scalars['String']>;
  firstName_lte?: InputMaybe<Scalars['String']>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_ends_with?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<Scalars['String']>>;
  firstName_not_starts_with?: InputMaybe<Scalars['String']>;
  firstName_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWhereContractsPersistencyInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_ends_with?: InputMaybe<Scalars['String']>;
  lastName_gt?: InputMaybe<Scalars['String']>;
  lastName_gte?: InputMaybe<Scalars['String']>;
  lastName_in?: InputMaybe<Array<Scalars['String']>>;
  lastName_lt?: InputMaybe<Scalars['String']>;
  lastName_lte?: InputMaybe<Scalars['String']>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_ends_with?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<Scalars['String']>>;
  lastName_not_starts_with?: InputMaybe<Scalars['String']>;
  lastName_starts_with?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  legacyTenant_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWhereContractsPersistencyInput>;
  middleName?: InputMaybe<Scalars['String']>;
  middleName_contains?: InputMaybe<Scalars['String']>;
  middleName_ends_with?: InputMaybe<Scalars['String']>;
  middleName_gt?: InputMaybe<Scalars['String']>;
  middleName_gte?: InputMaybe<Scalars['String']>;
  middleName_in?: InputMaybe<Array<Scalars['String']>>;
  middleName_lt?: InputMaybe<Scalars['String']>;
  middleName_lte?: InputMaybe<Scalars['String']>;
  middleName_not?: InputMaybe<Scalars['String']>;
  middleName_not_contains?: InputMaybe<Scalars['String']>;
  middleName_not_ends_with?: InputMaybe<Scalars['String']>;
  middleName_not_in?: InputMaybe<Array<Scalars['String']>>;
  middleName_not_starts_with?: InputMaybe<Scalars['String']>;
  middleName_starts_with?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  mobilePhone_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_ends_with?: InputMaybe<Scalars['String']>;
  mobilePhone_gt?: InputMaybe<Scalars['String']>;
  mobilePhone_gte?: InputMaybe<Scalars['String']>;
  mobilePhone_in?: InputMaybe<Array<Scalars['String']>>;
  mobilePhone_lt?: InputMaybe<Scalars['String']>;
  mobilePhone_lte?: InputMaybe<Scalars['String']>;
  mobilePhone_not?: InputMaybe<Scalars['String']>;
  mobilePhone_not_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_not_ends_with?: InputMaybe<Scalars['String']>;
  mobilePhone_not_in?: InputMaybe<Array<Scalars['String']>>;
  mobilePhone_not_starts_with?: InputMaybe<Scalars['String']>;
  mobilePhone_starts_with?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  nationalID_contains?: InputMaybe<Scalars['String']>;
  nationalID_ends_with?: InputMaybe<Scalars['String']>;
  nationalID_gt?: InputMaybe<Scalars['String']>;
  nationalID_gte?: InputMaybe<Scalars['String']>;
  nationalID_in?: InputMaybe<Array<Scalars['String']>>;
  nationalID_lt?: InputMaybe<Scalars['String']>;
  nationalID_lte?: InputMaybe<Scalars['String']>;
  nationalID_not?: InputMaybe<Scalars['String']>;
  nationalID_not_contains?: InputMaybe<Scalars['String']>;
  nationalID_not_ends_with?: InputMaybe<Scalars['String']>;
  nationalID_not_in?: InputMaybe<Array<Scalars['String']>>;
  nationalID_not_starts_with?: InputMaybe<Scalars['String']>;
  nationalID_starts_with?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  primaryLanguage_contains?: InputMaybe<Scalars['String']>;
  primaryLanguage_ends_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_gt?: InputMaybe<Scalars['String']>;
  primaryLanguage_gte?: InputMaybe<Scalars['String']>;
  primaryLanguage_in?: InputMaybe<Array<Scalars['String']>>;
  primaryLanguage_lt?: InputMaybe<Scalars['String']>;
  primaryLanguage_lte?: InputMaybe<Scalars['String']>;
  primaryLanguage_not?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_contains?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_ends_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_in?: InputMaybe<Array<Scalars['String']>>;
  primaryLanguage_not_starts_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_starts_with?: InputMaybe<Scalars['String']>;
  roles_every?: InputMaybe<RoleWhereContractsPersistencyInput>;
  roles_none?: InputMaybe<RoleWhereContractsPersistencyInput>;
  roles_some?: InputMaybe<RoleWhereContractsPersistencyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  autoPayThroughHood_not?: InputMaybe<Scalars['Boolean']>;
  bills_every?: InputMaybe<BillWhereInput>;
  bills_none?: InputMaybe<BillWhereInput>;
  bills_some?: InputMaybe<BillWhereInput>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  computedBedroom?: InputMaybe<BedroomWhereInput>;
  computedBuilding?: InputMaybe<BuildingWhereInput>;
  computedMemberType?: InputMaybe<MemberType>;
  computedMemberType_in?: InputMaybe<Array<MemberType>>;
  computedMemberType_not?: InputMaybe<MemberType>;
  computedMemberType_not_in?: InputMaybe<Array<MemberType>>;
  computedUnit?: InputMaybe<UnitWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  dontRenewContract_not?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_ends_with?: InputMaybe<Scalars['String']>;
  email_gt?: InputMaybe<Scalars['String']>;
  email_gte?: InputMaybe<Scalars['String']>;
  email_in?: InputMaybe<Array<Scalars['String']>>;
  email_lt?: InputMaybe<Scalars['String']>;
  email_lte?: InputMaybe<Scalars['String']>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_ends_with?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<Scalars['String']>>;
  email_not_starts_with?: InputMaybe<Scalars['String']>;
  email_starts_with?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId_contains?: InputMaybe<Scalars['String']>;
  externalId_ends_with?: InputMaybe<Scalars['String']>;
  externalId_gt?: InputMaybe<Scalars['String']>;
  externalId_gte?: InputMaybe<Scalars['String']>;
  externalId_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_lt?: InputMaybe<Scalars['String']>;
  externalId_lte?: InputMaybe<Scalars['String']>;
  externalId_not?: InputMaybe<Scalars['String']>;
  externalId_not_contains?: InputMaybe<Scalars['String']>;
  externalId_not_ends_with?: InputMaybe<Scalars['String']>;
  externalId_not_in?: InputMaybe<Array<Scalars['String']>>;
  externalId_not_starts_with?: InputMaybe<Scalars['String']>;
  externalId_starts_with?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_ends_with?: InputMaybe<Scalars['String']>;
  firstName_gt?: InputMaybe<Scalars['String']>;
  firstName_gte?: InputMaybe<Scalars['String']>;
  firstName_in?: InputMaybe<Array<Scalars['String']>>;
  firstName_lt?: InputMaybe<Scalars['String']>;
  firstName_lte?: InputMaybe<Scalars['String']>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_ends_with?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<Scalars['String']>>;
  firstName_not_starts_with?: InputMaybe<Scalars['String']>;
  firstName_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_ends_with?: InputMaybe<Scalars['String']>;
  lastName_gt?: InputMaybe<Scalars['String']>;
  lastName_gte?: InputMaybe<Scalars['String']>;
  lastName_in?: InputMaybe<Array<Scalars['String']>>;
  lastName_lt?: InputMaybe<Scalars['String']>;
  lastName_lte?: InputMaybe<Scalars['String']>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_ends_with?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<Scalars['String']>>;
  lastName_not_starts_with?: InputMaybe<Scalars['String']>;
  lastName_starts_with?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  legacyTenant_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWhereInput>;
  middleName?: InputMaybe<Scalars['String']>;
  middleName_contains?: InputMaybe<Scalars['String']>;
  middleName_ends_with?: InputMaybe<Scalars['String']>;
  middleName_gt?: InputMaybe<Scalars['String']>;
  middleName_gte?: InputMaybe<Scalars['String']>;
  middleName_in?: InputMaybe<Array<Scalars['String']>>;
  middleName_lt?: InputMaybe<Scalars['String']>;
  middleName_lte?: InputMaybe<Scalars['String']>;
  middleName_not?: InputMaybe<Scalars['String']>;
  middleName_not_contains?: InputMaybe<Scalars['String']>;
  middleName_not_ends_with?: InputMaybe<Scalars['String']>;
  middleName_not_in?: InputMaybe<Array<Scalars['String']>>;
  middleName_not_starts_with?: InputMaybe<Scalars['String']>;
  middleName_starts_with?: InputMaybe<Scalars['String']>;
  missingEmail?: InputMaybe<Scalars['Boolean']>;
  missingEmail_not?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  mobilePhone_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_ends_with?: InputMaybe<Scalars['String']>;
  mobilePhone_gt?: InputMaybe<Scalars['String']>;
  mobilePhone_gte?: InputMaybe<Scalars['String']>;
  mobilePhone_in?: InputMaybe<Array<Scalars['String']>>;
  mobilePhone_lt?: InputMaybe<Scalars['String']>;
  mobilePhone_lte?: InputMaybe<Scalars['String']>;
  mobilePhone_not?: InputMaybe<Scalars['String']>;
  mobilePhone_not_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_not_ends_with?: InputMaybe<Scalars['String']>;
  mobilePhone_not_in?: InputMaybe<Array<Scalars['String']>>;
  mobilePhone_not_starts_with?: InputMaybe<Scalars['String']>;
  mobilePhone_starts_with?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  nationalID_contains?: InputMaybe<Scalars['String']>;
  nationalID_ends_with?: InputMaybe<Scalars['String']>;
  nationalID_gt?: InputMaybe<Scalars['String']>;
  nationalID_gte?: InputMaybe<Scalars['String']>;
  nationalID_in?: InputMaybe<Array<Scalars['String']>>;
  nationalID_lt?: InputMaybe<Scalars['String']>;
  nationalID_lte?: InputMaybe<Scalars['String']>;
  nationalID_not?: InputMaybe<Scalars['String']>;
  nationalID_not_contains?: InputMaybe<Scalars['String']>;
  nationalID_not_ends_with?: InputMaybe<Scalars['String']>;
  nationalID_not_in?: InputMaybe<Array<Scalars['String']>>;
  nationalID_not_starts_with?: InputMaybe<Scalars['String']>;
  nationalID_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethods_every?: InputMaybe<UserPaymentMethodWhereInput>;
  paymentMethods_none?: InputMaybe<UserPaymentMethodWhereInput>;
  paymentMethods_some?: InputMaybe<UserPaymentMethodWhereInput>;
  pmsEmail?: InputMaybe<Scalars['String']>;
  pmsEmail_contains?: InputMaybe<Scalars['String']>;
  pmsEmail_ends_with?: InputMaybe<Scalars['String']>;
  pmsEmail_gt?: InputMaybe<Scalars['String']>;
  pmsEmail_gte?: InputMaybe<Scalars['String']>;
  pmsEmail_in?: InputMaybe<Array<Scalars['String']>>;
  pmsEmail_lt?: InputMaybe<Scalars['String']>;
  pmsEmail_lte?: InputMaybe<Scalars['String']>;
  pmsEmail_not?: InputMaybe<Scalars['String']>;
  pmsEmail_not_contains?: InputMaybe<Scalars['String']>;
  pmsEmail_not_ends_with?: InputMaybe<Scalars['String']>;
  pmsEmail_not_in?: InputMaybe<Array<Scalars['String']>>;
  pmsEmail_not_starts_with?: InputMaybe<Scalars['String']>;
  pmsEmail_starts_with?: InputMaybe<Scalars['String']>;
  pmsPhone?: InputMaybe<Scalars['String']>;
  pmsPhone_contains?: InputMaybe<Scalars['String']>;
  pmsPhone_ends_with?: InputMaybe<Scalars['String']>;
  pmsPhone_gt?: InputMaybe<Scalars['String']>;
  pmsPhone_gte?: InputMaybe<Scalars['String']>;
  pmsPhone_in?: InputMaybe<Array<Scalars['String']>>;
  pmsPhone_lt?: InputMaybe<Scalars['String']>;
  pmsPhone_lte?: InputMaybe<Scalars['String']>;
  pmsPhone_not?: InputMaybe<Scalars['String']>;
  pmsPhone_not_contains?: InputMaybe<Scalars['String']>;
  pmsPhone_not_ends_with?: InputMaybe<Scalars['String']>;
  pmsPhone_not_in?: InputMaybe<Array<Scalars['String']>>;
  pmsPhone_not_starts_with?: InputMaybe<Scalars['String']>;
  pmsPhone_starts_with?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  primaryLanguage_contains?: InputMaybe<Scalars['String']>;
  primaryLanguage_ends_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_gt?: InputMaybe<Scalars['String']>;
  primaryLanguage_gte?: InputMaybe<Scalars['String']>;
  primaryLanguage_in?: InputMaybe<Array<Scalars['String']>>;
  primaryLanguage_lt?: InputMaybe<Scalars['String']>;
  primaryLanguage_lte?: InputMaybe<Scalars['String']>;
  primaryLanguage_not?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_contains?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_ends_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_in?: InputMaybe<Array<Scalars['String']>>;
  primaryLanguage_not_starts_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_starts_with?: InputMaybe<Scalars['String']>;
  residentialStatus?: InputMaybe<ResidentialStatus>;
  residentialStatus_in?: InputMaybe<Array<ResidentialStatus>>;
  residentialStatus_not?: InputMaybe<ResidentialStatus>;
  residentialStatus_not_in?: InputMaybe<Array<ResidentialStatus>>;
  roles_every?: InputMaybe<RoleWhereInput>;
  roles_none?: InputMaybe<RoleWhereInput>;
  roles_some?: InputMaybe<RoleWhereInput>;
  syntheticUser?: InputMaybe<Scalars['Boolean']>;
  syntheticUser_not?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UserWherePersistencyContractsInput = {
  AND?: InputMaybe<Array<UserWherePersistencyContractsInput>>;
  NOT?: InputMaybe<Array<UserWherePersistencyContractsInput>>;
  OR?: InputMaybe<Array<UserWherePersistencyContractsInput>>;
  airtableId?: InputMaybe<Scalars['String']>;
  airtableId_contains?: InputMaybe<Scalars['String']>;
  airtableId_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_gt?: InputMaybe<Scalars['String']>;
  airtableId_gte?: InputMaybe<Scalars['String']>;
  airtableId_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_lt?: InputMaybe<Scalars['String']>;
  airtableId_lte?: InputMaybe<Scalars['String']>;
  airtableId_not?: InputMaybe<Scalars['String']>;
  airtableId_not_contains?: InputMaybe<Scalars['String']>;
  airtableId_not_ends_with?: InputMaybe<Scalars['String']>;
  airtableId_not_in?: InputMaybe<Array<Scalars['String']>>;
  airtableId_not_starts_with?: InputMaybe<Scalars['String']>;
  airtableId_starts_with?: InputMaybe<Scalars['String']>;
  autoPayThroughHood?: InputMaybe<Scalars['Boolean']>;
  autoPayThroughHood_not?: InputMaybe<Scalars['Boolean']>;
  bills_every?: InputMaybe<BillWherePersistencyContractsInput>;
  bills_none?: InputMaybe<BillWherePersistencyContractsInput>;
  bills_some?: InputMaybe<BillWherePersistencyContractsInput>;
  comment?: InputMaybe<Scalars['String']>;
  comment_contains?: InputMaybe<Scalars['String']>;
  comment_ends_with?: InputMaybe<Scalars['String']>;
  comment_gt?: InputMaybe<Scalars['String']>;
  comment_gte?: InputMaybe<Scalars['String']>;
  comment_in?: InputMaybe<Array<Scalars['String']>>;
  comment_lt?: InputMaybe<Scalars['String']>;
  comment_lte?: InputMaybe<Scalars['String']>;
  comment_not?: InputMaybe<Scalars['String']>;
  comment_not_contains?: InputMaybe<Scalars['String']>;
  comment_not_ends_with?: InputMaybe<Scalars['String']>;
  comment_not_in?: InputMaybe<Array<Scalars['String']>>;
  comment_not_starts_with?: InputMaybe<Scalars['String']>;
  comment_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>;
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not?: InputMaybe<Scalars['DateTime']>;
  deletedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  deleted_gt?: InputMaybe<Scalars['Int']>;
  deleted_gte?: InputMaybe<Scalars['Int']>;
  deleted_in?: InputMaybe<Array<Scalars['Int']>>;
  deleted_lt?: InputMaybe<Scalars['Int']>;
  deleted_lte?: InputMaybe<Scalars['Int']>;
  deleted_not?: InputMaybe<Scalars['Int']>;
  deleted_not_in?: InputMaybe<Array<Scalars['Int']>>;
  dontRenewContract?: InputMaybe<Scalars['Boolean']>;
  dontRenewContract_not?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_ends_with?: InputMaybe<Scalars['String']>;
  email_gt?: InputMaybe<Scalars['String']>;
  email_gte?: InputMaybe<Scalars['String']>;
  email_in?: InputMaybe<Array<Scalars['String']>>;
  email_lt?: InputMaybe<Scalars['String']>;
  email_lte?: InputMaybe<Scalars['String']>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_ends_with?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<Scalars['String']>>;
  email_not_starts_with?: InputMaybe<Scalars['String']>;
  email_starts_with?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_ends_with?: InputMaybe<Scalars['String']>;
  firstName_gt?: InputMaybe<Scalars['String']>;
  firstName_gte?: InputMaybe<Scalars['String']>;
  firstName_in?: InputMaybe<Array<Scalars['String']>>;
  firstName_lt?: InputMaybe<Scalars['String']>;
  firstName_lte?: InputMaybe<Scalars['String']>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_ends_with?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<Scalars['String']>>;
  firstName_not_starts_with?: InputMaybe<Scalars['String']>;
  firstName_starts_with?: InputMaybe<Scalars['String']>;
  hood?: InputMaybe<HoodWherePersistencyContractsInput>;
  id?: InputMaybe<Scalars['ID']>;
  id_contains?: InputMaybe<Scalars['ID']>;
  id_ends_with?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_contains?: InputMaybe<Scalars['ID']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  id_starts_with?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_ends_with?: InputMaybe<Scalars['String']>;
  lastName_gt?: InputMaybe<Scalars['String']>;
  lastName_gte?: InputMaybe<Scalars['String']>;
  lastName_in?: InputMaybe<Array<Scalars['String']>>;
  lastName_lt?: InputMaybe<Scalars['String']>;
  lastName_lte?: InputMaybe<Scalars['String']>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_ends_with?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<Scalars['String']>>;
  lastName_not_starts_with?: InputMaybe<Scalars['String']>;
  lastName_starts_with?: InputMaybe<Scalars['String']>;
  legacyTenant?: InputMaybe<Scalars['Boolean']>;
  legacyTenant_not?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<MemberWherePersistencyContractsInput>;
  middleName?: InputMaybe<Scalars['String']>;
  middleName_contains?: InputMaybe<Scalars['String']>;
  middleName_ends_with?: InputMaybe<Scalars['String']>;
  middleName_gt?: InputMaybe<Scalars['String']>;
  middleName_gte?: InputMaybe<Scalars['String']>;
  middleName_in?: InputMaybe<Array<Scalars['String']>>;
  middleName_lt?: InputMaybe<Scalars['String']>;
  middleName_lte?: InputMaybe<Scalars['String']>;
  middleName_not?: InputMaybe<Scalars['String']>;
  middleName_not_contains?: InputMaybe<Scalars['String']>;
  middleName_not_ends_with?: InputMaybe<Scalars['String']>;
  middleName_not_in?: InputMaybe<Array<Scalars['String']>>;
  middleName_not_starts_with?: InputMaybe<Scalars['String']>;
  middleName_starts_with?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  mobilePhone_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_ends_with?: InputMaybe<Scalars['String']>;
  mobilePhone_gt?: InputMaybe<Scalars['String']>;
  mobilePhone_gte?: InputMaybe<Scalars['String']>;
  mobilePhone_in?: InputMaybe<Array<Scalars['String']>>;
  mobilePhone_lt?: InputMaybe<Scalars['String']>;
  mobilePhone_lte?: InputMaybe<Scalars['String']>;
  mobilePhone_not?: InputMaybe<Scalars['String']>;
  mobilePhone_not_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_not_ends_with?: InputMaybe<Scalars['String']>;
  mobilePhone_not_in?: InputMaybe<Array<Scalars['String']>>;
  mobilePhone_not_starts_with?: InputMaybe<Scalars['String']>;
  mobilePhone_starts_with?: InputMaybe<Scalars['String']>;
  nationalID?: InputMaybe<Scalars['String']>;
  nationalID_contains?: InputMaybe<Scalars['String']>;
  nationalID_ends_with?: InputMaybe<Scalars['String']>;
  nationalID_gt?: InputMaybe<Scalars['String']>;
  nationalID_gte?: InputMaybe<Scalars['String']>;
  nationalID_in?: InputMaybe<Array<Scalars['String']>>;
  nationalID_lt?: InputMaybe<Scalars['String']>;
  nationalID_lte?: InputMaybe<Scalars['String']>;
  nationalID_not?: InputMaybe<Scalars['String']>;
  nationalID_not_contains?: InputMaybe<Scalars['String']>;
  nationalID_not_ends_with?: InputMaybe<Scalars['String']>;
  nationalID_not_in?: InputMaybe<Array<Scalars['String']>>;
  nationalID_not_starts_with?: InputMaybe<Scalars['String']>;
  nationalID_starts_with?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<Scalars['String']>;
  primaryLanguage_contains?: InputMaybe<Scalars['String']>;
  primaryLanguage_ends_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_gt?: InputMaybe<Scalars['String']>;
  primaryLanguage_gte?: InputMaybe<Scalars['String']>;
  primaryLanguage_in?: InputMaybe<Array<Scalars['String']>>;
  primaryLanguage_lt?: InputMaybe<Scalars['String']>;
  primaryLanguage_lte?: InputMaybe<Scalars['String']>;
  primaryLanguage_not?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_contains?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_ends_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_not_in?: InputMaybe<Array<Scalars['String']>>;
  primaryLanguage_not_starts_with?: InputMaybe<Scalars['String']>;
  primaryLanguage_starts_with?: InputMaybe<Scalars['String']>;
  roles_every?: InputMaybe<RoleWherePersistencyContractsInput>;
  roles_none?: InputMaybe<RoleWherePersistencyContractsInput>;
  roles_some?: InputMaybe<RoleWherePersistencyContractsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UserWhereUniqueContractsPersistencyInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
};

export type UserWhereUniqueInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
};

export type VennerNotificationConfiguration = {
  __typename?: 'VennerNotificationConfiguration';
  enabled: Scalars['Boolean'];
  toEmail?: Maybe<Array<Maybe<Scalars['String']>>>;
  when?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type VipPassHoodSettingValue = {
  __typename?: 'VipPassHoodSettingValue';
  settingId: Scalars['ID'];
  value: Scalars['String'];
};

export type CalendarBookingDetails = {
  slots: Array<CalendarTimeSlotInput>;
};

export type ExtractFeaturedSlidePriorityInput = {
  featuredSlideId: Scalars['String'];
};

export type InsertFeaturedSlidePriorityInput = {
  featuredSlideId: Scalars['String'];
  priority: Scalars['Int'];
};

export type PaymentMethodFee = {
  __typename?: 'paymentMethodFee';
  feePercentage: Scalars['Float'];
  name: Scalars['String'];
};

export type PaymentProviderFeesSetting = {
  __typename?: 'paymentProviderFeesSetting';
  paymentMethodFees?: Maybe<Array<PaymentMethodFee>>;
  providerName: Scalars['String'];
};

export type PaymentReceiptSettings = {
  __typename?: 'paymentReceiptSettings';
  enabled: Scalars['Boolean'];
};

export type PurchaseImmediateProductInput = {
  calendarBookingDetails?: InputMaybe<CalendarBookingDetails>;
  paymentMethodId: Scalars['String'];
  serviceProductId: Scalars['String'];
  userId: Scalars['String'];
};

export type PurchaseProductInput = {
  calendarBookingDetails?: InputMaybe<CalendarBookingDetails>;
  serviceProductId: Scalars['String'];
  userId: Scalars['String'];
};

export type PurchaseServiceProductResult = {
  __typename?: 'purchaseServiceProductResult';
  clientSecret?: Maybe<Scalars['String']>;
  isSucceeded: Scalars['Boolean'];
  order: ServiceProductOrder;
};

export type RentLateFeesSettings = {
  __typename?: 'rentLateFeesSettings';
  enabled: Scalars['Boolean'];
  globalOverrideGracePeriod?: Maybe<Scalars['Int']>;
};

export type SearchFeaturedSlidesInput = {
  first?: InputMaybe<Scalars['Int']>;
  hoodId: Scalars['String'];
  q: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
};

export type SubscribeProductInput = {
  serviceProductId: Scalars['String'];
  userId: Scalars['String'];
};

export type SubscribeServiceProductResult = {
  __typename?: 'subscribeServiceProductResult';
  isSucceeded: Scalars['Boolean'];
  subscriptionContract: SubscriptionContract;
};

export type FeaturedSlideAddPriorityMutationVariables = Exact<{
  data: InsertFeaturedSlidePriorityInput;
}>;


export type FeaturedSlideAddPriorityMutation = { __typename?: 'Mutation', insertFeaturedSlidePriority?: { __typename?: 'FeaturedSlidePriorityActionsResult', success?: boolean | null } | null };

export type FeaturedSlideRemovePriorityMutationVariables = Exact<{
  data: ExtractFeaturedSlidePriorityInput;
}>;


export type FeaturedSlideRemovePriorityMutation = { __typename?: 'Mutation', extractFeaturedSlidePriority?: { __typename?: 'FeaturedSlidePriorityActionsResult', success?: boolean | null } | null };

export type FeaturedSlideChangePriorityMutationVariables = Exact<{
  data: InsertFeaturedSlidePriorityInput;
}>;


export type FeaturedSlideChangePriorityMutation = { __typename?: 'Mutation', changeFeaturedSlidePriority?: { __typename?: 'FeaturedSlidePriorityActionsResult', success?: boolean | null } | null };

export type GipServicesNameByIdQueryVariables = Exact<{
  servicesIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GipServicesNameByIdQuery = { __typename?: 'Query', services: Array<{ __typename?: 'Service', id: string, name: string }> };

export type GipProductsNameByIdQueryVariables = Exact<{
  productsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GipProductsNameByIdQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, name: string }> };

export type ResourcesMobileAppPagesQueryVariables = Exact<{
  where?: InputMaybe<MobileAppPageWhereInput>;
}>;


export type ResourcesMobileAppPagesQuery = { __typename?: 'Query', mobileAppPages: Array<{ __typename?: 'MobileAppPage', id: string, title: string }> };

export type ResourcesHoodsQueryVariables = Exact<{
  where?: InputMaybe<HoodWhereInput>;
}>;


export type ResourcesHoodsQuery = { __typename?: 'Query', hoods: Array<{ __typename?: 'Hood', id: string, displayName?: string | null }> };

export type InterestGroupsQueryVariables = Exact<{
  where: QueryInterestGroupFindWhereInput;
}>;


export type InterestGroupsQuery = { __typename?: 'Query', interestGroups?: Array<{ __typename: 'InterestGroup', id?: string | null, isPublic?: boolean | null, description?: string | null, name?: string | null } | null> | null };

export type ResourcesPlacesQueryVariables = Exact<{
  where?: InputMaybe<PlaceWhereInput>;
}>;


export type ResourcesPlacesQuery = { __typename?: 'Query', places: Array<{ __typename?: 'Place', id: string, name: string }> };

export type ResourcesProductsQueryVariables = Exact<{
  where?: InputMaybe<ServiceProductWhereInput>;
}>;


export type ResourcesProductsQuery = { __typename?: 'Query', serviceProducts: Array<{ __typename?: 'ServiceProduct', id: string, name: string }> };

export type HoodNameByIdQueryVariables = Exact<{
  hoodId?: InputMaybe<Scalars['ID']>;
}>;


export type HoodNameByIdQuery = { __typename?: 'Query', hood?: { __typename?: 'Hood', name: string } | null };

export type ResourcesServicesQueryVariables = Exact<{
  where?: InputMaybe<ServiceWhereInput>;
}>;


export type ResourcesServicesQuery = { __typename?: 'Query', services: Array<{ __typename?: 'Service', id: string, name: string }> };

export type NotificationDeeplinkPlacesQueryVariables = Exact<{
  where: PlaceWhereUniqueInput;
}>;


export type NotificationDeeplinkPlacesQuery = { __typename?: 'Query', place?: { __typename?: 'Place', id: string, name: string } | null };

export type NotificationDeeplinkServiceQueryVariables = Exact<{
  where: ServiceWhereUniqueInput;
}>;


export type NotificationDeeplinkServiceQuery = { __typename?: 'Query', service?: { __typename?: 'Service', id: string, name: string } | null };

export type NotificationDeeplinkUserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type NotificationDeeplinkUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null };


export const FeaturedSlideAddPriorityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FeaturedSlideAddPriority"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"insertFeaturedSlidePriorityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insertFeaturedSlidePriority"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<FeaturedSlideAddPriorityMutation, FeaturedSlideAddPriorityMutationVariables>;
export const FeaturedSlideRemovePriorityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FeaturedSlideRemovePriority"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"extractFeaturedSlidePriorityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"extractFeaturedSlidePriority"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<FeaturedSlideRemovePriorityMutation, FeaturedSlideRemovePriorityMutationVariables>;
export const FeaturedSlideChangePriorityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FeaturedSlideChangePriority"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"insertFeaturedSlidePriorityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeFeaturedSlidePriority"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<FeaturedSlideChangePriorityMutation, FeaturedSlideChangePriorityMutationVariables>;
export const GipServicesNameByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GIPServicesNameById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"servicesIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"services"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id_in"},"value":{"kind":"Variable","name":{"kind":"Name","value":"servicesIds"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"50"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GipServicesNameByIdQuery, GipServicesNameByIdQueryVariables>;
export const GipProductsNameByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GIPProductsNameById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"productsIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"products"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id_in"},"value":{"kind":"Variable","name":{"kind":"Name","value":"productsIds"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"50"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GipProductsNameByIdQuery, GipProductsNameByIdQueryVariables>;
export const ResourcesMobileAppPagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResourcesMobileAppPages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"MobileAppPageWhereInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mobileAppPages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"50"}},{"kind":"Argument","name":{"kind":"Name","value":"skip"},"value":{"kind":"IntValue","value":"0"}},{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}},{"kind":"Argument","name":{"kind":"Name","value":"orderBy"},"value":{"kind":"EnumValue","value":"id_DESC"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<ResourcesMobileAppPagesQuery, ResourcesMobileAppPagesQueryVariables>;
export const ResourcesHoodsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResourcesHoods"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"HoodWhereInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hoods"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"10"}},{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]} as unknown as DocumentNode<ResourcesHoodsQuery, ResourcesHoodsQueryVariables>;
export const InterestGroupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"InterestGroups"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"QueryInterestGroupFindWhereInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"interestGroups"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}},{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"100"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isPublic"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]} as unknown as DocumentNode<InterestGroupsQuery, InterestGroupsQueryVariables>;
export const ResourcesPlacesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResourcesPlaces"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PlaceWhereInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"places"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"10"}},{"kind":"Argument","name":{"kind":"Name","value":"orderBy"},"value":{"kind":"EnumValue","value":"id_DESC"}},{"kind":"Argument","name":{"kind":"Name","value":"skip"},"value":{"kind":"IntValue","value":"0"}},{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<ResourcesPlacesQuery, ResourcesPlacesQueryVariables>;
export const ResourcesProductsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResourcesProducts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ServiceProductWhereInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"serviceProducts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"50"}},{"kind":"Argument","name":{"kind":"Name","value":"skip"},"value":{"kind":"IntValue","value":"0"}},{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}},{"kind":"Argument","name":{"kind":"Name","value":"orderBy"},"value":{"kind":"EnumValue","value":"id_DESC"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<ResourcesProductsQuery, ResourcesProductsQueryVariables>;
export const HoodNameByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"HoodNameById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"hoodId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hood"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"hoodId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<HoodNameByIdQuery, HoodNameByIdQueryVariables>;
export const ResourcesServicesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResourcesServices"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ServiceWhereInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"services"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"50"}},{"kind":"Argument","name":{"kind":"Name","value":"skip"},"value":{"kind":"IntValue","value":"0"}},{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}},{"kind":"Argument","name":{"kind":"Name","value":"orderBy"},"value":{"kind":"EnumValue","value":"id_DESC"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<ResourcesServicesQuery, ResourcesServicesQueryVariables>;
export const NotificationDeeplinkPlacesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"notificationDeeplinkPlaces"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PlaceWhereUniqueInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"place"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<NotificationDeeplinkPlacesQuery, NotificationDeeplinkPlacesQueryVariables>;
export const NotificationDeeplinkServiceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"notificationDeeplinkService"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ServiceWhereUniqueInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"service"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<NotificationDeeplinkServiceQuery, NotificationDeeplinkServiceQueryVariables>;
export const NotificationDeeplinkUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"notificationDeeplinkUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserWhereUniqueInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]} as unknown as DocumentNode<NotificationDeeplinkUserQuery, NotificationDeeplinkUserQueryVariables>;