import { useSearchParams } from '@remix-run/react';
import { Spin } from '@venncity/venn-ds';
import { useTranslation } from 'react-i18next';
import type { loader } from '~/routes/resources+/events+/$id.edit';
import { useResourceData } from '~/utils/http';
import { EventForm } from './event-form';

export function EditEventForm() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('event-edit');
  const eventId = searchParams.get('event-id');

  const { data } = useResourceData<typeof loader>(`resources/events/${eventId}/edit`);

  return data?.event ? (
    <EventForm initialValues={data.event} title={t('title')} />
  ) : (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Spin />
    </div>
  );
}

export const handle = {
  i18n: ['event-edit'],
};
