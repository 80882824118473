import { VariantProps, cva } from 'class-variance-authority';
import { ListBoxProps, Select, SelectProps, SelectValue } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { FieldDescription, FieldError, FieldLabel, FieldProps } from '../../atoms/field/field';
import Icon from '../../atoms/icon/icon';
import { ListBox } from '../../atoms/list-box/list-box';
import { Popover } from '../../atoms/popover/popover';
import { Pressable } from '../../atoms/pressable/pressable';
import { input, inputContainer } from '../../electrons/input';
import { listBoxTrigger, listBoxTriggerIcon } from '../../electrons/list-box';

const select = cva('', {
  variants: {
    variant: {
      light: [''],
      dark: ['!text-white'],
    },
    labelWeight: {
      normal: '',
      bold: 'font-bold',
    },
  },
  defaultVariants: {
    variant: 'light',
    labelWeight: 'normal',
  },
});

function _Select<T extends object>({
  children,
  size,
  label,
  labelWeight,
  description,
  errorMessage,
  isInvalid,
  items,
  variant,
  className,
  ...props
}: Omit<SelectProps<T>, 'children'> &
  VariantProps<typeof input> &
  VariantProps<typeof select> &
  FieldProps & {
    children?: ListBoxProps<T>['children'];
    items?: Iterable<T>;
    variant?: 'light' | 'dark';
  }) {
  return (
    <Select
      isInvalid={errorMessage ? true : isInvalid}
      placeholder="Select..."
      validationBehavior="aria"
      {...props}>
      {label && <FieldLabel className={select({ variant, labelWeight })}>{label}</FieldLabel>}
      <Pressable className={twMerge(inputContainer(), className)} data-invalid={errorMessage}>
        <SelectValue className={twMerge(input({ size }), 'pr-0')} />
        <span className={listBoxTrigger({ className: 'text-grey-900' })}>
          <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
        </span>
      </Pressable>
      {errorMessage ? (
        <FieldError>{errorMessage}</FieldError>
      ) : (
        description && <FieldDescription>{description}</FieldDescription>
      )}
      <Popover className="w-[var(--trigger-width)]">
        <ListBox items={items}>{children}</ListBox>
      </Popover>
    </Select>
  );
}

export { _Select as Select };
