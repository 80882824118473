import { useFetcher, useSearchParams } from '@remix-run/react';
import { DatePicker, Item, Select, TextField, Dialog, Heading, Text } from '@venncity/block';
import { Button, Divider } from '@venncity/venn-ds';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, kebabCase } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import type { ValueOf } from 'type-fest';
import { LinkType } from '~/codegen/graphql';
import AsyncCombobox from '~/components/async-combo-box';
import BuildingsTagField from '~/components/buildings-tag-field';
import { ImageUpload } from '~/components/image-upload';
import {
  FeaturedSlideFormAction,
  FeaturedSlideFormTabs,
  type FeaturedSlideFormDTO,
} from '~/dto/featured-slide-dto';
import { CommunityCombobox } from '~/routes/_app+/_layout/helpers/components/community-combobox';
import { useAppLoaderData } from '~/utils/common';
import useScrollToError from '~/utils/hooks/useScrollToError';
import type { ActionResponseBody } from '~/utils/http.server';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

interface FeaturedSlideFormProps {
  initialValues?: FeaturedSlideFormDTO;
  title: string;
}

const AudienceType = {
  ALL_BUILDINGS: 'allBuildings',
  SPECIFIC_BUILDINGS: 'specificBuildings',
};

export default function FeaturedSlideForm({ initialValues, title }: FeaturedSlideFormProps) {
  const fetcher = useFetcher<ActionResponseBody<{ id: string }>>();
  const dialogRef = useRef<HTMLElement>(null);
  const appLoaderData = useAppLoaderData();
  const searchNavigate = useSearchNavigate();
  const [linkType, setLinkType] = useState<LinkType>(
    (initialValues?.linkType as LinkType) || LinkType.PLACE,
  );
  const [searchParams] = useSearchParams();
  const [name, setName] = useState(initialValues?.name || '');

  const errors = fetcher.data?.errors;

  const featuredSlideId = searchParams.get('featured-slide-id');

  const timezone = appLoaderData.selectedCommunity.timezone;

  const formattedTimezone = dayjs().tz(timezone).format('z');

  const anyFetchersArePending =
    fetcher.formMethod === 'POST' && ['loading', 'submitting'].includes(fetcher.state);

  const isSaving =
    anyFetchersArePending && fetcher.formData?.get('action') === FeaturedSlideFormAction.Save;

  const isPreviewing =
    anyFetchersArePending && fetcher.formData?.get('action') === FeaturedSlideFormAction.Preview;

  const communityId = searchParams.get(GlobalSearchParam.CommunityId) || undefined;

  useEffect(() => {
    if (fetcher.state === 'loading' && !isEmpty(fetcher.data?.data)) {
      const action = fetcher.formData?.get('action') as ValueOf<typeof FeaturedSlideFormAction>;

      if (action === FeaturedSlideFormAction.Preview) {
        searchNavigate(
          {
            view: FeaturedSlideFormTabs.Preview,
            featuredSlideId: fetcher.data?.data?.id,
            communityId,
          },
          { preventScrollReset: true },
        );
      } else {
        searchNavigate(
          {
            view: undefined,
            featuredSlideId: undefined,
            dialogType: undefined,
            isResidentHosted: undefined,
            audience: undefined,
            communityId,
          },
          { preventScrollReset: true },
        );
      }
    }
  }, [communityId, fetcher.data?.data, fetcher.formData, fetcher.state, searchNavigate]);

  useScrollToError(dialogRef, fetcher.data?.errors || {});

  return (
    <Dialog ref={dialogRef}>
      <Dialog.Header title={title} />
      <fetcher.Form
        action={
          featuredSlideId
            ? `/resources/featured-slides/${featuredSlideId}/edit?community-id=${communityId}`
            : '/resources/featured-slides/new'
        }
        method="POST">
        <Dialog.Body>
          {initialValues && (
            <input hidden={true} name="community-id" value={initialValues.communityId} />
          )}
          {!initialValues && <CommunityCombobox key={communityId} />}
          <Fieldset title="Basic info">
            <TextField
              errorMessage={errors?.name}
              id="name"
              label="Post name"
              name="name"
              onChange={setName}
              value={name}
            />
            <TextField
              aria-label="Title"
              defaultValue={initialValues?.title || ''}
              errorMessage={errors?.title}
              id="title"
              label="Post content"
              name="title"
            />
          </Fieldset>
          <Divider />
          <Fieldset title="Date and time">
            <DatePicker
              aria-label="publishStartDate"
              defaultValue={initialValues?.publishStartDate}
              description={`Date is reflected in this neighborhood time zone (${formattedTimezone})`}
              errorMessage={errors?.publishStartDate}
              granularity="minute"
              id="publishStartDate"
              label="Start date"
              minValue={dayjs().startOf('day').toISOString()}
              name="publishStartDate"
              placeholderValue={dayjs().toISOString()}
              timezone={timezone}
            />
            <DatePicker
              aria-label="publishEndDate"
              defaultValue={initialValues?.publishEndDate}
              description={`Date is reflected in this neighborhood time zone (${formattedTimezone})`}
              errorMessage={errors?.publishEndDate}
              granularity="minute"
              id="publishEndDate"
              label="End date"
              minValue={dayjs().startOf('day').toISOString()}
              name="publishEndDate"
              placeholderValue={dayjs().add(1, 'day').toISOString()}
              timezone={timezone}
            />
          </Fieldset>
          <Divider />
          <Fieldset title="Post Image">
            <ImageUpload
              defaultValue={initialValues?.image?.id}
              description="Pick an image for your post"
              errorMessage={errors?.image}
              name="image"
            />
          </Fieldset>
          <Divider />
          <Fieldset title="Audience type">
            <Select
              aria-label="Audience Type"
              defaultSelectedKey={
                initialValues?.buildings?.length
                  ? AudienceType.SPECIFIC_BUILDINGS
                  : AudienceType.ALL_BUILDINGS
              }
              name="audience"
              onSelectionChange={(audience) => {
                searchNavigate({ audience: audience.toString() });
              }}>
              <Item id={AudienceType.ALL_BUILDINGS}>All buildings</Item>
              <Item id={AudienceType.SPECIFIC_BUILDINGS}>Specific buildings</Item>
            </Select>

            {(searchParams.get('audience') === AudienceType.SPECIFIC_BUILDINGS ||
              !isEmpty(initialValues?.buildings)) && (
              <BuildingsTagField
                communityId={communityId}
                defaultValueFull={initialValues?.buildings}
                errorMessage={errors?.buildings}
                placeholder="Select buildings"
              />
            )}
          </Fieldset>
          <Divider />
          <Fieldset title="Link type">
            <div>
              <Select
                aria-label="Link type"
                defaultSelectedKey={linkType}
                errorMessage={errors?.linkType}
                items={[
                  { value: LinkType.EVENT, text: 'Event' },
                  { value: LinkType.USER, text: 'Resident profile' },
                  { value: LinkType.SERVICE, text: 'Service or product' },
                  { value: LinkType.PLACE, text: 'Place' },
                  { value: LinkType.GENERALINFOPAGE, text: 'General info page' },
                  { value: LinkType.CHATGROUP, text: 'Community group' },
                  { value: LinkType.SELFPROFILE, text: 'Self profile' },
                  { value: LinkType.CREATEDISCUSSION, text: 'Create discussion' },
                  { value: LinkType.CUSTOM, text: 'Custom' },
                ]}
                name="linkType"
                onSelectionChange={(value) => {
                  setLinkType(value as LinkType);
                }}>
                {(item) => <Item id={item.value}>{item.text}</Item>}
              </Select>
              <LinkTypeField
                defaultLinkType={initialValues?.callToActionType}
                defaultValue={
                  linkType == initialValues?.callToActionType
                    ? initialValues?.callToActionEntityId
                    : ''
                }
                errors={errors}
                key={`linkType-${communityId}`}
                linkType={linkType}
              />
            </div>
          </Fieldset>
        </Dialog.Body>
        <Dialog.Footer className="justify-between">
          <Button
            disabled={anyFetchersArePending}
            formNoValidate={true}
            htmlType="submit"
            loading={isPreviewing}
            name="action"
            value={FeaturedSlideFormAction.Preview}
            variant="outlined">
            Preview
          </Button>
          <Button
            disabled={anyFetchersArePending}
            formNoValidate={true}
            htmlType="submit"
            loading={isSaving}
            name="action"
            type="primary"
            value={FeaturedSlideFormAction.Save}>
            Save
          </Button>
        </Dialog.Footer>
      </fetcher.Form>
    </Dialog>
  );
}

function LinkTypeField({
  linkType,
  defaultValue,
  defaultLinkType,
  errors,
}: {
  linkType?: LinkType;
  defaultValue?: string;
  defaultLinkType?: string;
  errors?: Record<string, string[] | undefined>;
}) {
  const [searchParams] = useSearchParams();
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);

  if (!linkType || [LinkType.SELFPROFILE, LinkType.CREATEDISCUSSION].includes(linkType)) {
    return null;
  }

  if (linkType === LinkType.CUSTOM) {
    return (
      <TextField
        className="my-6"
        defaultValue={defaultLinkType === LinkType.CUSTOM ? defaultValue : ''}
        errorMessage={errors ? errors['customLink'] : undefined}
        id="customLink"
        name="customLink"
      />
    );
  }
  const placeholder = `Search to select a ${linkType === LinkType.GENERALINFOPAGE ? 'general info page' : linkType}`;
  return (
    <AsyncCombobox<{ id: string; name?: string; title?: string }>
      className="my-6"
      defaultSelectedKey={defaultValue}
      errorMessage={errors ? errors[linkType] : undefined}
      key={`linkType-${communityId}-${linkType}`}
      name={linkType}
      placeholder={placeholder}
      resource={
        linkType === LinkType.CHATGROUP
          ? `groups?community-id=${communityId}&isPublic=true`
          : `${kebabCase(linkType)}s?community-id=${communityId}`
      }>
      {(item) => <Item>{item.name || item.title}</Item>}
    </AsyncCombobox>
  );
}

function Fieldset({
  children,
  title,
  description,
  metaData,
}: {
  children?: React.ReactNode;
  title: string;
  description?: string;
  metaData?: React.ReactNode;
}) {
  return (
    <fieldset className="min-w-0">
      <header>
        <Heading variant="h3">{title}</Heading>
        {description && (
          <Text className="text-grey-700 mt-2 inline-block max-w-md">{description}</Text>
        )}
        {metaData}
      </header>
      <div className="mt-8 space-y-8">{children}</div>
    </fieldset>
  );
}
