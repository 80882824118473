import { Link, useNavigate, useSearchParams } from '@remix-run/react';
import { Icon, Menu, Pressable } from '@venncity/block';
import { Tag, TagColors } from '@venncity/venn-ds';
import AmenitiesSvg from '@venncity/venn-ds/src/icons/AmenitiesSvg';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { AnimatePresence, motion } from 'motion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '~/lib/analytics';
import { GlobalSearchParam } from '~/utils/search-params';
import { sideMenuOpen } from '~/utils/state';
import { UserProfileDialogTrigger } from '../user-profile-dialog';
import triggerBackground from './trigger-background.svg';

const DESKTOP_WIDTH = 298;
const SM_BREAKPOINT = 640;

interface SideMenuProps {
  shouldShowServiceRequests: boolean;
  shouldShowPreLease: boolean;
  renewalsFeatureFlags: boolean;
  packagesFeatureFlags: boolean;
  shouldShowVisitors: boolean;
  shouldShowAwayNotice: boolean;
  shouldShowShiftNotes: boolean;
  shouldShowWorkflows: boolean;
}

export function SideMenu(props: SideMenuProps) {
  const {
    shouldShowServiceRequests,
    shouldShowPreLease,
    renewalsFeatureFlags,
    packagesFeatureFlags,
    shouldShowVisitors,
    shouldShowAwayNotice,
    shouldShowShiftNotes,
    shouldShowWorkflows,
  } = props;
  const [isSideMenuOpen, setIsSideMenuOpen] = useAtom(sideMenuOpen);
  const { t } = useTranslation('app-layout');
  const [searchParams] = useSearchParams();

  const navigate = useNavigate(); // Initialize useNavigate
  const { actionClicked } = useAnalytics();

  const handleAction = (key: React.Key) => {
    const communityId = searchParams.get(GlobalSearchParam.CommunityId);
    const communityIdParam = communityId ? `?${GlobalSearchParam.CommunityId}=${communityId}` : '';

    setIsSideMenuOpen(!isSideMenuOpen);
    const id = key.toString();
    if (id === 'service-requests') {
      actionClicked({
        target: null,
        element: 'maintenance dashboard side bar navigation',
      });

      navigate({
        pathname: '/service-requests',
        search: '?status=OPEN&status=IN_PROGRESS&status=ON_HOLD',
      });
    } else {
      navigate(`/${id}${communityIdParam}`);
    }
  };

  return (
    <div
      className={clsx(
        'fixed left-0 top-0 z-50 flex h-full w-full min-w-0 flex-col bg-no-repeat transition-transform duration-300 ease-in-out sm:w-[298px]',
        isSideMenuOpen ? 'translate-x-0' : '-translate-x-full',
      )}>
      <Background />
      <header className="relative z-10">
        <div className="flex h-24 w-full items-center justify-between px-6">
          <Link to="/">
            <VennLogo />
          </Link>
          {isSideMenuOpen && (
            <UserProfileDialogTrigger className="sm:hidden" id="side-menu-profile-dialog-trigger" />
          )}
        </div>
        <div className="flex h-20 items-center justify-center py-4">
          <Pressable
            aria-label="Toggle side menu"
            className={clsx(
              'absolute right-0 flex h-20 w-6 items-center justify-center outline-none transition-transform focus:outline-none',
              !isSideMenuOpen && 'translate-x-full delay-700',
            )}
            onPress={() => setIsSideMenuOpen((prev) => !prev)}>
            <AnimatePresence initial={false}>
              {!isSideMenuOpen && (
                <motion.img
                  alt="wave"
                  animate="visible"
                  exit="hidden"
                  initial="hidden"
                  key="wave"
                  src={triggerBackground}
                  variants={{
                    visible: {
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        bounce: 0,
                      },
                    },
                    hidden: {
                      x: '-100%',
                      opacity: 0,
                      transition: {
                        duration: 0.2,
                      },
                    },
                  }}
                />
              )}
            </AnimatePresence>
            <AnimatePresence initial={false} mode="wait">
              {isSideMenuOpen ? (
                <motion.div
                  animate={{ opacity: 1 }}
                  className="absolute text-white"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  key="open">
                  <Icon name="chevron-left"></Icon>
                </motion.div>
              ) : (
                <motion.div
                  animate={{ opacity: 1 }}
                  className="text-grey-900 absolute"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  key="closed">
                  <Icon name="chevron-right"></Icon>
                </motion.div>
              )}
            </AnimatePresence>
          </Pressable>
        </div>
      </header>
      <Menu
        aria-label="Side menu"
        className="min-h-0 flex-1 overflow-y-auto bg-transparent px-5 pt-16"
        onAction={handleAction}>
        <Menu.Item id="move-in">
          <Icon name="movein" />
          {t('sideMenu.moveIn')}
        </Menu.Item>
        <Menu.Item id="communication">
          <Icon name="communication" />
          {t('sideMenu.communication')}
        </Menu.Item>
        <Menu.Item id="stay">
          <Icon name="community" />
          {t('sideMenu.Community')}
        </Menu.Item>
        <Menu.Item id="insights">
          <Icon name="spark_stroke" />
          {t('sideMenu.insights')}
        </Menu.Item>
        {shouldShowPreLease && (
          <Menu.Item id="applications">
            <div className="text-grey-700">
              <Icon name="agreement" />
            </div>
            Applications & Leases
          </Menu.Item>
        )}

        <Menu.Item id="amenities/reservations">
          <AmenitiesSvg className="h-6 w-6" />
          {t('sideMenu.amenities')}
        </Menu.Item>
        <Menu.Item id={renewalsFeatureFlags ? 'renewals' : 'renewals/coming-soon'}>
          <Icon name="renewals" />
          {t('sideMenu.renewals')}
        </Menu.Item>
        <Menu.Item id="residents">
          <Icon name="residents" />
          {t('sideMenu.residents360')}
          <div className="flex flex-1 justify-end">
            <Tag color={TagColors.WARNING}>{t('sideMenu.BETA')}</Tag>
          </div>
        </Menu.Item>
        <Menu.Item id="mbr">
          <Icon name="reports" />
          {t('sideMenu.reports')}
        </Menu.Item>
        <Menu.Item id="csat">
          <Icon name="reports" />
          {t('sideMenu.csat')}
        </Menu.Item>
        {shouldShowWorkflows && (
          <Menu.Item id="workflows">
            <Icon name="star" />
            {t('sideMenu.workflows')}
            <Icon name="spark_stroke" />
          </Menu.Item>
        )}
        {packagesFeatureFlags && (
          <Menu.Item id="packages">
            <Icon name="package" />
            {t('sideMenu.packages')}
          </Menu.Item>
        )}
        {shouldShowVisitors && (
          <Menu.Item id="visitors">
            <Icon name="visitor" />
            {t('sideMenu.visitors')}
          </Menu.Item>
        )}
        {shouldShowAwayNotice && (
          <Menu.Item id="away-notices">
            <Icon className="text-grey-600" name="plant-2" />
            {t('sideMenu.awayNoticeDashboard')}
          </Menu.Item>
        )}
        {shouldShowShiftNotes && (
          <Menu.Item id="shift-notes">
            <Icon className="text-grey-600" name="file-plus" />
            {t('sideMenu.shiftNotesDashboard')}
          </Menu.Item>
        )}
        {shouldShowServiceRequests && (
          <Menu.Item id="service-requests">
            <Icon name="maintenance" />
            {t('sideMenu.maintenanceDashboard')}
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
}

function VennLogo() {
  return (
    <svg
      className="relative"
      fill="none"
      height="19"
      viewBox="0 0 72 19"
      width="72"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M34.3028 10.4507C34.3322 10.0362 34.3617 9.5329 34.3617 9.05922C34.3322 3.70067 31.0635 0.0592194 26.293 0.0592194C21.2868 0.0592194 17.812 3.70067 17.812 9.05922C17.812 14.477 21.4341 18.0592 26.5285 18.0592C29.12 18.0592 31.4463 17.4375 33.5371 15.6612L31.6819 12.9967C30.2684 14.1217 28.4721 14.8026 26.7641 14.8026C24.3494 14.8026 22.1997 13.5296 21.6991 10.4507H34.3028ZM30.5629 7.60856H21.7285C22.1114 5.09211 23.5838 3.37501 26.2341 3.40461C28.6193 3.40461 30.1506 4.94409 30.5629 7.60856ZM6.83192 17.6151H11.2491L18.081 0.503307H14.135L9.0994 13.7369L4.06381 0.503307H0L6.83192 17.6151ZM52.653 6.86858V17.6153H48.9131V8.112C48.9131 5.24029 47.4408 3.64161 44.9377 3.64161C42.5524 3.64161 40.5205 5.24029 40.5205 8.17121V17.6449H36.8101V0.533052H40.491V2.63503C41.8456 0.62187 43.9659 0.0889731 45.7033 0.0889731C49.7966 0.0593678 52.653 2.87187 52.653 6.86858ZM72.0001 17.6153V6.86858C72.0001 2.87187 69.1437 0.0593678 65.021 0.0889731C63.2835 0.0889731 61.1633 0.62187 59.8087 2.63503V0.533052H56.1277V17.6449H59.8676V8.17121C59.8676 5.24029 61.8995 3.64161 64.2848 3.64161C66.7878 3.64161 68.2602 5.24029 68.2602 8.112V17.6153H72.0001Z"
        fill="#3E3A37"
        fillRule="evenodd"
      />
    </svg>
  );
}

export function Background() {
  const [viewportSize, setViewportSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setViewportSize({
        // if greater than the sm breakpoint limit the width to DESKTOP_WIDTH
        width: window.innerWidth > SM_BREAKPOINT ? DESKTOP_WIDTH : window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Keeping the bezier curve control points and endpoints static relative to the path's right edge
  const dynamicPath = React.useMemo(
    () => `
    M0 0H${viewportSize.width}V94.7955
    C${viewportSize.width - 1.726} 104.03, ${viewportSize.width - 7.567} 108.892, ${viewportSize.width - 13.276} 113.644
    C${viewportSize.width - 19.722} 119.01, ${viewportSize.width - 26} 124.235, ${viewportSize.width - 26} 135.456
    C${viewportSize.width - 26} 146.537, ${viewportSize.width - 19.878} 151.589, ${viewportSize.width - 13.517} 156.837
    C${viewportSize.width - 7.732} 161.611, ${viewportSize.width - 1.75} 166.547, ${viewportSize.width} 176.33
    V178V183V${viewportSize.height}H0V183V178V0Z
  `,
    [viewportSize.height, viewportSize.width],
  );

  return (
    <svg
      className="absolute z-0 h-full w-full text-white"
      preserveAspectRatio="none"
      viewBox={`0 0 ${viewportSize.width} ${viewportSize.height}`}
      xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd" d={dynamicPath} fill="currentColor" fillRule="evenodd" />
    </svg>
  );
}
