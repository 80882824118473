import { isFunction } from 'lodash-es';
import React from 'react';
import { ListBoxItem, ListBoxItemProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { listBoxItem } from '../../electrons/list-box';
import Icon from '../icon/icon';

const _Item = React.forwardRef<HTMLDivElement, ListBoxItemProps>(
  ({ children, className, textValue, ...props }, ref) => {
    return (
      <ListBoxItem
        className={(bag) => {
          return twMerge(listBoxItem(), isFunction(className) ? className(bag) : className);
        }}
        ref={ref}
        textValue={typeof children === 'string' ? children : textValue}
        {...props}>
        {({ isSelected }) => (
          <>
            {children}
            {isSelected ? <Icon className="h-4 w-4" name="check" /> : <span className="h-4 w-4" />}
          </>
        )}
      </ListBoxItem>
    );
  },
);

export { _Item as Item };
