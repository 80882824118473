interface AmenitiesSvgProps {
  className: string;
}

const AmenitiesSvg = (props: AmenitiesSvgProps) => {
  return (
    <svg
      className={props.className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.156 11.499c1.305 0 1.305-1.49 2.606-1.49.993 0 1.228.868 1.862 1.279m11.17.211c-1.306 0-1.31-1.49-2.61-1.49-1.302 0-1.306 1.49-2.607 1.49-1.105 0-1.275-1.075-2.098-1.398M4.156 15.222c1.305 0 1.305-1.49 2.606-1.49s1.301 1.49 2.602 1.49 1.306-1.49 2.607-1.49c1.3 0 1.305 1.49 2.606 1.49 1.3 0 1.305-1.49 2.606-1.49s1.305 1.49 2.61 1.49M4.156 18.946c1.305 0 1.305-1.489 2.606-1.489s1.301 1.49 2.602 1.49 1.306-1.49 2.607-1.49c1.3 0 1.305 1.49 2.606 1.49 1.3 0 1.305-1.49 2.606-1.49s1.305 1.49 2.61 1.49"
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="M6.383 6.416v-.238c0-1.175.832-2.125 1.861-2.125 1.03 0 1.862.95 1.862 2.125v8.672M12.352 6.416v-.238c0-1.175.832-2.125 1.861-2.125 1.03 0 1.862.95 1.862 2.125v4.204"
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default AmenitiesSvg;
